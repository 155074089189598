import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';

export class RoleMinimal extends JsonLd {
  /**
   * The abbreviation or sort version of the {@see name}
   */
  @Expose() public abbreviation!: string;

  /**
   * Name of this Resource
   */
  @Expose() public name!: string;
}
