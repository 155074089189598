import { createFeatureSelector, createSelector } from '@ngrx/store';
import { actionAdapter, ACTIONS_FEATURE_KEY, ActionState } from './actions.reducer';

const { selectEntities, selectAll } = actionAdapter.getSelectors();

export class ActionsSelectors {
  public static readonly state = createFeatureSelector<ActionState>(ACTIONS_FEATURE_KEY);

  public static readonly loading = createSelector(ActionsSelectors.state, (state) => state.loading);

  public static readonly loaded = createSelector(ActionsSelectors.state, (state) => state.loaded);

  public static readonly savingDeletions = createSelector(
    ActionsSelectors.state,
    (state) => state.saveDeletions,
  );

  public static readonly lastChunkLoaded = createSelector(
    ActionsSelectors.state,
    (state) => state.lastChunkLoaded,
  );

  public static readonly actionEntities = createSelector(ActionsSelectors.state, (state) =>
    selectEntities(state),
  );

  public static readonly actions = createSelector(ActionsSelectors.state, (state) =>
    selectAll(state),
  );

  public static readonly loadedChunks = createSelector(
    ActionsSelectors.state,
    (state) => state.loadedChunks,
  );

  public static readonly lastLoadedChunk = createSelector(
    ActionsSelectors.loadedChunks,
    (chunks) => {
      return Math.max(...chunks);
    },
  );

  public static readonly totalItems = createSelector(
    ActionsSelectors.state,
    (state) => state.totalItems,
  );
}
