import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  LessonDetailedContract,
  SlotDetailedContract,
} from '@scheduler-frontend/assignment-contracts';
import {
  BusinessServiceDetailedContract,
  ProductContract,
} from '@scheduler-frontend/data-access-business-services';
import { ScheduleContract, ScheduleDetailedContract } from '@scheduler-frontend/schedule-contracts';
import { TsRangeInterface } from '@techniek-team/common';
import { SlotToCreateContract } from '../../../contract/slot-to-create.contract';

export const createSlotsActions = createActionGroup({
  source: '[CreateSlots]',
  events: {
    setScheduleSearchQuery: props<{ query: string }>(),
    loadSchedules: props<{ businessService: BusinessServiceDetailedContract }>(),
    loadSchedulesEmptyResults: emptyProps(),
    loadSchedulesSuccess: props<{ schedules: ScheduleContract[]; totalItems: number }>(),
    loadSchedulesFailure: props<{ error: unknown }>(),
    loadDetailedSchedule: props<{ id: string }>(),
    loadDetailedScheduleSuccess: props<{ schedule: ScheduleDetailedContract }>(),
    loadDetailedScheduleFailure: props<{ error: unknown }>(),

    loadProductsEmptyResults: emptyProps(),
    loadProductsSuccess: props<{ items: ProductContract[] }>(),
    loadProductsFailure: props<{ error: unknown }>(),

    loadLessons: props<{ schedule: ScheduleContract }>(),
    loadLessonsSuccess: props<{ lessons: LessonDetailedContract[] }>(),
    loadLessonsFailure: props<{ error: unknown }>(),

    openCreateSlotModal: emptyProps(),

    createSlotsToCreateSuccess: props<{ slotsToCreate: SlotToCreateContract[] }>(),
    removeSlotToCreate: props<{ id: string }>(),
    updateSlotToCreate: props<{
      id: string;
      changes: {
        timeRange?: TsRangeInterface<string>;
        amountOfPupils?: number;
      };
    }>(),

    clear: emptyProps(),
    createSlot: emptyProps(),
    createSlotSuccess: props<{ slots: SlotDetailedContract[] }>(),
    createSlotFailure: props<{ error: unknown }>(),
  },
});
