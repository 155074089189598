import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BusinessServiceContract,
  BusinessServicesSelectors,
} from '@scheduler-frontend/data-access-business-services';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { uniqBy } from 'lodash-es';
import { CREATE_SLOTS_FEATURE_KEY, CreateSlotsState } from '../reducers/create-slots.reducer';

export class ProductByLocationSelectors {
  public static readonly slotsState =
    createFeatureSelector<CreateSlotsState>(CREATE_SLOTS_FEATURE_KEY);

  public static readonly loading = createSelector(
    ProductByLocationSelectors.slotsState,
    (state) => state.loadingProducts,
  );

  public static readonly products = createSelector(
    ProductByLocationSelectors.slotsState,
    (state) => {
      return state.products;
    },
  );

  public static readonly businessServicesByLocation = createSelector(
    ProductByLocationSelectors.products,
    BusinessServicesSelectors.businessServiceEntities,
    (products, businessServices) => {
      return uniqBy(
        products.map(
          (item) =>
            businessServices[jsonLdSelectId(item.businessService)] as BusinessServiceContract,
        ),
        (item) => item?.['@id'],
      ).sort((businessServiceA, businessServiceB) => {
        return businessServiceA.name.localeCompare(businessServiceB.name);
      });
    },
  );
}
