import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { SlotContract } from '@scheduler-frontend/assignment-contracts';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, handleEndpointSuccess } from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { EditSlotApi } from './api/edit-slot.api';
import { editSlotActions } from './edit-slot.actions';
import { EditSlotSelectors } from './edit-slot.selectors';

@Injectable()
export class EditSlotEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly editSlotApi = inject(EditSlotApi);

  public saveEditSlot = createEffect(() =>
    this.actions$.pipe(
      ofType(editSlotActions.submitEditSlot),
      concatLatestFrom(() => this.store.select(EditSlotSelectors.selectedSlot).pipe(isDefined())),
      exhaustMap(([action, selectedSlot]) =>
        this.editSlotApi
          .execute(
            action.timeRange.start,
            action.timeRange.end,
            action.role,
            action.performSkillCheck,
            selectedSlot as SlotContract,
          )
          .pipe(
            map(() =>
              editSlotActions.submitEditSlotSuccess({
                slot: {
                  ...(selectedSlot as SlotContract),
                  timePeriod: {
                    ...(selectedSlot as SlotContract).timePeriod,
                    start: action.timeRange.start,
                    end: action.timeRange.end,
                  },
                  role: action.role['@id'],
                },
              }),
            ),
            catchError((error) => of(editSlotActions.submitEditSlotFailure({ error: error }))),
          ),
      ),
    ),
  );

  public readonly saveEditSlotFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(editSlotActions.submitEditSlotFailure, {
          message: 'Let op! Bijwerken van de shift niet gelukt!',
        }),
      ),
    { dispatch: false },
  );

  public readonly saveEditSlotSuccess = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(editSlotActions.submitEditSlotSuccess, {
          message: 'Shift is bijgewerkt!',
        }),
      ),
    { dispatch: false },
  );
}
