import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeleteActionsApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(id: string): Observable<void> {
    let url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/actions/${id}`;
    return this.httpClient.delete<void>(url);
  }
}
