import { Signal } from '@angular/core';
import { LocationModel } from '@scheduler-frontend/data-access-locations';
import { LazyJsonLd as JsonLd, SignalFetch } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';

/**
 * The standard version of the Lesson Resource.
 *
 * A Lesson isn't the same a lesson should attend to at school. A lesson
 * is a grouping of shift connected to an amount of pupils that are attending
 * the shift on a specific date and most of the time in a specific room. The lesson
 * also holds possibly information about which subject and on what level the lesson is given.
 * (There are services and therefor lesson which aren't about a specific subject
 * for example huiswerkbegeleiding)
 */
export class Lesson extends JsonLd {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Lesson';

  /**
   * The location where the assignment takes place.
   *
   * Even if the is online boolean is set to true the api still gives a location sometimes
   * because with some delivery types the candidate still is expected at a certain location
   * but is the pupil joining online.
   */
  @SignalFetch(() => LocationModel)
  @Expose()
  public location!: Signal<LocationModel | undefined>;

  @Expose() public isOnline!: boolean;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.className}: ${this.getId()}`;
  }
}
