import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, SelectCustomEvent } from '@ionic/angular';
import {
  AssignmentStateDisplayValues,
  AssignmentStateEnum,
} from '@scheduler-frontend/assignment-contracts';
import {
  AssignmentsStoreService,
  TransitionEnum,
  TransitionToMessage,
} from '@scheduler-frontend/data-access-assignment';
import { CapitalizeModule } from '@techniek-team/common';

@Component({
  selector: 'app-edit-status',
  standalone: true,
  imports: [CommonModule, CapitalizeModule, IonicModule, ReactiveFormsModule],
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss'],
})
export class EditStatusComponent {
  protected readonly TransitionToMessage = TransitionToMessage;

  protected readonly AssignmentStateDisplayValues = AssignmentStateDisplayValues;

  protected readonly AssignmentStateEnum = AssignmentStateEnum;

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly assignmentStatusControl = new FormControl<TransitionEnum | null>({
    value: null,
    disabled: true,
  });

  protected readonly setCurrentState = this.assignmentsStoreService.activeAssignment$
    .pipe(takeUntilDestroyed())
    .subscribe((assignment) => {
      this.assignmentStatusControl.patchValue(assignment.state as unknown as TransitionEnum, {
        emitEvent: false,
      });
    });

  protected readonly activeAssignmentTransitions =
    this.assignmentsStoreService.activeAssignmentTransitions$
      .pipe(takeUntilDestroyed())
      .subscribe((transition) => {
        if (transition.length === 0) {
          this.assignmentStatusControl.disable({ emitEvent: false });
          return;
        }
        this.assignmentStatusControl.enable({ emitEvent: false });
      });

  protected transition(ev: Event) {
    const event = ev as SelectCustomEvent<TransitionEnum>;
    this.assignmentsStoreService.applyTransition(event.detail.value);
  }
}
