import { Action, createReducer, on } from '@ngrx/store';
import { absenceActions } from './absence.actions';

export const ABSENCE_FEATURE_KEY = 'absence';

export interface AbsencesState {
  savingMarkAsAbsent: boolean;
  savingMarkAsPresent: boolean;
  error?: unknown;
}

export const initialState: AbsencesState = {
  // set initial required properties
  savingMarkAsAbsent: false,
  savingMarkAsPresent: false,
};

const reducer = createReducer(
  initialState,
  on(absenceActions.markAsAbsent, (state) => ({
    ...state,
    savingMarkAsAbsent: true,
    error: null,
  })),
  on(absenceActions.markAsAbsentSuccess, (state) => ({
    ...state,
    savingMarkAsAbsent: false,
  })),
  on(absenceActions.markAsAbsentFailure, (state, { error }) => ({
    ...state,
    savingMarkAsAbsent: false,
    error: error,
  })),
  on(absenceActions.markAsPresent, (state) => ({
    ...state,
    savingMarkAsPresent: true,
    error: null,
  })),
  on(absenceActions.markAsPresentSuccess, (state) => ({
    ...state,
    savingMarkAsPresent: false,
  })),
  on(absenceActions.markAsPresentFailure, (state, { error }) => ({
    ...state,
    savingMarkAsPresent: false,
    error: error,
  })),
);

export function absenceReducer(state: AbsencesState | undefined, action: Action) {
  return reducer(state, action);
}
