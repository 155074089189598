import { createActionGroup, props } from '@ngrx/store';
import { CalendarEventInterface } from '@scheduler-frontend/calendar-contracts';
import { CalendarViewEnum } from '@techniek-team/calendar';
import { PlainRangeInterface } from '@techniek-team/class-transformer';

export const calendarEventsActions = createActionGroup({
  source: 'Candidate/CalendarEvent',
  events: {
    initCalendarEvents: props<{ range: PlainRangeInterface<string> }>(),
    loadAvailabilityCalendarEventsSuccess: props<{
      availabilities: CalendarEventInterface[];
    }>(),
    loadAssignmentsHistoryCalendarEventsSuccess: props<{
      assignments: CalendarEventInterface[];
    }>(),
    loadAssignmentsFutureCalendarEventsSuccess: props<{
      assignments: CalendarEventInterface[];
    }>(),
    refreshCalendarEvents: props<{ range: PlainRangeInterface<string> }>(),
    loadCalendarEventsFailure: props<{ error: Error }>(),
    setActiveDate: props<{ activeDate: Date }>(),
    setCurrentView: props<{ currentView: CalendarViewEnum }>(),
  },
});
