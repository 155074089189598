import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, map, of, switchMap } from 'rxjs';
import { activeAssignmentActions } from '../actions/active-assignment.actions';
import { assignmentTransitionsActions } from '../actions/assignment-transitions.actions';
import { AssignmentApi } from '../api/assignment/assignment.api';
import { AssignmentsSelectors } from '../selectors/assignments.selectors';

@Injectable()
export class AssignmentTransitionsEffects {
  private readonly actions$ = inject(Actions);

  private readonly assignmentApi = inject(AssignmentApi);

  private readonly store = inject(Store);

  public readonly loadActiveAssignmentTransitionsFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(assignmentTransitionsActions.loadTransitionsFailure, {
          message: 'Er is iets misgegaan bij het laden van mogelijke status transities.',
        }),
      ),
    { dispatch: false },
  );

  public readonly loadActiveAssignmentTransitions = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.loadActiveAssignmentSuccess),
      concatLatestFrom(() =>
        this.store.pipe(select(AssignmentsSelectors.activeAssignment), isDefined()),
      ),
      switchMap(([action, activeAssignment]) =>
        this.assignmentApi.getTransitions(jsonLdSelectId(activeAssignment['@id'])).pipe(
          map((transitions) =>
            assignmentTransitionsActions.loadTransitionsSuccess({ transitions: transitions }),
          ),
          catchError((error) =>
            of(assignmentTransitionsActions.loadTransitionsFailure({ error: error })),
          ),
        ),
      ),
    ),
  );
}
