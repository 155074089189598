import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import {
  RoutesView,
  SchedulingViewQueryParams,
  setSchedulingViewQueryParam,
} from '@scheduler-frontend/scheduling-common';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO, isWithinInterval, parseISO } from 'date-fns';
import { map, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { schedulingViewActions } from '../actions/scheduling-view.actions';
import { schedulingActions } from '../actions/scheduling.actions';
import { SchedulingViewSelectors } from '../selectors/scheduling-view.selectors';
import { SchedulingSelectors } from '../selectors/scheduling.selectors';

@Injectable()
export class SchedulingViewEffects {
  private readonly actions$: Actions = inject(Actions);

  private readonly store: Store = inject(Store);

  private readonly router: Router = inject(Router);

  private readonly location = inject(Location);

  public updateUrlOnRangInViewChange = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          schedulingViewActions.setSchedulingView,
          schedulingViewActions.nextRangeInView,
          schedulingViewActions.previousRangeInView,
          schedulingViewActions.changeCurrentViewSuccess,
        ),
        concatLatestFrom(() => [this.store.pipe(select(SchedulingViewSelectors.timeRangeInView))]),
        tap(([_, rangeInView]) => {
          const newValue =
            rangeInView !== 'infinite'
              ? formatISO(parseISO(rangeInView.start), {
                  representation: 'date',
                })
              : undefined;
          setSchedulingViewQueryParam(
            this.location,
            this.router,
            SchedulingViewQueryParams.DATE_IN_VIEW,
            newValue,
          );
        }),
      ),
    { dispatch: false },
  );

  public clearSlotListOnViewChange = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingViewActions.changeCurrentView),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.slotList),
        this.store.select(SchedulingViewSelectors.timeRangeInView),
      ]),
      map(([action, selected, timeRangeInView]) => {
        return schedulingViewActions.changeCurrentViewSuccess({
          view: action.view,
          date: action.date,
          slotList: selected
            .filter((item) => {
              if (timeRangeInView === 'infinite' || action.view === RoutesView.TABLE) {
                return true;
              }
              return (
                isWithinInterval(item.timePeriod.start, timeRangeInView) &&
                isWithinInterval(item.timePeriod.end, timeRangeInView)
              );
            })
            .map((item) => jsonLdSelectId(item['@id'])),
        });
      }),
    ),
  );

  public startCandidateLoader = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingViewActions.setSchedulingView),
      filter((action) => (action?.selectedSlots ?? []).length > 0),
      map((action) => schedulingActions.startLoadingCandidates()),
    ),
  );

  public setActiveCandidateByQueryParamThroughRouterNavigation = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingViewActions.setSchedulingView),
      filter(
        (
          action,
        ): action is typeof schedulingViewActions.setSchedulingView & { activeCandidate: string } =>
          !!action.activeCandidate,
      ),
      map((action) => candidatesActions.setActiveCandidate({ selectedId: action.activeCandidate })),
    ),
  );
}
