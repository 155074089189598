import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getDate, getMonth, getYear, isAfter, isEqual, isValid, parse, parseISO, set, startOfDay } from 'date-fns';

interface DateTimeRangeValidatorOptions {
  startDateName: string;
  endDateName: string;
  startTimeName: string;
  endTimeName: string;
  separateField: true;
  dateFormat?: string;
  timeFormat?: string;
}

//eslint-disable-next-line max-lines-per-function
export function dateTimeRangeValidator(options: Partial<DateTimeRangeValidatorOptions> = {}): ValidatorFn {
  const input: DateTimeRangeValidatorOptions = {
    startDateName: 'startDate',
    endDateName: 'endDate',
    startTimeName: 'startTime',
    endTimeName: 'endTime',
    separateField: true,
    dateFormat: 'dd-MM-yyyy',
    ...options,
  };
  //eslint-disable-next-line complexity,max-lines-per-function
  return (group: AbstractControl): ValidationErrors | null => {
    if (!(group instanceof FormGroup)) {
      throw new Error('This method can only be used on a formGroup.');
    }
    if (input.separateField) {
      const dateError: ValidationErrors | null = dateRangeValidator({
        startDateName: input.startDateName,
        endDateName: input.endDateName,
        format: input.dateFormat,
      })(group);

      if (dateError) {
        return dateError;
      }

      const timeError: ValidationErrors | null = timeRangeValidator({
        startTimeName: input.startTimeName,
        endTimeName: input.endTimeName,
        format: input.timeFormat,
      })(group);
      if (timeError) {
        return timeError;
      }
    }
    let errors: ValidationErrors | null = null;
    if (!isValid(group.value[input.startDateName]) || !isValid(group.value[input.endDateName])) {
      errors = { tsRangeDateNotSet: { value: group } };
    }

    if (!errors) {
      const start: string | Date = group.getRawValue()[input.startDateName];
      const end: string | Date = group.getRawValue()[input.endDateName];
      const startDate: Date = (typeof start === 'string') ? parseISO(start) : start;
      const endDate: Date = (typeof end === 'string') ? parseISO(end) : end;
      if (isAfter(startDate, endDate)) {
        errors = { tsRangeNotInRange: { value: group } };
      }
    }

    if (errors) {
      return errors;
    }

    return null;
  };
}

interface DateRangeValidatorOptions {
  startDateName: string;
  endDateName: string;
  format?: string;
}

//eslint-disable-next-line max-lines-per-function
export function dateRangeValidator(options: Partial<DateRangeValidatorOptions> = {}): ValidatorFn {
  const input: DateRangeValidatorOptions = {
    startDateName: 'startDate',
    endDateName: 'endDate',
    format: 'dd-MM-yyyy',
    ...options,
  };
  return (group: AbstractControl): ValidationErrors | null => {
    if (!(group instanceof FormGroup)) {
      throw new Error('this method use only used on formGroups');
    }

    if (!isValid(group.value[input.startDateName]) || !isValid(group.value[input.endDateName])) {
      return { tsRangeDateNotSet: { value: group } };
    }
    const start: string | Date = group.getRawValue()[input.startDateName];
    const end: string | Date = group.getRawValue()[input.endDateName];
    const startDate: Date = (typeof start === 'string') ? parse(
      input.format as string,
      start,
      startOfDay(new Date()),
    ) : startOfDay(start);
    const endDate: Date = (typeof end === 'string') ? parse(
      input.format as string,
      end,
      startOfDay(new Date()),
    ) : startOfDay(end);

    if (isAfter(startDate, endDate)) {
      return { tsRangeNotInRange: { value: group } };
    }
    return null;
  };
}


interface TimeRangeValidatorOptions {
  startTimeName: string;
  endTimeName: string;
  format?: string;
}

//eslint-disable-next-line max-lines-per-function
export function timeRangeValidator(options: Partial<TimeRangeValidatorOptions>): ValidatorFn {
  const input: TimeRangeValidatorOptions = {
    startTimeName: 'startTime',
    endTimeName: 'endTime',
    format: 'HH:mm',
    ...options,
  };

  return (group: AbstractControl): ValidationErrors | null => {
    if (!(group instanceof FormGroup)) {
      throw new Error('This method can only be used on a FormGroup.');
    }

    if (!group.getRawValue()[input.startTimeName] || !group.getRawValue()[input.endTimeName]) {
      return { tsRangeTimeNotSet: { value: group } };
    }

    const defaultDate = new Date();
    const start: string | Date = group.getRawValue()[input.startTimeName];
    const end: string | Date = group.getRawValue()[input.endTimeName];
    const startTime: Date = (typeof start === 'string') ? parse(
      start,
      input.format as string,
      defaultDate,
    ) : set(start, { year: getYear(defaultDate), month: getMonth(defaultDate), date: getDate(defaultDate) });
    const endTime: Date = (typeof end === 'string') ? parse(
      end,
      input.format as string,
      defaultDate,
    ) : set(end, { year: getYear(defaultDate), month: getMonth(defaultDate), date: getDate(defaultDate) });

    if (isAfter(startTime, endTime) || isEqual(startTime, endTime)) {
      return { tsRangeNotInRange: { value: group } };
    }
    return null;
  };
}
