import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ProductTypeContract,
  ProductTypeDetailedContract,
} from '../contracts/product-type.contract';

export const productTypesActions = createActionGroup({
  source: '[ProductType]',
  events: {
    initProductTypes: emptyProps(),
    reloadProductTypes: emptyProps(),
    loadProductTypesSuccess: props<{
      productTypes: ProductTypeContract[];
      totalItems: number;
    }>(),
    loadFromCacheProductTypesSuccess: props<{
      productTypes: ProductTypeContract[];
      totalItems: number;
      cacheTimestamp: string; // iso 8601 date
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadProductTypesFailure: props<{ error: any }>(),
    /**
     * Loading a more detailed version of the product type
     */
    loadDetailedProductType: props<{ id: string }>(),
    loadDetailedProductTypeSuccess: props<{
      detailedProductType: ProductTypeDetailedContract;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadDetailedProductTypeFailure: props<{ error: any }>(),
  },
});
