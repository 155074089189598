import {
  DeliveryTypeContract,
  DeliveryTypeEnum,
} from '@scheduler-frontend/data-access-business-services';

export function isOnline(
  deliveryType: DeliveryTypeContract | DeliveryTypeEnum | null | undefined,
): boolean {
  if (deliveryType && typeof deliveryType === 'object' && 'deliveryType' in deliveryType) {
    deliveryType = deliveryType.deliveryType;
  }
  return !!deliveryType && deliveryType === DeliveryTypeEnum.ALL_ONLINE;
}
