import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  AssignmentContract,
  AssignmentDetailedContract,
} from '@scheduler-frontend/assignment-contracts';
import { absenceActions } from '@scheduler-frontend/data-access-absence';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { activeAssignmentActions } from '../actions/active-assignment.actions';
import { assignmentCompensationsActions } from '../actions/assignment-compensations.actions';
import { assignmentsActions } from '../actions/assignments.actions';

export const ASSIGNMENTS_FEATURE_KEY = 'assignments';

export interface AssignmentsState
  extends EntityState<AssignmentContract | AssignmentDetailedContract> {
  selectedId: string | null;

  loaded: boolean;

  loading: boolean;

  saving: boolean;

  processingUnassign: boolean;

  error?: unknown | null;

  savingTransition: boolean;
}

export const assignmentsAdapter: EntityAdapter<AssignmentContract | AssignmentDetailedContract> =
  createEntityAdapter<AssignmentContract | AssignmentDetailedContract>({
    selectId: jsonLdSelectId,
  });

export const initialAssignmentsState: AssignmentsState = assignmentsAdapter.getInitialState({
  selectedId: null,
  loaded: false,
  loading: false,
  saving: false,
  processingUnassign: false,
  savingTransition: false,
});

const reducer = createReducer(
  initialAssignmentsState,
  on(activeAssignmentActions.setActiveAssignment, (state, { selectedId }) => {
    return {
      ...state,
      selectedId: selectedId,
      loading: true,
    };
  }),
  on(activeAssignmentActions.loadActiveAssignmentSuccess, (state, { assignment }) => {
    return assignmentsAdapter.setOne(assignment, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(activeAssignmentActions.loadActiveAssignmentFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(activeAssignmentActions.clear, (state) => ({
    ...state,
    selectedId: null,
    loaded: false,
  })),
  on(assignmentsActions.addAssignment, (state, { assignment }) =>
    assignmentsAdapter.addOne(assignment, state),
  ),
  on(assignmentsActions.addAssignments, (state, { assignments }) =>
    assignmentsAdapter.addMany(assignments, state),
  ),
  on(activeAssignmentActions.setIsUrgentSuccess, (state, { isUrgent }) => {
    return assignmentsAdapter.updateOne(
      { id: state.selectedId as string, changes: { isUrgent: isUrgent } },
      {
        ...state,
      },
    );
  }),
  on(activeAssignmentActions.setDescriptionSuccess, (state, { description }) => {
    return assignmentsAdapter.updateOne(
      {
        id: state.selectedId as string,
        changes: { description: description ?? undefined },
      },
      {
        ...state,
      },
    );
  }),
  on(
    assignmentCompensationsActions.updateAssignmentCompensationsSuccess,
    (state, { mustPerformCompensation, automaticTravelCompensation }) => {
      return assignmentsAdapter.updateOne(
        {
          id: state.selectedId as string,
          changes: {
            performCompensation: mustPerformCompensation,
            automaticTravelCompensation: automaticTravelCompensation,
          },
        },
        {
          ...state,
        },
      );
    },
  ),
  on(
    activeAssignmentActions.setSelfAssignmentSuccess,
    (
      state,
      {
        selfAssignable,
        allowSelfAssignWhenNew,
        minimalGradeSelfAssign,
        maxTravelDistanceSelfAssign,
      },
    ) => {
      return assignmentsAdapter.updateOne(
        {
          id: state.selectedId as string,
          changes: {
            selfAssignable: selfAssignable,
            allowSelfAssignWhenNew: allowSelfAssignWhenNew,
            minimalGradeSelfAssign: minimalGradeSelfAssign ?? undefined,
            maxTravelDistanceSelfAssign: maxTravelDistanceSelfAssign ?? undefined,
          },
        },
        {
          ...state,
          savingSelfAssignmentSettings: false,
        },
      );
    },
  ),
  on(activeAssignmentActions.updateActualWorkingTimesSuccess, (state, { breakTime, time }) => {
    return assignmentsAdapter.updateOne(
      {
        id: state.selectedId as string,
        changes: {
          assignmentHasSlots: [
            ...(
              state.entities[state.selectedId as string] as AssignmentDetailedContract
            ).assignmentHasSlots.map((hasSlot) => {
              if (hasSlot['@id'] !== state.selectedId) {
                return hasSlot;
              }
              return {
                ...hasSlot,
                actualTimePeriod: time,
                breakTime: breakTime,
              };
            }),
          ],
        },
      },
      {
        ...state,
        savingActualWorkingTimes: false,
        targetAssignmentHasSlot: null,
      },
    );
  }),
  on(activeAssignmentActions.setIsBillableSuccess, (state, { assignmentHasSlotId, isBillable }) => {
    return assignmentsAdapter.updateOne(
      {
        id: state.selectedId as string,
        changes: {
          assignmentHasSlots: [
            ...(
              state.entities[state.selectedId as string] as AssignmentDetailedContract
            ).assignmentHasSlots.map((hasSlot) => {
              if (hasSlot['@id'] !== state.selectedId) {
                return hasSlot;
              }
              return {
                ...hasSlot,
                slot: {
                  ...hasSlot.slot,
                  lesson: {
                    ...hasSlot.slot.lesson,
                    isBillable: isBillable,
                  },
                },
              };
            }),
          ],
        },
      },
      {
        ...state,
        savingIsBillable: false,
        targetAssignmentHasSlot: null,
      },
    );
  }),
  on(activeAssignmentActions.unassignSlot, (state) => ({
    ...state,
    processingUnassign: true,
  })),
  on(assignmentsActions.unassignSlot, (state) => ({
    ...state,
    processingUnassign: true,
  })),
  on(assignmentsActions.unassignSlotSuccess, (state) => ({
    ...state,
    processingUnassign: false,
  })),
  on(assignmentsActions.unassignSlotFailure, (state, { error }) => ({
    ...state,
    error: error,
    processingUnassign: false,
  })),
  on(assignmentsActions.removeSlotFormAssignment, (state) => ({
    ...state,
    processingUnassign: true,
  })),
  on(assignmentsActions.removeSlotFormAssignmentSuccess, (state) => ({
    ...state,
    processingUnassign: false,
  })),
  on(assignmentsActions.removeSlotFormAssignmentFailure, (state) => ({
    ...state,
    processingUnassign: false,
  })),
  on(absenceActions.markAsAbsent, (state) => ({
    ...state,
    processingUnassign: true,
  })),
  on(absenceActions.markAsAbsentSuccess, (state) => ({
    ...state,
    processingUnassign: false,
  })),
  on(absenceActions.markAsAbsentFailure, (state) => ({
    ...state,
    processingUnassign: false,
  })),
  on(activeAssignmentActions.applyTransition, (state) => ({
    ...state,
    savingTransition: true,
  })),
  on(assignmentsActions.applyTransitionSuccess, (state, { newState }) =>
    assignmentsAdapter.updateOne(
      {
        id: state.selectedId as string,
        changes: {
          state: newState,
        },
      },
      {
        ...state,
        savingTransition: false,
      },
    ),
  ),
  on(assignmentsActions.applyTransitionFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingTransition: false,
  })),
);

export function assignmentsReducer(state: AssignmentsState | undefined, action: Action) {
  return reducer(state, action);
}
