import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SlotToCreateContract } from '../../../contract/slot-to-create.contract';
import { createSlotsActions } from '../actions/create-slots.actions';

export const SLOTS_TO_CREATE_FEATURE_KEY = 'SlotsToCreate';

export interface SlotsToCreateState extends EntityState<SlotToCreateContract> {}
export const slotsToCreateAdapter: EntityAdapter<SlotToCreateContract> =
  createEntityAdapter<SlotToCreateContract>({
    selectId: (item) => item.id,
  });
export const initialState: SlotsToCreateState = slotsToCreateAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(createSlotsActions.createSlotsToCreateSuccess, (state, { slotsToCreate }) => {
    return slotsToCreateAdapter.setAll(slotsToCreate, { ...state });
  }),

  on(createSlotsActions.removeSlotToCreate, (state, { id }) => {
    return slotsToCreateAdapter.removeOne(id, { ...state });
  }),
  on(createSlotsActions.updateSlotToCreate, (state, { id, changes }) => {
    return slotsToCreateAdapter.updateOne({ id: id, changes: changes }, { ...state });
  }),
);

export function slotsToCreateReducer(state: SlotsToCreateState | undefined, action: Action) {
  return reducer(state, action);
}
