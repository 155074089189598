import { Exclude, Expose } from 'class-transformer';
import { type FilterGroup } from './filter-group.model';
import { FilterInterface } from './filter.interface';

export interface FilterOptions {
  label?: string;
  icon?: string;
  iconBackground?: string;
  position?: number;
  disabled?: boolean;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalData?: any;
  canBeRemoved?: boolean;
}

export enum FilterInteractionState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
enum FilterDisplayState {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
}

interface StateChange {
  interactionState: FilterInteractionState;
  displayState: FilterDisplayState;
}

// Without this dynamic parameter AOT compiling will fail.
// @see https://angular.io/guide/angular-compiler-options#strictmetadataemit
// @dynamic
export class Filter implements FilterInterface {
  /**
   * unique identifier for this filter
   */
  @Exclude() public key: string;

  /**
   * The value or values of this filter.
   */
  @Expose() public value!: unknown | unknown[];

  /**
   * This property will be used as display text for the item in the filter menu.
   * If not set the name property will be used.
   */
  @Expose() public label?: string;

  /**
   * src to an option icon to prefix the filter item with
   */
  @Expose() public icon?: string;

  @Expose() public iconBackground?: string;

  @Expose() public canBeRemoved: boolean = true;

  /**
   * The position in which order the filters should be shown.
   * If not set it will order them alphabetically by displayValue (or otherwise name)
   * and if partially set position takes precedence over sort alphabetically.
   */
  @Expose() public position?: number;

  /**
   * This property isn't used in any way. It can by used to add aditional data
   * to a filter which for example can be used in callback give to
   * {@see FilterControllerService.registerTrigger}
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Expose() public additionalData?: any;

  /**
   * The filterGroup where this filter belongs to. This is not set when using the serializer
   * and needs to be set manually to prevent circular dependencies.
   */
  @Exclude() public filterGroup: FilterGroup | undefined;

  /**
   * This state states the current form of interaction that is possible with
   * this filter. There are 2 possible status values:
   *
   * * **ENABLED**:  This filter is active, meaning that the user can select
   *                   this filter.
   * * **DISABLED**: This filter is disabled meaning that the user can't select
   *                   this specific filter.
   *
   * When the filter is disabled it's still possible to change the selection of
   * filters. The component using the model is responsible for the disabling of
   * the filters.
   *
   * Setting the {@see FilterGroup.enable} and {@see FilterGroup.disable}
   * function also changes the {@see interactionState} for each filter.
   */
  @Exclude()
  protected interactionState: FilterInteractionState =
    FilterInteractionState.ENABLED;

  /**
   * This state states the current form of interaction that is possible with
   * this filter. The are 2 possible status values:
   *
   * * **VISIBLE**:   This filter is visible, meaning that the user can see
   *                    this filter.
   * * **HIDDEN**:    This filter is hidden, meaning that the user can't see this
   *                    specific filter.
   *
   * The implementation of the display states should be done within the
   * component using this model. This property is merely for storage and doesn't
   * have to be used.
   *
   * Setting the {@see FilterGroup.show} and {@see FilterGroup.hide} function
   * also changes the {@see displayState} for each filter.
   */
  @Exclude() protected displayState: FilterDisplayState =
    FilterDisplayState.VISIBLE;

  constructor(value: unknown | unknown[], options?: FilterOptions) {
    this.key = String(value);
    this.value = value;
    this.label = options?.label;
    this.icon = options?.icon;
    this.iconBackground = options?.iconBackground;
    this.position = options?.position;
    this.canBeRemoved = options?.canBeRemoved ?? true;
    this.additionalData = options?.additionalData;
    this.interactionState =
      typeof options?.disabled === 'boolean' && options?.disabled
        ? FilterInteractionState.DISABLED
        : FilterInteractionState.ENABLED;
    this.filterGroup = undefined;
  }

  /**
   * Returns the display text of the filter.
   *
   * This is the displayValue or if not set the key of this filter.
   */
  @Exclude()
  public get displayText(): string {
    if (this.label) {
      return this.label;
    }

    if (Array.isArray(this.value)) {
      return this.value.join(', ');
    }

    return (this.value as unknown as string).toString();
  }

  /**
   * Returns true if this filter is disabled. see {@see interactionState}
   * for more information.
   */
  public get disabled(): boolean {
    return this.interactionState === FilterInteractionState.DISABLED;
  }

  /**
   * Returns true if this filter is enabled. see {@see interactionState}
   * for more information.
   */
  public get enabled(): boolean {
    return this.interactionState === FilterInteractionState.ENABLED;
  }

  /**
   * Returns true if this filter is visible. see {@see displayState}
   * for more information.
   */
  public get visible(): boolean {
    return this.displayState === FilterDisplayState.VISIBLE;
  }

  /**
   * Returns true if this filter is hidden. see {@see displayState}
   * for more information.
   */
  public get hidden(): boolean {
    return this.displayState === FilterDisplayState.HIDDEN;
  }

  /**
   * Enables this filter. See {@see interactionState} for more information.
   */
  @Exclude()
  public enable(): void {
    this.interactionState = FilterInteractionState.ENABLED;
  }

  /**
   * Disables this filter. See {@see interactionState} for more information.
   */
  @Exclude()
  public disable(): void {
    this.interactionState = FilterInteractionState.DISABLED;
  }

  /**
   * Makes this filter visible. See {@see displayState} for more information.
   */
  @Exclude()
  public show(): void {
    this.displayState = FilterDisplayState.VISIBLE;
  }

  /**
   * Makes this filter hidden. See {@see displayState} for more information.
   */
  @Exclude()
  public hide(): void {
    this.displayState = FilterDisplayState.HIDDEN;
  }
}
