import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  AssignmentHasSlotDetailed,
  AssignmentHasSlotDetailedWithSlot,
  AssignmentHasSlotPurposeEnum,
  SlotDetailed,
} from '@scheduler-frontend/assignment-contracts';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { map, shareReplay } from 'rxjs';
import { ActiveAssignmentsPermissionsSelectors } from './+state/selectors/active-assignments-permissions.selectors';

@Injectable()
export class ActiveAssignmentsPermissionsStoreService {
  private readonly store = inject(Store);

  public canCreateAssignmentCompensationLine = this.store.selectSignal(
    ActiveAssignmentsPermissionsSelectors.canEditAssignmentCompensationLine,
  );

  public canDeleteAssignmentCompensationLine = this.store.selectSignal(
    ActiveAssignmentsPermissionsSelectors.canEditAssignmentCompensationLine,
  );

  public canChangeIsBillable = this.store.selectSignal(
    ActiveAssignmentsPermissionsSelectors.canChangeIsBillable,
  );

  public canEditManualTravelingCompensation = this.store.selectSignal(
    ActiveAssignmentsPermissionsSelectors.canEditManualTravelingCompensation,
  );
  public canEditPayoutCheckbox = this.store.selectSignal(
    ActiveAssignmentsPermissionsSelectors.canEditPayoutCheckbox,
  );
  public canEditSelfAssignable = this.store.selectSignal(
    ActiveAssignmentsPermissionsSelectors.canEditSelfAssignable,
  );

  public canReadSelfAssignable = this.store.selectSignal(UsersSelectors.isAdmin);

  public canMarkAsUrgent = this.store.selectSignal(
    ActiveAssignmentsPermissionsSelectors.canMarkAsUrgent,
  );

  public canUpdateAssignmentHasSlot$(assignmentHasSlot: AssignmentHasSlotDetailed) {
    return this.store.pipe(
      select(ActiveAssignmentsPermissionsSelectors.canUpdateAssignment),
      map((canUpdateAssignment) => {
        if (!canUpdateAssignment) {
          return false;
        }
        if (!assignmentHasSlot) {
          return false;
        }

        return assignmentHasSlot.purpose === AssignmentHasSlotPurposeEnum.SCHEDULING;
      }),
      shareReplay(),
    );
  }

  public canRemoveSlotFromAssignment$(assignmentHasSlot: AssignmentHasSlotDetailedWithSlot) {
    return this.store.pipe(
      select(ActiveAssignmentsPermissionsSelectors.canRemoveSlotFromAssignment),
      map((canRemoveSlotFromAssignment) => {
        if (!canRemoveSlotFromAssignment) {
          return false;
        }
        const slot: SlotDetailed = assignmentHasSlot.slot;

        if (slot.isCombined === false && slot.displayAsCombined === true) {
          return false;
        }

        return assignmentHasSlot.purpose === AssignmentHasSlotPurposeEnum.SCHEDULING;
      }),
      shareReplay(),
    );
  }
}
