import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  BusinessServiceContract,
  BusinessServiceDetailedContract,
} from '../contracts/business-service.contract';

export const businessServicesActions = createActionGroup({
  source: '[BusinessServices]',
  events: {
    initBusinessServices: emptyProps(),
    reloadBusinessServices: emptyProps(),
    loadBusinessServicesSuccess: props<{
      businessServices: BusinessServiceContract[];
      totalItems: number;
    }>(),
    loadFromCacheBusinessServicesSuccess: props<{
      businessServices: BusinessServiceContract[];
      totalItems: number;
      cacheTimestamp: string; // iso 8601 date
    }>(),
    loadBusinessServicesFailure: props<{ error: unknown }>(),

    /**
     * Loading a more detailed version of the business service.
     * Including the deliveryTypes.
     */
    loadDetailedBusinessService: props<{ id: string }>(),
    loadDetailedBusinessServiceSuccess: props<{
      detailedBusinessService: BusinessServiceDetailedContract;
    }>(),
    loadDetailedBusinessServiceFailure: props<{ error: unknown }>(),
  },
});
