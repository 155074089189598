import { Assignment } from '@scheduler-frontend/assignment-contracts';
import { Expose, Type } from 'class-transformer';
import { AssignmentCompensationLine } from './assignment-compensation-line.model';
import { AssignmentCompensation } from './assignment-compensation.model';
import { Compensation } from './deprecated/compensation.model';
import { PayoutWaitingForApprovalAssignmentHasSlot as AssignmentHasSlot } from './payout-waiting-for-approval-assignment-has-slot.model';

export class PayoutWaitingForApproval extends Assignment {
  @Type(() => AssignmentHasSlot)
  @Expose()
  public assignmentHasSlots!: AssignmentHasSlot[];

  @Type(() => Compensation)
  @Expose()
  public compensation?: AssignmentCompensation;

  public getSlotCompensationLines(id: string): AssignmentCompensationLine[] {
    if (!this.compensation || !this.compensation.assignmentCompensationLines) {
      return [];
    }

    return this.compensation.assignmentCompensationLines.filter((line) => {
      return line.assignmentHasSlot === id;
    });
  }

  public getExtraCompensationLines(): AssignmentCompensationLine[] {
    if (!this.compensation || !this.compensation.assignmentCompensationLines) {
      return [];
    }

    return (
      this.compensation.assignmentCompensationLines?.filter((line) => !line.assignmentHasSlot) ?? []
    );
  }
}
