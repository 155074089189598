import { createFeatureSelector, createSelector } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { Filter, FilterGroup } from '@techniek-team/search';
import { toDictionary } from '@techniek-team/tt-ngrx';
import { BusinessServiceContract } from '../contracts/business-service.contract';
import { BusinessServiceDetailed } from '../models/business-service-detailed.model';
import { BusinessService } from '../models/business-service.model';
import {
  BUSINESS_SERVICES_FEATURE_KEY,
  businessServicesAdapter,
  BusinessServicesState,
} from './business-services.reducer';

const { selectAll, selectEntities } = businessServicesAdapter.getSelectors();

export class BusinessServicesSelectors {
  public static readonly businessServicesState = createFeatureSelector<BusinessServicesState>(
    BUSINESS_SERVICES_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    BusinessServicesSelectors.businessServicesState,
    (state: BusinessServicesState) => state.loading,
  );

  public static readonly loadingDetailed = createSelector(
    BusinessServicesSelectors.businessServicesState,
    (state: BusinessServicesState) => state.loadingDetailed,
  );

  public static readonly loaded = createSelector(
    BusinessServicesSelectors.businessServicesState,
    (state: BusinessServicesState) => state.loaded,
  );

  public static readonly cacheTimestamp = createSelector(
    BusinessServicesSelectors.businessServicesState,
    (state: BusinessServicesState) => state.cacheTimeStamp,
  );

  public static readonly error = createSelector(
    BusinessServicesSelectors.businessServicesState,
    (state: BusinessServicesState) => state.error,
  );

  public static readonly businessServicesAll = createSelector(
    BusinessServicesSelectors.businessServicesState,
    (state: BusinessServicesState) => selectAll(state),
  );

  public static readonly businessServices = createSelector(
    BusinessServicesSelectors.businessServicesAll,
    (list: BusinessServiceContract[]) => [...list].filter((item) => !item.archivedAt),
  );

  public static readonly businessServiceEntities = createSelector(
    BusinessServicesSelectors.businessServicesState,
    (state: BusinessServicesState) => selectEntities(state),
  );

  public static readonly denormalizedBusinessServiceEntities = createSelector(
    BusinessServicesSelectors.businessServicesState,
    (state) => {
      return toDictionary(
        Object.values(state.entities).map((item) => {
          if (item && 'deliveryTypes' in item) {
            return denormalize(BusinessServiceDetailed, item);
          }
          return denormalize(BusinessService, item);
        }) as (BusinessService | BusinessServiceDetailed)[],
        (item) => item.getId(),
      );
    },
  );

  public static readonly businessServicesFilterGroupKey = 'businessService';

  public static readonly businessServicesFilterGroup = createSelector(
    BusinessServicesSelectors.businessServices,
    (businessService) => {
      return new FilterGroup(
        BusinessServicesSelectors.businessServicesFilterGroupKey,
        (businessService ?? []).map(
          (item) =>
            new Filter(item['@id'] as string, {
              label: `${item.name} ${item.businessEntity.name}`,
              additionalData: item,
            }),
        ),
        { multiple: true, label: 'Diensten', itemLabel: 'Dienst' },
      );
    },
  );

  public static readonly searchQueryMapper = createSelector(
    BusinessServicesSelectors.businessServiceEntities,
    (dict) => {
      return {
        businessService: {
          entities: dict,
          getDisplayText: (item: BusinessServiceContract) => item.name,
        },
      };
    },
  );
}
