@if (logRecord) {
  @if (logRecord.from) {
    De opdracht is veranderd van status&nbsp;
    <span class="ion-color-{{ logRecord.from }}">
      {{ AssignmentStateDisplayValues[logRecord.from] }}
      <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle"></fa-icon>
    </span>
    &nbsp;naar de status&nbsp;
    <span class="ion-color-{{ logRecord.to }}">
      {{ AssignmentStateDisplayValues[logRecord.to] }}
      <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle"></fa-icon> </span
    >.
  } @else {
    De opdracht is aangemaakt met de status status&nbsp;
    <span class="ion-color-{{ logRecord.to }}">
      {{ AssignmentStateDisplayValues[logRecord.to] }}
      <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle"></fa-icon> </span
    >.
  }
}
