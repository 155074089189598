import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AvailabilityStatisticContract } from '../contracts/availability-statistics.contract';

export const availabilityStatisticsActions = createActionGroup({
  source: '[AvailabilityStatistics]',
  events: {
    addAvailabilityStatistics: props<{
      items: {
        candidate: string;
        slots: string[];
        availabilityStatistic: AvailabilityStatisticContract;
      }[];
    }>(),
    clearAllAvailabilityStatistics: emptyProps,
  },
});
