import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CANDIDATES_SEARCH_FEATURE_KEY,
  CandidatesSearchState,
} from '../reducers/candidates-search.reducer';
import { candidateAdapter } from '../reducers/candidates.reducer';

const { selectEntities, selectAll } = candidateAdapter.getSelectors();

export class CandidatesSearchSelectors {
  public static readonly state = createFeatureSelector<CandidatesSearchState>(
    CANDIDATES_SEARCH_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    CandidatesSearchSelectors.state,
    (state) => state.loading,
  );

  public static readonly lastChunkLoaded = createSelector(
    CandidatesSearchSelectors.state,
    (state) => state.lastChunkLoaded,
  );

  public static readonly candidateEntities = createSelector(
    CandidatesSearchSelectors.state,
    (state) => selectEntities(state),
  );

  public static readonly candidates = createSelector(CandidatesSearchSelectors.state, (state) =>
    selectAll(state),
  );

  public static readonly loadedChunks = createSelector(
    CandidatesSearchSelectors.state,
    (state) => state.loadedChunks,
  );

  public static readonly lastLoadedChunk = createSelector(
    CandidatesSearchSelectors.loadedChunks,
    (chunks) => Math.max(...chunks),
  );

  public static readonly searchQuery = createSelector(
    CandidatesSearchSelectors.state,
    (state) => state.searchQuery,
  );
}
