import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { hashParams } from '@scheduler-frontend/common';
import { compareAsc } from 'date-fns';
import { memoize } from 'lodash-es';

/**
 * A memoized function that sorts an array of slots by start and end time.
 *
 * @param {(SlotContract | SlotDetailedContract)[]} slots - An array of slots to be sorted.
 * @returns {void}
 *
 * @example
 * const slots = [
 *   { timePeriod: { start: new Date('2022-01-01'), end: new Date('2022-01-02') } },
 *   { timePeriod: { start: new Date('2022-01-01'), end: new Date('2022-01-01') } },
 *   { timePeriod: { start: new Date('2022-01-02'), end: new Date('2022-01-02') } },
 * ];
 *
 * sortSlotsByTime(slots);
 *
 * // The slots array will have been sorted in-place, first by 'start' time, then by 'end' time
 */
export const sortSlotsByTime = memoize((slots: (SlotContract | SlotDetailedContract)[]): void => {
  slots.sort((slotA, slotB) => {
    let value: number = compareAsc(slotA.timePeriod.start, slotB.timePeriod.start);
    if (value !== 0) {
      return value;
    }

    return compareAsc(slotA.timePeriod.end, slotB.timePeriod.end);
  });
}, hashParams);
