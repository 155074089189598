import { createSelector } from '@ngrx/store';
import {
  AssignmentContract,
  AssignmentDetailedContract,
  AssignmentStateEnum,
  isState,
} from '@scheduler-frontend/assignment-contracts';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { AssignmentsSelectors } from './assignments.selectors';

export class AssignmentsPermissionsSelectors {
  public static readonly canMarkAsAbsent = (assignmentId: string) =>
    createSelector(
      AssignmentsSelectors.selectAssignment(assignmentId),
      UsersSelectors.isAdmin,
      AssignmentsPermissionsSelectors.isMarkAsAbsentGranted,
    );

  public static readonly isMarkAsAbsentGranted = (
    assignment: AssignmentContract | AssignmentDetailedContract | undefined,
    isAdmin: boolean,
  ) => {
    if (!assignment) {
      return false;
    }
    if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
      return false;
    }
    if (assignment.bookingPeriodClosed) {
      // Only admins are allowed
      return isAdmin;
    }
    return true;
  };

  public static readonly canMarkAsPresent = (assignmentId: string) =>
    createSelector(
      AssignmentsSelectors.selectAssignment(assignmentId),
      UsersSelectors.isAdmin,
      AssignmentsPermissionsSelectors.isMarkAsPresentGranted,
    );

  public static readonly isMarkAsPresentGranted = (
    assignment: AssignmentContract | AssignmentDetailedContract | undefined,
    isAdmin: boolean,
  ) => {
    if (!assignment) {
      return false;
    }

    if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
      return false;
    }
    if (assignment.bookingPeriodClosed) {
      // Only admins are allowed
      return isAdmin;
    }
    return true;
  };
}
