import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ApproachContract, ApproachMediumEnum } from '@scheduler-frontend/approach-contracts';
import { LevelEnum } from '@scheduler-frontend/enums';

export const schedulingApproachCandidatesActions = createActionGroup({
  source: '[Scheduling/ApproachCandidates]',
  events: {
    approach: props<
      | {
          communicateImmediately: true;
          filters?: {
            businessServices?: string[];
            roles?: string[];
            levels?: LevelEnum[];
            subjects?: string[];
          };
          approach?: string;
          approachThrough?: ApproachMediumEnum[];
          hasAnsweredThePhone?: boolean;
        }
      | {
          candidate?: string;
          communicateImmediately: false;
          filters?: {
            businessServices?: string[];
            roles?: string[];
            levels?: LevelEnum[];
            subjects?: string[];
          };
        }
    >(),
    candidateApproachInitiated: emptyProps(),
    createApproachSuccess: props<
      | { approaches: ApproachContract[]; communicateImmediately: false }
      | {
          approaches: ApproachContract[];
          communicateImmediately: true;
          approachThrough: ApproachMediumEnum[];
          hasAnsweredThePhone: boolean;
        }
    >(),
    createApproachFailure: props<{ error: unknown }>(),

    setActiveApproach: props<{ approach?: string }>(),

    /**
     * On this action the Scheduler actually state that he has approached the candidate.
     * So the selectsSlots and selectedCandidates are cleared. And the approach update is send to the server.
     */
    communicate: props<{
      /**
       * Use this approach or create a new one if not given.
       */
      approach?: string;
      approachThrough: ApproachMediumEnum[];
      hasAnsweredThePhone: boolean;
    }>(),
    communicateFailure: props<{ error: unknown }>(),
    communicateSuccess: props<{ approach: ApproachContract }>(),

    remindCandidate: props<{
      approach?: string;
      approachThrough: ApproachMediumEnum[];
      hasAnsweredThePhone: boolean;
    }>(),
    remindCandidateFailure: props<{ error: unknown }>(),
    remindCandidateSuccess: props<{ approach: ApproachContract }>(),
  },
});
