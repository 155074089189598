<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOriginEl"
  [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayPositions]="position()"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategyOptions.close()"
  (detach)="connectedOverlayDetach()"
>
  <div
    #dialog
    role="dialog"
    aria-label="Dialog"
    data-test="tt-mouseover-dialog"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
