import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CalendarEvent } from '@scheduler-frontend/calendar-contracts';
import { CalendarViewEnum } from '@techniek-team/calendar';
import { denormalize, PlainRangeInterface, TsRange } from '@techniek-team/class-transformer';
import { isDefined } from '@techniek-team/rxjs';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { calendarEventsActions } from './+state/actions/calendar-events.actions';
import { candidateAvailabilitiesActions } from './+state/actions/candidate-availabilities.actions';
import { CalendarEventsSelectors } from './+state/selectors/calendar-events.selectors';
import { CandidateAvailabilitiesSelectors } from './+state/selectors/candidate-availabilities.selectors';
import { CandidateRemarksSelectors } from './+state/selectors/candidate-remarks.selectors';

@Injectable()
export class CandidateCalendarStoreService {
  private readonly store = inject(Store);

  public calendarActiveDate$: Observable<Date> = this.store.pipe(
    select(CalendarEventsSelectors.selectActiveDate),
  );

  public calendarActiveDate = this.store.selectSignal(CalendarEventsSelectors.selectActiveDate);

  public calendarCurrentView$: Observable<CalendarViewEnum> = this.store.pipe(
    select(CalendarEventsSelectors.selectCurrentView),
  );

  public calendarCurrentView = this.store.selectSignal(CalendarEventsSelectors.selectCurrentView);

  public calendarLoadedMonths$: Observable<Date[]> = this.store.pipe(
    select(CandidateAvailabilitiesSelectors.selectLoadedMonths),
  );

  public calendarLoading$: Observable<boolean> = this.store.pipe(
    select(CalendarEventsSelectors.selectLoading),
  );

  public selectedCandidateRemarksLoading$: Observable<boolean> = this.store.pipe(
    select(CandidateRemarksSelectors.loading),
  );

  public selectedSavingCandidateRemark$: Observable<boolean> = this.store.pipe(
    select(CandidateRemarksSelectors.saving),
  );

  public calendarEvents$: Observable<CalendarEvent[]> = this.store.pipe(
    select(CalendarEventsSelectors.selectAll),
    isDefined(),
    map((events) => denormalize(CalendarEvent, events)),
  );

  public calendarEvents = this.store.selectSignal(CalendarEventsSelectors.selectAll);

  public initCalendar(): void {
    this.store.dispatch(
      calendarEventsActions.initCalendarEvents({
        range: new TsRange(
          startOfMonth(subMonths(new Date(), 2)),
          endOfMonth(addMonths(new Date(), 2)),
        ).toObject(),
      }),
    );
  }

  public refreshCalendar(range: PlainRangeInterface<string>): void {
    this.store.dispatch(calendarEventsActions.refreshCalendarEvents({ range: range }));
  }

  public setCalendarActiveDate(date: Date): void {
    this.store.dispatch(calendarEventsActions.setActiveDate({ activeDate: date }));
  }

  public setCalendarView(view: CalendarViewEnum): void {
    this.store.dispatch(calendarEventsActions.setCurrentView({ currentView: view }));
  }

  public appendCandidateAvailabilities(range: PlainRangeInterface<string>): void {
    this.store.dispatch(candidateAvailabilitiesActions.appendAvailabilities({ range: range }));
  }

  public refreshCandidateAvailabilities(range: PlainRangeInterface<string>): void {
    this.store.dispatch(candidateAvailabilitiesActions.refreshAvailabilities({ range: range }));
  }
}
