import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { businessServicesActions } from '@scheduler-frontend/data-access-business-services';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map } from 'rxjs';
import { slotTemplateActions } from '../actions/slot-template.actions';
import { SelectedSelectors } from '../selectors/selected.selectors';

@Injectable()
export class LoadBusinessServiceEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public readonly loadDetailedBusinessServiceOnSelect = createEffect(() => {
    return this.actions$.pipe(
      ofType(slotTemplateActions.changeBusinessService),
      concatLatestFrom(() => [this.store.select(SelectedSelectors.businessService)]),
      filter(([action, businessService]) => {
        return action.change['@id'] !== businessService?.['@id'] || !businessService;
      }),
      map(([action]) => {
        if ('deliveryTypes' in action.change) {
          return slotTemplateActions.changeBusinessServiceSuccess({ change: action.change });
        }
        return businessServicesActions.loadDetailedBusinessService({
          id: jsonLdSelectId(action.change['@id']),
        });
      }),
    );
  });

  public readonly updateSlotTemplateWhenBusinessServiceDetailedIsLoaded = createEffect(() => {
    return this.actions$.pipe(
      ofType(businessServicesActions.loadDetailedBusinessServiceSuccess),
      concatLatestFrom(() => this.store.pipe(select(SelectedSelectors.tempBusinessService))),
      filter(([action, temp]) => {
        return action.detailedBusinessService['@id'] === temp?.['@id'];
      }),
      map(([action]) => {
        return slotTemplateActions.changeBusinessServiceSuccess({
          change: action.detailedBusinessService,
        });
      }),
    );
  });
}
