import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { approachesActions } from '@scheduler-frontend/data-access-approaches';
import { candidatesActions, CandidatesSelectors } from '@scheduler-frontend/data-access-candidates';
import { SchedulingSelectors } from '@scheduler-frontend/data-access-scheduling';
import { isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId, tupleToNonNullableDictionary } from '@techniek-team/tt-ngrx';
import { exhaustMap, firstValueFrom, from, map, takeUntil } from 'rxjs';
import { CandidatesByRankingApi } from '../api/candidates-by-ranking.api';
import { candidatesByRankingActions } from '../candidates-by-ranking.actions';

@Injectable()
export class AlternativeShiftEffect {
  private readonly actions$ = inject(Actions);

  private readonly candidatesByRankingApi = inject(CandidatesByRankingApi);

  private readonly store = inject(Store);

  public showAlternativeShiftsWhenSchedulingByApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      map(() => candidatesByRankingActions.startShownAlternativeShifts()),
    ),
  );

  public hideAlternativeShiftsWhenStoppingSchedulingByApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.stopSchedulingByApproaches),
      map(() => candidatesByRankingActions.stopShownAlternativeShifts()),
    ),
  );

  //eslint-disable-next-line max-lines-per-function
  public loadAlternativeShiftForAlreadyAssignedShift = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.startShownAlternativeShifts),
      //eslint-disable-next-line max-lines-per-function
      exhaustMap(() =>
        this.actions$.pipe(
          ofType(candidatesActions.setActiveCandidate),
          exhaustMap(() => {
            return this.store.pipe(
              select(CandidatesSelectors.activeCandidate),
              isDefined(),
              concatLatestFrom(() =>
                this.store.pipe(select(SchedulingSelectors.alreadyAssignedSlotList), isDefined()),
              ),
              exhaustMap(([candidate, alreadyAssignedSlotList]) => {
                const promises: Promise<[string, SlotDetailedContract[]]>[] = [];
                for (let slot of alreadyAssignedSlotList) {
                  promises.push(
                    firstValueFrom(
                      this.candidatesByRankingApi.getAlternativeSlot(
                        jsonLdSelectId(slot['@id']),
                        jsonLdSelectId(candidate['@id']),
                      ),
                    ).then((alternatives) => [
                      jsonLdSelectId(slot['@id']),
                      alternatives['hydra:member'],
                    ]),
                  );
                }
                return from(Promise.all(promises));
              }),
              map((alternatives) => {
                return candidatesByRankingActions.loadAlternativeShiftsSuccess({
                  items: tupleToNonNullableDictionary(alternatives),
                });
              }),
              takeUntil(
                this.actions$.pipe(ofType(candidatesByRankingActions.stopShownAlternativeShifts)),
              ),
            );
          }),
        ),
      ),
    ),
  );
}
