import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { FetchStorageInterface } from '@techniek-team/fetch';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { subjectsActions } from './+state/subjects.actions';
import { SubjectsSelectors } from './+state/subjects.selectors';
import { SubjectContract } from './contracts/subject.contract';
import { SubjectModel } from './models/subject.model';

@Injectable()
export class SubjectsStoreService implements FetchStorageInterface<SubjectContract, SubjectModel> {
  private readonly store = inject(Store);

  public loading$: Observable<boolean> = this.store.pipe(select(SubjectsSelectors.loading));

  public loaded$: Observable<boolean> = this.store.pipe(select(SubjectsSelectors.loaded));

  public subjectEntities = this.store.selectSignal(SubjectsSelectors.subjectEntities);

  public subjects$ = this.store.pipe(
    select(SubjectsSelectors.subjects),
    isDefined(),
    map((data) => denormalize(SubjectModel, data)),
    shareReplay(),
  );

  public subjectsMap$ = this.store.pipe(
    select(SubjectsSelectors.subjectEntities),
    map((data) => {
      return new Map(
        Object.entries(data).map(([key, item]) => [key, denormalize(SubjectModel, data)]),
      );
    }),
    shareReplay(),
  );

  public subjectsFilterGroup = this.store.selectSignal(SubjectsSelectors.subjectsFilterGroup);

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(subjectsActions.initSubjects());
  }

  public reload(): void {
    this.store.dispatch(subjectsActions.reloadSubjects());
  }

  public supportsFetch(identifier: string): boolean {
    return identifier === 'SubjectModel';
  }

  public async waitForInitialization(): Promise<void> {
    await firstEmitFrom(
      this.store.pipe(
        select(SubjectsSelectors.loaded),
        filter((loaded) => loaded === true),
      ),
    );
  }

  public getFetchFromStorage(value: string): Observable<SubjectModel | undefined> {
    return this.store.pipe(
      select(SubjectsSelectors.denormalizedSubjectEntities),
      map((dict) => dict[jsonLdSelectId(value)]),
      isDefined(),
    );
  }
}
