import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  adapter,
  APPROACHES_TO_DO_FEATURE_KEY,
  ApproachesToDoState,
} from '../reducer/approaches-to-do.reducer';

const { selectAll, selectEntities } = adapter.getSelectors();

export class ApproachesToDoSelectors {
  public static readonly state = createFeatureSelector<ApproachesToDoState>(
    APPROACHES_TO_DO_FEATURE_KEY,
  );

  public static readonly loaded = createSelector(
    ApproachesToDoSelectors.state,
    (state: ApproachesToDoState) => state.loaded,
  );

  public static readonly loading = createSelector(
    ApproachesToDoSelectors.state,
    (state: ApproachesToDoState) => state.loading,
  );

  public static readonly loadedChunks = createSelector(
    ApproachesToDoSelectors.state,
    (state: ApproachesToDoState) => state.loadedChunks,
  );

  public static readonly lastChunkLoaded = createSelector(
    ApproachesToDoSelectors.state,
    (state: ApproachesToDoState) => state.lastChunkLoaded,
  );

  public static readonly error = createSelector(
    ApproachesToDoSelectors.state,
    (state: ApproachesToDoState) => state.error,
  );

  public static readonly approaches = createSelector(
    ApproachesToDoSelectors.state,
    (state: ApproachesToDoState) => (state.loaded ? selectAll(state) : undefined),
  );

  public static readonly approachesEntities = createSelector(
    ApproachesToDoSelectors.state,
    (state: ApproachesToDoState) => selectEntities(state),
  );
}
