import { Directive, TemplateRef } from '@angular/core';

/**
 * Use this directive on a `<ng-template>` to allow easy reference in a
 * component where this item is nested. Use the TemplateOutlet directive to
 * reference the template when defined in the component via ContentChild.
 *
 * ```
 *  <my-nesting-component>
 *    <ng-template templateRef><ion-icon></ion-icon></ng-template>
 *  </my-nesting-component>
 * ```
 *
 * ```
 *  @Component({
 *    template: `
 *      <div *ngIf="iconRef">
 *        <ng-container [ngTemplateOutlet]="iconRef.templateRef"></ng-container>
 *      </div>>
 *    `
 *  })
 *  export class MyNestingComponent {
 *    @ContentChild(TemplateRefDirective) public iconRef: TemplateRefDirective | undefined;
 *  }
 */
//eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[templateRef]', standalone: true })
export class TemplateRefDirective {
  constructor(public templateRef: TemplateRef<unknown>) {
  }
}
