import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { IonicModule, PopoverController } from '@ionic/angular';
import { TsRange } from '@techniek-team/class-transformer';
import { isToday } from 'date-fns';
import { TtDatePickerHeaderComponent } from './tt-date-picker-header/tt-date-picker-header.component';

@Component({
  selector: 'tt-date-picker-popover',
  templateUrl: './tt-date-picker-popover.component.html',
  styleUrls: ['./tt-date-picker-popover.component.scss'],
  standalone: true,
  imports: [
    MatCalendar,
    IonicModule,
  ],
})
export class TtDatePickerPopoverComponent {
  private popoverController = inject(PopoverController);

  protected readonly TtDatePickerHeaderComponent: typeof TtDatePickerHeaderComponent =
    TtDatePickerHeaderComponent;

  @Input() public set date(date: TsRange | DateRange<Date> | Date | null) {
    if (date instanceof TsRange) {
      this.currentDate = date.dateRange;
      return;
    }

    this.currentDate = date;
  };

  @Input() public type: 'single' | 'range' = 'single';

  @Input() public showActions: boolean = true;

  /**
   * The minimum selectable date.
   */
  @Input() public minDate!: Date | null;

  /**
   * The maximum selectable date.
   */
  @Input() public maxDate!: Date | null;

  @Output() public readonly selectedChange = new EventEmitter<
    DateRange<Date> | Date | null | undefined
  >();

  @Output() public readonly cancelClicked = new EventEmitter<void>();

  @Output() public readonly okClicked = new EventEmitter<void>();

  protected currentDate: DateRange<Date> | Date | null = null;

  public dateChanged(date: Date | null): Promise<boolean | void> {
    if (this.type === 'single') {
      this.currentDate = date;
      this.selectedChange.emit(this.currentDate);
      return this.select();
    }

    const range: DateRange<Date> = this.currentDate as DateRange<Date>;
    if (range && range.start && date && date > range.start && !range.end) {
      this.currentDate = new DateRange(range.start, date);
      this.selectedChange.emit(this.currentDate);
      return this.select();
    }

    this.currentDate = new DateRange(date, null);
    return Promise.resolve();
  }

  public async select(event?: Event): Promise<boolean | void> {
    event?.stopImmediatePropagation();
    const overlay = await this.popoverController.getTop();
    if (overlay && this.currentDate) {
      if (this.currentDate instanceof DateRange && this.currentDate.start && this.currentDate.end) {
        return this.popoverController.dismiss(
          new TsRange(this.currentDate.start, this.currentDate.end, true, true),
          'select',
        );
      }
      return this.popoverController.dismiss(this.currentDate, 'select');
    }
    this.selectedChange.emit(this.currentDate);
    this.okClicked.emit();
  }

  public cancel(event: Event): Promise<boolean> {
    event.stopImmediatePropagation();
    this.cancelClicked.emit();
    return this.popoverController.dismiss(undefined, 'cancel');
  }

  public showToday(date: Date): string[] {
    return isToday(date) ? ['today'] : [];
  }

  public get firstDate() {
    if (this.currentDate instanceof DateRange) {
      return this.currentDate.start;
    }
    return this.currentDate;
  }
}
