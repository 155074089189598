import { NgModule } from '@angular/core';
import { TtStatisticCardComponent } from './tt-statistic-card.component';

/**
 * @deprecated use standalone component
 */
@NgModule({
    imports: [
        TtStatisticCardComponent,
    ],
    exports: [TtStatisticCardComponent],
})
export class TtStatisticCardModule {
}
