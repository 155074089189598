import { LevelEnum } from '@scheduler-frontend/enums';
import { Expose } from 'class-transformer';

export class ApproachFilters {
  @Expose() public businessServices!: string[];

  @Expose() public roles!: string[];

  @Expose() public subjects!: string[];

  @Expose() public educationTypes!: LevelEnum[];
}
