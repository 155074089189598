import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApproachesToDoState } from '../reducer/approaches-to-do.reducer';
import {
  adapter,
  APPROACHES_WAITING_FEATURE_KEY,
  ApproachesWaitingState,
} from '../reducer/approaches-waiting.reducer';

const { selectAll, selectEntities } = adapter.getSelectors();

export class ApproachesWaitingSelectors {
  public static readonly state = createFeatureSelector<ApproachesWaitingState>(
    APPROACHES_WAITING_FEATURE_KEY,
  );

  public static readonly loaded = createSelector(
    ApproachesWaitingSelectors.state,
    (state: ApproachesWaitingState) => state.loaded,
  );

  public static readonly loading = createSelector(
    ApproachesWaitingSelectors.state,
    (state: ApproachesWaitingState) => state.loading,
  );

  public static readonly loadedChunks = createSelector(
    ApproachesWaitingSelectors.state,
    (state: ApproachesWaitingState) => state.loadedChunks,
  );

  public static readonly lastChunkLoaded = createSelector(
    ApproachesWaitingSelectors.state,
    (state: ApproachesWaitingState) => state.lastChunkLoaded,
  );

  public static readonly error = createSelector(
    ApproachesWaitingSelectors.state,
    (state: ApproachesWaitingState) => state.error,
  );

  public static readonly approaches = createSelector(
    ApproachesWaitingSelectors.state,
    (state: ApproachesToDoState) => (state.loaded ? selectAll(state) : undefined),
  );

  public static readonly approachesEntities = createSelector(
    ApproachesWaitingSelectors.state,
    (state: ApproachesWaitingState) => selectEntities(state),
  );
}
