import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Injector,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, ModalController, PopoverController } from '@ionic/angular';
import { TsRange } from '@techniek-team/class-transformer';
import { setIconValidationClasses } from '@techniek-team/common';
import { format } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { TtDatePickerModalComponent } from '../tt-date-picker-modal/tt-date-picker-modal.component';
import { TtDatePickerPopoverComponent } from '../tt-date-picker-popover/tt-date-picker-popover.component';

type OnChangeCallback = (value: TsRange | Date | null) => void;
type OnCallback = () => void;

@Component({
  selector: 'tt-date-picker-input',
  templateUrl: './tt-date-picker-input-control.component.html',
  styleUrls: ['./tt-date-picker-input-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtDatePickerInputControlComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    IonicModule,
    ReactiveFormsModule
],
})
export class TtDatePickerInputControlComponent
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  /**
   * Represents the fill style of a shape.
   */
  @Input() public fill?: 'outline' | 'solid';

  /**
   * Formats a date value according to locale rules.
   *
   * `DatePipe` is executed only when it detects a pure change to the input value.
   * A pure change is either a change to a primitive input value
   * (such as `String`, `Number`, `Boolean`, or `Symbol`),
   * or a changed object reference (such as `Date`, `Array`, `Function`, or `Object`).
   *
   * Note that mutating a `Date` object does not cause the pipe to be rendered again.
   * To ensure that the pipe is executed, you must create a new `Date` object.
   *
   * Only the `en-US` locale data comes with Angular. To localize dates
   * in another language, you must import the corresponding locale data.
   * See the [I18n guide](guide/i18n-common-format-data-locale) for more information.
   *
   * The time zone of the formatted value can be specified either by passing it in as the second
   * parameter of the pipe, or by setting the default through the `DATE_PIPE_DEFAULT_OPTIONS`
   * injection token. The value that is passed in as the second parameter takes precedence over
   * the one defined using the injection token.
   */
  @Input() public format: string = 'EEEE d MMMM yyyy';

  /**
   * choose single if you want to select a single date and range if you want to select
   * a range
   */
  @Input() public type: 'single' | 'range' = 'single';

  /**
   * A placeholder for a string value.
   *
   * @typedef {string} Placeholder
   * @description Represents a placeholder value that can be replaced with an actual string.
   */
  @Input() public placeholder?: string;

  /**
   * Represents a label.
   *
   * @typedef {string} Label
   */
  @Input() public label?: string;

  @Input() public labelPlacement?: string;

  @Input() public ariaLabel?: string;

  /**
   * The color to use from your application's color palette. Default options are: `"primary"`, `"secondary"`,
   * `"tertiary"`, `"success"`, `"warning"`, `"danger"`, `"light"`, `"medium"`, and `"dark"`. For more information on
   * colors, see [theming](/docs/theming/basics).
   */
  @Input() public color?: string;

  /**
   * Determines whether the content should be wrapped with an ion-item.
   */
  @Input() public wrapWithItem: boolean = true;

  /**
   * Represents the type of interface, either a ion-popover or a ion-modal.
   */
  @Input() public interface: 'popover' | 'modal' = 'modal';

  /**
   * Represents the presence or absence of an icon.
   *
   *  The `noIcon` variable indicates whether an icon should be displayed or not.
   * It is a boolean value that is set to `false` by default.
   */
  @Input() public noIcon: boolean = false;

  /**
   * The minimum selectable date.
   */
  @Input() public minDate?: Date;

  /**
   * The maximum selectable date.
   */
  @Input() public maxDate?: Date;

  protected dateControl: FormControl<string | null> = new FormControl<
    string | null
  >(null);

  private date$ = new BehaviorSubject<TsRange | Date | null>(null);

  private modal!: HTMLIonModalElement | HTMLIonPopoverElement;

  constructor(
    private injector: Injector,
    private elementRef: ElementRef,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    this.date$.subscribe((date) => {
      this.dateControl.setValue(this.dateToFormattedString(date));
      this.onChange(date);
    });
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    setIconValidationClasses(this.injector, this.elementRef);
  }

  /**
   * @inheritDoc
   */
  public onChange: OnChangeCallback = (_value: TsRange | Date | null) => {
    /* callback */
  };

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: OnChangeCallback): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public onTouch: OnCallback = () => {
    /* callback */
  };

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: OnCallback): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateControl.disable();
    } else {
      this.dateControl.enable();
    }
  }

  /**
   * @inheritDoc
   */
  public writeValue(date: Date): void {
    this.date$.next(date);

    this.dateControl.reset(this.dateToFormattedString(date));
  }

  @HostListener('click', ['$event'])
  public async openDatePicker(event: Event): Promise<void> {
    if (this.dateControl.disabled || this.modal?.isOpen) {
      return;
    }
    this.dateControl.markAsTouched();
    this.dateControl.markAsDirty();
    this.onTouch();

    const ionInput: HTMLElement | null =
      this.elementRef.nativeElement.querySelector('ion-input');
    if (ionInput) {
      this.renderer.addClass(ionInput, 'has-focus');
    }

    const color: string = this.color ? `ion-color-${this.color}` : '';

    if (this.interface === 'modal') {
      await this.createModal(color);
    } else {
      await this.createPopover(color, event);
    }
    this.initOnModalDismiss(ionInput);

    return this.modal.present();
  }

  private initOnModalDismiss(ionInput: HTMLElement | null): void {
    this.modal.onDidDismiss().then((overlayEvent) => {
      if (overlayEvent.data !== undefined) {
        this.date$.next(overlayEvent.data);
        this.onTouch();
        this.dateControl.markAsDirty();
        this.dateControl.markAsTouched();
        this.dateControl.updateValueAndValidity();
      }
      if (ionInput) {
        this.renderer.removeClass(ionInput, 'has-focus');
      }
    });
  }

  private async createPopover(color: string, event: Event): Promise<void> {
    this.modal = await this.popoverController.create({
      component: TtDatePickerPopoverComponent,
      cssClass: `tt-date-picker-popover ${color}`,
      event: event,
      reference: 'event',
      showBackdrop: false,
      side: 'bottom',
      alignment: 'start',
      componentProps: {
        date: this.date$.getValue(),
        minDate: this.minDate,
        maxDate: this.maxDate,
        type: this.type,
      },
    });
  }

  private async createModal(color: string): Promise<void> {
    this.modal = await this.modalController.create({
      component: TtDatePickerModalComponent,
      cssClass: `tt-date-picker-modal ${color}`,
      showBackdrop: true,
      componentProps: {
        date: this.date$.getValue(),
        minDate: this.minDate,
        maxDate: this.maxDate,
        type: this.type,
      },
    });
  }

  private dateToFormattedString(date: TsRange | Date | null): string | null {
    if (!date) {
      return null;
    }

    if (date instanceof TsRange) {
      if (date.inclusiveEnd) {
        return date.format(this.format, ' t/m ')
      }

      return date.format(this.format, ' - ');
    }

    return format(date, this.format);
  }
}
