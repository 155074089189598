interface WhatsAppDataInput {
  date: string;
  role: string;
  subject: string;
  level: string;
  location: string;
}
export type WhatsAppDataStringsInput = WhatsAppDataInput & Record<string, string>;

function template<Type extends Record<string, string>, Key extends string & keyof Type>(
  strings: TemplateStringsArray,
  ...keys: Key[]
) {
  return (values: Type) => {
    const result: string[] = [strings[0]];
    keys.forEach((key, i) => {
      const value = values[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
}

export function whatsAppTemplateApproach(values: {
  candidateName: string;
  userName: string;
  data: WhatsAppDataStringsInput[];
}): string {
  return startTemplate({
    candidateName: values.candidateName,
    userName: values.userName,
    assignments: values.data
      .map((data: WhatsAppDataStringsInput) => assignmentTemplate(data).trim())
      .join('\n'),
  });
}

export const startTemplate = template`Beste ${'candidateName'},
In de komende periode hebben we meerdere diensten openstaan, die aansluiten bij jouw profiel en vaardigheden.
Heb jij interesse om bij een van onderstaande diensten aan de slag te gaan?
${'assignments'}
Ben jij in 1 of meerdere geïnteresseerd? Groet, ${'userName'}
`;

export const assignmentTemplate = template`- ${'date'}, ${'role'} ${'subject'} ${'level'} in ${'location'}`;
