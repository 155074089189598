import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { TtModalTitleComponent } from './tt-modal-title/tt-modal-title.component';
import { TtModalComponent } from './tt-modal.component';

/**
 * @deprecated use standalone component
 */
@NgModule({
    imports: [
        TtModalComponent,
        TtModalTitleComponent,
    ],
    exports: [
        TtModalComponent,
        TtModalTitleComponent,
    ],
})
export class TtModalModule {}
