import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, ToggleCustomEvent } from '@ionic/angular';
import { AssignmentsStoreService } from '@scheduler-frontend/data-access-assignment';

@Component({
  selector: 'app-edit-payout',
  standalone: true,
  imports: [FormsModule, IonicModule, ReactiveFormsModule],
  templateUrl: './edit-payout.component.html',
  styleUrls: ['./edit-payout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPayoutComponent {
  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected updateAssignmentPayout(
    type: 'mustPerformCompensation' | 'automaticTravelCompensation',
    event: ToggleCustomEvent,
  ) {
    this.assignmentsStoreService.updateAssignment({
      [type]: event.detail.checked as boolean,
    });
  }
}
