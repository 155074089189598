/**
 * Enum containing they different level on which we teach to pupils.
 *
 * Some levels are not listed in the enum, because although they are valid option for the scheduler-api these levels are
 * levels in which don't teach at the moment. Most of them are only there because there used on other application
 * which use the same enum.
 *
 * The missing items are: `VMBO`, `VMBO_GL`, `MBO`, `ANY`, `UNKNOWN`
 *
 */

export enum LevelEnum {
  PO = 'PO',
  VMBO_B = 'VMBO B',
  VMBO_K = 'VMBO K',
  VMBO_TL = 'VMBO TL',
  HAVO = 'HAVO',
  VWO = 'VWO',
  HBO = 'HBO',
  WO = 'WO',
  ANY = 'ANY',
}

export type ChooseableLevelEnum = Exclude<LevelEnum, LevelEnum.ANY>;

export enum LevelDisplayValues {
  PO = 'po',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  'VMBO B' = 'vmbo-b',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  'VMBO K' = 'vmbo-k',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  'VMBO TL' = 'vmbo-tl',
  HAVO = 'havo',
  VWO = 'vwo',
  HBO = 'hbo',
  WO = 'wo',
}

export function levelSearchQueryMapper() {
  return {
    'lesson.level': {
      entities: LevelDisplayValues,
      getDisplayText: (level: string) => level,
    },
  };
}
