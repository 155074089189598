import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CandidateContract } from '@scheduler-frontend/candidate-contracts';

export const candidatesSearchActions = createActionGroup({
  source: 'Candidate/Search',
  events: {
    setCandidateSearchQuery: props<{ query?: string }>(),
    clearSearchQuery: emptyProps(),
    loadCandidateSearch: emptyProps(),
    loadCandidateSearchSuccess: props<{
      candidates: CandidateContract[];
      chunk: number;
      totalItems: number;
    }>(),
    loadCandidateSearchFailure: props<{ error: Error }>(),
    refresh: emptyProps(),
    loadCandidateSearchNextChunk: emptyProps(),
    loadCandidateSearchNextChunkSuccess: props<{
      candidates: CandidateContract[];
      chunk: number;
      totalItems: number;
    }>(),
  },
});
