import { isBefore, parseISO } from 'date-fns';

export class Token {
  constructor(
    public tokenType: string,
    public accessToken: string,
    public expiresIn: number,
    public refreshToken: string
  ) {}

  public isExpired(): boolean {
    return isBefore(parseISO(this.expiresIn.toString()), new Date());
  }
}
