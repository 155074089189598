import { inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity/src/models';
import { select, Store } from '@ngrx/store';
import {
  Approach,
  ApproachAssigneeFilter,
  ApproachContract,
  ApproachDetailed,
} from '@scheduler-frontend/approach-contracts';
import {
  SlotContract,
  SlotDetailedWithAssignmentHasSlot,
} from '@scheduler-frontend/assignment-contracts';
import { denormalize } from '@techniek-team/class-transformer';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { approachesHandledActions } from './+state/actions/approaches-handled.actions';
import { approachesToDoActions } from './+state/actions/approaches-to-do.actions';
import { approachesWaitingActions } from './+state/actions/approaches-waiting.actions';
import { approachesActions } from './+state/actions/approaches.actions';
import { ApproachesHandledSelectors } from './+state/selectors/approaches-handled.selectors';
import { ApproachesToDoSelectors } from './+state/selectors/approaches-to-do.selectors';
import { ApproachesWaitingSelectors } from './+state/selectors/approaches-waiting.selectors';
import { ApproachesSelectors } from './+state/selectors/approaches.selectors';

@Injectable()
export class ApproachesStoreService {
  private readonly store = inject(Store);

  private readonly actions = inject(Actions);
  public loading$: Observable<boolean> = this.store.pipe(select(ApproachesSelectors.loading));

  public loaded$: Observable<boolean> = this.store.pipe(select(ApproachesSelectors.loaded));

  public saving$: Observable<boolean> = this.store.pipe(select(ApproachesSelectors.saving));

  public activeApproach$ = this.store.pipe(
    select(ApproachesSelectors.activeApproach),
    map((approach) => {
      if (!approach) {
        return undefined;
      }

      if ('approachHasSlots' in approach) {
        return denormalize(ApproachDetailed, approach);
      }

      return denormalize(Approach, approach);
    }),
  );

  public getApproachById$(approachId: string) {
    return this.store.select(ApproachesSelectors.selectApproach(approachId)).pipe(
      map((approach) => {
        if (!approach) {
          return undefined;
        }

        if ('approachHasSlots' in approach) {
          return denormalize(ApproachDetailed, approach);
        }

        return denormalize(Approach, approach as ApproachContract);
      }),
      shareReplay(),
    );
  }

  public getDetailedApproachById$(approachId: string): Observable<ApproachDetailed> {
    return this.getApproachById$(approachId).pipe(
      filter((approach): approach is ApproachDetailed => {
        return approach instanceof ApproachDetailed;
      }),
      shareReplay(),
    );
  }

  public getSlotsGroupedByDateAndAssignmentForApproach$(approachId: string) {
    return this.store
      .select(ApproachesSelectors.slotListGroupedByDateAndAssignmentForApproach(approachId))
      .pipe(
        isDefined(),
        map((items) => {
          return new Map(
            Object.entries(items as Dictionary<Dictionary<SlotContract[]>>).map(
              ([dateKey, value]) => {
                return [
                  dateKey,
                  new Map(
                    Object.entries(value as Dictionary<SlotContract[]>).map(
                      ([assignmentKey, slots]) => {
                        return [
                          assignmentKey,
                          denormalize(SlotDetailedWithAssignmentHasSlot, slots as SlotContract[]),
                        ];
                      },
                    ),
                  ),
                ];
              },
            ),
          );
        }),
        shareReplay(1),
      );
  }

  public generatedWhatsappMessage$ = this.store.pipe(
    select(ApproachesSelectors.whatsappMessage),
    shareReplay(),
  );

  public activeAssigneeFilter$ = this.store.pipe(
    select(ApproachesSelectors.selectedAssigneeFilter),
    shareReplay(),
  );

  public activeCandidateSearch$ = this.store.pipe(
    select(ApproachesSelectors.selectedCandidateSearch),
    shareReplay(),
  );

  public loadingToDo$: Observable<boolean> = this.store.pipe(
    select(ApproachesToDoSelectors.loading),
  );

  public loadedToDo$: Observable<boolean> = this.store.pipe(select(ApproachesToDoSelectors.loaded));

  public lastChunkLoadedToDo$: Observable<boolean> = this.store.pipe(
    select(ApproachesToDoSelectors.lastChunkLoaded),
  );

  public approachesToDo$: Observable<Approach[]> = this.store.pipe(
    select(ApproachesToDoSelectors.approaches),
    isDefined(),
    map((approaches: ApproachContract[]) => {
      return approaches.map((approach: ApproachContract) => denormalize(Approach, approach));
    }),
  );

  public loadingWaiting$: Observable<boolean> = this.store.pipe(
    select(ApproachesWaitingSelectors.loading),
  );

  public loadedWaiting$: Observable<boolean> = this.store.pipe(
    select(ApproachesWaitingSelectors.loaded),
  );

  public lastChunkLoadedWaiting$: Observable<boolean> = this.store.pipe(
    select(ApproachesWaitingSelectors.lastChunkLoaded),
  );

  public approachesWaiting$: Observable<Approach[]> = this.store.pipe(
    select(ApproachesWaitingSelectors.approaches),
    isDefined(),
    map((approaches: ApproachContract[]) => {
      return approaches.map((approach: ApproachContract) => denormalize(Approach, approach));
    }),
    shareReplay(),
  );

  public loadingHandled$: Observable<boolean> = this.store.pipe(
    select(ApproachesHandledSelectors.loading),
  );

  public loadedHandled$: Observable<boolean> = this.store.pipe(
    select(ApproachesHandledSelectors.loaded),
  );

  public lastChunkLoadedHandled$: Observable<boolean> = this.store.pipe(
    select(ApproachesHandledSelectors.lastChunkLoaded),
  );

  public approachesHandled$: Observable<Approach[]> = this.store.pipe(
    select(ApproachesHandledSelectors.approaches),
    isDefined(),
    map((approaches: ApproachContract[]) => {
      return approaches.map((approach: ApproachContract) => denormalize(Approach, approach));
    }),
    shareReplay(),
  );

  public setActiveAssigneeFilter(assigneeFilter: ApproachAssigneeFilter): void {
    this.store.dispatch(approachesActions.setActiveAssigneeFilter({ filter: assigneeFilter }));
  }

  public setActiveCandidateSearch(value: string): void {
    this.store.dispatch(approachesActions.setActiveCandidateSearch({ value: value }));
  }

  public initApproachesLists(): void {
    this.store.dispatch(approachesToDoActions.loadApproaches());
    this.store.dispatch(approachesWaitingActions.loadApproaches());
    this.store.dispatch(approachesHandledActions.loadApproaches());
  }

  public initApproachesToDo(): void {
    this.store.dispatch(approachesToDoActions.loadApproaches());
  }

  public initApproachesWaiting(): void {
    this.store.dispatch(approachesWaitingActions.loadApproaches());
  }

  public initApproachesHandled(): void {
    this.store.dispatch(approachesHandledActions.loadApproaches());
  }

  public async nextApproachesToDo(): Promise<void> {
    if (!(await firstEmitFrom(this.lastChunkLoadedToDo$))) {
      this.store.dispatch(approachesToDoActions.loadNextChunk());
      return firstEmitFrom(this.actions.pipe(ofType(approachesToDoActions.loadNextChunkSuccess)));
    }
  }

  public async nextApproachesWaiting(): Promise<void> {
    if (!(await firstEmitFrom(this.lastChunkLoadedWaiting$))) {
      this.store.dispatch(approachesWaitingActions.loadNextChunk());
      return firstEmitFrom(
        this.actions.pipe(ofType(approachesWaitingActions.loadNextChunkSuccess)),
      );
    }
  }

  public async nextApproachesHandled(): Promise<void> {
    if (!(await firstEmitFrom(this.lastChunkLoadedHandled$))) {
      this.store.dispatch(approachesHandledActions.loadNextChunk());
      return firstEmitFrom(
        this.actions.pipe(ofType(approachesHandledActions.loadNextChunkSuccess)),
      );
    }
  }

  public markApproachAsHandled(): void {
    this.store.dispatch(approachesActions.markApproachAsHandled());
  }

  public markApproachAsExpired(): void {
    this.store.dispatch(approachesActions.markApproachAsExpired());
  }

  public startSchedulingByApproaches(): void {
    this.store.dispatch(approachesActions.startSchedulingByApproaches());
  }

  public stopSchedulingByApproaches(): void {
    this.store.dispatch(approachesActions.stopSchedulingByApproaches());
  }
}
