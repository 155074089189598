import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DeliveryTypeSelectors } from './+state/selectors/delivery-type.selector';
import { RequirementsSelectors } from './+state/selectors/requirements.selectors';

@Injectable()
export class CreateSlotsRequirementsStoreService {
  private readonly store = inject(Store);

  public mustSetNumberOfPupils = this.store.selectSignal(
    RequirementsSelectors.mustSetNumberOfPupils,
  );

  public mayHaveDifferentOperationalLocation = this.store.selectSignal(
    RequirementsSelectors.mayHaveDifferentOperationalLocation,
  );

  public mustHaveSubject = this.store.selectSignal(RequirementsSelectors.mustHaveSubject);

  public mustHaveLevel = this.store.selectSignal(RequirementsSelectors.mustHaveLevel);

  public mustHaveDeliveryType = this.store.selectSignal(RequirementsSelectors.mustHaveDeliveryType);

  public mustHavePredefinedLesson = this.store.selectSignal(
    RequirementsSelectors.mustHavePredefinedLesson,
  );

  public isAtHome = this.store.selectSignal(DeliveryTypeSelectors.isAtHome);
}
