import { createSelector } from '@ngrx/store';
import {
  AssignmentStateEnum,
  isState,
  SlotDetailedContract,
  SlotDetailedWithAssignmentHasSlot,
} from '@scheduler-frontend/assignment-contracts';
import { isBookingPeriodClosed } from '@scheduler-frontend/booking-service';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';

export class SlotsPermissionsSelectors {
  public static canUpdate = (slot: SlotDetailedContract) =>
    createSelector(UsersSelectors.isAdmin, (isAdmin) => {
      if (slot?.assignmentHasSlot?.assignment) {
        if (isState(slot.assignmentHasSlot.assignment.state, AssignmentStateEnum.APPROVED)) {
          return false;
        }
      }

      if (isBookingPeriodClosed(slot)) {
        // only admin can update in closed booking period
        return isAdmin;
      }
      return true;
    });

  public static canMarkAsAbsent = (
    slot: SlotDetailedContract | SlotDetailedWithAssignmentHasSlot,
  ) =>
    createSelector(UsersSelectors.isAdmin, (isAdmin) => {
      if (!slot?.assignmentHasSlot?.assignment || !slot?.assignmentHasSlot?.assignment.candidate) {
        return false;
      }

      if (this.isParentSlot(slot)) {
        return false;
      }

      if (isState(slot.assignmentHasSlot.assignment.state, AssignmentStateEnum.APPROVED)) {
        return false;
      }

      if (isBookingPeriodClosed(slot)) {
        return isAdmin;
      }

      return true;
    });

  public static canMarkAsPresent = (
    slot: SlotDetailedContract | SlotDetailedWithAssignmentHasSlot,
  ) =>
    createSelector(UsersSelectors.isAdmin, (isAdmin) => {
      if (!slot?.assignmentHasSlot?.assignment || !slot.assignmentHasSlot.assignment.candidate) {
        return false;
      }

      if (this.isParentSlot(slot)) {
        return false;
      }

      if (isState(slot.assignmentHasSlot.assignment.state, AssignmentStateEnum.APPROVED)) {
        return false;
      }

      if (isBookingPeriodClosed(slot)) {
        return isAdmin;
      }

      return true;
    });

  private static isParentSlot(
    slot: SlotDetailedContract | SlotDetailedWithAssignmentHasSlot,
  ): boolean {
    return slot.displayAsCombined && !slot.isCombined;
  }
}
