import { createActionGroup, props } from '@ngrx/store';
import { CandidateMinimalContract } from '@scheduler-frontend/data-access-users';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { TsRangeInterface } from '@techniek-team/common';

export const absenceActions = createActionGroup({
  source: '[Absence]',
  events: {
    markAsAbsent: props<{
      assignmentHasSlots: string[];
      absencePeriod: PlainRangeInterface<string>;
      candidate: string;
    }>(),
    markAsAbsentSuccess: props<{
      assignmentHasSlots: string[];
      absencePeriod?: TsRangeInterface<string>;
      candidate: CandidateMinimalContract;
    }>(),
    markAsAbsentFailure: props<{
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any;
      candidate: CandidateMinimalContract;
    }>(),
    markAsPresent: props<{ assignmentHasSlot: string; candidate?: string }>(),
    markAsPresentSuccess: props<{
      assignmentHasSlot: string;
      candidate: CandidateMinimalContract;
    }>(),
    markAsPresentFailure: props<{
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any;
      candidate: CandidateMinimalContract;
    }>(),
  },
});
