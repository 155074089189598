import { hashParams } from '@scheduler-frontend/common';
import { JsonLdInterface } from '@techniek-team/api-platform';
import { sortChain } from '@techniek-team/common';
import { NonNullableDictionary } from '@techniek-team/tt-ngrx';
import { compareDesc } from 'date-fns';
import { memoize } from 'lodash-es';

export interface ScheduleGroup {
  slots: NonNullableDictionary<JsonLdInterface[]>;
}

function sortByDateAndExtraSortOption<T>(
  activities: T[],
  getEnd: (a: T) => number,
  extraSort?: (a: T, b: T) => number,
): void {
  if (extraSort) {
    activities.sort((a, b) =>
      sortChain(a, b).pipe((itemA, itemB) => {
        return compareDesc(getEnd(itemA), getEnd(itemB));
      }, extraSort),
    );
    return;
  }

  activities.sort((a, b) => compareDesc(getEnd(a), getEnd(b)));
}

function selectMostEfficientGroupsByDate<T>(
  itemsInDescendingOrder: T[],
  getStart: (a: T) => number,
  getEnd: (a: T) => number,
): [T[], T[]] {
  let selectedActivities: T[] = [];
  let end: number = 0;
  for (let activity of itemsInDescendingOrder.reverse()) {
    if (getStart(activity) > end) {
      selectedActivities.push(activity);
      end = getEnd(activity);
    }
  }

  let unselectedActivities = itemsInDescendingOrder.filter(
    (activity) => !selectedActivities.includes(activity),
  );

  return [selectedActivities, unselectedActivities];
}

export const groupIntoMostEfficientGroupsByDate = memoize(
  <T extends JsonLdInterface | ScheduleGroup>(
    activities: T[],
    getStart: (a: T) => number,
    getEnd: (a: T) => number,
    extraSort?: (a: T, b: T) => number,
  ): T[][] => {
    sortByDateAndExtraSortOption(activities, getEnd, extraSort);

    let selections: T[][] = [];
    let unselected: T[] = [...activities];

    let count = 0;
    while (unselected.length > 0) {
      let [selected, rest] = selectMostEfficientGroupsByDate(unselected, getStart, getEnd);
      unselected = rest;
      selections.push(selected);
      if (count > 500) {
        throw new Error('tried selected more than 500 times, this is way to many');
      }
      count++;
    }
    return selections;
  },
  (activities: (JsonLdInterface | ScheduleGroup)[], getStart, getEnd) =>
    hashParams(
      activities.map((item) => {
        if ('@id' in item) {
          return item;
        }
        return Object.values(item.slots).map((slotArray) => slotArray.map((slot) => slot));
      }),
    ),
);
