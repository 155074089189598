import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { OAuthService } from '../shared/oauth/oauth.service';

export async function pkceAuthentication(
  route: ActivatedRouteSnapshot,
): Promise<boolean | UrlTree> {
  const oauthService = inject(OAuthService);
  const authCodePKCE: string = route.queryParams['code'];
  let params: Params = { ...route.queryParams };
  delete params['code'];

  if (authCodePKCE) {
    await firstValueFrom(oauthService.exchangeAuthCodePKCE(authCodePKCE), {
      defaultValue: undefined,
    });
    // we do not want to display the code in the address bar so
    // do a redirect to the home or save origin page including the left
    // over queryParameters.
    return oauthService.getRedirectAfterLoginUrlTree({
      queryParams: params,
    });
  }

  return true;
}
