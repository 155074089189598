import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { Release } from '@scheduler-frontend/models';
import { TtModalModule } from '@techniek-team/components/modal';

@Component({
  selector: 'app-whats-new-modal',
  templateUrl: './whats-new-modal.component.html',
  styleUrls: ['./whats-new-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TtModalModule, NgOptimizedImage],
})
export class WhatsNewModalComponent {
  protected readonly modalController = inject(ModalController);
  @Input() public releases: Release[] = [];
}
