import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RANKINGS_FEATURE_KEY, RankingsState, rankingsAdapter } from './rankings.reducer';

const { selectAll, selectEntities } = rankingsAdapter.getSelectors();

export class RankingsSelectors {
  public static readonly rankingsState = createFeatureSelector<RankingsState>(RANKINGS_FEATURE_KEY);

  public static readonly rankingsLoading = createSelector(
    RankingsSelectors.rankingsState,
    (state: RankingsState) => state.loading,
  );

  public static readonly rankingsLoaded = createSelector(
    RankingsSelectors.rankingsState,
    (state: RankingsState) => state.loaded,
  );

  public static readonly rankingsError = createSelector(
    RankingsSelectors.rankingsState,
    (state: RankingsState) => state.error,
  );

  public static readonly rankings = createSelector(
    RankingsSelectors.rankingsState,
    (state: RankingsState) => selectAll(state),
  );

  public static readonly rankingEntities = createSelector(
    RankingsSelectors.rankingsState,
    (state: RankingsState) => selectEntities(state),
  );
}
