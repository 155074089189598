import { AssignmentStateEnum } from '@scheduler-frontend/assignment-contracts';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { formatISO, isValid, max, min, parse, parseISO } from 'date-fns';
import { memoize } from 'lodash-es';
import { CustomLabels } from './custom.labels';

export interface ParsedSearchExpression {
  [param: string]:
    | PlainRangeInterface<string | number>
    | string
    | number
    | readonly (string | number)[];
}

function findValidDate(date?: string): string | undefined {
  if (typeof date !== 'string') {
    return undefined;
  }
  if (isValid(parseISO(date))) {
    return date;
  }

  const formats = ['yyyy-MM-dd', 'yyyy-MM-dd HH:mm:ss'];
  for (const format of formats) {
    const parsedDate = parse(date, format, new Date());
    if (isValid(parsedDate)) {
      return date;
    }
  }
  return undefined;
}

class ExpressionParser {
  public static readonly expressionRegex: RegExp = /\s*(and\b|or\b|xor\b)\s*/i;

  public static readonly propertyRegex: RegExp = /([^\s=<>]*?) ?(<=|>=|>|<|=) ?(.*)/i;

  public static readonly functionCallRegex: RegExp = /([a-z_-]+)\(([^)]+)\)/i;

  public static readonly arrayRegex: RegExp = /\[(.*)]/;

  public static readonly stringRegex: RegExp = /'(.*)'/;

  private gatheredData: ParsedSearchExpression = {};

  constructor(private expression: string) {
    this.expression = expression;
  }

  public parse(): ParsedSearchExpression {
    this.gatherCustomExpressions();

    const expressions: string[] = this.expression.split(ExpressionParser.expressionRegex);
    for (let expression of expressions) {
      this.parseAndGatherData(expression);
    }
    return this.gatheredData;
  }

  private parseAndGatherData(expression: string): void {
    const [parseMatch, subject, data] = this.parseExpression(expression);
    if (!parseMatch) {
      return;
    }

    if (this.gatherNumberOfPupils(subject, data)) {
      return;
    }

    if (this.gatherTimePeriod(subject, data)) {
      return;
    }

    this.gatherActiveItemIds(subject, data);
  }

  private extractValue(parts: RegExpExecArray): string | string[] {
    let data: string | string[] = parts[3];
    if (ExpressionParser.arrayRegex.test(parts[3])) {
      data = parts[3]
        .slice(1, -1)
        .split(/\s*,\s*/)
        .map((item) => item.replace(/["'`]*/gi, ''));
    } else if (ExpressionParser.stringRegex.test(parts[3])) {
      data = parts[3].replace(/["'`]*/gi, '');
    }
    return data;
  }

  private gatherNumberOfPupils(subject: string, data: string | string[]): boolean {
    const lastPartOfSubject = subject.split('.').pop();
    if (lastPartOfSubject === 'numberOfPupils') {
      // We have to check if there is already data that is a number, as the number 0 will result into false
      // when just checking for 'this.gatheredData[subject]'.
      if (typeof this.gatheredData[subject] === 'number') {
        this.gatheredData[subject] = {
          start: Math.min(this.gatheredData[subject] as number, parseInt(data as string, 10)),
          end: Math.max(this.gatheredData[subject] as number, parseInt(data as string, 10)),
          inclusiveStart: true,
          inclusiveEnd: false,
        } as PlainRangeInterface<number>;
        return true;
      }
      this.gatheredData[subject] = parseInt(data as string, 10);
      return true;
    }
    return false;
  }

  private gatherTimePeriod(subject: string, data: string | string[]): boolean {
    const lastPartOfSubject = subject.split('.').pop();
    if (
      lastPartOfSubject === 'timePeriod' &&
      !subject.startsWith('lower') &&
      !subject.startsWith('upper')
    ) {
      throw new Error('Cannot convert time period correctly in search expression!');
    }
    let date = findValidDate(data as string);
    if (!date) {
      // is the subject state that it a date we return true if the date itself in invalid
      // otherwise is we result into parsing errors later on
      return lastPartOfSubject === 'date' || lastPartOfSubject === 'timePeriod';
    }
    if (lastPartOfSubject === 'date' || lastPartOfSubject === 'timePeriod') {
      if (this.gatheredData[subject]) {
        this.gatheredData[subject] = {
          start: formatISO(min([this.gatheredData[subject] as string, date])),
          end: formatISO(max([this.gatheredData[subject] as string, date])),
          inclusiveStart: true,
          inclusiveEnd: false,
        };
        return true;
      }
      this.gatheredData[subject] = date;
      return true;
    }
    return false;
  }

  private gatherActiveItemIds(subject: string, data: string | string[]) {
    if (subject === 'slot.candidate' && data === 'NULL') {
      this.gatheredData[subject] = AssignmentStateEnum.UNASSIGNED;
    } else {
      this.gatheredData[subject] = data;
    }
  }

  private gatherCustomExpressions(): void {
    for (let [customLabel, item] of CustomLabels) {
      if (this.expression.includes(item.value)) {
        if (this.gatheredData['custom_labels']) {
          (this.gatheredData['custom_labels'] as string[]).push(customLabel);
          // Remove from the expression, so we don't try to render another chip for it.
          this.expression = this.expression.replace(item.value, '');
          continue;
        }

        this.gatheredData['custom_labels'] = [item.value];
        // Remove from the expression, so we don't try to render another chip for it.
        this.expression = this.expression.replace(item.value, '');
      }
    }
  }

  private parseExpression(expression: string): [boolean, string, string | string[], string[]] {
    if (ExpressionParser.propertyRegex.test(expression)) {
      const parts: RegExpExecArray | null = ExpressionParser.propertyRegex.exec(expression);
      if (!parts || parts[1] === 'schedule.phase') return [false, '', [], parts ?? []];
      let functionCallParams: RegExpExecArray | null = ExpressionParser.functionCallRegex.exec(
        parts[1],
      );
      let subject = functionCallParams ? functionCallParams[2] : parts[1];
      let data = this.extractValue(parts);
      return [true, subject, data, parts ?? []];
    }
    return [false, '', [], []];
  }
}

export const searchExpressionToObject = memoize((query: string): ParsedSearchExpression => {
  const parser = new ExpressionParser(query);
  return parser.parse();
});
