<ion-card>
  <mat-calendar
    data-test="tt-date-picker-popover-calendar"
    [headerComponent]="TtDatePickerHeaderComponent"
    [selected]="currentDate"
    [startAt]="firstDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateClass]="showToday"
    (blur)="$event.stopImmediatePropagation()"
    (selectedChange)="dateChanged($event)"
  ></mat-calendar>

  @if (showActions) {
    <div class="button-container">
      <ion-button
        data-test="tt-date-picker-popover-cancel-button"
        (click)="cancel($event)"
        size="small"
        fill="clear"
      >
        Annuleren
      </ion-button>

      <ion-button
        data-test="tt-date-picker-popover-select-button"
        (click)="select($event)"
        size="small"
        fill="clear">
        Ok
      </ion-button>
    </div>
  }
</ion-card>
