import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { Expose } from 'class-transformer';

/**
 * Standard version of the Search resource from Scheduler-api
 */
export class SearchMetaData {
  /**
   * The query string
   */
  @Expose() public query!: string;

  /**
   * Identifing chips shown to the user in the search view.
   */
  @Expose() public filters!: {
    [param: string]:
      | PlainRangeInterface<string | number>
      | string
      | number
      | readonly (string | number)[];
  };
}
