import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CalendarEventInterface } from '@scheduler-frontend/calendar-contracts';
import { CalendarViewEnum } from '@techniek-team/calendar';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { calendarEventsActions } from '../actions/calendar-events.actions';

export const CALENDAR_EVENT_FEATURE_KEY: string = 'calendarEvent';

export interface CalendarEventState extends EntityState<CalendarEventInterface> {
  calendarEvents: {
    availabilities: {
      events: CalendarEventInterface[];
      loading: boolean;
      loaded: boolean;
    };
    assignments: {
      events: {
        future: CalendarEventInterface[];
        history: CalendarEventInterface[];
      };
      loading: boolean;
      loaded: boolean;
    };
  };
  error?: Error | null;
  activeDate: Date;
  currentView: CalendarViewEnum;
}

export const calendarEventEntityAdapter: EntityAdapter<CalendarEventInterface> =
  createEntityAdapter<CalendarEventInterface>({
    selectId: jsonLdSelectId,
  });

export const initialCalendarEventState: CalendarEventState =
  calendarEventEntityAdapter.getInitialState({
    calendarEvents: {
      availabilities: {
        events: [],
        loading: false,
        loaded: false,
      },
      assignments: {
        events: {
          future: [],
          history: [],
        },
        loading: false,
        loaded: false,
      },
    },
    error: null,
    activeDate: new Date(),
    currentView: CalendarViewEnum.MONTH,
  });

const reducer = createReducer(
  initialCalendarEventState,
  on(calendarEventsActions.initCalendarEvents, (state) => {
    return {
      ...state,
      calendarEvents: {
        ...state.calendarEvents,
        availabilities: {
          ...state.calendarEvents.availabilities,
          loading: true,
        },
        assignments: {
          ...state.calendarEvents.assignments,
          loading: true,
        },
      },
      error: null,
    };
  }),
  on(calendarEventsActions.refreshCalendarEvents, (state) => {
    return {
      ...state,
      calendarEvents: {
        ...state.calendarEvents,
        availabilities: {
          ...state.calendarEvents.availabilities,
          loading: true,
        },
        assignments: {
          ...state.calendarEvents.assignments,
          loading: true,
        },
      },
      error: null,
    };
  }),
  on(calendarEventsActions.loadAvailabilityCalendarEventsSuccess, (state, { availabilities }) => {
    return calendarEventEntityAdapter.setAll(
      [
        ...state.calendarEvents.assignments.events.history,
        ...state.calendarEvents.assignments.events.future,
        ...availabilities,
      ],
      {
        ...state,
        calendarEvents: {
          ...state.calendarEvents,
          availabilities: {
            loading: false,
            loaded: true,
            events: availabilities,
          },
        },
      },
    );
  }),
  on(
    calendarEventsActions.loadAssignmentsHistoryCalendarEventsSuccess,
    (state, { assignments }) => {
      return calendarEventEntityAdapter.setAll(
        [...state.calendarEvents.availabilities.events, ...assignments],
        {
          ...state,
          calendarEvents: {
            ...state.calendarEvents,
            assignments: {
              loading: false,
              loaded: true,
              events: {
                history: assignments,
                future: state.calendarEvents.assignments.events.future,
              },
            },
          },
        },
      );
    },
  ),
  on(calendarEventsActions.loadAssignmentsFutureCalendarEventsSuccess, (state, { assignments }) => {
    return calendarEventEntityAdapter.setAll(
      [...state.calendarEvents.availabilities.events, ...assignments],
      {
        ...state,
        calendarEvents: {
          ...state.calendarEvents,
          assignments: {
            loading: false,
            loaded: true,
            events: {
              history: state.calendarEvents.assignments.events.history,
              future: assignments,
            },
          },
        },
      },
    );
  }),
  on(calendarEventsActions.loadCalendarEventsFailure, (state, { error }) => ({
    ...state,
    error: error,
    calendarEvents: {
      ...state.calendarEvents,
      availabilities: {
        ...state.calendarEvents.availabilities,
        loading: false,
      },
      assignments: {
        ...state.calendarEvents.assignments,
        loading: false,
      },
    },
  })),
  on(calendarEventsActions.setActiveDate, (state, { activeDate }) => ({
    ...state,
    activeDate: activeDate,
  })),
  on(calendarEventsActions.setCurrentView, (state, { currentView }) => ({
    ...state,
    currentView: currentView,
  })),
);
export function calendarEventsReducer(
  state: CalendarEventState | undefined,
  action: Action,
): CalendarEventState {
  return reducer(state, action);
}
