/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { assignmentStateSearchQueryMapper } from '@scheduler-frontend/assignment-contracts';
import { BusinessEntitiesSelectors } from '@scheduler-frontend/data-access-business-entities';
import {
  BusinessServicesSelectors,
  deliveryTypeSearchQueryMapper,
} from '@scheduler-frontend/data-access-business-services';
import { LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { ProductTypesSelectors } from '@scheduler-frontend/data-access-product-types';
import { RegionsSelectors } from '@scheduler-frontend/data-access-regions';
import { RolesSelectors } from '@scheduler-frontend/data-access-roles';
import { SchedulesSelectors } from '@scheduler-frontend/data-access-schedules';
import { SubjectsSelectors } from '@scheduler-frontend/data-access-subjects';
import { levelSearchQueryMapper } from '@scheduler-frontend/enums';
import { memoize } from 'lodash-es';
import { SearchContract } from '../../contract/search.contract';
import { getReadableSearchChips } from '../../functions/readable-search-chips.function';
import { Search } from '../../models/search.model';
import { SEARCH_FEATURE_KEY, searchAdapter, SearchState } from '../reducer/search.reducer';

// Lookup the 'DataAccessSearch' feature state managed by NgRx
const { selectAll, selectEntities } = searchAdapter.getSelectors();

export class SearchSelectors {
  public static readonly state = createFeatureSelector<SearchState>(SEARCH_FEATURE_KEY);

  public static readonly schedulingBySearch = createSelector(
    SearchSelectors.state,
    (state) => state.schedulingBySearch,
  );
  public static readonly loading = createSelector(SearchSelectors.state, (state) => state.loading);

  public static readonly loadingCurrentSearch = createSelector(
    SearchSelectors.state,
    (state) => state.loadingCurrentSystemSearch,
  );

  public static readonly loaded = createSelector(SearchSelectors.state, (state) => state.loaded);

  public static readonly error = createSelector(SearchSelectors.state, (state) => state.error);

  public static readonly searches = createSelector(SearchSelectors.state, (state) =>
    selectAll(state),
  );

  public static readonly searchEntities = createSelector(SearchSelectors.state, (state) => {
    return selectEntities(state);
  });

  public static readonly currentSearchId = createSelector(
    SearchSelectors.state,
    (state) => state.currentSystemSearch,
  );

  public static readonly lastCreated = createSelector(
    SearchSelectors.state,
    (state) => state.lastCreatedSearch,
  );

  public static readonly currentSearch = createSelector(
    SearchSelectors.searchEntities,
    SearchSelectors.currentSearchId,
    (entities, selectedId) => {
      return selectedId ? entities[selectedId] : undefined;
    },
  );

  public static readonly lastCreatedSearch = createSelector(
    SearchSelectors.searchEntities,
    SearchSelectors.lastCreated,
    (entities, selectedId) => {
      return selectedId ? entities[selectedId] : undefined;
    },
  );

  public static readonly getSearchChips = memoize((search: SearchContract | Search) =>
    createSelector(
      BusinessEntitiesSelectors.searchQueryMapper,
      BusinessServicesSelectors.searchQueryMapper,
      LocationsSelectors.searchQueryMapper,
      ProductTypesSelectors.searchQueryMapper,
      RegionsSelectors.searchQueryMapper,
      RolesSelectors.searchQueryMapper,
      SubjectsSelectors.searchQueryMapper,
      SchedulesSelectors.searchQueryMapper,
      deliveryTypeSearchQueryMapper,
      levelSearchQueryMapper,
      assignmentStateSearchQueryMapper,
      (...queryMappersDictionaries) => getReadableSearchChips(search, queryMappersDictionaries),
    ),
  );
}
