import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideUsersStore } from '@scheduler-frontend/data-access-users';
import { CalendarEventsEffects } from './+state/effects/calendar-events.effects';
import { CandidateApproachesEffects } from './+state/effects/candidate-approaches.effects';
import { CandidateAssignmentsFutureEffects } from './+state/effects/candidate-assignments-future.effects';
import { CandidateAssignmentsHistoryEffects } from './+state/effects/candidate-assignments-history.effects';
import { CandidateAvailabilitiesEffects } from './+state/effects/candidate-availabilities.effects';
import { CandidateLongTermAvailabilityEffects } from './+state/effects/candidate-long-term-availability.effects';
import { CandidateRemarksEffects } from './+state/effects/candidate-remarks.effects';
import { CandidatesSearchEffects } from './+state/effects/candidates-search.effects';
import { CandidatesEffects } from './+state/effects/candidates.effects';
import {
  CALENDAR_EVENT_FEATURE_KEY,
  calendarEventsReducer,
} from './+state/reducers/calendar-events.reducer';
import {
  CANDIDATE_ALLOTMENT_STATISTICS_FEATURE_KEY,
  candidateAllotmentStatisticsReducer,
} from './+state/reducers/candidate-allotment-statistics.reducer';
import {
  CANDIDATE_APPROACHES_FEATURE_KEY,
  candidateApproachesReducer,
} from './+state/reducers/candidate-approaches.reducer';
import {
  CANDIDATE_ASSIGNMENTS_FUTURE_FEATURE_KEY,
  candidateAssignmentsFutureReducer,
} from './+state/reducers/candidate-assignments-future.reducer';
import {
  CANDIDATE_ASSIGNMENTS_HISTORY_FEATURE_KEY,
  candidateAssignmentsHistoryReducer,
} from './+state/reducers/candidate-assignments-history.reducer';
import {
  CANDIDATE_AVAILABILITIES_FEATURE_KEY,
  candidateAvailabilitiesReducer,
} from './+state/reducers/candidate-availabilities.reducer';
import {
  CANDIDATE_REMARKS_FEATURE_KEY,
  candidateRemarksReducer,
} from './+state/reducers/candidate-remarks.reducer';
import {
  CANDIDATES_SEARCH_FEATURE_KEY,
  candidatesSearchReducer,
} from './+state/reducers/candidates-search.reducer';
import { CANDIDATES_FEATURE_KEY, candidatesReducer } from './+state/reducers/candidates.reducer';
import { CandidateCalendarStoreService } from './candidate-calendar-store.service';
import { CandidatesSearchStoreService } from './candidates-search-store.service';
import { CandidatesStoreService } from './candidates-store.service';

export function provideCandidatesStore(): Provider | EnvironmentProviders[] {
  return [
    provideUsersStore(),
    provideState({ name: CANDIDATES_FEATURE_KEY, reducer: candidatesReducer }),
    provideState({
      name: CANDIDATE_ALLOTMENT_STATISTICS_FEATURE_KEY,
      reducer: candidateAllotmentStatisticsReducer,
    }),
    provideState({ name: CALENDAR_EVENT_FEATURE_KEY, reducer: calendarEventsReducer }),
    provideState({
      name: CANDIDATE_AVAILABILITIES_FEATURE_KEY,
      reducer: candidateAvailabilitiesReducer,
    }),
    provideState({ name: CANDIDATE_REMARKS_FEATURE_KEY, reducer: candidateRemarksReducer }),
    provideState({
      name: CANDIDATE_ASSIGNMENTS_HISTORY_FEATURE_KEY,
      reducer: candidateAssignmentsHistoryReducer,
    }),
    provideState({
      name: CANDIDATE_ASSIGNMENTS_FUTURE_FEATURE_KEY,
      reducer: candidateAssignmentsFutureReducer,
    }),
    provideState({ name: CANDIDATE_APPROACHES_FEATURE_KEY, reducer: candidateApproachesReducer }),
    provideState({ name: CANDIDATES_SEARCH_FEATURE_KEY, reducer: candidatesSearchReducer }),
    provideEffects(
      CandidatesEffects,
      CandidateAvailabilitiesEffects,
      CalendarEventsEffects,
      CandidateRemarksEffects,
      CandidateAssignmentsHistoryEffects,
      CandidateAssignmentsFutureEffects,
      CandidateApproachesEffects,
      CandidatesSearchEffects,
      CandidateLongTermAvailabilityEffects,
    ),
    CandidatesStoreService,
    CandidateCalendarStoreService,
    CandidatesSearchStoreService,
  ];
}
