<ng-container [formGroup]="form">
  <div class="role" [style.grid-row]="gridRow()">
    {{ slotToCreate().role.name }}
  </div>

  <!-- FIXME The ion-item is not displaying the form-errors as the "ion-invalid" class is not set.
    For now workaround this by using a div-element and some custom styling for the errors. -->
  <div [style.grid-row]="gridRow()" formGroupName="timeRange" class="time">
    <div class="controls">
      <tt-time-control
        fill="solid"
        [clearInput]="true"
        data-test="who-control-start-time-input"
        label="Starttijd"
        labelPlacement="floating"
        formControlName="startTime"
      >
      </tt-time-control>

      <tt-time-control
        fill="solid"
        data-test="who-control-end-time-input"
        [class.invalid-group]="form.controls.timeRange.errors"
        [clearInput]="true"
        label="Eindtijd"
        labelPlacement="floating"
        formControlName="endTime"
      >
      </tt-time-control>
    </div>

    <ion-note
      data-test="slot-to-create-start-time-invalid"
      *ngxControlError="form.controls.timeRange.controls.startTime; track: 'required'"
    >
      Starttijd is <strong>verplicht</strong> en kan maximaal <strong>23:59</strong> zijn.
    </ion-note>

    <ion-note
      data-test="slot-to-create-end-time-invalid"
      *ngxControlError="form.controls.timeRange.controls.endTime; track: 'required'"
    >
      Eindtijd is <strong>verplicht</strong> en kan maximaal <strong>23:59</strong> zijn.
    </ion-note>

    <ion-note
      data-test="slot-to-create-time-not-valid-error"
      *ngxControlError="form.controls.timeRange; track: 'tsRangeNotInRange'"
    >
      De eindtijd moet later zijn dan de starttijd.
    </ion-note>
  </div>

  @if (requirementsStoreService.mustSetNumberOfPupils()) {
    <ion-item
      lines="none"
      class="pupils item-interactive item-select ion-activatable ion-focusable"
      [style.grid-row]="gridRow()"
    >
      <ion-input
        fill="solid"
        label="Leerlingaantal"
        labelPlacement="floating"
        formControlName="amountOfPupils"
        type="number"
        data-test="slot-to-create-amount-of-pupils-input"
      >
      </ion-input>
      <ion-note slot="error" *ngxControlError="form.controls.amountOfPupils; track: 'required'">
        Leerlingaantal is <strong>verplicht</strong>.
      </ion-note>
    </ion-item>
  }

  @if (requirementsStoreService.mustHaveSubject()) {
    <div class="subject" [style.grid-row]="gridRow()">
      {{ slotToCreate().subject?.name }}
    </div>
  }
  <ion-button
    data-test="delete-row-button"
    color="light"
    class="delete-row"
    [style.grid-row]="gridRow()"
    (click)="removeSlotToCreate()"
  >
    <fa-icon slot="icon-only" [icon]="faTrash"></fa-icon>
  </ion-button>
</ng-container>
