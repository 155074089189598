import { createSelector } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { groupBy } from 'lodash-es';
import { SlotToCreateContract } from '../../../contract/slot-to-create.contract';
import { CreateSlotsSelectors } from './create-slots.selectors';

export class SlotsToCreateSelectors {
  public static readonly slotTemplate = createSelector(
    CreateSlotsSelectors.slotsState,
    (state) => state.slotTemplate,
  );

  public static readonly slotsToCreate = createSelector(
    CreateSlotsSelectors.slotsState,
    (state) => state.slotsToCreate,
  );

  public static readonly slotsToCreateGroupedByDate = createSelector(
    SlotsToCreateSelectors.slotsToCreate,
    (slotsToCreate) => {
      type SlotToCreatWithGridRow = SlotToCreateContract & { gridRow: number };
      let groups: { date: string; headerGridRow: number; items: SlotToCreatWithGridRow[] }[] = [];
      const grouped = groupBy(slotsToCreate, (item) =>
        formatISO(item.timeRange.start, { representation: 'date' }),
      );
      let count = 2;
      for (let [group, items] of Object.entries(grouped)) {
        groups.push({
          date: group,
          headerGridRow: count,
          items: items.map((item) => {
            count++;
            return { ...item, gridRow: count };
          }),
        });
        count++;
      }

      return groups;
    },
  );
}
