import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { isAtLocation } from '@scheduler-frontend/create-slot-modal-common';
import { DeliveryTypeEnum } from '@scheduler-frontend/data-access-business-services';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { format, isFuture, isPast } from 'date-fns';
import { SelectedSelectors } from './selected.selectors';

export class DeliveryTypeSelectors {
  public static readonly deliveryTypes = createSelector(
    SelectedSelectors.businessService,
    (businessServiceDetailed) => {
      return businessServiceDetailed?.deliveryTypes;
    },
  );

  public static readonly validDeliveryTypes = createSelector(
    DeliveryTypeSelectors.deliveryTypes,
    (deliveryTypes) => {
      return (deliveryTypes ?? []).filter(
        (deliveryType) =>
          !isPast(deliveryType.validityRange.end) && !isFuture(deliveryType.validityRange.start),
      );
    },
  );

  public static readonly deliveryTypesReadableValidity = createSelector(
    DeliveryTypeSelectors.deliveryTypes,
    (deliveryTypes) => {
      if (!deliveryTypes || deliveryTypes.length === 0) {
        return;
      }

      return deliveryTypes.reduce((readableValidityDict: Dictionary<string>, deliveryType) => {
        const start: string = deliveryType.validityRange.start;
        const end: string = deliveryType.validityRange.end;
        const id = deliveryType['@id'];

        if (isPast(end)) {
          readableValidityDict[id] = `was geldig tot ${format(end, 'dd-MM-yyyy')}`;
        } else if (isFuture(start)) {
          readableValidityDict[id] = `geldig vanaf ${format(start, 'dd-MM-yyyy')}`;
        } else if (!isPast(end) && !isFuture(start)) {
          readableValidityDict[id] = 'actief';
        }

        return readableValidityDict;
      }, {});
    },
  );

  public static readonly isAtHome = createSelector(
    SelectedSelectors.deliveryType,
    (deliveryType) => deliveryType?.deliveryType === DeliveryTypeEnum.ALL_AT_PUPIL_HOME,
  );

  public static readonly isAtLocation = createSelector(
    SelectedSelectors.deliveryType,
    (deliveryType) =>
      deliveryType?.deliveryType ? isAtLocation(deliveryType?.deliveryType) : false,
  );
}
