import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideSchedulingStore } from '@scheduler-frontend/data-access-scheduling';
import { ApproachesHandledEffects } from './+state/effects/approaches-handled.effects';
import { ApproachesInitEffects } from './+state/effects/approaches-init.effects';
import { ApproachesToDoEffects } from './+state/effects/approaches-to-do.effects';
import { ApproachesWaitingEffects } from './+state/effects/approaches-waiting.effects';
import { ApproachesEffects } from './+state/effects/approaches.effects';
import {
  APPROACHES_HANDLED_FEATURE_KEY,
  approachesHandledReducer,
} from './+state/reducer/approaches-handled.reducer';
import {
  APPROACHES_TO_DO_FEATURE_KEY,
  approachesToDoReducer,
} from './+state/reducer/approaches-to-do.reducer';
import {
  APPROACHES_WAITING_FEATURE_KEY,
  approachesWaitingReducer,
} from './+state/reducer/approaches-waiting.reducer';
import { APPROACHES_FEATURE_KEY, approachesReducer } from './+state/reducer/approaches.reducer';
import { ApproachesStoreService } from './approaches-store.service';

export function provideApproachesStore(): Provider | EnvironmentProviders[] {
  return [
    provideSchedulingStore(),
    provideState({ name: APPROACHES_FEATURE_KEY, reducer: approachesReducer }),
    provideState({ name: APPROACHES_TO_DO_FEATURE_KEY, reducer: approachesToDoReducer }),
    provideState({ name: APPROACHES_WAITING_FEATURE_KEY, reducer: approachesWaitingReducer }),
    provideState({ name: APPROACHES_HANDLED_FEATURE_KEY, reducer: approachesHandledReducer }),
    provideEffects(
      ApproachesEffects,
      ApproachesToDoEffects,
      ApproachesWaitingEffects,
      ApproachesHandledEffects,
      ApproachesInitEffects,
    ),
    ApproachesStoreService,
  ];
}
