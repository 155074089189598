import { inject, Injectable, Signal } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BusinessServiceContract } from '@scheduler-frontend/data-access-business-services';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { isDefined } from '@techniek-team/rxjs';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map, Observable, shareReplay, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { createSlotsActions } from './+state/actions/create-slots.actions';
import { CreateSlotsSelectors } from './+state/selectors/create-slots.selectors';
import { DeliveryTypeSelectors } from './+state/selectors/delivery-type.selector';
import { LessonsByScheduleSelectors } from './+state/selectors/lessons-by-schedule.selectors';
import { ProductByLocationSelectors } from './+state/selectors/product-by-location.selectors';
import { ScheduleSelectors } from './+state/selectors/schedule.selectors';

@Injectable()
export class CreateSlotsStoreService {
  private readonly store = inject(Store);

  public readonly lessonsBySchedule = this.store.selectSignal(LessonsByScheduleSelectors.lessons, {
    equal: isEqual,
  });

  public readonly deliveryTypes = this.store.selectSignal(DeliveryTypeSelectors.deliveryTypes, {
    equal: isEqual,
  });

  public readonly deliveryTypesReadableValidity = this.store.selectSignal(
    DeliveryTypeSelectors.deliveryTypesReadableValidity,
  );

  public readonly foundSchedules = this.store.selectSignal(ScheduleSelectors.foundSchedules);

  public readonly validSubjects = this.store.selectSignal(CreateSlotsSelectors.validSubjects);

  public readonly validRoles = this.store.selectSignal(CreateSlotsSelectors.validRoles);

  public readonly operationalLocations = this.store.selectSignal(
    CreateSlotsSelectors.operationalLocations,
  );

  public readonly loading = this.store.selectSignal(CreateSlotsSelectors.loading);

  public readonly loadingSchedules = this.store.selectSignal(ScheduleSelectors.loadingSchedules);

  public readonly loadingLessons = this.store.selectSignal(LessonsByScheduleSelectors.loading);

  public readonly submitting = this.store.selectSignal(CreateSlotsSelectors.submitting);

  private searchInputSubscription?: Subscription;

  public readonly businessServicesByLocation: Signal<BusinessServiceContract[]> =
    this.store.selectSignal(ProductByLocationSelectors.businessServicesByLocation, {
      equal: isEqual,
    });

  public readonly scheduleSearch$: (
    searchInputObserver: Observable<string | undefined>,
  ) => Observable<ScheduleContract[]> = (searchInputObserver: Observable<string | undefined>) => {
    if (this.searchInputSubscription) {
      this.searchInputSubscription.unsubscribe();
    }
    this.searchInputSubscription = searchInputObserver
      .pipe(distinctUntilChanged(), isDefined(), debounceTime(300))
      .subscribe((query) => {
        this.store.dispatch(createSlotsActions.setScheduleSearchQuery({ query: query }));
      });

    return this.store.pipe(
      select(ScheduleSelectors.foundSchedules),
      map((schedules) => schedules),
      shareReplay(),
    );
  };

  public openCreateSlotModal() {
    this.store.dispatch(createSlotsActions.openCreateSlotModal());
  }

  public submitCreateSlotForm() {
    this.store.dispatch(createSlotsActions.createSlot());
  }
}
