import { createActionGroup, props } from '@ngrx/store';
import { SlotContract } from '@scheduler-frontend/assignment-contracts';
import { RoleContract } from '@scheduler-frontend/data-access-roles';
import { TsRangeInterface } from '@techniek-team/common';

export const editSlotActions = createActionGroup({
  source: '[editSlot]',
  events: {
    openEditSlotModal: props<{ slotId: string }>(),
    submitEditSlot: props<{
      timeRange: TsRangeInterface<string>;
      role: RoleContract;
      performSkillCheck: boolean;
    }>(),
    submitEditSlotSuccess: props<{ slot: SlotContract }>(),
    submitEditSlotFailure: props<{ error: unknown }>(),
  },
});
