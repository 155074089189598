import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { catchError, filter, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { searchActions } from '../action/search.actions';
import { userSearchActions } from '../action/user-search.actions';
import { SearchApi } from '../api/search.api';

@Injectable()
export class UserSearchEffects {
  private readonly actions$ = inject(Actions);

  private readonly searchApi = inject(SearchApi);

  public readonly addNewUserSearchHashWhenUserInitiatesNewSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(
        searchActions.createSearchHashSuccess,
        searchActions.appendToSearchHashSuccess,
        searchActions.removeFromSearchHashSuccess,
      ),
      filter(({ isUserInitiated }) => isUserInitiated),
      map(({ search, isUserInitiated }) => {
        return userSearchActions.addUserSearchHash({
          search: {
            isFavorite: false,
            lastQueriedAt: formatISO(new Date()),
            search: search,
          },
        });
      }),
    ),
  );

  public readonly loadSearchHash = createEffect(() =>
    this.actions$.pipe(
      ofType(userSearchActions.setCurrentUserSearch),
      switchMap((action) =>
        this.searchApi.getHash(action.searchId).pipe(
          map((response) => {
            return userSearchActions.addUserSearchHash({
              search: {
                lastQueriedAt: formatISO(new Date()),
                search: response,
              },
            });
          }),
          catchError((error) => of(userSearchActions.addUserSearchHashFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly loadSearchHashFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(userSearchActions.addUserSearchHashFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de zoekopdracht.',
        }),
      ),
    { dispatch: false },
  );
}
