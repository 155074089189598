import { Person } from '@scheduler-frontend/data-access-users';
import { JsonLd } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';

export class PersonalRemark extends JsonLd {
  @Type(() => Date)
  @Expose()
  public createdAt!: Date;

  @Type(() => Person)
  @Expose()
  public createdBy?: Person;

  @Type(() => Date)
  @Expose()
  public updatedAt?: Date;

  @Type(() => Person)
  @Expose()
  public updatedBy?: Person;

  @Expose() public remark!: string;
}
