import { Directive, TemplateRef } from '@angular/core';

//eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
  //eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[selectSearchOption]',
    standalone: true
})
export class TtIonSelectSearchOptionDirective {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public template: TemplateRef<any>) {
  }
}
