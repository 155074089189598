import { animate, state, style, transition, trigger } from '@angular/animations';
import { IMAGE_CONFIG, NgOptimizedImage } from '@angular/common';
import { Component, computed, contentChildren, effect, inject, input, output, signal, viewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TtSubMenuComponent } from './sub-menu/tt-sub-menu.component';
import { TtMenuItemComponent } from './tt-menu-item/tt-menu-item.component';

/**
 * The component is the menu you see on the left hand side of the screen. It gives
 * the ability to navigate to other parts of the system.
 */
@Component({
  selector: 'tt-menu',
  templateUrl: './tt-menu.component.html',
  styleUrls: ['./tt-menu.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    RouterLink,
    TtMenuItemComponent,
    TtSubMenuComponent,
    NgOptimizedImage
],
  providers: [
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 40,
      },
    },
  ],
  animations: [
    trigger('expandMenu', [
      state(
        'true',
        style({
          width: 'var(--tt-menu-width-expanded, 184px)',
          flex: '0 0 var(--tt-menu-width-expanded)',
        }),
      ),
      state(
        'false',
        style({
          width: 'var(--tt-menu-width-collapsed, 184px)',
          flex: '0 0 fit-content',
        }),
      ),
      state(
        'false',
        style({
          width:
            'calc(var(--tt-menu-item-icon-container-size, 64px) + (var(--tt-menu-padding, 24px)*2))',
        }),
      ),
      transition('true <=> false', [animate('200ms')]),
    ]),
  ],
})
export class TtMenuComponent {
  private storage = inject(Storage);

  private readonly menuItems = contentChildren(TtMenuItemComponent);

  private readonly footerMenuIem = viewChild(TtMenuItemComponent);

  public readonly header = input<string>();

  public readonly isExpandedChange = output<boolean>();

  protected readonly isExpanded = signal(false);

  protected readonly activeMenuItem = computed(() => {
    return this.menuItems().find((item) => item.isActive());
  });

  private readonly setChildIsExpanded = effect(
    () => {
      this.footerMenuIem()?.expanded.set(this.isExpanded());
      for (let menuItem of this.menuItems()) {
        menuItem.expanded.set(this.isExpanded());
      }
    },
    { allowSignalWrites: true },
  );

  private readonly setTTMenuExpandedFromStorage = this.storage
    .create()
    .then(() => this.storage.get('ttMenuExpanded'))
    .then((isExpanded) => {
      if (isExpanded !== undefined) {
        this.toggleExpandMenu(isExpanded);
      }
    });

  /**
   * Enlarges or reduces the size of the menu.
   */
  protected toggleExpandMenu(isExpanded?: boolean): void {
    if (isExpanded === undefined) {
      isExpanded = !this.isExpanded();
    }
    this.isExpanded.set(isExpanded);
    this.isExpandedChange.emit(isExpanded);
    this.storage
      .create()
      .then(() => this.storage.set('ttMenuExpanded', isExpanded));
  }
}
