import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { LocationModel } from '@scheduler-frontend/data-access-locations';
import { TsRange, TsRangeInterface } from '@techniek-team/class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationDashboardFinalizeResponse } from './location-dashboard.response';

@Injectable({
  providedIn: 'root',
})
export class LocationDashboardApi {
  protected readonly httpClient = inject(HttpClient);

  public getLocationFinalizeList(
    location: string | LocationModel,
  ): Observable<LocationDashboardFinalizeResponse[]> {
    if (location instanceof LocationModel) {
      location = location.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${location}/unfinalized`;

    return this.httpClient.get<LocationDashboardFinalizeResponse<TsRangeInterface>[]>(url).pipe(
      map((response) => {
        return response.map((item) => {
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          let newItem: LocationDashboardFinalizeResponse<any> = {
            ...item,
          };
          newItem.assignmentHasSlots = newItem.assignmentHasSlots.map((assignment) => {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newAssignment: LocationDashboardFinalizeResponse<any>['assignmentHasSlots'][0] = {
              ...assignment,
            };

            newAssignment.slot.timePeriod = TsRange.fromObject(newAssignment.slot.timePeriod);
            if (newAssignment.actualTimePeriod) {
              newAssignment.actualTimePeriod = TsRange.fromObject(newAssignment.actualTimePeriod);
            }

            return newAssignment;
          });
          return newItem;
        });
      }),
    );
  }
}
