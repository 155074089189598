import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideCandidatesStore } from '@scheduler-frontend/data-access-candidates';
import { provideSchedulingStore } from '@scheduler-frontend/data-access-scheduling';
import {
  CANDIDATES_BY_RANKING_FEATURE_KEY,
  candidatesByRankingReducer,
} from './+state/candidates-by-ranking.reducer';
import { AlternativeShiftEffect } from './+state/effects/alternative-shift-effect.service';
import { LoadCandidatesByRankingEffect } from './+state/effects/load-candidates-by-ranking.effect';
import { LoadSlotsByCandidateEffect } from './+state/effects/load-slots-by-candidate.effect';
import { ModifyCandidatesByRankingListEffect } from './+state/effects/modify-candidates-by-ranking-list.effect';
import { UpdateCandidateStoreEffect } from './+state/effects/update-candidate-store.effect';
import { UpdateRankingStoreEffect } from './+state/effects/update-ranking-store.effect';
import { UpdateSchedulingStoreEffect } from './+state/effects/update-scheduling-store.effect';
import { UpdateSlotStoreEffect } from './+state/effects/update-slot-store.effect';
import { CandidatesByRankingStoreService } from './candidates-by-ranking-store.service';

export function provideCandidatesByRankingStore(): Provider | EnvironmentProviders[] {
  return [
    provideCandidatesStore(),
    provideSchedulingStore(),
    provideState({ name: CANDIDATES_BY_RANKING_FEATURE_KEY, reducer: candidatesByRankingReducer }),
    provideEffects(
      LoadCandidatesByRankingEffect,
      LoadSlotsByCandidateEffect,
      ModifyCandidatesByRankingListEffect,
      UpdateCandidateStoreEffect,
      UpdateRankingStoreEffect,
      UpdateSchedulingStoreEffect,
      UpdateSlotStoreEffect,
      AlternativeShiftEffect,
    ),
    CandidatesByRankingStoreService,
  ];
}
