import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  LessonDetailedContract,
  Slot,
  SlotAssignmentHasSlotContract,
  SlotDetailedWithAssignmentHasSlot,
} from '@scheduler-frontend/assignment-contracts';
import { Candidate } from '@scheduler-frontend/candidate-contracts';
import { LocationModel } from '@scheduler-frontend/data-access-locations';
import { environment } from '@scheduler-frontend/environments';
import { Schedule } from '@scheduler-frontend/schedule-contracts';
import { Resource } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetSlotsWithoutActualTimesV1,
  GetSlotsWithoutWorkingTimesV3,
} from './actual-working-times.response';

//eslint-disable-next-line max-lines-per-function
function convertGetSlotsWithoutActualTimesV1ToV3(
  response: GetSlotsWithoutActualTimesV1,
): Resource<GetSlotsWithoutWorkingTimesV3> {
  return {
    '@id': `${environment.scheduler.iri}/v3/slots/${response.id}`,
    '@type': 'Slot',
    'schedule': {
      '@id': `${environment.scheduler.iri}/v3/schedules/${response.schedule.id}`,
      '@type': 'Schedule',
      'name': response.schedule.name,
    },
    'lesson': {
      '@id': `${environment.scheduler.iri}/v3/lessons/${response.schedule.id}`,
      '@type': 'Lesson',
      'location': response.location
        ? `${environment.scheduler.iri}/v3/locations/${response.location.id}`
        : undefined,
      'numberOfPupils': response.lesson.numberOfPupils,
      'subject': response.subject
        ? `${environment.scheduler.iri}/v3/subjects/${response.subject.id}`
        : undefined,
      'level': response.lesson.level,
      'date': response.lesson.date,
      'name': response.lesson.name,
      'isBillable': response.lesson.isBillable,
      'isOnline': response.lesson.isOnline,
    } as Resource<LessonDetailedContract>,
    'timePeriod': response.timePeriod,
    'role': `${environment.scheduler.iri}/v3/roles/${response.role.id}`,
    'actions': response.actions.map((action) => ({
      '@id': `${environment.scheduler.iri}/v3/actions/${action.id}`,
      '@type': 'Action',
      'description': action.description,
    })),
    'assignmentHasSlot': response.assignmentHasSlot
      ? ({
          '@id': `${environment.scheduler.iri}/v3/assignment_has_slots/${response.assignmentHasSlot.id}`,
          '@type': 'AssignmentHasSlot',
          'actualTimePeriod': response.assignmentHasSlot.actualTimePeriod,
          'breakTime': response.assignmentHasSlot.breakTime,
          'purpose': response.assignmentHasSlot.purpose,
          'assignment': {
            '@id': `${environment.scheduler.iri}/v3/assignments/${response.assignment.id}`,
            '@type': 'Assignment',
            'candidate': response.assignment.candidateId
              ? {
                  '@id': `${environment.scheduler.iri}/v3/candidates/${response.assignment.candidateId}`,
                  '@type': 'Candidate',
                  'fullName': response.assignment.candidateName,
                }
              : undefined,
            'name': response.assignment.name,
            'description': response.assignment.description,
            'state': response.assignment.state,
            'bookingPeriodClosed': response.assignment.bookingPeriodClosed,
          },
        } as Resource<SlotAssignmentHasSlotContract>)
      : undefined,
    'displayAsCombined': response.displayAsCombined,
    'isCombined': response.isCombined,
    'performSkillCheck': response.performSkillCheck,
  };
}

/**
 * Api to retrieve the {@see Slot} resource from the scheduler-api.
 * Method and give resources from this api are missing
 * {@see SlotDetailed.assignmentHasSlot.actualTimePeriod} which needs to be set.
 */
@Injectable({
  providedIn: 'root',
})
export class ActualWorkingTimesApi {
  protected readonly httpClient = inject(HttpClient);

  /**
   * Gets a list of slots for which the tutor should provide the actual working times
   * after finishing a shift
   *
   * @todo This endpoint needs to be change to and api platform endpoint
   *   <sub toto create issue>
   */
  public getSlotsWithoutActualTimes(): Observable<SlotDetailedWithAssignmentHasSlot[]> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/slots/requiring_actual_time_period_update_overview`;

    return this.httpClient.get<GetSlotsWithoutActualTimesV1[]>(url).pipe(
      map((response) => response.map((item) => convertGetSlotsWithoutActualTimesV1ToV3(item))),
      map((response) => denormalize(SlotDetailedWithAssignmentHasSlot, response)),
    );
  }

  /**
   * Gets a list of slots for which the location coordinator should
   * provide the actual working times after finishing a shift
   *
   * @todo This endpoint needs to be change to and api platform endpoint
   *   <sub todo create issue>
   */
  public getSlotsWithoutActualTimesLocation(
    location: string | LocationModel,
  ): Observable<SlotDetailedWithAssignmentHasSlot[]> {
    if (location instanceof LocationModel) {
      location = location.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/slots/${location}/requiring_actual_time_period_update`;

    return this.httpClient.get<GetSlotsWithoutActualTimesV1[]>(url).pipe(
      map((response) => response.map((item) => convertGetSlotsWithoutActualTimesV1ToV3(item))),
      map((response) => denormalize(SlotDetailedWithAssignmentHasSlot, response)),
    );
  }

  /**
   * Sends a push message to all candidates that need to update their
   * actual working times after finishing a shift
   */
  public sendReminderForActualTimeWorked(): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/candidates/send-reminder-for-actual-time-worked`;

    return this.httpClient.post<void>(url, undefined);
  }
}
