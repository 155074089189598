import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { omit } from 'lodash-es';
import { RoutesView } from './scheduling-views-router.selectors';

export enum SchedulingViewQueryParams {
  VIEW = 'schedulingView',
  DATE_IN_VIEW = 'schedulingDateInView',
  SELECTED_LOCATIONS = 'schedulingSelectedLocation[]',
  SELECTED_PRODUCT_TYPES = 'schedulingSelectedProductTypes[]',
  SELECTED_SLOTS = 'schedulingSelectedSlots[]',
  SELECTED_CANDIDATES = 'schedulingSelectedCandidates[]',
  ACTIVE_CANDIDATE = 'schedulingActiveCandidate',
}

export function setSchedulingViewQueryParam(
  location: Location,
  router: Router,
  key: SchedulingViewQueryParams.VIEW,
  newValue: RoutesView,
): void;

export function setSchedulingViewQueryParam(
  location: Location,
  router: Router,
  key:
    | SchedulingViewQueryParams.SELECTED_LOCATIONS
    | SchedulingViewQueryParams.SELECTED_PRODUCT_TYPES
    | SchedulingViewQueryParams.SELECTED_SLOTS
    | SchedulingViewQueryParams.SELECTED_CANDIDATES,
  newValue: string[],
): void;

export function setSchedulingViewQueryParam(
  location: Location,
  router: Router,
  key: SchedulingViewQueryParams.DATE_IN_VIEW | SchedulingViewQueryParams.ACTIVE_CANDIDATE,
  newValue: string | undefined | null,
): void;

export function setSchedulingViewQueryParam(
  location: Location,
  router: Router,
  key: SchedulingViewQueryParams,
  newValue: RoutesView | string | string[] | null | undefined,
): void {
  const route = router.parseUrl(location.path());
  const currentQueryParams = omit({ ...route.queryParams }, [key]);
  route.queryParams = [];

  const updatedQueryParams = getUpdatedQueryParams(currentQueryParams, key, newValue);
  location.replaceState(
    route.toString(),
    Location.normalizeQueryParams(updatedQueryParams.toString()),
  );
}

function getUpdatedQueryParams(
  currentQueryParams: { [key: string]: string | string[] },
  key: string,
  newValue: string | string[] | undefined | null,
): HttpParams {
  currentQueryParams = omit({ ...currentQueryParams }, [key]);

  if ((Array.isArray(newValue) && newValue.length > 0) || newValue) {
    return new HttpParams({ fromObject: { ...currentQueryParams, [key]: newValue } });
  }

  return new HttpParams({ fromObject: { ...currentQueryParams } });
}
