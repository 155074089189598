import { createActionGroup, props } from '@ngrx/store';
import { AssignmentCompensationLineContract } from '../../contracts/assignment-compensation-line.contract';
import { AssignmentCompensationContract } from '../../contracts/assignment-compensation.contract';
import { PayoutArticleCodeEnum } from '../../enums/payout-article-code.enum';

export const assignmentCompensationsActions = createActionGroup({
  source: '[AssignmentCompensations]',
  events: {
    setActiveAssignmentCompensation: props<{
      assignmentCompensation: string | null;
    }>(),

    loadAssignmentCompensation: props<{ assignment: string }>(),
    loadAssignmentCompensationSuccess: props<{
      assignmentCompensation: AssignmentCompensationContract;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadAssignmentCompensationFailure: props<{ error: any }>(),

    addCompensationLine: props<{
      compensationLine: {
        articleCode: PayoutArticleCodeEnum;
        description: string;
        quantity: string;
        amount: string;
        assignmentHasSlot: string;
        subtotal: number;
        date: string;
      };
    }>(),
    addCompensationLineSuccess: props<{
      compensationLine: AssignmentCompensationLineContract;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    addCompensationLineFailure: props<{ error: any }>(),

    removeCompensationLine: props<{ compensationLine: string }>(),
    removeCompensationLineSuccess: props<{ compensationLine: string }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeCompensationLineFailure: props<{ error: any }>(),

    updateAssignmentCompensations: props<{
      mustPerformCompensation?: boolean;
      automaticTravelCompensation?: boolean;
    }>(),
    updateAssignmentCompensationsSuccess: props<{
      mustPerformCompensation: boolean;
      automaticTravelCompensation: boolean;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateAssignmentCompensationsFailure: props<{ error: any }>(),
  },
});
