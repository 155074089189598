import { Exclude, Expose } from 'class-transformer';

export class JsonLd {
  /**
   * Regex to match the id with the iri.
   *
   * It used to be this regex. But since there are project having fixtures that
   * don't have valid uuid this doesn't suffice.
   * /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g
   */
  @Exclude() public readonly iriRegex: RegExp = new RegExp(
    /^\/.*\/([A-z\d\-_]*)/,
  );

  @Expose({ name: '@context' }) public _context?: string;

  @Expose({ name: '@id' }) public _id!: string;

  @Exclude() public uuid!: string;

  /**
   * In some instances we need the name of the classModel. This is for instance
   * when using the {@see Fetch} decorator. Normally you could use
   * (new Instance()).constructor.name). But when angular minifies the production
   * build it also changes the name of the class demolising the constructor.name
   * in the Process.
   *
   * Setting this property is a way to fix this issue. Methods like the {@see Fetch}
   * decorator use the {@see className} class name getter. where this property
   * take precedence over constructor.name
   */
  public readonly className!: string;

  @Expose({ name: '@type' }) public _type?: string;

  /**
   * Return the Iri of this model. Undefined means that the model is newly
   * created and needs to be post to the server before we have an Iri.
   */
  @Exclude() public getIri(): string | undefined {
    return this._id;
  }

  /**
   * Returns this unique identifier of the Resource. If the property id existed on the resource
   * it returns that. Otherwise it tries to get the last uuid it can find from the iri.
   */
  @Exclude() public getId(): string {
    if (this.uuid) {
      return this.uuid;
    }
    return this.parseIdFromIri() as string;
  }

  /**
   * Return True if the instance doesn't have an id because it an new instance.
   */
  @Exclude() public isNewInstance(): boolean {
    return !this._id;
  }

  /**
   * Tries to find all uuid's within the iri and returns the last one since that's the id
   * of the Resource.
   */
  @Exclude() private parseIdFromIri(): string | void {
    if (!this._id) {
      return;
    }
    const match: string[] | null = this._id.match(this.iriRegex);

    if (match) {
      this.uuid = match.pop() as string;
      return this.uuid;
    }

    return undefined;
  }

  /**
   * In some instances we need the name of the classModel. This is for instance
   * when using the {@see Fetch} decorator. Normally you could use
   * (new Instance()).constructor.name). But when angular minifies the production
   * build it also changes the name of the class demolising the constructor.name
   * in the Process.
   *
   * Setting the {@see type} property is a way to fix this issue. Methods like
   * the {@see Fetch} decorator use this getter. Where the {@see type} and {@see _type}
   * property take precedence over constructor.name
   */
  @Exclude() public getClassName(): string {
    return this.className ?? this._type ?? this.constructor.name;
  }

  constructor() {
    this.uuid = this.parseIdFromIri() as string;
  }
}
