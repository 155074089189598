import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { FetchStorageInterface } from '@techniek-team/fetch';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { regionsActions } from './+state/regions.actions';
import { RegionsSelectors } from './+state/regions.selectors';
import { RegionContract } from './contracts/region.contract';
import { Region } from './models/region.model';

@Injectable()
export class RegionsStoreService implements FetchStorageInterface<RegionContract, Region> {
  private readonly store = inject(Store);

  private readonly actions$ = inject(Actions);
  public loading$: Observable<boolean> = this.store.pipe(select(RegionsSelectors.regionsLoading));

  public loaded$: Observable<boolean> = this.store.pipe(select(RegionsSelectors.regionsLoaded));

  public count$: Observable<number> = this.store.pipe(select(RegionsSelectors.count));

  public regions$: Observable<Region[]> = this.store.pipe(
    select(RegionsSelectors.allRegions),
    map((data) => denormalize(Region, data)),
    shareReplay(),
  );

  public provinces$: Observable<Region[]> = this.store.pipe(
    select(RegionsSelectors.allRegions),
    map((data) => denormalize(Region, data)),
    shareReplay(),
  );

  public regionsPreFetchMap$: Observable<Map<string, Region>> = this.store.pipe(
    select(RegionsSelectors.regionsEntities),
    map((data) => {
      return new Map(Object.entries(data).map(([key, item]) => [key, denormalize(Region, data)]));
    }),
    shareReplay(),
  );

  public provincesFilterGroup = this.store.selectSignal(RegionsSelectors.provincesFilterGroup);

  public supportsFetch(identifier: string): boolean {
    return identifier === 'Region';
  }

  public getFetchFromStorage<O>(value: string): Observable<RegionContract | Region | undefined> {
    return this.store.pipe(
      select(RegionsSelectors.denormalizedRegionEntities),
      map((dict) => dict[jsonLdSelectId(value)]),
      isDefined(),
    );
  }

  public async waitForInitialization(): Promise<void> {
    await firstEmitFrom(
      this.store.pipe(
        select(RegionsSelectors.regionsLoaded),
        filter((loaded) => loaded === true),
      ),
    );
  }

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public async init(): Promise<void> {
    if (await firstEmitFrom(this.store.pipe(select(RegionsSelectors.regionsInitialized)))) {
      return;
    }

    this.store.dispatch(regionsActions.initRegions());
  }

  public reload(): void {
    this.store.dispatch(regionsActions.reloadRegions());
  }
}
