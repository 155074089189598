import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AssignmentDetailed } from '@scheduler-frontend/assignment-contracts';
import {
  ActiveAssignmentsPermissionsStoreService,
  AssignmentsStoreService,
} from '@scheduler-frontend/data-access-assignment';
import { TtNumberInputModule } from '@techniek-team/components/number-input';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-self-assign-form',
  templateUrl: './self-assign-form.component.html',
  styleUrls: ['./self-assign-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, ReactiveFormsModule, AsyncPipe, TtNumberInputModule],
})
export class SelfAssignFormComponent {
  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  /**
   * The form.
   */
  protected readonly selfAssignForm = new FormGroup({
    selfAssignable: new FormControl<boolean>(false, { nonNullable: true }),
    allowSelfAssignWhenNew: new FormControl<boolean>(
      { value: false, disabled: true },
      { nonNullable: true },
    ),
    minimalGradeSelfAssign: new FormControl<number | null>({ value: 7.5, disabled: true }, [
      Validators.min(0),
      Validators.max(10),
    ]),
    maxTravelDistanceSelfAssign: new FormControl<number | null>(
      {
        value: null,
        disabled: true,
      },
      [Validators.min(0), Validators.max(350)],
    ),
  });

  protected readonly canEditSelfAssignable = effect(() => {
    const granted = this.activeAssignmentsPermissionsStoreService.canEditSelfAssignable();
    if (granted) {
      this.selfAssignForm.enable({ emitEvent: false });
    } else {
      this.selfAssignForm.disable({ emitEvent: false });
    }
  });

  private readonly disableFormOnSelfAssignableChange = this.selfAssignForm
    .get('selfAssignable')
    ?.valueChanges.subscribe((selfAssignable) => {
      if (selfAssignable === true) {
        this.selfAssignForm.get('allowSelfAssignWhenNew')?.enable({ emitEvent: false });
        this.selfAssignForm.get('minimalGradeSelfAssign')?.enable({ emitEvent: false });
        this.selfAssignForm.get('maxTravelDistanceSelfAssign')?.enable({ emitEvent: false });
      } else {
        this.selfAssignForm.get('allowSelfAssignWhenNew')?.disable({ emitEvent: false });
        this.selfAssignForm.get('minimalGradeSelfAssign')?.disable({ emitEvent: false });
        this.selfAssignForm.get('maxTravelDistanceSelfAssign')?.disable({ emitEvent: false });
      }
    });

  /**
   * trigger save on form change.
   */
  private readonly saveOnChange = this.selfAssignForm.valueChanges
    .pipe(
      filter(() => {
        return this.selfAssignForm.valid && this.selfAssignForm.dirty;
      }),
      debounceTime(2000),
    )
    .subscribe((formValue) => {
      this.assignmentsStoreService.setActiveAssignmentSelfAssignmentSettings(
        formValue.selfAssignable as boolean,
        formValue.allowSelfAssignWhenNew as boolean,
        formValue.minimalGradeSelfAssign,
        formValue.maxTravelDistanceSelfAssign,
      );
    });

  public assignment = input.required<AssignmentDetailed>();

  public resetFormOnAssignment = effect(() => {
    const assignment = this.assignment();
    if (!assignment) {
      return;
    }
    this.selfAssignForm.reset(
      {
        selfAssignable: assignment.selfAssignable,
        allowSelfAssignWhenNew: assignment.allowSelfAssignWhenNew,
        minimalGradeSelfAssign: {
          value:
            typeof assignment.minimalGradeSelfAssign === 'string'
              ? parseFloat(assignment.minimalGradeSelfAssign)
              : assignment.minimalGradeSelfAssign ?? 7.5,
          disabled: !assignment?.businessService()?.hasAssignmentReviews,
          // There's any is needed due to a bug in the typings of the reset method
          // https://github.com/angular/angular/issues/46458
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
        maxTravelDistanceSelfAssign: assignment.maxTravelDistanceSelfAssign ?? null,
      },
      { emitEvent: false },
    );
  });
}
