import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { formatISO, isAfter, subDays } from 'date-fns';
import { catchError, exhaustMap, from, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubjectContract } from '../contracts/subject.contract';
import { SubjectApi } from './api/subject.api';
import { subjectsActions } from './subjects.actions';
import { SUBJECTS_FEATURE_KEY } from './subjects.reducer';

@Injectable()
export class SubjectsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly storage = inject(Storage);

  private readonly store = inject(Store);

  private readonly subjectApi = inject(SubjectApi);

  public createSubjectsFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(subjectsActions.loadSubjectsFailure, {
          message: 'Er is iets misgegaan bij het laden van alle vakken.',
        }),
      ),
    { dispatch: false },
  );

  public createSaveSubjectsToStorageEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(subjectsActions.loadSubjectsSuccess),
        tap((action) => {
          if (action.subjects.length === 0) {
            return;
          }
          return from(
            Promise.all([
              this.storage.set(`${SUBJECTS_FEATURE_KEY}-store-cache`, {
                items: action.subjects,
                timestamp: new Date(),
              }),
            ]),
          );
        }),
      ),
    { dispatch: false },
  );

  public initSubjects = createEffect(() =>
    this.actions$.pipe(
      ofType(subjectsActions.initSubjects),
      switchMap(() =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() => {
            return from<Promise<{ timestamp: Date; items: SubjectContract[] } | null>>(
              this.storage.get(`${SUBJECTS_FEATURE_KEY}-store-cache`),
            ).pipe(
              exhaustMap((cache) => {
                if (cache && isAfter(cache.timestamp, subDays(new Date(), 1))) {
                  return of(
                    subjectsActions.loadFromCacheSubjectsSuccess({
                      subjects: cache.items,
                      totalItems: cache.items.length,
                      cacheTimestamp: formatISO(cache.timestamp),
                    }),
                  );
                }
                return this.subjectApi.getSubjects().pipe(
                  map((response) => {
                    return subjectsActions.loadSubjectsSuccess({
                      subjects: response['hydra:member'],
                      totalItems: response['hydra:totalItems'],
                    });
                  }),
                );
              }),
              catchError((error) => of(subjectsActions.loadSubjectsFailure({ error: error }))),
            );
          }),
        ),
      ),
    ),
  );

  public reloadSubject = createEffect(() =>
    this.actions$.pipe(
      ofType(subjectsActions.reloadSubjects),
      switchMap(() =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() =>
            this.subjectApi.getSubjects().pipe(
              map((response) => {
                return subjectsActions.loadSubjectsSuccess({
                  subjects: response['hydra:member'],
                  totalItems: response['hydra:totalItems'],
                });
              }),
              catchError((error) => of(subjectsActions.loadSubjectsFailure({ error: error }))),
            ),
          ),
        ),
      ),
    ),
  );

  public ngrxOnInitEffects() {
    return subjectsActions.initSubjects();
  }
}
