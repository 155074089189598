import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AssignmentDetailed } from '@scheduler-frontend/assignment-contracts';
import {
  ActiveAssignmentsPermissionsStoreService,
  AssignmentsStoreService,
} from '@scheduler-frontend/data-access-assignment';

@Component({
  selector: 'app-urgent-form',
  templateUrl: './urgent-form.component.html',
  styleUrls: ['./urgent-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule, AsyncPipe],
})
export class UrgentFormComponent {
  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  /**
   * The form.
   */
  protected readonly urgentForm = new FormControl(
    { value: false, disabled: true },
    { nonNullable: true },
  );

  protected readonly canMarkAsUrgent = effect(() => {
    const granted = this.activeAssignmentsPermissionsStoreService.canMarkAsUrgent();
    if (granted) {
      this.urgentForm.enable({ emitEvent: false });
    } else {
      this.urgentForm.disable({ emitEvent: false });
    }
  });

  /**
   * The provided assignment.
   */
  @Input()
  public set assignment(assignment: AssignmentDetailed | null | undefined) {
    if (assignment) {
      this.currentAssignment = assignment;
      this.urgentForm.reset(assignment.isUrgent);
    }
  }

  protected currentAssignment!: AssignmentDetailed;

  protected editIsUrgent(): void {
    const formValue = this.urgentForm.getRawValue();

    this.assignmentsStoreService.markActiveAssignmentAsUrgent(formValue);
  }
}
