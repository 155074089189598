import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId, NonNullableDictionary } from '@techniek-team/tt-ngrx';
import { schedulingApproachCandidatesActions } from '../actions/scheduling-approach-candidates.actions';
import { schedulingAssignToSlotsActions } from '../actions/scheduling-assign-to-slots.actions';
import { schedulingRejectSlotsActions } from '../actions/scheduling-reject-slots.actions';
import { schedulingViewActions } from '../actions/scheduling-view.actions';
import { schedulingActions } from '../actions/scheduling.actions';

export const SCHEDULING_FEATURE_KEY = 'scheduling';

export interface SchedulingState {
  selectedSlots: string[];
  selectedSlotsForRejection: string[];
  selectedCandidates: string[];
  activeApproach?: string;
  error?: unknown | null;
  slotList: string[];
  alternativeSlotList?: NonNullableDictionary<string[]>;
  candidateList: string[];
  loadingSlots: boolean;
  loadedSlots: boolean;
  loadingCandidates: boolean;
  loadedCandidates: boolean;
  savingAssignment: boolean;
  savingApproach: boolean;
}

export const initialSchedulingState: SchedulingState = {
  selectedSlots: [],
  selectedSlotsForRejection: [],
  selectedCandidates: [],
  slotList: [],
  candidateList: [],
  savingAssignment: false,
  loadingSlots: false,
  loadedSlots: false,
  loadingCandidates: false,
  loadedCandidates: false,
  savingApproach: false,
};

const reducer = createReducer(
  initialSchedulingState,
  on(
    schedulingActions.reset,
    (state) =>
      ({
        ...state,
        selectedSlots: [],
        slotList: [],
        candidateList: [],
        selectedSlotsForRejection: [],
        selectedCandidates: [],
      }) as SchedulingState,
  ),
  on(schedulingViewActions.setSchedulingView, (state, { selectedSlots, selectedCandidates }) => {
    return {
      ...state,
      selectedSlots: selectedSlots ?? state.selectedSlots ?? [],
      selectedCandidates: selectedCandidates ?? state.selectedCandidates ?? [],
    } as SchedulingState;
  }),
  on(schedulingViewActions.changeCurrentViewSuccess, (state, { view, slotList }) => {
    return {
      ...state,
      slotList: slotList,
    };
  }),
  on(schedulingActions.selectSlots, (state, { items }) => {
    const selectionSet = new Set(state.selectedSlots);
    for (let item of items) {
      selectionSet.add(item);
    }
    return {
      ...state,
      selectedSlots: [...selectionSet.values()],
    };
  }),
  on(schedulingActions.deselectSlots, (state, { items }) => {
    const selectionSet = new Set(state.selectedSlots);
    for (let item of items) {
      selectionSet.delete(item);
    }
    return {
      ...state,
      selectedSlots: [...selectionSet.values()],
    };
  }),
  on(schedulingActions.clearSelectedSlots, (state) => ({
    ...state,
    selectedSlots: [],
  })),
  on(schedulingActions.selectSlotsForRejection, (state, { items }) => {
    const selectionSet = new Set(state.selectedSlotsForRejection);
    for (let item of items) {
      selectionSet.add(item);
    }
    return {
      ...state,
      selectedSlotsForRejection: [...selectionSet.values()],
    };
  }),
  on(schedulingActions.deselectSlotsForRejection, (state, { items }) => {
    const selectionSet = new Set(state.selectedSlotsForRejection);
    for (let item of items) {
      selectionSet.delete(item);
    }
    return {
      ...state,
      selectedSlotsForRejection: [...selectionSet.values()],
    };
  }),
  on(schedulingActions.clearSelectedSlotsForRejection, (state) => ({
    ...state,
    selectedSlotsForRejection: [],
  })),
  on(schedulingActions.selectCandidates, (state, { items }) => {
    const selectionSet = new Set(state.selectedCandidates);
    for (let item of items) {
      selectionSet.add(item);
    }
    return {
      ...state,
      selectedCandidates: [...selectionSet.values()],
    } as SchedulingState;
  }),
  on(schedulingActions.deselectCandidates, (state, { items }) => {
    const selectionSet = new Set(state.selectedCandidates);
    for (let item of items) {
      selectionSet.delete(item);
    }
    return {
      ...state,
      selectedCandidates: [...selectionSet.values()],
    };
  }),
  on(schedulingActions.clearSelectedCandidates, (state) => ({
    ...state,
    selectedCandidates: [],
  })),
  on(schedulingActions.setCandidateList, (state, { items }) => ({
    ...state,
    candidateList: items,
    loadingCandidates: false,
    loadedCandidates: true,
  })),
  on(schedulingActions.setAlternativeSlotList, (state, { items }) => ({
    ...state,
    alternativeSlotList: items ?? {},
    loadingCandidates: false,
    loadedCandidates: true,
  })),
  on(schedulingActions.setSlotList, (state, { items }) => ({
    ...state,
    slotList: items,
    loadingSlots: false,
    loadedSlots: true,
  })),
  /** Assign candidate **/
  on(schedulingAssignToSlotsActions.assignCandidate, (state) => ({
    ...state,
    savingAssignment: true,
  })),
  on(schedulingAssignToSlotsActions.assignCandidateFailure, (state, { error }) => ({
    ...state,
    savingAssignment: false,
    error: error,
  })),
  on(
    schedulingAssignToSlotsActions.assignCandidateSuccess,
    schedulingAssignToSlotsActions.assignCandidateCancel,
    (state) => ({
      ...state,
      savingAssignment: false,
    }),
  ),

  /** Reject slots for candidate **/
  on(schedulingRejectSlotsActions.rejectSlotsForCandidate, (state) => ({
    ...state,
    savingAssignment: true,
  })),
  on(schedulingRejectSlotsActions.rejectSlotsForCandidateFailure, (state, { error }) => ({
    ...state,
    savingAssignment: false,
    error: error,
  })),
  on(schedulingRejectSlotsActions.rejectSlotsForCandidateSuccess, (state) => ({
    ...state,
    savingAssignment: false,
    selectedSlotsForRejection: [],
  })),

  on(schedulingActions.startLoadingSlots, (state) => ({
    ...state,
    loadingSlots: true,
  })),
  on(schedulingActions.startLoadingCandidates, (state) => ({
    ...state,
    loadingCandidates: true,
  })),
  on(schedulingActions.setSlotListFailure, (state) => ({
    ...state,
    loadingSlots: false,
  })),
  on(schedulingActions.setCandidateListFailure, (state) => ({
    ...state,
    loadingCandidates: false,
  })),
  on(schedulingApproachCandidatesActions.setActiveApproach, (state) => ({
    ...state,
    loadingSlots: true,
  })),
  on(schedulingApproachCandidatesActions.approach, (state) => ({
    ...state,
    savingApproach: true,
  })),
  on(
    schedulingApproachCandidatesActions.createApproachSuccess,
    (state, { communicateImmediately, approaches }) => {
      if (communicateImmediately) {
        return {
          ...state,
          selectedId: jsonLdSelectId(approaches[0]),
          savingApproach: communicateImmediately ? state.savingApproach : false,
          error: null,
        };
      }
      return {
        ...state,
        savingApproach: communicateImmediately ? state.savingApproach : false,
        error: null,
      };
    },
  ),
  on(schedulingApproachCandidatesActions.createApproachFailure, (state, { error }) => ({
    ...state,
    savingApproach: false,
    error: error,
  })),
  on(schedulingApproachCandidatesActions.communicateSuccess, (state) => ({
    ...state,
    selectedSlots: [],
    savingApproach: false,
  })),
  on(schedulingApproachCandidatesActions.setActiveApproach, (state, { approach }) => ({
    ...state,
    selectedId: approach,
  })),
  on(
    schedulingApproachCandidatesActions.createApproachSuccess,
    schedulingAssignToSlotsActions.assignCandidateSuccess,
    (state) => ({
      ...state,
      selectedSlots: [],
      selectedCandidates: [],
      candidateList: [],
    }),
  ),
);

export function schedulingReducer(state: SchedulingState | undefined, action: Action) {
  return reducer(state, action);
}
