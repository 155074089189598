<div
  class="menu"
  [class.expanded]="isExpanded()"
  [class.no-submenu]="!activeMenuItem()?.submenu"
  [@expandMenu]="isExpanded()"
  >
  <ion-item lines="none" [routerLink]="['/']" class="logo">
    @defer {
    <div class="image">
      <svg
        width="35"
        height="43"
        viewBox="0 0 35 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
          d="M33.0352 12.2129L1.45932 12.2129C0.730217 12.2129 0.113281 12.8426 0.113281 13.5868L0.113281 35.8555C0.113281 36.5997 0.730217 37.2295 1.45932 37.2295L22.0986 37.2295L26.8659 42.0954C27.1463 42.3816 27.651 42.1526 27.651 41.7519L27.651 37.2295L33.0913 37.2295C33.8204 37.2295 34.4373 36.5997 34.4373 35.8555L34.4373 13.5868C34.3812 12.8426 33.7643 12.2129 33.0352 12.2129Z"
          fill="#2CD5C4"
          />
        <path
          d="M26.5854 30.5886L1.34715 36.9429C0.730217 37.1146 0.113281 36.5994 0.113281 35.9124L0.113281 13.3002C0.113281 12.6705 0.505877 12.0981 1.12281 11.9836L25.9685 5.74373C26.8098 5.51475 27.651 6.2017 27.651 7.06039L27.651 29.2146C27.595 29.8444 27.2024 30.4168 26.5854 30.5886Z"
          fill="#FFB81C"
          />
        <path
          d="M0.954557 11.6975L17.9483 0.305565C19.1822 -0.495881 20.8087 0.362811 20.8087 1.90846L20.8087 22.3453C20.8087 22.975 20.4722 23.6048 19.9674 23.9482L1.1789 36.5424C0.730217 36.8286 0.113281 36.4851 0.113281 35.9699L0.113281 13.2432C0.113281 12.6135 0.449791 12.041 0.954557 11.6975Z"
          fill="#F23154"
          />
      </svg>
    </div>
  }
  @if (header() && isExpanded()) {
    <ion-label>{{ header() }}</ion-label>
  }
</ion-item>

<ng-content select="slot[top]"></ng-content>
<ng-content></ng-content>
<ng-content select="slot[bottom]"></ng-content>

<div class="footer">
  <ng-content select="[slot=footer]"></ng-content>
  <tt-menu-item
    [showLabelWhenCollapsed]="false"
    class="expand-button"
    data-test="tt-menu-expand"
    (click)="toggleExpandMenu()"
    [disablePopover]="true"
    [icon]="'double-chevron'"
    [title]="'Menu inklappen'"
    >
  </tt-menu-item>
</div>
</div>

@if (activeMenuItem()?.submenu) {
  @defer (on timer(10ms)) {
  @if (activeMenuItem()?.submenu) {
    <tt-sub-menu
      [template]="activeMenuItem()?.submenu() ?? null"
      [title]="activeMenuItem()?.title()"
      >
    </tt-sub-menu>
  }
}
}

<ion-router-outlet [animated]="false"></ion-router-outlet>
