import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ApproachContract,
  ApproachDetailedContract,
  ApproachHasSlotContract,
  isApproachDetailed,
} from '@scheduler-frontend/approach-contracts';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { formatDatesToCombinedString } from '@scheduler-frontend/common';
import { LocationContract, LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import {
  RoleContract,
  RoleDetailedContract,
  RolesSelectors,
} from '@scheduler-frontend/data-access-roles';
import {
  SchedulingApproachSelectors,
  SchedulingSelectors,
} from '@scheduler-frontend/data-access-scheduling';
import { SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { SubjectContract, SubjectsSelectors } from '@scheduler-frontend/data-access-subjects';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { ChooseableLevelEnum, LevelDisplayValues } from '@scheduler-frontend/enums';
import {
  WhatsAppDataStringsInput,
  whatsAppTemplateApproach,
} from '@scheduler-frontend/scheduling-common';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { compareAsc, formatISO } from 'date-fns';
import {
  APPROACHES_FEATURE_KEY,
  approachesAdapter,
  ApproachesState,
} from '../reducer/approaches.reducer';

const { selectAll, selectEntities } = approachesAdapter.getSelectors();

export class ApproachesSelectors {
  public static readonly state = createFeatureSelector<ApproachesState>(APPROACHES_FEATURE_KEY);

  public static readonly loaded = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => state.loaded,
  );

  public static readonly loading = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => state.loading,
  );

  public static readonly saving = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => state.saving,
  );

  public static readonly loadingDetailed = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => state.loadingDetailed,
  );

  public static readonly error = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => state.error,
  );

  public static readonly approaches = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => selectAll(state),
  );

  public static readonly approachEntities = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => selectEntities(state),
  );

  public static readonly activeApproach = createSelector(
    ApproachesSelectors.approachEntities,
    SchedulingApproachSelectors.activeApproachId,
    (entities, selectedId): ApproachContract | ApproachDetailedContract | undefined =>
      selectedId ? entities[selectedId] : undefined,
  );

  public static selectApproach(approachId: string) {
    return createSelector(ApproachesSelectors.state, (state: ApproachesState) => {
      return selectEntities(state)[approachId];
    });
  }

  public static slotList(approachId: string) {
    return createSelector(
      SlotsSelectors.slots,
      SlotsSelectors.slotEntities,
      ApproachesSelectors.selectApproach(approachId),
      (slots, slotEntities, approach) => {
        if (slots.length === 0 || !approach || !isApproachDetailed(approach)) {
          return [];
        }

        const slotIds: string[] = approach.approachHasSlots.map(
          (approachHasSlot: ApproachHasSlotContract) => {
            return jsonLdSelectId(approachHasSlot.slot);
          },
        );

        return slotIds.map((id: string) => slotEntities[id]).filter((slot) => !!slot) as (
          | SlotContract
          | SlotDetailedContract
        )[];
      },
    );
  }

  public static slotListGroupedByDate(approachId: string) {
    return createSelector(ApproachesSelectors.slotList(approachId), (slots) => {
      if (!slots) {
        return undefined;
      }

      // For debugging purposes it is easier to have the slots sorted by date
      // and put into the dictionary in that order.
      slots = slots.sort((slotA, slotB) =>
        compareAsc(slotA.timePeriod.start, slotB.timePeriod.start),
      );

      const slotsByDateDict: Dictionary<(SlotDetailedContract | SlotContract)[]> = {};
      for (let slot of slots) {
        const dateKey: string = formatISO(slot.timePeriod.start, {
          representation: 'date',
        });
        if (slotsByDateDict[dateKey]) {
          slotsByDateDict[dateKey]?.push(slot);
        } else {
          slotsByDateDict[dateKey] = [slot];
        }
      }

      return slotsByDateDict;
    });
  }

  public static slotListGroupedByDateAndAssignmentForApproach(approachId: string) {
    return createSelector(ApproachesSelectors.slotListGroupedByDate(approachId), (slots) => {
      if (!slots) {
        return undefined;
      }

      const dictByAssignment: Dictionary<(SlotDetailedContract | SlotContract)[]> =
        SchedulingSelectors.createSlotsDictionaryByAssignment(slots);
      return SchedulingSelectors.createSlotsDictionaryByDateAndAssignment(dictByAssignment);
    });
  }

  public static readonly activeApproachSlotIds = createSelector(
    ApproachesSelectors.activeApproach,
    (active) => {
      if (!active?.hasOwnProperty('approachHasSlots')) {
        return undefined;
      }

      return (active as ApproachDetailedContract)?.approachHasSlots
        ? (active as ApproachDetailedContract).approachHasSlots.map((item) =>
            jsonLdSelectId(item.slot['@id']),
          )
        : undefined;
    },
  );

  public static readonly selectedAssigneeFilter = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => state.selectedAssigneeFilter,
  );

  public static readonly selectedCandidateSearch = createSelector(
    ApproachesSelectors.state,
    (state: ApproachesState) => state.selectedCandidateSearch,
  );

  public static readonly whatsappMessage = createSelector(
    ApproachesSelectors.activeApproach,
    SchedulingSelectors.selectedSlotsByAssignment,
    LocationsSelectors.locationsEntities,
    RolesSelectors.roleEntities,
    SubjectsSelectors.subjectEntities,
    UsersSelectors.activeUser,
    (approach, slotsByDateAndAssignmentDict, locations, roles, subjects, user) => {
      if (!approach || !slotsByDateAndAssignmentDict) {
        return undefined;
      }

      const whatsAppData: WhatsAppDataStringsInput[] = [];

      // Iterate over each date and assignment.
      for (const slotsByAssignmentsDict of Object.values(slotsByDateAndAssignmentDict)) {
        if (slotsByAssignmentsDict) {
          // Iterate over each assignment.
          for (const slotsByAssignment of Object.values(slotsByAssignmentsDict)) {
            //eslint-disable-next-line max-depth
            if (!slotsByAssignment) {
              continue;
            }

            whatsAppData.push(
              ApproachesSelectors.createWhatsAppMessageDataForAssignment(
                slotsByAssignment,
                roles,
                subjects,
                locations,
              ),
            );
          }
        }
      }

      return whatsAppTemplateApproach({
        candidateName: approach.candidate.fullName,
        userName: user?.firstName ?? 'Het Lyceo Roosterteam',
        data: whatsAppData,
      });
    },
  );

  private static createWhatsAppMessageDataForAssignment(
    slotsByAssignment: (SlotContract | SlotDetailedContract)[],
    roles: Dictionary<RoleContract | RoleDetailedContract>,
    subjects: Dictionary<SubjectContract>,
    locations: Dictionary<LocationContract>,
  ): WhatsAppDataStringsInput {
    const assignmentData: WhatsAppDataStringsInput = slotsByAssignment.reduce(
      (accumulated: WhatsAppDataStringsInput, slot: SlotContract | SlotDetailedContract) => {
        accumulated.role = roles[jsonLdSelectId(slot.role)]?.abbreviation ?? '';
        accumulated.subject =
          slot.lesson && 'subject' in slot.lesson
            ? subjects[jsonLdSelectId(slot.lesson.subject)]?.name ?? ''
            : '';
        accumulated.level =
          slot.lesson && 'level' in slot.lesson
            ? LevelDisplayValues[slot.lesson.level as ChooseableLevelEnum]
            : '';
        accumulated.location =
          slot.lesson && slot.lesson.location
            ? locations[jsonLdSelectId(slot.lesson.location)]?.city ?? 'online'
            : 'online';

        return accumulated;
      },
      { date: '', role: '', subject: '', level: '', location: '' },
    );

    const dates: string[] = slotsByAssignment.map(
      (slot: SlotContract | SlotDetailedContract) => slot.timePeriod.start,
    );
    assignmentData.date = formatDatesToCombinedString(dates, 'd MMMM');

    return assignmentData;
  }
}
