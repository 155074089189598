import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { SubjectContract } from '../../contracts/subject.contract';

@Injectable({
  providedIn: 'root',
})
export class SubjectApi {
  private httpClient = inject(HttpClient);

  public getSubjects(): Observable<Collection<SubjectContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/subjects`;
    return this.httpClient.get<Collection<SubjectContract>>(url);
  }
}
