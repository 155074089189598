import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AWAITING_FOLLOW_UP_SLOTS_FEATURE_KEY,
  awaitingSlotsAdapter,
  AwaitingSlotsState,
} from './awaiting-follow-up-slots.reducer';

const { selectAll, selectEntities, selectTotal } = awaitingSlotsAdapter.getSelectors();

export class AwaitingFollowUpSlotsSelectors {
  public static readonly state = createFeatureSelector<AwaitingSlotsState>(
    AWAITING_FOLLOW_UP_SLOTS_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    AwaitingFollowUpSlotsSelectors.state,
    (state: AwaitingSlotsState) => state.loading,
  );

  public static readonly loaded = createSelector(
    AwaitingFollowUpSlotsSelectors.state,
    (state: AwaitingSlotsState) => state.loaded,
  );

  public static readonly error = createSelector(
    AwaitingFollowUpSlotsSelectors.state,
    (state: AwaitingSlotsState) => state.error,
  );

  public static readonly pupilsWithoutFollowUpSlot = createSelector(
    AwaitingFollowUpSlotsSelectors.state,
    (state: AwaitingSlotsState) => selectAll(state),
  );

  public static readonly pupilsWithoutFollowUpSlotCount = createSelector(
    AwaitingFollowUpSlotsSelectors.state,
    (state: AwaitingSlotsState) => selectTotal(state),
  );

  public static readonly pupilsWithoutFollowUpSlotEntities = createSelector(
    AwaitingFollowUpSlotsSelectors.state,
    (state: AwaitingSlotsState) => selectEntities(state),
  );
}
