/**
 * This model contains certain calculated statistics about a group of slots.
 * This is for example used by the {@see LocationPicker} to show the radial
 * about slot being filled.
 *
 * @todo fix this
 * @hj this also looks verry nasty and crap. no idea where is comes from and where it's used
 * @deprecated for now until we know where is for
 */
export class Statistic {
  constructor(
    /**
     * total amount of slot within this group
     */
    public total: number = 0,
    /**
     * Amount of slot that are filled.
     */
    public filled: number = 0,
    /**
     * Amount of slot that are waiting for confirmation.
     */
    public waitingForConfirmation: number = 0,
    /**
     * Amount of slot that are in draft.
     */
    public draft: number = 0,
    /**
     * Percentage for completion where 100% means that all slot are filled
     */
    public percentageDone: number = 0,
  ) {}
}
