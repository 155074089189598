import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ApproachContract } from '@scheduler-frontend/approach-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { approachesToDoActions } from '../actions/approaches-to-do.actions';
import { approachesActions } from '../actions/approaches.actions';

export const APPROACHES_TO_DO_FEATURE_KEY = 'approachesToDo';

export interface ApproachesToDoState extends EntityState<ApproachContract> {
  loaded: boolean;
  loading: boolean;
  loadedChunks: number[];
  lastChunkLoaded: boolean;
  totalItems?: number | null;
  error?: unknown | null;
}

export const adapter: EntityAdapter<ApproachContract> = createEntityAdapter<ApproachContract>({
  selectId: jsonLdSelectId,
});

export const initialApproachesToDoState: ApproachesToDoState = adapter.getInitialState({
  loaded: false,
  loading: false,
  loadedChunks: [],
  lastChunkLoaded: false,
  error: null,
});

const reducer = createReducer(
  initialApproachesToDoState,
  on(approachesToDoActions.loadApproaches, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(approachesToDoActions.loadNextChunk, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(approachesToDoActions.loadApproachesSuccess, (state, { approaches, chunk, totalItems }) =>
    adapter.setAll(approaches, {
      ...state,
      loading: false,
      loaded: true,
      loadedChunks: [chunk],
      lastChunkLoaded: approaches.length === totalItems,
      error: null,
    }),
  ),
  on(approachesToDoActions.addApproach, (state, { approach }) =>
    adapter.setOne(approach, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),
  ),
  on(approachesToDoActions.removeApproach, (state, { approach }) =>
    adapter.removeOne(jsonLdSelectId(approach['@id']), {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),
  ),
  on(approachesToDoActions.loadNextChunkSuccess, (state, { approaches, chunk, totalItems }) =>
    adapter.addMany(approaches, {
      ...state,
      loading: false,
      loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()],
      lastChunkLoaded: state.ids.length + approaches.length === totalItems,
      error: null,
    }),
  ),
  on(approachesToDoActions.loadApproachesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(approachesActions.setActiveAssigneeFilter, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(approachesActions.setActiveCandidateSearch, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
);

export function approachesToDoReducer(state: ApproachesToDoState | undefined, action: Action) {
  return reducer(state, action);
}
