import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { IonicModule } from '@ionic/angular';
import { CapitalizePipe, TtDateRangePipe } from '@techniek-team/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tt-date-picker-header',
  templateUrl: './tt-date-picker-header.component.html',
  styleUrls: ['./tt-date-picker-header.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TtDateRangePipe,
    CapitalizePipe,
  ],
})
export class TtDatePickerHeaderComponent<D extends Date | DateRange<Date>> implements OnDestroy {

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    protected calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    cdr: ChangeDetectorRef,
  ) {
    calendar.stateChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => cdr.markForCheck());
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public previousYear(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  public previousMonth(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
  }

  public nextYear(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }

  public nextMonth(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
  }
}
