import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { slotTemplateActions } from './+state/actions/slot-template.actions';
import { SelectedSelectors } from './+state/selectors/selected.selectors';

@Injectable()
export class CreateSlotSetStoreService {
  private readonly store = inject(Store);

  public changeFormValidity(
    change: ReturnType<typeof slotTemplateActions.changeFormValidity>['change'],
  ) {
    this.store.dispatch(slotTemplateActions.changeFormValidity({ change: change }));
  }

  public changeProductLocation(
    change: ReturnType<typeof slotTemplateActions.changeProductLocation>['change'],
  ) {
    this.store.dispatch(slotTemplateActions.changeProductLocation({ change: change }));
  }

  public changeDeliveryType(
    change: ReturnType<typeof slotTemplateActions.changeDeliveryType>['change'],
  ) {
    this.store.dispatch(slotTemplateActions.changeDeliveryType({ change: change }));
  }

  public async changeBusinessService(
    change: ReturnType<typeof slotTemplateActions.changeBusinessService>['change'],
  ) {
    const current = await firstValueFrom(
      this.store.pipe(select(SelectedSelectors.businessService)),
    );
    if (change['@id'] !== current?.['@id'] || !current) {
      this.store.dispatch(slotTemplateActions.changeBusinessService({ change: change }));
    }
  }

  public changeWhoRole(
    index: number,
    change: ReturnType<typeof slotTemplateActions.changeWhoRole>['change'],
  ) {
    this.store.dispatch(slotTemplateActions.changeWhoRole({ change: change, index: index }));
  }

  public changeWhoTimeRange(
    index: number,
    change: ReturnType<typeof slotTemplateActions.changeWhoTimeRange>['change'],
  ) {
    this.store.dispatch(slotTemplateActions.changeWhoTimeRange({ change: change, index: index }));
  }

  public changeWhoAmountOfPupils(
    index: number,
    change: ReturnType<typeof slotTemplateActions.changeWhoAmountOfPupils>['change'],
  ) {
    this.store.dispatch(
      slotTemplateActions.changeWhoAmountOfPupils({ change: change, index: index }),
    );
  }

  public changeWhoOperationalLocation(
    index: number,
    change: ReturnType<typeof slotTemplateActions.changeWhoOperationalLocation>['change'],
  ) {
    this.store.dispatch(
      slotTemplateActions.changeWhoOperationalLocation({ change: change, index: index }),
    );
  }
  public changeWhoSubject(
    index: number,
    change: ReturnType<typeof slotTemplateActions.changeWhoSubject>['change'],
  ) {
    this.store.dispatch(slotTemplateActions.changeWhoSubject({ change: change, index: index }));
  }

  public changeWhoLevel(
    index: number,
    change: ReturnType<typeof slotTemplateActions.changeWhoLevel>['change'],
  ) {
    this.store.dispatch(slotTemplateActions.changeWhoLevel({ change: change, index: index }));
  }

  public changeSchedule(change: ReturnType<typeof slotTemplateActions.changeSchedule>['change']) {
    this.store.dispatch(slotTemplateActions.changeSchedule({ change: change }));
  }

  public changeWhen(change: ReturnType<typeof slotTemplateActions.changeWhen>['change']) {
    this.store.dispatch(slotTemplateActions.changeWhen({ change: change }));
  }
}
