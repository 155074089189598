import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { CandidateMinimalContract } from '@scheduler-frontend/data-access-users';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import { catchError, map, of, switchMap } from 'rxjs';
import { schedulingRejectSlotsActions } from '../actions/scheduling-reject-slots.actions';
import { AssignApi } from '../api/assign.api';
import { RejectApi } from '../api/reject.api';
import { SchedulingSelectors } from '../selectors/scheduling.selectors';

@Injectable()
export class SchedulingRejectSlotsEffect {
  private readonly actions$: Actions = inject(Actions);

  private readonly store: Store = inject(Store);

  private readonly assignApi: AssignApi = inject(AssignApi);

  private readonly rejectApi: RejectApi = inject(RejectApi);

  private readonly router: Router = inject(Router);

  public rejectSlotsForCandidates = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingRejectSlotsActions.rejectSlotsForCandidate),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.selectedCandidates),
        this.store.select(SchedulingSelectors.selectedSlotForRejectionIds),
      ]),
      switchMap(([action, candidates, slotIds]) =>
        this.rejectApi
          .postCandidateRejection({
            candidate: jsonLdSelectId(candidates[0]?.['@id'] as string),
            // TODO This has to be replaced by actually looking up the correct
            //  decline reason, but currently this is not possible. This uuid is
            //  from the decline reason: "Niet (meer) geïnteresseerd in dienst".
            declineReason: 'd84c1175-2e98-457d-9947-674412553629',
            slots: slotIds,
          })
          .pipe(
            map(() =>
              schedulingRejectSlotsActions.rejectSlotsForCandidateSuccess({
                candidate: candidates[0] as CandidateMinimalContract,
              }),
            ),
            catchError((error) =>
              of(schedulingRejectSlotsActions.rejectSlotsForCandidateFailure({ error: error })),
            ),
          ),
      ),
    ),
  );

  public rejectSlotsForCandidatesFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(schedulingRejectSlotsActions.rejectSlotsForCandidateFailure, {
          message: 'Er is iets misgegaan bij het afwijzen van de shift(s) voor de begeleider.',
        }),
      ),
    { dispatch: false },
  );

  public rejectSlotsForCandidatesSuccess = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(schedulingRejectSlotsActions.rejectSlotsForCandidateSuccess, {
          message: (action) =>
            `De shifts voor ${action.candidate.fullName} zijn succesvol afgewezen.`,
        }),
      ),
    { dispatch: false },
  );
}
