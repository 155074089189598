import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, select, Store } from '@ngrx/store';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { exhaustMap, filter, from, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { actionsActions } from './actions.actions';
import { ActionState } from './actions.reducer';
import { ActionsSelectors } from './actions.selectors';
import { DeleteActionsApi } from './api/delete-actions.api';
import { LoadActionsApi } from './api/load-actions.api';

@Injectable()
export class ActionsEffects implements OnInitEffects {
  private readonly actions = inject(Actions);

  private readonly actionsApi = inject(LoadActionsApi);

  private readonly deleteActionsApi = inject(DeleteActionsApi);

  private readonly store = inject(Store<ActionState>);

  private readonly modalController = inject(ModalController);

  public ngrxOnInitEffects(): Action {
    return actionsActions.loadActions();
  }

  public loadActions = createEffect(() =>
    this.actions.pipe(
      ofType(actionsActions.loadActions),
      exhaustMap(() =>
        this.actionsApi.execute(1).pipe(
          map((response) =>
            actionsActions.loadActionsSuccess({
              actions: response['hydra:member'],
              totalItems: response['hydra:totalItems'],
              chunk: 1,
            }),
          ),
          catchError((error) => of(actionsActions.loadActionsFailure({ error: error }))),
        ),
      ),
    ),
  );

  public loadNextChunk = createEffect(() =>
    this.actions.pipe(
      ofType(actionsActions.loadActionsNextChunk),
      concatLatestFrom(() => this.store.pipe(select(ActionsSelectors.lastLoadedChunk))),
      exhaustMap(([_, lastChunk]) =>
        this.actionsApi.execute(lastChunk + 1).pipe(
          map((response) =>
            actionsActions.loadActionsNextChunkSuccess({
              actions: response['hydra:member'],
              chunk: lastChunk + 1,
              totalItems: response['hydra:totalItems'],
            }),
          ),
          catchError((error) => of(actionsActions.loadActionsFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly deleteAction = createEffect(() =>
    this.actions.pipe(
      ofType(actionsActions.deleteAction),
      exhaustMap(({ id }) => {
        return from(
          this.modalController.create({
            component: TtSimpleModalComponent,
            componentProps: {
              title: 'Verwijdering bevestigen',
              message: 'Weet je zeker dat je deze actie wilt verwijderen?',
            },
          }),
        ).pipe(
          switchMap((confirmModal) => confirmModal.present().then(() => confirmModal)),
          switchMap((confirmModal) => confirmModal.onWillDismiss()),
          filter(({ role }) => role === 'confirm'),
          exhaustMap(() =>
            this.deleteActionsApi.execute(id).pipe(
              map(() => actionsActions.deleteActionSuccess({ id: id })),
              catchError((error) => of(actionsActions.deleteActionFailure({ error: error }))),
            ),
          ),
        );
      }),
    ),
  );

  public readonly handleLoadActionsFailures = createEffect(
    () =>
      this.actions.pipe(
        handleEndpointFailure(actionsActions.loadActionsFailure, {
          message: 'Laden van acties mislukt.',
        }),
      ),
    { dispatch: false },
  );

  public readonly handleDeleteActionFailures = createEffect(
    () =>
      this.actions.pipe(
        handleEndpointFailure(actionsActions.deleteActionFailure, {
          message: 'Verwijderen van actie mislukt.',
        }),
      ),
    { dispatch: false },
  );
}
