import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  adapter,
  CANDIDATE_APPROACHES_FEATURE_KEY,
  CandidateApproachesState,
} from '../reducers/candidate-approaches.reducer';

const { selectAll, selectEntities } = adapter.getSelectors();

export class CandidateApproachesSelectors {
  public static readonly state = createFeatureSelector<CandidateApproachesState>(
    CANDIDATE_APPROACHES_FEATURE_KEY,
  );

  public static readonly loaded = createSelector(
    CandidateApproachesSelectors.state,
    (state: CandidateApproachesState) => state.loaded,
  );

  public static readonly loading = createSelector(
    CandidateApproachesSelectors.state,
    (state: CandidateApproachesState) => state.loading,
  );

  public static readonly loadedChunks = createSelector(
    CandidateApproachesSelectors.state,
    (state: CandidateApproachesState) => state.loadedChunks,
  );

  public static readonly lastChunkLoaded = createSelector(
    CandidateApproachesSelectors.state,
    (state: CandidateApproachesState) => state.lastChunkLoaded,
  );

  public static readonly error = createSelector(
    CandidateApproachesSelectors.state,
    (state: CandidateApproachesState) => state.error,
  );

  public static readonly approaches = createSelector(
    CandidateApproachesSelectors.state,
    (state: CandidateApproachesState) => (state.loaded ? selectAll(state) : undefined),
  );

  public static readonly approachesEntities = createSelector(
    CandidateApproachesSelectors.state,
    (state: CandidateApproachesState) => selectEntities(state),
  );
}
