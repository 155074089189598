import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AvailabilityContract, AvailabilityTypeEnum } from '@scheduler-frontend/calendar-contracts';
import { Resource } from '@techniek-team/api-platform';
import { PlainRangeInterface } from '@techniek-team/class-transformer';

export const candidateAvailabilitiesActions = createActionGroup({
  source: 'Candidate/Availabilities',
  events: {
    initAvailabilities: props<{ range: PlainRangeInterface<string> }>(),
    loadAvailabilitiesSuccess: props<{
      availabilities: Resource<AvailabilityContract>[];
    }>(),
    appendAvailabilities: props<{ range: PlainRangeInterface<string> }>(),
    appendAvailabilitiesSuccess: props<{
      availabilities: Resource<AvailabilityContract>[];
    }>(),
    refreshAvailabilities: props<{ range: PlainRangeInterface<string> }>(),
    refreshAvailabilitiesSuccess: props<{
      availabilities: Resource<AvailabilityContract>[];
    }>(),
    loadAvailabilitiesFailure: props<{ error: unknown }>(),
    removeAvailability: props<{ id: string }>(),
    removeAvailabilitySuccess: emptyProps(),
    removeAvailabilityFailure: props<{ error: unknown }>(),
    removeCalendarEventFailure: props<{ error: unknown }>(),
    setCandidateAvailability: props<{
      timePeriod: PlainRangeInterface<string>;
      candidateId: string;
      availabilityValue: AvailabilityTypeEnum;
      remarks?: string | null;
    }>(),
    setCandidateAvailabilitySuccess: props<{ range: PlainRangeInterface<string> }>(),
    setCandidateAvailabilityFailure: props<{ error: unknown }>(),
  },
});
