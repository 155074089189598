import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CANDIDATE_ASSIGNMENTS_HISTORY_FEATURE_KEY,
  candidateAssignmentsHistoryAdapter,
  CandidateAssignmentsHistoryState,
} from '../reducers/candidate-assignments-history.reducer';

const { selectAll } = candidateAssignmentsHistoryAdapter.getSelectors();

export class CandidateAssignmentsHistorySelectors {
  public static readonly state = createFeatureSelector<CandidateAssignmentsHistoryState>(
    CANDIDATE_ASSIGNMENTS_HISTORY_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    CandidateAssignmentsHistorySelectors.state,
    (state) => state.loading,
  );

  public static readonly loaded = createSelector(
    CandidateAssignmentsHistorySelectors.state,
    (state) => state.loaded,
  );

  public static readonly loadedChunks = createSelector(
    CandidateAssignmentsHistorySelectors.state,
    (state) => state.loadedChunks,
  );

  public static readonly lastChunkLoaded = createSelector(
    CandidateAssignmentsHistorySelectors.state,
    (state) => state.lastChunkLoaded,
  );

  public static readonly error = createSelector(
    CandidateAssignmentsHistorySelectors.state,
    (state) => state.error,
  );

  public static readonly selectAll = createSelector(
    CandidateAssignmentsHistorySelectors.state,
    (state) => selectAll(state),
  );
}
