import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CandidateMinimal } from '@scheduler-frontend/data-access-users';
import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';

export enum RecordType {
  GENERIC = 'GENERIC',
  TRANSITION = 'TRANSITION',
}

export class LogRecord extends JsonLd {
  public readonly icon!: IconProp;

  public readonly iconColor!: string;

  public readonly type!: RecordType;

  constructor(
    public readonly initiatedAt: Date,
    public readonly initiatedBy?: CandidateMinimal,
    public readonly sortPriority?: number,
  ) {
    super();
    this.initiatedAt = initiatedAt;
    this.initiatedBy = initiatedBy;
    this.sortPriority = sortPriority;
  }
}
