<ion-content class="content">
  <div>
    <ion-card>
      <img
        [ngSrc]="logoUrl"
        width="600"
        height="200"
        alt="Logo"
        [priority]="true"
        class="logo ion-margin-bottom"
      />
      @if (authenticating()) {
        <ion-spinner color="tertiary" name="crescent"> </ion-spinner>
      }
      @if (!authenticating()) {
        <ion-button
          (click)="login()"
          data-test="login-button"
          expand="block"
          color="secondary"
          id="btn-login"
        >
          Inloggen
        </ion-button>
      }
    </ion-card>
  </div>
  <div class="white-background"></div>
  <div class="green-background"></div>
</ion-content>
