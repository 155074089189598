import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AwaitingFirstSlotsSelectors } from './+state/awaiting-first-slots.selectors';
import { AwaitingFollowUpSlotsSelectors } from './+state/awaiting-follow-up-slots.selectors';
import { awaitingSlotsActions } from './+state/awaiting-slots.actions';

@Injectable()
export class AwaitingSlotsStoreService {
  private readonly store = inject(Store);

  public pupilsWithoutFirstSlotLoading = this.store.selectSignal(
    AwaitingFirstSlotsSelectors.loading,
  );
  public pupilsWithoutFirstSlotLoaded = this.store.selectSignal(AwaitingFirstSlotsSelectors.loaded);

  public pupilsWithoutFirstSlot = this.store.selectSignal(
    AwaitingFirstSlotsSelectors.pupilsWithoutFirstSlot,
  );

  public pupilsWithoutFirstSlotCount = this.store.selectSignal(
    AwaitingFirstSlotsSelectors.pupilsWithoutFirstSlotCount,
  );

  public pupilsWithoutFollowUpSlot = this.store.selectSignal(
    AwaitingFollowUpSlotsSelectors.pupilsWithoutFollowUpSlot,
  );

  public pupilsWithoutFollowUpSlotCount = this.store.selectSignal(
    AwaitingFollowUpSlotsSelectors.pupilsWithoutFollowUpSlotCount,
  );

  public pupilsWithoutFollowUpSlotLoading = this.store.selectSignal(
    AwaitingFollowUpSlotsSelectors.loading,
  );

  public pupilsWithoutFollowUpSlotLoaded = this.store.selectSignal(
    AwaitingFollowUpSlotsSelectors.loaded,
  );

  public reloadWithoutFollowUpSlots(): void {
    this.store.dispatch(awaitingSlotsActions.loadWithoutFollowUpSlotReload());
  }

  public reloadWithoutFirstSlots(): void {
    this.store.dispatch(awaitingSlotsActions.loadWithoutFirstSlotReload());
  }
}
