import { DateRange } from '@angular/material/datepicker';
import {
  TsRangeInterface as CommonTsRangeInterface,
  TtDateRangePipe,
} from '@techniek-team/common';
import { Exclude, Expose, Type } from 'class-transformer';
import {
  addMilliseconds,
  differenceInMinutes,
  format,
  formatISO,
  getDate,
  getMonth,
  getYear,
  Interval,
  isSameDay,
  isWithinInterval,
  set,
  subMilliseconds,
} from 'date-fns';
import { FormatOptions } from 'date-fns/format';
import { nl } from 'date-fns/locale';
import { denormalize } from '../../../transformers/denormalize';
import { PlainRangeInterface, RangeModel } from '../range.model';

export type TsRangeInterfaceA = PlainRangeInterface;

/**
 * @deprecated use inclusiveStart and inclusiveEnd instead of startInclusive and endInclusive. @HJ 2023-02-22
 */
export interface TsRangeInterfaceB {
  start: string;
  end: string;
  startInclusive: boolean;
  endInclusive: boolean;
}

export type TsRangeInterface = TsRangeInterfaceA | TsRangeInterfaceB;

export class TsRange
  extends RangeModel<Date>
  implements CommonTsRangeInterface<Date>
{
  @Type(TsRange.getDate)
  @Expose()
  public declare start: Date;

  @Type(TsRange.getDate)
  @Expose()
  public declare end: Date;

  /**
   * @deprecated use inclusiveStart and inclusiveEnd instead of startInclusive and endInclusive. @HJ 2023-02-22
   */
  @Exclude() public set startInclusive(state: boolean) {
    this.inclusiveStart = state ?? true;
  }

  /**
   * @deprecated use inclusiveStart and inclusiveEnd instead of startInclusive and endInclusive. @HJ 2023-02-22
   */
  @Exclude() public set endInclusive(state: boolean) {
    this.inclusiveEnd = state ?? true;
  }

  /**
   * Either include or exclude the start from the range.
   */
  public get min(): Date {
    if (this.inclusiveStart) {
      return this.start;
    }
    return addMilliseconds(this.start, 1);
  }

  /**
   * Either include or exclude the end from the range.
   */
  public get max(): Date {
    if (this.inclusiveEnd) {
      return this.end;
    }
    return subMilliseconds(this.end, 1);
  }

  /**
   * Returns the duration in minutes.
   */
  public duration(): number {
    return differenceInMinutes(this.min, this.max);
  }

  /**
   * @inheritDoc
   */
  public contains(date: Date): boolean {
    return isWithinInterval(date, this.interval);
  }

  /**
   * Returns a human-readable string.
   *
   * @param divider between the to dates. by default the dates are seperated by
   * a dash: ' - '.
   * @param formatString
   */
  public humanReadableString(
    divider: string = ' - ',
    formatString?: string,
  ): string {
    return new TtDateRangePipe('nl-NL', {}).transform(this, {
      divider: divider,
      format: formatString ?? 'eee d-LLL HH:mm',
    });
  }

  /**
   * Formatting the TsRange using the date-fns format string. and dividing it
   * with the given divider parameter string.
   *
   * @param formatString date-fns format string. Returns the default
   * {@see TsRange.humanReadableString} if empty.
   * @param divider between the to dates. by default the dates are seperated by
   * a dash: ' - '.
   * @param options object given to the format functions.
   */
  public format(
    formatString: string,
    divider: string = ' - ',
    options?: FormatOptions,
  ): string {
    if (!options) {
      options = { locale: nl };
    }

    if (formatString) {
      return `${format(this.min, formatString, options)}${divider}${format(this.max, formatString, options)}`;
    }

    return this.humanReadableString(divider, formatString);
  }

  /**
   * the method return a tsRange changing the day of both start and end to the
   * given parameters. Without changes the time currently set.
   */
  public setDay(startDay: Date, endDay: Date | null = null): TsRange {
    if (!endDay) {
      endDay = startDay;
    }
    return new TsRange(
      set(this.start, {
        year: getYear(startDay),
        month: getMonth(startDay),
        date: getDate(startDay),
      }),
      set(this.end, {
        year: getYear(endDay),
        month: getMonth(endDay),
        date: getDate(endDay),
      }),
      this.inclusiveStart,
      this.inclusiveEnd,
    );
  }

  /**
   * Converts the given timeRange into a DateFns interval.
   */
  public get interval(): Interval {
    return { start: this.start, end: this.end };
  }

  public get dateRange(): DateRange<Date> {
    return new DateRange<Date>(this.start, this.end);
  }

  /**
   * Instantiate the int-range class using the object
   * returned in the api response
   */
  public static fromObject(data: TsRangeInterface): TsRange {
    return denormalize(TsRange, data);
  }

  /**
   * The lambda arrow functions can not be used in static items apparently, so
   * with the fromObject function it died. This fixes that.
   */
  protected static getDate(): typeof Date {
    return Date;
  }

  /**
   * @inheritDoc
   */
  public toObject(): TsRangeInterfaceA {
    return {
      start: formatISO(this.start),
      end: formatISO(this.end),
      inclusiveStart: this.inclusiveStart,
      inclusiveEnd: this.inclusiveEnd,
    };
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    const parts: string[] = [];
    parts.push(this.inclusiveStart ? '[' : '(');
    // The lower bound of a range can be omitted, meaning that all values less
    // than the upper bound are included in the range, e.g., (,3].
    parts.push(this.start ? formatISO(this.start) : '');
    parts.push(',');
    // Likewise, if the upper bound of the range is omitted, then all values
    // greater than the lower bound are included in the range
    parts.push(this.end ? formatISO(this.end) : '');
    parts.push(this.inclusiveEnd ? ']' : ')');
    return parts.join('');
  }
}
