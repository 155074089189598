import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { LogRecordContract } from '@scheduler-frontend/log-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { activeAssignmentHistoryActions } from '../actions/active-assignment-history.actions';

export const ACTIVE_ASSIGNMENT_HISTORY_FEATURE_KEY = 'activeAssignmentHistory';

export interface ActiveAssignmentHistoryState extends EntityState<LogRecordContract> {
  loaded: boolean;
  loading: boolean;
  loadedChunks: number[];
  lastChunkLoaded: boolean;
  totalItems?: number | null;
  error?: unknown | null;
}

export const adapter: EntityAdapter<LogRecordContract> = createEntityAdapter<LogRecordContract>({
  selectId: jsonLdSelectId,
});

export const initialState: ActiveAssignmentHistoryState = adapter.getInitialState({
  loaded: false,
  loading: false,
  loadedChunks: [],
  lastChunkLoaded: false,
  error: null,
});

const reducer = createReducer(
  initialState,
  on(activeAssignmentHistoryActions.loadHistory, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(activeAssignmentHistoryActions.loadNextChunk, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(activeAssignmentHistoryActions.loadHistorySuccess, (state, { records, chunk, totalItems }) =>
    adapter.setAll(records, {
      ...state,
      loading: false,
      loaded: true,
      loadedChunks: [chunk],
      lastChunkLoaded: records.length === totalItems,
      error: null,
    }),
  ),
  on(activeAssignmentHistoryActions.loadNextChunkSuccess, (state, { records, chunk, totalItems }) =>
    adapter.addMany(records, {
      ...state,
      loading: false,
      loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()],
      lastChunkLoaded: state.ids.length + records.length === totalItems,
      error: null,
    }),
  ),
  on(activeAssignmentHistoryActions.loadHistoryFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function activeAssignmentHistoryReducer(
  state: ActiveAssignmentHistoryState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
