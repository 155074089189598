import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { slotsActions } from './slots.actions';

export const SLOTS_FEATURE_KEY = 'Slot';

export interface SlotsState extends EntityState<SlotContract | SlotDetailedContract> {
  selectedId?: string | number; // which DataAccessSlots record has been selected
  loadingSlot: boolean;
  error?: unknown;
  currentSearch: {
    loadedChunks: number[];
    loading: boolean;
    loaded: boolean;
    totalItems?: number;
  };
}

export const slotsAdapter: EntityAdapter<SlotContract | SlotDetailedContract> =
  createEntityAdapter<SlotContract>({
    selectId: jsonLdSelectId,
  });

export const initialSlotsState: SlotsState = slotsAdapter.getInitialState({
  // set initial required properties
  loadingSlot: false,
  currentSearch: {
    loadedChunks: [],
    loading: false,
    loaded: false,
  },
});

const reducer = createReducer(
  initialSlotsState,
  on(slotsActions.setActiveSlot, (state, { slotId }) => ({
    ...state,
    selectedId: slotId,
  })),
  on(slotsActions.loadSlot, (state) => ({
    ...state,
    loadingSlot: true,
  })),
  on(slotsActions.loadSlotSuccess, (state, { slots }) =>
    slotsAdapter.setMany(slots, {
      ...state,
      loadingSlot: false,
    }),
  ),
  on(slotsActions.loadSlotFailure, (state, { error }) => ({
    ...state,
    loadingSlot: false,
    error: error,
  })),
  on(slotsActions.loadSlotsSuccess, (state, { slots, totalItems, chunk }) =>
    slotsAdapter.setMany(slots, {
      ...state,
      currentSearch: {
        loadedChunks: [...new Set([...state.currentSearch.loadedChunks, chunk]).values()],
        loading: false,
        loaded: true,
        totalItems: totalItems,
      },
    }),
  ),
  on(slotsActions.loadSlotsFailure, (state, { error }) => ({
    ...state,
    loadingSlot: false,
    error: error,
  })),
  on(slotsActions.createSlots, (state) => ({
    ...state,
    loadingSlot: true,
  })),
  on(slotsActions.createSlotsSuccess, (state, { slots }) =>
    slotsAdapter.setMany(slots, {
      ...state,
      loadingSlot: false,
    }),
  ),
  on(slotsActions.createSlotsFailure, (state, { error }) => ({
    ...state,
    loadingSlot: false,
    error: error,
  })),
  on(slotsActions.updateSlot, (state) => ({
    ...state,
    loadingSlot: true,
  })),
  on(slotsActions.updateSlotSuccess, (state, { slot }) =>
    slotsAdapter.setOne(slot, {
      ...state,
      loadingSlot: false,
    }),
  ),
  on(slotsActions.updateSlotFailure, (state, { error }) => ({
    ...state,
    loadingSlot: false,
    error: error,
  })),
  on(slotsActions.deleteSlot, (state) => ({
    ...state,
    loadingSlot: true,
  })),
  on(slotsActions.deleteSlotSuccess, (state, { slotId }) =>
    slotsAdapter.removeOne(slotId, {
      ...state,
      loadingSlot: false,
    }),
  ),
  on(slotsActions.deleteSlotFailure, (state, { error }) => ({
    ...state,
    loadingSlot: false,
    error: error,
  })),
  on(slotsActions.slotCreated, (state, { slot }) =>
    slotsAdapter.upsertOne(slot, {
      ...state,
    }),
  ),
  on(slotsActions.slotUpdated, (state, { slot }) =>
    slotsAdapter.upsertOne(slot, {
      ...state,
    }),
  ),
  on(slotsActions.slotDeleted, (state, { slot }) =>
    slotsAdapter.removeOne(slot, {
      ...state,
    }),
  ),
);

export function slotsReducer(state: SlotsState | undefined, action: Action) {
  return reducer(state, action);
}
