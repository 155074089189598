import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { BusinessEntitiesEffects } from './+state/business-entities.effects';
import {
  BUSINESS_ENTITIES_FEATURE_KEY,
  businessEntitiesReducer,
} from './+state/business-entities.reducer';
import { BusinessEntitiesStoreService } from './business-entities-store.service';

export function provideBusinessEntitiesStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: BUSINESS_ENTITIES_FEATURE_KEY, reducer: businessEntitiesReducer }),
    provideEffects(BusinessEntitiesEffects),
    BusinessEntitiesStoreService,
  ];
}
