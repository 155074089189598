@if (selfAssignForm) {
  <ng-container [formGroup]="selfAssignForm">
    <ion-list>
      <ion-list-header data-test="self-assign-form-header">
        Zelf inplannen
        @if (assignmentsStoreService.savingSelfAssignmentSettings$ | async) {
          <ion-spinner></ion-spinner>
        }
      </ion-list-header>
      <ion-item lines="none">
        <ion-toggle
          labelPlacement="start"
          color="secondary"
          formControlName="selfAssignable"
          data-test="self-assign-form-self-assignable"
        >
          Zelf inplanbaar
        </ion-toggle>
      </ion-item>
      <ion-item lines="none">
        <ion-label></ion-label>
        <ion-toggle
          labelPlacement="start"
          color="secondary"
          formControlName="allowSelfAssignWhenNew"
          data-test="self-assign-form-allow-self-assign-when-new"
        >
          Zelf inplanbaar indien nieuw
        </ion-toggle>
      </ion-item>
    </ion-list>
    <div class="number-inputs">
      <tt-number-input
        data-test="self-assign-form-min-grade"
        label="Minimaal cijfer"
        labelPlacement="stacked"
        fill="outline"
        [max]="10"
        [min]="1"
        formControlName="minimalGradeSelfAssign"
      ></tt-number-input>
      <tt-number-input
        label="Maximaal aantal km"
        labelPlacement="stacked"
        fill="outline"
        [allowDecimals]="false"
        [max]="350"
        [min]="0"
        formControlName="maxTravelDistanceSelfAssign"
        data-test="self-assign-form-max-travel-distance-self-assign"
      ></tt-number-input>
    </div>
  </ng-container>
}
