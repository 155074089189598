<ion-card>
  <ion-card-header>
    <ion-card-title>
      Shifts met actie benodigd (
      @if (!actionsStoreService.loading()) {
        {{ actionsStoreService.totalItems() }}
      } @else {
        <ion-skeleton-text style="width: 20px; display: inline-block"></ion-skeleton-text>
      }
      )
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-list data-test="action-list-list" class="ion-no-padding">
      @if (!actionsStoreService.loading() || actionsStoreService.loaded()) {
        @for (action of actionsStoreService.actions(); track action['@id']) {
          <ion-item
            [button]="true"
            lines="full"
            [disabled]="actionsStoreService.savingDeletions()"
            data-test="action-list-item"
            (click)="navigateToActionsSlot(action)"
          >
            <div data-test="time" slot="start" class="date-container">
              <p>{{ action.slot.timePeriod.start | date: 'dd' }}</p>
              <small>{{ action.slot.timePeriod.start | date: 'LLL' }}</small>
            </div>
            <ion-label class="ion-text-wrap">
              <p data-test="service-name">
                {{ rolesStoreService.businessServiceEntitiesByRole()?.[action.slot!.role]?.name }}
              </p>
              <small data-test="description">{{ action.description }}</small>
            </ion-label>
            <ion-button
              fill="clear"
              color="tertiary"
              slot="end"
              data-test="action-list-item-delete-action"
              (click)="actionsStoreService.deleteAction(action); $event.stopImmediatePropagation()"
            >
              <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-item>
        }
        <ion-item
          lines="none"
          class="more-button"
          [button]="true"
          (click)="actionsStoreService.nextChunk()"
        >
          @if (actionsStoreService.loading()) {
            <ion-spinner name="crescent"></ion-spinner>
          } @else {
            Toon meer
          }
        </ion-item>
      } @else {
        @for (action of [1, 2, 3, 4, 5]; track action) {
          <ion-item [button]="true" lines="full" data-test="action-list-skeleton-loaded-items">
            <div slot="start" class="date-container">
              <p><ion-skeleton-text [animated]="true" style="width: 21px"></ion-skeleton-text></p>
              <small
                ><ion-skeleton-text [animated]="true" style="width: 21px"></ion-skeleton-text
              ></small>
            </div>
            <ion-label class="ion-text-wrap">
              <p><ion-skeleton-text [animated]="true" style="width: 60%"></ion-skeleton-text></p>
              <small
                ><ion-skeleton-text [animated]="true" style="width: 80%"></ion-skeleton-text
              ></small>
            </ion-label>
            <ion-button fill="clear" color="tertiary" slot="end">
              <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-item>
        }
        <ion-item lines="none" class="more-button" [button]="true"> Toon meer </ion-item>
      }
    </ion-list>
  </ion-card-content>
</ion-card>
