<tt-modal (cancel)="modalController.dismiss()">
  <tt-modal-title color="tertiary"> Opdrachtstatus aangepast </tt-modal-title>

  <div>
    @if (!oldTransitionState() && newTransitionState()) {
      <p>
        De status van de opdracht is veranderd naar
        <span
          class="ion-color-{{ newTransitionState() }}"
          [matTooltip]="newTransitionStateExplanation()"
          data-test="new-state"
        >
          {{ newTransitionStateDisplay() | lowercase }}
          <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle" />
        </span>
      </p>
    } @else if (oldTransitionState() && newTransitionState()) {
      <p>
        De status van de opdracht is veranderd van
        <span
          class="ion-color-{{ oldTransitionState() }}"
          [matTooltip]="oldTransitionStateExplanation()"
          data-test="old-state"
        >
          {{ oldTransitionStateDisplay() | lowercase }}
          <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle" />
        </span>
        naar
        <span
          class="ion-color-{{ newTransitionState() }}"
          [matTooltip]="newTransitionStateExplanation()"
          data-test="new-state"
        >
          {{ newTransitionStateDisplay() | lowercase }}
          <fa-icon [fixedWidth]="true" [icon]="faExclamationCircle" />
        </span>
      </p>
    }
    @for (message of messages(); track message.text) {
      <div class="message {{ message.messageType }}">
        <fa-icon
          data-test="message-icon"
          [fixedWidth]="true"
          [icon]="iconMap[message.messageType]"
        ></fa-icon>
        <div>{{ message.text }}</div>
      </div>
    }
  </div>
  <ion-button
    (click)="modalController.dismiss()"
    slot="action"
    data-test="dismiss-button"
    fill="outline"
    color="secondary"
    >Ok
  </ion-button>
</tt-modal>
