import { Dictionary } from '@ngrx/entity/src/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AssignmentStateEnum,
  SlotContract,
  SlotDetailedContract,
} from '@scheduler-frontend/assignment-contracts';
import { CandidatesSelectors } from '@scheduler-frontend/data-access-candidates';
import { SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { NonNullableDictionary } from '@techniek-team/tt-ngrx';
import { compareAsc, differenceInMinutes, formatISO, parseISO } from 'date-fns';
import { SCHEDULING_FEATURE_KEY, SchedulingState } from '../reducers/scheduling.reducer';
import { SchedulingViewSelectors } from './scheduling-view.selectors';

export class SchedulingSelectors {
  private static readonly state = createFeatureSelector<SchedulingState>(SCHEDULING_FEATURE_KEY);

  public static readonly slotListIds = createSelector(
    SchedulingSelectors.state,
    (state) => state.slotList,
  );

  public static readonly alternativeSlotListIds = createSelector(
    SchedulingSelectors.state,
    (state) => state.alternativeSlotList,
  );

  public static readonly selectedSlotIds = createSelector(
    SchedulingSelectors.state,
    (state) => state.selectedSlots,
  );

  public static readonly candidateListIds = createSelector(
    SchedulingSelectors.state,
    (state) => state.candidateList,
  );

  public static readonly loadedCandidates = createSelector(SchedulingSelectors.state, (state) => {
    return state.loadedCandidates;
  });

  public static readonly loadingSlots = createSelector(SchedulingSelectors.state, (state) => {
    return state.loadingSlots;
  });

  public static readonly loadedSlots = createSelector(SchedulingSelectors.state, (state) => {
    return state.loadedSlots;
  });

  public static readonly loadingCandidates = createSelector(SchedulingSelectors.state, (state) => {
    return state.loadingCandidates;
  });

  public static readonly selectedSlotForRejectionIds = createSelector(
    SchedulingSelectors.state,
    (state) => state.selectedSlotsForRejection,
  );

  public static readonly selectedCandidateIds = createSelector(
    SchedulingSelectors.state,
    (state) => state.selectedCandidates,
  );

  public static readonly savingAssignment = createSelector(
    SchedulingSelectors.state,
    (state: SchedulingState) => state.savingAssignment,
  );

  public static readonly slotList = createSelector(
    SlotsSelectors.slots,
    SlotsSelectors.slotEntities,
    SchedulingSelectors.slotListIds,
    (slots, slotEntities, slotListIds) => {
      if (slots.length === 0) {
        return [];
      }

      slots.sort((slotA, slotB) => compareAsc(slotA.timePeriod.start, slotB.timePeriod.start));

      return slotListIds.map((item) => slotEntities[item]).filter((item) => !!item) as (
        | SlotContract
        | SlotDetailedContract
      )[];
    },
  );

  public static readonly selectedSlots = createSelector(
    SchedulingSelectors.selectedSlotIds,
    SlotsSelectors.slotEntities,
    (selectedSlotIds, slotEntities) => {
      return selectedSlotIds
        .map((item) => slotEntities[item])
        .filter((item) => item !== undefined) as (SlotContract | SlotDetailedContract)[];
    },
  );

  public static readonly slotsInView = createSelector(
    SchedulingSelectors.slotList,
    SchedulingSelectors.selectedSlots,
    SchedulingViewSelectors.onlyShowSelectedSlotsInView,
    (slotList, selectedSlots, onlyShowSelectedSlotsInView) => {
      if (onlyShowSelectedSlotsInView) {
        return selectedSlots;
      }
      return slotList;
    },
  );

  public static readonly alternativeSlotList = createSelector(
    SlotsSelectors.slots,
    SlotsSelectors.slotEntities,
    SchedulingSelectors.alternativeSlotListIds,
    (slots, slotEntities, slotListIds) => {
      if (!slotListIds || slots.length === 0) {
        return undefined;
      }
      return Object.entries<string[]>(slotListIds).reduce<
        NonNullableDictionary<(SlotDetailedContract | SlotContract | undefined)[]>
      >((dict, item, index) => {
        dict[item[0]] = item[1].map((slot) => slotEntities[slot]);
        return dict;
      }, {});
    },
  );

  public static readonly alreadyAssignedSlotList = createSelector(
    SchedulingSelectors.slotsInView,
    (slotList) => {
      if (!slotList || slotList.length === 0) {
        return undefined;
      }
      return slotList.filter((slot) => {
        return (
          'assignmentHasSlot' in slot &&
          slot.assignmentHasSlot?.assignment &&
          slot.assignmentHasSlot.assignment.state !== AssignmentStateEnum.UNASSIGNED
        );
      });
    },
  );

  public static readonly slotListGroupedByDate = createSelector(
    SchedulingSelectors.slotsInView,
    (slots) => {
      if (!slots) {
        return undefined;
      }

      const slotsByDateDict: Dictionary<(SlotDetailedContract | SlotContract)[]> = {};
      for (let slot of slots) {
        const dateKey: string = formatISO(slot.timePeriod.start, {
          representation: 'date',
        });
        if (slotsByDateDict[dateKey]) {
          slotsByDateDict[dateKey]?.push(slot);
        } else {
          slotsByDateDict[dateKey] = [slot];
        }
      }

      return slotsByDateDict;
    },
  );

  public static readonly slotListGroupedByDateAndAssignment = createSelector(
    SchedulingSelectors.slotListGroupedByDate,
    (slots) => {
      if (!slots) {
        return undefined;
      }

      const dictByAssignment: Dictionary<(SlotDetailedContract | SlotContract)[]> =
        SchedulingSelectors.createSlotsDictionaryByAssignment(slots);
      return SchedulingSelectors.createSlotsDictionaryByDateAndAssignment(dictByAssignment);
    },
  );

  public static readonly slotListGroupedBySchedule = createSelector(
    SchedulingSelectors.slotsInView,
    (slots) => {
      if (!slots) {
        return undefined;
      }

      const slotsByScheduleDict: Dictionary<(SlotDetailedContract | SlotContract)[]> = {};
      for (let slot of slots) {
        const scheduleId: string = slot.schedule['@id'];
        if (slotsByScheduleDict[scheduleId]) {
          slotsByScheduleDict[scheduleId]?.push(slot);
        } else {
          slotsByScheduleDict[scheduleId] = [slot];
        }
      }

      return slotsByScheduleDict;
    },
  );

  public static readonly candidateList = createSelector(
    CandidatesSelectors.candidateEntities,
    SchedulingSelectors.loadedCandidates,
    SchedulingSelectors.candidateListIds,
    (candidateEntities, loadedCandidates, candidateListIds) => {
      return loadedCandidates
        ? candidateListIds.map((item) => {
            return candidateEntities[item];
          })
        : undefined;
    },
  );

  public static readonly requestedMinutes = createSelector(
    SchedulingSelectors.selectedSlots,
    (selectedSlots) =>
      selectedSlots.reduce((previousValue, currentValue) => {
        return (
          previousValue +
          differenceInMinutes(currentValue.timePeriod.end, currentValue.timePeriod.start)
        );
      }, 0),
  );

  public static readonly hasSelectedSlots = createSelector(
    SchedulingSelectors.selectedSlots,
    (selectedSlots) => selectedSlots.length > 0,
  );

  public static readonly selectedSlotsForRejection = createSelector(
    SchedulingSelectors.selectedSlotForRejectionIds,
    SlotsSelectors.slotEntities,
    (selectedSlotForRejectionIds, slotEntities) =>
      selectedSlotForRejectionIds.map((item) => slotEntities[item]) as (
        | SlotContract
        | SlotDetailedContract
      )[],
  );

  public static readonly hasSelectedSlotsForRejection = createSelector(
    SchedulingSelectors.selectedSlotForRejectionIds,
    (selectedSlotForRejectionIds) => selectedSlotForRejectionIds.length > 0,
  );

  public static readonly selectedSlotsByAssignment = createSelector(
    SchedulingSelectors.selectedSlots,
    (slots) => {
      if (!slots) {
        return undefined;
      }

      // For debugging purposes it is easier to have the slots sorted by date
      // and put into the dictionary in that order.
      slots = slots.sort((slotA, slotB) =>
        compareAsc(slotA.timePeriod.start, slotB.timePeriod.start),
      );

      const slotsByDateDict: Dictionary<(SlotDetailedContract | SlotContract)[]> = {};
      for (let slot of slots) {
        const dateKey: string = formatISO(slot.timePeriod.start, {
          representation: 'date',
        });
        if (slotsByDateDict[dateKey]) {
          slotsByDateDict[dateKey]?.push(slot);
        } else {
          slotsByDateDict[dateKey] = [slot];
        }
      }

      const dictByAssignment: Dictionary<(SlotDetailedContract | SlotContract)[]> =
        SchedulingSelectors.createSlotsDictionaryByAssignment(slotsByDateDict);
      return SchedulingSelectors.createSlotsDictionaryByDateAndAssignment(dictByAssignment);
    },
  );

  public static readonly selectedCandidates = createSelector(
    SchedulingSelectors.selectedCandidateIds,
    CandidatesSelectors.candidateEntities,
    (selectedCandidateIds, candidateEntities) =>
      selectedCandidateIds.map((item) => candidateEntities[item]),
  );

  public static readonly hasSelectedCandidates = createSelector(
    SchedulingSelectors.selectedCandidateIds,
    (selectedCandidateIds) => selectedCandidateIds.length > 0,
  );

  public static createSlotsDictionaryByAssignment(
    slots: Dictionary<(SlotDetailedContract | SlotContract)[]>,
  ): Dictionary<(SlotDetailedContract | SlotContract)[]> {
    const dictByAssignment: Dictionary<(SlotDetailedContract | SlotContract)[]> = {};
    for (const [dateKey, slotsByDay] of Object.entries(slots)) {
      if (!slotsByDay) {
        continue;
      }
      for (const slot of slotsByDay) {
        const noAssignmentId: string = 'random-uuid-' + crypto.randomUUID();
        const assignmentId: string =
          'assignmentHasSlot' in slot
            ? slot.assignmentHasSlot?.assignment?.['@id'] ?? noAssignmentId
            : noAssignmentId;
        if (dictByAssignment[assignmentId]) {
          dictByAssignment[assignmentId]?.push(slot);
        } else {
          dictByAssignment[assignmentId] = [slot];
        }
      }
    }

    return dictByAssignment;
  }

  public static createSlotsDictionaryByDateAndAssignment(
    dictByAssignment: Dictionary<(SlotDetailedContract | SlotContract)[]>,
  ): Dictionary<Dictionary<(SlotDetailedContract | SlotContract)[]>> {
    const dictByDateAndAssignment: Dictionary<Dictionary<(SlotDetailedContract | SlotContract)[]>> =
      {};
    for (let [assignmentId, slotsByAssignment] of Object.entries(dictByAssignment)) {
      if (!slotsByAssignment) {
        continue;
      }

      const dateKey: string = formatISO(
        SchedulingSelectors.getFirstDateOfAssignment(slotsByAssignment),
        { representation: 'date' },
      );

      const dictBySingularAssignment: Dictionary<(SlotDetailedContract | SlotContract)[]> = {};
      dictBySingularAssignment[assignmentId] = slotsByAssignment;

      if (dictByDateAndAssignment[dateKey]) {
        dictByDateAndAssignment[dateKey] = {
          ...dictByDateAndAssignment[dateKey],
          ...dictBySingularAssignment,
        };
      } else {
        dictByDateAndAssignment[dateKey] = dictBySingularAssignment;
      }
    }
    return dictByDateAndAssignment;
  }

  private static getFirstDateOfAssignment(
    slotsByAssignment: (SlotDetailedContract | SlotContract)[],
  ): Date {
    return slotsByAssignment.reduce((earliestDate, slot): Date => {
      const slotStartDate: Date = parseISO(slot.timePeriod.start);
      return earliestDate < slotStartDate ? earliestDate : slotStartDate;
    }, parseISO(slotsByAssignment[0].timePeriod.start));
  }
}
