<tt-modal (cancel)="modalController.dismiss()">
  <tt-modal-title color="tertiary">What's new</tt-modal-title>

  @for (release of releases; track release) {
    <ion-card data-test="whats-new-modal-card" class="ion-text-start ion-margin-bottom">
      <ion-card-header>
        <ion-card-title data-test="whats-new-modal-release-name">{{ release.name }}</ion-card-title>
        <ion-card-subtitle data-test="whats-new-modal-release-date">{{
          release.releasedAt | date
        }}</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content
        class="whats-new"
        data-test="whats-new-modal-description"
        [innerHTML]="release.descriptionHtml"
      >
      </ion-card-content>
      @if (release.images.length) {
        <ion-card-content>
          @for (image of release.images; track image) {
            <img
              data-test="whats-new-modal-image"
              [ngSrc]="image.url"
              height="292"
              width="600"
              [alt]="image.name"
            />
          }
        </ion-card-content>
      }
    </ion-card>
  }
</tt-modal>
