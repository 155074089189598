<ion-item lines="none"
          class="ion-no-margin ion-no-padding">
  <ion-button slot="start"
              fill="clear"
              size="small"
              data-test="tt-date-picker-header-previous-month"
              (click)="previousMonth()">
    <ion-icon name="chevron-back"></ion-icon>
  </ion-button>
  <ion-label data-test="tt-date-picker-header-active-month">
    {{calendar.activeDate | ttDate: 'MMM' | capitalize }}
  </ion-label>
  <ion-button slot="end"
              fill="clear"
              size="small"
              data-test="tt-date-picker-header-next-month"
              (click)="nextMonth()">
    <ion-icon name="chevron-forward"></ion-icon>
  </ion-button>
</ion-item>
<ion-item lines="none"
          class="ion-no-margin ion-no-padding">
  <ion-button slot="start"
              fill="clear"
              size="small"
              data-test="tt-date-picker-header-previous-year"
              (click)="previousYear()">
    <ion-icon name="chevron-back"></ion-icon>
  </ion-button>
  <ion-label data-test="tt-date-picker-header-active-year">
    {{calendar.activeDate | ttDate: 'yyyy'}}
  </ion-label>
  <ion-button slot="end"
              fill="clear"
              size="small"
              data-test="tt-date-picker-header-next-year"
              (click)="nextYear()">
    <ion-icon name="chevron-forward"></ion-icon>
  </ion-button>
</ion-item>
