import { DatePipe } from '@angular/common';
import { Component, computed, inject, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { AvailabilityTypeEnum } from '@scheduler-frontend/calendar-contracts';
import { CandidateAvailabilities } from '@scheduler-frontend/candidate-contracts';
import { UsersStoreService } from '@scheduler-frontend/data-access-users';
import { TsRange } from '@techniek-team/class-transformer';
import { TtDatePickerInputModule } from '@techniek-team/components/date-picker-input';
import { TtModalModule, TtSimpleModalComponent } from '@techniek-team/components/modal';
import { TtPermissionModule } from '@techniek-team/permissions';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { nl } from 'date-fns/locale';
import { CandidatesStoreService } from '../../candidates-store.service';

interface AvailabilityForm {
  dateRange: FormControl<TsRange | null>;

  remarks: FormControl<string | null>;
}

@Component({
  selector: 'app-availability-long-term-modal',
  templateUrl: './availability-long-term-modal.component.html',
  styleUrls: ['./availability-long-term-modal.component.scss'],
  standalone: true,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: nl },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
  imports: [
    IonicModule,
    TtDatePickerInputModule,
    ReactiveFormsModule,
    TtPermissionModule,
    DatePipe,
    TtModalModule,
  ],
})
export class AvailabilityLongTermModalComponent {
  protected readonly usersStoreService = inject(UsersStoreService);

  protected readonly candidateStoreService = inject(CandidatesStoreService);

  public readonly candidate = computed(() => this.candidateStoreService.activeDetailedCandidate());

  public readonly availabilitiesByPlanner = computed(
    () => this.candidate()?.availabilitiesByPlanner ?? ([] as CandidateAvailabilities[]),
  );

  public readonly availabilityChanged = output<boolean>();

  public availabilityForm: FormGroup<AvailabilityForm> = new FormGroup<AvailabilityForm>({
    dateRange: new FormControl<TsRange | null>(null, [Validators.required]),
    remarks: new FormControl<string | null>(null),
  });

  protected readonly modalController = inject(ModalController);

  /**
   * Set and save long-term unavailability status for the selected candidate.
   */
  protected async saveLongTermUnavailable(): Promise<void | boolean> {
    if (!this.availabilityForm.value) {
      return;
    }

    const range: TsRange = this.availabilityForm.value.dateRange as TsRange;
    range.inclusiveStart = true;
    range.inclusiveEnd = true;

    await this.candidateStoreService.setCandidateAvailability({
      timePeriod: range.toObject(),
      candidateId: jsonLdSelectId(this.candidate()),
      availabilityValue: AvailabilityTypeEnum.NO,
      remarks: this.availabilityForm.value.remarks,
    });

    return this.modalController.dismiss();
  }

  /**
   * Remove the candidate's long-term unavailability status.
   */
  protected async removeLongTermUnavailable(id: string): Promise<void> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: TtSimpleModalComponent,
      componentProps: {
        title: 'Verwijdering bevestigen',
        message: 'Weet je zeker dat je deze langdurige onbeschikbaarheid wilt verwijderen?',
      },
    });
    await confirmModal.present();

    const { role } = await confirmModal.onWillDismiss();

    if (role === 'confirm') {
      this.candidateStoreService.removeCandidateAvailability(id);
      this.availabilityForm.reset();
    }
  }
}
