import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { Observable } from 'rxjs';
import { schedulesActions } from './+state/schedules.actions';
import { SchedulesSelectors } from './+state/schedules.selectors';

@Injectable()
export class SchedulesStoreService {
  private readonly store = inject(Store);

  public loading$: Observable<boolean> = this.store.pipe(select(SchedulesSelectors.loading));

  public loading = this.store.selectSignal(SchedulesSelectors.loading);

  public loaded$: Observable<boolean> = this.store.pipe(select(SchedulesSelectors.loaded));

  public groupedByProductType = this.store.selectSignal(SchedulesSelectors.groupedByProductType);

  public productTypesAtLocation = this.store.selectSignal(
    SchedulesSelectors.productTypesAtLocation,
  );

  public productTypeGroupWithSelectedSchedule = this.store.selectSignal(
    SchedulesSelectors.productTypeGroupWithSelectedSchedule,
  );

  public selectedSchedule = this.store.selectSignal(SchedulesSelectors.selectedSchedule);

  public searchQuery = this.store.selectSignal(SchedulesSelectors.searchQuery);

  public selectedProductTypesAtLocation = this.store.selectSignal(
    SchedulesSelectors.selectedProductTypesAtLocation,
  );

  public selectedProductTypesAtLocationDict = this.store.selectSignal(
    SchedulesSelectors.selectedProductTypesAtLocationDict,
  );
  public reload(): void {
    this.store.dispatch(schedulesActions.reloadLocationSchedules());
  }

  public setSearchQuery(query: string) {
    this.store.dispatch(schedulesActions.setSearchQuery({ query: query }));
  }

  public selectSchedule(schedule?: ScheduleContract) {
    this.store.dispatch(
      schedulesActions.setSelectedSchedule({
        schedule: schedule ? jsonLdSelectId(schedule) : undefined,
      }),
    );
  }

  public selectProductTypes(productTypeId: string) {
    this.store.dispatch(
      schedulesActions.selectedProductTypeAtLocation({ productTypeId: [productTypeId] }),
    );
  }

  public deselectProductTypes(productTypeId: string) {
    this.store.dispatch(
      schedulesActions.deselectedProductTypeAtLocation({ productTypeId: productTypeId }),
    );
  }
}
