import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RoleContract } from '@scheduler-frontend/data-access-roles';
import { TsRangeInterface } from '@techniek-team/common';
import { editSlotActions } from './+state/edit-slot.actions';
import { EditSlotSelectors } from './+state/edit-slot.selectors';

@Injectable()
export class EditSlotStoreService {
  private readonly store = inject(Store);

  public readonly loading = this.store.selectSignal(EditSlotSelectors.loading);

  public readonly submitting = this.store.selectSignal(EditSlotSelectors.submitting);

  public readonly slot = this.store.selectSignal(EditSlotSelectors.selectedSlot);

  public readonly selectedSlotRole = this.store.selectSignal(EditSlotSelectors.selectedSlotRole);

  public readonly selectedSlotBusinessService = this.store.selectSignal(
    EditSlotSelectors.selectedSlotBusinessService,
  );

  public readonly selectedSlotProductType = this.store.selectSignal(
    EditSlotSelectors.selectedSlotProductType,
  );

  public readonly selectedSlotLocation = this.store.selectSignal(
    EditSlotSelectors.selectedSlotLocation,
  );

  public readonly validRoles = this.store.selectSignal(EditSlotSelectors.validRoles);

  public openEditSlotModal(slotId: string) {
    this.store.dispatch(editSlotActions.openEditSlotModal({ slotId: slotId }));
  }

  public submitEditSlot(slot: {
    timeRange: TsRangeInterface<string>;
    role: RoleContract;
    performSkillCheck: boolean;
  }) {
    this.store.dispatch(editSlotActions.submitEditSlot(slot));
  }
}
