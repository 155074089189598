import { createSelector } from '@ngrx/store';
import { isOnline } from '@scheduler-frontend/create-slot-modal-common';
import {
  mayHaveAlternativeLocation,
  mustHaveLevel,
  mustHavePredefinedLesson,
  mustHaveSubject,
  mustSetNumberOfPupils,
} from '@scheduler-frontend/product-type-requirements';
import { DeliveryTypeSelectors } from './delivery-type.selector';
import { SelectedSelectors } from './selected.selectors';

export class RequirementsSelectors {
  public static readonly mustSetNumberOfPupils = createSelector(
    SelectedSelectors.productType,
    (productType) => mustSetNumberOfPupils(productType),
  );

  public static readonly mayHaveDifferentOperationalLocation = createSelector(
    SelectedSelectors.deliveryType,
    SelectedSelectors.productType,
    (deliveryType, productType) =>
      mayHaveAlternativeLocation(productType) && !isOnline(deliveryType),
  );

  public static readonly mustHaveSubject = createSelector(
    SelectedSelectors.productType,
    (productType) => mustHaveSubject(productType),
  );

  public static readonly mustHaveLevel = createSelector(
    SelectedSelectors.productType,
    (productType) => mustHaveLevel(productType),
  );

  public static readonly mustHaveDeliveryType = createSelector(
    DeliveryTypeSelectors.validDeliveryTypes,
    (validDeliveryTypes) => validDeliveryTypes.length > 0,
  );

  public static readonly mustHavePredefinedLesson = createSelector(
    SelectedSelectors.productType,
    (productType) => mustHavePredefinedLesson(productType),
  );
}
