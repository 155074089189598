import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { BusinessServicesEffects } from './+state/business-services.effects';
import {
  BUSINESS_SERVICES_FEATURE_KEY,
  businessServicesReducer,
} from './+state/business-services.reducer';
import { BusinessServicesStoreService } from './business-services-store.service';

export function provideBusinessServicesStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: BUSINESS_SERVICES_FEATURE_KEY, reducer: businessServicesReducer }),
    provideEffects(BusinessServicesEffects),
    BusinessServicesStoreService,
  ];
}
