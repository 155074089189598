<app-header [translucent]="true">
  <app-location color="white"></app-location>
</app-header>

<div class="header">
  <h4>Vestiging</h4>
  <h2 data-test="home-page-header-location-name">
    @if (locationsStoreService.currentLocation$ | async; as location) {
      {{ location.name }}
    } @else {
      <ion-skeleton-text animated="true" class="loading-location"></ion-skeleton-text>
    }
  </h2>
</div>

<ion-content>
  <div class="content">
    <div class="action-list">
      <app-dashboard-action-list></app-dashboard-action-list>
      @if ((isSchedulingTeam$ | async) === false) {
        @if (finalizeList$ | async; as finalizeList) {
          @if (finalizeList.length > 0) {
            <ion-button
              fill="clear"
              color="warning"
              [routerLink]="['/location-payout-approval-overview']"
            >
              Rond nu {{ finalizeList.length }} opdrachten af
            </ion-button>
          } @else {
            <ion-button
              fill="clear"
              color="secondary"
              [routerLink]="['/location-payout-approval-overview']"
            >
              Alle opdrachten zijn afgerond
            </ion-button>
          }
        } @else {
          <ion-button
            fill="clear"
            color="warning"
            [routerLink]="['/location-payout-approval-overview']"
          >
            Rond nu opdrachten af
          </ion-button>
        }
      }
    </div>

    <div class="stat-cards">
      @if (
        !awaitingSlotsStoreService.pupilsWithoutFirstSlotLoading() &&
        awaitingSlotsStoreService.pupilsWithoutFirstSlotLoaded()
      ) {
        <tt-statistic-card
          [routerLink]="['/wachtend-op-shift']"
          [value]="awaitingSlotsStoreService.pupilsWithoutFirstSlotCount()"
          [color]="awaitingSlotsStoreService.pupilsWithoutFirstSlotCount() > 0 ? 'danger' : 'dark'"
          title="Wachtend op eerste shift"
          statisticName="leerling"
          statisticNamePlural="leerlingen"
          [button]="true"
          class="awaiting-slots"
          data-test="home-page-statistic-card-awaiting-first-slot"
        >
        </tt-statistic-card>
      } @else {
        <ion-skeleton-text
          animated="true"
          class="loading-statistic"
          data-test="home-page-loading-statistic-card-first-slot"
        >
        </ion-skeleton-text>
      }

      @if (
        !awaitingSlotsStoreService.pupilsWithoutFollowUpSlotLoading() &&
        awaitingSlotsStoreService.pupilsWithoutFollowUpSlotLoaded()
      ) {
        <tt-statistic-card
          [routerLink]="['/wachtend-op-shift/vervolg-shift']"
          [value]="awaitingSlotsStoreService.pupilsWithoutFollowUpSlotCount()"
          [color]="
            awaitingSlotsStoreService.pupilsWithoutFollowUpSlotCount() > 0 ? 'danger' : 'dark'
          "
          title="Wachtend op vervolg-shift"
          statisticName="leerling"
          statisticNamePlural="leerlingen"
          [button]="true"
          class="awaiting-slots"
          data-test="home-page-statistic-card-awaiting-follow-up-slot"
        >
        </tt-statistic-card>
      } @else {
        <ion-skeleton-text
          animated="true"
          class="loading-statistic"
          data-test="home-page-loading-statistic-card-follow-up-slot"
        >
        </ion-skeleton-text>
      }

      @if (usersStoreService.isLocationManager()) {
        @if (slotsWithHoursToApproveCount$ | async; as statistics) {
          <tt-statistic-card
            [routerLink]="['/daadwerkelijk-gewerkte-uren/doorgeven-vestigingsmanager']"
            [previousValue]="statistics.get('previous')"
            [value]="statistics.get('current')"
            [color]="statistics.get('current')! > 0 ? 'danger' : 'dark'"
            title="Daadwerkelijk gewerkte uren doorgeven"
            statisticName="shift"
            statisticNamePlural="shifts"
            [button]="true"
            class="hours-approval"
            data-test="home-page-statistic-card-hours-approval"
          >
          </tt-statistic-card>
        } @else {
          <ion-skeleton-text
            animated="true"
            class="loading-statistic"
            data-test="home-page-loading-statistic-card-hours-approval"
          >
          </ion-skeleton-text>
        }
        @if (candidateAwaitingPayoutApproval$ | async; as statistics) {
          <tt-statistic-card
            [routerLink]="['/uitbetaling/locatie']"
            [previousValue]="statistics.get('previous')"
            [value]="statistics.get('current')"
            [color]="statistics.get('current')! > 0 ? 'danger' : 'dark'"
            title="Uitbetalingen goedkeuren"
            statisticName="begeleider"
            statisticNamePlural="begeleiders"
            [button]="true"
            class="hours-approval"
            data-test="home-page-statistic-card-candidate-waiting-payout-approval"
          >
          </tt-statistic-card>
        } @else {
          <ion-skeleton-text
            animated="true"
            class="loading-statistic"
            data-test="home-page-loading-statistic-card-candidate-waiting-payout-approval"
          >
          </ion-skeleton-text>
        }

        <ion-card class="hours-approval-card">
          <ion-card-header>
            <ion-card-title>Daadwerkelijk gewerkte uren doorgeven</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <p>
              Het doorgeven van de door begeleiders daadwerkelijk gewerkte uren is verplaatst naar
              een
              <span
                class="link"
                [routerLink]="['/daadwerkelijk-gewerkte-uren/doorgeven-vestigingsmanager']"
              >
                aparte pagina </span
              >.<br />
              Deze pagina is terug te vinden in het hoofdmenu aan de linkerkant.
            </p>
          </ion-card-content>
        </ion-card>
      }
    </div>
  </div>
</ion-content>
