<ion-card>
  <ion-card-header>
    <ion-card-subtitle>Selecteer een datum</ion-card-subtitle>
    @if (currentDate) {
      <ion-card-title>{{ currentDate | ttDate: "EEEE d MMMM YYYY" }}</ion-card-title>
    }
  </ion-card-header>

  <mat-calendar
    data-test="tt-date-picker-modal-calendar"
    [selected]="currentDate"
    [startAt]="firstDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateClass]="showToday"
    (selectedChange)="dateChanged($event)"></mat-calendar>

  @if (showActions) {
    <div class="button-container">
      <ion-button
        data-test="tt-date-picker-modal-cancel-button"
        (click)="cancel()"
        size="small"
        fill="clear">
        Annuleren
      </ion-button>

      <ion-button
        data-test="tt-date-picker-modal-select-button"
        (click)="select()"
        size="small"
        fill="clear">
        Ok
      </ion-button>
    </div>
  }
</ion-card>
