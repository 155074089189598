import { LocationMinimal } from '@scheduler-frontend/data-access-locations';
import { RoleMinimal } from '@scheduler-frontend/data-access-roles';
import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';

export class PayoutWaitingForApprovalSlot extends JsonLd {
  @Type(() => LocationMinimal)
  @Expose()
  public location!: LocationMinimal;

  @Type(() => TsRange)
  @Expose()
  public timePeriod!: TsRange;

  @Type(() => RoleMinimal)
  @Expose()
  public role!: RoleMinimal;
}
