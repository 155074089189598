import { createSelector } from '@ngrx/store';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { groupIntoMostEfficientGroupsByDate } from '@scheduler-frontend/common/functions';
import { ScheduleMinimalContract } from '@scheduler-frontend/schedule-contracts';
import { NonNullableDictionary } from '@techniek-team/tt-ngrx';
import { map } from 'lodash-es';
import { createCountDictionary } from '../../functions/create-count-dictionary.function';
import { createGridSchedules, ScheduleGrid } from '../../functions/create-grid-schedules.function';
import { groupSlotsByISODayNumber } from '../../functions/group-by-iso-day-number.function';
import { groupSlotsBySchedule } from '../../functions/group-by-schedule.function';
import { maxIsoDayNumber } from '../../functions/max-iso-day-number.function';
import { maxSlotsCount } from '../../functions/max-slots-count.function';
import { minIsoDayNumber } from '../../functions/min-iso-day-number.function';
import { sortScheduleGroup } from '../../functions/sort-schedule-group.function';
import { sortSlotsByTime } from '../../functions/sort-slots-by-time.function';
import { SchedulingSelectors } from './scheduling.selectors';

export interface ScheduleGroup {
  slots: NonNullableDictionary<(SlotContract | SlotDetailedContract)[]>;
  schedule: ScheduleMinimalContract;
  startDay: number;
  endDay: number;
  totalRow: number;
}

export class SchedulingWeekViewSelectors {
  public static readonly slotListGroupedBySchedule = createSelector(
    SchedulingSelectors.slotsInView,
    (slots) => {
      if (!slots) {
        return;
      }

      sortSlotsByTime(slots);

      return map(groupSlotsBySchedule(slots), (groupedSlots) => {
        const slotsGroupedByISODayNumber = groupSlotsByISODayNumber(groupedSlots);
        return {
          slots: slotsGroupedByISODayNumber,
          schedule: groupedSlots[0].schedule,
          startDay: minIsoDayNumber(slotsGroupedByISODayNumber),
          endDay: maxIsoDayNumber(slotsGroupedByISODayNumber),
          totalRow: maxSlotsCount(slotsGroupedByISODayNumber),
        } as ScheduleGroup;
      });
    },
  );

  public static readonly slotListAsGridSchedules = createSelector(
    SchedulingWeekViewSelectors.slotListGroupedBySchedule,
    (groupedSlotsBySchedule) => {
      if (!groupedSlotsBySchedule) {
        return [] as ScheduleGrid[];
      }

      const mostEfficient: ScheduleGroup[][] = groupIntoMostEfficientGroupsByDate(
        groupedSlotsBySchedule,
        (scheduleGroup: ScheduleGroup) => scheduleGroup.startDay,
        (scheduleGroup: ScheduleGroup) => scheduleGroup.endDay,
        sortScheduleGroup,
      );
      const countPerISODateNumber = createCountDictionary(7, 1, 2);
      return createGridSchedules(mostEfficient, countPerISODateNumber);
    },
  );
}
