import { Action, createReducer, on } from '@ngrx/store';
import { activeAssignmentActions } from '../actions/active-assignment.actions';

export const ACTIVE_ASSIGNMENT_FEATURE_KEY = 'activeAssignment';

export interface ActiveAssignmentState {
  savingIsUrgent: boolean;

  savingDescription: boolean;

  savingSelfAssignmentSettings: boolean;

  savingActualWorkingTimes: boolean;

  targetAssignmentHasSlotActualWorkingTimes: string | null;

  savingIsBillable: boolean;

  targetAssignmentHasSlotIsBillable: string | null;

  unassigningSlot: boolean;

  processingUnassign: boolean;

  error?: unknown | null;
}

export const initialState: ActiveAssignmentState = {
  savingIsUrgent: false,
  savingDescription: false,
  savingActualWorkingTimes: false,
  targetAssignmentHasSlotActualWorkingTimes: null,
  targetAssignmentHasSlotIsBillable: null,
  savingIsBillable: false,
  savingSelfAssignmentSettings: false,
  unassigningSlot: false,
  processingUnassign: false,
};

const reducer = createReducer(
  initialState,
  on(activeAssignmentActions.setIsUrgent, (state) => ({
    ...state,
    savingIsUrgent: true,
  })),
  on(activeAssignmentActions.setIsUrgentSuccess, (state) => ({
    ...state,
    savingIsUrgent: false,
  })),
  on(activeAssignmentActions.setIsUrgentFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingIsUrgent: false,
  })),
  on(activeAssignmentActions.setDescription, (state) => ({
    ...state,
    savingDescription: true,
  })),
  on(activeAssignmentActions.setDescriptionSuccess, (state) => ({
    ...state,
    savingDescription: false,
  })),
  on(activeAssignmentActions.setDescriptionFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingDescription: false,
  })),
  on(activeAssignmentActions.setSelfAssignment, (state) => ({
    ...state,
    savingSelfAssignmentSettings: true,
  })),
  on(activeAssignmentActions.setSelfAssignmentSuccess, (state) => ({
    ...state,
    savingSelfAssignmentSettings: false,
  })),
  on(activeAssignmentActions.setSelfAssignmentFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingSelfAssignmentSettings: false,
  })),
  on(activeAssignmentActions.updateActualWorkingTimes, (state, { assignmentHasSlotId }) => ({
    ...state,
    savingActualWorkingTimes: true,
    targetAssignmentHasSlotActualWorkingTimes: assignmentHasSlotId,
  })),
  on(activeAssignmentActions.updateActualWorkingTimesSuccess, (state) => ({
    ...state,
    savingActualWorkingTimes: false,
    targetAssignmentHasSlotActualWorkingTimes: null,
  })),
  on(activeAssignmentActions.updateActualWorkingTimesFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingActualWorkingTimes: false,
    targetAssignmentHasSlotActualWorkingTimes: null,
  })),
  on(activeAssignmentActions.setIsBillable, (state, { assignmentHasSlotId }) => ({
    ...state,
    savingIsBillable: true,
    targetAssignmentHasSlotIsBillable: assignmentHasSlotId,
  })),
  on(activeAssignmentActions.setIsBillableSuccess, (state) => ({
    ...state,
    savingIsBillable: false,
    targetAssignmentHasSlotIsBillable: null,
  })),
  on(activeAssignmentActions.setIsBillableFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingIsBillable: false,
    targetAssignmentHasSlotIsBillable: null,
  })),
);

export function activeAssignmentReducer(state: ActiveAssignmentState | undefined, action: Action) {
  return reducer(state, action);
}
