<tt-modal data-test="tt-simple-modal" (cancel)="cancel()">
  <tt-modal-title color="secondary">{{ title }}</tt-modal-title>
  <p>{{ message }}</p>
  @for (button of buttons; track button) {
    <ion-button
      data-test="tt-simple-modal-button"
      slot="action"
      (click)="buttonClick(button)"
      [ngClass]="button?.cssClass ?? ''"
      [fill]="button?.fill"
      [color]="button?.color">
      {{button.text}}
    </ion-button>
  }
</tt-modal>
