import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { LocationsEffects } from './+state/locations.effects';
import { LOCATIONS_FEATURE_KEY, locationsReducer } from './+state/locations.reducer';
import { LocationsStoreService } from './locations-store.service';

export function provideLocationsStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: LOCATIONS_FEATURE_KEY, reducer: locationsReducer }),
    provideEffects(LocationsEffects),
    LocationsStoreService,
  ];
}
