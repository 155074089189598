import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Resource } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { FetchStorageInterface } from '@techniek-team/fetch';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { productTypesActions } from './+state/product-types.actions';
import { ProductTypesSelectors } from './+state/product-types.selectors';
import {
  ProductTypeContract,
  ProductTypeDetailedContract,
} from './contracts/product-type.contract';
import { ProductTypeDetailed } from './models/product-type-detailed.model';
import { ProductType } from './models/product-type.model';

@Injectable()
export class ProductTypesStoreService
  implements FetchStorageInterface<ProductTypeContract, ProductType>
{
  private readonly store = inject(Store);

  public loading$: Observable<boolean> = this.store.pipe(select(ProductTypesSelectors.loading));

  public loadingDetailed$: Observable<boolean> = this.store.pipe(
    select(ProductTypesSelectors.loadingDetailed),
  );

  public loaded$: Observable<boolean> = this.store.pipe(select(ProductTypesSelectors.loaded));

  public productTypeEntities = this.store.selectSignal(ProductTypesSelectors.productTypeEntities);

  public productTypes$: Observable<(ProductType | ProductTypeDetailed)[]> = this.store.pipe(
    select(ProductTypesSelectors.productTypes),
    isDefined(),
    map((data) => {
      return data.map((item: Resource<ProductTypeContract | ProductTypeDetailedContract>) => {
        if ('requiresPredefinedLesson' in item) {
          return denormalize(ProductTypeDetailed, item);
        }
        return denormalize(ProductType, item);
      });
    }),
    shareReplay(),
  );

  public productTypesMap$: Observable<Map<string, ProductType>> = this.store.pipe(
    select(ProductTypesSelectors.productTypeEntities),
    map((data) => {
      return new Map(
        Object.entries(data).map(([key, _item]) => {
          if ('requiresPredefinedLesson' in data) {
            return [key, denormalize(ProductTypeDetailed, data)];
          }
          return [key, denormalize(ProductType, data)];
        }),
      );
    }),
    shareReplay(),
  );

  public productTypesFilterGroup = this.store.selectSignal(
    ProductTypesSelectors.productTypesFilterGroup,
  );

  public productTypesFilterGroupKey = ProductTypesSelectors.productTypesFilterGroupKey;

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(productTypesActions.initProductTypes());
  }

  public reload(): void {
    this.store.dispatch(productTypesActions.reloadProductTypes());
  }

  public loadDetailedProductType(id: string): void {
    this.store.dispatch(productTypesActions.loadDetailedProductType({ id: id }));
  }

  public supportsFetch(identifier: string): boolean {
    return identifier === 'ProductType';
  }

  public async waitForInitialization(): Promise<void> {
    await firstEmitFrom(
      this.store.pipe(
        select(ProductTypesSelectors.loaded),
        filter((loaded) => loaded === true),
      ),
    );
  }

  public getFetchFromStorage(value: string): Observable<ProductType | ProductTypeDetailed> {
    return this.store.pipe(
      select(ProductTypesSelectors.denormalizedProductTypeEntities),
      map((dict) => dict[jsonLdSelectId(value)]),
      isDefined(),
    );
  }

  public getById(id: string): Observable<ProductType | ProductTypeDetailed> {
    return this.getFetchFromStorage(id);
  }
}
