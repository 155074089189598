import { Dictionary } from '@ngrx/entity/src/models';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { hashParams } from '@scheduler-frontend/common';
import { memoize } from 'lodash-es';

/**
 * A memoized function that calculates the minimum ISO day number from the grouped slot contracts.
 *
 * This function receives a Dictionary where the keys are ISO day numbers and the values are
 * arrays of SlotContract or SlotDetailedContract.
 * It returns the minimum ISO day number from the dictionary keys.
 *
 * @param {Dictionary<(SlotContract | SlotDetailedContract)[]>} slotsGroupedByISODayNumber - A dictionary
 *  where the keys are ISO day numbers and the values are arrays of slot contracts.
 * @returns {number} - The smallest ISO day number from the keys of the given dictionary.
 *
 * @example
 * const slotsGroupedByISODayNumber = {
 *   "1": [{...slotData1}, {...slotData2}],
 *   "2": [{...slotData3}, {...slotData4}],
 *   "3": [{...slotData5}]
 * };
 *
 * // The resulting minDayNumber will be: 1
 * const minDayNumber = minIsoDayNumber(slotsGroupedByISODayNumber);
 */
export const minIsoDayNumber = memoize(
  (slotsGroupedByISODayNumber: Dictionary<(SlotContract | SlotDetailedContract)[]>): number => {
    return Math.min(
      ...Object.keys(slotsGroupedByISODayNumber).map((dayNumber: string) =>
        parseInt(dayNumber, 10),
      ),
    );
  },
  hashParams,
);
