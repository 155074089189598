import { createFeatureSelector, createSelector } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { Filter, FilterGroup } from '@techniek-team/search';
import { toDictionary } from '@techniek-team/tt-ngrx';
import {
  ProductTypeContract,
  ProductTypeDetailedContract,
} from '../contracts/product-type.contract';
import { ProductTypeDetailed } from '../models/product-type-detailed.model';
import { ProductType } from '../models/product-type.model';
import {
  PRODUCT_TYPES_FEATURE_KEY,
  productTypesAdapter,
  ProductTypesState,
} from './product-types.reducer';

const { selectAll, selectEntities } = productTypesAdapter.getSelectors();

export class ProductTypesSelectors {
  public static readonly productTypesState =
    createFeatureSelector<ProductTypesState>(PRODUCT_TYPES_FEATURE_KEY);

  public static readonly loading = createSelector(
    ProductTypesSelectors.productTypesState,
    (state: ProductTypesState) => state.loading,
  );

  public static readonly loadingDetailed = createSelector(
    ProductTypesSelectors.productTypesState,
    (state: ProductTypesState) => state.loadingDetailed,
  );

  public static readonly loaded = createSelector(
    ProductTypesSelectors.productTypesState,
    (state: ProductTypesState) => state.loaded,
  );

  public static readonly cacheTimestamp = createSelector(
    ProductTypesSelectors.productTypesState,
    (state: ProductTypesState) => state.cacheTimeStamp,
  );

  public static readonly error = createSelector(
    ProductTypesSelectors.productTypesState,
    (state: ProductTypesState) => state.error,
  );

  public static readonly productTypes = createSelector(
    ProductTypesSelectors.productTypesState,
    (state: ProductTypesState) => selectAll(state),
  );

  public static readonly productTypeEntities = createSelector(
    ProductTypesSelectors.productTypesState,
    (state: ProductTypesState) => selectEntities(state),
  );

  public static readonly denormalizedProductTypeEntities = createSelector(
    ProductTypesSelectors.productTypesState,
    (state) =>
      toDictionary(
        Object.values(state.entities).map((item) => {
          if (item && 'requiresPredefinedLesson' in item) {
            return denormalize(ProductTypeDetailed, item as ProductTypeDetailedContract);
          }
          return denormalize(ProductType, item as ProductTypeContract);
        }) as (ProductType | ProductTypeDetailed)[],
        (item) => item.getId(),
      ),
  );

  public static readonly productTypesFilterGroupKey = 'businessService.productType';

  public static readonly productTypesFilterGroup = createSelector(
    ProductTypesSelectors.productTypes,
    (productType) => {
      return new FilterGroup(
        ProductTypesSelectors.productTypesFilterGroupKey,
        (productType ?? []).map(
          (item) =>
            new Filter(item['@id'] as string, {
              label: item.name,
              additionalData: item,
            }),
        ),
        { multiple: true, label: 'Producttypes', itemLabel: 'Producttype' },
      );
    },
  );

  public static readonly searchQueryMapper = createSelector(
    ProductTypesSelectors.productTypeEntities,
    (dict) => {
      return {
        'businessService.productType': {
          entities: dict,
          getDisplayText: (item: ProductTypeContract) => item.name,
        },
      };
    },
  );
}
