import { Expose, Type } from 'class-transformer';
import { SearchMetaData } from './search-meta-data.model';

/**
 * Standard version of the Search resource from Scheduler-api
 */
export class Search {
  /**
   * Unique hash of the search Query
   */
  @Expose() public hash!: string;

  @Type(() => SearchMetaData)
  @Expose()
  public metaData?: SearchMetaData;

  @Expose() public slots: string[] = [];
}
