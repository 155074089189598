import { NgModule } from '@angular/core';
import { TtDatePickerInputControlComponent } from './tt-date-picker-input-control.component';

/**
 * @deprecated use standalone component
 */
@NgModule({
    exports: [
        TtDatePickerInputControlComponent,
    ],
    imports: [
        TtDatePickerInputControlComponent,
    ],
})
export class TtDatePickerInputModule {
}
