import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { UserSearchContract } from '../../contract/user-search.contract';
import { userSearchActions } from '../action/user-search.actions';
import { searchSelectId } from './search.reducer';

export const USER_SEARCH_FEATURE_KEY = 'userSearch';

export interface UserSearchState extends EntityState<UserSearchContract> {
  lastCreatedUserSearch?: string;
  currentUserSearch?: string; // which Search record has been selected by the user
  loaded: boolean;
  loading: boolean;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  savingAsFavorite: boolean;
}

export const userSearchAdapter = createEntityAdapter<UserSearchContract>({
  selectId: searchSelectId,
});

export const initialUserSearchState: UserSearchState = userSearchAdapter.getInitialState({
  loaded: false,
  loading: false,
  savingAsFavorite: false,
});

const reducer = createReducer<UserSearchState>(
  initialUserSearchState,
  on(userSearchActions.addUserSearchHash, (state, { search }) =>
    userSearchAdapter.upsertOne(search as UserSearchContract, {
      ...state,
      loading: false,
      loaded: true,
      lastCreatedUserSearch: (search as UserSearchContract).search.hash,
    }),
  ),
  on(userSearchActions.setCurrentUserSearch, (state, { searchId }) => ({
    ...state,
    currentUserSearch: searchId,
  })),
  on(userSearchActions.loadFavoriteSearches, (state) => ({
    ...state,
    error: null,
    loading: true,
  })),
  on(userSearchActions.loadFavoriteSearchesSuccess, (state, { searches }) =>
    userSearchAdapter.setMany(searches, {
      ...state,
      loading: false,
      loaded: true,
    }),
  ),
  on(userSearchActions.loadFavoriteSearchesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  on(userSearchActions.loadRecentSearches, (state) => ({
    ...state,
    error: null,
    loading: true,
  })),
  on(userSearchActions.loadRecentSearchesSuccess, (state, { searches }) =>
    userSearchAdapter.setMany(searches, {
      ...state,
      loading: false,
      loaded: true,
    }),
  ),
  on(userSearchActions.loadRecentSearchesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(userSearchActions.toggleFavorite, (state) => ({
    ...state,
    error: null,
    savingAsFavorite: true,
  })),
  on(userSearchActions.toggleFavoriteSuccess, (state, { favorite }) =>
    userSearchAdapter.setOne(favorite, {
      ...state,
      loaded: true,
      savingAsFavorite: false,
    }),
  ),
  on(userSearchActions.toggleFavoriteFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingAsFavorite: false,
  })),
  on(userSearchActions.clearCurrentUserSearch, (state) => ({
    ...state,
    currentUserSearch: undefined,
  })),
);

export function userSearchReducer(state: UserSearchState | undefined, action: Action) {
  return reducer(state, action);
}
