import { createActionGroup, props } from '@ngrx/store';
import {
  AssignmentContract,
  AssignmentDetailedContract,
  AssignmentStateEnum,
} from '@scheduler-frontend/assignment-contracts';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { TransitionEnum } from '../../enums/transition.enum';

export const assignmentsActions = createActionGroup({
  source: '[Assignments]',
  events: {
    removeSlotFormAssignment: props<{ slots: string[] }>(),
    removeSlotFormAssignmentSuccess: props<{ slots: string[] }>(),
    removeSlotFormAssignmentFailure: props<{ error: unknown }>(),

    // todo implement this
    updateAssignment: props<{ assignment: AssignmentDetailedContract }>(),
    addAssignment: props<{ assignment: AssignmentContract | AssignmentDetailedContract }>(),
    addAssignments: props<{ assignments: (AssignmentContract | AssignmentDetailedContract)[] }>(),

    unassignSlot: props<{
      assignment: string;
      assignmentHasSlot: string;
      absencePeriod?: PlainRangeInterface<string>;
    }>(),
    unassignSlotSuccess: props<{ assignment: string; assignmentHasSlot: string }>(),
    unassignSlotFailure: props<{ error: unknown }>(),

    unassign: props<{ assignment: string; absencePeriod?: PlainRangeInterface<string> }>(),
    unassignSuccess: props<{ assignment: string }>(),
    unassignFailure: props<{ error: unknown }>(),

    applyTransition: props<{ assignment?: string; transition: TransitionEnum }>(),
    applyTransitionSuccess: props<{ newState: AssignmentStateEnum }>(),
    applyTransitionFailure: props<{ error: unknown }>(),

    openAssignmentModal: props<{ assignment: string }>(),
  },
});
