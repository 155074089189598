export enum DeliveryTypeEnum {
  ALL_ONLINE = 'ALL_ONLINE',
  ALL_AT_LOCATION = 'ALL_AT_LOCATION',
  ALL_AT_PUPIL_HOME = 'ALL_AT_PUPIL_HOME',
  CANDIDATE_ONLINE_PUPIL_AT_LOCATION = 'CANDIDATE_ONLINE_PUPIL_AT_LOCATION',
  CANDIDATE_AT_LOCATION_PUPIL_ONLINE = 'CANDIDATE_AT_LOCATION_PUPIL_ONLINE',
}

export enum DeliveryTypeDisplayValues {
  ALL_ONLINE = 'Online',
  ALL_AT_LOCATION = 'Op locatie',
  ALL_AT_PUPIL_HOME = 'Bij leerling thuis',
  CANDIDATE_ONLINE_PUPIL_AT_LOCATION = 'Begeleider online, leerling op locatie',
  CANDIDATE_AT_LOCATION_PUPIL_ONLINE = 'Begeleider op locatie, leerling online',
}

export function deliveryTypeSearchQueryMapper() {
  return {
    'deliveryType.deliveryType': {
      entities: DeliveryTypeDisplayValues,
      getDisplayText: (deliveryType: string) => deliveryType,
    },
  };
}
