import { Expose } from 'class-transformer';

/**
 * standard version of the Coordinate resource from Scheduler-api
 */
export class Coordinate {
  /**
   * Latitude in degrees. Values will be clamped to the range [-90, 90]. This means that if the value specified is
   * less than -90, it will be set to -90. And if the value is greater than 90, it will be set to 90.
   */
  @Expose() public latitude: number;

  /**
   * Longitude in degrees. Values outside the range [-180, 180] will be wrapped so that they fall within the
   * range. For example, a value of -190 will be converted to 170. A value of 190 will be converted to -170. This
   * reflects the fact that longitudes wrap around the globe.
   */
  @Expose() public longitude: number;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }

  /**
   * Return a googleMaps api compatible LatLng model.
   */
  public getLatLng(): { lat: number; lng: number } {
    return { lat: this.latitude, lng: this.longitude };
  }

  /**
   * @inheritDoc
   */
  public toString(): string {
    return `${this.latitude},${this.longitude}`;
  }
}
