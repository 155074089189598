import { inject, Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { isDefined } from '@techniek-team/rxjs';
import { addSeconds, isBefore, parseISO } from 'date-fns';
import { combineLatest, firstValueFrom, from, Observable, take } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthApi } from '../../api/auth/auth.api';
import { AuthConfig } from '../../auth.config';
import { Token } from '../../models/token.model';
import { InAppBrowserService } from '../in-app-browser/in-app-browser.service';

export interface RedirectAfterLoginOption {
  /**
   * When true the {@see redirectAfterLogin} method wil redirect to the
   * configured home page regardless of the origin. When false it tries to
   * redirect back to the origin URL and uses the configured home page as
   * fallback URL.
   */
  forceRedirectHome?: boolean;

  /**
   * Optional option to pass any query parameters through to the URL it's going to
   * redirect to.
   */
  queryParams?: Params;
}

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  private alertCtrl = inject(AlertController);
  private storage = inject(Storage);
  private navCtrl = inject(NavController);
  private inAppBrowserService = inject(InAppBrowserService);
  private toastCtrl = inject(ToastController);
  private config = inject(AuthConfig);
  private authApi = inject(AuthApi);
  private router = inject(Router);

  /**
   * Open the Skoleo login screen in the inAppBrowser
   *
   * If the redirect goes to base url of the app. The deeplink package
   * will pick up that url and opens the app. Because deeplinking is
   * not working with ionic serve, we do a localhost check to determine
   * if running ionic serve
   */
  //eslint-disable-next-line max-lines-per-function
  public async initiateAuthCodePKCE(callback?: () => void, service?: string): Promise<void> {
    await this.clearAuthenticationTokens(false);

    const verifier: string = this.generateCodeVerifier();
    const challenge: string = await this.challengeFromVerifier(verifier);
    const startWithLocalhost: boolean = document.URL.startsWith('http://localhost:8100');
    const redirectUri: string = startWithLocalhost
      ? 'http://localhost:8100/'
      : this.config.redirectUri;

    await this.storage.create();
    await Promise.all([
      this.storage.set('code_verifier', verifier),
      this.storage.set('redirect_uri', redirectUri),
      this.storage.set('client_id', this.config.clientId),
      this.storage.set('sso_base_url', `${this.config.ssoBaseUrl}`),
    ]);

    const authorizeUrl: string =
      this.config.authorizeUrl || `${this.config.ssoBaseUrl}/authorization`;

    let url: string =
      authorizeUrl +
      '?response_type=code' +
      '&client_id=' +
      encodeURIComponent(this.config.clientId) +
      '&redirect_uri=' +
      encodeURIComponent(redirectUri) +
      '&code_challenge_method=S256' +
      '&code_challenge=' +
      encodeURIComponent(challenge);

    if (service) {
      url += `&service=${service}`;
    }

    return this.inAppBrowserService.create({
      url: url,
      callback: callback,
      windowName: '_self',
    });
  }

  /**
   * Exchange the code for an accessToken and store
   * the response in the localstorage
   */
  public exchangeAuthCodePKCE(code: string): Observable<void> {
    return from(this.storage.create()).pipe(
      mergeMap(() => {
        return combineLatest([
          from(this.storage.get('code_verifier')),
          from(this.storage.get('redirect_uri')),
        ]).pipe(
          switchMap(([codeVerifier, redirectUri]: [string, string]) => {
            return this.authApi.getToken(code, codeVerifier, redirectUri);
          }),
          catchError(() => {
            this.showToast('Inloggen is mislukt');
            return from(this.navCtrl.navigateRoot(this.config.loginUrl)).pipe(map(() => undefined));
          }),
          isDefined(),
          concatMap((response) => this.storeAccessToken(response as Token)),
          take(1),
        );
      }),
    );
  }

  /**
   * Check if user's accessToken is still valid, if it has expired
   * refresh the token
   */
  public async hasValidAccessToken(): Promise<boolean> {
    await this.storage.create();
    const expiresIn: string = await this.storage.get('accessTokenExpiresIn');
    // there is no token stored in local storage
    if (expiresIn === null) {
      return false;
    }

    // token has expired
    if (isBefore(parseISO(expiresIn), new Date())) {
      return this.refreshAccessToken();
    }

    // token is still valid
    return true;
  }

  /**
   * Get a new accessToken using the refreshToken
   */
  public async refreshAccessToken(): Promise<boolean> {
    await this.storage.create();
    const refreshToken: string = await this.storage.get('refreshToken');
    if (!refreshToken) {
      return false;
    }

    try {
      const response: Token = await firstValueFrom(this.authApi.refreshAccessToken(refreshToken));

      await this.storeAccessToken(response);

      return true;
    } catch (error) {
      await this.presentSessionExpiredAlert();
      return false;
    }
  }

  /**
   * Logout by removing all tokens from the localStorage
   */
  public async clearAuthenticationTokens(clearOriginUrl: boolean = true): Promise<void> {
    await this.storage.create();
    let promises: Promise<unknown>[] = [
      this.storage.remove('accessToken'),
      this.storage.remove('accessTokenExpiresIn'),
      this.storage.remove('refreshToken'),
    ];

    if (clearOriginUrl) {
      promises.push(this.storage.remove('originUrl'));
    }
    return Promise.all(promises).then(() => {
      /* return void */
    });
  }

  /**
   * Redirect to the login page
   *
   * Note: You probably don't need this method. If you are going to use this
   * in the {@see canActivate }. Use the {@see getLoginUrlTree} and use that response
   * as return value for the canActivate method.
   */
  public redirectToLoginPage(): Promise<boolean> {
    return this.navCtrl.navigateRoot(this.getLoginUrlTree());
  }

  /**
   * Redirect to the home or origin page after logging in.
   *
   * Note: You probably don't need this method. If you are going to use this
   * in the {@see canActivate }. Use the {@see getRedirectAfterLoginUrlTree} and use that
   * response as return value for the canActivate method.
   */
  public async redirectAfterLogin(options?: RedirectAfterLoginOption): Promise<boolean> {
    const urlTree: UrlTree = await this.getRedirectAfterLoginUrlTree(options);
    return this.navCtrl.navigateRoot(urlTree);
  }

  /**
   * Method return a {@see UrlTree} for the configured loginUrl.
   *
   * As the {@see canActivate } interface states it should return a UrlTree if the
   * user can't activate and should be redirected to a different route. This Method
   * is a helper function return such an url tree for the login page
   */
  public getLoginUrlTree(): UrlTree {
    return this.router.parseUrl(this.config.loginUrl);
  }

  /**
   * Method return a {@see UrlTree} for the configured loginUrl.
   *
   * As the {@see canActivate } interface states it should return a UrlTree if the
   * user can't activate and should be redirected to a different route. This method
   * is a helper function that returns such a URL tree for the home or origin page.
   */
  public async getRedirectAfterLoginUrlTree(options?: RedirectAfterLoginOption): Promise<UrlTree> {
    options = {
      // merged option with default settings.
      forceRedirectHome: false,
      ...options,
    };
    let urlTree: UrlTree = this.router.parseUrl(this.config.homeUrl);

    if (!options.forceRedirectHome) {
      const originUrl: string | null = await this.storage.get('originUrl');
      if (originUrl && !originUrl.match('.*(login|authenticate).*')) {
        urlTree = this.router.parseUrl(originUrl);
      }
    }

    await this.storage.remove('originUrl');
    urlTree.queryParams = options.queryParams ?? {};
    return urlTree;
  }

  /**
   * Store the accessToken date in the localstorage.
   *
   * Note: Don't change this function to private, because this is used directly
   * for the magic links in the project lyceo-alfred-web-app.
   */
  public async storeAccessToken(token: Token): Promise<void> {
    await this.storage.create();
    const expiresIn: string = addSeconds(new Date(), token.expiresIn).toISOString();
    await Promise.all([
      this.storage.set('accessToken', token.accessToken),
      this.storage.set('accessTokenExpiresIn', expiresIn),
      this.storage.set('refreshToken', token.refreshToken),
    ]);
  }

  /**
   * Create and display the session expired alert
   * When the alert gets closed redirect the user to
   * the skoleo login
   */
  private presentSessionExpiredAlert(): Promise<boolean> {
    this.showToast('Je sessie is verlopen, log opnieuw in.');
    return this.redirectToLoginPage();
  }

  /**
   * Helper method to display a toast
   */
  private showToast(message: string): void {
    this.toastCtrl
      .create({
        message: message,
        duration: 2000,
        color: 'dark',
      })
      .then((newToast) => newToast.present());
  }

  /**
   * Generates a code verifier which is a random code following the specs of RFC7636
   *
   * @see https://www.rfc-editor.org/rfc/rfc7636.html#section-4.1
   */
  private generateCodeVerifier(): string {
    let array: Uint32Array = new Uint32Array(128 / 2);
    window.crypto.getRandomValues(array);
    // this code verifier needs to be between 43 and 128 characters.
    return Array.from(array, this.convertDecimalToHexadecimal).join('').substring(0, 128);
  }

  /**
   * converts decimal to hexadecimal number. It also prefixes the singel characters hex number
   * with a leading zero.
   */
  private convertDecimalToHexadecimal(decimal: number): string {
    return ('0' + decimal.toString(16)).slice(-2);
  }

  /**
   * Create a challenge code from the given verifier.
   */
  private async challengeFromVerifier(verifier: string): Promise<string> {
    let hashed: ArrayBuffer = await this.sha256(verifier);
    return this.base64URLEncode(hashed);
  }

  /**
   * encrypt the given text to sha256
   */
  private sha256(plain: string): Promise<ArrayBuffer> {
    const encoder: TextEncoder = new TextEncoder();
    const data: Uint8Array = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  /**
   * base64 encodes the given arrayBuffer
   */
  private base64URLEncode(plain: ArrayBuffer): string {
    let base64: string = '';
    let bytes: Uint8Array = new Uint8Array(plain);
    for (let i: number = 0; i < bytes.byteLength; i++) {
      base64 += String.fromCharCode(bytes[i]);
    }
    return window.btoa(base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }
}
