import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { PayoutArticleCodeEnum } from '../../enums/payout-article-code.enum';
import {
  adapter,
  ASSIGNMENT_COMPENSATIONS_FEATURE_KEY,
  AssignmentCompensationsState,
} from '../reducer/assignment-compensation.reducer';
import { AssignmentsSelectors } from './assignments.selectors';

const { selectAll, selectEntities } = adapter.getSelectors();

export class AssignmentCompensationsSelectors {
  public static readonly state = createFeatureSelector<AssignmentCompensationsState>(
    ASSIGNMENT_COMPENSATIONS_FEATURE_KEY,
  );

  public static readonly savingAssignmentCompensations = createSelector(
    AssignmentCompensationsSelectors.state,
    (state) => state.savingAssignmentCompensations,
  );

  public static readonly loaded = createSelector(
    AssignmentCompensationsSelectors.state,
    (state) => state.loaded,
  );

  public static readonly loading = createSelector(
    AssignmentCompensationsSelectors.state,
    (state) => state.loading,
  );

  public static readonly activeAssignmentCompensationId = createSelector(
    AssignmentCompensationsSelectors.state,
    (state) => state.selectedId,
  );

  public static readonly savingCompensationLine = createSelector(
    AssignmentCompensationsSelectors.state,
    (state) => state.savingCompensationLine,
  );

  public static readonly assignmentCompensations = createSelector(
    AssignmentCompensationsSelectors.state,
    (state) => (state.loaded ? selectAll(state) : undefined),
  );

  public static readonly assignmentCompensationsEntities = createSelector(
    AssignmentCompensationsSelectors.state,
    (state) => selectEntities(state),
  );

  public static readonly activeAssignmentCompensation = createSelector(
    AssignmentCompensationsSelectors.state,
    AssignmentCompensationsSelectors.assignmentCompensationsEntities,
    (state, entities) => (state.selectedId ? entities[state.selectedId] : undefined),
  );

  public static readonly activeAssignmentCompensationLines = createSelector(
    AssignmentCompensationsSelectors.activeAssignmentCompensation,
    (compensation) => (compensation ? compensation.assignmentCompensationLines : undefined),
  );

  public static readonly activeCompensationAllowedArticleCodes = createSelector(
    AssignmentsSelectors.activeAssignment,
    AssignmentCompensationsSelectors.activeAssignmentCompensationLines,
    UsersSelectors.isAdmin,
    (assignment, compensationLines, isAdmin) => {
      const mapAllowedArticleCode: PayoutArticleCodeEnum[] = [];
      if (compensationLines) {
        const allowExtraExpenses = compensationLines.find(
          (compensationLine) => compensationLine.compensation?.allowExtraExpenses,
        );
        if (allowExtraExpenses) {
          mapAllowedArticleCode.push(PayoutArticleCodeEnum.REIMBURSEMENT);
        }

        const allowExtraHours = compensationLines.find(
          (compensationLine) => compensationLine.compensation?.allowExtraHours,
        );
        if (allowExtraHours) {
          mapAllowedArticleCode.push(PayoutArticleCodeEnum.REMUNERATION);
        }
      }

      if (
        assignment &&
        'addPremiumAllowed' in assignment &&
        assignment?.addPremiumAllowed &&
        isAdmin
      ) {
        mapAllowedArticleCode.push(PayoutArticleCodeEnum.SLOT_PREMIUM);
      }
      return mapAllowedArticleCode;
    },
  );
}
