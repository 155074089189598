import {
  IMutableContext as UnleashMutableContext,
  IStorageProvider as UnleashStorageProvider,
  IToggle as UnleashToggle,
} from 'unleash-proxy-client';

/**
 * Almost exact representation of import { IConfig } from 'unleash-proxy-client';
 */
export class TtFeatureFlagsConfig {

  /**
   * The Unleash Proxy URL to connect to. E.g.: https://examples.com/proxy
   */
  public url!: string;

  /**
   * The name of the application using this SDK. Will be used as part of the
   * metrics sent to Unleash Proxy. Will also be part of the Unleash Context.
   */
  public appName!: string;

  /**
   * The Unleash Proxy Secret to be used
   */
  public clientKey?: string;

  /**
   * How often, in seconds, the SDK should check for updated toggle configuration.
   * If set to 0 will disable checking for updates
   */
  public refreshInterval?: number = 900; // 15 minutes

  /**
   * If set to true, the client will not check for updated toggle configuration
   */
  public disableRefresh?: boolean = false;

  /**
   * How often, in seconds, the SDK should send usage metrics back to
   * Unleash Proxy
   *
   * Note: Gitlab Feature flags doesn't uses this data.
   */
  public metricsInterval?: number = 3600; // 1 hour

  /**
   * Set this option to true if you want to disable usage metrics.
   *
   * Note: Gitlab Feature flags doesn't uses this data.
   */
  public disableMetrics?: boolean = true;

  /**
   * Allows you to inject a custom storeProvider. Defaults to LocalStorageProvider
   */
  public storageProvider?: UnleashStorageProvider;

  /**
   * Identify the current environment. Will be part of the Unleash Context.
   *
   * This environment should match the environments set in Gitlab Feature flags
   */
  public environment?: string;

  /**
   * Allows you to bootstrap the cached feature toggle configuration.
   */
  public bootstrap?: UnleashToggle[] = [];

  /**
   * Should the boostrap automatically override cached data in the local-storage.
   * Will only be used if boostrap is not an empty array.
   */
  public bootstrapOverride?: boolean = true;

  public context?: UnleashMutableContext;
}
