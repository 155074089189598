import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ApproachDetailedContract } from '@scheduler-frontend/approach-contracts';
import { approachesActions, ApproachesSelectors } from '@scheduler-frontend/data-access-approaches';
import { SchedulingSelectors } from '@scheduler-frontend/data-access-scheduling';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import {
  catchError,
  combineLatest,
  exhaustMap,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CandidatesByRankingApi } from '../api/candidates-by-ranking.api';
import { candidatesByRankingActions } from '../candidates-by-ranking.actions';

@Injectable()
export class LoadSlotsByCandidateEffect implements OnRunEffects {
  private readonly actions$ = inject(Actions);

  private readonly candidatesByRankingApi = inject(CandidatesByRankingApi);

  private readonly store = inject(Store);

  //eslint-disable-next-line max-lines-per-function
  public loadBestMatchingSlotsForSelectedCandidate = createEffect(() =>
    combineLatest([
      this.store.pipe(
        select(SchedulingSelectors.selectedCandidateIds),
        filter((selectedCandidates) => !!selectedCandidates?.length),
      ),
      this.store.pipe(
        select(ApproachesSelectors.activeApproach),
        isDefined(),
        filter((approach) => approach.hasOwnProperty('approachHasSlots')),
        isDefined(),
      ),
    ]).pipe(
      switchMap(([selectedCandidates, approach]) => {
        return this.candidatesByRankingApi
          .getSlotsForCandidate(
            selectedCandidates[0],
            (approach as ApproachDetailedContract).filters,
          )
          .pipe(
            map((collection) => {
              return candidatesByRankingActions.loadSlotsByCandidateSuccess({
                items: collection['hydra:member'],
              });
            }),
            catchError((error) =>
              of(candidatesByRankingActions.loadSlotByCandidateFailure({ error: error })),
            ),
          );
      }),
      takeUntil(this.actions$.pipe(ofType(candidatesByRankingActions.stopLoadSlotByCandidate))),
    ),
  );

  public handleFailureOfLoadBestMatchingSlotsForSelectedCandidate = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidatesByRankingActions.loadSlotByCandidateFailure, {
          message: 'Er is iets misgegaan bij het laden van de shifts voor deze begeleider.',
        }),
      ),
    { dispatch: false },
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions$.pipe(ofType(approachesActions.stopSchedulingByApproaches))),
        ),
      ),
    );
  }
}
