import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { ProductTypesEffects } from './+state/product-types.effects';
import { PRODUCT_TYPES_FEATURE_KEY, productTypesReducer } from './+state/product-types.reducer';
import { ProductTypesStoreService } from './product-types-store.service';

export function provideProductTypesStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: PRODUCT_TYPES_FEATURE_KEY, reducer: productTypesReducer }),
    provideEffects(ProductTypesEffects),
    ProductTypesStoreService,
  ];
}
