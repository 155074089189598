import { Router } from '@angular/router';
import { ApproachContract, ApproachState } from '@scheduler-frontend/approach-contracts';
import { ToastService } from '@techniek-team/services';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';

export async function navigateToCorrectStepInFlow(
  approach: ApproachContract,
  router: Router,
  toastService: ToastService,
): Promise<void> {
  const isApproachFlow: boolean = router.url.includes('benader-begeleider');
  if (!isApproachFlow) {
    return;
  }

  const isAtApproachPage: boolean = isApproachFlow && !router.url.includes('/inplannen');
  const isAtAssignPage: boolean = isApproachFlow && router.url.includes('/inplannen');

  if (isAtApproachPage && approach.state === ApproachState.TODO) {
    return;
  }

  const toastMessage: string =
    'De link die je hebt gebruikt is verouderd. Je bent doorgestuurd naar de juiste pagina.';

  const matchedIncorrectState: boolean = [ApproachState.WAITING, ApproachState.HANDLED].some(
    (state: ApproachState): boolean => state === approach.state,
  );
  if (isAtApproachPage && matchedIncorrectState) {
    await router.navigateByUrl(`/benader-begeleider/${jsonLdSelectId(approach)}/inplannen`);
    await toastService.create({ message: toastMessage });
    return;
  }

  if (isAtAssignPage && approach.state === ApproachState.TODO) {
    await router.navigateByUrl(`/benader-begeleider/${jsonLdSelectId(approach)}`);
    await toastService.create({ message: toastMessage });
  }
}
