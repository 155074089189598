import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ApproachContract } from '@scheduler-frontend/approach-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { candidateApproachesActions } from '../actions/candidate-approaches.actions';

export const CANDIDATE_APPROACHES_FEATURE_KEY: string = 'candidateApproaches';

export interface CandidateApproachesState extends EntityState<ApproachContract> {
  loaded: boolean;
  loading: boolean;
  loadedChunks: number[];
  lastChunkLoaded: boolean;
  totalItems?: number | null;
  error?: unknown | null;
}

export const adapter: EntityAdapter<ApproachContract> = createEntityAdapter<ApproachContract>({
  selectId: jsonLdSelectId,
});

export const initialCandidateApproachesState: CandidateApproachesState = adapter.getInitialState({
  loaded: false,
  loading: false,
  loadedChunks: [],
  lastChunkLoaded: false,
  error: null,
});

const reducer = createReducer(
  initialCandidateApproachesState,
  on(candidateApproachesActions.loadApproaches, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(candidateApproachesActions.loadNextChunk, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(candidateApproachesActions.loadApproachesSuccess, (state, { approaches, chunk, totalItems }) =>
    adapter.setAll(approaches, {
      ...state,
      loading: false,
      loaded: true,
      loadedChunks: [chunk],
      lastChunkLoaded: approaches.length === totalItems,
      error: null,
    }),
  ),
  on(candidateApproachesActions.loadNextChunkSuccess, (state, { approaches, chunk, totalItems }) =>
    adapter.addMany(approaches, {
      ...state,
      loading: false,
      loaded: true,
      loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()],
      lastChunkLoaded: state.ids.length + approaches.length === totalItems,
      error: null,
    }),
  ),
  on(candidateApproachesActions.loadApproachesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function candidateApproachesReducer(
  state: CandidateApproachesState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
