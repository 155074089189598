import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  forwardRef,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { isBookingPeriodClosed } from '@scheduler-frontend/booking-service';
import { SlotsPermissionsStoreService } from '@scheduler-frontend/data-access-slots';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { TtSelectionModel } from '@techniek-team/common';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import {
  addMonths,
  addWeeks,
  differenceInCalendarISOWeeks,
  eachDayOfInterval,
  getISODay,
  isAfter,
  isBefore,
  isSameMonth,
  isToday,
  isWithinInterval,
  lastDayOfISOWeek,
  lastDayOfMonth,
  startOfISOWeek,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { memoize } from 'lodash-es';
import { NgxControlError } from 'ngxtension/control-error';
import { CreateSlotsSelectedStoreService } from '../../create-slots-selected-store.service';

interface Day {
  date: Date;
  isDisabled: boolean;
  isToday: boolean;
}

interface Month {
  daysPerWeek: Day[][];
  firstOfMonth: Date;
}

@Component({
  selector: 'app-dates-control',
  standalone: true,
  templateUrl: './dates-control.component.html',
  styleUrls: ['./dates-control.component.scss'],
  imports: [IonicModule, DatePipe, NgxControlError],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatesControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatesControlComponent implements OnInit, ControlValueAccessor {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  private readonly slotsPermissionsStoreService = inject(SlotsPermissionsStoreService);

  private readonly createSlotsSelectedStoreService = inject(CreateSlotsSelectedStoreService);

  public min = input<Date | null>(null);

  public max = input<Date | null>(null);

  protected isControlDisabled = signal(false);

  private firstDayOfFirstMonth = signal(startOfMonth(new Date()));

  private monthsCache = new Map<string, Month>();

  /**
   * A list of all the weeks in the displayed months and their week numbers.
   */
  protected monthsMapping = computed(() => {
    const schedule = this.createSlotsSelectedStoreService.schedule();
    const scheduleKey = schedule ? jsonLdSelectId(schedule) : '';
    const months: Month[] = [];

    for (let i = 0; i < 3; i++) {
      const firstDay: Date = addMonths(this.firstDayOfFirstMonth(), i);

      if (!this.monthsCache.has(firstDay.toString() + scheduleKey)) {
        this.monthsCache.set(
          firstDay.toString() + scheduleKey,
          this.createMonthMapping(firstDay, schedule),
        );
      }

      const month: Month | undefined = this.monthsCache.get(firstDay.toString() + scheduleKey);
      if (month !== undefined) {
        months.push(month);
      }
    }

    return months;
  });

  protected selection = new TtSelectionModel<Date>(true);

  /**
   * the onChange callback
   */
  private onChange = (_newValue: Date[] | null) => {
    /* callback */
  };

  /**
   * The onTouch callback.
   */
  public onTouch = () => {
    /* callback */
  };

  protected isOtherMonthMemoize = memoize(
    function (targetDay: Date, targetOtherMonth: Date) {
      return !isSameMonth(targetDay, targetOtherMonth);
    },
    (...params) => params.map((param) => param.toString()).join(''),
  );

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.registerOnChangeObserver();
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: (newValue: Date[] | null) => void): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    this.isControlDisabled.set(isDisabled);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * @inheritDoc
   */
  public writeValue(value: Date[]): void {
    if (!value) {
      this.selection.clear();
      return;
    }

    this.selection.setSelection(...value);
    this.changeDetectorRef.markForCheck();
  }

  protected toggleDay(day: { date: Date }): void {
    if (this.selection.isSelected(day.date)) {
      this.selection.deselect(day.date);
      return;
    }

    this.selection.select(day.date);
  }

  protected nextMonth(): void {
    this.firstDayOfFirstMonth.update((current) => addMonths(current, 1));
  }

  protected previousMonth(): void {
    this.firstDayOfFirstMonth.update((current) => subMonths(current, 1));
  }

  /**
   * Check and change the selection status for a given ISO day of the week.
   * If all related days are unselected, it selects them all. Otherwise, it deselects all days.
   */
  protected toggleDayOfTheWeek(isoWeekDay: number, month: Month): void {
    const days: Day[] = month.daysPerWeek.flatMap((currentWeek) => {
      return currentWeek.filter(
        (day) => isSameMonth(month.firstOfMonth, day.date) && getISODay(day.date) === isoWeekDay,
      );
    });

    this.toggleSelection(days, () => true);
  }

  /**
   * Check and change the selection status for a given week.
   * If all days are unselected, it selects them all. Otherwise, it deselects all days.
   */
  protected toggleWeek(week: Day[], month: Month): void {
    this.toggleSelection(week, (day) => isSameMonth(month.firstOfMonth, day.date));
  }

  private toggleSelection(days: Day[], selector: (day: Day) => boolean) {
    const allDaysSelected: boolean = days.every((day) => !this.selection.isSelected(day.date));

    for (const day of days) {
      if (selector(day)) {
        if (allDaysSelected) {
          this.selection.select(day.date);
        } else {
          this.selection.deselect(day.date);
        }
      }
    }
  }

  /**
   * Method which creates a 3D map of all dates within the month of the given date.
   * So it returns an array containing weeks, where a week is an array of days (Date),
   */
  private createMonthMapping(month: Date, schedule: ScheduleContract | undefined): Month {
    let monthMap: Day[][] = [];
    const firstOfMonth: Date = startOfMonth(month);
    const endOfMonth: Date = lastDayOfMonth(month);
    const amountOfWeeks: number = differenceInCalendarISOWeeks(endOfMonth, firstOfMonth);
    for (let i: number = 0; i <= amountOfWeeks; i++) {
      monthMap.push(
        eachDayOfInterval({
          start: startOfISOWeek(addWeeks(firstOfMonth, i)),
          end: lastDayOfISOWeek(addWeeks(firstOfMonth, i)),
        }).map((date) => {
          return {
            date: date,
            isDisabled: this.isDisabled(date, schedule),
            isToday: isToday(date),
          };
        }),
      );
    }
    return {
      daysPerWeek: monthMap,
      firstOfMonth: firstOfMonth,
    };
  }

  private isDisabled(day: Date, schedule: ScheduleContract | undefined): boolean {
    if (schedule && !isWithinInterval(day, schedule.validityRange)) {
      return true;
    }
    if (this.min() && isBefore(day, this.min() as Date)) {
      return true;
    }

    if (this.max() && isAfter(day, this.max() as Date)) {
      return true;
    }

    return (
      isBookingPeriodClosed(day) &&
      !this.slotsPermissionsStoreService.canCreateOutsideBookingPeriod()
    );
  }

  private registerOnChangeObserver(): void {
    this.selection.changed.subscribe((_) => {
      this.onChange(this.selection.size > 0 ? this.selection.selected : null);
    });
  }
}
