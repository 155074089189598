/** @format **/
import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Provider,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  BrowserTracing,
  init,
  Replay,
  routingInstrumentation,
  TraceService,
} from '@sentry/angular-ivy';
import { Integration } from '@sentry/types';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { SentryEnvironment, SentryWebConfig } from './sentry-web.config';

/**
 * @deprecated
 */
@NgModule({})
export class SentryWebModule {
  //eslint-disable-next-line max-lines-per-function
  public static forRoot(options: SentryWebConfig): ModuleWithProviders<SentryWebModule> {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mergedOptions: any = { ...new SentryWebConfig(), ...options };
    const tracingProviders: Provider[] = [];
    const traceConfig: Partial<SentryWebConfig> = {};

    if (options.enableTracing) {
      tracingProviders.push({ provide: TraceService, deps: [Router] });
      tracingProviders.push({
        provide: APP_INITIALIZER,
        useFactory: (): Function => (): void => {
          /* empty */
        },
        deps: [TraceService],
        multi: true,
      });
      traceConfig.replaysOnErrorSampleRate = 1.0;
      traceConfig.replaysSessionSampleRate = options.environment === SentryEnvironment.LOCAL ? 1.0 : 0.1;
      traceConfig.tracesSampleRate = options.environment === SentryEnvironment.LOCAL ? 1.0 : 0.5;
    }

    init({
      ...mergedOptions,
      enabled: true,
      defaultIntegrations: false,
      integrations: SentryWebModule.createIntegrations(options),
    });

    return {
      ngModule: SentryWebModule,
      providers: [
        { provide: SentryWebConfig, useValue: options },
        { provide: SentryErrorHandler },
        { provide: ErrorHandler, useExisting: SentryErrorHandler },
        ...tracingProviders,
      ],
    };
  }

  private static createIntegrations(
    options: SentryWebConfig,
  ): (integrations: Integration[]) => Integration[] {
    return (integrations: Integration[]) => {
      if (options.enableTracing) {
        integrations.push(new Replay());
        integrations.push(
          new BrowserTracing({
            routingInstrumentation: routingInstrumentation,
          }),
        );
      }
      return integrations;
    };
  }
}
