import { animate, animation, AnimationReferenceMetadata, style } from '@angular/animations';
import { M3Duration, M3Easing } from './m3-motion.enum';

export const tableEnterAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 0, lineHeight: 0, paddingTop: '0', paddingBottom: '0' }),
  animate(
    `${M3Duration.LONG_2} ${M3Easing.EMPHASIZED_DECELERATE}`,
    style({
      maxHeight: 50,
      lineHeight: 1.5,
      paddingTop: '10px',
      paddingBottom: '10px',
    }),
  ),
]);

export const tableLeaveAnimation: AnimationReferenceMetadata = animation([
  style({
    maxHeight: 50,
    lineHeight: 1.5,
    paddingTop: '10px',
    paddingBottom: '10px',
  }),
  animate(
    `${M3Duration.SHORT} ${M3Easing.EMPHASIZED_ACCELERATE}`,
    style({ maxHeight: 0, lineHeight: 0, paddingTop: '0', paddingBottom: '0' }),
  ),
]);

export const tableLoadingEnterAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 0 }),
  animate(`${M3Duration.SHORT} ${M3Easing.EMPHASIZED_DECELERATE}`, style({ maxHeight: 4 })),
]);

export const tableLoadingLeaveAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 4 }),
  animate(
    `${M3Duration.SHORT} ${M3Easing.EMPHASIZED_ACCELERATE}`,
    style({ maxHeight: 0, lineHeight: 0, padding: '0 16px' }),
  ),
]);

export const noTableDataEnterAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 0, paddingTop: '0', paddingBottom: '0' }),
  animate(
    `${M3Duration.LONG_2} ${M3Easing.EMPHASIZED_DECELERATE}`,
    style({ maxHeight: 100, paddingTop: '10px', paddingBottom: '10px' }),
  ),
]);

export const noTableDataLeaveAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 100, paddingTop: '10px', paddingBottom: '10px' }),
  animate(
    `${M3Duration.SHORT} ${M3Easing.EMPHASIZED_ACCELERATE}`,
    style({ maxHeight: 0, paddingTop: '0', paddingBottom: '0' }),
  ),
]);

export const gridCellDataEnterAnimation: AnimationReferenceMetadata = animation([
  style({ transform: 'scale(0)' }),
  animate(
    `${M3Duration.LONG} ${M3Easing.EMPHASIZED_DECELERATE}`,
    style({ transform: 'scale(1.05)' }),
  ),
  animate(
    `${M3Duration.SHORT} ${M3Easing.EMPHASIZED_DECELERATE}`,
    style({ transform: 'scale(1)' }),
  ),
]);

export const gridCellDataLeaveAnimation: AnimationReferenceMetadata = animation([
  style({ transform: 'scale(1)' }),
  animate(
    `${M3Duration.SHORT} ${M3Easing.EMPHASIZED_ACCELERATE}`,
    style({ transform: 'scale(1.05)' }),
  ),
  animate(
    `${M3Duration.SHORT} ${M3Easing.EMPHASIZED_ACCELERATE}`,
    style({ transform: 'scale(0)' }),
  ),
]);
