import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { locationsActions, LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, isAction } from '@techniek-team/tt-ngrx';
import { formatISO, isAfter, subDays } from 'date-fns';
import { catchError, exhaustMap, from, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { WithoutFollowUpSlotContract } from '../../contracts/without-follow-up-slot.contract';
import { WithoutFollowUpSlotApi } from '../api/without-follow-up-slot.api';
import { AWAITING_FOLLOW_UP_SLOTS_FEATURE_KEY } from '../awaiting-follow-up-slots.reducer';
import { awaitingSlotsActions } from '../awaiting-slots.actions';

@Injectable()
export class AwaitingFollowUpSlotsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly storage = inject(Storage);

  private readonly store = inject(Store);

  private readonly tutoringApi = inject(WithoutFollowUpSlotApi);

  public createSubjectsFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(awaitingSlotsActions.loadWithoutFollowUpSlotFailure, {
          message:
            'Er is iets misgegaan bij het laden van de leerlingen die wachten op een vervolg-shift.',
        }),
      ),
    { dispatch: false },
  );

  public saveAwaitingForSlotsToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(awaitingSlotsActions.loadWithoutFollowUpSlotSuccess),
        concatLatestFrom(() =>
          this.store.pipe(select(LocationsSelectors.currentLocationId), isDefined()),
        ),
        tap(([action, currentLocation]) => {
          return from(
            Promise.all([
              this.storage.set(`${AWAITING_FOLLOW_UP_SLOTS_FEATURE_KEY}-store-cache`, {
                items: action.items,
                timestamp: new Date(),
              }),
            ]),
          );
        }),
      ),
    { dispatch: false },
  );

  //eslint-disable-next-line max-lines-per-function
  public loadPupilsWithoutFollowUpSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(
        awaitingSlotsActions.loadWithoutFollowUpSlot,
        awaitingSlotsActions.loadWithoutFollowUpSlotReload,
        locationsActions.selectLocation,
      ),
      switchMap((action) =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          concatLatestFrom(() =>
            this.store.pipe(select(LocationsSelectors.currentLocationId), isDefined()),
          ),
          exhaustMap(([_user, currentLocation]) => {
            return from<Promise<{ timestamp: Date; items: WithoutFollowUpSlotContract[] } | null>>(
              this.storage.get(
                `${AWAITING_FOLLOW_UP_SLOTS_FEATURE_KEY}-${currentLocation}-store-cache`,
              ),
            ).pipe(
              exhaustMap((cache) => {
                const isReload = isAction(
                  action,
                  awaitingSlotsActions.loadWithoutFollowUpSlotReload,
                );
                if (!isReload && cache && isAfter(cache.timestamp, subDays(new Date(), 1))) {
                  return of(
                    awaitingSlotsActions.loadFormCacheWithoutFollowUpSlotSuccess({
                      items: cache.items,
                      totalItems: cache.items.length,
                      cacheTimestamp: formatISO(cache.timestamp),
                    }),
                  );
                }
                return this.tutoringApi.execute(currentLocation).pipe(
                  map((response) => {
                    return awaitingSlotsActions.loadWithoutFollowUpSlotSuccess({
                      items: response['hydra:member'],
                      totalItems: response['hydra:totalItems'],
                    });
                  }),
                );
              }),
              catchError((error) =>
                of(awaitingSlotsActions.loadWithoutFollowUpSlotFailure({ error: error })),
              ),
            );
          }),
        ),
      ),
    ),
  );

  public ngrxOnInitEffects() {
    return awaitingSlotsActions.loadWithoutFollowUpSlot();
  }
}
