import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { environment } from '@scheduler-frontend/environments';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { Observable } from 'rxjs';
import { SlotToCreateContract } from '../../../contract/slot-to-create.contract';

@Injectable({
  providedIn: 'root',
})
export class CreateSlotApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(slotsToCreate: SlotToCreateContract[]): Observable<SlotDetailedContract[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/slots`;

    return this.httpClient.post<SlotDetailedContract[]>(url, {
      slots: slotsToCreate.map((item) => ({
        scheduleId: jsonLdSelectId(item.schedule['@id']),
        startTime: item.timeRange.start,
        endTime: item.timeRange.end,
        roleId: jsonLdSelectId(item.role),
        locationId: item.location ? jsonLdSelectId(item.location) : undefined,
        subjectId: item.subject ? jsonLdSelectId(item.subject) : undefined,
        level: item.level,
        lessonId: item.lesson ? jsonLdSelectId(item.lesson) : undefined,
        deliveryTypeId: item.deliveryType ? jsonLdSelectId(item.deliveryType) : undefined,
        numberOfPupils: item.amountOfPupils,
        source: 'SCHEDULER',
      })),
    });
  }
}
