import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'tt-modal-title',
    templateUrl: './tt-modal-title.component.html',
    styleUrls: ['./tt-modal-title.component.scss'],
    standalone: true,
    imports: [IonicModule],
})
export class TtModalTitleComponent {

  @Input() public color: string = 'primary';

  @Input() public isLoading: boolean = false;

}
