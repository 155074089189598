import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Release } from '@scheduler-frontend/models';
import { denormalize } from '@techniek-team/class-transformer';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_RELEASES } from './release.response.object';

export interface ReleaseApiInterface {
  getReleases(): Observable<Release[]>;
}

@Injectable({
  providedIn: 'root',
})
export class ReleaseApi implements ReleaseApiInterface {
  protected readonly httpClient = inject(HttpClient);

  public getReleases(): Observable<Release[]> {
    return of(GET_RELEASES).pipe(map((response) => denormalize(Release, response)));
    return this.httpClient
      .get<Release[]>('releases')
      .pipe(map((response) => denormalize(Release, response)));
  }
}
