import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductTypesSelectors } from '@scheduler-frontend/data-access-product-types';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { CREATE_SLOTS_FEATURE_KEY, CreateSlotsState } from '../reducers/create-slots.reducer';

export class SelectedSelectors {
  public static readonly slotsState =
    createFeatureSelector<CreateSlotsState>(CREATE_SLOTS_FEATURE_KEY);

  public static readonly slotTemplate = createSelector(
    SelectedSelectors.slotsState,
    (state) => state.slotTemplate,
  );

  public static readonly tempBusinessService = createSelector(
    SelectedSelectors.slotTemplate,
    (slotTemplate) => slotTemplate.tempBusinessService,
  );

  public static readonly businessService = createSelector(
    SelectedSelectors.slotTemplate,
    (slotTemplate) => slotTemplate.businessService,
  );

  public static readonly productLocation = createSelector(
    SelectedSelectors.slotTemplate,
    (slotTemplate) => slotTemplate.productLocation,
  );

  public static readonly deliveryType = createSelector(
    SelectedSelectors.slotTemplate,
    (slotTemplate) => slotTemplate.deliveryType,
  );

  public static readonly tempSchedule = createSelector(
    SelectedSelectors.slotTemplate,
    (slotTemplate) => slotTemplate.tempSchedule,
  );

  public static readonly schedule = createSelector(
    SelectedSelectors.slotTemplate,
    (slotTemplate) => slotTemplate.schedule,
  );

  public static readonly productType = createSelector(
    SelectedSelectors.businessService,
    ProductTypesSelectors.productTypeEntities,
    (businessService, productTypes) =>
      businessService?.productType
        ? productTypes[jsonLdSelectId(businessService.productType)]
        : undefined,
  );
}
