import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { RoleContract, RoleDetailedContract } from '../contracts/role.contract';
import { rolesActions } from './roles.actions';

export const ROLES_FEATURE_KEY = 'roles';

export interface RolesState extends EntityState<RoleContract | RoleDetailedContract> {
  totalItems?: number;

  loaded: boolean;

  loading: boolean;

  error?: unknown | null;

  cacheTimeStamp?: string;
}

export const rolesAdapter: EntityAdapter<RoleContract | RoleDetailedContract> = createEntityAdapter<
  RoleContract | RoleDetailedContract
>({
  selectId: jsonLdSelectId,
});

export const initialRolesState: RolesState = rolesAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
});

const reducer = createReducer(
  initialRolesState,
  on(rolesActions.initRoles, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(rolesActions.reloadRoles, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(rolesActions.loadRolesSuccess, (state, { roles, totalItems }) =>
    rolesAdapter.setAll(roles, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: formatISO(new Date()),
    }),
  ),
  on(rolesActions.loadFromCacheRolesSuccess, (state, { roles, totalItems, cacheTimestamp }) =>
    rolesAdapter.setAll(roles, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: cacheTimestamp,
    }),
  ),
  on(rolesActions.loadRolesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function rolesReducer(state: RolesState | undefined, action: Action) {
  return reducer(state, action);
}
