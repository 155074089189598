import { LowerCasePipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleCheck,
  faCircleInfo,
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';
import { IonicModule, ModalController } from '@ionic/angular';
import {
  AssignmentStateDisplayValues,
  AssignmentStateEnum,
  AssignmentStateExplanation,
} from '@scheduler-frontend/assignment-contracts';
import { AssignmentTransitionMessageEnum } from '@scheduler-frontend/data-access-assignment';
import { TtModalModule } from '@techniek-team/components/modal';

interface AppliedTransition {
  oldState: AssignmentStateEnum;
  newState: AssignmentStateEnum;
  messages: {
    text: string;
    code: number;
    messageType: AssignmentTransitionMessageEnum;
  }[];
}

@Component({
  selector: 'app-assignment-transition-messages-modal',
  templateUrl: './assignment-transition-messages-modal.component.html',
  styleUrls: ['./assignment-transition-messages-modal.component.scss'],
  standalone: true,
  imports: [TtModalModule, MatTooltip, FaIconComponent, LowerCasePipe, IonicModule],
})
export class AssignmentTransitionMessagesModalComponent {
  protected readonly modalController = inject(ModalController);

  protected readonly faExclamationCircle = faExclamationCircle;

  public readonly appliedTransition = input.required<AppliedTransition, AppliedTransition>({
    transform: (response: AppliedTransition) => {
      const order: AssignmentTransitionMessageEnum[] = [
        AssignmentTransitionMessageEnum.ERROR,
        AssignmentTransitionMessageEnum.WARNING,
        AssignmentTransitionMessageEnum.INFO,
        AssignmentTransitionMessageEnum.SUCCESS,
      ];

      response.messages.sort((a, b) => {
        return order.indexOf(a.messageType) - order.indexOf(b.messageType);
      });
      return response;
    },
  });

  protected readonly newTransitionState = computed(() => this.appliedTransition().newState);

  protected readonly newTransitionStateDisplay = computed(
    () => AssignmentStateDisplayValues[this.appliedTransition().newState],
  );

  protected readonly newTransitionStateExplanation = computed(
    () => AssignmentStateExplanation[this.appliedTransition().newState],
  );

  protected readonly oldTransitionState = computed(() => this.appliedTransition().oldState);

  protected readonly oldTransitionStateDisplay = computed(
    () => AssignmentStateDisplayValues[this.appliedTransition().oldState],
  );

  protected readonly oldTransitionStateExplanation = computed(
    () => AssignmentStateExplanation[this.appliedTransition().oldState],
  );

  protected readonly messages = computed(() => this.appliedTransition().messages);

  protected readonly iconMap: { [key in AssignmentTransitionMessageEnum]: IconProp } = {
    [AssignmentTransitionMessageEnum.ERROR]: faExclamationCircle,
    [AssignmentTransitionMessageEnum.WARNING]: faExclamationTriangle,
    [AssignmentTransitionMessageEnum.INFO]: faCircleInfo,
    [AssignmentTransitionMessageEnum.SUCCESS]: faCircleCheck,
  };
}
