import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AssignmentHasSlotDetailed } from '@scheduler-frontend/assignment-contracts';
import { TsRange } from '@techniek-team/class-transformer';
import { TsRangeInterface } from '@techniek-team/common';
import { Observable } from 'rxjs';
import { absenceActions } from './+state/absence.actions';
import { AbsenceSelectors } from './+state/absence.selectors';

@Injectable()
export class AbsenceStoreService {
  private readonly store = inject(Store);

  public savingMarkAsAbsent$: Observable<boolean> = this.store.pipe(
    select(AbsenceSelectors.savingMarkAsAbsent),
  );

  public savingMarkAsPresent$: Observable<boolean> = this.store.pipe(
    select(AbsenceSelectors.savingMarkAsPresent),
  );

  public markAsAbsent(
    candidate: string,
    absencePeriod: TsRange | TsRangeInterface<string>,
    assignmentHasSlot: string | AssignmentHasSlotDetailed,
  ): void {
    if (absencePeriod instanceof TsRange) {
      absencePeriod = absencePeriod.toObject();
    }
    if (assignmentHasSlot instanceof AssignmentHasSlotDetailed) {
      assignmentHasSlot = assignmentHasSlot.getId();
    }
    this.store.dispatch(
      absenceActions.markAsAbsent({
        candidate: candidate,
        absencePeriod: absencePeriod,
        assignmentHasSlots: [assignmentHasSlot],
      }),
    );
  }

  public markAsPresent(
    candidate: string,
    assignmentHasSlot: AssignmentHasSlotDetailed | string,
  ): void {
    if (assignmentHasSlot instanceof AssignmentHasSlotDetailed) {
      assignmentHasSlot = assignmentHasSlot.getId();
    }
    this.store.dispatch(
      absenceActions.markAsPresent({
        candidate: candidate,
        assignmentHasSlot: assignmentHasSlot,
      }),
    );
  }
}
