import { TtSelectionModel } from '@techniek-team/common';
import { Exclude, Expose, Type } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  BaseFilterGroup,
  BaseFilterGroupOptions,
  FilterGroupDisplayState,
  FilterGroupInteractionState,
} from './base-filter-group.model';
import { FilterGroupInterface } from './filter-group.interface';
import { Filter } from './filter.model';

export interface FilterGroupOptions extends BaseFilterGroupOptions {}

export class FilterGroup
  extends BaseFilterGroup
  implements FilterGroupInterface
{
  public readonly uniqueId: string = window.crypto.randomUUID();

  public override readonly type = 'FilterGroup';

  /**
   * This property will be used as the type display text within the autocomplete.
   * It wil be displayed on the right side to indicated that the item in question belongs to this
   * FilterGroup. If not set the {@see this.label} will be used
   * If not set the name property will be used.
   *
   * @inheritDoc
   */
  @Expose() public itemLabel?: string;

  @Type(() => Filter)
  @Expose()
  public get filters(): Filter[] {
    return this.availableFilters$.getValue();
  }

  public set filters(filters: Filter[]) {
    this.setFilters(filters);
  }

  /**
   * Returns the display text of the filter groups within the autocomplete field.
   */
  @Exclude()
  public get itemDisplayText(): string {
    return this.itemLabel ?? this.label ?? this.key;
  }

  /**
   * Returns the amount of available filters.
   */
  @Exclude()
  public get size(): number {
    return this.filters.length;
  }

  @Exclude()
  public get selected(): Filter[] {
    return this.selection.selected;
  }

  @Exclude() protected selection!: TtSelectionModel<Filter>;

  /**
   * An array of filters within this group.
   *
   * @inheritDoc from {@see FilterGroupInterface.filters}
   */
  @Exclude() protected availableFilters$ = new BehaviorSubject<Filter[]>([]);

  @Exclude()
  public readonly filters$ = this.availableFilters$.asObservable();

  constructor(
    key: string,
    filters?: Observable<Filter[]> | Filter[],
    options?: FilterGroupOptions,
  ) {
    super();
    this.key = key;
    this.required = options?.required ?? false;
    this.label = options?.label;
    this.itemLabel = options?.itemLabel;
    this.position = options?.position;
    this.multiple = options?.multiple ?? false;
    this.visibility = options?.visibility ?? FilterGroupDisplayState.VISIBLE;
    this.interactionState.next(
      //eslint-disable-next-line max-len
      typeof options?.disabled === 'boolean' && options?.disabled
        ? FilterGroupInteractionState.DISABLED
        : FilterGroupInteractionState.ENABLED,
    );
    this.selection = new TtSelectionModel<Filter>(this.multiple, (item) => {
      return item.key;
    });
    this.setFilters(filters ?? []);
  }

  /**
   * Setter for {@see availableFilters$}.
   */
  @Exclude()
  public setFilterObservable(observable: Observable<Filter[]>): void {
    this.setFilters(observable);
  }

  /**
   * Enables this filterGroup. See {@see interactionState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public override enable(
    options: { onlySelf: boolean } = { onlySelf: false },
  ): void {
    super.enable(options);
    if (!options.onlySelf) {
      for (let filter of this.filters) {
        filter.enable();
      }
    }
  }

  /**
   * Disables this filterGroup. See {@see interactionState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public override disable(
    options: { onlySelf: boolean } = { onlySelf: false },
  ): void {
    super.disable(options);
    if (!options.onlySelf) {
      for (let filter of this.filters) {
        filter.disable();
      }
    }
  }

  /**
   * Makes this filter visible. See {@see displayState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public override show(
    options: { onlySelf: boolean } = { onlySelf: false },
  ): void {
    super.show(options);
    if (!options.onlySelf) {
      for (let filter of this.filters) {
        filter.show();
      }
    }
  }

  /**
   * Makes this filter invisible. See {@see displayState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public override hide(
    options: { onlySelf: boolean } = { onlySelf: false },
  ): void {
    super.hide(options);
    if (!options.onlySelf) {
      for (let filter of this.filters) {
        filter.hide();
      }
    }
  }

  @Exclude()
  public select(targetFilter: Filter): void {
    this.selection.select(targetFilter);
  }

  @Exclude()
  public deselect(targetFilter: Filter): void {
    this.selection.deselect(targetFilter);
  }

  @Exclude()
  public toggle(targetFilter: Filter): void {
    this.selection.toggle(targetFilter);
  }

  @Exclude()
  public isSelected(targetFilter: Filter): boolean {
    return this.selection.isSelected(targetFilter);
  }

  @Exclude()
  public selectionChange() {
    return this.selection.changed;
  }

  @Exclude()
  public clear(): void {
    this.selection.clear();
  }

  public override toObject() {
    return {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      [this.key]: this.selected.map((item) => (item.value as any).toString()),
    };
  }

  /**
   * Sort the list of FilterGroups.
   *
   * 1. Sort by position ascending.
   * 2. Groups with a position take precedence over groups without
   * 3. If no position is set sort alphabetically on displayValue or name if
   *    display value is not set.
   *
   */
  protected sortFilters(valueA: Filter, valueB: Filter): number {
    if (
      typeof valueA.position === 'number' &&
      typeof valueB.position === 'number'
    ) {
      return valueA.position - valueB.position;
    }

    if (
      typeof valueA.position === 'number' ||
      typeof valueB.position === 'number'
    ) {
      return typeof valueA.position === 'number' ? -1 : 1;
    }

    return (valueA?.displayText || '').localeCompare(valueB.displayText);
  }

  protected setFilters(filtersInput: Filter[] | Observable<Filter[]>): void {
    if (Array.isArray(filtersInput)) {
      for (const item of filtersInput) {
        item.filterGroup = this;
      }
      this.availableFilters$.next(filtersInput.sort(this.sortFilters));
      return;
    }

    filtersInput
      .pipe(
        tap((filtersList) =>
          filtersList.forEach((filter) => (filter.filterGroup = this)),
        ),
        map((filterList) => filterList.sort(this.sortFilters)),
      )
      .subscribe((filterList) => {
        for (const item of filterList) {
          item.filterGroup = this;
        }
        this.availableFilters$.next(filterList);
      });
    this.selection.clear();
  }
}
