import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { Observable } from 'rxjs';
import { usersActions } from './+state/users.actions';
import { UsersSelectors } from './+state/users.selectors';

@Injectable()
export class UsersStoreService {
  private readonly store = inject(Store);
  public loading$: Observable<boolean> = this.store.pipe(select(UsersSelectors.selectUserLoading));

  public loaded$: Observable<boolean> = this.store.pipe(select(UsersSelectors.selectUserLoaded));

  public user$ = this.store.pipe(select(UsersSelectors.activeUser)).pipe(isDefined());

  public isSchedulingTeam = this.store.selectSignal(UsersSelectors.isSchedulingTeam);

  public isLocationManager = this.store.selectSignal(UsersSelectors.isLocationManager);

  public isAdmin = this.store.selectSignal(UsersSelectors.isAdmin);

  public init(): void {
    this.store.dispatch(usersActions.initUser());
  }
}
