import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CacheService } from '@techniek-team/services';
import { consoleInDev } from '@techniek-team/common';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { TtFetchConfig } from '../tt-fetch-config';
import { PreFetchMethodsObject } from './pre-fetch-service.interface';

/**
 * This service is used to set up the predefined caches.
 * @deprecated use FetchStorageInterface
 */
@Injectable({
  providedIn: 'root',
})
export class PreFetchService {

  public finishedPrefetch: Subject<void> = new Subject<void>();

  constructor(
    private cacheService: CacheService,
    private httpClient: HttpClient,
    private config: TtFetchConfig,
    private injector: Injector,
  ) {
  }

  /**
   * Preloads the given endpoints.
   */
  public initPreLoad(): void {
    if (this.config.preFetchService) {
      consoleInDev(this.config.debug ?? false).log('start preLoad');
      consoleInDev(this.config.debug ?? false).time('finished preLoad');
      this.preFetch().then(() => {
        consoleInDev(this.config.debug ?? false).timeEnd('finished preLoad');
      });
    }

  }

  /**
   * Create an array of promises from each prefetch observable given.
   */
  private async preFetch(): Promise<void> {
    let preFetches: PreFetchMethodsObject | Promise<PreFetchMethodsObject> = this.injector
      .get(this.config.preFetchService)
      .preFetch();

    if (preFetches instanceof Promise) {
      preFetches = await preFetches;
    }

    const promises: Promise<void>[] = [];
    for (let [identifier, endpoint] of Object.entries(preFetches)) {
      const preFetch: Promise<void> = firstEmitFrom(endpoint.pipe(first()))
        .then((result) => consoleInDev(this.config.debug ?? false).log(`${identifier} has been cached`, result));
      promises.push(preFetch);
    }
    return Promise.all(promises).then(() => {
      this.finishedPrefetch.next();
      this.finishedPrefetch.complete();
    });
  }
}
