import { createActionGroup, props } from '@ngrx/store';
import { TransitionContract } from '../../contracts/transition.contract';

export const assignmentTransitionsActions = createActionGroup({
  source: '[Assignments/Transitions]',
  events: {
    loadTransitionsSuccess: props<{ transitions: TransitionContract[] }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadTransitionsFailure: props<{ error: any }>(),
  },
});
