<tt-modal (cancel)="dismiss()">
  <tt-modal-title>Uitbetalingsregel toevoegen</tt-modal-title>
  <ng-container
    *ngrxLet="assignmentsStoreService.activeCompensationAllowedArticleCodes$ as articleCodes"
  >
    @if (articleCodes.length > 0) {
      <form class="extraCompensationLine" [formGroup]="compensationLineForm">
        <ion-select
          formControlName="article"
          label="Artikelcode"
          labelPlacement="stacked"
          interface="popover"
          fill="outline"
          placeholder="Selecteer Artikelcode"
        >
          @for (code of articleCodes; track code) {
            <ion-select-option [value]="code">
              {{ ArticleCodeEnumDisplayValues[code] }}
            </ion-select-option>
          }
        </ion-select>
        <ion-input
          fill="outline"
          label="Omschrijving"
          labelPlacement="stacked"
          formControlName="description"
          placeholder="Omschrijving"
        ></ion-input>
        <ion-select
          formControlName="assignmentHasSlot"
          label="Shift-datum & tijd"
          labelPlacement="stacked"
          fill="outline"
          interface="popover"
          placeholder="Selecteer Shift-datum & tijd"
        >
          @if (selectableAssignmentHasSlots$ | async; as assignmentHasSlots) {
            @if (assignmentHasSlots.length > 0) {
              @for (assignmentHasSlot of assignmentHasSlots; track assignmentHasSlot) {
                <ion-select-option [value]="assignmentHasSlot">
                  {{ assignmentHasSlot.slot.timePeriod.start | date: 'dd-MM-yyyy HH:mm' }}
                  - {{ assignmentHasSlot.slot.timePeriod.end | date: 'HH:mm' }}
                </ion-select-option>
              }
            } @else {
              <ion-select-option [disabled]="true">
                Geen toegestane shifts gevonden.
              </ion-select-option>
            }
          }
        </ion-select>
        <div class="number-inputs">
          <tt-number-input
            formControlName="quantity"
            labelPlacement="stacked"
            fill="outline"
            label="Aantal"
            [min]="0"
          ></tt-number-input>
          <tt-number-input
            label="Bedrag"
            fill="outline"
            labelPlacement="stacked"
            formControlName="amount"
            [allowDecimals]="true"
            [min]="0"
          ></tt-number-input>
        </div>
      </form>
    }
    <ion-button
      slot="action"
      [disabled]="
        (assignmentsStoreService.savingCompensationLine$ | async) || compensationLineForm.invalid
      "
      (click)="addLine()"
    >
      Toevoegen
      @if (assignmentsStoreService.savingCompensationLine$ | async) {
        <ion-spinner></ion-spinner>
      }
    </ion-button>
  </ng-container>
</tt-modal>
