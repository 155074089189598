import { createFeatureSelector, createSelector } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { Filter, FilterGroup } from '@techniek-team/search';
import { toDictionary } from '@techniek-team/tt-ngrx';
import { BusinessEntityContract } from '../contracts/business-entity.contract';
import { BusinessEntity } from '../models/business-entity.model';
import {
  BUSINESS_ENTITIES_FEATURE_KEY,
  businessEntitiesAdapter,
  BusinessEntitiesState,
} from './business-entities.reducer';

const { selectAll, selectEntities } = businessEntitiesAdapter.getSelectors();

export class BusinessEntitiesSelectors {
  public static readonly businessEntitiesState = createFeatureSelector<BusinessEntitiesState>(
    BUSINESS_ENTITIES_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    BusinessEntitiesSelectors.businessEntitiesState,
    (state: BusinessEntitiesState) => state.loading,
  );

  public static readonly loaded = createSelector(
    BusinessEntitiesSelectors.businessEntitiesState,
    (state: BusinessEntitiesState) => state.loaded,
  );

  public static readonly cacheTimestamp = createSelector(
    BusinessEntitiesSelectors.businessEntitiesState,
    (state: BusinessEntitiesState) => state.cacheTimeStamp,
  );

  public static readonly error = createSelector(
    BusinessEntitiesSelectors.businessEntitiesState,
    (state: BusinessEntitiesState) => state.error,
  );

  public static readonly businessEntities = createSelector(
    BusinessEntitiesSelectors.businessEntitiesState,
    (state: BusinessEntitiesState) => selectAll(state),
  );

  public static readonly businessEntityEntities = createSelector(
    BusinessEntitiesSelectors.businessEntitiesState,
    (state: BusinessEntitiesState) => selectEntities(state),
  );
  public static readonly denormalizedBusinessEntityEntities = createSelector(
    BusinessEntitiesSelectors.businessEntitiesState,
    (state) =>
      toDictionary(denormalize(BusinessEntity, Object.values(state.entities)), (item) =>
        item.getId(),
      ),
  );

  public static readonly businessEntitiesFilterGroupKey = 'businessService.businessEntity';

  public static readonly businessEntitiesFilterGroup = createSelector(
    BusinessEntitiesSelectors.businessEntities,
    (businessEntities) => {
      return new FilterGroup(
        BusinessEntitiesSelectors.businessEntitiesFilterGroupKey,
        (businessEntities ?? []).map(
          (item) =>
            new Filter(item['@id'] as string, {
              label: item.name,
              additionalData: item,
            }),
        ),
        { multiple: true, itemLabel: 'Bedrijf', label: 'Bedrijven' },
      );
    },
  );

  public static readonly searchQueryMapper = createSelector(
    BusinessEntitiesSelectors.businessEntityEntities,
    (dict) => {
      return {
        'businessService.businessEntity': {
          entities: dict,
          getDisplayText: (item: BusinessEntityContract) => item.name,
        },
      };
    },
  );
}
