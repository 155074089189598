/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { environment } from '@scheduler-frontend/environments';
import { addSeconds, isBefore } from 'date-fns';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { filter, from, map, Observable, of, retry, switchMap, tap, throwError } from 'rxjs';
import { MercureBaseEvent } from './events/mercure-base.event';
import { MercureCreatedEvent } from './events/mercure-created.event';
import { MercureDeletedEvent } from './events/mercure-deleted.event';
import { MercureErrorEvent } from './events/mercure-error.event';
import { MercureOpenEvent } from './events/mercure-open.event';
import { MercureUpdatedEvent } from './events/mercure-updated.event';
import { MercureEvent } from './events/mercure.event';

const XSSI_PREFIX = /^\)]}',?\n/;

export enum MercureResponseType {
  JSON = 'json',
  TEXT = 'text',
  BLOB = 'blob',
}
export interface MercureClientOptions {
  withCredentials: boolean;
  responseType: MercureResponseType;
  observe?: 'events' | 'body' | 'response';
  contentType?: string;
  tokenEndpoint: boolean;
  tokenEndpointUrl: string;
  debug: boolean;
  mercureEventTypes: Record<string, typeof MercureEvent<any>>;
}

@Injectable({
  providedIn: 'root',
})
export class MercureClient {
  private readonly httpClient = inject(HttpClient);

  private readonly storage = inject(Storage);

  private readonly defaultMercureEventType: Record<string, typeof MercureEvent<any>> = {
    message: MercureEvent,
    create: MercureCreatedEvent,
    created: MercureCreatedEvent,
    update: MercureUpdatedEvent,
    updated: MercureUpdatedEvent,
    delete: MercureDeletedEvent,
    deleted: MercureDeletedEvent,
  };

  private readonly defaults: MercureClientOptions = {
    withCredentials: false,
    tokenEndpoint: true,
    tokenEndpointUrl: `${environment.mercure.tokenUrl}`,
    responseType: MercureResponseType.JSON,
    observe: 'body',
    debug: false,
    mercureEventTypes: {},
  };

  public request<R, K extends MercureEvent<R> = MercureEvent<R>>(
    url: string,
    topics: string[],
    options: {
      withCredentials?: boolean;
      responseType?: MercureResponseType;
      observe: 'response';
      contentType?: string;
      tokenEndpoint?: boolean;
      tokenEndpointUrl?: string;
      debug: boolean;
      mercureEventTypes?: Record<string, typeof MercureEvent<any>>;
    },
  ): Observable<K>;

  public request<R>(
    url: string,
    topics: string[],
    options: {
      withCredentials?: boolean;
      responseType?: MercureResponseType;
      observe: 'events';
      contentType?: string;
      tokenEndpoint?: boolean;
      tokenEndpointUrl?: string;
      debug?: boolean;
      mercureEventTypes?: Record<string, typeof MercureEvent<any>>;
    },
  ): Observable<MercureBaseEvent>;

  public request<R>(
    url: string,
    topics: string[],
    options: {
      withCredentials?: boolean;
      responseType?: MercureResponseType;
      observe?: 'body';
      contentType?: string;
      tokenEndpoint?: boolean;
      tokenEndpointUrl?: string;
      debug?: boolean;
      mercureEventTypes?: Record<string, typeof MercureEvent<any>>;
    },
  ): Observable<R>;

  //eslint-disable-next-line max-lines-per-function
  public request<R = any>(
    url: string,
    topics: string[],
    options?: {
      withCredentials?: boolean;
      responseType?: MercureResponseType;
      observe?: 'events' | 'body' | 'response';
      contentType?: string;
      tokenEndpoint?: boolean;
      tokenEndpointUrl?: string;
      debug?: boolean;
      mercureEventTypes?: Record<string, typeof MercureEvent<any>>;
    },
  ): Observable<MercureEvent<R>> | Observable<MercureBaseEvent> | Observable<R> {
    const optionsList = { ...this.defaults, ...(options ?? {}) };
    let events$ = this.buildEventSourceObservable(url, topics, optionsList).pipe(
      tap((event) => {
        if (optionsList.debug) {
          if (event instanceof MercureEvent) {
            //eslint-disable-next-line no-console
            console.log(`[Mercure][${event.eventType}][${topics.join(', ')}]`, event);
            return;
          }
          //eslint-disable-next-line no-console
          console.log(`[Mercure][${event.eventType}]`, event);
        }
      }),
    );
    if (optionsList.observe === 'events') {
      return events$;
    }

    // The requested stream contains either the full response or the body. In either
    // case, the first step is to filter the event stream to extract a stream of
    // responses(s).
    const res$: Observable<MercureEvent<R>> = events$.pipe(
      filter((event: MercureBaseEvent) => event instanceof MercureEvent),
    ) as Observable<MercureEvent<R>>;

    // Decide which stream to return.
    switch (optionsList.observe || 'body') {
      case 'body':
        // The requested stream is the body. Map the response stream to the response
        // body. This could be done more simply, but a misbehaving interceptor might
        // transform the response body into a different format and ignore the requested
        // responseType. Guard against this by validating that the response is of the
        // requested type.
        switch (optionsList.responseType) {
          case MercureResponseType.BLOB:
            return res$.pipe(
              map((res: MercureEvent<any>) => {
                // Validate that the body is a Blob.
                if (res.data !== null && !(res.data instanceof Blob)) {
                  throw new Error('Response is not a Blob.');
                }
                return res.data;
              }),
            );
          case MercureResponseType.TEXT:
            return res$.pipe(
              map((res: MercureEvent<any>) => {
                // Validate that the body is a string.
                if (res.data !== null && typeof res.data !== 'string') {
                  throw new Error('Response is not a string.');
                }
                return res.data;
              }),
            );
          case MercureResponseType.JSON:
          default:
            // No validation needed for JSON responses, as they can be of any type.
            return res$.pipe(map((res: MercureEvent<any>) => res.data));
        }
      case 'response':
        // The response stream was requested directly, so return it.
        return res$;
      default:
        // Guard against new future observe types being added.
        throw new Error(`Unreachable: unhandled observe type ${optionsList.observe}}`);
    }
  }

  private createUrl(url: string, topics: string[]) {
    const urlObject = new URL(url);
    for (let topic of topics) {
      urlObject.searchParams.append('topic', topic);
    }
    return urlObject;
  }

  private buildEventSourceObservable(url: string, topics: string[], options: MercureClientOptions) {
    if (!options.tokenEndpoint) {
      return this.createEventSource(this.createUrl(url, topics), options);
    }
    const getToken$ = this.getToken(options);

    return from(this.storage.get('mercure_token')).pipe(
      switchMap((token?: { token: string; validUntil: Date }) => {
        if (!this.isMercureTokenValid(token)) {
          return getToken$;
        }
        return of(token);
      }),
      switchMap((token) =>
        this.createEventSource(this.createUrl(url, topics), options, token.token),
      ),
      retry({
        count: 2,
        delay: (error) => this.retryConnection(error, url, topics, options),
      }),
    );
  }

  private retryConnection(
    error: any,
    url: string,
    topics: string[],
    options: MercureClientOptions,
  ) {
    if (typeof error === 'object' && error?.['error']?.['status'] === 401) {
      return from(this.storage.remove('mercure_token')).pipe(
        switchMap(() =>
          this.getToken(options).pipe(
            switchMap((token) =>
              this.createEventSource(this.createUrl(url, topics), options, token.token),
            ),
          ),
        ),
      );
    }
    // TODO check what happen on network changed errors
    console.error(error);
    return throwError(() => error);
  }

  private isMercureTokenValid(token?: {
    token: string;
    validUntil: Date;
  }): token is { token: string; validUntil: Date } {
    return !!(token?.validUntil && isBefore(new Date(), token.validUntil));
  }

  //eslint-disable-next-line max-lines-per-function
  private createEventSource(url: URL, optionsList: MercureClientOptions, token?: string) {
    //eslint-disable-next-line max-lines-per-function
    return new Observable<MercureBaseEvent>((observer) => {
      let eventSource: EventSource;
      if (optionsList?.tokenEndpoint) {
        eventSource = new EventSourcePolyfill(url.toString(), {
          //eslint-disable-next-line @typescript-eslint/naming-convention
          headers: { Authorization: `Bearer ${token}` ?? '' },
        });
      } else {
        eventSource = new EventSource(url, {
          withCredentials: optionsList.withCredentials,
        });
      }

      eventSource.addEventListener('open', () =>
        observer.next(new MercureOpenEvent({ eventType: 'open' })),
      );
      eventSource.addEventListener('error', (errorEvent) =>
        observer.error(new MercureErrorEvent({ error: errorEvent, eventType: 'error' })),
      );

      const eventTypes = Object.entries(optionsList.mercureEventTypes).concat(
        Object.entries(this.defaultMercureEventType),
      );
      for (let [eventType, cls] of eventTypes) {
        eventSource.addEventListener(eventType, (event) =>
          observer.next(
            //eslint-disable-next-line new-cap
            new cls({
              data: event.data ? this.parseBody(optionsList.responseType, event.data) : undefined,
              eventType: event.type,
              lastEventId: event.lastEventId,
            }),
          ),
        );
      }

      return () => eventSource.close();
    });
  }

  private getToken(options: MercureClientOptions) {
    return this.httpClient.get<{ token: string; lifetime: number }>(options.tokenEndpointUrl).pipe(
      tap((response) => {
        this.storage.set('mercure_token', {
          token: response.token,
          validUntil: addSeconds(new Date(), response.lifetime),
        });
      }),
    );
  }

  private parseBody(
    responseType: 'json' | 'text' | 'blob',
    binContent: string,
    contentType?: string,
  ): string | object | Blob | null {
    switch (responseType) {
      case 'json':
        // stripping the XSSI when present
        return binContent === ''
          ? null
          : (JSON.parse(binContent.replace(XSSI_PREFIX, '')) as Object);
      case 'text':
        return binContent === '' ? null : binContent;
      case 'blob':
        return new Blob([binContent], { type: contentType });
    }
  }
}
