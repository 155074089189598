import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ApproachAssigneeFilter,
  ApproachContract,
  ApproachDetailedContract,
} from '@scheduler-frontend/approach-contracts';
import { schedulingApproachCandidatesActions } from '@scheduler-frontend/data-access-scheduling';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { approachesActions } from '../actions/approaches.actions';

export const APPROACHES_FEATURE_KEY = 'approaches';

export interface ApproachesState extends EntityState<ApproachContract | ApproachDetailedContract> {
  loaded: boolean;
  loading: boolean;
  saving: boolean;
  loadingDetailed: boolean;
  error?: unknown;
  selectedAssigneeFilter?: ApproachAssigneeFilter;
  selectedCandidateSearch?: string;
}

export const approachesAdapter: EntityAdapter<ApproachContract | ApproachDetailedContract> =
  createEntityAdapter<ApproachContract | ApproachDetailedContract>({
    selectId: jsonLdSelectId,
  });

export const initialApproachesState: ApproachesState = approachesAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
  saving: false,
  loadingDetailed: false,
});

const reducer = createReducer(
  initialApproachesState,
  on(schedulingApproachCandidatesActions.createApproachSuccess, (state, { approaches }) =>
    approachesAdapter.setMany(approaches, {
      ...state,
    }),
  ),
  /** Mark approach as handled. */
  on(approachesActions.markApproachAsHandled, (state) => ({
    ...state,
    saving: true,
  })),
  on(approachesActions.markApproachAsHandledSuccess, (state, { approach }) => ({
    ...state,
    saving: false,
  })),
  on(approachesActions.markApproachAsHandledFailure, (state, { error }) => ({
    ...state,
    saving: false,
    error: error,
  })),
  /** Mark approach as expired. */
  on(approachesActions.markApproachAsExpired, (state) => ({
    ...state,
    saving: true,
  })),
  on(approachesActions.markApproachAsExpiredSuccess, (state) => ({
    ...state,
    saving: false,
  })),
  on(approachesActions.markApproachAsExpiredFailure, (state, { error }) => ({
    ...state,
    saving: false,
    error: error,
  })),
  /** Load approach */
  on(approachesActions.loadApproach, (state) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(approachesActions.loadApproachSuccess, (state, { approach }) => {
    return approachesAdapter.setOne(approach, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(approachesActions.loadApproachFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(approachesActions.setActiveAssigneeFilter, (state, { filter }) => ({
    ...state,
    selectedAssigneeFilter: filter,
  })),
  on(approachesActions.setActiveCandidateSearch, (state, { value }) => ({
    ...state,
    selectedCandidateSearch: value,
  })),
);

export function approachesReducer(state: ApproachesState | undefined, action: Action) {
  return reducer(state, action);
}
