import { GetReleasesResponse } from './release.response';

export const GET_RELEASES: GetReleasesResponse[] = [
  {
    name: 'Release v3.2.0',
    tagName: 'v3.2.0',
    releasedAt: '2023-08-23T10:00:00.000Z',
    descriptionHtml: `
<h2>Nieuwe overzichten - wachtend op shift</h2>
<p>
  We hebben 2 nieuwe overzichten geïntroduceerd om een beter beeld te geven
  over welke leerlingen op dit moment in afwachting zijn van een shift:
  <ul>
    <li>Het eerste overzicht toont leerlingen die nog wachten op hun allereerste shift.</li>
    <li>Het tweede overzicht geeft een overzicht van leerlingen die in afwachting zijn van een vervolg-shift.</li>
  </ul>
  Deze overzichten kun je vinden via het hoofdmenu aan de zijkant. Op het dashboard is daarnaast een nieuw
  blokje toegevoegd met het aantal leerlingen dat wacht op een eerste shift.
</p>
<br>
<h2>Opmerking toevoegen bij begeleider</h2>
<p>
  We hebben nu de optie toegevoegd om opmerkingen toe te voegen bij begeleiders.
  Deze handige feature is beschikbaar via het begeleider-overzicht en maakt het
  opslaan van relevante informatie over begeleiders eenvoudiger.
</p>
<p>
  Belangrijk detail: opmerkingen die via de roostertool worden toegevoegd,
  kunnen niet achteraf via de roostertool worden bijgewerkt of verwijderd. Dit
  kan alleen via het personeelsadministratie-systeem (Perza).
</p>
<br>
<h2>Verbeterde weergave beschikbaarheid begeleider</h2>
<p>
  Het beschikbaarheidsoverzicht van een begeleider is geüpdatet. Het nieuwe ontwerp is niet
  alleen overzichtelijker, maar het biedt ook een verbeterde gebruikerservaring. Nu kun je
  gemakkelijk wisselen tussen verschillende maanden. Bovendien hebben we een handige dagweergave toegevoegd,
  zodat je ook gedetailleerde beschikbaarheidsinformatie per dag kunt zien.
</p>
    `,
    images: [
      { name: 'Wachtend op shift', url: './assets/img/awaiting-slots.png' },
      {
        name: 'Opmerkingen begeleider',
        url: './assets/img/candidate-remarks.png',
      },
      {
        name: 'Beschikbaarheid begeleider',
        url: './assets/img/candidate-availability.png',
      },
    ],
  },
  {
    name: 'Release v3.0.0',
    tagName: 'v3.0.0',
    releasedAt: '2023-02-13T14:00:00.000Z',
    //eslint-disable-next-line
    descriptionHtml: `
<h2>Nieuwe layout</h2>
<p>
  De afgelopen maanden heeft het IT-team hard gewerkt om de roostertool een complete metamorfose te geven.
  Hij ziet er niet alleen een stuk mooier uit, maar werkt ook een stuk sneller en efficiënter.
  Een groot deel van de gebruikers heeft deze nieuwe versie al uitgeprobeerd in de beta-omgeving.
  Nu is het tijd om de 'oude' roostertool vaarwel te zeggen!
</p>
<br>
<h3>Nieuwe functionaliteiten</h3>
<ul>
  <li>
    Wanneer je een begeleider uitroostert van een opdracht (of een shift verwijdert uit een opdracht),
    word je gevraagd om hiervoor een reden op te geven.
  </li>
  <li>Overnachtingsaddressen van de begeleiders zijn nu zichtbaar in het kandidaatscherm.</li>
  <li> In het opdrachtdialoog kun je in de tab 'historie' zien wanneer en door wie een
  opdracht is ingepland, bevestigd, goedgekeurd etc. </li>
  <li> 'Oude' locaties zijn in principe niet meer zichtbaar in de roostertool. Alleen leden
   van het roosterteam kunnen oude locaties nog inzien, wanneer zij hier specifiek op zoeken op de zoekpagina.
   </li>
 </ul>
 <p><strong>PS:</strong> De beta-omgeving blijft bestaan. Hierin zullen nieuwe functionaliteiten als eerste beschikbaar
 komen.
 Dus wil je graag als eerste genieten van de nieuwste features én het IT team helpen met jouw feedback, blijf dan
 vooral gebruikmaken van de beta versie!</p>
`,
    images: [
      {
        name: 'New UI',
        url: './assets/img/new-shifts-v2.png',
      },
    ],
  },
  {
    name: 'Release v3.0.0-RC.4',
    tagName: 'v3.0.0-RC.4',
    releasedAt: '2023-02-07T13:00:00.000Z',
    //eslint-disable-next-line
    descriptionHtml: `
<h2>Nieuw ontwerp voor shifts</h2>
<p>
    Op verzoek is er nog een keer gekeken naar de weergave van shifts.
    <br />Dit hebben we natuurlijk meteen voor jullie opgepakt!
</p>
<br />
<h3>Opgeloste bugs</h3>
<ul>
  <li>
    Nadat je voor de tweede keer iemand had ingepland, bleef de lijst met kandidaten
    <br />leeg. Dit is nu opgelost.
  </li>
  <li>Soms bleef de applicatie hangen als er meerdere shifts geselecteerd waren.<br /> Dit is nu opgelost.</li>
  <li>Shifts waar al iemand op ingepland is, kun je nu niet meer aanklikken bij het <br /> selecteren van shifts.</li>
  <li>Als je van week wisselde, waren de shifts allemaal rood. Nu niet meer.</li>
</ul>
`,
    images: [
      {
        name: 'New UI',
        url: './assets/img/new-shifts-v2.png',
      },
    ],
  },
  {
    name: 'Release v3.0.0-RC.3',
    tagName: 'v3.0.0-RC.3',
    releasedAt: '2023-01-18T15:00:00.000Z',
    //eslint-disable-next-line
    descriptionHtml: `
    <h2>Bugfixes</h2>
<p>Een aantal van de bugs waar mensen tegen aanliepen zijn gefixt met deze release.</p>
<br>
<h3>Opgeloste bugs</h3>
<ul>
    <li>Inplannen van meerder shifts tegelijk</li>
    <li>Vestigingsmanagers kunnen nu ook het WhatsApp-icoontje gebruiken</li>
    <li>Opdrachtpreview klopt weer met wat geselecteerd is</li>
    <li>'Sorteer volgorde' in de week-weergave is verbeterd</li>
    <li>Kandidatenlijst verspringt soms</li>
    <li>Flikkerende mouseover bij shifts</li>
</ul>
`,
    images: [
      {
        name: 'New UI',
        url: './assets/img/new-ui.png',
      },
    ],
  },
  {
    name: 'Release v3.0.0-RC.2',
    tagName: 'v3.0.0-RC.2',
    releasedAt: '2022-12-20T16:00:00.000Z',
    //eslint-disable-next-line
    descriptionHtml: `
    <h2>Nieuw layout shifts</h2>
<p>Roostertool heeft een nieuw ontwerp voor de shifts. Ook is de weergave van de shifts
   in<br> het dag en week overzicht verbetert zodat deze sneller werkt.</p>
<br>
<h3>Andere verbeteringen</h3>
<ul>
    <li>Whatsapp knop om direct contact op te nemen via whatsapp</li>
    <li>Alleen actieve locaties worden nu weergegeven in de zoekbalk en op de agenda<br> pagina</li>
</ul>
<h3>Opgeloste bugs</h3>
<ul>
    <li>Automatisch reiskosten vergoeding aanzetten werkt weer</li>
    <li>Zelf inplanbaar aanzetten werkt weer</li>
    <li>Verspringen van datum als je iemand inplant via week overzicht</li>
    <li>Opdracht dialog uren goedkeuren</li>
    <li>Opdracht dialog status wijzigen</li>
    <li>Rooster Input bij aanmaken shift is te klein</li>
    <li>Datumpikker bij zoekbalk begint de week met maandag</li>
    <li>Op de zoekpagina mist het totaal overzicht</li>
    <li>Zoekpagina refreshed na inplannen van een shift</li>
</ul>
`,
    images: [
      {
        name: 'New Shift Design',
        url: './assets/img/new-shifts.jpg',
      },
    ],
  },
  {
    name: 'Release v3.0.0-RC.1',
    tagName: 'v3.0.0-RC.1',
    releasedAt: '2022-12-09T11:00:00.000Z',
    //eslint-disable-next-line
    descriptionHtml: `
    <h2>New UI!</h2>
    <p>
        Roostertool heeft een nieuw design. Het design komt nu meer overeen met de nieuwe
        <br>
        Lyceo huisstijl en de nieuwe architectuur zorgt ook voor een snellere applicatie.
    </p>
    <br>
    <h3>Andere verbeteringen</h3>
    <ul>
        <li>Opdracht overzicht heeft een historie tab waar je de status wijzigingen van een shift <br>kan zien.</li>
        <li>Nieuwe status voorlopig bevestigd</li>
    </ul>`,
    images: [
      {
        name: 'New UI',
        url: './assets/img/new-ui.png',
      },
    ],
  },
];
