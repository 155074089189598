import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RejectApi {
  protected readonly httpClient = inject(HttpClient);

  public postCandidateRejection(request: {
    candidate: string;
    declineReason: string;
    slots: string[];
  }): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/candidates/${request.candidate}/rejection`;

    return this.httpClient.post<void>(url, {
      declineReason: request.declineReason,
      slots: request.slots,
    });
  }
}
