import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { TsRange } from '@techniek-team/class-transformer';
import { isDefined } from '@techniek-team/rxjs';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import { mergeMap, of, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { candidateAvailabilitiesActions } from '../actions/candidate-availabilities.actions';
import { AvailabilityApi } from '../api/availability/availability.api';
import { CandidateAvailabilitiesSelectors } from '../selectors/candidate-availabilities.selectors';
import { CandidatesSelectors } from '../selectors/candidates.selectors';

@Injectable()
export class CandidateAvailabilitiesEffects {
  private readonly availabilityApi = inject(AvailabilityApi);

  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public setCandidateAvailabilityEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAvailabilitiesActions.setCandidateAvailability),
      mergeMap((action) => {
        return this.availabilityApi
          .postCandidateAvailability(
            action.timePeriod,
            action.candidateId,
            action.availabilityValue,
            action.remarks,
          )
          .pipe(
            map((response) =>
              candidateAvailabilitiesActions.setCandidateAvailabilitySuccess({
                range: action.timePeriod,
              }),
            ),
            catchError((error) =>
              of(
                candidateAvailabilitiesActions.setCandidateAvailabilityFailure({
                  error: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  public refreshAvailabilityOnSetAvailability = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAvailabilitiesActions.setCandidateAvailabilitySuccess),
      map((action) =>
        candidateAvailabilitiesActions.refreshAvailabilities({ range: action.range }),
      ),
    ),
  );

  public setCandidateAvailabilityFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidateAvailabilitiesActions.setCandidateAvailabilityFailure, {
          message: 'Het opslaan van de langdurige onbeschikbaarheid is mislukt.',
        }),
      ),
    { dispatch: false },
  );

  public setCandidateAvailabilitySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(candidateAvailabilitiesActions.setCandidateAvailabilitySuccess, {
          message: 'Langdurige onbeschikbaarheid opgeslagen.',
        }),
      ),
    { dispatch: false },
  );

  public removeAvailabilityEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAvailabilitiesActions.removeAvailability),
      mergeMap((action) => {
        return this.availabilityApi.deleteAvailability(action.id).pipe(
          map((response) => candidateAvailabilitiesActions.removeAvailabilitySuccess()),
          catchError((error) =>
            of(
              candidateAvailabilitiesActions.removeAvailabilityFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public removeAvailabilityFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidateAvailabilitiesActions.removeAvailabilityFailure, {
          message: 'Beschikbaarheid verwijderen is mislukt.',
        }),
      ),
    { dispatch: false },
  );

  public removeAvailabilitySuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(candidateAvailabilitiesActions.removeAvailabilitySuccess, {
          message: 'Beschikbaarheid is verwijderd.',
        }),
      ),
    { dispatch: false },
  );

  public createInitEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAvailabilitiesActions.initAvailabilities),
      concatLatestFrom(() =>
        this.store.select(CandidatesSelectors.activeCandidate).pipe(isDefined()),
      ),
      mergeMap(([action, candidate]) => {
        return this.availabilityApi.getAvailabilities(jsonLdSelectId(candidate), action.range).pipe(
          map((response) =>
            candidateAvailabilitiesActions.loadAvailabilitiesSuccess({
              availabilities: response['hydra:member'],
            }),
          ),
          catchError((error) =>
            of(
              candidateAvailabilitiesActions.loadAvailabilitiesFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  //eslint-disable-next-line max-lines-per-function
  public refreshAvailabilitiesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAvailabilitiesActions.refreshAvailabilities),
      // prettier-ignore
      concatLatestFrom(() => this.store.select(CandidatesSelectors.activeCandidate).pipe(isDefined())),
      mergeMap(([action, candidate]) => {
        return this.availabilityApi
          .getAvailabilities(jsonLdSelectId(candidate), TsRange.fromObject(action.range))
          .pipe(
            // prettier-ignore
            withLatestFrom(this.store.pipe(select(CandidateAvailabilitiesSelectors.selectWithinRange(TsRange.fromObject(action.range))))),
            map(([refreshedEvents, currentEvents]) => ({
              toUpsert: refreshedEvents['hydra:member'],
              // prettier-ignore
              toRemove: currentEvents.filter((item) =>
              !refreshedEvents['hydra:member'].find((newItem) => {
                return newItem['@id'] === item?.['@id'];
              }),
            ).map((item) => item?.['@id'] ?? ''),
            })),
            map(({ toRemove, toUpsert }) => {
              return candidateAvailabilitiesActions.refreshAvailabilitiesSuccess({
                availabilities: toUpsert,
              });
            }),
            catchError((error) =>
              of(
                candidateAvailabilitiesActions.loadAvailabilitiesFailure({
                  error: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  public appendAvailabilitiesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAvailabilitiesActions.appendAvailabilities),
      concatLatestFrom(() =>
        this.store.select(CandidatesSelectors.activeCandidate).pipe(isDefined()),
      ),
      mergeMap(([action, candidate]) => {
        return this.availabilityApi.getAvailabilities(jsonLdSelectId(candidate), action.range).pipe(
          map((response) =>
            candidateAvailabilitiesActions.appendAvailabilitiesSuccess({
              availabilities: response['hydra:member'],
            }),
          ),
          catchError((error) =>
            of(
              candidateAvailabilitiesActions.loadAvailabilitiesFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );
}
