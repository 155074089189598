import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { DeclineReasonsEffects } from './+state/decline-reasons.effects';
import {
  DECLINE_REASONS_FEATURE_KEY,
  declineReasonsReducer,
} from './+state/decline-reasons.reducer';
import { DeclineReasonsStoreService } from './decline-reasons-store.service';

export function provideDeclineReasonsStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: DECLINE_REASONS_FEATURE_KEY, reducer: declineReasonsReducer }),
    provideEffects(DeclineReasonsEffects),
    DeclineReasonsStoreService,
  ];
}
