<div class="payment-checkbox">
  <ion-toggle
    (ionChange)="updateAssignmentPayout('mustPerformCompensation', $event)"
    [checked]="assignmentsStoreService.activeAssignmentMustPerformCompensation()"
    labelPlacement="start"
    color="secondary"
  >
    Uitbetalen
  </ion-toggle>

  <ion-toggle
    labelPlacement="start"
    color="secondary"
    (ionChange)="updateAssignmentPayout('automaticTravelCompensation', $event)"
    [checked]="assignmentsStoreService.activeAssignmentAutomaticTravelCompensation()"
    [disabled]="!assignmentsStoreService.activeAssignmentMustPerformCompensation()"
  >
    Automatische reiskostenvergoeding
  </ion-toggle>
</div>
