/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvironmentProviders, InjectionToken, Provider } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthApi } from './api/auth/auth.api';
import { AuthConfig } from './auth.config';
import { InAppBrowserWebService } from './shared/in-app-browser/in-app-browser-web.service';
import { InAppBrowserService } from './shared/in-app-browser/in-app-browser.service';
import { OAuthService } from './shared/oauth/oauth.service';
import { UserInterface } from './shared/user/user.interface';
import { UserService } from './shared/user/user.service';

export type CheckUserRolesFnWithDeps<T extends any> = (
  ...deps: T[]
) => (currentUser: UserInterface, route: ActivatedRouteSnapshot) => void | Promise<void>;
export type CheckUserRolesFn = (
  currentUser: UserInterface,
  route: ActivatedRouteSnapshot,
) => void | Promise<void>;
export const CHECK_USER_ROLES = new InjectionToken<CheckUserRolesFn>('auth.checkUserRoles');

export function withUserRoles<T extends Provider, K extends any>(
  fn: CheckUserRolesFn,
): (Provider | EnvironmentProviders)[];
export function withUserRoles<T extends Provider, K extends any>(
  fn: CheckUserRolesFnWithDeps<K>,
  ...deps: T[]
): (Provider | EnvironmentProviders)[];
export function withUserRoles<T extends Provider, K extends any>(
  fn: CheckUserRolesFn | CheckUserRolesFnWithDeps<K>,
  ...deps: T[]
): (Provider | EnvironmentProviders)[] {
  if (deps) {
    return [
      {
        provide: CHECK_USER_ROLES,
        useFactory: (...dependencies: K[]) => (fn as CheckUserRolesFnWithDeps<K>)(...dependencies),
        deps: deps,
      },
    ];
  }
  return [
    {
      provide: CHECK_USER_ROLES,
      useValue: fn,
    },
  ];
}

export function provideTtAuth<T extends UserInterface>(
  options: AuthConfig<T>,
  ...features: (Provider | EnvironmentProviders)[][]
) {
  options = { ...new AuthConfig(), ...options };
  return [
    { provide: AuthConfig, useValue: options },
    InAppBrowserWebService,
    {
      provide: InAppBrowserService,
      useExisting: InAppBrowserWebService,
    },
    AuthApi,
    UserService,
    OAuthService,
    ...features,
  ];
}
