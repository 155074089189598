<tt-modal (cancel)="modalController.dismiss()">
  <tt-modal-title slot="start" color="tertiary">Shift aanmaken</tt-modal-title>

  <div slot="start" class="stepper">
    <ion-item
      lines="none"
      [button]="true"
      (click)="currentSegment.set(CreateSlotSegments.SHIFT_TEMPLATE)"
    >
      <div slot="start">1</div>
      <ion-label>Shift-template</ion-label>
    </ion-item>
    <div class="line"></div>
    <ion-item
      lines="none"
      [button]="true"
      [disabled]="slotTemplateForm.invalid"
      (click)="currentSegment.set(CreateSlotSegments.SHIFT_FINE_TUNE)"
    >
      <div slot="start">2</div>
      <ion-label>Shift(s) bijwerken</ion-label>
    </ion-item>
  </div>

  <form
    [class.hidden]="currentSegment() !== CreateSlotSegments.SHIFT_TEMPLATE"
    [formGroup]="slotTemplateForm"
    class="create-form"
  >
    <tt-ion-select-search
      fill="solid"
      formControlName="productLocation"
      data-test="product-location-control-search-select"
      [showBackdrop]="false"
      [alignment]="'center'"
      [options]="locationsStoreService.allLocations()"
      [displayNameFunction]="displayByName"
      [compareWith]="compareWithId"
    >
      <ion-label position="floating"> Productlocatie </ion-label>
      <ion-note
        slot="error"
        *ngxControlError="slotTemplateForm.controls.productLocation; track: 'required'"
      >
        Locatie is <strong>verplicht</strong>.
      </ion-note>
    </tt-ion-select-search>

    <tt-ion-select-search
      fill="solid"
      formControlName="businessService"
      data-test="business-service-control-search-select"
      [showBackdrop]="false"
      [alignment]="'center'"
      [options]="createSlotsStoreService.businessServicesByLocation()"
      [displayNameFunction]="displayBusinessService"
      [compareWith]="compareWithId"
    >
      <ion-label position="floating"> Dienst </ion-label>
      <ng-container *selectSearchOption="let item; let search = search">
        <span
          [innerHTML]="
            item.name + ' (' + item.businessEntity.name + ')' | highlightSearchText: search
          "
        ></span>
      </ng-container>
    </tt-ion-select-search>

    @if (
      createSlotsStoreService.deliveryTypes()?.length &&
      slotTemplateForm.controls.businessService.value
    ) {
      <ion-select
        fill="solid"
        label="Uitlevervorm"
        data-test="delivery-type-control-select"
        labelPlacement="floating"
        formControlName="deliveryType"
        interface="popover"
        [interfaceOptions]="{ showBackDrop: false }"
        [compareWith]="compareById"
      >
        @for (deliveryType of createSlotsStoreService.deliveryTypes(); track deliveryType) {
          <ion-select-option [value]="deliveryType">
            {{ DeliveryTypeDisplayValues[deliveryType.deliveryType] }}
            <ion-text color="medium">
              ({{ createSlotsStoreService.deliveryTypesReadableValidity()?.[deliveryType['@id']] }})
            </ion-text>
          </ion-select-option>
        }
      </ion-select>
    }

    <tt-ion-select-search
      fill="solid"
      formControlName="schedule"
      [showBackdrop]="false"
      alignment="center"
      data-test="schedule-control-select"
      [options]="createSlotsStoreService.scheduleSearch$"
      [displayNameFunction]="displayByName"
      [compareWith]="compareWithId"
    >
      <ion-label position="floating"> Rooster </ion-label>
      <ng-container *selectSearchOption="let item; let search = search">
        <span [innerHTML]="item.name | highlightSearchText: search"></span>
      </ng-container>
      <ion-note
        slot="error"
        *ngxControlError="slotTemplateForm.controls.schedule; track: 'required'"
      >
        Rooster is <strong>verplicht</strong>.
      </ion-note>
    </tt-ion-select-search>

    <div class="who-row" formArrayName="who">
      @for (_ of slotTemplateForm.controls['who'].controls; track _; let index = $index) {
        <app-who-control
          [index]="index"
          [rowNumber]="index + 1"
          [formControlName]="index"
        ></app-who-control>
        <div class="buttons" [style.grid-row]="index + 1">
          <ion-button
            data-test="clone-row-button"
            color="light"
            class="who-button cloneButton"
            (click)="cloneWhoControl(index)"
          >
            <fa-icon slot="icon-only" [icon]="faCopy"></fa-icon>
          </ion-button>
          <ion-button
            data-test="delete-row-button"
            color="light"
            class="who-button deleteButton"
            [disabled]="slotTemplateForm.controls['who'].controls.length <= 1"
            (click)="removeWhoControl(index)"
          >
            <fa-icon slot="icon-only" [icon]="faTrash"></fa-icon>
          </ion-button>
        </div>
      }
    </div>

    <div class="extra-shift-button">
      <ion-button
        color="light"
        class="extra-shift"
        data-test="create-extra-slot-button"
        size="small"
        (click)="addWhoControl()"
      >
        Extra shift aanmaken
      </ion-button>
    </div>

    @if (requirementsStoreService.mustHavePredefinedLesson()) {
      <tt-ion-select-search
        formControlName="when"
        fill="solid"
        [showBackdrop]="false"
        [multiple]="true"
        [compareWith]="compareWithId"
        [displayNameFunction]="displayLesson"
        alignment="center"
        [options]="createSlotsStoreService.lessonsBySchedule()"
        data-test="create-slot-modal-select-lesson"
        class="lesson-select"
      >
        <ion-label position="floating"> Les </ion-label>
        <ng-container *selectSearchOption="let item; let search = search">
          <span
            [innerHTML]="
              (item.name ? item.name + ' - ' : '') +
                (item.date | date: 'dd-MM-yyyy') +
                ' - (' +
                item.numberOfPupils +
                ' leerlingen)' | highlightSearchText: search
            "
          ></span>
        </ng-container>
        <ion-note slot="error" *ngxControlError="slotTemplateForm.controls.when; track: 'required'">
          Les is <strong>verplicht</strong>.
        </ion-note>
      </tt-ion-select-search>
    } @else {
      <app-dates-control formControlName="when"></app-dates-control>
    }
  </form>

  @if (currentSegment() === CreateSlotSegments.SHIFT_FINE_TUNE) {
    <app-fine-tune data-test="create-slot-fine-tune-page"> </app-fine-tune>
  }

  <ion-button
    data-test="create-slot-cancel-button"
    type="button"
    tabindex="-1"
    fill="clear"
    slot="action"
    (click)="modalController.dismiss()"
  >
    Annuleren
  </ion-button>

  @if (currentSegment() === CreateSlotSegments.SHIFT_FINE_TUNE) {
    <ion-button
      data-test="create-slot-previous-button"
      type="button"
      tabindex="-1"
      slot="action"
      fill="outline"
      (click)="currentSegment.set(CreateSlotSegments.SHIFT_TEMPLATE)"
    >
      Vorige
    </ion-button>
  }

  @if (currentSegment() === CreateSlotSegments.SHIFT_TEMPLATE) {
    <ion-button
      type="button"
      tabindex="-1"
      slot="action"
      data-test="create-slot-next-button"
      [disabled]="slotTemplateForm.invalid"
      (click)="currentSegment.set(CreateSlotSegments.SHIFT_FINE_TUNE)"
      color="secondary"
    >
      Volgende
    </ion-button>
  }

  @if (currentSegment() === CreateSlotSegments.SHIFT_FINE_TUNE) {
    <ion-button
      type="submit"
      (click)="createSlotsStoreService.submitCreateSlotForm()"
      slot="action"
      [disabled]="
        createSlotsStoreService.submitting() ||
        slotTemplateForm.invalid ||
        slotTemplateForm.pristine
      "
      color="secondary"
      data-test="create-slot-submit-button"
    >
      @if (createSlotsStoreService.submitting()) {
        <ion-spinner slot="start" color="medium"></ion-spinner>
      }
      Aanmaken
    </ion-button>
  }
</tt-modal>
