import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, filter, map, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { createSlotsActions } from '../actions/create-slots.actions';
import { slotTemplateActions } from '../actions/slot-template.actions';
import { LessonsByScheduleApi } from '../api/lessons-by-schedule.api';
import { RequirementsSelectors } from '../selectors/requirements.selectors';
import { SelectedSelectors } from '../selectors/selected.selectors';

@Injectable()
export class LoadLessonsEffects {
  private readonly actions$ = inject(Actions);

  private readonly lessonsByScheduleApi = inject(LessonsByScheduleApi);

  private readonly store = inject(Store);

  public readonly initLoadLessonsWhenNeedingPredefinedLesson = createEffect(() =>
    this.actions$.pipe(
      ofType(
        slotTemplateActions.changeBusinessServiceSuccess,
        slotTemplateActions.changeScheduleSuccess,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(RequirementsSelectors.mustHavePredefinedLesson)),
        this.store.pipe(select(SelectedSelectors.schedule), isDefined()),
      ]),
      filter(([_action, mustHavePredefinedLesson]) => !!mustHavePredefinedLesson),
      map(([_action, _mustHavePredefinedLesson, schedule]) =>
        createSlotsActions.loadLessons({ schedule: schedule }),
      ),
    ),
  );

  public readonly loadLessonsBySchedule = createEffect(() =>
    this.actions$.pipe(
      ofType(createSlotsActions.loadLessons),
      switchMap((action) => {
        return this.lessonsByScheduleApi.execute(jsonLdSelectId(action.schedule)).pipe(
          map((response) => {
            return createSlotsActions.loadLessonsSuccess({ lessons: response['hydra:member'] });
          }),
          catchError((error) => of(createSlotsActions.loadLessonsFailure({ error: error }))),
        );
      }),
    ),
  );

  public readonly loadLessonsByScheduleFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(createSlotsActions.loadLessonsFailure, {
          message: 'Er is iets misgegaan bij het laden van de lessen bij het rooster.',
        }),
      ),
    { dispatch: false },
  );
}
