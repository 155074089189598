import { Dictionary } from '@ngrx/entity';
import { CustomLabelsQueryMapperDict, SearchQueryMap } from '@scheduler-frontend/search-expression';
import { PlainRangeInterface, TsRange } from '@techniek-team/class-transformer';
import { TsRangeInterface } from '@techniek-team/common';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { format } from 'date-fns';
import { merge } from 'lodash-es';
import { SearchContract } from '../contract/search.contract';

export function getReadableSearchChips(
  search: SearchContract,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryMappersDictionaries: Dictionary<SearchQueryMap<any>>[],
): string[] {
  const queryMappers: Dictionary<SearchQueryMap<unknown>> = merge(
    CustomLabelsQueryMapperDict,
    ...queryMappersDictionaries,
  );
  const filters = search.metaData?.filters;
  if (!filters) {
    return [];
  }

  const chips: string[] = [];
  for (let [key, value] of Object.entries(filters)) {
    handleFilterKey(key, value, queryMappers, chips);
  }

  return chips;
}

function handleFilterKey(
  key: string,
  value: string | number | PlainRangeInterface<string | number> | readonly (string | number)[],
  queryMappers: Dictionary<SearchQueryMap<unknown>>,
  chips: string[],
): void {
  switch (key) {
    case 'lesson.date':
      addDateChip(value as string | PlainRangeInterface<string>, chips);
      break;
    case 'lesson.numberOfPupils':
      addNumberOfPupilsChip(value as PlainRangeInterface<number>, chips);
      break;
    default:
      addDefaultChip(key, value as string[], queryMappers, chips);
      break;
  }
}

function addDateChip(value: string | PlainRangeInterface<string>, chips: string[]): void {
  if (typeof value === 'string') {
    chips.push(format(new Date(value), 'dd-MM-yyyy'));
  } else {
    chips.push(TsRange.fromObject(value as TsRangeInterface<string>).format('dd-MM-yyyy', ' t/m '));
  }
}

function addNumberOfPupilsChip(value: PlainRangeInterface<number>, chips: string[]): void {
  chips.push(`${value.start} tot ${value.end} leerlingen`);
}

function addDefaultChip(
  key: string,
  value: string[],
  queryMappers: Dictionary<SearchQueryMap<unknown>>,
  chips: string[],
): void {
  const queryMap = queryMappers[key];
  if (!queryMap) {
    chips.push(`onbekend <small>(${key})</small>`);
    return;
  }
  value = Array.isArray(value) ? value : [value];
  value.forEach((iri: string) => addEntityChip(iri, key, queryMap, chips));
}

function addEntityChip(
  iri: string,
  key: string,
  queryMap: SearchQueryMap<unknown>,
  chips: string[],
): void {
  const id = jsonLdSelectId(iri);
  if (!queryMap.entities[id]) {
    chips.push(`onbekend <small>(${key} | ${id})</small>`);
    return;
  }
  chips.push(queryMap.getDisplayText(queryMap.entities[id]));
}
