/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dictionary } from '@ngrx/entity';
import { IdSelector } from '@ngrx/entity/src/models';

export function toDictionary<T extends object>(
  objects: T[],
  selector: IdSelector<T>,
): Dictionary<T> {
  return objects.reduce<Dictionary<T>>((dict, item, index) => {
    dict[selector(item)] = item;
    return dict;
  }, {});
}
