import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { ProductTypesSelectors } from '@scheduler-frontend/data-access-product-types';
import { schedulesActions, SchedulesSelectors } from '@scheduler-frontend/data-access-schedules';
import { schedulingViewActions } from '@scheduler-frontend/data-access-scheduling';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { omit } from 'lodash-es';
import { catchError, filter, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { searchActions } from '../action/search.actions';
import { userSearchActions } from '../action/user-search.actions';
import { SearchApi } from '../api/search.api';
import { SearchSelectors } from '../selector/search.selectors';

@Injectable()
export class SearchEffects {
  private readonly actions$ = inject(Actions);

  private readonly searchApi = inject(SearchApi);

  private readonly store = inject(Store);

  public readonly createSearchHash = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.createSearchHash),
      switchMap(({ filters, isUserInitiated, setAsCurrentSearch }) =>
        this.searchApi.createSearchHash(filters, isUserInitiated).pipe(
          map((response) =>
            searchActions.createSearchHashSuccess({
              isUserInitiated: isUserInitiated,
              search: response,
              setAsCurrentSearch: setAsCurrentSearch,
            }),
          ),
          catchError((error) => of(searchActions.createSearchHashFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly appendToSearchHash = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.appendToSearchHash),
      concatLatestFrom(() => [this.store.pipe(select(SearchSelectors.currentSearch))]),
      switchMap(([{ filters, removeKeys, isUserInitiated, setAsCurrentSearch }, currentSearch]) => {
        return this.searchApi
          .createSearchHash(
            omit(
              {
                ...(currentSearch?.metaData?.filters ?? {}),
                ...filters,
              },
              removeKeys ?? [],
            ),
            isUserInitiated,
          )
          .pipe(
            map((response) =>
              searchActions.appendToSearchHashSuccess({
                isUserInitiated: isUserInitiated,
                search: response,
                setAsCurrentSearch: setAsCurrentSearch,
              }),
            ),
            catchError((error) => of(searchActions.createSearchHashFailure({ error: error }))),
          );
      }),
    ),
  );

  public readonly removeFromSearchHash = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.removeFromSearchHash),
      concatLatestFrom(() => [this.store.pipe(select(SearchSelectors.currentSearch), isDefined())]),
      switchMap(([{ keys, isUserInitiated, setAsCurrentSearch }, currentSearch]) =>
        this.searchApi
          .createSearchHash(
            omit({ ...(currentSearch.metaData?.filters ?? {}) }, keys),
            isUserInitiated,
          )
          .pipe(
            map((response) =>
              searchActions.removeFromSearchHashSuccess({
                isUserInitiated: isUserInitiated,
                search: response,
                setAsCurrentSearch: setAsCurrentSearch,
              }),
            ),
            catchError((error) => of(searchActions.createSearchHashFailure({ error: error }))),
          ),
      ),
    ),
  );

  public readonly setCurrentUserSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(
        searchActions.createSearchHashSuccess,
        searchActions.appendToSearchHashSuccess,
        searchActions.removeFromSearchHashSuccess,
      ),
      filter((action) => {
        return !!action.setAsCurrentSearch;
      }),
      map((action) => {
        if (action.isUserInitiated) {
          return userSearchActions.setCurrentUserSearch({ searchId: action.search.hash });
        }

        return searchActions.initCurrentSystemSearch({ searchId: action.search.hash });
      }),
    ),
  );

  public readonly createSearchHashFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(searchActions.createSearchHashFailure, {
          message: 'Er is iets misgegaan bij het aanmaken van de zoekopdracht.',
        }),
      ),
    { dispatch: false },
  );

  public readonly setSelectedLocationFiltersAsSystemSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulesActions.selectedProductTypeAtLocation,
        schedulesActions.deselectedProductTypeAtLocation,
        schedulingViewActions.setSchedulingView,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SchedulesSelectors.selectedProductTypesAtLocation)),
      ]),
      map(([_, selectedProductType]) => {
        const key = ProductTypesSelectors.productTypesFilterGroupKey;
        if (selectedProductType.length > 0) {
          return searchActions.appendToSearchHash({
            filters: { [key]: [...selectedProductType] },
            isUserInitiated: false,
            setAsCurrentSearch: true,
          });
        }
        return searchActions.removeFromSearchHash({
          keys: [key],
          isUserInitiated: false,
          setAsCurrentSearch: true,
        });
      }),
    ),
  );
}
