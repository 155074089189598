import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CandidateAllotmentStatisticContract } from '../../contracts/candidate-allotment-statistic.contract';

export const candidateAllotmentStatisticsActions = createActionGroup({
  source: '[CandidateAllotments]',
  events: {
    addCandidateAllotmentStatistics: props<{
      items: {
        candidate: string;
        slots: string[];
        candidateAllotmentStatistics: CandidateAllotmentStatisticContract[];
      }[];
    }>(),
    clearAllCandidateAllotmentStatistics: emptyProps(),
  },
});
