import { Dictionary } from '@ngrx/entity';
import { CandidateHasSkillsContract } from '@scheduler-frontend/candidate-contracts';
import { hashParams } from '@scheduler-frontend/common';
import { BusinessServiceContract } from '@scheduler-frontend/data-access-business-services';
import { RoleContract, RoleDetailedContract } from '@scheduler-frontend/data-access-roles';
import { SubjectContract } from '@scheduler-frontend/data-access-subjects';
import { LevelEnum } from '@scheduler-frontend/enums';
import { sortString } from '@techniek-team/common';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { isFuture, isPast, max, min } from 'date-fns';
import { groupBy, memoize, uniq } from 'lodash-es';

export interface CandidateSkillPerSubjectLevelCombined {
  subjects: SubjectContract[];
  levels: LevelEnum[];
  skills: CandidateHasSkillsContract[];
  earliestStartDate: Date;
  latestEndDate: Date;
}

export interface CandidateSkillsPerRole {
  skills: CandidateSkillPerSubjectLevelCombined[];
  role: RoleContract;
}

export interface CandidateSkillsPerBusinessService {
  past: CandidateSkillsPerRole[];
  present: CandidateSkillsPerRole[];
  future: CandidateSkillsPerRole[];
  businessService: BusinessServiceContract;
}

export const createCandidatePerBusinessService = memoize(
  (
    skills: CandidateHasSkillsContract[],
    businessService: BusinessServiceContract,
    roles: Dictionary<RoleContract | RoleDetailedContract>,
    subjects: Dictionary<SubjectContract>,
  ) => {
    const skillSplitByTiming = groupBy(skills, (skill) => {
      if (isPast(skill.validityRange.end)) {
        return 'past';
      }
      if (isFuture(skill.validityRange.start)) {
        return 'future';
      }
      return 'present';
    });

    return {
      past: createCandidateSkillsPerRole(skillSplitByTiming['past'], roles, subjects),
      present: createCandidateSkillsPerRole(skillSplitByTiming['present'], roles, subjects),
      future: createCandidateSkillsPerRole(skillSplitByTiming['future'], roles, subjects),
      businessService: businessService,
    } as CandidateSkillsPerBusinessService;
  },
  (skills) => hashParams(skills),
);

const createCandidateSkillsPerRole = memoize(
  (
    skills: CandidateHasSkillsContract[],
    roles: Dictionary<RoleContract | RoleDetailedContract>,
    subjects: Dictionary<SubjectContract>,
  ): CandidateSkillsPerRole[] => {
    const groupByRole = groupBy(skills, (skill) => skill.skill.role);
    return Object.entries(groupByRole).map(([roleId, value]) => {
      return {
        skills: createCandidateSkillPerRoleLevelCombined(value, subjects),
        role: roles[jsonLdSelectId(roleId)] as RoleContract,
      } as CandidateSkillsPerRole;
    });
  },
  (skills) => hashParams(skills),
);

const createCandidateSkillPerRoleLevelCombined = memoize(
  (
    skills: CandidateHasSkillsContract[],
    subjects: Dictionary<SubjectContract>,
  ): CandidateSkillPerSubjectLevelCombined[] => {
    const skillsBySubject = groupBy(skills, (skill) => skill.skill.subject ?? 'noSubject');
    const skillsMerger = groupBy(Object.values(skillsBySubject), (skillBySubject) => {
      return skillBySubject
        .map((skill) => skill.skill.level)
        .sort(sortString)
        .join('');
    });
    return Object.values(skillsMerger).map((mergedSkills) => {
      const skillsList = mergedSkills.flat(1);
      return {
        skills: skillsList,
        subjects: uniq(
          skillsList.map((skill) => (skill.skill.subject ? skill.skill.subject : 'noSubject')),
        ).map((subject) =>
          subject !== 'noSubject' ? subjects[jsonLdSelectId(subject)] : 'noSubject',
        ),
        levels: uniq(skillsList.map((skill) => skill.skill.level)),
        earliestStartDate: min(skillsList.map((skill) => skill.validityRange.start)),
        latestEndDate: max(skillsList.map((skill) => skill.validityRange.end)),
      } as CandidateSkillPerSubjectLevelCombined;
    });
  },
  (skills) => hashParams(skills),
);
