import { DeliveryTypeEnum } from '@scheduler-frontend/data-access-business-services';

/**
 * Returns true if the delivery type is at a location. When delivery type is
 * unknown we assume that it is also at location.
 */
export function isAtLocation(deliveryType: DeliveryTypeEnum): boolean {
  // When delivery type is unknown we assume that location is required.
  if (deliveryType === null) {
    return true;
  }

  const allAtLocation: boolean = deliveryType === DeliveryTypeEnum.ALL_AT_LOCATION;
  const candidateOnline: boolean =
    deliveryType === DeliveryTypeEnum.CANDIDATE_ONLINE_PUPIL_AT_LOCATION;
  const candidateAtLocation: boolean =
    deliveryType === DeliveryTypeEnum.CANDIDATE_AT_LOCATION_PUPIL_ONLINE;

  return allAtLocation || candidateOnline || candidateAtLocation;
}
