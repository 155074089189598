import { Dictionary } from '@ngrx/entity';
import { tupleToDictionary } from '@techniek-team/tt-ngrx';
import { SearchQueryMap } from './search-query-map.interface';

//eslint-disable-next-line @typescript-eslint/naming-convention
export const CustomLabels: [string, { displayText: string; value: string }][] = [
  [
    'OWN_LOCATION',
    {
      displayText: 'ET op hub',
      value: 'schedule.product = "26c7d36f-5e46-5e91-baa9-294e21cf856f"',
    },
  ],
  [
    'INSCHOOL',
    {
      displayText: 'ET op school',
      value: 'schedule.product <> "26c7d36f-5e46-5e91-baa9-294e21cf856f"',
    },
  ],
  [
    'CONFLICT',
    {
      displayText: 'Conflict',
      value: 'assignmentHasSlot.isConflict = "true"',
    },
  ],
  [
    'CANCELLED',
    {
      displayText: 'ET Geannuleerd',
      value: 'lesson.numberOfPupils = 0 AND lesson.maxNumberOfPupils = 0',
    },
  ],
  [
    'NO_SKILL_CHECK',
    {
      displayText: 'Vaardigheden uit',
      value: 'slot.performSkillCheck = "false"',
    },
  ],
  [
    'NOT_BILLABLE',
    {
      displayText: 'Saldo uit',
      value: 'lesson.isBillable = "false"',
    },
  ],
  [
    'URGENT',
    {
      displayText: 'Urgent',
      value: 'assignment.isUrgent = "true"',
    },
  ],
  [
    'NO_COMPENSATION_POSSIBLE',
    {
      displayText: 'Geen vergoeding mogelijk',
      value:
        'assignment.isCompensationPossible = "false" AND assignment.expectsCompensation = "true"',
    },
  ],
  [
    'SELF_ASSIGNED',
    {
      displayText: 'Zelf ingepland',
      value: 'assignment.candidate = assignment.assignedBy AND assignment.selfAssignable = "true"',
    },
  ],
  [
    'COMPENSATION_DISABLED',
    {
      displayText: 'Vergoeding uitgezet',
      value:
        'assignment.isCompensationPossible = "true" AND assignment.performCompensation = "false"',
    },
  ],
];

//eslint-disable-next-line @typescript-eslint/naming-convention
export const CustomLabelsQueryMapperDict: Dictionary<
  SearchQueryMap<{ displayText: string; value: string }>
> = {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  custom_labels: {
    entities: tupleToDictionary(CustomLabels),
    getDisplayText: (item) => item.displayText,
  },
};
