import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { UsersSelectors, UsersStoreService } from '@scheduler-frontend/data-access-users';
import { Collection } from '@techniek-team/api-platform';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO, isAfter, subDays } from 'date-fns';
import {
  catchError,
  exhaustMap,
  filter,
  from,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { LocationContract } from '../contracts/location.contract';
import { LocationTypeEnum } from '../enums/location-type.enum';
import { LocationApi } from './api/location/location.api';
import { locationsActions } from './locations.actions';
import { LOCATIONS_FEATURE_KEY } from './locations.reducer';
import { LocationsSelectors } from './locations.selectors';

@Injectable()
export class LocationsEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly locationApi = inject(LocationApi);

  private readonly storage = inject(Storage);

  private readonly userService = inject(UsersStoreService);

  private readonly store = inject(Store);

  public ngrxOnInitEffects() {
    return locationsActions.initLocations({
      locationTypes: [LocationTypeEnum.HUB, LocationTypeEnum.SCHOOL, LocationTypeEnum.EXTERN],
      includeArchived: false,
    });
  }

  public saveLocationsToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locationsActions.loadLocationsSuccess),
        tap((action) => {
          if (action.locations.length === 0) {
            return;
          }
          return from(
            Promise.all([
              this.storage.set(`${LOCATIONS_FEATURE_KEY}-store-cache`, {
                items: action.locations,
                locationTypes: action.locationTypes,
                timestamp: new Date(),
              }),
            ]),
          );
        }),
      ),
    { dispatch: false },
  );

  //eslint-disable-next-line max-lines-per-function
  public readonly initLocations = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.initLocations),
      exhaustMap((action) =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() => {
            return from(this.storage.get(`${LOCATIONS_FEATURE_KEY}-store-cache`)).pipe(
              exhaustMap((cache) => {
                if (cache && isAfter(cache.timestamp, subDays(new Date(), 1))) {
                  return of(
                    locationsActions.loadFromCacheLocationsSuccess({
                      locations: cache.items,
                      totalItems: cache.items.length,
                      locationTypes: cache.locationTypes,
                      cacheTimestamp: formatISO(cache.timestamp),
                    }),
                  );
                }
                return this.locationApi
                  .getLocations({
                    locationTypes: action.locationTypes,
                    includeArchived: action.includeArchived,
                  })
                  .pipe(
                    map((response) => {
                      return [action, response] as [typeof action, Collection<LocationContract>];
                    }),
                    map(([action2, locations]) =>
                      locationsActions.loadLocationsSuccess({
                        locations: locations['hydra:member'],
                        totalItems: locations['hydra:totalItems'],
                        locationTypes: action2.locationTypes,
                      }),
                    ),
                    catchError((error) =>
                      of(locationsActions.loadLocationsFailure({ error: error })),
                    ),
                  );
              }),
            );
          }),
        ),
      ),
    ),
  );

  public readonly reloadLocations = this.actions$.pipe(
    ofType(locationsActions.reloadLocations),
    concatLatestFrom(() => [
      this.store.pipe(select(LocationsSelectors.locationTypesLoaded)),
      this.store.pipe(select(LocationsSelectors.archivedLocationTypesLoaded)),
    ]),
    map(([action, locationtypes, archivedLocationTypes]) => {
      return locationsActions.initLocations({
        locationTypes: action.includeArchived ? archivedLocationTypes : locationtypes,
        includeArchived: action.includeArchived,
      });
    }),
  );

  public loadPupilPrivateLocations = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.loadPupilPrivateLocations),
      exhaustMap((action) =>
        this.locationApi
          .getLocations({
            locationTypes: [LocationTypeEnum.PRIVATE],
            includeArchived: action.includeArchived,
          })
          .pipe(
            isDefined(),
            map((response) => {
              return [action, response] as [typeof action, Collection<LocationContract>];
            }),
            map(([_action, locations]) =>
              locationsActions.loadPupilPrivateLocationsSuccess({
                locations: locations['hydra:member'],
                totalItems: locations['hydra:totalItems'],
              }),
            ),
            catchError((error) => of(locationsActions.loadLocationsFailure({ error: error }))),
          ),
      ),
    ),
  );

  public loadLocationsFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(locationsActions.loadLocationsFailure, {
          message: 'Er is iets misgegaan bij het laden van Locations.',
        }),
      ),
    { dispatch: false },
  );

  public loadPrivateLocationsFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(locationsActions.loadPupilPrivateLocationsFailure, {
          message: 'Oeps! Het ophalen van de privé addresses is mislukt!',
        }),
      ),
    { dispatch: false },
  );

  public saveLocationToStorage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(locationsActions.selectLocation),
        concatLatestFrom(() => this.userService.user$),
        tap(([action, user]) => {
          if (!action.locationId) {
            return Promise.all([
              this.storage.remove(`active-location-${user?.id}`),
              this.storage.remove('active-location-global'),
            ]);
          }
          if (user) {
            return from(this.storage.set(`active-location-${user.id}`, action.locationId));
          }
          return from(this.storage.set('active-location-global', action.locationId));
        }),
      ),
    { dispatch: false },
  );

  public setSelectedLocationFromStorage = createEffect(() =>
    this.actions$.pipe(
      ofType(locationsActions.loadLocationsSuccess, locationsActions.loadFromCacheLocationsSuccess),
      withLatestFrom(
        this.userService.user$.pipe(
          switchMap((user) => {
            if (user) {
              return this.storage.get(`active-location-${user.id}`);
            }
            return this.storage.get('active-location-global');
          }),
        ),
      ),
      concatLatestFrom(() => this.store.pipe(select(LocationsSelectors.currentLocationId))),
      filter(([_, currentLocation]) => currentLocation === undefined),
      map(([[action, storageId], currentLocation]) => {
        if (!storageId) {
          storageId = jsonLdSelectId(action.locations[0]);
        }
        return locationsActions.selectLocation({
          locationId: storageId,
        });
      }),
    ),
  );
}
