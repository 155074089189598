import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { ApproachMediumEnum } from '@scheduler-frontend/approach-contracts';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, map, of, switchMap } from 'rxjs';
import { schedulingApproachCandidatesActions } from '../actions/scheduling-approach-candidates.actions';
import { CommunicateApproachApi } from '../api/communicate-approach.api';
import { CreateApproachApi } from '../api/create-approach.api';
import { SchedulingApproachSelectors } from '../selectors/scheduling-approach.selectors';
import { SchedulingSelectors } from '../selectors/scheduling.selectors';

export class SchedulingApproachCandidatesEffect {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly createApproachApi = inject(CreateApproachApi);

  private readonly communicateApproachApi = inject(CommunicateApproachApi);

  public approachCandidate = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingApproachCandidatesActions.approach),
      concatLatestFrom(() => [
        this.store.pipe(select(SchedulingSelectors.selectedCandidateIds)),
        this.store.pipe(select(SchedulingSelectors.selectedSlotIds)),
      ]),
      switchMap(([action, candidates, slots]) => {
        return this.createApproachApi
          .execute(
            'candidate' in action && action.candidate
              ? [action.candidate]
              : candidates.map((a) => `/api/v3/candidates/${a}`),
            !action.communicateImmediately,
            action.communicateImmediately ? slots.map((a) => `/api/v3/slots/${a}`) : undefined,
            action.filters,
          )
          .pipe(
            map((response) => {
              if (action.communicateImmediately && action.approachThrough) {
                return schedulingApproachCandidatesActions.createApproachSuccess({
                  approaches: response,
                  communicateImmediately: true,
                  approachThrough: action.approachThrough ?? [ApproachMediumEnum.MOBILE_APP],
                  hasAnsweredThePhone: action.hasAnsweredThePhone ?? false,
                });
              }
              return schedulingApproachCandidatesActions.createApproachSuccess({
                approaches: response,
                communicateImmediately: false,
              });
            }),
            catchError((error) =>
              of(schedulingApproachCandidatesActions.createApproachFailure({ error: error })),
            ),
          );
      }),
    ),
  );

  public communicate = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulingApproachCandidatesActions.communicate,
        schedulingApproachCandidatesActions.createApproachSuccess,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SchedulingApproachSelectors.activeApproachId), isDefined()),
        this.store.pipe(select(SchedulingSelectors.selectedSlots), isDefined()),
      ]),
      switchMap(([action, activeApproachId, selectedSlots]) => {
        if ('communicateImmediately' in action && !action.communicateImmediately) {
          return of(schedulingApproachCandidatesActions.candidateApproachInitiated());
        }
        let approach =
          'approaches' in action
            ? (jsonLdSelectId(action.approaches[0]) as string)
            : (action.approach as string);
        const selectedSlotIris: string[] = selectedSlots.map((slot) => slot['@id']);

        return this.communicateApproachApi
          .execute(approach, action.approachThrough, action.hasAnsweredThePhone, selectedSlotIris)
          .pipe(
            map((response) =>
              schedulingApproachCandidatesActions.communicateSuccess({
                approach: response,
              }),
            ),
            catchError((error) =>
              of(schedulingApproachCandidatesActions.communicateFailure({ error: error })),
            ),
          );
      }),
    ),
  );

  public createApproachFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(schedulingApproachCandidatesActions.createApproachFailure, {
          message: 'Er is iets misgegaan bij het benaderen van de kandidaten.',
        }),
      ),
    { dispatch: false },
  );

  public communicateFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(schedulingApproachCandidatesActions.communicateFailure, {
          message: 'Er is iets misgegaan bij het opslaan van de benadering voor de kandidaat.',
        }),
      ),
    { dispatch: false },
  );
}
