import { InjectionToken } from '@angular/core';

export interface InAppBrowserCreateOptions {
  url: string;
  windowName?: string;
  callback?: () => void;
}

export const IN_APP_BROWSER_TOKEN = new InjectionToken<InAppBrowserService>('IN APP BROWSER TOKEN');

export abstract class InAppBrowserService {
  public abstract create(options: InAppBrowserCreateOptions): Promise<void>;
}
