import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';
import { AssignmentCompensationLineContract } from '../../../contracts/assignment-compensation-line.contract';
import { PayoutArticleCodeEnum } from '../../../enums/payout-article-code.enum';
import { GetAssignmentCompensationResponse } from './assignment-compensation.response';

@Injectable({
  providedIn: 'root',
})
export class AssignmentCompensationApi {
  protected readonly httpClient = inject(HttpClient);

  public getAssignmentCompensation(
    assignment: string,
  ): Observable<GetAssignmentCompensationResponse> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/assignments/${assignment}/compensation`;

    return this.httpClient.get<GetAssignmentCompensationResponse>(url);
  }

  /**
   * Create a new assignment compensation line by doing a POST request
   * to the API.
   */
  public addCompensationLine(
    assignmentCompensation: string,
    assignmentCompensationLine: {
      articleCode: PayoutArticleCodeEnum;
      description: string;
      quantity: string;
      amount: string;
      assignmentHasSlot: string;
      subtotal: number;
      date: string;
    },
  ): Observable<AssignmentCompensationLineContract> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${assignmentCompensation}/compensations/lines`;

    return this.httpClient.post<AssignmentCompensationLineContract>(url, {
      //eslint-disable-next-line @typescript-eslint/naming-convention
      article_code: assignmentCompensationLine.articleCode,
      description: assignmentCompensationLine.description,
      quantity: assignmentCompensationLine.quantity,
      amount: assignmentCompensationLine.amount,
      assignmentHasSlot: assignmentCompensationLine.assignmentHasSlot,
      subtotal: assignmentCompensationLine.subtotal,
      date: assignmentCompensationLine.date,
    });
  }

  public removeCompensationLine(assignmentCompensationLineId: string): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${assignmentCompensationLineId}/compensation/line`;

    return this.httpClient.delete<void>(url);
  }

  public postMustPerformCompensation(
    assignment: string,
    needsCompensation: boolean,
    automaticTravelCompensation: boolean,
  ) {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${assignment}/compensation`;

    return this.httpClient.post<{
      mustPerformCompensation: boolean;
      automaticTravelCompensation: boolean;
      canEnableCompensation: boolean;
    }>(url, {
      mustPerformCompensation: needsCompensation,
      automaticTravelCompensation: automaticTravelCompensation,
    });
  }
}
