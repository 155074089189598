import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { locationsActions } from '@scheduler-frontend/data-access-locations';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, filter, map, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { createSlotsActions } from '../actions/create-slots.actions';
import { slotTemplateActions } from '../actions/slot-template.actions';
import { ScheduleDetailedApi } from '../api/schedule-detailed.api';
import { ScheduleApi } from '../api/schedule.api';
import { DeliveryTypeSelectors } from '../selectors/delivery-type.selector';
import { ScheduleSelectors } from '../selectors/schedule.selectors';
import { SelectedSelectors } from '../selectors/selected.selectors';

@Injectable()
export class LoadSchedulesEffects {
  private readonly actions$ = inject(Actions);

  private readonly scheduleDetailedApi = inject(ScheduleDetailedApi);

  private readonly scheduleApi = inject(ScheduleApi);

  private readonly store = inject(Store);

  public readonly initLoadSchedules = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        createSlotsActions.setScheduleSearchQuery,
        slotTemplateActions.changeProductLocation,
        slotTemplateActions.changeBusinessServiceSuccess,
        slotTemplateActions.changeDeliveryType,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SelectedSelectors.businessService), isDefined()),
      ]),
      map(([_action, businessService]) => {
        return createSlotsActions.loadSchedules({ businessService: businessService });
      }),
    );
  });

  public readonly loadSchedules = createEffect(() => {
    return this.actions$.pipe(
      ofType(createSlotsActions.loadSchedules),
      concatLatestFrom(() => [
        this.store.select(SelectedSelectors.productLocation),
        this.store.select(DeliveryTypeSelectors.isAtLocation),
        this.store.select(ScheduleSelectors.scheduleSearchQuery),
      ]),
      switchMap(([action, location, isAtLocation, searchInput]) => {
        return this.scheduleApi
          .execute(
            jsonLdSelectId(action.businessService),
            location ? jsonLdSelectId(location) : undefined,
            searchInput,
          )
          .pipe(
            map((response) => {
              if (response['hydra:member'].length === 0) {
                return createSlotsActions.loadSchedulesEmptyResults();
              }

              return createSlotsActions.loadSchedulesSuccess({
                schedules: response['hydra:member'],
                totalItems: response['hydra:totalItems'],
              });
            }),
            catchError((error) => of(createSlotsActions.loadSchedulesFailure({ error: error }))),
          );
      }),
    );
  });

  public readonly loadSchedulesFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(createSlotsActions.loadSchedulesFailure, {
          message: 'Oeps! Het ophalen van de roosters is mislukt!',
        }),
      ),
    { dispatch: false },
  );

  public readonly loadSchedulesEmptyResults = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(createSlotsActions.loadSchedulesEmptyResults, {
          message: 'Let op! Er zijn voor deze combinatie geen roosters gevonden!',
        }),
      ),
    { dispatch: false },
  );

  public readonly loadDetailedScheduleOnSelect = createEffect(() => {
    return this.actions$.pipe(
      ofType(slotTemplateActions.changeSchedule),
      concatLatestFrom(() => this.store.pipe(select(SelectedSelectors.tempSchedule))),
      switchMap(([action, schedule]) => {
        if (schedule && 'pupilServiceSchedule' in schedule) {
          return of(
            slotTemplateActions.changeScheduleSuccess({
              change: schedule,
            }),
          );
        }
        return this.scheduleDetailedApi.execute(jsonLdSelectId(action.change)).pipe(
          map((response) => {
            return slotTemplateActions.changeScheduleSuccess({
              change: response,
            });
          }),
          catchError((error) => of(createSlotsActions.loadSchedulesFailure({ error: error }))),
        );
      }),
    );
  });

  public readonly loadPrivateTutorLocationsOnScheduleDetailed = createEffect(() => {
    return this.actions$.pipe(
      ofType(slotTemplateActions.changeSchedule, slotTemplateActions.changeDeliveryType),
      concatLatestFrom(() => [
        this.store.pipe(select(SelectedSelectors.schedule), isDefined()),
        this.store.pipe(select(DeliveryTypeSelectors.isAtHome)),
      ]),
      filter(([action, schedule, atHome]) => atHome),
      map(([action, schedule]) => {
        return locationsActions.loadPupilPrivateLocations({
          pupil: jsonLdSelectId(schedule.pupilServiceSchedule?.pupil),
          includeArchived: false,
        });
      }),
    );
  });
}
