import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  SlotDetailedContract,
  SlotDetailedWithAssignmentHasSlot,
} from '@scheduler-frontend/assignment-contracts';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { shareReplay } from 'rxjs';
import { SlotsPermissionsSelectors } from './+state/slots-permissions.selectors';

@Injectable()
export class SlotsPermissionsStoreService {
  private readonly store = inject(Store);

  public readonly canCreateOutsideBookingPeriod$ = this.store
    .pipe(select(UsersSelectors.isAdmin))
    .pipe(shareReplay());

  public readonly canCreateOutsideBookingPeriod = this.store.selectSignal(UsersSelectors.isAdmin);

  public readonly canChangePreformSkillCheck$ = this.store
    .pipe(select(UsersSelectors.isAdmin))
    .pipe(shareReplay());

  public readonly canUpdate$ = this.store
    .pipe(select(SlotsPermissionsSelectors.canUpdate))
    .pipe(shareReplay());

  public canMarkAsAbsent$(slot: SlotDetailedContract | SlotDetailedWithAssignmentHasSlot) {
    return this.store
      .pipe(select(SlotsPermissionsSelectors.canMarkAsAbsent(slot)))
      .pipe(shareReplay());
  }

  public canMarkAsPresent$(slot: SlotDetailedContract | SlotDetailedWithAssignmentHasSlot) {
    return this.store
      .pipe(select(SlotsPermissionsSelectors.canMarkAsPresent(slot)))
      .pipe(shareReplay());
  }
}
