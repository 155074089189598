import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { ReleaseImage } from './release-image.model';

/**
 * The Bare minimal version of the Schedule Resource.
 */
export class Release extends JsonLd {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Release';

  @Expose() public name!: string;

  @Expose() public tagName!: string;

  @Type(() => Date)
  @Expose()
  public releasedAt!: Date;

  @Expose() public descriptionHtml!: string;

  @Type(() => ReleaseImage)
  @Expose()
  public images!: ReleaseImage[];

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
