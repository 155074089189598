import { Injectable } from '@angular/core';
import {
  InAppBrowserCreateOptions,
  InAppBrowserService,
} from './in-app-browser.service';

@Injectable()
export class InAppBrowserWebService extends InAppBrowserService {
  public create(options: InAppBrowserCreateOptions): Promise<void> {
    window.open(options.url, options.windowName ?? '_self');
    return Promise.resolve();
  }
}
