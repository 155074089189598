import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { hashParams } from '@scheduler-frontend/common';
import { sortString } from '@techniek-team/common';
import { AvailabilityStatisticWithHashContract } from '../contracts/availability-statistics.contract';
import { availabilityStatisticsActions } from './availability-statistics.actions';

export const TRAVEL_DISTANCE_STATISTICS_FEATURE_KEY = 'availabilityStatistics';

export interface AvailabilityStatisticsState
  extends EntityState<AvailabilityStatisticWithHashContract> {
  loaded: boolean;
  loading: boolean;
  error?: string | null;
}

export function selectId(item: AvailabilityStatisticWithHashContract): string {
  return item.hash;
}

export function createAvailabilityStatisticsId(candidate: string, slots: string[]) {
  return hashParams([candidate, ...slots.sort(sortString)]);
}

export const availabilityStatisticsAdapter: EntityAdapter<AvailabilityStatisticWithHashContract> =
  createEntityAdapter<AvailabilityStatisticWithHashContract>({
    selectId: selectId,
  });

export const initialAvailabilityStatisticsState: AvailabilityStatisticsState =
  availabilityStatisticsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
  });

const reducer = createReducer(
  initialAvailabilityStatisticsState,
  on(availabilityStatisticsActions.addAvailabilityStatistics, (state, { items }) =>
    availabilityStatisticsAdapter.setMany(
      items.map((item) => {
        return {
          ...item.availabilityStatistic,
          hash: createAvailabilityStatisticsId(item.candidate, item.slots),
        };
      }),
      {
        ...state,
        loaded: true,
        loading: false,
      },
    ),
  ),
  on(availabilityStatisticsActions.clearAllAvailabilityStatistics, (state) =>
    availabilityStatisticsAdapter.removeAll(state),
  ),
);

export function availabilityStatisticsReducer(
  state: AvailabilityStatisticsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
