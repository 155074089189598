import { Expose } from 'class-transformer';
import { JsonLd } from './json-ld.model';

export class HydraView extends JsonLd {
  @Expose({ name: 'hydra:first' }) public first?: string;

  @Expose({ name: 'hydra:last' }) public last?: string;

  @Expose({ name: 'hydra:next' }) public next?: string;

  @Expose({ name: 'hydra:previous' }) public previous?: string;
}
