import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CandidateContract } from '@scheduler-frontend/candidate-contracts';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CandidateSearchApi {
  private readonly httpClient = inject(HttpClient);

  public execute(query: string, page: number): Observable<Collection<CandidateContract>> {
    let url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/candidates`;
    let params: HttpParams = new HttpParams({
      fromObject: {
        query: query,
        page: page,
        itemsPerPage: 20,
      },
    });

    // todo this is needed because the endpoint doesn't support pagination yet.
    params = params.set('limit', 200);

    return this.httpClient.get<Collection<CandidateContract>>(url, { params: params });
  }
}
