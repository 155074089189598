import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CANDIDATE_REMARKS_FEATURE_KEY,
  candidateRemarksAdapter,
  CandidateRemarksState,
} from '../reducers/candidate-remarks.reducer';

const { selectAll } = candidateRemarksAdapter.getSelectors();

export class CandidateRemarksSelectors {
  public static readonly state = createFeatureSelector<CandidateRemarksState>(
    CANDIDATE_REMARKS_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    CandidateRemarksSelectors.state,
    (state) => state.loading,
  );

  public static readonly saving = createSelector(
    CandidateRemarksSelectors.state,
    (state) => state.saving,
  );

  public static readonly loaded = createSelector(
    CandidateRemarksSelectors.state,
    (state) => state.loaded,
  );

  public static readonly error = createSelector(
    CandidateRemarksSelectors.state,
    (state) => state.error,
  );

  public static readonly remarks = createSelector(CandidateRemarksSelectors.state, (state) => {
    return selectAll(state);
  });
}
