import { Action, createReducer, on } from '@ngrx/store';
import { LessonDetailedContract } from '@scheduler-frontend/assignment-contracts';
import {
  BusinessServiceContract,
  BusinessServiceDetailedContract,
  DeliveryTypeContract,
  ProductContract,
} from '@scheduler-frontend/data-access-business-services';
import { LocationContract } from '@scheduler-frontend/data-access-locations';
import { RoleDetailedContract } from '@scheduler-frontend/data-access-roles';
import { SubjectContract } from '@scheduler-frontend/data-access-subjects';
import { LevelEnum } from '@scheduler-frontend/enums';
import { ScheduleContract, ScheduleDetailedContract } from '@scheduler-frontend/schedule-contracts';
import { TsRangeInterface } from '@techniek-team/common';
import { SlotToCreateContract } from '../../../contract/slot-to-create.contract';
import { createSlotsActions } from '../actions/create-slots.actions';
import { slotTemplateActions } from '../actions/slot-template.actions';

export const CREATE_SLOTS_FEATURE_KEY = 'CreateSlots';

export interface SlotTemplate {
  productLocation?: LocationContract;

  tempBusinessService?: BusinessServiceContract | BusinessServiceDetailedContract;
  businessService?: BusinessServiceDetailedContract;

  deliveryType?: DeliveryTypeContract | null;

  tempSchedule?: ScheduleContract | ScheduleDetailedContract;
  schedule?: ScheduleDetailedContract;

  when?: LessonDetailedContract[] | string[]; // iso dates

  who: {
    role: RoleDetailedContract;
    timeRange: TsRangeInterface<string>;
    amountOfPupils: number | null;
    operationalLocation: LocationContract | null;
    subject: SubjectContract | null;
    level: LevelEnum | null;
  }[];
}

export interface CreateSlotsState {
  slotTemplate: SlotTemplate;
  slotsToCreate: SlotToCreateContract[];
  isFormValid: boolean;
  loading: boolean;
  submitting: boolean;
  loadingBusinessService: boolean;
  loadingProducts: boolean;
  products: ProductContract[];
  loadingLessons: boolean;
  lessons: LessonDetailedContract[];
  scheduleSearchQuery?: string;
  foundSchedulesTotal?: number;
  foundSchedules: ScheduleContract[];
  loadingSchedules: boolean;
  error?: unknown;
}

export const initialDataAccessCreateSlotsState: CreateSlotsState = {
  slotTemplate: { who: [] },
  slotsToCreate: [],
  isFormValid: false,
  loading: false,
  submitting: false,
  loadingBusinessService: false,
  loadingProducts: false,
  products: [],
  loadingLessons: false,
  lessons: [],
  foundSchedules: [],
  loadingSchedules: false,
};

const reducer = createReducer(
  initialDataAccessCreateSlotsState,
  on(slotTemplateActions.changeFormValidity, (state, { change }) => {
    return { ...state, isFormValid: change };
  }),
  on(slotTemplateActions.changeProductLocation, (state, { change }) => {
    return {
      ...state,
      slotTemplate: { ...state.slotTemplate, productLocation: change },
      loadingProducts: true,
    };
  }),
  on(slotTemplateActions.changeBusinessService, (state, { change }) => {
    return {
      ...state,
      slotTemplate: {
        ...state.slotTemplate,
        tempBusinessService: change,
        loadingBusinessService: true,
        loading: true,
      },
    };
  }),
  on(slotTemplateActions.changeBusinessServiceSuccess, (state, { change }) => {
    return {
      ...state,
      slotTemplate: {
        ...state.slotTemplate,
        businessService: change,
        loadingBusinessService: false,
        loading: false,
      },
    };
  }),
  on(slotTemplateActions.changeDeliveryType, (state, { change }) => {
    return { ...state, slotTemplate: { ...state.slotTemplate, deliveryType: change } };
  }),
  on(slotTemplateActions.changeSchedule, (state, { change }) => {
    return {
      ...state,
      slotTemplate: { ...state.slotTemplate, tempSchedule: change, loading: true },
    };
  }),
  on(slotTemplateActions.changeScheduleSuccess, (state, { change }) => {
    return { ...state, slotTemplate: { ...state.slotTemplate, schedule: change, loading: false } };
  }),
  on(slotTemplateActions.changeWhoRole, (state, { index, change }) => {
    const who = [...state.slotTemplate.who];
    who[index] = { ...state.slotTemplate.who[index], role: change };

    return { ...state, slotTemplate: { ...state.slotTemplate, who: who } };
  }),
  on(slotTemplateActions.changeWhoTimeRange, (state, { index, change }) => {
    const who = [...state.slotTemplate.who];
    who[index] = { ...state.slotTemplate.who[index], timeRange: change };

    return { ...state, slotTemplate: { ...state.slotTemplate, who: who } };
  }),
  on(slotTemplateActions.changeWhoAmountOfPupils, (state, { index, change }) => {
    const who = [...state.slotTemplate.who];
    who[index] = { ...state.slotTemplate.who[index], amountOfPupils: change };

    return { ...state, slotTemplate: { ...state.slotTemplate, who: who } };
  }),
  on(slotTemplateActions.changeWhoOperationalLocation, (state, { index, change }) => {
    const who = [...state.slotTemplate.who];
    who[index] = { ...state.slotTemplate.who[index], operationalLocation: change };

    return { ...state, slotTemplate: { ...state.slotTemplate, who: who } };
  }),
  on(slotTemplateActions.changeWhoSubject, (state, { index, change }) => {
    const who = [...state.slotTemplate.who];
    who[index] = { ...state.slotTemplate.who[index], subject: change };

    return { ...state, slotTemplate: { ...state.slotTemplate, who: who } };
  }),
  on(slotTemplateActions.changeWhoLevel, (state, { index, change }) => {
    const who = [...state.slotTemplate.who];
    who[index] = { ...state.slotTemplate.who[index], level: change };

    return { ...state, slotTemplate: { ...state.slotTemplate, who: who } };
  }),
  on(slotTemplateActions.changeWho, (state, { index, change }) => {
    const who = [...state.slotTemplate.who];
    who[index] = { ...state.slotTemplate.who[index], ...change };

    return { ...state, slotTemplate: { ...state.slotTemplate, who: who } };
  }),
  on(slotTemplateActions.changeWhen, (state, { change }) => {
    return { ...state, slotTemplate: { ...state.slotTemplate, when: change } };
  }),
  on(createSlotsActions.loadProductsEmptyResults, (state) => {
    return { ...state, loadingProducts: false, products: [] };
  }),
  on(createSlotsActions.loadProductsSuccess, (state, { items }) => {
    return { ...state, loadingProducts: false, products: items };
  }),
  on(createSlotsActions.loadProductsFailure, (state, { error }) => {
    return { ...state, loadingProducts: false, error: error };
  }),
  on(createSlotsActions.loadLessons, (state) => {
    return { ...state, loadingLessons: true };
  }),
  on(createSlotsActions.loadLessonsSuccess, (state, { lessons }) => {
    return { ...state, loadingLessons: false, lessons: lessons };
  }),
  on(createSlotsActions.loadLessonsFailure, (state, { error }) => {
    return { ...state, loadingLessons: false, error: error };
  }),
  on(createSlotsActions.setScheduleSearchQuery, (state, { query }) => ({
    ...state,
    foundSchedules: [],
    scheduleSearchQuery: query,
  })),
  on(createSlotsActions.loadSchedules, (state) => ({
    ...state,
    loadingSchedules: true,
  })),
  on(createSlotsActions.loadSchedulesSuccess, (state, { schedules, totalItems }) => ({
    ...state,
    loadingSchedules: false,
    foundSchedules: schedules,
    foundSchedulesTotal: totalItems,
  })),
  on(createSlotsActions.loadSchedulesEmptyResults, (state) => ({
    ...state,
    loadingSchedules: false,
    foundSchedules: [],
    foundSchedulesTotal: 0,
  })),
  on(createSlotsActions.createSlotsToCreateSuccess, (state, { slotsToCreate }) => ({
    ...state,
    slotsToCreate: slotsToCreate,
  })),
  on(createSlotsActions.updateSlotToCreate, (state, { id, changes }) => {
    const targetIndex = state.slotsToCreate.findIndex((item) => item.id === id);
    if (targetIndex < 0) {
      return state;
    }
    let target = {
      ...state.slotsToCreate[targetIndex],
      timeRange: changes.timeRange ?? state.slotsToCreate[targetIndex].timeRange,
      amountOfPupils: changes.amountOfPupils ?? state.slotsToCreate[targetIndex].amountOfPupils,
    } as SlotToCreateContract;
    let newList = [...state.slotsToCreate];
    newList[targetIndex] = target;
    return {
      ...state,
      slotsToCreate: newList,
    };
  }),
  on(createSlotsActions.removeSlotToCreate, (state, { id }) => {
    const newSlotsToCreate = [...state.slotsToCreate];
    const indexOf = state.slotsToCreate.findIndex((item) => item.id === id);
    if (indexOf < 0) {
      return state;
    }
    newSlotsToCreate.splice(indexOf, 1);
    return {
      ...state,
      slotsToCreate: newSlotsToCreate,
    };
  }),
  on(createSlotsActions.createSlot, (state) => ({ ...state, submitting: true })),
  on(createSlotsActions.createSlotFailure, (state, { error }) => ({
    ...state,
    error: error,
    submitting: false,
  })),
  on(
    createSlotsActions.createSlotSuccess,
    createSlotsActions.clear,
    (state) => initialDataAccessCreateSlotsState,
  ),
);

export function createCreateSlotsReducer(state: CreateSlotsState | undefined, action: Action) {
  return reducer(state, action);
}
