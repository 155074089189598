import { inject, Injectable, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Approach, ApproachContract } from '@scheduler-frontend/approach-contracts';
import {
  AssignmentDetailedWithAssignmentHasSlot,
  Slot,
} from '@scheduler-frontend/assignment-contracts';
import { AvailabilityTypeEnum } from '@scheduler-frontend/calendar-contracts';
import { PersonalRemark } from '@scheduler-frontend/candidate-contracts';
import { DeclineReason } from '@scheduler-frontend/data-access-decline-reasons';
import { CandidateMinimal } from '@scheduler-frontend/data-access-users';
import { ChooseableLevelEnum } from '@scheduler-frontend/enums';
import { denormalize, PlainRangeInterface } from '@techniek-team/class-transformer';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { derivedAsync } from 'ngxtension/derived-async';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { candidateAllotmentStatisticsActions } from './+state/actions/candidate-allotment-statistics.actions';
import { candidateApproachesActions } from './+state/actions/candidate-approaches.actions';
import { candidateAssignmentsFutureActions } from './+state/actions/candidate-assignments-future.actions';
import { candidateAssignmentsHistoryActions } from './+state/actions/candidate-assignments-history.actions';
import { candidateAvailabilitiesActions } from './+state/actions/candidate-availabilities.actions';
import { candidateRemarksActions } from './+state/actions/candidate-remarks.actions';
import { candidatesActions } from './+state/actions/candidates.actions';
import { CandidateApproachesSelectors } from './+state/selectors/candidate-approaches.selectors';
import { CandidateAssignmentsFutureSelectors } from './+state/selectors/candidate-assignments-future.selectors';
import { CandidateAssignmentsHistorySelectors } from './+state/selectors/candidate-assignments-history.selectors';
import { CandidateRemarksSelectors } from './+state/selectors/candidate-remarks.selectors';
import { CandidatesSearchSelectors } from './+state/selectors/candidates-search.selectors';
import { CandidatesSkillsSelectors } from './+state/selectors/candidates-skills.selectors';
import { CandidatesSelectors } from './+state/selectors/candidates.selectors';

@Injectable()
export class CandidatesStoreService {
  private readonly store = inject(Store);

  private readonly actions = inject(Actions);

  public hasActiveCandidate = this.store.selectSignal(CandidatesSelectors.hasActiveCandidate);

  public activeCandidate = this.store.selectSignal(CandidatesSelectors.activeCandidate);

  public candidateSearchQuery = this.store.selectSignal(CandidatesSearchSelectors.searchQuery);

  public activeCandidateId = this.store.selectSignal(CandidatesSelectors.activeCandidateId);

  public activeDetailedCandidate = this.store.selectSignal(
    CandidatesSelectors.activeCandidateDetails,
  );

  public activeCandidateMainLocation = this.store.selectSignal(
    CandidatesSelectors.activeCandidateMainLocation,
  );

  public loading = this.store.selectSignal(CandidatesSelectors.loading);

  public candidateSkillsPerBusinessService = this.store.selectSignal(
    CandidatesSkillsSelectors.skillPerBusinessService,
  );

  public activeCandidateSkillsBusinessServices$ = this.store
    .select(CandidatesSelectors.activeCandidateCurrentAndFutureSkillsBusinessServices)
    .pipe(isDefined());

  public activeCandidateBusinessServicesToApproachFor$ = this.store
    .select(CandidatesSelectors.activeCandidateBusinessServicesToApproachFor)
    .pipe(isDefined());

  public activeCandidateSkillsRoles$ = this.store
    .select(CandidatesSelectors.activeCandidateCurrentAndFutureSkillRoles)
    .pipe(isDefined());

  public activeCandidateRolesToApproachFor$ = this.store
    .select(CandidatesSelectors.activeCandidateRolesToApproachFor)
    .pipe(isDefined());

  public activeCandidateRolesToApproachFor = this.store.selectSignal(
    CandidatesSelectors.activeCandidateRolesToApproachFor,
  );

  public activeCandidateSkillSubjects$ = this.store
    .select(CandidatesSelectors.activeCandidateCurrentAndFutureSkillSubjects)
    .pipe(isDefined());

  public activeCandidateSubjectsToApproachFor$ = this.store
    .select(CandidatesSelectors.activeCandidateSubjectsToApproachFor)
    .pipe(isDefined());

  public activeCandidateSkillLevels$ = this.store
    .select(CandidatesSelectors.activeCandidateCurrentAndFutureSkillLevels)
    .pipe(isDefined());

  public activeCandidateLevelsToApproachFor: Signal<ChooseableLevelEnum[]> =
    this.store.selectSignal(CandidatesSelectors.activeCandidateLevelsToApproachFor);

  /** Assignments history */
  public candidateAssignmentsHistory$: Observable<AssignmentDetailedWithAssignmentHasSlot[]> =
    this.store.select(CandidateAssignmentsHistorySelectors.selectAll).pipe(
      isDefined(),
      map((assignments) => denormalize(AssignmentDetailedWithAssignmentHasSlot, assignments)),
    );

  public candidateAssignmentsHistoryLoading = this.store.selectSignal(
    CandidateAssignmentsHistorySelectors.loading,
  );

  public candidateAssignmentsHistoryLoaded = this.store.selectSignal(
    CandidateAssignmentsHistorySelectors.loaded,
  );

  public candidateAssignmentsHistoryLastChunkLoaded = this.store.selectSignal(
    CandidateAssignmentsHistorySelectors.lastChunkLoaded,
  );

  /** Assignments future */
  public candidateAssignmentsFuture$: Observable<AssignmentDetailedWithAssignmentHasSlot[]> =
    this.store.select(CandidateAssignmentsFutureSelectors.selectAll).pipe(
      isDefined(),
      map((assignments) => denormalize(AssignmentDetailedWithAssignmentHasSlot, assignments)),
    );

  public candidateAssignmentsFuture = derivedAsync(() => this.candidateAssignmentsFuture$);

  public candidateAssignmentsFutureLoading = this.store.selectSignal(
    CandidateAssignmentsFutureSelectors.loading,
  );

  public candidateAssignmentsFutureLoaded = this.store.selectSignal(
    CandidateAssignmentsFutureSelectors.loaded,
  );

  public candidateAssignmentsFutureLastChunkLoaded = this.store.selectSignal(
    CandidateAssignmentsFutureSelectors.lastChunkLoaded,
  );

  public candidateRemarks$: Observable<PersonalRemark[]> = this.store
    .select(CandidateRemarksSelectors.remarks)
    .pipe(
      isDefined(),
      map((remarks) => denormalize(PersonalRemark, remarks)),
    );

  public candidateRemarks = this.store.selectSignal(CandidateRemarksSelectors.remarks);

  public candidateLoading$: Observable<boolean> = this.store.select(
    CandidatesSelectors.selectAllLoading,
  );

  public remarksLoading = this.store.selectSignal(CandidateRemarksSelectors.loading);

  public savingRemarks$: Observable<boolean> = this.store.select(CandidateRemarksSelectors.saving);
  public savingRemarks = this.store.selectSignal(CandidateRemarksSelectors.saving);

  public approachesLoading$: Observable<boolean> = this.store.select(
    CandidateApproachesSelectors.loading,
  );

  public approachesLoaded$: Observable<boolean> = this.store.select(
    CandidateApproachesSelectors.loaded,
  );

  public approachesLastChunkLoaded$: Observable<boolean> = this.store.select(
    CandidateApproachesSelectors.lastChunkLoaded,
  );

  public candidateApproaches$: Observable<Approach[]> = this.store
    .select(CandidateApproachesSelectors.approaches)
    .pipe(
      isDefined(),
      map((approaches: ApproachContract[]) => {
        return approaches.map((approach: ApproachContract) => denormalize(Approach, approach));
      }),
    );

  public setActiveCandidate(candidateId: string | null): void {
    this.store.dispatch(
      candidatesActions.setActiveCandidate({
        selectedId: candidateId,
      }),
    );
  }

  public clearActiveCandidate(): void {
    this.store.dispatch(candidatesActions.clearActiveCandidate());
  }

  public refreshCandidate(): void {
    this.store.dispatch(candidatesActions.refreshActiveCandidate());
  }

  public refreshRemarks(): void {
    this.store.dispatch(candidateRemarksActions.refreshActiveCandidateRemarks());
  }

  public addCandidateRemark(remark: string): void {
    this.store.dispatch(candidateRemarksActions.addCandidateRemark({ remark: remark }));
  }

  public async nextCandidateAssignmentsHistory(): Promise<void> {
    if (!(await firstEmitFrom(toObservable(this.candidateAssignmentsHistoryLastChunkLoaded)))) {
      this.store.dispatch(candidateAssignmentsHistoryActions.loadNextChunk());
      return firstEmitFrom(
        this.actions.pipe(ofType(candidateAssignmentsHistoryActions.loadNextChunkSuccess)),
      );
    }
  }

  public async nextCandidateAssignmentsFuture(): Promise<void> {
    if (!(await firstEmitFrom(toObservable(this.candidateAssignmentsHistoryLastChunkLoaded)))) {
      this.store.dispatch(candidateAssignmentsFutureActions.loadNextChunk());
      return firstEmitFrom(
        this.actions.pipe(ofType(candidateAssignmentsFutureActions.loadNextChunkSuccess)),
      );
    }
  }

  public async setCandidateAvailability(options: {
    timePeriod: PlainRangeInterface;
    candidateId: string;
    availabilityValue: AvailabilityTypeEnum;
    remarks?: string | null;
  }): Promise<void> {
    this.store.dispatch(candidateAvailabilitiesActions.setCandidateAvailability(options));
    const action = await Promise.race([
      firstEmitFrom(
        this.actions.pipe(ofType(candidateAvailabilitiesActions.setCandidateAvailabilitySuccess)),
      ),
      firstEmitFrom(
        this.actions.pipe(ofType(candidateAvailabilitiesActions.setCandidateAvailabilityFailure)),
      ),
    ]);

    if (action && typeof action === 'object' && 'error' in action) {
      return Promise.reject(action.error);
    }

    return Promise.resolve();
  }

  public removeCandidateAvailability(id: string) {
    this.store.dispatch(candidateAvailabilitiesActions.removeAvailability({ id: id }));
  }

  public async nextCandidateApproaches(): Promise<void> {
    if (!(await firstEmitFrom(this.approachesLastChunkLoaded$))) {
      this.store.dispatch(candidateApproachesActions.loadNextChunk());
      return firstEmitFrom(
        this.actions.pipe(ofType(candidateApproachesActions.loadNextChunkSuccess)),
      );
    }
  }

  public markCandidateRejections(
    declineReason: string | DeclineReason,
    slots: (string | Slot)[],
    candidate?: string | CandidateMinimal,
  ): void {
    if (slots[0] instanceof Slot) {
      slots = (slots as Slot[]).map((slot) => slot.getId());
    }
    this.store.dispatch(
      candidatesActions.markCandidateRejection({
        candidate: typeof candidate === 'string' ? candidate : candidate?.getId(),
        declineReason: typeof declineReason === 'string' ? declineReason : declineReason.getId(),
        slots: slots as string[],
      }),
    );
  }

  public clearAllCandidateAllotmentStatistics(): void {
    this.store.dispatch(candidateAllotmentStatisticsActions.clearAllCandidateAllotmentStatistics());
  }

  public openLongTermAvailabilityModal(): void {
    this.store.dispatch(candidatesActions.openLongTermAvailabilityModal());
  }
}
