import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { schedulingActions } from '@scheduler-frontend/data-access-scheduling';
import { jsonLdSelectId, NonNullableDictionary } from '@techniek-team/tt-ngrx';
import { exhaustMap, filter, map, takeUntil } from 'rxjs';
import { candidatesByRankingActions } from '../candidates-by-ranking.actions';
import { CandidatesByRankingSelectors } from '../candidates-by-ranking.selectors';

@Injectable()
export class UpdateSchedulingStoreEffect {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly destroyRef = inject(DestroyRef);

  public startLoadingCandidates = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidatesByRankingActions.initCandidatesByRanking,
        candidatesByRankingActions.refreshCandidatesByRanking,
        candidatesByRankingActions.loadNextChunk,
      ),
      map(() => schedulingActions.startLoadingCandidates()),
    ),
  );

  public setCandidateList = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidatesByRankingActions.loadCandidatesByRankingSuccess,
        candidatesByRankingActions.loadNextChunkSuccess,
      ),
      concatLatestFrom(() => [this.store.select(CandidatesByRankingSelectors.candidateIds)]),
      takeUntilDestroyed(this.destroyRef),
      filter(([_action, candidateIds]) => !!candidateIds),
      map(([_action, candidateIds]) => {
        return schedulingActions.setCandidateList({
          items: candidateIds,
        });
      }),
    ),
  );

  public setLoadingCandidateFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.loadCandidatesByRankingFailure),
      map(() => schedulingActions.setCandidateListFailure()),
    ),
  );

  public startLoadingSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.initLoadSlotByCandidate),
      exhaustMap(() =>
        this.actions$.pipe(
          ofType(schedulingActions.selectCandidates),
          map(() => {
            return schedulingActions.startLoadingSlots();
          }),
          takeUntil(this.actions$.pipe(ofType(candidatesByRankingActions.stopLoadSlotByCandidate))),
        ),
      ),
    ),
  );

  public setSlotList = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.loadSlotsByCandidateSuccess),
      filter((action) => !!action.items),
      map((action) =>
        schedulingActions.setSlotList({
          items: action.items.map((item) => jsonLdSelectId(item.slot['@id'])) as string[],
        }),
      ),
    ),
  );

  public setLoadingSlotsFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.loadSlotByCandidateFailure),
      map(() => schedulingActions.setSlotListFailure()),
    ),
  );

  public setAlternativeSlotList = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.loadAlternativeShiftsSuccess),
      map((action) =>
        schedulingActions.setAlternativeSlotList({
          items: Object.entries(action.items as { [s: string]: SlotDetailedContract[] }).reduce<
            NonNullableDictionary<string[]>
          >((dict, item) => {
            dict[item[0] as string] = item[1].map((slot) => jsonLdSelectId(slot) as string);
            return dict;
          }, {}),
        }),
      ),
    ),
  );

  public clearAlternativeSlotListWhenStopShowingAlternativeShifts = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.stopShownAlternativeShifts),
      map(() => schedulingActions.setAlternativeSlotList({ items: undefined })),
    ),
  );
}
