import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ScheduleContract, ScheduleDetailedContract } from '@scheduler-frontend/schedule-contracts';

export const schedulesActions = createActionGroup({
  source: '[Schedules]',
  events: {
    selectedProductTypeAtLocation: props<{ productTypeId: string[] }>(),
    deselectedProductTypeAtLocation: props<{ productTypeId: string }>(),
    loadLocationSchedules: emptyProps(),
    reloadLocationSchedules: emptyProps(),
    loadLocationSchedulesSuccess: props<{
      schedules: ScheduleContract[];
      location: string;
      totalItems: number;
      query?: string;
    }>(),
    loadFromCacheLocationSchedulesSuccess: props<{
      schedules: ScheduleContract[];
      location: string;
      totalItems: number;
      cacheTimestamp: string; // iso 8601 date
    }>(),
    loadLocationSchedulesFailure: props<{ error: unknown }>(),
    setSearchQuery: props<{ query?: string }>(),
    setSelectedSchedule: props<{ schedule?: string }>(),

    /**
     * Mercure updates trigger these actions.
     */
    scheduleCreated: props<{ schedule: ScheduleContract }>(),
    scheduleUpdated: props<{ schedule: ScheduleDetailedContract }>(),
    scheduleDeleted: props<{ schedule: string }>(),
  },
});
