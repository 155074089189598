import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CREATE_SLOTS_FEATURE_KEY, CreateSlotsState } from '../reducers/create-slots.reducer';

export class LessonsByScheduleSelectors {
  public static readonly lessonsState =
    createFeatureSelector<CreateSlotsState>(CREATE_SLOTS_FEATURE_KEY);

  public static readonly loading = createSelector(
    LessonsByScheduleSelectors.lessonsState,
    (state) => state.loadingLessons,
  );

  public static readonly lessons = createSelector(
    LessonsByScheduleSelectors.lessonsState,
    (state) => {
      return state.lessons;
    },
  );
}
