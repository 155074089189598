import { isAfter, setDate, startOfDay, subMonths } from 'date-fns';

/**
 * Returns the booking's period closing date for the given date.
 */
export function getBookingPeriodForDate(date: Date): Date {
  if (isAfter(date, setDate(startOfDay(date), 6))) {
    return setDate(startOfDay(date), 6);
  }
  return setDate(subMonths(startOfDay(date), 1), 6);
}
