import { Signal } from '@angular/core';
import { BusinessEntity } from '@scheduler-frontend/data-access-business-entities';
import { ProductType } from '@scheduler-frontend/data-access-product-types';
import { LazyJsonLd as JsonLd, SignalFetch } from '@techniek-team/fetch';
import { Exclude, Expose, Type } from 'class-transformer';

/**
 * Standard version of the BusinessService resource from Scheduler-api.
 */
export class BusinessService extends JsonLd {
  /**
   * @inheritDoc
   */
  @Exclude() public override readonly className: string = 'BusinessService';

  /**
   * The name of the service.
   *
   * This is a product(service) our company(s) supplies to customers.
   */
  @Expose() public name!: string;

  @Expose() public hasAssignmentReviews: boolean = false;

  /**
   * The company under which we supply this service.
   */
  @Type(() => BusinessEntity)
  @Expose()
  public businessEntity!: BusinessEntity;

  @Type(() => Date)
  @Expose()
  public archivedAt?: Date;

  @SignalFetch(() => ProductType)
  @Expose()
  public productType!: Signal<ProductType | undefined>;

  //eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.name} (${this.businessEntity.toString()})`;
  }
}
