import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { NonNullableDictionary } from '@techniek-team/tt-ngrx';

export const schedulingActions = createActionGroup({
  source: '[Scheduling/Scheduling]',
  events: {
    reset: emptyProps(),
    clearAllSelections: emptyProps(),

    //>== select slot
    /**
     * Select/deselect all slots within this assignment.
     */
    selectSlotsInAssignment: props<{ assignment: string }>(),
    deselectSlotsInAssignment: props<{ assignment: string }>(),
    /**
     * Select/deselect given slots to add them to the list to approach/schedule.
     * Note: On the approach-assign-page this is the list of slots to assign.
     */
    selectSlots: props<{ items: string[] }>(),
    deselectSlots: props<{ items: string[] }>(),
    selectAllSlots: emptyProps(),
    clearSelectedSlots: emptyProps(),
    //<== select slot

    //>== reject slot
    /**
     * Select/deselect all slots within this assignment to reject.
     */
    selectSlotsInAssignmentForRejection: props<{ assignment: string }>(),
    deselectSlotsInAssignmentForRejection: props<{ assignment: string }>(),
    /**
     * Select/deselect given slots to add them to the list to reject.
     */
    selectSlotsForRejection: props<{ items: string[] }>(),
    deselectSlotsForRejection: props<{ items: string[] }>(),
    clearSelectedSlotsForRejection: emptyProps(),
    //<== reject slot

    //>== select candidate
    /**
     * The selected candidates when scheduling.
     * Currently, we only support one candidate. In the future we might expand
     * this to be multiple candidates when inviting them for a slot selection.
     */
    selectCandidates: props<{ items: string[] }>(),
    deselectCandidates: props<{ items: string[] }>(),
    clearSelectedCandidates: emptyProps(),
    //<== select candidate

    /**
     * The actions isn't used by the scheduling store self but can be used in other stores to load the next chunk of
     * data Since the views are schedule base agnostic it doesn't know what endpoint to should trigger to get the next
     * chunks.
     *
     * TODO this isn't used yet and should be add to the various views
     */
    nextChunk: emptyProps(),
    nextChunkSuccess: emptyProps(),

    //>== slot list
    /**
     * sets loading slots to true until setSlotList have been called
     */
    startLoadingSlots: emptyProps(),
    /**
     * This is the list of currently loaded slot that are visible in views (maybe after a bit of scrolling up)
     */
    setSlotList: props<{ items: string[] }>(),
    /**
     * Sets loading slot to false in case of an failure
     */
    setSlotListFailure: emptyProps(),
    //<== slot list

    //>== Alternative slot list
    /**
     * Some page show a alternative slot in case another slot is already assigned. These alternatives are stored in
     * this list
     */
    setAlternativeSlotList: props<{ items: NonNullableDictionary<string[]> | undefined }>(),
    //<== Alternative slot list

    //>== candidate list
    /**
     * sets loading candidates to true until setSlotList have been called
     */
    startLoadingCandidates: emptyProps(),
    /**
     * This is the list of currently loaded candidates that are visible in views (maybe after a bit of scrolling up)
     */
    setCandidateList: props<{ items: string[] }>(),
    /**
     * Sets loading candidates to false in case of an failure
     */
    setCandidateListFailure: emptyProps(),
    //<== candidate list

    openScheduleDetails: props<{ schedule: string }>(),

    setTimeRangeInView: props<{ range: PlainRangeInterface<string> }>(),
  },
});
