import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ProductTypeContract,
  ProductTypesSelectors,
} from '@scheduler-frontend/data-access-product-types';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { groupSchedulesByProductType } from '../functions/group-schedules-by-product-type.function';
import { SCHEDULES_FEATURE_KEY, schedulesAdapter, SchedulesState } from './schedules.reducer';

const { selectAll, selectEntities } = schedulesAdapter.getSelectors();

export class SchedulesSelectors {
  public static readonly state = createFeatureSelector<SchedulesState>(SCHEDULES_FEATURE_KEY);

  public static readonly loading = createSelector(
    SchedulesSelectors.state,
    (state: SchedulesState) => state.loading,
  );

  public static readonly searchQuery = createSelector(
    SchedulesSelectors.state,
    (state: SchedulesState) => state.query,
  );

  public static readonly selectedScheduleId = createSelector(
    SchedulesSelectors.state,
    (state: SchedulesState) => state.selectedSchedule,
  );

  public static readonly loaded = createSelector(
    SchedulesSelectors.state,
    (state: SchedulesState) => state.loaded,
  );

  public static readonly cacheTimestamp = createSelector(
    SchedulesSelectors.state,
    (state: SchedulesState) => state.cacheTimeStamp,
  );

  public static readonly error = createSelector(
    SchedulesSelectors.state,
    (state: SchedulesState) => state.error,
  );

  public static readonly schedules = createSelector(
    SchedulesSelectors.state,
    (state: SchedulesState) => selectAll(state),
  );

  public static readonly scheduleEntities = createSelector(
    SchedulesSelectors.state,
    (state: SchedulesState) => selectEntities(state),
  );

  public static readonly groupedByProductType = createSelector(
    SchedulesSelectors.schedules,
    (schedules) => groupSchedulesByProductType(schedules),
  );

  public static readonly productTypesAtLocation = createSelector(
    SchedulesSelectors.groupedByProductType,
    ProductTypesSelectors.productTypeEntities,
    (groupedByProductType, productTypes) => {
      return Object.keys(groupedByProductType)
        .map((productTypeId) => {
          return productTypes[jsonLdSelectId(productTypeId)];
        })
        .filter((item): item is ProductTypeContract => item !== undefined);
    },
  );

  public static readonly selectedProductTypesAtLocation = createSelector(
    SchedulesSelectors.state,
    (state) => state.selectedProductTypesAtLocation,
  );

  public static readonly selectedProductTypesAtLocationDict = createSelector(
    SchedulesSelectors.selectedProductTypesAtLocation,
    (selected) => {
      const dict: Dictionary<boolean> = {};
      for (let item of selected) {
        dict[item] = true;
      }
      return dict;
    },
  );

  public static readonly schedulesFilterGroupKey = 'slot.schedule';

  public static readonly searchQueryMapper = createSelector(
    SchedulesSelectors.scheduleEntities,
    (dict) => {
      return {
        schedulesFilterGroupKey: {
          entities: dict,
          getDisplayText: (item: ScheduleContract) => item.name,
        },
      };
    },
  );

  public static readonly selectedSchedule = createSelector(
    SchedulesSelectors.scheduleEntities,
    SchedulesSelectors.selectedScheduleId,
    (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
  );

  public static readonly productTypeGroupWithSelectedSchedule = createSelector(
    SchedulesSelectors.selectedSchedule,
    SchedulesSelectors.groupedByProductType,
    ProductTypesSelectors.productTypeEntities,
    (selectedSchedule, groupedByProductType, productTypes) => {
      if (selectedSchedule) {
        for (let productType in groupedByProductType) {
          if (groupedByProductType[productType]?.includes(selectedSchedule)) {
            return productTypes[jsonLdSelectId(productType)];
          }
        }
      }
      return undefined;
    },
  );
}
