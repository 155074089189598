import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutesView } from '@scheduler-frontend/scheduling-common';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { format } from 'date-fns';
import {
  SCHEDULING_VIEW_FEATURE_KEY,
  SchedulingViewState,
} from '../reducers/scheduling-view.reducer';

export class SchedulingViewSelectors {
  private static readonly state = createFeatureSelector<SchedulingViewState>(
    SCHEDULING_VIEW_FEATURE_KEY,
  );

  public static readonly timeRangeInView = createSelector(
    SchedulingViewSelectors.state,
    (state) => {
      return state.timeRangeInView;
    },
  );

  public static readonly inlineAssignButtonsIsVisible = createSelector(
    SchedulingViewSelectors.state,
    (state) => state.showInlineAssignButtons,
  );

  public static readonly slotSelectionCheckBoxIsVisible = createSelector(
    SchedulingViewSelectors.state,
    (state) => state.showSlotSelectionCheckbox,
  );

  public static readonly onlyShowSelectedSlotsInView = createSelector(
    SchedulingViewSelectors.state,
    (state) => state.showOnlySelectedSlotsInView,
  );

  public static readonly currentView = createSelector(
    SchedulingViewSelectors.state,
    (state) => state.currentView,
  );

  public static readonly timeRangeInViewReadable = createSelector(
    SchedulingViewSelectors.currentView,
    SchedulingViewSelectors.timeRangeInView,
    (currentView, timeRangeInView) => {
      const dayFormat: string = 'EEEEEE d MMM yyyy';

      if (currentView === RoutesView.DAY) {
        return format((timeRangeInView as PlainRangeInterface<string>).start, dayFormat);
      }

      if (currentView === RoutesView.WEEK) {
        return (
          format((timeRangeInView as PlainRangeInterface<string>).start, dayFormat) +
          ' - ' +
          format((timeRangeInView as PlainRangeInterface<string>).end, dayFormat)
        );
      }

      return undefined;
    },
  );
}
