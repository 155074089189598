import { Signal } from '@angular/core';
import { SignalFetch } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';
import { BusinessServiceDetailed } from '@scheduler-frontend/data-access-business-services';
import { Role } from './role.model';

/**
 * standard version of the Role resource from Scheduler-api
 */
export class RoleDetailed extends Role {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'RoleDetailed';

  /**
   * The service where the role belongs to.
   */
  @SignalFetch(() => BusinessServiceDetailed)
  @Expose()
  public override businessService!: Signal<BusinessServiceDetailed | undefined>;

  //eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
