import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { formatISO, isAfter, subDays } from 'date-fns';
import { catchError, exhaustMap, from, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessEntityApi } from './api/business-entity.api';
import { businessEntitiesActions } from './business-entities.actions';
import { BUSINESS_ENTITIES_FEATURE_KEY } from './business-entities.reducer';

@Injectable()
export class BusinessEntitiesEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly storage = inject(Storage);

  private readonly store = inject(Store);

  private readonly businessEntityApi = inject(BusinessEntityApi);

  public createBusinessEntitiesFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(businessEntitiesActions.loadBusinessEntitiesFailure, {
          message: 'Er is iets misgegaan bij het laden van alle diensten.',
        }),
      ),
    { dispatch: false },
  );

  public createSaveBusinessEntitiesToStorageEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(businessEntitiesActions.loadBusinessEntitiesSuccess),
        tap((action) => {
          if (action.businessEntities.length === 0) {
            return;
          }
          return from(
            Promise.all([
              this.storage.set(`${BUSINESS_ENTITIES_FEATURE_KEY}-store-cache`, {
                items: action.businessEntities,
                timestamp: new Date(),
              }),
            ]),
          );
        }),
      ),
    { dispatch: false },
  );

  public initBusinessEntities = createEffect(() =>
    this.actions$.pipe(
      ofType(businessEntitiesActions.initBusinessEntities),
      switchMap(() =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() => {
            return from(this.storage.get(`${BUSINESS_ENTITIES_FEATURE_KEY}-store-cache`)).pipe(
              exhaustMap((cache) => {
                if (cache && isAfter(cache.timestamp, subDays(new Date(), 1))) {
                  return of(
                    businessEntitiesActions.loadFromCacheBusinessEntitiesSuccess({
                      businessEntities: cache.items,
                      totalItems: cache.items.length,
                      cacheTimestamp: formatISO(cache.timestamp),
                    }),
                  );
                }
                return this.businessEntityApi.getBusinessEntities().pipe(
                  map((response) => {
                    return businessEntitiesActions.loadBusinessEntitiesSuccess({
                      businessEntities: response['hydra:member'],
                      totalItems: response['hydra:totalItems'],
                    });
                  }),
                  catchError((error) =>
                    of(businessEntitiesActions.loadBusinessEntitiesFailure({ error: error })),
                  ),
                );
              }),
            );
          }),
        ),
      ),
    ),
  );

  public reloadBusinessEntities = createEffect(() =>
    this.actions$.pipe(
      ofType(businessEntitiesActions.reloadBusinessEntities),
      switchMap(() =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() =>
            this.businessEntityApi.getBusinessEntities().pipe(
              map((response) => {
                return businessEntitiesActions.loadBusinessEntitiesSuccess({
                  businessEntities: response['hydra:member'],
                  totalItems: response['hydra:totalItems'],
                });
              }),
              catchError((error) =>
                of(businessEntitiesActions.loadBusinessEntitiesFailure({ error: error })),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  public ngrxOnInitEffects() {
    return businessEntitiesActions.initBusinessEntities();
  }
}
