/** @format **/
import { HttpClient } from '@angular/common/http';
import { computed, EnvironmentInjector, inject, isSignal, runInInjectionContext, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { denormalize, JsonLd } from '@techniek-team/class-transformer';
import { TtFetchConfig } from '../tt-fetch-config';
import { isDefined } from '@techniek-team/rxjs';
import { ClassConstructor, Transform, TransformationType } from 'class-transformer';
import 'reflect-metadata';
import {
  find,
  from,
  merge,
  Observable,
  of,
  ReplaySubject,
  share,
  switchMap,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  FetchStorageInterface,
  UpdatableFetchStorageInterface,
} from '../storage/fetch-storage.interface';


/**
 * This function can be used as a Decorator. It can be used to lazy loaded a property from the server when needed.
 * It also first looks at the {@see CacheService} for the object, retrieving it only once.
 *
 * The class should have a Generic type `<Lazy = FetchObservable<unknown>>` This way state that it's an lazyLoaded
 * object. Which means that all properties with the @Fetch decorator are {@see FetchObservable} to which you can
 * subscribe to get the object or from which you can retrieve the iri through {@see FetchObservable.getIri }
 *
 * When you can to retrieve the object and want all the properties to be eagerLoaded at forhand can you use the
 * {@see JsonLd.fetchAll} which you should implement youself in the model (see example below). It should return the
 * same model but with the generic Model<EagerLoaded>. When returning this generic all properties with the @Fetch
 * decorator are typed with the models they should contain. For each use see the {@see eagerLoad } rxjs Operator.
 *
 * Important the {@see TtFetchModule } should be loaded before using this decorator, or you should set the
 * {@see HttpClient}, {@see CacheService}, BaseUrl and debug property with {@see Reflect.setMetaData} (see second
 * example) If not set the decorator wil throw an error.
 *
 * If a request returns an error, to prevent every Zone action from calling the same request again, a fallback object
 * can be supplied that will be returned instead. This should match the structure of the ClassConstructor object.
 */

//eslint-disable-next-line @typescript-eslint/naming-convention,max-lines-per-function
export function SignalFetch<T extends JsonLd>(
  instanceType: () => ClassConstructor<T>,
  fallback?: Object,
): PropertyDecorator {
  return Transform(
    ({ value, obj, key, type, options }) => {
      /* istanbul ignore next */
      if (type !== TransformationType.PLAIN_TO_CLASS || options.ignoreDecorators) {
        return value;
      }

      if (!value) {
        return computed(() => value);
      }

      const environmentInjector = Reflect.getMetadata('environmentInjector', SignalFetch);
      const fetcher =  runInInjectionContext(environmentInjector, () => {
        return new Fetcher<T>(instanceType(), environmentInjector);
      });

      if (isSignal(value)) {
        return fetcher.modifyToSignal<T>(obj[key], obj, fallback);
      }

      /* istanbul ignore next */
      if (typeof value !== 'string') {
        /* istanbul ignore next */
        console.warn("This should not be possible WTF are you doing!?");
        /* istanbul ignore next */
        return value;
      }


      return fetcher.modifyToSignal<T>(value, obj, fallback);
    },
    { toClassOnly: true },
  );
}

/**
 * This is an internal class used by the {@see Fetch} Decorator.
 * To modify the object into an {@see FetchObservable}
 */
class Fetcher<T extends JsonLd> {
  protected readonly httpClient = inject(HttpClient);

  protected readonly config = inject(TtFetchConfig);

  protected readonly storages: FetchStorageInterface<unknown, unknown>[];

  protected readonly supportedStorages: FetchStorageInterface<unknown, unknown>[];

  constructor(public readonly instanceType: ClassConstructor<T>, private environmentInjector: EnvironmentInjector) {
    this.storages = this.config.storageServices?.map(storage => inject(storage)) ?? [];
    this.supportedStorages = (this.storages ?? []).filter((storage) =>
      storage.supportsFetch(this.typeName),
    );
  }

  private get instance(): T {
    //eslint-disable-next-line new-cap
    return new this.instanceType();
  }

  private get typeName(): string {
    return this.instance.getClassName();
  }

  public modifyToSignal<O>(
    value: string,
    obj: O,
    fallback?: Object,
  ): Signal<T | undefined> {
    let observable = this.waitForInitialization().pipe(
      switchMap(() =>
        merge(
          ...this.createStorageObservables(value, obj, fallback),
          this.retrieveObjectMissingFromCache(value, fallback),
        ).pipe(
          find(({ item }) => !!item),
          tap((item) => {
            const error = new Error("Fetch decorator couldn't find the model");
            return !item ? error : undefined;
          }),
          isDefined(),
          tap(({ item, isFallback }) => this.addFetchedFallbackToStorage(item, isFallback)),
          map(({ item, isFallback }) => {
            return (item instanceof JsonLd ? item : denormalize(this.instanceType, item)) as T;
          }),
        ),
      ),
    );

    return toSignal(observable, { rejectErrors: true, injector: this.environmentInjector });
  }

  private waitForInitialization() {
    return from(
      Promise.all(this.supportedStorages.map((storage) => storage.waitForInitialization())),
    );
  }

  private addFetchedFallbackToStorage(item: T, isFallback: boolean) {
    if (isFallback) {
      for (let storage of this.supportedStorages) {
        if (this.isUpdatable(storage)) {
          storage.addFetchedItem(this.typeName, item);
        }
      }
    }
  }

  private isUpdatable(
    storage: FetchStorageInterface<unknown, unknown>,
  ): storage is UpdatableFetchStorageInterface<unknown, unknown> {
    return storage.hasOwnProperty('addFetchedItem');
  }

  private createStorageObservables<O>(value: string, obj: O, fallback?: Object) {
    return this.supportedStorages.map((storage) => {
      return storage.getFetchFromStorage(value, obj, this.typeName, fallback).pipe(
        isDefined(),
        map((result) => ({ item: result as T, isFallback: false })),
      );
    });
  }

  /**
   * If the requested content is not yet stored in cache, the content is retrieved first. If a fallback object is given,
   * the returned content will always be defined, even if an HttpError is returned by the HttpClient.
   */
  private retrieveObjectMissingFromCache(
    value: string,
    fallback?: Object,
  ): Observable<{ item: T; isFallback: boolean }> {
    return timer(500).pipe(
      switchMap(() =>
        this.httpClient.get(this.config.baseUrl + value).pipe(
          map((response) => denormalize(this.instanceType, response)),
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: false,
          }),
          catchError((error) => {
            if (fallback) {
              return of(fallback as T);
            }
            return throwError(() => error);
          }),
          map((result) => ({ item: result, isFallback: true })),
        ),
      ),
    );
  }
}
