import { createFeatureSelector, createSelector } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { Filter, FilterGroup } from '@techniek-team/search';
import { jsonLdSelectId, toDictionary } from '@techniek-team/tt-ngrx';
import { LocationContract } from '../contracts/location.contract';
import { LocationModel } from '../models/location.model';
import { LOCATIONS_FEATURE_KEY, locationsAdapter, LocationsState } from './locations.reducer';

// Lookup the 'Locations' feature state managed by NgRx
const { selectAll, selectEntities } = locationsAdapter.getSelectors();

export class LocationsSelectors {
  public static readonly selectLocationsState =
    createFeatureSelector<LocationsState>(LOCATIONS_FEATURE_KEY);

  public static readonly locationsLoading = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.loading,
  );

  public static readonly locationsLoaded = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.loaded,
  );

  public static readonly locationTypesLoaded = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.locationTypesLoaded,
  );

  public static readonly archivedLocationTypesLoaded = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.archivedLocationTypesLoaded,
  );

  public static readonly locationsInitialized = createSelector(
    LocationsSelectors.selectLocationsState,
    (state) => state.loaded || state.loading,
  );

  public static readonly locationsError = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.error,
  );

  public static readonly allLocations = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => selectAll(state),
  );

  public static readonly activeLocations = createSelector(
    LocationsSelectors.allLocations,
    (locations) => locations.filter((location) => !location.archivedAt),
  );

  public static readonly locationsEntities = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => selectEntities(state),
  );

  public static readonly denormalizedLocationEntities = createSelector(
    LocationsSelectors.selectLocationsState,
    (state) =>
      toDictionary(denormalize(LocationModel, Object.values(state.entities)), (item) =>
        item.getId(),
      ),
  );

  public static readonly currentLocationId = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state?.selectedId,
  );

  public static readonly currentPupilsPrivateLocations = createSelector(
    LocationsSelectors.selectLocationsState,
    (state: LocationsState) => state.currentPupilPrivateLocations,
  );

  public static readonly currentLocation = createSelector(
    LocationsSelectors.locationsEntities,
    LocationsSelectors.currentLocationId,
    (entities, selectedId) => {
      return selectedId ? entities[selectedId] : undefined;
    },
  );

  public static readonly count = createSelector(
    LocationsSelectors.allLocations,
    (locations) => locations?.length ?? 0,
  );

  public static readonly locationsFilterGroupKey = 'lesson.location';

  public static readonly locationsFilterGroup = createSelector(
    LocationsSelectors.activeLocations,
    (subject) => {
      const getDisplayName = (item: LocationContract) => {
        if (item.name && item.name.replace(/\s/g, '')) {
          return item.name;
        }
        return jsonLdSelectId(item['@id']);
      };
      return new FilterGroup(
        LocationsSelectors.locationsFilterGroupKey,
        (subject ?? []).map(
          (item) =>
            new Filter(item['@id'] as string, {
              label: getDisplayName(item),
              additionalData: item,
            }),
        ),
        { multiple: true, label: 'Locaties', itemLabel: 'Locatie' },
      );
    },
  );

  public static readonly searchQueryMapper = createSelector(
    LocationsSelectors.locationsEntities,
    (dict) => {
      return {
        'lesson.location': {
          entities: dict,
          getDisplayText: (item: LocationContract) => {
            if (item.name && item.name.replace(/\s/g, '')) {
              return item.name;
            }
            return jsonLdSelectId(item['@id']);
          },
        },
      };
    },
  );
}
