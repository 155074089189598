import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { TransitionContract } from '../../contracts/transition.contract';
import { activeAssignmentActions } from '../actions/active-assignment.actions';
import { assignmentTransitionsActions } from '../actions/assignment-transitions.actions';

export const ASSIGNMENT_TRANSITIONS_FEATURE_KEY = 'AssignmentTransitions';

export interface AssignmentTransitionsState extends EntityState<TransitionContract> {
  loaded: boolean;

  loading: boolean;

  error?: unknown | null;
}

export const adapter: EntityAdapter<TransitionContract> = createEntityAdapter<TransitionContract>({
  selectId: (transition: TransitionContract) => transition.name,
});

export const initialState: AssignmentTransitionsState = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
});

const reducer = createReducer(
  initialState,
  on(activeAssignmentActions.setActiveAssignment, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(assignmentTransitionsActions.loadTransitionsSuccess, (state, { transitions }) =>
    adapter.setAll(transitions, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),
  ),
  on(assignmentTransitionsActions.loadTransitionsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
);

export function assignmentTransitionsReducer(
  state: AssignmentTransitionsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
