import { Expose, Type } from 'class-transformer';
import { ApproachFilters } from './approach-filters.model';
import { ApproachHasSlot } from './approach-has-slot.model';
import { Approach } from './approach.model';

export class ApproachDetailed extends Approach {
  @Type(() => ApproachHasSlot)
  @Expose()
  public approachHasSlots!: ApproachHasSlot[];

  @Type(() => ApproachFilters)
  @Expose()
  public filters!: ApproachFilters;
}
