import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import {
  objectToSearchExpression,
  searchExpressionToObject,
} from '@scheduler-frontend/search-expression';
import { Resource } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Filters } from '../../contract/search.contract';
import { UserSearchContract } from '../../contract/user-search.contract';
import {
  GetHashResponseV1,
  GetHashResponseV3,
  GetUserSearchesResponseV1,
  GetUserSearchesResponseV3,
} from './search.response';

function convertGetUserSearchesResponseV1ToV3(
  response: GetUserSearchesResponseV1,
): GetUserSearchesResponseV3 {
  return {
    isFavorite: response.isFavorite,
    lastQueriedAt: response.lastQueriedAt,
    search: convertGetHashResponseV1ToV3(response.search),
  };
}
function convertGetHashResponseV1ToV3(response: GetHashResponseV1): GetHashResponseV3 {
  return {
    hash: response.hash,
    metaData: {
      query: response.query,
      filters: searchExpressionToObject(response.query),
    },
  };
}

@Injectable({
  providedIn: 'root',
})
export class SearchApi {
  protected readonly http = inject(HttpClient);

  /**
   * Create a search-hash which matches a search query. This can easily be shared between users.
   */
  public createSearchHash(
    filters: Filters,
    isUserInitiated: boolean = true,
    query?: string,
  ): Observable<GetHashResponseV3> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/search`;
    query = query ? query : objectToSearchExpression(filters);
    const params: HttpParams = new HttpParams().appendAll({
      search: query,
      isUserInitiated: isUserInitiated ? '1' : '0',
    });
    return this.http.get<{ token: string }>(url, { params: params }).pipe(
      map((object) => ({
        hash: object.token,
        metaData: {
          query: query,
          filters: filters,
        },
      })),
    );
  }

  /**
   * Return a query and labels(if any) corresponding to the given search hash
   */
  public getHash(hash: string): Observable<GetHashResponseV3> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/searches/${hash}`;

    return this.http
      .get<GetHashResponseV1>(url)
      .pipe(map((response) => convertGetHashResponseV1ToV3(response)));
  }

  /**
   * returns a list of recently executed searches.
   */
  public getRecentSearches(): Observable<GetUserSearchesResponseV3[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/search/recents`;

    return this.http
      .get<GetUserSearchesResponseV1[]>(url)
      .pipe(map((response) => response.map((item) => convertGetUserSearchesResponseV1ToV3(item))));
  }

  /**
   * returns a list of favorite executed searches.
   */
  public getFavoriteSearches(): Observable<GetUserSearchesResponseV3[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/search/favorites`;

    return this.http
      .get<Resource<GetUserSearchesResponseV1>[]>(url)
      .pipe(map((response) => response.map((item) => convertGetUserSearchesResponseV1ToV3(item))));
  }

  /**
   * Marks the given search hash as favorite search depending on the favorite parameter.
   */
  public setFavoriteSearch(search: UserSearchContract): Observable<GetUserSearchesResponseV3> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/search/${
      search.search.hash
    }/favorite/${search.isFavorite ? 0 : 1}`;

    return this.http.get<void>(url).pipe(
      map(() => ({
        ...search,
        isFavorite: !search.isFavorite,
      })),
    );
  }
}
