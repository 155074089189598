import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CandidateHasSkillsContract } from '@scheduler-frontend/candidate-contracts';
import {
  BusinessServiceContract,
  BusinessServicesSelectors,
} from '@scheduler-frontend/data-access-business-services';
import { RolesSelectors } from '@scheduler-frontend/data-access-roles';
import { SubjectsSelectors } from '@scheduler-frontend/data-access-subjects';
import { jsonLdSelectId, NonNullableDictionary } from '@techniek-team/tt-ngrx';
import { createCandidatePerBusinessService } from '../../functions/create-candidate-per-business-service.function';
import {
  candidateAdapter,
  CANDIDATES_FEATURE_KEY,
  CandidatesState,
} from '../reducers/candidates.reducer';
import { CandidatesSelectors } from './candidates.selectors';

const { selectEntities, selectAll } = candidateAdapter.getSelectors();

export class CandidatesSkillsSelectors {
  public static readonly state = createFeatureSelector<CandidatesState>(CANDIDATES_FEATURE_KEY);

  public static readonly skills = createSelector(
    CandidatesSelectors.activeCandidateDetails,
    (candidate) => candidate?.candidateHasSkills,
  );

  public static readonly skillPerBusinessService = createSelector(
    CandidatesSkillsSelectors.skills,
    BusinessServicesSelectors.businessServiceEntities,
    RolesSelectors.roleEntities,
    SubjectsSelectors.subjectEntities,
    (skills, businessServices, roles, subjects) => {
      const serviceDict: NonNullableDictionary<CandidateHasSkillsContract[]> = {};

      for (const skill of skills ?? []) {
        const service =
          businessServices[
            jsonLdSelectId(roles[jsonLdSelectId(skill.skill.role)]?.businessService)
          ];
        if (!service) {
          continue;
        }
        const id = jsonLdSelectId(service['@id']);
        serviceDict[id] = [...(serviceDict[id] ?? []), skill];
      }
      return Object.entries(serviceDict).map(([businessServiceId, items]) => {
        return createCandidatePerBusinessService(
          items,
          businessServices[businessServiceId] as BusinessServiceContract,
          roles,
          subjects,
        );
      });
    },
  );
}
