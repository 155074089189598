import { Exclude, Expose, Type } from 'class-transformer';
import { SlotStatistics } from '../deprecated/slot-statistics.model';
import { LessonDetailed } from '../lesson/lesson-detailed.model';
import { SlotAction } from './slot-action.model';
import { Slot } from './slot.model';

/**
 * Detailed version of the Slot Resource.
 */
export class SlotDetailed extends Slot {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'SlotDetailed';

  @Type(() => SlotAction)
  @Expose()
  public actions?: SlotAction[];

  /**
   * The lesson where this slot belongs to
   */
  @Type(() => LessonDetailed)
  @Expose()
  public override lesson!: LessonDetailed;

  /**
   * Returns true if slot.isCombined is true, or if slot is in assignment with
   * one or more other slots that have isCombined = true.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public displayAsCombined: boolean = false;

  /**
   * If true, this slot is combined with one or more other (conflicting) slots into one assignment.
   * Only possible if productType.allowCombinedSlots is true (e.g. Summerschools).
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public isCombined: boolean = false;

  /**
   * If true the skills of the candidate should be taken into account.
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public performSkillCheck!: boolean;

  // todo this code is verry ugly and need to be removed

  /**
   * Property containing statistics about the slot.
   */
  @Exclude() public statistics?: SlotStatistics;

  /**
   * Returns true if there are statistics available.
   */
  public hasStatistics(): boolean {
    return !!this.statistics;
  }

  @Exclude() public isParentSlot(): boolean {
    return this.displayAsCombined && !this.isCombined;
  }
}
