import { Exclude, Expose, Type } from 'class-transformer';
import { isEqual, roundToNearestMinutes } from 'date-fns';
import { AssignmentHasSlotDetailedWithAssignment } from '../assignment-has-slot/assignment-has-slot-with-assignment.model';
import { Assignment } from '../assignment/assignment.model';
import { SlotDetailed } from './slot-detailed.model';

/**
 * Detailed version of the Slot Resource.
 */
export class SlotDetailedWithAssignmentHasSlot extends SlotDetailed {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'SlotDetailedWithAssignmentHasSlot';

  /**
   * Assignment where the slot belongs to
   *
   * Note: this property is only available when this resource is retrieved
   * through the {@see SlotApi} or {@see SearchApi}. In other cases look at
   * the parent of this resource
   */
  @Type(() => AssignmentHasSlotDetailedWithAssignment)
  @Expose()
  public assignmentHasSlot?: AssignmentHasSlotDetailedWithAssignment;

  /**
   * Assignment where the slot belongs to
   */
  public get assignment(): Assignment | undefined {
    return this.assignmentHasSlot?.assignment;
  }

  @Exclude() public get isTimePeriodEqual(): boolean {
    if (
      !this.assignmentHasSlot?.actualTimePeriodUpdatedAt ||
      !this.assignmentHasSlot.actualTimePeriod
    ) {
      // no actual time period set.
      return false;
    }
    const isStartEqual: boolean = isEqual(
      roundToNearestMinutes(this.timePeriod.start),
      roundToNearestMinutes(this.assignmentHasSlot.actualTimePeriod.start),
    );
    const isEndEqual: boolean = isEqual(
      roundToNearestMinutes(this.timePeriod.end),
      roundToNearestMinutes(this.assignmentHasSlot.actualTimePeriod.end),
    );
    return isStartEqual && isEndEqual;
  }

  public isAssignable(): boolean {
    return this.assignment === undefined || this.assignment?.isUnassigned();
  }
}
