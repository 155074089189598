import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideCandidatesStore } from '@scheduler-frontend/data-access-candidates';
import { provideSlotsStore } from '@scheduler-frontend/data-access-slots';
import { SchedulingApproachCandidatesRemindEffect } from './+state/effects/scheduling-approach-candidates-remind.effect';
import { SchedulingApproachCandidatesEffect } from './+state/effects/scheduling-approach-candidates.effect';
import { SchedulingAssignToSlotsEffect } from './+state/effects/scheduling-assign-to-slots.effect';
import { SchedulingRejectSlotsEffect } from './+state/effects/scheduling-reject-slots.effect';
import { SchedulingViewEffects } from './+state/effects/scheduling-view.effects';
import { SchedulingEffects } from './+state/effects/scheduling.effects';
import {
  SCHEDULING_VIEW_FEATURE_KEY,
  schedulingViewReducer,
} from './+state/reducers/scheduling-view.reducer';
import { SCHEDULING_FEATURE_KEY, schedulingReducer } from './+state/reducers/scheduling.reducer';
import { SchedulingStoreService } from './scheduling-store.service';
import { SchedulingViewStoreService } from './scheduling-view-store.service';

export function provideSchedulingStore(): Provider | EnvironmentProviders[] {
  return [
    provideCandidatesStore(),
    provideSlotsStore(),
    provideState({ name: SCHEDULING_FEATURE_KEY, reducer: schedulingReducer }),
    provideState({ name: SCHEDULING_VIEW_FEATURE_KEY, reducer: schedulingViewReducer }),
    provideEffects(
      SchedulingEffects,
      SchedulingApproachCandidatesEffect,
      SchedulingApproachCandidatesRemindEffect,
      SchedulingAssignToSlotsEffect,
      SchedulingRejectSlotsEffect,
      SchedulingViewEffects,
    ),
    SchedulingStoreService,
    SchedulingViewStoreService,
  ];
}
