import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import {
  ProductTypeContract,
  ProductTypeDetailedContract,
} from '../../../contracts/product-type.contract';

/**
 * Api to retrieve the {@see Product-Type} resource from the scheduler-api
 */
@Injectable({
  providedIn: 'root',
})
export class ProductTypeApi {
  protected httpClient = inject(HttpClient);

  /**
   * Returns all {@see Product-Type} from the scheduler Api.
   *
   * The response is cashed in the {@see CacheService} and is refreshed every day.
   * The list comes sorted ascending on name.
   */
  public getProductTypes(): Observable<Collection<ProductTypeContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/product_types`;

    return this.httpClient.get<Collection<ProductTypeContract>>(url);
  }

  public getProductType(productType: string): Observable<ProductTypeDetailedContract> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/product_types/${productType}`;

    return this.httpClient.get<ProductTypeDetailedContract>(url);
  }
}
