import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { SelectedSelectors } from './+state/selectors/selected.selectors';

@Injectable()
export class CreateSlotsSelectedStoreService {
  private readonly store = inject(Store);

  public businessService = this.store.selectSignal(SelectedSelectors.businessService, {
    equal: isEqual,
  });

  public deliveryType = this.store.selectSignal(SelectedSelectors.deliveryType, {
    equal: isEqual,
  });

  public schedule = this.store.selectSignal(SelectedSelectors.schedule, {
    equal: isEqual,
  });
}
