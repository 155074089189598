import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, switchMap } from 'rxjs';
import { AvailabilityLongTermModalComponent } from '../../modals/availability-long-term-modal/availability-long-term-modal.component';
import { candidatesActions } from '../actions/candidates.actions';

@Injectable()
export class CandidateLongTermAvailabilityEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly modalController = inject(ModalController);

  public readonly openLongTermAvailabilityModal = createEffect(
    () =>
      this.actions$.pipe(
        ofType(candidatesActions.openLongTermAvailabilityModal),
        switchMap(() => {
          return from(
            this.modalController.create({
              component: AvailabilityLongTermModalComponent,
              cssClass: 'long-term-availability-modal',
            }),
          ).pipe(
            switchMap((confirmModal) => confirmModal.present().then(() => confirmModal)),
            switchMap((confirmModal) => confirmModal.onWillDismiss()),
          );
        }),
      ),
    { dispatch: false },
  );
}
