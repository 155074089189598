import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { SchedulesEffects } from './+state/schedules.effects';
import { SCHEDULES_FEATURE_KEY, schedulesReducer } from './+state/schedules.reducer';
import { SchedulesStoreService } from './schedules-store.service';

export function provideSchedulesStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: SCHEDULES_FEATURE_KEY, reducer: schedulesReducer }),
    provideEffects(SchedulesEffects),
    SchedulesStoreService,
  ];
}
