import { Pipe, PipeTransform } from '@angular/core';
import { jsonLdSelectId } from './json-ld-select-id.function';

@Pipe({
  name: 'jsonLdSelect',
  standalone: true,
})
export class JsonLdSelectIdPipe implements PipeTransform {
  public transform(value: unknown) {
    return jsonLdSelectId(value);
  }
}
