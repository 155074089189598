<ng-container [formGroup]="whoControl">
  <tt-ion-select-search
    fill="solid"
    class="role-control"
    formControlName="role"
    [style.grid-row]="rowNumber()"
    [showBackdrop]="false"
    data-test="who-control-role-select"
    [options]="createSlotsStoreService.validRoles()"
    [displayNameFunction]="displayByName"
  >
    <ion-label position="floating">Rol</ion-label>
    <div *selectSearchOption="let element">
      {{ element.abbreviation }} - {{ element.name }} ({{
        businessServicesStoreService.businessServiceEntities()[
          element.businessService | jsonLdSelect
        ]?.businessEntity?.name
      }})
    </div>
    <ion-note slot="error" *ngxControlError="whoControl.controls.role; track: 'required'">
      Rol is <strong>verplicht</strong>.
    </ion-note>
  </tt-ion-select-search>

  <!-- FIXME The ion-item is not displaying the form-errors as the "ion-invalid" class is not set.
      For now workaround this by using a div-element and some custom styling for the errors. -->
  <div [style.grid-row]="rowNumber()" formGroupName="timeRange" class="time">
    <div class="controls">
      <tt-time-control
        fill="solid"
        [clearInput]="true"
        data-test="who-control-start-time-input"
        label="Starttijd"
        labelPlacement="floating"
        formControlName="startTime"
      >
      </tt-time-control>

      <tt-time-control
        fill="solid"
        data-test="who-control-end-time-input"
        [class.invalid-group]="whoControl.controls.timeRange.errors"
        [clearInput]="true"
        label="Eindtijd"
        labelPlacement="floating"
        formControlName="endTime"
      >
      </tt-time-control>
    </div>

    <ion-note
      data-test="who-control-start-time-invalid"
      *ngxControlError="whoControl.controls.timeRange.controls.startTime; track: 'required'"
    >
      Starttijd is <strong>verplicht</strong> en kan maximaal <strong>23:59</strong> zijn.
    </ion-note>

    <ion-note
      data-test="who-control-end-time-invalid"
      *ngxControlError="whoControl.controls.timeRange.controls.endTime; track: 'required'"
    >
      Eindtijd is <strong>verplicht</strong> en kan maximaal <strong>23:59</strong> zijn.
    </ion-note>

    <ion-note
      data-test="who-control-time-not-valid-error"
      *ngxControlError="whoControl.controls.timeRange; track: 'tsRangeNotInRange'"
    >
      De eindtijd moet later zijn dan de starttijd.
    </ion-note>
  </div>

  @if (requirementsStoreService.mustSetNumberOfPupils()) {
    <ion-item
      lines="none"
      class="pupils item-interactive item-select ion-activatable ion-focusable"
      [style.grid-row]="rowNumber()"
    >
      <ion-input
        fill="solid"
        label="Leerlingaantal"
        labelPlacement="floating"
        formControlName="amountOfPupils"
        type="number"
        data-test="who-control-amount-of-pupils-input"
      >
      </ion-input>
      <ion-note
        slot="error"
        *ngxControlError="whoControl.controls.amountOfPupils; track: 'required'"
      >
        Leerlingaantal is <strong>verplicht</strong>.
      </ion-note>
    </ion-item>
  }

  @if (
    requirementsStoreService.mayHaveDifferentOperationalLocation() ||
    requirementsStoreService.isAtHome()
  ) {
    <tt-ion-select-search
      fill="solid"
      [style.grid-row]="rowNumber()"
      formControlName="operationalLocation"
      (focus)="onTouch()"
      [showBackdrop]="false"
      alignment="center"
      [options]="createSlotsStoreService.operationalLocations()"
      [displayNameFunction]="displayByName"
      data-test="who-control-operational-location-select"
    >
      <ion-label position="floating">Locatie</ion-label>
      <ion-note
        slot="error"
        *ngxControlError="whoControl.controls.operationalLocation; track: 'required'"
      >
        Locatie is <strong>verplicht</strong>.
      </ion-note>
    </tt-ion-select-search>
  }

  @if (
    this.requirementsStoreService.mustHaveSubject() ||
    this.createSlotsStoreService.validSubjects().length > 0
  ) {
    <tt-ion-select-search
      fill="solid"
      [style.grid-row]="rowNumber()"
      formControlName="subject"
      [showBackdrop]="false"
      alignment="center"
      [options]="createSlotsStoreService.validSubjects()"
      data-test="who-control-subject-select"
      [displayNameFunction]="displayByName"
    >
      <ion-label position="floating">Onderwerp</ion-label>
      <ion-note slot="error" *ngxControlError="whoControl.controls.subject; track: 'required'">
        Onderwerp is <strong>verplicht</strong>.
      </ion-note>
    </tt-ion-select-search>
  }

  @if (requirementsStoreService.mustHaveLevel()) {
    <ion-select
      fill="solid"
      label="Schooltype"
      data-test="who-control-level-select"
      labelPlacement="floating"
      formControlName="level"
      interface="popover"
      [style.grid-row]="rowNumber()"
      errorText="Schooltype is verplicht."
      [interfaceOptions]="{ showBackDrop: false }"
    >
      @for (level of schoolLevels; track level) {
        <ion-select-option [value]="level">
          {{ LevelDisplayValues[level] }}
        </ion-select-option>
      }
    </ion-select>
  }
</ng-container>
