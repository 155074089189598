<ion-button (click)="previousMonth()" fill="clear" size="small">
  <ion-icon slot="icon-only" icon="chevron-back-outline"></ion-icon>
</ion-button>

@for (month of monthsMapping(); track month.firstOfMonth.toString()) {
  <div class="month" [attr.data-test]="'month-' + (month.firstOfMonth | date: 'MM')">
    <div class="tt-subtitle-2 month-header">{{ month.firstOfMonth | date: 'yyyy - MMMM' }}</div>

    <div class="calendar">
      <div class="day-header"></div>
      @for (
        weekDay of ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'];
        let index = $index;
        track weekDay
      ) {
        <ion-button
          (click)="toggleDayOfTheWeek(index + 1, month)"
          class="day-header"
          fill="clear"
          [disabled]="isControlDisabled()"
        >
          {{ weekDay }}
        </ion-button>
      }

      @for (week of month.daysPerWeek; track week[0].date.getTime()) {
        <ion-button
          (click)="toggleWeek(week, month)"
          class="week-number"
          fill="clear"
          [disabled]="isControlDisabled()"
        >
          {{ week[0].date | date: 'w' }}
        </ion-button>

        @for (day of week; track day.date.getTime()) {
          <ion-button
            (click)="toggleDay(day)"
            [class.other-month]="isOtherMonthMemoize(day.date, month.firstOfMonth)"
            [class.selected]="selection.isSelected(day.date)"
            [class.today]="day.isToday"
            [disabled]="isControlDisabled() || day.isDisabled"
            fill="clear"
            class="day"
          >
            {{ day.date | date: 'dd' }}
          </ion-button>
        }
      }
    </div>
  </div>
}

<ion-button (click)="nextMonth()" fill="clear" size="small">
  <ion-icon slot="icon-only" icon="chevron-forward-outline"></ion-icon>
</ion-button>
