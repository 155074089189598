import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AWAITING_FIRST_SLOTS_FEATURE_KEY,
  awaitingSlotsAdapter,
  AwaitingSlotsState,
} from './awaiting-first-slots.reducer';

const { selectAll, selectEntities, selectTotal } = awaitingSlotsAdapter.getSelectors();

export class AwaitingFirstSlotsSelectors {
  public static readonly state = createFeatureSelector<AwaitingSlotsState>(
    AWAITING_FIRST_SLOTS_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    AwaitingFirstSlotsSelectors.state,
    (state: AwaitingSlotsState) => state.loading,
  );

  public static readonly loaded = createSelector(
    AwaitingFirstSlotsSelectors.state,
    (state: AwaitingSlotsState) => state.loaded,
  );

  public static readonly error = createSelector(
    AwaitingFirstSlotsSelectors.state,
    (state: AwaitingSlotsState) => state.error,
  );

  public static readonly pupilsWithoutFirstSlot = createSelector(
    AwaitingFirstSlotsSelectors.state,
    (state: AwaitingSlotsState) => selectAll(state),
  );

  public static readonly pupilsWithoutFirstSlotCount = createSelector(
    AwaitingFirstSlotsSelectors.state,
    (state: AwaitingSlotsState) => selectTotal(state),
  );

  public static readonly pupilsWithoutFirstSlotEntities = createSelector(
    AwaitingFirstSlotsSelectors.state,
    (state: AwaitingSlotsState) => selectEntities(state),
  );
}
