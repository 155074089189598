import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import {
  schedulingActions,
  schedulingViewActions,
  SchedulingViewSelectors,
} from '@scheduler-frontend/data-access-scheduling';
import { slotsActions, SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { objectToSearchExpression } from '@scheduler-frontend/search-expression';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { addMonths, formatISO, subWeeks } from 'date-fns';
import { FormatISOOptions } from 'date-fns/formatISO';
import { catchError, exhaustMap, filter, from, Observable, of, switchMap, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Filters,
  isSearchDetailedContract,
  SearchContract,
  SearchMetaDataContract,
} from '../../contract/search.contract';
import { UserSearchContract } from '../../contract/user-search.contract';
import { searchActions } from '../action/search.actions';
import { userSearchActions } from '../action/user-search.actions';
import { SearchSlotApi } from '../api/search-slot.api';
import { SearchSelectors } from '../selector/search.selectors';
import { UserSearchSelectors } from '../selector/user-search.selectors';

@Injectable()
export class SchedulingSearchEffects implements OnRunEffects {
  private readonly actions$ = inject(Actions);

  private readonly searchSlotApi = inject(SearchSlotApi);

  private readonly store = inject(Store);

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(searchActions.startSchedulingBySearch),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions$.pipe(ofType(searchActions.stopSchedulingBySearch))),
        ),
      ),
    );
  }

  public readonly convertUserSearchToSystemSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(userSearchActions.setCurrentUserSearch, userSearchActions.addUserSearchHash),
      concatLatestFrom(() => [
        this.store.pipe(
          select(UserSearchSelectors.currentSearch),
          filter(
            (item): item is UserSearchContract & { search: { metaData: SearchMetaDataContract } } =>
              !!(item && item.search.metaData && item.search.metaData.filters),
          ),
        ),
        this.store.pipe(select(SchedulingViewSelectors.timeRangeInView), isDefined()),
        this.store.pipe(select(SearchSelectors.searchEntities)),
      ]),
      exhaustMap(([_action, currentUserSearch, timeRangeInView, searches]) => {
        let filters = this.adjustFiltersToDateRangeToView(currentUserSearch, timeRangeInView);
        return from(this.calculatedHash(objectToSearchExpression(filters))).pipe(
          map((hash) => {
            if (searches[hash]) {
              return searchActions.setCurrentSystemSearch({ searchId: hash });
            }
            return searchActions.createSearchHash({ filters: filters, isUserInitiated: false });
          }),
        );
      }),
    ),
  );

  public readonly convertInitSearchToSystemSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.initCurrentSystemSearch),
      concatLatestFrom(() => [
        this.store.pipe(select(SchedulingViewSelectors.timeRangeInView), isDefined()),
        this.store.pipe(select(SearchSelectors.searchEntities)),
      ]),
      exhaustMap(([action, timeRangeInView, searches]) => {
        const search = searches[action.searchId] as SearchContract;
        let filters = this.adjustFiltersToDateRangeToView(search, timeRangeInView);
        return from(this.calculatedHash(objectToSearchExpression(filters))).pipe(
          map((hash) => {
            if (searches[hash]) {
              return searchActions.setCurrentSystemSearch({ searchId: hash });
            }
            return searchActions.createSearchHash({ filters: filters, isUserInitiated: false });
          }),
        );
      }),
    ),
  );

  public readonly convertSystemSearchOnChange = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulingActions.setTimeRangeInView,
        schedulingViewActions.nextRangeInView,
        schedulingViewActions.previousRangeInView,
        schedulingViewActions.changeCurrentViewSuccess,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SearchSelectors.currentSearch), isDefined()),
        this.store.pipe(select(SchedulingViewSelectors.timeRangeInView), isDefined()),
        this.store.pipe(select(SearchSelectors.searchEntities)),
      ]),
      exhaustMap(([_action, currentSearch, timeRangeInView, searches]) => {
        let filters = this.adjustFiltersToDateRangeToView(currentSearch, timeRangeInView);
        return from(this.calculatedHash(objectToSearchExpression(filters))).pipe(
          map((hash) => {
            if (searches[hash]) {
              return searchActions.setCurrentSystemSearch({ searchId: hash });
            }
            return searchActions.createSearchHash({
              filters: filters,
              isUserInitiated: false,
              setAsCurrentSearch: true,
            });
          }),
        );
      }),
    ),
  );

  public readonly setSystemSearchAsCurrentSearchOnCreatedSystemSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.createSearchHashSuccess, searchActions.removeFromSearchHashSuccess),
      filter((action) => !action.isUserInitiated),
      map((action) => searchActions.setCurrentSystemSearch({ searchId: action.search.hash })),
    ),
  );

  public readonly loadSlotsOfCurrentSystemSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(
        searchActions.initSchedulingBySearch,
        searchActions.setCurrentSystemSearch,
        searchActions.reloadCurrentSearch,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SearchSelectors.currentSearchId), isDefined()),
      ]),
      switchMap(([_, search]) => {
        return this.searchSlotApi.search(search, { withRelatedShifts: true, page: 1 }).pipe(
          map((result) =>
            slotsActions.loadSlotsSuccess({
              slots: result['hydra:member'],
              totalItems: result['hydra:totalItems'],
              chunk: 1,
            }),
          ),
          catchError((error) => of(slotsActions.loadSlotsFailure({ error: error }))),
        );
      }),
    ),
  );

  public readonly startLoadingSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.setCurrentSystemSearch, searchActions.reloadCurrentSearch),
      map(() => schedulingActions.startLoadingSlots()),
    ),
  );

  public readonly addSlotsToCurrentSearchState = createEffect(() =>
    this.actions$.pipe(
      ofType(slotsActions.loadSlotsSuccess),
      concatLatestFrom(() => this.store.pipe(select(SearchSelectors.currentSearch), isDefined())),
      map(([action, currentSearch]) => {
        return searchActions.addSlotListToCurrentSearchSuccess({
          search: {
            ...currentSearch,
            slots: [
              ...new Set([
                ...(isSearchDetailedContract(currentSearch) ? currentSearch?.slots ?? [] : []),
                ...action.slots.map((slot) => jsonLdSelectId(slot) as string),
              ]).values(),
            ],
          },
        });
      }),
    ),
  );

  public readonly setSchedulingSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.addSlotListToCurrentSearchSuccess, searchActions.setCurrentSystemSearch),
      concatLatestFrom(() => this.store.pipe(select(SearchSelectors.currentSearch), isDefined())),
      map(([action, search]) => search),
      filter(isSearchDetailedContract),
      map((search) =>
        schedulingActions.setSlotList({
          items: search.slots as string[],
        }),
      ),
    ),
  );

  public readonly nextChuckForCurrentSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.nextChunkCurrentSearch),
      concatLatestFrom(() => [
        this.store.pipe(select(SearchSelectors.currentSearch), isDefined()),
        this.store.pipe(
          select(SlotsSelectors.selectCurrentSearchLoadedChunks),
          isDefined(),
          map((chunks) => Math.max(...chunks)),
        ),
      ]),
      switchMap(([_, search, lastChunk]) =>
        this.searchSlotApi.search(search.hash, { withRelatedShifts: true, page: lastChunk }).pipe(
          map((result) =>
            slotsActions.loadSlotsSuccess({
              slots: result['hydra:member'],
              totalItems: result['hydra:totalItems'],
              chunk: lastChunk + 1,
            }),
          ),
          catchError((error) => of(slotsActions.loadSlotsFailure({ error: error }))),
        ),
      ),
    ),
  );

  public readonly loadCurrentSearchFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(slotsActions.loadSlotsFailure, {
          message: 'Er is iets misgegaan bij het laden van de zoekopdracht.',
        }),
      ),
    { dispatch: false },
  );

  public readonly setCurrentSystemSearchFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(searchActions.addSlotListToCurrentSearchFailure, {
          message: 'Er is iets misgegaan bij het laden van de gegeven zoekopdracht.',
        }),
      ),
    { dispatch: false },
  );

  public clearSelectionOnStoppingSchedulingBySearch = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.stopSchedulingBySearch),
      map(() => schedulingActions.clearAllSelections()),
    ),
  );

  private adjustFiltersToDateRangeToView(
    currentSearch: UserSearchContract | SearchContract,
    timeRangeInView: PlainRangeInterface<string | number> | 'infinite',
  ): Filters {
    const search = ('search' in currentSearch ? currentSearch.search : currentSearch) as {
      metaData: SearchMetaDataContract;
    };
    const filters = search.metaData.filters;
    const lessonDateFilter = filters['lesson.date'] as PlainRangeInterface<string>;
    const formatIsoObject: FormatISOOptions = { representation: 'date' };

    if (timeRangeInView === 'infinite') {
      return {
        ...filters,
        'lesson.date': {
          start: lessonDateFilter?.start || formatISO(subWeeks(new Date(), 2), formatIsoObject),
          end: lessonDateFilter?.end || formatISO(addMonths(new Date(), 2), formatIsoObject),
          inclusiveStart: true,
          inclusiveEnd: false,
        },
      };
    }

    return {
      ...filters,
      'lesson.date': {
        ...timeRangeInView,
        start: formatISO(timeRangeInView.start, formatIsoObject),
        end: formatISO(timeRangeInView.end, formatIsoObject),
      },
    };
  }

  private async calculatedHash(query: string): Promise<string> {
    const msgBuffer = new TextEncoder().encode(query);

    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  }
}
