import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { BusinessServicesSelectors } from '@scheduler-frontend/data-access-business-services';
import { LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { ProductTypesSelectors } from '@scheduler-frontend/data-access-product-types';
import { RoleDetailedContract, RolesSelectors } from '@scheduler-frontend/data-access-roles';
import { SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { sortString } from '@techniek-team/common';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { EDIT_SLOT_FEATURE_KEY, EditSlotsState } from './edit-slot.reducer';

export class EditSlotSelectors {
  public static readonly state = createFeatureSelector<EditSlotsState>(EDIT_SLOT_FEATURE_KEY);

  public static readonly loading = createSelector(
    EditSlotSelectors.state,
    (state) => state.loading,
  );

  public static readonly submitting = createSelector(
    EditSlotSelectors.state,
    (state) => state.submitting,
  );

  public static selectedSlotId = createSelector(
    EditSlotSelectors.state,
    (state) => state.selectedSlotId,
  );

  public static selectedSlot = createSelector(
    EditSlotSelectors.selectedSlotId,
    SlotsSelectors.slotEntities,
    (id, slots): SlotContract | SlotDetailedContract | undefined => (id ? slots[id] : undefined),
  );

  public static selectedSlotRole = createSelector(
    EditSlotSelectors.selectedSlot,
    RolesSelectors.roleEntities,
    (slot, roles) => (slot?.role ? roles[jsonLdSelectId(slot?.role)] : undefined),
  );

  public static selectedSlotBusinessService = createSelector(
    EditSlotSelectors.selectedSlotRole,
    BusinessServicesSelectors.businessServiceEntities,
    (role, businessServices) => {
      if (!role?.businessService) {
        return undefined;
      }
      if (typeof role.businessService === 'string') {
        return businessServices[jsonLdSelectId(role.businessService)];
      }
      return role.businessService;
    },
  );

  public static selectedSlotProductType = createSelector(
    EditSlotSelectors.selectedSlotBusinessService,
    ProductTypesSelectors.productTypeEntities,
    (businessService, productTypes) => {
      if (!businessService?.productType) {
        return undefined;
      }
      return productTypes[jsonLdSelectId(businessService.productType)];
    },
  );

  public static selectedSlotLocation = createSelector(
    EditSlotSelectors.selectedSlot,
    LocationsSelectors.locationsEntities,
    (slot, locations) => {
      if (!slot?.lesson?.location) {
        return undefined;
      }
      return locations[jsonLdSelectId(slot?.lesson?.location)];
    },
  );

  public static readonly validRoles = createSelector(
    EditSlotSelectors.selectedSlotBusinessService,
    RolesSelectors.roles,
    (businessService, roles) => {
      if (!businessService) {
        return [];
      }
      const filtered = roles.filter((role) => {
        const roleBusinessService =
          typeof role.businessService === 'string'
            ? role.businessService
            : role.businessService['@id'];
        return roleBusinessService === businessService['@id'];
      });
      filtered.sort((a, b) => {
        const sortingPriority: number = (a.sortingPriority ?? 0) - (b.sortingPriority ?? 0);

        return sortingPriority !== 0 ? sortingPriority : sortString(a.name, b.name);
      });

      return filtered as RoleDetailedContract[];
    },
  );
}
