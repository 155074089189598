/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dictionary } from '@ngrx/entity';
import { denormalize, JsonLd } from '@techniek-team/class-transformer';
import { ClassConstructor, ClassTransformOptions } from 'class-transformer';
import { NonNullableDictionary } from './non-nullable-dictionary.function';

export function denormalizeDictionary<
  T extends JsonLd,
  V extends Dictionary<any[]> | NonNullableDictionary<any[]>,
>(cls: ClassConstructor<T>, plain: V, options?: ClassTransformOptions): Dictionary<T[]>;
export function denormalizeDictionary<
  T extends JsonLd,
  V extends Dictionary<any> | NonNullableDictionary<any>,
>(cls: ClassConstructor<T>, plain: V, options?: ClassTransformOptions): Dictionary<T>;
export function denormalizeDictionary<T extends JsonLd, V extends any>(
  cls: ClassConstructor<T>,
  plain: Dictionary<V> | Dictionary<V[]>,
  options?: ClassTransformOptions,
): Dictionary<T> | Dictionary<T[]> {
  const dict: Dictionary<any> = {};
  for (let [key, value] of Object.entries(plain)) {
    if (value) {
      if (Array.isArray(value)) {
        dict[key] = denormalize(cls, value, options);
        continue;
      }
      dict[key] = denormalize(cls, value, options);
    }
  }
  return dict;
}
