<div
  class="user-container ion-activatable"
  data-test="logged-in-user-container"
  [id]="popoverTrigger()"
>
  <tt-avatar
    [name]="(userStoreService.user$ | async)?.fullName ?? ''"
    color="tertiary"
    data-test="logged-in-user-avatar"
  ></tt-avatar>

  @if (displayDetails()) {
    <span data-test="logged-in-user-full-name">
      {{ (userStoreService.user$ | async)?.fullName }}
    </span>
  }

  <ion-ripple-effect></ion-ripple-effect>
</div>

<ion-popover
  class="logged-in-user-popover"
  [trigger]="popoverTrigger()"
  [dismissOnSelect]="true"
  [showBackdrop]="false"
>
  <ng-template>
    <ion-content data-test="logged-in-user-popover-content">
      <ion-list>
        @if (userStoreService.user$ | async; as user) {
          <ion-item lines="full">
            <strong>{{ user.email }}</strong>
          </ion-item>
        }

        <ion-item
          lines="full"
          data-test="logged-in-user-whats-new-button"
          class="whats-new"
          [button]="true"
          (click)="releaseService.openWhatsNewModal()"
        >
          What's new
          <ion-badge slot="end">{{ release() }}</ion-badge>
        </ion-item>
        <ion-item [button]="true" lines="full" [id]="popoverTrigger() + 'refresh-cache'">
          Cachegeheugen verversen
        </ion-item>
        <ion-popover
          [trigger]="popoverTrigger() + 'refresh-cache'"
          [dismissOnSelect]="true"
          side="end"
        >
          <ng-template>
            <ion-content>
              <ion-list>
                <ion-item [button]="true" lines="full" (click)="clearSchedules()">
                  Ververs roosters
                </ion-item>
                <ion-item
                  [button]="true"
                  lines="full"
                  (click)="locationsStoreService.reloadLocations()"
                >
                  Ververs locaties
                </ion-item>
                <ion-item
                  [button]="true"
                  lines="full"
                  (click)="
                    businessEntitiesStoreService.reload(); businessServicesStoreService.reload()
                  "
                >
                  Ververs diensten
                </ion-item>
                <ion-item [button]="true" lines="full" (click)="subjectsStoreService.reload()">
                  Ververs vakken
                </ion-item>
                <ion-item [button]="true" lines="full" (click)="regionsStoreService.reload()">
                  Ververs regio's
                </ion-item>
                <ion-item [button]="true" lines="full" (click)="rolesStoreService.reload()">
                  Ververs rollen
                </ion-item>
                <ion-item [button]="true" (click)="reloadAll()" lines="full"
                  >Alles verversen</ion-item
                >
              </ion-list>
            </ion-content>
          </ng-template>
        </ion-popover>
        <ion-item
          lines="none"
          data-test="logged-in-user-logout-button"
          [button]="true"
          (click)="userService.handleLogoutAction()"
        >
          Uitloggen
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>
