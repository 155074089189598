import { ENVIRONMENT_INITIALIZER, importProvidersFrom } from '@angular/core';
import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

export function ionicStorageFactory() {
  return [
    importProvidersFrom(
      IonicStorageModule.forRoot({
        name: '__scheduler-frontend',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      }),
    ),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: (storage: Storage) => {
        return () => storage.create();
      },
      multi: true,
      deps: [Storage],
    },
  ];
}
