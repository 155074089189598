import { inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  Assignment,
  AssignmentContract,
  AssignmentDetailed,
  AssignmentDetailedContract,
  AssignmentDetailedWithAssignmentHasSlot,
  AssignmentHasSlotDetailed,
  AssignmentHasSlotDetailedWithAssignment,
  AssignmentHasSlotDetailedWithSlot,
  Slot,
  SlotAssignmentHasSlotContract,
} from '@scheduler-frontend/assignment-contracts';
import { Candidate, CandidateContract } from '@scheduler-frontend/candidate-contracts';
import { denormalize, TsRange } from '@techniek-team/class-transformer';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { instanceToPlain } from 'class-transformer';
import { filter, map, Observable, shareReplay, switchMap } from 'rxjs';
import { activeAssignmentHistoryActions } from './+state/actions/active-assignment-history.actions';
import { activeAssignmentActions } from './+state/actions/active-assignment.actions';
import { assignmentCompensationsActions } from './+state/actions/assignment-compensations.actions';
import { assignmentsActions } from './+state/actions/assignments.actions';
import { ActiveAssignmentHistorySelectors } from './+state/selectors/active-assignment-history.selectors';
import { ActiveAssignmentSelectors } from './+state/selectors/active-assignment.selectors';
import { AssignmentCompensationsSelectors } from './+state/selectors/assignment-compensations.selectors';
import { AssignmentTransitionsSelectors } from './+state/selectors/assignment-transitions.selectors';
import { AssignmentsSelectors } from './+state/selectors/assignments.selectors';
import { AssignmentCompensationContract } from './contracts/assignment-compensation.contract';
import { PayoutArticleCodeEnum } from './enums/payout-article-code.enum';
import { TransitionEnum } from './enums/transition.enum';
import { AssignmentCompensation } from './models/assignment-compensation.model';
import { TransitionLogRecord } from './models/transition-log-record.model';
import { TransitionOption } from './models/transition-option.model';

@Injectable()
export class AssignmentsStoreService {
  private readonly store = inject(Store);

  private readonly actions = inject(Actions);

  public activeAssignment$: Observable<AssignmentDetailedWithAssignmentHasSlot> = this.store.pipe(
    select(AssignmentsSelectors.activeAssignment),
    isDefined(),
    map((assignment) => {
      return denormalize(AssignmentDetailedWithAssignmentHasSlot, assignment);
    }),
    shareReplay(1),
  );

  public activeAssignment = this.store.selectSignal(AssignmentsSelectors.activeAssignment);

  public activeAssignmentMustPerformCompensation = this.store.selectSignal(
    AssignmentsSelectors.activeAssignmentMustPerformCompensation,
  );

  public activeAssignmentAutomaticTravelCompensation = this.store.selectSignal(
    AssignmentsSelectors.activeAssignmentAutomaticTravelCompensation,
  );

  public activeAssignmentHasSlots$ = this.store.pipe(
    select(AssignmentsSelectors.activeAssignmentHasSlots),
    isDefined(),
    map((assignmentHasSlots) => {
      return denormalize(AssignmentHasSlotDetailedWithSlot, assignmentHasSlots);
    }),
    shareReplay(1),
  );

  public activeAssignmentHistory$ = this.store.pipe(
    select(ActiveAssignmentHistorySelectors.history),
    map((historyRecords) => {
      return historyRecords
        ? (denormalize(TransitionLogRecord, historyRecords) as TransitionLogRecord[])
        : historyRecords;
    }),
    isDefined(),
    shareReplay(1),
  );

  public activeAssignmentHistoryLoading$ = this.store.pipe(
    select(ActiveAssignmentHistorySelectors.loading),
    shareReplay(1),
  );

  public activeAssignmentCompensation$ = this.store.pipe(
    select(AssignmentCompensationsSelectors.activeAssignmentCompensation),
    isDefined(),
    map<AssignmentCompensationContract, AssignmentCompensation>((compensation) => {
      return denormalize(AssignmentCompensation, compensation);
    }),
    shareReplay(1),
  );

  public activeAssignmentCompensation = this.store.selectSignal(
    AssignmentCompensationsSelectors.activeAssignmentCompensation,
  );

  public savingAssignmentCompensations = this.store.selectSignal(
    AssignmentCompensationsSelectors.savingAssignmentCompensations,
  );

  public lastChunkAssignmentHistory$ = this.store.pipe(
    select(ActiveAssignmentHistorySelectors.lastChunkLoaded),
    shareReplay(1),
  );

  public activeAssignmentCandidate$ = this.store.pipe(
    select(ActiveAssignmentSelectors.activeAssignmentCandidate),
    isDefined(),
    map<CandidateContract, Candidate>((candidate) => {
      return denormalize(Candidate, candidate);
    }),
    shareReplay(1),
  );

  public activeAssignmentTransitions$ = this.store.pipe(
    select(AssignmentTransitionsSelectors.assignmentTransitions),
    map((transitions) => {
      return transitions ? denormalize(TransitionOption, transitions) : transitions;
    }),
    shareReplay(1),
  );

  public loading$ = this.store.pipe(select(AssignmentsSelectors.loading), shareReplay(1));

  public loaded$ = this.store.pipe(select(AssignmentsSelectors.loaded), shareReplay(1));

  public savingIsUrgent$ = this.store.pipe(
    select(ActiveAssignmentSelectors.savingIsUrgent),
    shareReplay(1),
  );

  public savingCompensationLine$ = this.store.pipe(
    select(AssignmentCompensationsSelectors.savingCompensationLine),
    shareReplay(1),
  );

  public savingIsBillable$ = this.store.pipe(
    select(ActiveAssignmentSelectors.savingIsBillable),
    shareReplay(1),
  );

  public savingDescription$ = this.store.pipe(
    select(ActiveAssignmentSelectors.savingDescription),
    shareReplay(1),
  );

  public processingUnassign$ = this.store.pipe(
    select(AssignmentsSelectors.processingUnassign),
    shareReplay(1),
  );

  public savingSelfAssignmentSettings$ = this.store.pipe(
    select(ActiveAssignmentSelectors.savingSelfAssignmentSettings),
    shareReplay(1),
  );

  public savingPayoutSettings$ = this.store.pipe(
    // todo add correct selector
    select(ActiveAssignmentSelectors.savingSelfAssignmentSettings),
    shareReplay(1),
  );

  public activeCompensationAllowedArticleCodes$ = this.store.pipe(
    select(AssignmentCompensationsSelectors.activeCompensationAllowedArticleCodes),
  );

  public activeCompensationAllowedArticleCodes = this.store.selectSignal(
    AssignmentCompensationsSelectors.activeCompensationAllowedArticleCodes,
  );

  public setActiveAssignment(assignmentId: string | null): void {
    this.store.dispatch(
      activeAssignmentActions.setActiveAssignment({
        selectedId: assignmentId,
      }),
    );
  }

  public clearActiveAssignment(): void {
    this.store.dispatch(activeAssignmentActions.clear());
  }

  public refreshAssignment(): void {
    this.store.dispatch(activeAssignmentActions.refresh());
  }

  public addAssignment(
    assignment: Assignment | AssignmentDetailed | AssignmentDetailedContract | AssignmentContract,
  ): void {
    if (assignment instanceof AssignmentDetailed) {
      assignment = instanceToPlain(assignment) as AssignmentDetailedContract;
    }
    if (assignment instanceof Assignment) {
      assignment = instanceToPlain(assignment) as AssignmentContract;
    }
    this.store.dispatch(assignmentsActions.addAssignment({ assignment: assignment }));
  }

  public markActiveAssignmentAsUrgent(isUrgent: boolean): void {
    this.store.dispatch(activeAssignmentActions.setIsUrgent({ isUrgent: isUrgent }));
  }

  public setActiveAssignmentDescription(description: string | null): void {
    this.store.dispatch(activeAssignmentActions.setDescription({ description: description }));
  }

  public updateActualWorkingTimes(
    assignmentHasSlotId: string,
    time: TsRange,
    breakTime?: number,
  ): void {
    this.store.dispatch(
      activeAssignmentActions.updateActualWorkingTimes({
        time: time.toObject(),
        breakTime: breakTime,
        assignmentHasSlotId: assignmentHasSlotId,
      }),
    );
  }

  public setIsBillable(assignmentHasSlotId: string, isBillable: boolean): void {
    this.store.dispatch(
      activeAssignmentActions.setIsBillable({
        assignmentHasSlotId: assignmentHasSlotId,
        isBillable: isBillable,
      }),
    );
  }

  public setActiveAssignmentSelfAssignmentSettings(
    selfAssignable: boolean,
    allowSelfAssignWhenNew: boolean,
    minimalGradeSelfAssign: number | null = null,
    maxTravelDistanceSelfAssign: number | null = null,
  ): void {
    this.store.dispatch(
      activeAssignmentActions.setSelfAssignment({
        selfAssignable: selfAssignable,
        allowSelfAssignWhenNew: allowSelfAssignWhenNew,
        minimalGradeSelfAssign: minimalGradeSelfAssign,
        maxTravelDistanceSelfAssign: maxTravelDistanceSelfAssign,
      }),
    );
  }

  public removeSlotFormAssignment(slot: string | Slot): void {
    this.store.dispatch(
      assignmentsActions.removeSlotFormAssignment({
        slots: [typeof slot === 'string' ? slot : slot.getId()],
      }),
    );
  }

  public unassignActiveAssignment() {
    this.store.dispatch(activeAssignmentActions.unassign({}));
  }

  public unassignAssignment(assignment: string | Assignment): void {
    assignment = assignment instanceof Assignment ? assignment.getId() : assignment;
    this.store.dispatch(assignmentsActions.unassign({ assignment: assignment }));
  }

  /**
   * By default we try to assign the slot from the active assignment
   */
  public unassignSlot(
    assignmentHasSlot:
      | string
      | AssignmentHasSlotDetailed
      | AssignmentHasSlotDetailedWithAssignment
      | SlotAssignmentHasSlotContract
      | undefined,
    absencePeriod?: TsRange,
  ): void {
    if (!assignmentHasSlot) {
      return;
    }

    this.store.dispatch(
      activeAssignmentActions.unassignSlot({
        assignmentHasSlot: jsonLdSelectId(assignmentHasSlot),
        absencePeriod: absencePeriod?.toObject(),
      }),
    );
  }

  public openAssignmentModal(assignment: Assignment | AssignmentContract | string) {
    this.store.dispatch(
      assignmentsActions.openAssignmentModal({
        assignment: jsonLdSelectId(assignment),
      }),
    );
  }

  public savingActualWorkingTimes(
    assignmentHasSlot: AssignmentHasSlotDetailed | string,
  ): Observable<boolean> {
    if (assignmentHasSlot instanceof AssignmentHasSlotDetailed) {
      assignmentHasSlot = assignmentHasSlot.getId();
    }
    return this.store.pipe(
      select(ActiveAssignmentSelectors.targetAssignmentHasSlotActualWorkingTimes),
      filter((target) => assignmentHasSlot === target?.['@id']),
      switchMap(() => this.store.pipe(select(ActiveAssignmentSelectors.savingActualWorkingTimes))),
      shareReplay(),
    );
  }

  public markAsPresent(assignmentHasSlot: AssignmentHasSlotDetailed | string): void {
    if (assignmentHasSlot instanceof AssignmentHasSlotDetailed) {
      assignmentHasSlot = assignmentHasSlot.getId();
    }
    this.store.dispatch(
      activeAssignmentActions.askToMarkAsPresent({
        assignmentHasSlot: assignmentHasSlot,
      }),
    );
  }

  public async nextHistory(): Promise<void> {
    if (!(await firstEmitFrom(this.lastChunkAssignmentHistory$))) {
      this.store.dispatch(activeAssignmentHistoryActions.loadNextChunk());
      return firstEmitFrom(
        this.actions.pipe(ofType(activeAssignmentHistoryActions.loadNextChunkSuccess)),
      );
    }
  }

  public addCompensationLine(compensationLine: {
    articleCode: PayoutArticleCodeEnum;
    description: string;
    quantity: string;
    amount: string;
    assignmentHasSlot: string;
    subtotal: number;
    date: string;
  }): void {
    this.store.dispatch(
      assignmentCompensationsActions.addCompensationLine({
        compensationLine: compensationLine,
      }),
    );
  }

  public removeCompensationLine(compensationLine: string): void {
    this.store.dispatch(
      assignmentCompensationsActions.removeCompensationLine({
        compensationLine: compensationLine,
      }),
    );
  }

  public updateAssignment(change: {
    mustPerformCompensation?: boolean;
    automaticTravelCompensation?: boolean;
  }): void {
    this.store.dispatch(
      assignmentCompensationsActions.updateAssignmentCompensations({
        mustPerformCompensation: change.mustPerformCompensation,
        automaticTravelCompensation: change.automaticTravelCompensation,
      }),
    );
  }

  public applyTransition(transition: TransitionEnum): void {
    this.store.dispatch(activeAssignmentActions.applyTransition({ transition: transition }));
  }
}
