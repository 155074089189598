import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ProductContract } from '@scheduler-frontend/data-access-business-services';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsByLocationApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(location: string): Observable<Collection<ProductContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/locations/${location}/products?editableOnly=true`;
    return this.httpClient.get<Collection<ProductContract>>(url);
  }
}
