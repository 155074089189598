import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  schedulingActions,
  schedulingViewActions,
} from '@scheduler-frontend/data-access-scheduling';
import { map } from 'rxjs';
import { approachesHandledActions } from '../actions/approaches-handled.actions';
import { approachesToDoActions } from '../actions/approaches-to-do.actions';
import { approachesWaitingActions } from '../actions/approaches-waiting.actions';
import { approachesActions } from '../actions/approaches.actions';

@Injectable()
export class ApproachesInitEffects {
  private readonly actions$: Actions = inject(Actions);

  public showInlineAssignButtonsWhenSchedulingByApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      map(() => schedulingViewActions.showInlineAssignButtons()),
    ),
  );

  public hideInlineAssignButtonsWhenStoppingSchedulingByApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.stopSchedulingByApproaches),
      map(() => schedulingViewActions.hideInlineAssignButtons()),
    ),
  );

  public showSlotSelectionCheckboxWhenSchedulingByApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      map(() => schedulingViewActions.showSlotSelectionCheckbox()),
    ),
  );

  public hideSlotSelectionCheckboxWhenSchedulingByApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.stopSchedulingByApproaches),
      map(() => schedulingViewActions.hideSlotSelectionCheckbox()),
    ),
  );

  public clearSelectionOnStoppingSchedulingByApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.stopSchedulingByApproaches),
      map(() => schedulingActions.clearAllSelections()),
    ),
  );

  public startLoadingTodoApproaches = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      map(() => approachesToDoActions.loadApproaches()),
    ),
  );

  public startLoadingWaitingApproaches = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      map(() => approachesWaitingActions.loadApproaches()),
    ),
  );

  public startLoadingHandledApproaches = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      map(() => approachesHandledActions.loadApproaches()),
    ),
  );
}
