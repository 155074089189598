import { Signal } from '@angular/core';
import { SignalFetch } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';
import { BusinessService } from '@scheduler-frontend/data-access-business-services';
import { RoleMinimal } from './role-minimal.model';

/**
 * standard version of the Role resource from Scheduler-api
 */
export class Role extends RoleMinimal {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Role';

  /**
   * If where sorting roles we should take this sorting order into account.
   * Sorting is mostly done within a specific businessService
   */
  @Expose() public sortingPriority?: number;

  /**
   * If true a break time is allowed in the shift.
   *
   * Only available for BUSINESS_USER_ROLE, therefore it has a default value
   */
  @Expose() public allowBreakTime: boolean = false;

  /**
   * The service where the role belongs to.
   */
  @SignalFetch(() => BusinessService)
  @Expose()
  public businessService!: Signal<BusinessService | undefined>;

  //eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
