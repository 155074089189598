<ion-input data-test="tt-date-picker-input-input"
           [label]="label"
           [labelPlacement]="labelPlacement"
           [attr.aria-label]="ariaLabel"
           [formControl]="dateControl"
           [fill]="fill"
           [placeholder]="placeholder"></ion-input>
@if (!noIcon) {
  <div
    class="ion-icon">
    <ion-icon data-test="tt-date-picker-input-icon"
              size="small"
              name="calendar-clear-outline"></ion-icon>
  </div>
}
<div class="click-blocker"></div>
