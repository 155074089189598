import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { FetchStorageInterface } from '@techniek-team/fetch';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { rolesActions } from './+state/roles.actions';
import { RolesSelectors } from './+state/roles.selectors';
import { RoleContract, RoleDetailedContract } from './contracts/role.contract';
import { RoleDetailed } from './models/role-detailed.model';
import { Role } from './models/role.model';

@Injectable()
export class RolesStoreService implements FetchStorageInterface<RoleContract, Role> {
  private readonly store = inject(Store);
  public loading$: Observable<boolean> = this.store.pipe(select(RolesSelectors.loading));

  public loaded$: Observable<boolean> = this.store.pipe(select(RolesSelectors.loaded));

  public roles$: Observable<(Role | RoleDetailed)[]> = this.store.pipe(
    select(RolesSelectors.roles),
    isDefined(),
    map((data) => {
      return data.map((item: RoleContract | RoleDetailedContract) => {
        if (typeof item.businessService === 'string') {
          return denormalize(Role, item);
        }
        return denormalize(RoleDetailed, item);
      });
    }),
    shareReplay(),
  );

  public roles = this.store.selectSignal(RolesSelectors.roles);

  public roleEntities = this.store.selectSignal(RolesSelectors.roleEntities);

  public businessServiceEntitiesByRole = this.store.selectSignal(
    RolesSelectors.businessServiceEntitiesByRole,
  );

  public rolesMap$: Observable<Map<string, Role | RoleDetailed>> = this.store.pipe(
    select(RolesSelectors.roleEntities),
    map((data) => {
      return new Map(
        Object.entries(data).map(([key, item]) => {
          if (typeof item?.businessService === 'string') {
            return [key, denormalize(Role, item)];
          }
          return [key, denormalize(RoleDetailed, data)];
        }),
      );
    }),
    shareReplay(),
  );

  public rolesFilterGroup = this.store.selectSignal(RolesSelectors.rolesFilterGroup);

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(rolesActions.initRoles());
  }

  public reload(): void {
    this.store.dispatch(rolesActions.reloadRoles());
  }

  public supportsFetch(identifier: string): boolean {
    return identifier === 'Role';
  }

  public async waitForInitialization(): Promise<void> {
    await firstEmitFrom(
      this.store.pipe(
        select(RolesSelectors.loaded),
        filter((loaded) => loaded === true),
      ),
    );
  }

  public getFetchFromStorage(value: string): Observable<Role | undefined> {
    return this.store.pipe(
      select(RolesSelectors.denormalizedRoleEntities),
      map((dict) => dict[jsonLdSelectId(value)]),
      isDefined(),
    );
  }
}
