import { computed, Signal } from '@angular/core';
import { Role } from '@scheduler-frontend/data-access-roles';
import { ScheduleMinimal } from '@scheduler-frontend/schedule-contracts';
import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { SignalFetch } from '@techniek-team/fetch';
import { Exclude, Expose, Type } from 'class-transformer';
import { getISODay } from 'date-fns';
import { Lesson } from '../lesson/lesson.model';

/**
 * Standard version of the Slot Resource.
 */
export class Slot extends JsonLd {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Slot';

  /**
   * The schedule where this Slot is scheduled on.
   */
  @Type(() => ScheduleMinimal)
  @Expose()
  public schedule!: ScheduleMinimal;

  /**
   * Lesson to which this slot belongs.
   */
  @Type(() => Lesson)
  @Expose()
  public lesson!: Lesson;

  /**
   * Time period when this shift takes place
   */
  @Type(() => TsRange)
  @Expose()
  public timePeriod!: TsRange;

  /**
   * Role the candidate takes when doing this slot.
   */
  @SignalFetch(() => Role)
  @Expose()
  public role!: Signal<Role | undefined>;

  @Exclude() public get dayNumber(): Signal<number> {
    return computed(() => getISODay(this.timePeriod.start));
  }
}
