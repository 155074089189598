import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LyceoUser } from '@techniek-team/oauth';

export const usersActions = createActionGroup({
  source: 'Users',
  events: {
    initUser: emptyProps(),
    loadUserSuccess: props<{ user: LyceoUser }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadUserFailure: props<{ error: any }>(),
  },
});
