import { Routes } from '@angular/router';
import { provideCreateSlotsStore } from '@scheduler-frontend/create-slot-modal';
import { provideActionsStore } from '@scheduler-frontend/data-access-action';
import { provideApproachesStore } from '@scheduler-frontend/data-access-approaches';
import { provideAwaitingSlotsStore } from '@scheduler-frontend/data-access-awaiting-slots';
import { provideCandidatesByRankingStore } from '@scheduler-frontend/data-access-candidates-by-ranking';
import { provideRankingsStore } from '@scheduler-frontend/data-access-rankings';
import { provideSchedulesStore } from '@scheduler-frontend/data-access-schedules';
import { provideSearchStore } from '@scheduler-frontend/data-access-search';
import { LoginPage } from '@scheduler-frontend/login-page';
import { NotFoundComponent } from '@scheduler-frontend/not-found';
import { RoutesView } from '@scheduler-frontend/scheduling-common';
import { oauthAuthenticationGuard, pkceAuthentication } from '@techniek-team/oauth';
import { AppComponent } from './app/app.component';
import { HomePage } from './app/home/home.page';

/**
 * All routes are also specified in the {@see ViewPermission} so add the route also
 * there
 */
export const ROUTES: Routes = [
  {
    path: 'login',
    component: LoginPage,
    canActivate: [pkceAuthentication],
  },
  {
    path: '',
    component: AppComponent,
    canActivate: [pkceAuthentication, oauthAuthenticationGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        providers: [provideSearchStore(), provideAwaitingSlotsStore(), provideActionsStore()],
        component: HomePage,
        title: 'Roostertool - Home',
      },
      {
        path: 'locatierooster',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadComponent: () =>
          import('@scheduler-frontend/location-schedule').then((m) => m.LocationScheduleComponent),
        loadChildren: () =>
          import('@scheduler-frontend/scheduling-views').then((m) => [
            ...m.provideSchedulingViewRoutes(RoutesView.WEEK),
          ]),
        providers: [provideSearchStore(), provideRankingsStore(), provideSchedulesStore()],
        title: 'Roostertool - Locatie Rooster',
      },
      {
        path: 'inplannen',
        loadChildren: () => import('@scheduler-frontend/assign-candidate').then((m) => m.ROUTES),
        title: 'Roostertool - inplannen',
      },
      {
        path: 'benaderlijst',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadComponent: () =>
          import('@scheduler-frontend/page-approach-list').then((m) => m.ApproachListPage),
        providers: [provideApproachesStore(), provideCandidatesByRankingStore()],
        title: 'Roostertool - Benader lijst',
      },
      {
        path: 'benader-begeleider',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadChildren: () =>
          import('@scheduler-frontend/page-approach-candidate').then((m) => m.ROUTES),
        title: 'Roostertool - Begeleider Benaderen',
      },
      {
        path: 'daadwerkelijk-gewerkte-uren',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        children: [
          {
            path: 'doorgeven-begeleiders',
            loadComponent: () =>
              import(
                './app/hours-approval-overview/hours-approval-by-candidate-overview/hours-approval-by-candidate-overview.page'
              ).then((m) => m.HoursApprovalByCandidateOverviewPage),
            title: 'Roostertool - Daadwerkelijk gewerkte uren goedkeuren',
          },
          {
            path: 'doorgeven-vestigingsmanager',
            loadComponent: () =>
              import(
                './app/hours-approval-overview/hours-approval-via-scheduler-overview/hours-approval-via-scheduler-overview.page'
              ).then((m) => m.HoursApprovalViaSchedulerOverviewPage),
            title: 'Roostertool - Daadwerkelijk gewerkte uren goedkeuren',
          },
        ],
      },
      {
        path: 'uitbetaling',
        loadChildren: () => import('./app/payout/payout.routes').then((m) => m.ROUTES),
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
      },
      {
        path: 'zoeken',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadChildren: () =>
          Promise.all([
            import('@scheduler-frontend/search-overview'),
            import('@scheduler-frontend/search-results'),
          ]).then((modules) => modules.map((m) => m.ROUTES).flat(1)),
      },
      {
        path: 'wachtend-op-shift',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        providers: [provideAwaitingSlotsStore(), provideCreateSlotsStore()],
        children: [
          {
            path: 'eerste-shift',
            loadComponent: () =>
              import('@scheduler-frontend/awaiting-first-slot').then(
                (m) => m.AwaitingFirstSlotPage,
              ),
          },
          {
            path: 'vervolg-shift',
            loadComponent: () =>
              import('@scheduler-frontend/awaiting-future-slot').then(
                (m) => m.AwaitingFutureSlotPage,
              ),
          },
          { path: '', pathMatch: 'full', redirectTo: 'eerste-shift' },
        ],
      },
      {
        path: 'begeleiders',
        canActivate: [pkceAuthentication, oauthAuthenticationGuard],
        loadChildren: () => import('@scheduler-frontend/candidates').then((m) => m.ROUTES),
      },
      {
        path: 'location-overview',
        redirectTo: 'locatierooster',
      },
      {
        path: 'agenda',
        redirectTo: 'locatierooster',
      },
      {
        path: 'hours-approval-overview',
        redirectTo: 'daadwerkelijk-gewerkte-uren',
      },
      {
        path: 'search',
        redirectTo: 'zoeken',
      },
      {
        path: 'candidate-search',
        redirectTo: 'begeleiders',
      },
      {
        path: 'dashboard',
        redirectTo: '/home',
      },
      {
        path: 'location-payout-approval-overview',
        redirectTo: 'uitbetaling/locatie',
      },
      {
        path: 'payout-approval-overview',
        redirectTo: 'uitbetaling/hoofdkantoor',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: '**',
    canActivate: [pkceAuthentication, oauthAuthenticationGuard],
    component: NotFoundComponent,
  },
];
