import { createActionGroup, props } from '@ngrx/store';
import { LessonDetailedContract } from '@scheduler-frontend/assignment-contracts';
import {
  BusinessServiceContract,
  BusinessServiceDetailedContract,
  DeliveryTypeContract,
} from '@scheduler-frontend/data-access-business-services';
import { LocationContract } from '@scheduler-frontend/data-access-locations';
import { RoleDetailedContract } from '@scheduler-frontend/data-access-roles';
import { SubjectContract } from '@scheduler-frontend/data-access-subjects';
import { LevelEnum } from '@scheduler-frontend/enums';
import { ScheduleContract, ScheduleDetailedContract } from '@scheduler-frontend/schedule-contracts';
import { TsRangeInterface } from '@techniek-team/common';

export const slotTemplateActions = createActionGroup({
  source: '[CreateSlots/SlotTemplate]',
  events: {
    changeFormValidity: props<{ change: boolean }>(),
    changeProductLocation: props<{ change: LocationContract }>(),
    changeDeliveryType: props<{ change: DeliveryTypeContract | null }>(),
    changeBusinessService: props<{
      change: BusinessServiceContract | BusinessServiceDetailedContract;
    }>(),
    changeBusinessServiceSuccess: props<{
      change: BusinessServiceDetailedContract;
    }>(),
    changeWho: props<{
      index: number;
      change: {
        role: RoleDetailedContract;
        timeRange: TsRangeInterface<string>;
        amountOfPupils: number | null;
        alternativeLocation: LocationContract | null;
        subject: SubjectContract | null;
        level: LevelEnum | null;
      };
    }>(),
    changeWhoRole: props<{ index: number; change: RoleDetailedContract }>(),
    changeWhoTimeRange: props<{ index: number; change: TsRangeInterface<string> }>(),
    changeWhoAmountOfPupils: props<{ index: number; change: number }>(),
    changeWhoOperationalLocation: props<{ index: number; change: LocationContract | null }>(),
    changeWhoSubject: props<{ index: number; change: SubjectContract | null }>(),
    changeWhoLevel: props<{ index: number; change: LevelEnum | null }>(),
    changeSchedule: props<{ change: ScheduleContract | ScheduleDetailedContract }>(),
    changeScheduleSuccess: props<{ change: ScheduleDetailedContract }>(),
    changeWhen: props<{ change: LessonDetailedContract[] | string[] }>(), // iso 8601 date
  },
});
