import { createActionGroup, props } from '@ngrx/store';
import { LocationContract } from '../contracts/location.contract';
import { LocationTypeEnum } from '../enums/location-type.enum';

export const locationsActions = createActionGroup({
  source: 'Locations',
  events: {
    initLocations: props<{
      includeArchived: boolean;
      locationTypes: LocationTypeEnum[];
    }>(),
    reloadLocations: props<{
      includeArchived: boolean;
    }>(),
    loadFromCacheLocationsSuccess: props<{
      locations: LocationContract[];
      totalItems: number;
      locationTypes: LocationTypeEnum[];
      cacheTimestamp: string; // iso 8601 date
    }>(),
    loadLocationsSuccess: props<{
      locations: LocationContract[];
      totalItems: number;
      locationTypes: LocationTypeEnum[];
    }>(),
    loadPupilPrivateLocations: props<{
      includeArchived: boolean;
      pupil?: string;
    }>(),
    loadPupilPrivateLocationsSuccess: props<{
      locations: LocationContract[];
      totalItems: number;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadPupilPrivateLocationsFailure: props<{ error: any }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadLocationsFailure: props<{ error: any }>(),
    selectLocation: props<{ locationId: string | undefined }>(),
  },
});
