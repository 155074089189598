import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BusinessServiceContract,
  BusinessServicesSelectors,
} from '@scheduler-frontend/data-access-business-services';
import { denormalize } from '@techniek-team/class-transformer';
import { Filter, FilterGroup } from '@techniek-team/search';
import { jsonLdSelectId, toDictionary } from '@techniek-team/tt-ngrx';
import { RoleContract, RoleDetailedContract } from '../contracts/role.contract';
import { Role } from '../models/role.model';
import { ROLES_FEATURE_KEY, rolesAdapter, RolesState } from './roles.reducer';

const { selectAll, selectEntities } = rolesAdapter.getSelectors();

export class RolesSelectors {
  public static readonly rolesState = createFeatureSelector<RolesState>(ROLES_FEATURE_KEY);

  public static readonly loading = createSelector(
    RolesSelectors.rolesState,
    (state: RolesState) => state.loading,
  );

  public static readonly loaded = createSelector(
    RolesSelectors.rolesState,
    (state: RolesState) => state.loaded,
  );

  public static readonly cacheTimestamp = createSelector(
    RolesSelectors.rolesState,
    (state: RolesState) => state.cacheTimeStamp,
  );

  public static readonly error = createSelector(
    RolesSelectors.rolesState,
    (state: RolesState) => state.error,
  );

  public static readonly roles = createSelector(RolesSelectors.rolesState, (state: RolesState) =>
    selectAll(state),
  );

  public static readonly roleEntities = createSelector(
    RolesSelectors.rolesState,
    (state: RolesState) => selectEntities(state),
  );

  public static readonly businessServiceEntitiesByRole = createSelector(
    RolesSelectors.roleEntities,
    BusinessServicesSelectors.businessServiceEntities,
    (roles, businessServices) => {
      if (!roles || !businessServices) {
        return;
      }
      const businessServiceByRoleDict = {} as Dictionary<BusinessServiceContract>;
      for (let [roleId, role] of Object.entries<RoleContract | RoleDetailedContract>(roles as {})) {
        if (typeof role.businessService === 'string') {
          businessServiceByRoleDict[roleId] = businessServices[role.businessService];
          continue;
        }
        businessServiceByRoleDict[roleId] = role.businessService;
      }
      return businessServiceByRoleDict;
    },
  );

  public static readonly denormalizedRoleEntities = createSelector(
    RolesSelectors.rolesState,
    (state) =>
      toDictionary(denormalize(Role, Object.values(state.entities)), (item) => item.getId()),
  );

  public static readonly rolesFilterGroup = createSelector(
    RolesSelectors.roles,
    BusinessServicesSelectors.businessServiceEntities,
    (role, businessService) => {
      return new FilterGroup(
        'slot.role',
        (role ?? []).map(
          (item) =>
            new Filter(item['@id'] as string, {
              label: item.businessService
                ? `${item.name} ${typeof item.businessService === 'string' ? businessService[jsonLdSelectId(item.businessService)]?.name : item.businessService?.name}`
                : item.name,
              additionalData: item,
            }),
        ),
        { multiple: true, label: 'Rollen', itemLabel: 'Rol' },
      );
    },
  );

  public static readonly searchQueryMapper = createSelector(RolesSelectors.roleEntities, (dict) => {
    return {
      'slot.role': {
        entities: dict,
        getDisplayText: (item: RoleContract | RoleDetailedContract) => item.name,
      },
    };
  });
}
