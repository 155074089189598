<ion-app>
  <tt-menu [header]="'Roostertool'" (isExpandedChange)="isExpanded.set($event)">
    <tt-menu-item [routerLink]="['/home']" [icon]="faChartUser" [title]="'Dashboard'">
    </tt-menu-item>
    @if (usersViewPermissionStoreService.viewSearchPage()) {
      <tt-menu-item [routerLink]="['/zoeken']" [icon]="faMagnifyingGlass" [title]="'Zoeken'">
      </tt-menu-item>
    }

    @if (usersViewPermissionStoreService.viewCandidatesPage()) {
      <tt-menu-item
        [routerLink]="['/benaderlijst']"
        [icon]="faCalendarUsers"
        [title]="'Benaderlijst'"
      >
      </tt-menu-item>
    }

    @if (usersViewPermissionStoreService.viewLocationAgendaPage()) {
      <tt-menu-item
        [routerLink]="['/locatierooster']"
        [icon]="faCalendarDays"
        [title]="'Locatierooster'"
      >
      </tt-menu-item>
    }
    @if (usersViewPermissionStoreService.viewCandidatesPage()) {
      <tt-menu-item [routerLink]="['/begeleiders']" [icon]="faUserGroup" [title]="'Begeleiders'">
      </tt-menu-item>
    }

    <tt-menu-item
      [routerLink]="['/wachtend-op-shift']"
      [icon]="faSnooze"
      [title]="'Wachtend op shift'"
      [submenu]="awaitingSlotsSubmenu"
    >
    </tt-menu-item>

    <ng-template #awaitingSlotsSubmenu>
      <tt-sub-menu-item [routerLink]="['/wachtend-op-shift/eerste-shift']"
        >Eerste shift</tt-sub-menu-item
      >
      <tt-sub-menu-item [routerLink]="['/wachtend-op-shift/vervolg-shift']"
        >Vervolg-shift</tt-sub-menu-item
      >
    </ng-template>

    @if (usersViewPermissionStoreService.viewHQHoursApprovalPage()) {
      <tt-menu-item
        [routerLink]="['/daadwerkelijk-gewerkte-uren/doorgeven-begeleiders']"
        [icon]="faAlarmClock"
        [title]="'Uren doorgeven app'"
      >
      </tt-menu-item>
    }
    @if (usersViewPermissionStoreService.viewLocationHoursApprovalPage()) {
      <tt-menu-item
        [routerLink]="['/daadwerkelijk-gewerkte-uren/doorgeven-vestigingsmanager']"
        [icon]="faCalendarClock"
        [title]="'Daadwerkelijke uren'"
      >
      </tt-menu-item>
    }
    @if (usersViewPermissionStoreService.viewHQPayoutPage()) {
      <tt-menu-item
        [routerLink]="['/uitbetaling']"
        [icon]="faFileInvoice"
        [submenu]="payoutSubmenu"
        [title]="'Uitbetaaloverzicht'"
      >
      </tt-menu-item>

      <ng-template #payoutSubmenu>
        <tt-sub-menu-item [routerLink]="['/uitbetaling/hoofdkantoor']"
          >Hoofdkantoor</tt-sub-menu-item
        >
        <tt-sub-menu-item [routerLink]="['/uitbetaling/locatie']">Locatie</tt-sub-menu-item>
      </ng-template>
    } @else if (usersViewPermissionStoreService.viewLocationPayoutPage()) {
      <tt-menu-item
        [routerLink]="['/uitbetaling/locatie']"
        [icon]="faFileInvoiceDollar"
        [title]="'Uitbetaaloverzicht'"
      ></tt-menu-item>
    }

    <tt-menu-item (click)="launchBeta()" [title]="'Beta Aanzetten'" [class.beta]="isBeta">
      <div class="beta-icon" [class.expanded]="isExpanded()">
        <fa-icon [slot]="'start'" [icon]="faFlask"></fa-icon>
        <ion-badge class="beta-badge" [color]="isBeta ? 'secondary' : 'medium'">new</ion-badge>
        <ion-label [style.display]="isExpanded() ? 'none' : 'block'">Beta</ion-label>
      </div>
      <ion-toggle
        color="secondary"
        [style.display]="isExpanded() ? 'block' : 'none'"
        justify="space-between"
        [checked]="isBeta"
        [slot]="'end'"
      >
        Beta
      </ion-toggle>
    </tt-menu-item>

    <app-logged-in-user slot="footer" [displayDetails]="isExpanded()"></app-logged-in-user>
  </tt-menu>
</ion-app>
