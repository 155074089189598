@if (locationsStoreService.locations$ | async; as locations) {
  <tt-ion-select-search
    data-test="location-select"
    lines="none"
    [color]="(colorSubject$ | async) ?? IonColorType.DARK"
    [formControl]="locationSearchControl"
    [width]="475"
    [alignment]="'center'"
    [options]="locations"
  >
  </tt-ion-select-search>
} @else {
  <ion-skeleton-text animated="true"></ion-skeleton-text>
}
