import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Exclude, Expose, Type } from 'class-transformer';
import { isFuture, isPast, isWithinInterval } from 'date-fns';
import { DeliveryTypeEnum } from '../enums/delivery-type.enum';

/**
 * Standard version of the DeliveryType resource from Scheduler-api.
 */
export class DeliveryType extends JsonLd {
  /**
   * @inheritDoc
   */
  @Exclude() public override readonly className: string = 'DeliveryType';

  /**
   * Type of Delivery
   *
   * This is a type of way on how we supply the customer with this service.
   * {@see DeliveryTypeEnum}
   */
  @Expose() public deliveryType!: DeliveryTypeEnum;

  @Type(() => TsRange)
  @Expose()
  public validityRange!: TsRange;

  @Exclude() public isPast(): boolean {
    return isPast(this.validityRange.end);
  }

  @Exclude() public isFuture(): boolean {
    return isFuture(this.validityRange.start);
  }

  @Exclude() public isValidOnDate(date: Date): boolean {
    return isWithinInterval(date, {
      start: this.validityRange.start,
      end: this.validityRange.end,
    });
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.deliveryType;
  }
}
