import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { AbsentModalComponent } from '@scheduler-frontend/absent-modal';
import {
  AssignmentContract,
  AssignmentDetailedContract,
  AssignmentStateEnum,
  isBeforeState,
  isState,
} from '@scheduler-frontend/assignment-contracts';
import { absenceActions } from '@scheduler-frontend/data-access-absence';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import { DeclineReason } from '@scheduler-frontend/data-access-decline-reasons';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { OverlayEventDetail } from '@techniek-team/ionic-mocks/lib/overlay-base-controller.mock';
import { isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, filter, from, map, Observable, of, switchMap } from 'rxjs';
import { TransitionEnum } from '../../enums/transition.enum';
import { activeAssignmentActions } from '../actions/active-assignment.actions';
import { assignmentsActions } from '../actions/assignments.actions';
import { AssignmentApi } from '../api/assignment/assignment.api';
import { AssignmentsSelectors } from '../selectors/assignments.selectors';

@Injectable()
export class UnassignAssignmentsEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly modalController = inject(ModalController);

  private readonly assignmentApi = inject(AssignmentApi);

  public readonly earlyStageUnassignCompleteAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentsActions.unassign, activeAssignmentActions.unassign),
      concatLatestFrom((action) => [
        this.store.pipe(select(AssignmentsSelectors.selectAssignmentOrActive(action)), isDefined()),
        this.store.pipe(
          select(AssignmentsSelectors.selectAssignmentCandidateOrActive(action)),
          isDefined(),
        ),
      ]),
      filter(([_action, assignment, candidate]) => {
        return isBeforeState(
          (assignment as AssignmentContract).state,
          AssignmentStateEnum.WAITING_FOR_CONFIRMATION,
        );
      }),
      switchMap(([action, assignment, candidate]) => {
        return this.createConfirmModalObservable(
          `Wil je de begeleider volledig uitroosteren?`,
        ).pipe(
          filter(({ role }) => role === 'confirm'),
          map(() =>
            assignmentsActions.removeSlotFormAssignment({
              slots: (assignment as AssignmentDetailedContract).assignmentHasSlots.map((item) =>
                jsonLdSelectId(item.slot['@id']),
              ),
            }),
          ),
          catchError((error) => of(assignmentsActions.unassignFailure({ error: error }))),
        );
      }),
    ),
  );

  public readonly earlyStageUnassignSingleSlotFromAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentsActions.unassignSlot, activeAssignmentActions.unassignSlot),
      concatLatestFrom((action) => [
        this.store.pipe(select(AssignmentsSelectors.selectAssignmentOrActive(action)), isDefined()),
        this.store.pipe(
          select(AssignmentsSelectors.selectAssignmentCandidateOrActive(action)),
          isDefined(),
        ),
      ]),
      filter(([_action, assignment]) => {
        return isBeforeState(assignment.state, AssignmentStateEnum.WAITING_FOR_CONFIRMATION);
      }),
      switchMap(([action, assignment, candidate]) => {
        return this.createConfirmModalObservable(
          `Begeleider ${candidate.fullName} zal worden ontkoppeld van deze shift.`,
        ).pipe(
          filter(({ role }) => role === 'confirm'),
          map(() =>
            assignmentsActions.removeSlotFormAssignment({
              slots: [action.assignmentHasSlot],
            }),
          ),
          catchError((error) => of(assignmentsActions.unassignSlotFailure({ error: error }))),
        );
      }),
    ),
  );

  //eslint-disable-next-line max-lines-per-function
  public readonly laterStageUnassignCompleteAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentsActions.unassign, activeAssignmentActions.unassign),
      concatLatestFrom((action) => [
        this.store.pipe(select(AssignmentsSelectors.selectAssignmentOrActive(action)), isDefined()),
        this.store.pipe(
          select(AssignmentsSelectors.selectAssignmentCandidateOrActive(action)),
          isDefined(),
        ),
      ]),
      filter(([_, assignment]) =>
        isState(assignment.state, AssignmentStateEnum.PROVISIONALLY_CONFIRMED),
      ),
      switchMap(([action, assignment, candidate]) => {
        return this.showDeclineReason(assignment, true).pipe(
          map(({ role, data }) => {
            const hasSlots = (assignment as AssignmentDetailedContract).assignmentHasSlots.map(
              (item) => jsonLdSelectId(item.slot['@id']),
            );
            const candidateId = jsonLdSelectId(candidate['@id']);
            if (role === 'sick') {
              return absenceActions.markAsAbsent({
                absencePeriod: (assignment as AssignmentDetailedContract)
                  .period as PlainRangeInterface,
                assignmentHasSlots: hasSlots,
                candidate: candidateId,
              });
            }
            if (role === 'confirm' && data) {
              return candidatesActions.markCandidateRejection({
                candidate: candidateId,
                slots: hasSlots,
                declineReason: data.getId(),
              });
            }
            return undefined;
          }),
          isDefined(),
          catchError((error) => of(assignmentsActions.unassignSlotFailure({ error: error }))),
        );
      }),
    ),
  );

  public readonly transitionToUnassignOnCandidateRejectionSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesActions.markCandidateRejectionSuccess),
      concatLatestFrom((action) => [
        this.store.pipe(select(AssignmentsSelectors.selectAssignmentOrActive(action)), isDefined()),
      ]),
      map(([action, assignment]) =>
        assignmentsActions.applyTransition({
          assignment: jsonLdSelectId(assignment['@id']),
          transition: TransitionEnum.UNASSIGN,
        }),
      ),
    ),
  );

  public readonly laterStageUnassignSingleSlotFromAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentsActions.unassignSlot, activeAssignmentActions.unassignSlot),
      concatLatestFrom((action) => [
        this.store.pipe(select(AssignmentsSelectors.selectAssignmentOrActive(action)), isDefined()),
        this.store.pipe(
          select(AssignmentsSelectors.selectAssignmentCandidateOrActive(action)),
          isDefined(),
        ),
      ]),
      filter(([_action, assignment]) => {
        return isState(assignment.state, AssignmentStateEnum.PROVISIONALLY_CONFIRMED);
      }),
      switchMap(([action, assignment, candidate]) => {
        return this.createConfirmModalObservable(
          `Begeleider ${candidate.fullName} zal worden ontkoppeld van deze shift.`,
        ).pipe(
          filter(({ role }) => role === 'sick'),
          map(() => {
            const defaultPeriod = (
              assignment as AssignmentDetailedContract
            ).assignmentHasSlots.find((hasSlot) => hasSlot['@id'] === action.assignmentHasSlot)
              ?.slot.timePeriod as PlainRangeInterface;

            return absenceActions.markAsAbsent({
              candidate: jsonLdSelectId(candidate['@id']),
              assignmentHasSlots: [action.assignmentHasSlot],
              absencePeriod: action.absencePeriod ?? defaultPeriod,
            });
          }),
          catchError((error) => of(assignmentsActions.unassignSlotFailure({ error: error }))),
        );
      }),
    ),
  );

  public readonly removeSlotFromAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentsActions.removeSlotFormAssignment),
      switchMap((action) =>
        this.assignmentApi.removeSlot(action.slots).pipe(
          map((response) => {
            if (response.failed.length > 0) {
              throw new Error("one or more slots aren't removed");
            }
            return assignmentsActions.removeSlotFormAssignmentSuccess({ slots: action.slots });
          }),
          catchError((error) =>
            of(assignmentsActions.removeSlotFormAssignmentFailure({ error: error })),
          ),
        ),
      ),
    ),
  );

  private showDeclineReason(
    target: AssignmentContract,
    absentForFullAssignment: boolean = false,
  ): Observable<OverlayEventDetail<DeclineReason | undefined>> {
    return from(
      this.modalController.create({
        component: AbsentModalComponent,
        componentProps: {
          assignment: target,
          absentForFullAssignment: absentForFullAssignment,
        },
        cssClass: ['stack-modal'],
      }),
    ).pipe(
      switchMap((response) => response.present().then(() => response)),
      switchMap((response) => response.onWillDismiss<DeclineReason | undefined>()),
    );
  }

  private createConfirmModalObservable(message: string): Observable<OverlayEventDetail> {
    return from(
      this.modalController.create({
        component: TtSimpleModalComponent,
        componentProps: {
          title: 'Shift openstellen',
          message: message,
        },
      }),
    ).pipe(
      switchMap((confirmModal) => confirmModal.present().then(() => confirmModal)),
      switchMap((confirmModal) => confirmModal.onWillDismiss()),
    );
  }
}
