import { PersonContract } from '@scheduler-frontend/data-access-users';
import { LevelEnum } from '@scheduler-frontend/enums';
import { JsonLdInterface } from '@techniek-team/api-platform';
import { ApproachState } from '../enums/approach-state.enum';
import { ApproachHasEventContract } from './approach-has-event.contract';
import { ApproachHasSlotContract } from './approach-has-slot.contract';

export interface ApproachContract extends JsonLdInterface {
  candidate: PersonContract;

  person: PersonContract;

  state: ApproachState;

  numberOfSlots: number;

  latestCommunicationEvent?: ApproachHasEventContract;

  approachHasEvents: ApproachHasEventContract[];
}

export interface ApproachDetailedContract extends ApproachContract {
  approachHasSlots: ApproachHasSlotContract[];

  filters: {
    businessServices: string[]; // ids
    roles: string[]; // ids
    subjects: string[]; // ids
    educationTypes: LevelEnum[];
  };
}

export function isApproachDetailed(
  approach: ApproachContract | ApproachDetailedContract,
): approach is ApproachDetailedContract {
  return approach?.hasOwnProperty('approachHasSlots');
}
