import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { AssignmentsStoreService } from '@scheduler-frontend/data-access-assignment';
import { AssignmentHasSlotItemComponent } from './assignment-has-slot-item/assignment-has-slot-item.component';

export type AssignmentHasSlotRowFormValue = FormGroup<AssignmentHasSlotRowForm>['value'];

export interface AssignmentHasSlotRowForm {
  breakTime: FormControl<number>;

  actualTimePeriod: FormGroup<{
    start: FormControl<Date>;
    end: FormControl<Date>;
  }>;
}

@Component({
  selector: 'app-assignment-has-slot-table',
  standalone: true,
  templateUrl: './assignment-has-slot-table.component.html',
  styleUrls: ['./assignment-has-slot-table.component.scss'],
  imports: [LetDirective, AssignmentHasSlotItemComponent],
})
export class AssignmentHasSlotTableComponent {
  protected readonly displayedColumns: string[] = [
    'marking',
    'location',
    'date',
    'time',
    'breakTime',
    'actualTime',
    'role',
    'saldo',
    'actions',
  ];

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);
}
