import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_FEATURE_KEY, UsersState } from './users.reducer';

export class UsersSelectors {
  public static readonly selectUsersState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);

  public static readonly selectUserLoading = createSelector(
    UsersSelectors.selectUsersState,
    (state) => state.loading,
  );

  public static readonly selectUserLoaded = createSelector(
    UsersSelectors.selectUsersState,
    (state) => state.loaded,
  );

  public static readonly selectUserError = createSelector(
    UsersSelectors.selectUsersState,
    (state) => state.error,
  );

  public static readonly activeUser = createSelector(
    UsersSelectors.selectUsersState,
    (state: UsersState) => state.user,
  );

  public static readonly roles = createSelector(
    UsersSelectors.activeUser,
    (state) => (state?.roles ?? []) as string[],
  );

  public static readonly isAdmin = createSelector(
    UsersSelectors.roles,
    (roles) => roles?.includes('ROLE_SKOLEO_TT_PLANNER_ADMIN') ?? false,
  );

  public static readonly isLocationManager = createSelector(
    UsersSelectors.roles,
    (roles) =>
      roles?.some((role) =>
        [
          'ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM',
          'ROLE_SKOLEO_TT_PLANNER_ADMIN',
          'ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT',
        ].includes(role),
      ) ?? false,
  );

  public static readonly isSchedulingTeam = createSelector(
    UsersSelectors.roles,
    (roles) =>
      roles?.some((role) =>
        ['ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM', 'ROLE_SKOLEO_TT_PLANNER_ADMIN'].includes(role),
      ) ?? false,
  );
}
