import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import { catchError, map, of, switchMap } from 'rxjs';
import { schedulingApproachCandidatesActions } from '../actions/scheduling-approach-candidates.actions';
import { CommunicateApproachApi } from '../api/communicate-approach.api';
import { CreateApproachApi } from '../api/create-approach.api';
import { SchedulingApproachSelectors } from '../selectors/scheduling-approach.selectors';

export class SchedulingApproachCandidatesRemindEffect {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly createApproachApi = inject(CreateApproachApi);

  private readonly communicateApproachApi = inject(CommunicateApproachApi);

  public updateApproachOnCandidateRemind = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingApproachCandidatesActions.remindCandidate),
      concatLatestFrom(() => [
        this.store.pipe(select(SchedulingApproachSelectors.activeApproachId), isDefined()),
      ]),
      switchMap(([action, activeApproach]) => {
        return this.communicateApproachApi
          .execute(
            action?.approach ? jsonLdSelectId(action?.approach) : activeApproach,
            action.approachThrough,
            action.hasAnsweredThePhone,
          )
          .pipe(
            map((approach) =>
              schedulingApproachCandidatesActions.remindCandidateSuccess({
                approach: approach,
              }),
            ),
            catchError((error) =>
              of(schedulingApproachCandidatesActions.remindCandidateFailure({ error: error })),
            ),
          );
      }),
    ),
  );

  public remindCandidateFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(schedulingApproachCandidatesActions.remindCandidateFailure, {
          message:
            'Er is iets misgegaan bij het opslaan dat je een herinnering hebt gestuurd naar deze kandidaat.',
        }),
      ),
    { dispatch: false },
  );

  public remindCandidateSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(schedulingApproachCandidatesActions.remindCandidateSuccess, {
          message:
            'We hebben het feit dat je deze begeleider hebt herinnerd successful opgeslagen.',
        }),
      ),
    { dispatch: false },
  );
}
