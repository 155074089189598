import { Dictionary } from '@ngrx/entity/src/models';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { hashParams } from '@scheduler-frontend/common';
import { memoize } from 'lodash-es';

/**
 * A memoized function that calculates the maximum count of slots in any ISO day.
 *
 * This function receives a Dictionary where the keys are ISO day numbers and
 * the values are arrays of SlotContract or SlotDetailedContract.
 * It returns the maximum count of slots available in any ISO day.
 *
 * @param {Dictionary<(SlotContract | SlotDetailedContract)[]>} groupedByDay - A dictionary where
 *  the keys are ISO day numbers and the values are arrays of slot contracts.
 * @returns {number} - The maximum count of slots within a single day.
 *
 * @example
 * const groupedByDay = {
 *   "1": [{...slotData1}, {...slotData2}],
 *   "2": [{...slotData3}, {...slotData4}, {...slotData5}],
 * };
 *
 * // The resulting maxSlots will be: 3
 * const maxSlots = maxSlotsCount(groupedByDay);
 */
export const maxSlotsCount = memoize(
  (groupedByDay: Dictionary<(SlotContract | SlotDetailedContract)[]>): number => {
    const group = Object.values(groupedByDay);

    if (group.length === 0) {
      return 0;
    }

    return Math.max(
      ...group.map((slots) => {
        return slots?.length ?? 0;
      }),
    );
  },
  hashParams,
);
