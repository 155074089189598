import { Action, createReducer, on } from '@ngrx/store';
import { editSlotActions } from './edit-slot.actions';

export const EDIT_SLOT_FEATURE_KEY = 'EditSlot';

export interface EditSlotsState {
  selectedSlotId?: string;
  loading: boolean;
  submitting: boolean;
  error?: unknown;
}

export const initialEditSlotState: EditSlotsState = {
  loading: false,
  submitting: false,
};

const reducer = createReducer(
  initialEditSlotState,
  on(editSlotActions.openEditSlotModal, (state, { slotId }) => {
    return { ...state, selectedSlotId: slotId };
  }),
  on(editSlotActions.submitEditSlot, (state) => {
    return { ...state, submitting: true };
  }),
  on(editSlotActions.submitEditSlotSuccess, (state) => {
    return { ...state, submitting: false };
  }),
  on(editSlotActions.submitEditSlotFailure, (state, { error }) => {
    return { ...state, submitting: false, error: error };
  }),
);

export function createEditSlotReducer(state: EditSlotsState | undefined, action: Action) {
  return reducer(state, action);
}
