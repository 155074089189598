import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RoutesView } from '@scheduler-frontend/scheduling-common';

export const schedulingViewActions = createActionGroup({
  source: '[Scheduling/View]',
  events: {
    setSchedulingView: props<{
      view?: RoutesView;
      dateInView?: string; // iso format date
      selectedLocations?: string[];
      activeCandidate?: string;
      selectedProductTypes?: string[];
      selectedSlots?: string[];
      selectedCandidates?: string[];
    }>(),
    nextRangeInView: emptyProps(),
    previousRangeInView: emptyProps(),
    changeCurrentView: props<{ view: RoutesView; date?: string }>(),
    changeCurrentViewSuccess: props<{ view: RoutesView; slotList: string[]; date?: string }>(),

    /**
     * This actions start/stop shown they `inplannen` and `afwijzen` button with the
     * {@link SlotComponent} or {@link AssignmentSlotsItemCardComponent}
     */
    showInlineAssignButtons: emptyProps(),
    hideInlineAssignButtons: emptyProps(),

    showSlotSelectionCheckbox: emptyProps(),
    hideSlotSelectionCheckbox: emptyProps(),

    showOnlySelectedSlotsInView: emptyProps(),
    stopShowingOnlySelectedSlotsInView: emptyProps(),
  },
});
