import { Component, ContentChild, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TemplateRefDirective } from '@techniek-team/common';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgTemplateOutlet, AsyncPipe, SlicePipe, DecimalPipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';

export enum TtStatisticCardNotation {
  PERCENTAGE = 'PERCENTAGE',
  OF_TOTAL = 'OF_TOTAL',
  NONE = 'NONE',
}

@Component({
    selector: 'tt-statistic-card',
    templateUrl: './tt-statistic-card.component.html',
    styleUrls: ['./tt-statistic-card.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        NgTemplateOutlet,
        AsyncPipe,
        SlicePipe,
        DecimalPipe,
    ],
})
export class TtStatisticCardComponent implements OnInit, OnDestroy {
  /**
   * If found, the content of this template will be shown as an icon.
   */
  @ContentChild(TemplateRefDirective) public iconRef: TemplateRefDirective | undefined;

  public readonly TtStatisticCardNotation: typeof TtStatisticCardNotation = TtStatisticCardNotation;

  /**
   * Color of the Statistics text.
   */
  @Input() public set color(color: IonColor) {
    if (color) {
      this.colorSubject$.next(color);
    }
  }

  /**
   * Optional title which will be set above or below the value.
   */
  @Input() public title: string | undefined;

  /**
   * Either place the title at the top or bottom of the statistic card.
   *
   * Note: This is only used when title is set.
   */
  @Input() public titlePlacement: 'top' | 'bottom' = 'top';

  /**
   * Optional prefix for the statistic value
   */
  @Input() public statisticPrefix: string | undefined;

  /**
   * Suffix for the statistic value.
   *
   * For example when the value is 1 and the statisticName is 'opdracht'
   * the card displays `1 opdracht`.
   *
   * Note: You need to set both this and {@see statisticNamePlural} for it to
   * work. If only one is set the parameter is ignored.
   */
  @Input() public statisticName: string | undefined;

  /**
   * Suffix for the statistic value. for example when the value is 25 and the
   * statisticNamePlural is 'opdrachten' the card display `25 opdrachten`
   *
   * Note: You need to set both this and {@see statisticName} for it to
   * work. If only one is set the parameter are ignored.
   */
  @Input() public statisticNamePlural: string | undefined;

  /**
   * The previous statistic value. When this is set the card calculates the
   * difference (or different percentage).
   *
   * Note: When this parameter is empty is doesn't show the chart icon and or
   * any differences.
   */
  @Input() public previousValue: number | undefined;

  /**
   * The value's decimal representation is specified by the `digitsInfo`
   * parameter, written in the following format:<br>
   *
   * ```
   * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
   * ```
   *
   *  - `minIntegerDigits`:
   * The minimum number of integer digits before the decimal point.
   * Default is 1.
   *
   * - `minFractionDigits`:
   * The minimum number of digits after the decimal point.
   * Default is 0.
   *
   *  - `maxFractionDigits`:
   * The maximum number of digits after the decimal point.
   * Default is 0.
   *
   * If the formatted value is truncated it will be rounded using the "to-nearest" method:
   *
   * ```
   * {{3.6 | number: '1.0-0'}}
   * <!--will output '4'-->
   *
   * {{-3.6 | number:'1.0-0'}}
   * <!--will output '-4'-->

   * {{3.64736 | number:'1.0-3'}}
   * <!--will output '3.648'-->
   *
   * {{4 | number:'1.2-0'}}
   * <!--will output '4.00'-->
   * ```
   **/
  @Input() public digitsInfo: string = '1.0-0';

  /**
   * The 'total' value to display.
   */
  @Input() public total: number | 'infinite' = 'infinite';

  /**
   * Whether the percentage will be shown inside the statistic card.
   */
  @Input() public notation: TtStatisticCardNotation | string = TtStatisticCardNotation.OF_TOTAL;

  /**
   * If true, a button tag will be rendered and the card will be tappable.
   */
  @Input() public button: boolean = false;

  /**
   * Show a spinner and obfuscate the content of the card.
   */
  @Input() public set loading(state: boolean) {
    this.loading$.next(state);
  }

  /**
   * Set the value of the internal observable for value on the statistic card.
   */
  @Input() public set value(value: number | undefined | null) {
    this.valueSubject$.next(value ?? 0);
  }

  /**
   * Calculated value as an observable.
   */
  protected value$: Observable<number>;

  /**
   * Loading state as an observable, so the template doesn't go haywire.
   */
  protected loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Percentage filled.
   */
  protected differenceWithPrevious$: Observable<number>;

  /**
   * The internal value of the statistic card.
   */
  protected valueSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /**
   * Subject to clean-up subscriptions onDestroy.
   */
  private destroy$: Subject<void> = new Subject<void>();

  /**
   * Color of the statistics text.
   */
  private colorSubject$: BehaviorSubject<IonColor> = new BehaviorSubject<IonColor>(IonColorType.DARK);

  constructor(private element: ElementRef, private renderer: Renderer2) {
    this.value$ = this.createValueObservable();
    this.differenceWithPrevious$ = this.createCalculateObservable();
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createColorSubscriber();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Create an observable for the mapping of the current value in the value
   * subject, so it only needs to calculate when the value changes.
   */
  private createValueObservable(): Observable<number> {
    return this.valueSubject$.pipe(
      map((value) => {
        if (this.total === 'infinite') {
          return Math.max(value ?? 0, 0);
        }
        return Math.min(this.total, Math.max(value ?? 0, 0));
      }),
    );
  }

  /**
   * Do some calculations to render a correct statistic card.
   */
  private createCalculateObservable(): Observable<number> {
    return this.valueSubject$
      .pipe(
        takeUntil(this.destroy$),
        map(value => {
          if (this.total === 'infinite') {
            value = Math.max(value ?? 0, 0);
          } else {
            value = Math.min(this.total, Math.max(value ?? 0, 0));
          }

          if (this.notation === TtStatisticCardNotation.PERCENTAGE) {
            const total: number = (this.total === 'infinite') ? 100 : this.total;
            return ((value - (this.previousValue ?? 0)) / total) * 100;
          }
          return (value - (this.previousValue ?? 0));
        }),
      );
  }

  private createColorSubscriber(): void {
    this.colorSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe(color => {
        const previousColorClasses: string[] = this.element.nativeElement.className.split(' ')
          .filter((item: string) => {
            if (item) {
              return item.match(/ion-color-*./);
            }
            return false;
          });

        for (let className of previousColorClasses) {
          this.renderer.removeClass(this.element.nativeElement, className);
        }

        this.renderer.addClass(this.element.nativeElement, 'ion-color-'+ (color ?? 'primary'));
      });
  }

}
