import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TransitionEnum } from '@scheduler-frontend/data-access-assignment';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';
import { ApplyTransitionResponse } from './transition.response';

@Injectable({
  providedIn: 'root',
})
export class TransitionApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(
    assignmentId: string,
    transitionName: TransitionEnum,
  ): Observable<ApplyTransitionResponse> {
    const applyAssignmentTransitionUrl: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${assignmentId}/transitions`;
    return this.httpClient.post<ApplyTransitionResponse>(applyAssignmentTransitionUrl, {
      transitionName: transitionName,
    });
  }
}
