import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RankingContract } from '../contracts/ranking.contract';

export const rankingsActions = createActionGroup({
  source: '[Rankings]',
  events: {
    addRankings: props<{
      items: { candidate: string; slots: string[]; ranking: RankingContract }[];
    }>(),
    clearAllRankings: emptyProps,
  },
});
