import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { FetchStorageInterface } from '@techniek-team/fetch';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { businessServicesActions } from './+state/business-services.actions';
import { BusinessServicesSelectors } from './+state/business-services.selectors';
import { BusinessServiceContract } from './contracts/business-service.contract';
import { BusinessServiceDetailed } from './models/business-service-detailed.model';
import { BusinessService } from './models/business-service.model';

@Injectable()
export class BusinessServicesStoreService
  implements FetchStorageInterface<BusinessServiceContract, BusinessService>
{
  private readonly store = inject(Store);

  public loading$ = this.store.pipe(select(BusinessServicesSelectors.loading));

  public loadingDetailed$ = this.store.pipe(select(BusinessServicesSelectors.loadingDetailed));

  public loaded$ = this.store.pipe(select(BusinessServicesSelectors.loaded));

  public businessServiceEntities = this.store.selectSignal(
    BusinessServicesSelectors.businessServiceEntities,
  );

  public businessServicesMap$: Observable<Map<string, BusinessService>> = this.store.pipe(
    select(BusinessServicesSelectors.businessServiceEntities),
    map((data) => {
      return new Map(
        Object.entries(data).map(([key, _item]) => {
          if ('deliveryTypes' in data) {
            return [key, denormalize(BusinessServiceDetailed, data)];
          }
          return [key, denormalize(BusinessService, data)];
        }),
      );
    }),
    shareReplay(),
  );

  public businessServicesFilterGroup = this.store.selectSignal(
    BusinessServicesSelectors.businessServicesFilterGroup,
  );

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(businessServicesActions.initBusinessServices());
  }

  public reload(): void {
    this.store.dispatch(businessServicesActions.reloadBusinessServices());
  }

  /**
   * For now load the detailed business service via this function. In the future
   * it should just dispatch the action from other stores. For example when
   * creating new slots via the create slot dialog.
   */
  public loadDetailedBusinessService(id: string): void {
    this.store.dispatch(businessServicesActions.loadDetailedBusinessService({ id: id }));
  }

  public supportsFetch(identifier: string): boolean {
    return identifier === 'BusinessService';
  }

  public async waitForInitialization(): Promise<void> {
    await firstEmitFrom(
      this.store.pipe(
        select(BusinessServicesSelectors.loaded),
        filter((loaded) => loaded === true),
      ),
    );
  }

  public getFetchFromStorage(value: string): Observable<BusinessService | BusinessServiceDetailed> {
    return this.store.pipe(
      select(BusinessServicesSelectors.denormalizedBusinessServiceEntities),
      map((dict) => dict[jsonLdSelectId(value)]),
      isDefined(),
    );
  }

  public getById(id: string): Observable<BusinessService | BusinessServiceDetailed> {
    return this.getFetchFromStorage(id);
  }
}
