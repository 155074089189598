import {
  ProductTypeContract,
  ProductTypeDetailed,
  ProductTypeDetailedContract,
} from '@scheduler-frontend/data-access-product-types';

export function mustSetNumberOfPupils(
  productType: ProductTypeDetailedContract | ProductTypeContract | ProductTypeDetailed | undefined,
): boolean {
  return !!(
    productType &&
    'allowSetNumberOfPupils' in productType &&
    productType.allowSetNumberOfPupils
  );
}
