import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  adapter,
  APPROACHES_HANDLED_FEATURE_KEY,
  ApproachesHandledState,
} from '../reducer/approaches-handled.reducer';
import { ApproachesToDoState } from '../reducer/approaches-to-do.reducer';

const { selectAll, selectEntities } = adapter.getSelectors();

export class ApproachesHandledSelectors {
  public static readonly state = createFeatureSelector<ApproachesHandledState>(
    APPROACHES_HANDLED_FEATURE_KEY,
  );

  public static readonly loaded = createSelector(
    ApproachesHandledSelectors.state,
    (state: ApproachesHandledState) => state.loaded,
  );

  public static readonly loading = createSelector(
    ApproachesHandledSelectors.state,
    (state: ApproachesHandledState) => state.loading,
  );

  public static readonly loadedChunks = createSelector(
    ApproachesHandledSelectors.state,
    (state: ApproachesHandledState) => state.loadedChunks,
  );

  public static readonly lastChunkLoaded = createSelector(
    ApproachesHandledSelectors.state,
    (state: ApproachesHandledState) => state.lastChunkLoaded,
  );

  public static readonly error = createSelector(
    ApproachesHandledSelectors.state,
    (state: ApproachesHandledState) => state.error,
  );

  public static readonly approaches = createSelector(
    ApproachesHandledSelectors.state,
    (state: ApproachesToDoState) => (state.loaded ? selectAll(state) : undefined),
  );

  public static readonly approachesEntities = createSelector(
    ApproachesHandledSelectors.state,
    (state: ApproachesHandledState) => selectEntities(state),
  );
}
