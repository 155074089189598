import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { RegionContract } from '../../../contracts/region.contract';

/**
 * Api to retrieve the {@see Region} resource from the scheduler-api
 */
@Injectable({
  providedIn: 'root',
})
export class RegionApi {
  protected readonly httpClient = inject(HttpClient);

  /**
   * Returns all {@see Region} from the scheduler Api.
   *
   * The response is cashed in the {@see CacheService} and is refreshed every day.
   * The list comes sorted ascending on name.
   */
  public getRegions(): Observable<Collection<RegionContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/regions`;

    return this.httpClient.get<Collection<RegionContract>>(url);
  }
}
