import { createSelector } from '@ngrx/store';
import {
  AssignmentDetailedContract,
  AssignmentStateEnum,
  isBeforeState,
  isState,
} from '@scheduler-frontend/assignment-contracts';
import {
  BusinessServiceDetailedContract,
  BusinessServicesSelectors,
} from '@scheduler-frontend/data-access-business-services';
import { ProductTypesSelectors } from '@scheduler-frontend/data-access-product-types';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { AssignmentsPermissionsSelectors } from './assignments-permissions.selectors';
import { AssignmentsSelectors } from './assignments.selectors';

export class ActiveAssignmentsPermissionsSelectors {
  public static readonly canUpdateAssignment = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    (assignment, isAdmin) => {
      if (!assignment) {
        return false;
      }

      if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
        return false;
      }

      if (isBeforeState(assignment.state, AssignmentStateEnum.WAITING_FOR_CONFIRMATION)) {
        return false;
      }

      if (assignment.bookingPeriodClosed) {
        // Only admins are allowed
        return isAdmin;
      }

      return true;
    },
  );

  public static readonly canRemoveSlotFromAssignment = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    (assignment, isAdmin) => {
      if (!assignment) {
        return false;
      }

      // removing the last slot of an assignment would be the same as unassigned
      // the candidate from an assignment. so do that instead.
      if ((assignment as AssignmentDetailedContract).assignmentHasSlots.length <= 1) {
        return false;
      }

      if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
        return false;
      }

      if (assignment.bookingPeriodClosed) {
        // Only admins are allowed
        return isAdmin;
      }
      return true;
    },
  );

  public static readonly canMarkAsAbsent = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    AssignmentsPermissionsSelectors.isMarkAsAbsentGranted,
  );

  public static readonly canMarkAsPresent = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    AssignmentsPermissionsSelectors.isMarkAsPresentGranted,
  );

  public static readonly canAddPremium = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    (assignment, isAdmin) => {
      if (!isAdmin) {
        return false;
      }

      return (assignment as AssignmentDetailedContract).addPremiumAllowed;
    },
  );

  public static readonly canEditAssignmentCompensationLine = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    (assignment, isAdmin) => {
      if (!assignment) {
        return false;
      }

      if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
        return false;
      }

      if (assignment.bookingPeriodClosed) {
        // Only admins are allowed
        return isAdmin;
      }

      return true;
    },
  );

  public static readonly canChangeIsBillable = createSelector(
    AssignmentsSelectors.activeAssignment,
    BusinessServicesSelectors.businessServiceEntities,
    ProductTypesSelectors.productTypeEntities,
    UsersSelectors.isAdmin,
    (assignment, businessServices, productTypes, isAdmin) => {
      if (!assignment) {
        return false;
      }

      const businessService = businessServices[
        (assignment as AssignmentDetailedContract).businessService
      ] as BusinessServiceDetailedContract;
      if (businessService && !productTypes[businessService.productType]?.hasBalance) {
        return false;
      }

      if (assignment.bookingPeriodClosed) {
        // Only admins are allowed
        return isAdmin;
      }

      return true;
    },
  );

  public static readonly canEditManualTravelingCompensation = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    (assignment, isAdmin) => {
      if (
        !assignment ||
        !(assignment as AssignmentDetailedContract).allowManualTravelCompensation
      ) {
        return false;
      }

      if (!(assignment as AssignmentDetailedContract).canEnableCompensation) {
        return false;
      }

      if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
        return false;
      }

      if (assignment.bookingPeriodClosed) {
        // Only admins are allowed
        return isAdmin;
      }

      return true;
    },
  );

  public static readonly canEditPayoutCheckbox = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    (assignment, isAdmin) => {
      if (!assignment) {
        return false;
      }

      if (!(assignment as AssignmentDetailedContract).canEnableCompensation) {
        return false;
      }

      if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
        return false;
      }

      if (assignment.bookingPeriodClosed) {
        // Only admins are allowed
        return isAdmin;
      }

      return true;
    },
  );

  public static readonly canEditSelfAssignable = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    (assignment, isAdmin) => {
      if (!assignment) {
        return false;
      }

      if (!(assignment as AssignmentDetailedContract).allowUpdatingSelfAssignFields) {
        return false;
      }

      return isAdmin;
    },
  );

  public static readonly canMarkAsUrgent = createSelector(
    AssignmentsSelectors.activeAssignment,
    UsersSelectors.isAdmin,
    (assignment, isAdmin) => {
      if (!assignment) {
        return false;
      }

      if (isState(assignment.state, AssignmentStateEnum.WAITING_FOR_CUSTOMER_DOCUMENTS)) {
        return false;
      }

      return isAdmin;
    },
  );
}
