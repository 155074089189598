import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BusinessEntityContract } from '../contracts/business-entity.contract';

export const businessEntitiesActions = createActionGroup({
  source: '[BusinessEntities]',
  events: {
    initBusinessEntities: emptyProps(),
    reloadBusinessEntities: emptyProps(),
    loadBusinessEntitiesSuccess: props<{
      businessEntities: BusinessEntityContract[];
      totalItems: number;
    }>(),
    loadFromCacheBusinessEntitiesSuccess: props<{
      businessEntities: BusinessEntityContract[];
      totalItems: number;
      cacheTimestamp: string; // iso 8601 date
    }>(),
    loadBusinessEntitiesFailure: props<{ error: unknown }>(),
  },
});
