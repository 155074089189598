import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CandidateMinimalContract } from '@scheduler-frontend/data-access-users';

export const schedulingRejectSlotsActions = createActionGroup({
  source: '[Scheduling/RejectSlots]',
  events: {
    /**
     * On this action the scheduler-api actually rejects the assignment (slots)
     * for the candidate.
     */
    rejectSlotsForCandidate: emptyProps(),
    rejectSlotsForCandidateFailure: props<{ error: unknown }>(),
    rejectSlotsForCandidateSuccess: props<{ candidate: CandidateMinimalContract }>(),
  },
});
