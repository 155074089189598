import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { hashParams } from '@scheduler-frontend/common';
import { sortString } from '@techniek-team/common';
import { CandidateAllotmentStatisticWithHashContract } from '../../contracts/candidate-allotment-statistic.contract';
import { candidateAllotmentStatisticsActions } from '../actions/candidate-allotment-statistics.actions';

export const CANDIDATE_ALLOTMENT_STATISTICS_FEATURE_KEY = 'candidateAllotmentStatistics';

export interface CandidateAllotmentStatisticsState
  extends EntityState<CandidateAllotmentStatisticWithHashContract> {
  loaded: boolean;
  loading: boolean;
  error?: string | null;
}

export function selectId(item: CandidateAllotmentStatisticWithHashContract): string {
  return item.hash;
}

export function createAllotmentStatisticsId(candidate: string, slots: string[], isoWeek: string) {
  return hashParams(candidate, ...slots.sort(sortString), isoWeek);
}
//eslint-disable-next-line max-len
export const candidateAllotmentStatisticsAdapter =
  createEntityAdapter<CandidateAllotmentStatisticWithHashContract>({
    selectId: selectId,
  });

export const initialCandidateAllotmentStatisticsState: CandidateAllotmentStatisticsState =
  candidateAllotmentStatisticsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
  });

const reducer: ActionReducer<CandidateAllotmentStatisticsState> = createReducer(
  initialCandidateAllotmentStatisticsState,
  on(candidateAllotmentStatisticsActions.addCandidateAllotmentStatistics, (state, { items }) =>
    candidateAllotmentStatisticsAdapter.setMany(
      items
        .map((item) => {
          return item.candidateAllotmentStatistics.map((allotment) => ({
            ...allotment,
            hash: createAllotmentStatisticsId(item.candidate, item.slots, allotment.isoWeek),
          }));
        })
        .flat(1),
      {
        ...state,
        loaded: true,
        loading: false,
      },
    ),
  ),
  on(candidateAllotmentStatisticsActions.clearAllCandidateAllotmentStatistics, (state) =>
    candidateAllotmentStatisticsAdapter.removeAll(state),
  ),
);

export function candidateAllotmentStatisticsReducer(
  state: CandidateAllotmentStatisticsState | undefined,
  action: Action,
): CandidateAllotmentStatisticsState {
  return reducer(state, action);
}
