import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CREATE_SLOTS_FEATURE_KEY, CreateSlotsState } from '../reducers/create-slots.reducer';

export class ScheduleSelectors {
  public static readonly slotsState =
    createFeatureSelector<CreateSlotsState>(CREATE_SLOTS_FEATURE_KEY);

  public static readonly scheduleSearchQuery = createSelector(
    ScheduleSelectors.slotsState,
    (state) => state.scheduleSearchQuery,
  );

  public static readonly foundSchedules = createSelector(
    ScheduleSelectors.slotsState,
    (state) => state.foundSchedules,
  );

  public static readonly loadingSchedules = createSelector(
    ScheduleSelectors.slotsState,
    (state) => state.loadingSchedules,
  );

  public static readonly totalFoundSchedules = createSelector(
    ScheduleSelectors.slotsState,
    (state) => state.foundSchedulesTotal,
  );
}
