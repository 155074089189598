import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LocationModel, LocationsStoreService } from '@scheduler-frontend/data-access-locations';
import { TtIonSelectSearchModule } from '@techniek-team/components/ion-select-search';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';
import { isDefined } from '@techniek-team/rxjs';
import { BehaviorSubject, filter, take, withLatestFrom } from 'rxjs';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  standalone: true,
  imports: [TtIonSelectSearchModule, AsyncPipe, IonicModule, ReactiveFormsModule],
})
export class LocationComponent implements OnInit {
  protected readonly locationsStoreService = inject(LocationsStoreService);

  private readonly element = inject(ElementRef);

  private readonly renderer = inject(Renderer2);

  private readonly destroyRef = inject(DestroyRef);

  public locationSearchControl = new FormControl<LocationModel | null>(null, [Validators.required]);

  @Input() public set color(color: IonColor) {
    if (color) {
      this.colorSubject$.next(color);
    }
  }

  protected readonly colorSubject$ = new BehaviorSubject<IonColor>(IonColorType.DARK);

  protected readonly IonColorType: typeof IonColorType = IonColorType;

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createColorSubscriber();
    this.locationsStoreService.init();
    this.locationSearchControl.valueChanges
      .pipe(
        isDefined(),
        takeUntilDestroyed(this.destroyRef),
        withLatestFrom(this.locationsStoreService.currentLocation$),
        filter(([newLocation, oldLocation]) => newLocation.getIri() !== oldLocation.getIri()),
      )
      .subscribe(([location]) => {
        this.locationsStoreService.selectLocation(location);
      });
    this.locationsStoreService.currentLocation$.pipe(take(1)).subscribe((location) => {
      if (this.locationSearchControl.pristine) {
        this.locationSearchControl.reset(location, { emitEvent: false });
      }
    });
  }

  private createColorSubscriber(): void {
    this.colorSubject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((color) => {
      const previousColorClasses: string[] = (
        this.element.nativeElement.className.split(' ') as string[]
      ).filter((item) => {
        if (item) {
          return item.match(/ion-color-*./);
        }
        return false;
      });
      for (let className of previousColorClasses) {
        this.renderer.removeClass(this.element.nativeElement, className);
      }
      this.renderer.addClass(this.element.nativeElement, 'ion-color-' + color);
    });
  }
}
