import { computed, inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { isNull, isUndefined, omitBy } from 'lodash-es';
import { shareReplay } from 'rxjs';
import { searchActions } from './+state/action/search.actions';
import { userSearchActions } from './+state/action/user-search.actions';
import { SearchSelectors } from './+state/selector/search.selectors';
import { UserSearchSelectors } from './+state/selector/user-search.selectors';
import { Filters, SearchContract } from './contract/search.contract';
import { UserSearch } from './models/search-user.model';
import { Search } from './models/search.model';

@Injectable()
export class SearchStoreService {
  private readonly store = inject(Store);

  public readonly currentSystemSearch = this.store.selectSignal(SearchSelectors.currentSearch);

  public readonly currentUserSearch = this.store.selectSignal(UserSearchSelectors.currentSearch);

  public readonly lastCreatedSystemSearch = this.store.selectSignal(
    SearchSelectors.lastCreatedSearch,
  );

  public readonly lastCreatedUserSearch = this.store.selectSignal(
    UserSearchSelectors.lastCreatedSearch,
  );

  private readonly actions = inject(Actions);

  public userSearchesLoading = this.store.selectSignal(UserSearchSelectors.loading);

  public savingAsFavorite = this.store.selectSignal(UserSearchSelectors.savingAsFavorite);

  public schedulingBySearch = this.store.selectSignal(SearchSelectors.schedulingBySearch);

  private favoritesInternal = this.store.selectSignal(UserSearchSelectors.favorites);

  public favorites = computed(() => {
    return denormalize(UserSearch, this.favoritesInternal());
  });

  private recentInternal = this.store.selectSignal(UserSearchSelectors.recent);

  public recent = computed(() => {
    return denormalize(UserSearch, this.recentInternal());
  });

  public currentSearchChips = this.store.selectSignal(UserSearchSelectors.currentUserSearchChips);

  public currentSearchParsedToSearchBarInputData = this.store.selectSignal(
    UserSearchSelectors.currentUserSearchParsedToSearchBarInputData,
  );

  /**
   * This only creates a search but doesn't automatically load it
   */
  public async createSearch(
    filters: Filters,
    isUserInitiated: boolean = true,
    setAsCurrentSearch: boolean = false,
  ): Promise<void | Error> {
    this.store.dispatch(
      searchActions.createSearchHash({
        filters: omitBy(omitBy(filters, isUndefined), isNull),
        isUserInitiated: isUserInitiated,
        setAsCurrentSearch: setAsCurrentSearch,
      }),
    );

    const action = await Promise.race([
      setAsCurrentSearch
        ? firstEmitFrom(this.actions.pipe(ofType(searchActions.setCurrentSystemSearch)))
        : firstEmitFrom(this.actions.pipe(ofType(searchActions.createSearchHashSuccess))),
      firstEmitFrom(this.actions.pipe(ofType(searchActions.createSearchHashFailure))),
    ]);

    if (action && typeof action === 'object' && 'error' in action) {
      return Promise.reject(action.error);
    }

    return Promise.resolve();
  }

  public appendFiltersToSearch(
    filters: Filters,
    isUserInitiated: boolean = true,
    setAsCurrentSearch: boolean = false,
  ) {
    const setProperties = omitBy(filters, (item) => {
      return !item || (Array.isArray(item) && item.length === 0);
    });
    const emptyProperties = omitBy(filters, (item) => {
      return !(!item || (Array.isArray(item) && item.length === 0));
    });
    this.store.dispatch(
      searchActions.appendToSearchHash({
        filters: setProperties,
        removeKeys: Object.keys(emptyProperties),
        isUserInitiated: isUserInitiated,
        setAsCurrentSearch: setAsCurrentSearch,
      }),
    );
  }

  public removeFiltersFromSearch(
    filterKeys: string[],
    isUserInitiated: boolean = true,
    setAsCurrentSearch: boolean = false,
  ) {
    this.store.dispatch(
      searchActions.removeFromSearchHash({
        keys: filterKeys,
        isUserInitiated: isUserInitiated,
        setAsCurrentSearch: setAsCurrentSearch,
      }),
    );
  }

  public initLoadUserSearches(): void {
    this.store.dispatch(userSearchActions.loadRecentSearches());
    this.store.dispatch(userSearchActions.loadFavoriteSearches());
  }

  public toggleAsFavorite(search: UserSearch | string): void {
    if (search instanceof UserSearch) {
      search = search.search.hash;
    }
    this.store.dispatch(userSearchActions.toggleFavorite({ favorite: search }));
  }

  public setCurrentSearch(search: Search | UserSearch | string): void {
    if (search instanceof UserSearch) {
      search = search.search.hash;
    }
    if (search instanceof Search) {
      search = search.hash;
    }
    this.store.dispatch(userSearchActions.setCurrentUserSearch({ searchId: search }));
  }

  public reloadCurrentSearch() {
    this.store.dispatch(searchActions.reloadCurrentSearch());
  }

  public startSchedulingBySearch() {
    this.store.dispatch(searchActions.startSchedulingBySearch());
  }

  public stopSchedulingBySearch() {
    this.store.dispatch(searchActions.stopSchedulingBySearch());
  }

  public startLocationScheduleSearch() {
    this.store.dispatch(searchActions.startLocationScheduleSearch());
  }

  public stopLocationScheduleSearch() {
    this.store.dispatch(searchActions.stopLocationScheduleSearch());
  }

  public getSearchChips$(search: SearchContract | Search) {
    return this.store.pipe(select(SearchSelectors.getSearchChips(search)), shareReplay());
  }

  public clearCurrentSearch() {
    this.store.dispatch(userSearchActions.clearCurrentUserSearch());
    this.store.dispatch(searchActions.clearCurrentSystemSearch());
  }
}
