import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { AssignmentDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { candidateAssignmentsFutureActions } from '../actions/candidate-assignments-future.actions';
import { candidatesActions } from '../actions/candidates.actions';

export const CANDIDATE_ASSIGNMENTS_FUTURE_FEATURE_KEY: string = 'candidateAssignmentsFuture';

export interface CandidateAssignmentsFutureState extends EntityState<AssignmentDetailedContract> {
  error?: Error | null;
  loading: boolean;
  loaded: boolean;
  loadedChunks: number[];
  lastChunkLoaded: boolean;
}

export const candidateAssignmentsFutureAdapter: EntityAdapter<AssignmentDetailedContract> =
  createEntityAdapter<AssignmentDetailedContract>({
    selectId: jsonLdSelectId,
  });

export const initialCandidateAssignmentsFutureState: CandidateAssignmentsFutureState =
  candidateAssignmentsFutureAdapter.getInitialState({
    error: null,
    loading: false,
    loaded: false,
    loadedChunks: [],
    lastChunkLoaded: false,
  });

const reducer: ActionReducer<CandidateAssignmentsFutureState> = createReducer(
  initialCandidateAssignmentsFutureState,
  on(candidatesActions.loadActiveCandidateSuccess, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(candidateAssignmentsFutureActions.loadSuccess, (state, { assignments, chunk, totalItems }) =>
    candidateAssignmentsFutureAdapter.setAll(assignments, {
      ...state,
      loading: false,
      loaded: true,
      loadedChunks: [chunk],
      lastChunkLoaded: assignments.length === totalItems,
      error: null,
    }),
  ),
  on(candidateAssignmentsFutureActions.refresh, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(candidateAssignmentsFutureActions.loadFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(candidateAssignmentsFutureActions.loadNextChunk, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    candidateAssignmentsFutureActions.loadNextChunkSuccess,
    (state, { assignments, chunk, totalItems }) =>
      candidateAssignmentsFutureAdapter.addMany(assignments, {
        ...state,
        loading: false,
        loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()],
        lastChunkLoaded: state.ids.length + assignments.length === totalItems,
        error: null,
      }),
  ),
  on(candidatesActions.clearActiveCandidate, (state) =>
    candidateAssignmentsFutureAdapter.removeAll({
      ...state,
      loaded: false,
      error: null,
    }),
  ),
);

export function candidateAssignmentsFutureReducer(
  state: CandidateAssignmentsFutureState | undefined,
  action: Action,
): CandidateAssignmentsFutureState {
  return reducer(state, action);
}
