import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import {
  IsActiveMatchOptions,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import {
  FaConfig,
  FaIconComponent,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { IconDefinition, IconLookup, IconProp } from '@fortawesome/fontawesome-svg-core';
import { IonicModule, IonPopover } from '@ionic/angular';
import { TtMouseoverComponent } from '@techniek-team/mouseover';

@Component({
  selector: 'tt-menu-item',
  templateUrl: './tt-menu-item.component.html',
  styleUrls: ['./tt-menu-item.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    RouterLink,
    RouterLinkActive,
    AsyncPipe,
    FaIconComponent,
    NgTemplateOutlet,
    TtMouseoverComponent
],
})
export class TtMenuItemComponent {
  private readonly iconLibrary = inject(FaIconLibrary);

  private readonly fontAwesomeConfig = inject(FaConfig);

  public readonly routerLinkActive = viewChild(RouterLinkActive);

  public readonly popover = viewChild(IonPopover);

  /**
   * Commands to pass to {@link Router#createUrlTree Router#createUrlTree}.
   *   - **array**: commands to pass to {@link Router#createUrlTree Router#createUrlTree}.
   *   - **string**: shorthand for array of commands with just the string, i.e. `['/route']`
   *   - **null|undefined**: effectively disables the `routerLink`
   * @see {@link Router#createUrlTree Router#createUrlTree}
   * @see RouterLink.routerLink
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly routerLink = input<any[] | string>();

  /**
   * Options to configure how to determine if the router link is active.
   *
   * These options are passed to the `Router.isActive()` function.
   *
   * @see Router.isActive
   * @see RouterLinkActive.routerLinkActiveOptions
   */
  public readonly routerLinkActiveOptions = input<
    { exact: boolean } | IsActiveMatchOptions
  >({
    exact: false,
  });

  /**
   * TemplateRef of the subMenu if any
   *
   * @example
   * ```typescript
   * <tt-menu [header]="'Lyceo Styling'">
   *     <tt-menu-item [routerLink]="['/people']"
   *                   [icon]="'people'"
   *                   [submenu]="people"
   *                   [title]="'People'"></tt-menu-item>
   *     <ng-template #people>
   *         <tt-sub-menu-item>Rare mensen</tt-sub-menu-item>
   *         <tt-sub-menu-item>Leuke Mensen</tt-sub-menu-item>
   *     </ng-template>
   * </tt-menu>
   * ```
   */
  public readonly submenu = input<TemplateRef<unknown> | null>();

  /**
   * The tile of the menu item.
   *
   * The title is shown in both the menu(if expanded) and the submenu(if available)
   * Note that it's only shown in the menu when the <tt-menu-item> element has no inner
   * content, otherwise it shows that instead.
   */
  public readonly title = input.required<string>();

  public readonly showLabelWhenCollapsed = input<boolean>(true);

  /**
   * If true the popover is completely disable for the menu item.
   */
  public readonly disablePopover = input(false);

  /**
   * Emit when the menu item is active.
   */
  public readonly isActiveChange = output<boolean>();

  protected readonly uuid = window.crypto.randomUUID();

  protected readonly routerLinkActiveSubscription = effect(() => {
    const routerLinkActive = this.routerLinkActive();
    if (routerLinkActive) {
      return routerLinkActive?.isActiveChange.subscribe((isActive) => {
        this.isActiveChange.emit(isActive);
        this.isActive.set(isActive);
      });
    }
    return undefined;
  });

  public readonly isActive = signal(this.routerLinkActive()?.isActive ?? false);

  /**
   * Input to set the icon used in the menu item. This can either be a font-awesome
   * {@see IconProp} or an ion-icon icon name (string) or an url to svg (string)
   *
   * Note: This property is only used when the <tt-menu-item> element has no inner
   * content, otherwise it shows that instead.
   */
  public readonly icon = input<string | IconProp>();

  public readonly fontAwesomeIcon = computed(() => {
    return this.icon() as IconProp;
  });

  public readonly isFontAwesomeIcon = computed(() => {
    const icon = this.icon();
    if (!icon) {
      return false;
    }
    if (
      typeof icon === 'object' &&
      'prefix' in icon &&
      'iconName' in icon &&
      'icon' in icon
    ) {
      return true;
    }
    return this.lookupIsFontAwesomeIcon(icon as IconProp);
  });

  public readonly expanded = signal(false);

  /**
   * If true the popover is open.
   */
  protected popoverOpen: boolean = false;

  /**
   * Trigger on MouseEnter and opens the popover.
   *
   * The Popover is only opened when the menu isn't expanded and there isn't a submenu
   */
  protected presentPopover(event: Event): void {
    const popover = this.popover();
    if (
      this.expanded() ||
      this.disablePopover() ||
      this.isActive() ||
      !popover
    ) {
      return;
    }
    popover.event = event;
    this.popoverOpen = true;
  }

  /**
   * Trigger on MouseLeave and closes the popover.
   *
   * The MouseLeave is set on multiple elements and therefor needs to check if
   * it actually needs to close the popover.
   */
  protected leavePopover(event: Event): void {
    if (event instanceof MouseEvent) {
      if (
        (event.relatedTarget as Element)
          ?.closest('ion-content')
          ?.classList?.contains('menu-item-popover')
      ) {
        return;
      }
    }
    this.popoverOpen = false;
  }

  /**
   * Method determines if the given icon is a Font Awesome icon.
   */
  private lookupIsFontAwesomeIcon(
    iconSpec: IconDefinition | IconProp,
  ): iconSpec is IconDefinition {
    let lookup: IconLookup;
    if (Array.isArray(iconSpec) && (iconSpec as string[]).length === 2) {
      lookup = { prefix: iconSpec[0], iconName: iconSpec[1] };
    } else if (typeof iconSpec === 'string') {
      lookup = {
        prefix: this.fontAwesomeConfig.defaultPrefix,
        iconName: iconSpec,
      };
    } else {
      return false;
    }

    return !!this.iconLibrary.getIconDefinition(lookup.prefix, lookup.iconName);
  }
}
