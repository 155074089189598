import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RoleContract } from '../contracts/role.contract';

export const rolesActions = createActionGroup({
  source: '[Roles]',
  events: {
    initRoles: emptyProps(),
    reloadRoles: emptyProps(),
    loadRolesSuccess: props<{ roles: RoleContract[]; totalItems: number }>(),
    loadFromCacheRolesSuccess: props<{
      roles: RoleContract[];
      totalItems: number;
      cacheTimestamp: string; // iso 8601 date
    }>(),
    loadRolesFailure: props<{ error: unknown }>(),
  },
});
