import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TsRangeInterface } from '@techniek-team/common';
import { createSlotsActions } from './+state/actions/create-slots.actions';
import { SlotsToCreateSelectors } from './+state/selectors/slots-to-create.selectors';

@Injectable()
export class SlotsToCreateStoreService {
  private readonly store = inject(Store);

  public readonly slotsToCreateGroupedByDate = this.store.selectSignal(
    SlotsToCreateSelectors.slotsToCreateGroupedByDate,
  );

  public removeSlotToCreate(id: string) {
    this.store.dispatch(createSlotsActions.removeSlotToCreate({ id: id }));
  }

  public updateSlotToCreate(
    id: string,
    changes: {
      timeRange?: TsRangeInterface<string>;
      amountOfPupils?: number;
    },
  ) {
    this.store.dispatch(createSlotsActions.updateSlotToCreate({ id: id, changes: changes }));
  }
}
