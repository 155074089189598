import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LessonApi {
  protected readonly httpClient = inject(HttpClient);

  public setIsBillable(lessonId: string, isBillable: boolean): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/lessons/${lessonId}/isBillable`;

    return this.httpClient.put<void>(url, {
      isBillable: isBillable,
    });
  }
}
