import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { hashParams } from '@scheduler-frontend/common';
import { sortString } from '@techniek-team/common';
import { RankingWithHashContract } from '../contracts/ranking.contract';
import { rankingsActions } from './rankings.actions';

export const RANKINGS_FEATURE_KEY = 'rankings';

export interface RankingsState extends EntityState<RankingWithHashContract> {
  totalItems?: number;
  loaded: boolean;
  loading: boolean;
  error?: string | null;
}

export function selectId(item: RankingWithHashContract): string {
  return item.hash;
}

export function createRankingsId(candidate: string, slots: string[]) {
  return hashParams(candidate, ...slots.sort(sortString));
}
export const rankingsAdapter: EntityAdapter<RankingWithHashContract> =
  createEntityAdapter<RankingWithHashContract>({
    selectId: selectId,
  });

export const initialRankingsState: RankingsState = rankingsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
});

const reducer = createReducer(
  initialRankingsState,
  on(rankingsActions.addRankings, (state, { items }) =>
    rankingsAdapter.setMany(
      items.map((item) => {
        return {
          ...item.ranking,
          hash: createRankingsId(item.candidate, item.slots),
        };
      }),
      {
        ...state,
        loaded: true,
        loading: false,
      },
    ),
  ),
  on(rankingsActions.clearAllRankings, (state) => rankingsAdapter.removeAll(state)),
);

export function getRankingHash(ids: string[]): string {
  const concatenatedIds: string = ids
    .sort() // Sort to always get the same order of ids, so we get the same hash.
    .join(); // Join all the ids together.

  let hash: number = 0;

  for (let i: number = 0; i < concatenatedIds.length; i++) {
    const charCode: number = concatenatedIds.charCodeAt(i);
    //eslint-disable-next-line no-bitwise
    hash = (31 * hash + charCode) & 0xffffffff;
  }

  return hashParams();
}

export function rankingsReducer(state: RankingsState | undefined, action: Action) {
  return reducer(state, action);
}
