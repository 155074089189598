import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Exclude, Expose, Type } from 'class-transformer';
import { isEqual, roundToNearestMinutes } from 'date-fns';
import { PayoutWaitingForApprovalSlot } from './payout-waiting-for-approval-slot.model';

export class PayoutWaitingForApprovalAssignmentHasSlot extends JsonLd {
  @Type(() => PayoutWaitingForApprovalSlot)
  @Expose()
  public slot!: PayoutWaitingForApprovalSlot;

  @Type(() => TsRange)
  @Expose()
  public actualTimePeriod!: TsRange;

  @Exclude() public get isTimePeriodEqual(): boolean {
    const isStartEqual: boolean = isEqual(
      roundToNearestMinutes(this.slot.timePeriod.start),
      roundToNearestMinutes(this.actualTimePeriod.start),
    );
    const isEndEqual: boolean = isEqual(
      roundToNearestMinutes(this.slot.timePeriod.end),
      roundToNearestMinutes(this.actualTimePeriod.end),
    );
    return isStartEqual && isEndEqual;
  }
}
