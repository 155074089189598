import { Expose } from 'class-transformer';

export interface PlainRangeInterface<
  PlainType extends string | number = string,
> {
  start: PlainType;
  end: PlainType;
  inclusiveStart: boolean;
  inclusiveEnd: boolean;
}

//eslint-disable-next-line max-len
export abstract class RangeModel<
  RangeType,
  PlainType extends string | number = string,
> {
  @Expose() public start: RangeType;

  @Expose() public end: RangeType;

  @Expose() public inclusiveStart: boolean = true;

  @Expose() public inclusiveEnd: boolean = false;

  /**
   * Either include or exclude the start from the range
   */
  public abstract min: RangeType;

  /**
   * Either include or exclude the end from the range
   */
  public abstract max: RangeType;

  constructor(
    start: RangeType,
    end: RangeType,
    inclusiveStart: boolean = true,
    inclusiveEnd: boolean = false,
  ) {
    this.start = start;
    this.end = end;
    this.inclusiveStart = inclusiveStart;
    this.inclusiveEnd = inclusiveEnd;
  }

  /**
   * returns true if target is within range
   */
  public abstract contains(target: RangeType): boolean;

  /**
   * return a plain object representation of the range.
   */
  public abstract toObject(): PlainRangeInterface<PlainType>;

  public abstract humanReadableString(
    formatString: string,
    divider: string,
  ): string;

  public abstract format(formatString: string, divider: string): string;

  /**
   * return a string representation of the range.
   */
  public abstract toString(): string;
}
