import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { rankingsActions } from '@scheduler-frontend/data-access-rankings';
import { SchedulingSelectors } from '@scheduler-frontend/data-access-scheduling';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map } from 'rxjs';
import { candidatesByRankingActions } from '../candidates-by-ranking.actions';

@Injectable()
export class UpdateRankingStoreEffect {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public addRankingToRankingsStore = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.loadSlotsByCandidateSuccess),
      filter((action) => !!action.items),
      concatLatestFrom(() => this.store.pipe(select(SchedulingSelectors.selectedCandidateIds))),
      map(([action, candidateIds]) => {
        // TODO Error when multiple active candidates?
        return rankingsActions.addRankings({
          items: action.items.map((item) => {
            return {
              candidate: candidateIds[0],
              slots: [jsonLdSelectId(item.slot)],
              ranking: item.ranking,
            };
          }),
        });
      }),
    ),
  );
}
