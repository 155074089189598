import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CANDIDATE_ASSIGNMENTS_FUTURE_FEATURE_KEY,
  candidateAssignmentsFutureAdapter,
  CandidateAssignmentsFutureState,
} from '../reducers/candidate-assignments-future.reducer';

const { selectAll } = candidateAssignmentsFutureAdapter.getSelectors();

export class CandidateAssignmentsFutureSelectors {
  public static readonly state = createFeatureSelector<CandidateAssignmentsFutureState>(
    CANDIDATE_ASSIGNMENTS_FUTURE_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    CandidateAssignmentsFutureSelectors.state,
    (state) => state.loading,
  );

  public static readonly loaded = createSelector(
    CandidateAssignmentsFutureSelectors.state,
    (state) => state.loaded,
  );

  public static readonly loadedChunks = createSelector(
    CandidateAssignmentsFutureSelectors.state,
    (state) => state.loadedChunks,
  );

  public static readonly lastChunkLoaded = createSelector(
    CandidateAssignmentsFutureSelectors.state,
    (state) => state.lastChunkLoaded,
  );

  public static readonly error = createSelector(
    CandidateAssignmentsFutureSelectors.state,
    (state) => state.error,
  );

  public static readonly selectAll = createSelector(
    CandidateAssignmentsFutureSelectors.state,
    (state) => selectAll(state),
  );
}
