import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Exclude, Expose } from 'class-transformer';

/**
 * standard version of the Product type resource from Scheduler-api
 */
export class ProductType extends JsonLd {
  /**
   * @inheritDoc
   */
  @Exclude() public override readonly className: string = 'ProductType';

  /**
   * The abbreviation or sort version of the {@see name}
   */
  @Expose() public abbreviation!: string;

  /**
   * Name of this Product Type
   */
  @Expose() public name!: string;

  /**
   * State that manual creation of shift is allowed for the product type.
   */
  @Expose() public allowCreateShift!: boolean;

  /**
   * If true a slots saldo can be disabled
   *
   * Only available for BUSINESS_USER_ROLE, therefore it has a default value
   */
  @Expose() public hasBalance!: boolean;

  public override toString(): string {
    return this.name;
  }
}
