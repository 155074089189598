import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduleApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(
    businessService: string,
    location?: string,
    searchInput?: string,
  ): Observable<Collection<ScheduleContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/schedules`;

    let params: HttpParams = new HttpParams()
      .set('editableOnly', true)
      .set('businessService', businessService);

    if (location) {
      params = params.append('location', location);
    }

    if (searchInput) {
      params = params.append('search', searchInput);
    }

    return this.httpClient.get<Collection<ScheduleContract>>(url, { params: params });
  }
}
