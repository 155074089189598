import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UsersViewSelectors } from './+state/users-view.selectors';

@Injectable()
export class UsersViewPermissionStoreService {
  private readonly store = inject(Store);

  public readonly viewHQHoursApprovalPage = this.store.selectSignal(
    UsersViewSelectors.viewHQHoursApprovalPage,
  );

  public readonly viewLocationHoursApprovalPage = this.store.selectSignal(
    UsersViewSelectors.viewLocationHoursApprovalPage,
  );

  public readonly viewHQPayoutPage = this.store.selectSignal(UsersViewSelectors.viewHQPayoutPage);

  public readonly viewLocationPayoutPage = this.store.selectSignal(
    UsersViewSelectors.viewLocationPayoutPage,
  );

  public readonly viewSearchPage = this.store.selectSignal(UsersViewSelectors.viewSearchPage);

  public readonly viewLocationAgendaPage = this.store.selectSignal(
    UsersViewSelectors.viewLocationAgendaPage,
  );

  public readonly viewCandidatesPage = this.store.selectSignal(
    UsersViewSelectors.viewCandidatesPage,
  );
}
