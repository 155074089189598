import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LessonDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { formatISO, subDays } from 'date-fns';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LessonsByScheduleApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(schedule: string): Observable<Collection<LessonDetailedContract>> {
    let params: HttpParams = new HttpParams({
      fromObject: {
        'date[after]': formatISO(subDays(new Date(), 28), { representation: 'date' }),
      },
    });

    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/schedules/${schedule}/lessons`;

    return this.httpClient.get<Collection<LessonDetailedContract>>(url, { params: params });
  }
}
