/* eslint-disable */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asType',
  pure: true,
  standalone: true,
})
export class AsTypePipe implements PipeTransform {
  public transform<T>(value: any, _cls: (new (...args: any[]) => T) | T): T {
    return value as T;
  }
}
