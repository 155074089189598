<tt-modal (cancel)="modalController.dismiss()">
  <tt-modal-title color="tertiary">Shift bewerken</tt-modal-title>

  <app-slot-details></app-slot-details>

  <form [formGroup]="editSlotForm">
    <div>
      <tt-ion-select-search
        fill="solid"
        class="role-control"
        formControlName="role"
        [showBackdrop]="false"
        data-test="edit-slot-role-select"
        [options]="editSlotStoreService.validRoles()"
        [displayNameFunction]="roleDisplayValue"
      >
        <ion-label position="floating">Rol</ion-label>
        <div *selectSearchOption="let element">
          {{ element.abbreviation }} - {{ element.name }} ({{
            businessServicesStoreService.businessServiceEntities()[
              element.businessService | jsonLdSelect
            ]?.businessEntity?.name
          }})
        </div>
      </tt-ion-select-search>

      <div formGroupName="timeRange" class="time">
        <div class="controls">
          <tt-time-control
            fill="solid"
            [clearInput]="true"
            data-test="edit-slot-start-time-input"
            label="Starttijd"
            labelPlacement="floating"
            formControlName="startTime"
          >
          </tt-time-control>

          <tt-time-control
            fill="solid"
            data-test="edit-slot-end-time-input"
            [class.invalid-group]="editSlotForm.controls.timeRange.errors"
            [clearInput]="true"
            label="Eindtijd"
            labelPlacement="floating"
            formControlName="endTime"
          >
          </tt-time-control>
        </div>

        <ion-note
          data-test="edit-slot-start-time-invalid"
          *ngxControlError="editSlotForm.controls.timeRange.controls.startTime; track: 'required'"
        >
          Starttijd is <strong>verplicht</strong> en kan maximaal <strong>23:59</strong> zijn.
        </ion-note>

        <ion-note
          data-test="edit-slot-end-time-invalid"
          *ngxControlError="editSlotForm.controls.timeRange.controls.endTime; track: 'required'"
        >
          Eindtijd is <strong>verplicht</strong> en kan maximaal <strong>23:59</strong> zijn.
        </ion-note>

        <ion-note
          data-test="edit-slot-time-not-valid-error"
          *ngxControlError="editSlotForm.controls.timeRange; track: 'tsRangeNotInRange'"
        >
          De eindtijd moet later zijn dan de starttijd.
        </ion-note>
      </div>
    </div>

    <ion-checkbox
      data-test="edit-slot-preform-skill-check"
      formControlName="performSkillCheck"
      justify="start"
      labelPlacement="end"
    >
      Vaardigheden van begeleider controleren
    </ion-checkbox>
  </form>
  <ion-button
    data-test="edit-slot-cancel-button"
    type="button"
    tabindex="-1"
    fill="clear"
    slot="action"
    (click)="modalController.dismiss()"
  >
    Annuleren
  </ion-button>
  <ion-button
    type="submit"
    slot="action"
    data-test="edit-slot-submit-button"
    [disabled]="editSlotStoreService.submitting() || editSlotForm.invalid"
    (click)="save()"
    color="secondary"
  >
    Opslaan
    @if (editSlotStoreService.submitting()) {
      <ion-spinner slot="start" color="medium"></ion-spinner>
    }
  </ion-button>
</tt-modal>
