import { NgModule } from '@angular/core';
import { TtIonSelectSearchControlComponent } from './tt-ion-select-search-control.component';
import { TtIonSelectSearchOptionDirective } from './tt-ion-select-search-option.directive';

/**
 * @deprecated use standalone component
 */
@NgModule({
    imports: [
        TtIonSelectSearchControlComponent,
        TtIonSelectSearchOptionDirective,
    ],
    exports: [
        TtIonSelectSearchControlComponent,
        TtIonSelectSearchOptionDirective,
    ],
})
export class TtIonSelectSearchModule {
}
