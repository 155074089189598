import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { schedulesActions } from './schedules.actions';

export const SCHEDULES_FEATURE_KEY = 'schedules';

export interface SchedulesState extends EntityState<ScheduleContract> {
  query?: string;

  totalItems?: number;

  loaded: boolean;

  loading: boolean;

  error?: unknown | null;

  cacheTimeStamp?: string;

  selectedSchedule?: string;

  selectedProductTypesAtLocation: string[];
}

export const schedulesAdapter: EntityAdapter<ScheduleContract> =
  createEntityAdapter<ScheduleContract>({
    selectId: jsonLdSelectId,
  });

export const initialSchedulesState: SchedulesState = schedulesAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  currentList: [],
  loading: false,
  selectedProductTypesAtLocation: [],
});

const reducer = createReducer(
  initialSchedulesState,
  on(schedulesActions.selectedProductTypeAtLocation, (state, { productTypeId }) => ({
    ...state,
    selectedProductTypesAtLocation: [
      ...new Set([...state.selectedProductTypesAtLocation, ...productTypeId]).values(),
    ],
  })),
  on(schedulesActions.deselectedProductTypeAtLocation, (state, { productTypeId }) => {
    const current = new Set(state.selectedProductTypesAtLocation);
    current.delete(productTypeId);
    return {
      ...state,
      selectedProductTypesAtLocation: [...current.values()],
    };
  }),
  on(schedulesActions.setSearchQuery, (state, { query }) => ({
    ...state,
    query: query,
    loading: true,
  })),
  on(schedulesActions.loadLocationSchedules, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(schedulesActions.reloadLocationSchedules, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(schedulesActions.loadLocationSchedulesSuccess, (state, { schedules, totalItems }) =>
    schedulesAdapter.setAll(schedules, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: formatISO(new Date()),
    }),
  ),
  on(
    schedulesActions.loadFromCacheLocationSchedulesSuccess,
    (state, { schedules, totalItems, cacheTimestamp }) =>
      schedulesAdapter.setAll(schedules, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: cacheTimestamp,
      }),
  ),
  on(schedulesActions.loadLocationSchedulesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(schedulesActions.setSelectedSchedule, (state, { schedule }) => ({
    ...state,
    selectedSchedule: schedule,
  })),
  on(schedulesActions.scheduleCreated, (state, { schedule }) =>
    schedulesAdapter.upsertOne(schedule, { ...state }),
  ),
  on(schedulesActions.scheduleUpdated, (state, { schedule }) =>
    schedulesAdapter.upsertOne(schedule, { ...state }),
  ),
  on(schedulesActions.scheduleDeleted, (state, { schedule }) =>
    schedulesAdapter.removeOne(schedule, { ...state }),
  ),
);

export function schedulesReducer(state: SchedulesState | undefined, action: Action) {
  return reducer(state, action);
}
