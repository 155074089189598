import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { formatISO, isAfter, subDays } from 'date-fns';
import { catchError, exhaustMap, from, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleContract } from '../contracts/role.contract';
import { RoleApi } from './api/roles.api';
import { rolesActions } from './roles.actions';
import { ROLES_FEATURE_KEY } from './roles.reducer';

@Injectable()
export class RolesEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly storage = inject(Storage);

  private readonly store = inject(Store);

  private readonly roleApi = inject(RoleApi);

  public createRolesFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(rolesActions.loadRolesFailure, {
          message: 'Er is iets misgegaan bij het laden van alle rollen.',
        }),
      ),
    { dispatch: false },
  );

  public createSaveRolesToStorageEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rolesActions.loadRolesSuccess),
        tap((action) => {
          if (action.roles.length === 0) {
            return;
          }
          return from(
            Promise.all([
              this.storage.set(`${ROLES_FEATURE_KEY}-store-cache`, {
                items: action.roles,
                timestamp: new Date(),
              }),
            ]),
          );
        }),
      ),
    { dispatch: false },
  );

  public createInitRolesEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.initRoles),
      switchMap(() =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() => {
            return from<Promise<{ timestamp: Date; items: RoleContract[] } | null>>(
              this.storage.get(`${ROLES_FEATURE_KEY}-store-cache`),
            ).pipe(
              exhaustMap((cache) => {
                if (cache && isAfter(cache.timestamp, subDays(new Date(), 1))) {
                  return of(
                    rolesActions.loadFromCacheRolesSuccess({
                      roles: cache.items,
                      totalItems: cache.items.length,
                      cacheTimestamp: formatISO(cache.timestamp),
                    }),
                  );
                }
                return this.roleApi.getRoles().pipe(
                  map((response) => {
                    return rolesActions.loadRolesSuccess({
                      roles: response['hydra:member'],
                      totalItems: response['hydra:totalItems'],
                    });
                  }),
                );
              }),
              catchError((error) => of(rolesActions.loadRolesFailure({ error: error }))),
            );
          }),
        ),
      ),
    ),
  );

  public reloadRoles = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.reloadRoles),
      switchMap(() =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() =>
            this.roleApi.getRoles().pipe(
              map((response) => {
                return rolesActions.loadRolesSuccess({
                  roles: response['hydra:member'],
                  totalItems: response['hydra:totalItems'],
                });
              }),
              catchError((error) => of(rolesActions.loadRolesFailure({ error: error }))),
            ),
          ),
        ),
      ),
    ),
  );

  public ngrxOnInitEffects() {
    return rolesActions.initRoles();
  }
}
