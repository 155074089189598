import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { handleEndpointFailure, handleEndpointSuccess } from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, filter, map, of } from 'rxjs';
import { createSlotsToCreate } from '../../functions/create-slots-to-create.function';
import { createSlotsActions } from '../actions/create-slots.actions';
import { slotTemplateActions } from '../actions/slot-template.actions';
import { CreateSlotApi } from '../api/create-slot.api';
import { SlotTemplate } from '../reducers/create-slots.reducer';
import { CreateSlotsSelectors } from '../selectors/create-slots.selectors';
import { SlotsToCreateSelectors } from '../selectors/slots-to-create.selectors';

@Injectable()
export class CreateSlotsEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly createSlotApi = inject(CreateSlotApi);

  public readonly createSlotsToCompleteOfSlotTemplatesWhenValidForm = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        slotTemplateActions.changeProductLocation,
        slotTemplateActions.changeDeliveryType,
        slotTemplateActions.changeBusinessService,
        slotTemplateActions.changeBusinessServiceSuccess,
        slotTemplateActions.changeWho,
        slotTemplateActions.changeWhoRole,
        slotTemplateActions.changeWhoTimeRange,
        slotTemplateActions.changeWhoAmountOfPupils,
        slotTemplateActions.changeWhoOperationalLocation,
        slotTemplateActions.changeWhoSubject,
        slotTemplateActions.changeWhoLevel,
        slotTemplateActions.changeSchedule,
        slotTemplateActions.changeScheduleSuccess,
        slotTemplateActions.changeWhen,
        slotTemplateActions.changeFormValidity,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(SlotsToCreateSelectors.slotTemplate)),
        this.store.pipe(select(CreateSlotsSelectors.isFormValid)),
      ]),
      filter(([_action, template, isFormValid]) => {
        return !!(
          isFormValid &&
          template.productLocation &&
          template.businessService &&
          template.schedule &&
          template.when &&
          template.when.length > 0 &&
          template.who &&
          template.who.length > 0
        );
      }),
      map(([_action, template, _isFormValid]) => {
        return createSlotsActions.createSlotsToCreateSuccess({
          slotsToCreate: createSlotsToCreate(template as Required<SlotTemplate>),
        });
      }),
    );
  });

  public readonly submitSlotToCreateForm = createEffect(() =>
    this.actions$.pipe(
      ofType(createSlotsActions.createSlot),
      concatLatestFrom(() => this.store.pipe(select(SlotsToCreateSelectors.slotsToCreate))),
      exhaustMap(([action, slotsToCreate]) => {
        return this.createSlotApi.execute(slotsToCreate).pipe(
          map((response) => {
            return createSlotsActions.createSlotSuccess({ slots: response });
          }),
          catchError((error) => {
            return of(createSlotsActions.createSlotFailure({ error: error }));
          }),
        );
      }),
    ),
  );

  public readonly submitSlotToCreateFormFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(createSlotsActions.createSlotFailure, {
          message: 'Er is iets misgegaan, bij het aanmaken van deze shift(s).',
        }),
      ),
    { dispatch: false },
  );

  public readonly submitSlotToCreateFormSuccess = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(createSlotsActions.createSlotSuccess, {
          message: 'De shift(s) is/zijn aangemaakt',
        }),
      ),
    { dispatch: false },
  );
}
