import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { GetCandidateAssignmentsResponse } from './assignment.response';

@Injectable({
  providedIn: 'root',
})
export class AssignmentApi {
  private readonly httpClient: HttpClient = inject(HttpClient);

  public getCandidateAssignments(
    candidateId: string,
    page: number,
    filters?: { startBefore?: Date; startAfter?: Date },
    sort?: { period?: 'asc' | 'desc' },
  ): Observable<Collection<GetCandidateAssignmentsResponse>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/candidates/${candidateId}/assignments`;

    let params: HttpParams = new HttpParams()
      .set('page', page.toString())
      .set('itemsPerPage', '20');

    if (filters) {
      if (filters.startBefore) {
        params = params.set('period[start_before]', filters.startBefore.toISOString());
      }

      if (filters.startAfter) {
        params = params.set('period[start_after]', filters.startAfter.toISOString());
      }
    }

    if (sort && sort.period) {
      params = params.set('order[period]', sort.period);
    }

    return this.httpClient.get<Collection<GetCandidateAssignmentsResponse>>(url, {
      params: params,
    });
  }
}
