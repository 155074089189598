import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Assignment, Slot } from '@scheduler-frontend/assignment-contracts';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { denormalize, TsRange, TsRangeInterface } from '@techniek-team/class-transformer';
import { normalizeNull } from '@techniek-team/common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssignmentTransitionContract } from '../../../contracts/assignment-transition.contract';
import { TransitionContract } from '../../../contracts/transition.contract';
import { PayoutWaitingForApproval } from '../../../models/payout-waiting-for-approval.model';
import {
  ApplyTransitionRequest,
  EditDescriptionRequest,
  MarkAsUrgentRequest,
  UpdateAssignmentSelfAssignDataRequest,
  UpdateSlotActualWorkingTimesRequest,
} from './assignment.request';
import {
  ApplyTransitionResponse,
  FinalizeAssignmentsResponse,
  GetAssignmentResponse,
  GetPreviewListResponse,
  GetTransitionResponse,
  RemoveSlotFromAssignmentResponse,
} from './assignment.response';

@Injectable({
  providedIn: 'root',
})
export class AssignmentApi {
  protected readonly httpClient = inject(HttpClient);

  /**
   * remove a Single slot from an Assignment that is not yet finalized.
   */
  public removeSlot(slots: string[]): Observable<RemoveSlotFromAssignmentResponse> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/unassign_slots`;

    return this.httpClient.post<RemoveSlotFromAssignmentResponse>(url, { slots: slots });
  }

  /**
   * Retrieves a detailed version of the Assignment resource for the given parameter.
   */
  public getAssignment(assignment: string | Assignment): Observable<GetAssignmentResponse> {
    if (assignment instanceof Assignment) {
      assignment = assignment.getId();
    }
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/assignments/${assignment}`;
    return this.httpClient
      .get<GetAssignmentResponse>(url)
      .pipe(map((response) => normalizeNull(response)));
  }

  /**
   * Edits the Assignments description.
   */
  public editDescription(request: EditDescriptionRequest): Observable<void> {
    if (request.assignment instanceof Assignment) {
      request.assignment = request.assignment.getId();
    }

    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignment}/description`;

    return this.httpClient.post<void>(url, {
      description: request.description,
    });
  }

  /**
   * Updates the working times in an Assignment Slot.
   */
  public updateActualWorkingTimes(request: UpdateSlotActualWorkingTimesRequest): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignmentId}/slots/${request.assignmentHasSlotId}`;

    return this.httpClient.post<void>(url, {
      actualStartTime: request.time.start,
      actualEndTime: request.time.end,
      breakTime: request.breakTime,
    });
  }

  /**
   * Updates the Assignments selfAssign data.
   */
  public updateSelfAssignSettings(
    request: UpdateAssignmentSelfAssignDataRequest,
  ): Observable<void> {
    if (request.assignment instanceof Assignment) {
      request.assignment = request.assignment.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignment}/self-assign`;

    return this.httpClient.patch<void>(url, request.selfAssign);
  }

  public getTransitions(assignment: string): Observable<TransitionContract[]> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${assignment}/transitions/available`;

    return this.httpClient
      .get<GetTransitionResponse>(url)
      .pipe(map((response) => response.transitions));
  }

  public getExecutedTransitions(
    assignment: string | Assignment,
    page: number,
  ): Observable<Collection<AssignmentTransitionContract>> {
    if (assignment instanceof Assignment) {
      assignment = assignment.getId();
    }

    let params: HttpParams = new HttpParams()
      .set('page', page.toString())
      .set('itemsPerPage', '20');

    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/assignments/${assignment}/executed_transitions`;
    return this.httpClient.get<Collection<AssignmentTransitionContract>>(url, {
      params: params,
    });
  }

  /**
   * Applies a predefined transition on the provided Assignment.
   */
  public applyTransition(request: ApplyTransitionRequest): Observable<ApplyTransitionResponse> {
    if (request.assignment instanceof Assignment) {
      request.assignment = request.assignment.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignment}/transitions`;

    return this.httpClient.post<ApplyTransitionResponse>(url, {
      transitionName: request.transition,
    });
  }

  /**
   * Finalizes the Assignment.
   */
  public finalizeAssignments(
    assignmentIds: PayoutWaitingForApproval[],
  ): Observable<FinalizeAssignmentsResponse> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/finalized`;
    return this.httpClient.post<FinalizeAssignmentsResponse>(
      url,
      assignmentIds.map((item) => item.getId()),
    );
  }

  /**
   * Retrieves the Assignment preview list.
   */
  public getPreviewList(slots: Slot[]): Observable<GetPreviewListResponse[] | undefined> {
    if (slots.length === 0) {
      return of(undefined);
    }

    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/preview-list`;
    let params: HttpParams = new HttpParams();

    slots.forEach((slot, index) => {
      params = params.append('slots[' + index + ']', slot.getId());
    });

    return this.httpClient
      .get<{
        assignmentPreviewList: GetPreviewListResponse<TsRangeInterface>[];
      }>(url, { params: params })
      .pipe(
        map((response) => response.assignmentPreviewList),
        map((response) =>
          response.map((item) => {
            return {
              ...item,
              slots: item.slots.map((slot) => ({
                ...slot,
                timePeriod: denormalize(TsRange, slot.timePeriod),
              })),
            };
          }),
        ),
      );
  }

  public markAsUrgent(request: MarkAsUrgentRequest): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/mark-as-urgent`;
    return this.httpClient.post<void>(url, request);
  }
}
