import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ACTIVE_ASSIGNMENT_HISTORY_FEATURE_KEY,
  ActiveAssignmentHistoryState,
  adapter,
} from '../reducer/active-assignment-history.reducer';

const { selectAll, selectEntities } = adapter.getSelectors();

export class ActiveAssignmentHistorySelectors {
  public static readonly state = createFeatureSelector<ActiveAssignmentHistoryState>(
    ACTIVE_ASSIGNMENT_HISTORY_FEATURE_KEY,
  );

  public static readonly loaded = createSelector(
    ActiveAssignmentHistorySelectors.state,
    (state) => state.loaded,
  );

  public static readonly loading = createSelector(
    ActiveAssignmentHistorySelectors.state,
    (state) => state.loading,
  );

  public static readonly loadedChunks = createSelector(
    ActiveAssignmentHistorySelectors.state,
    (state) => state.loadedChunks,
  );

  public static readonly lastChunkLoaded = createSelector(
    ActiveAssignmentHistorySelectors.state,
    (state) => state.lastChunkLoaded,
  );

  public static readonly error = createSelector(
    ActiveAssignmentHistorySelectors.state,
    (state) => state.error,
  );

  public static readonly history = createSelector(
    ActiveAssignmentHistorySelectors.state,
    (state) => (state.loaded ? selectAll(state) : undefined),
  );

  public static readonly historyEntities = createSelector(
    ActiveAssignmentHistorySelectors.state,
    (state) => selectEntities(state),
  );
}
