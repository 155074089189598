/** @format **/
import {
  ConnectedPosition,
  OverlayModule,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  input,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IonContent, IonicModule } from '@ionic/angular';
import { fromMutation, isDefined } from '@techniek-team/rxjs';
import { EMPTY, fromEvent, map, Observable, Subscription, tap } from 'rxjs';
import { catchError, filter, startWith, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'tt-mouseover',
  templateUrl: './tt-mouseover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, OverlayModule, ScrollingModule],
})
export class TtMouseoverComponent implements OnInit, AfterViewInit {
  /**
   * The id of the element that should trigger the mouse over.
   * This element will be referenced to as the element of origin.
   */
  public trigger = input.required<string>();

  /**
   * Whether or not the mouseover is disabled.
   */
  @Input() public disabled: boolean = false;

  /**
   * Event emitter that triggers when the dialog gets opened.
   */
  @Output() public readonly ttMouseoverOpen: EventEmitter<void> =
    new EventEmitter<void>();

  public position = input<ConnectedPosition[]>([]);

  /**
   * Event emitter that triggers when the dialog gets closed.
   */
  @Output() public readonly ttMouseoverClose: EventEmitter<void> =
    new EventEmitter<void>();

  /**
   * The dialog that will be opened by the CDK overlay.
   */
  @ViewChild('dialog') public readonly dialog!: ElementRef;

  /**
   * If the CDK overlay should be open or closed.
   */
  protected isOpened: boolean = false;

  /**
   * The element of origin that triggers the CDK overlay.
   */
  protected overlayOriginEl!: HTMLElement;

  constructor(
    protected scrollStrategyOptions: ScrollStrategyOptions,
    private changeDetectorRef: ChangeDetectorRef,
    private destroy$: DestroyRef,
    @Optional() private ionContent?: IonContent,
  ) {}

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    const overElement: HTMLElement | null = document.querySelector(
      `#${this.trigger()}`,
    );
    if (overElement) {
      this.overlayOriginEl = document.querySelector(
        `#${this.trigger()}`,
      ) as HTMLElement;
      this.init();
    } else {
      fromMutation(document.documentElement)
        .pipe(
          map(
            (a) => document.querySelector(`#${this.trigger()}`) as HTMLElement,
          ),
          isDefined(),
          take(1),
        )
        .subscribe((element) => {
          this.overlayOriginEl = element as HTMLElement;
          this.init();
        });
    }
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    if (this.ionContent) {
      this.ionContent.ionScrollStart
        .pipe(takeUntilDestroyed(this.destroy$))
        .subscribe(() => this.connectedOverlayDetach());
    }
  }

  /**
   * Triggered when the overlay gets detached.
   */
  public connectedOverlayDetach(): void {
    this.changeState(false);
  }

  public closeAndDisable(): void {
    this.close();
    this.disable();
  }

  public enable(): void {
    this.disabled = false;
  }

  public disable(): void {
    this.disabled = true;
  }

  /**
   * Check if we need to open the CDK overlay. When opened update the internal
   * state and wait for the close observable to emit. When it does, we change
   * the internal state again.
   */
  private init(): void {
    this.createListener();
  }

  private createListener(): Subscription {
    return this.createOpenObservable()
      .pipe(
        takeUntilDestroyed(this.destroy$),
        tap(() => this.changeState(true)),
        switchMap(() => this.createCloseObservable()),
        catchError((_error) => {
          this.changeState(false);
          return EMPTY;
        }),
        take(1),
      )
      .subscribe(() => {
        this.changeState(false);
        this.createListener();
      });
  }

  public close(): void {
    this.connectedOverlayDetach();
  }

  /**
   * Listen to the 'mouseenter' event on the origin element that will trigger
   * the CDK overlay to display. When the overlay is not yet visible we listen
   * to the 'mousemove' event to check if the cursor is inside the origin element.
   */
  private createOpenObservable(): Observable<Event> {
    return fromEvent(this.overlayOriginEl, 'mouseenter').pipe(
      filter(() => !this.isOpened),
      switchMap((enterEvent: Event) =>
        fromEvent(document, 'mousemove').pipe(
          startWith(enterEvent),
          filter((event: Event) =>
            this.isInsideOriginElement(this.overlayOriginEl, event),
          ),
        ),
      ),
    );
  }

  /**
   * Listen to the 'mousemove' event on the document. When the cursor stops moving
   * we will check if we moved outside the origin element or the CDK overlay.
   */
  private createCloseObservable(): Observable<Event> {
    return fromEvent(document, 'mousemove').pipe(
      filter(() => this.isOpened),
      filter((event: Event) =>
        this.isMovedOutside(this.overlayOriginEl, this.dialog, event),
      ),
    );
  }

  /**
   * Change the state of the dialog (open/close it). Also emit whether the dialog
   * was opened or closed.
   */
  private changeState(isOpened: boolean): void {
    if (this.disabled) {
      return;
    }
    this.isOpened = isOpened;

    if (isOpened) {
      this.ttMouseoverOpen.emit();
    } else {
      this.ttMouseoverClose.emit();
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * If the cursor has moved outside the element of origin and the dialog that
   * was opened by the CDK overlay.
   */
  private isMovedOutside(
    overlayOriginEl: HTMLElement,
    dialog: ElementRef,
    event: Event,
  ): boolean {
    if (!('target' in event)) {
      return true;
    }

    return !(
      overlayOriginEl.contains(event.target as Node) ||
      dialog.nativeElement.contains(event.target)
    );
  }

  /**
   * If the cursor is within the boundaries of the element of origin.
   */
  private isInsideOriginElement(
    overlayOriginEl: HTMLElement,
    event: Event,
  ): boolean {
    if (!('target' in event)) {
      return false;
    }

    return (
      overlayOriginEl.contains(event.target as Node) ||
      overlayOriginEl === event.target
    );
  }
}
