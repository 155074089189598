import { TsRange } from '@techniek-team/class-transformer';
import { Exclude, Expose, Type } from 'class-transformer';
import { format, isFuture, isSameDay, isSameMonth, isSameYear, max, min } from 'date-fns';
import { nl } from 'date-fns/locale';
import { AssignmentHasSlotDetailedWithSlot } from '../assignment-has-slot/assignment-has-slot-with-slot.model';
import { AssignmentDetailed } from './assignment-detail.model';

/**
 * The Detailed version of the Assignment resource from Scheduler-api
 */
export class AssignmentDetailedWithAssignmentHasSlot extends AssignmentDetailed {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'AssignmentDetailedWithAssignmentHasSlot';

  /**
   * A list of all the slots or shift within the assignment. Each slot contains
   * a start/end time for when it takes place.
   */
  @Type(() => AssignmentHasSlotDetailedWithSlot)
  @Expose()
  public assignmentHasSlots: AssignmentHasSlotDetailedWithSlot[] = [];

  @Exclude()
  public get whoPeriodString(): string {
    const start: Date = min(this.getCorrectTimePeriods.map((period) => period.start));
    const end: Date = max(this.getCorrectTimePeriods.map((period) => period.start));

    if (isSameDay(start, end)) {
      return format(start, "d MMM ''yy", { locale: nl });
    } else if (isSameMonth(start, end)) {
      return `${format(start, 'd')}-${format(end, "d MMM ''yy")}`;
    } else if (isSameYear(start, end)) {
      return `${format(start, 'd MMM')}-${format(end, "d MMM ''yy")}`;
    }
    return `${format(start, "d MMM '''yy")}-${format(end, "d MMM ''yy")}`;
  }

  /**
   * Return either the actual time periods which can either be the actual time period
   * or the slot time period if the actual isn't set yet.
   */
  @Exclude()
  public get getCorrectTimePeriods(): TsRange[] {
    return this.assignmentHasSlots.map((assignmentHasSlots) => {
      return assignmentHasSlots.realTimePeriod;
    });
  }

  /**
   * Return true if the first start time of any slot lies in the past.
   */
  @Exclude()
  public isPast(): boolean {
    for (let slot of this.assignmentHasSlots) {
      if (isFuture(slot.startDate)) {
        return false;
      }
    }
    return true;
  }
}
