import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SCHEDULING_FEATURE_KEY, SchedulingState } from '../reducers/scheduling.reducer';

export class SchedulingApproachSelectors {
  private static readonly state = createFeatureSelector<SchedulingState>(SCHEDULING_FEATURE_KEY);

  public static readonly activeApproachId = createSelector(
    SchedulingApproachSelectors.state,
    (state) => state.activeApproach,
  );

  public static readonly savingApproach = createSelector(
    SchedulingApproachSelectors.state,
    (state) => state.savingApproach,
  );
}
