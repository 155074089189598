import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Slot, SlotContract } from '@scheduler-frontend/assignment-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { Observable } from 'rxjs';
import { CreateSlotRequest, UpdateSlotRequest } from './+state/api/slot.request';
import { slotsActions } from './+state/slots.actions';
import { SlotsSelectors } from './+state/slots.selectors';

@Injectable()
export class SlotsStoreService {
  private readonly store = inject(Store);
  public loading$: Observable<boolean> = this.store.pipe(select(SlotsSelectors.loading));

  public activeSlot$ = this.store.pipe(select(SlotsSelectors.activeSlot));

  public setActiveSlot(slot: Slot | string): void {
    if (slot instanceof Slot) {
      slot = slot.getId();
    }
    this.store.dispatch(slotsActions.setActiveSlot({ slotId: slot }));
  }

  public createSlot(slots: CreateSlotRequest[]): void {
    this.store.dispatch(slotsActions.createSlots({ request: slots }));
  }

  public updateSlot(slots: UpdateSlotRequest): void {
    this.store.dispatch(slotsActions.updateSlot({ request: slots }));
  }

  public showConfirmationToDeleteSlot(slot: SlotContract | string): void {
    this.store.dispatch(
      slotsActions.showConfirmationToDeleteSlot({ slotId: jsonLdSelectId(slot) }),
    );
  }
}
