import { Component, input } from '@angular/core';
import {
  IsActiveMatchOptions,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'tt-sub-menu-item',
  templateUrl: './tt-sub-menu-item.component.html',
  styleUrls: ['./tt-sub-menu-item.component.scss'],
  standalone: true,
  imports: [IonicModule, RouterLink, RouterLinkActive],
})
export class TtSubMenuItemComponent {
  /**
   * Commands to pass to {@link Router#createUrlTree Router#createUrlTree}.
   *   - **array**: commands to pass to {@link Router#createUrlTree Router#createUrlTree}.
   *   - **string**: shorthand for array of commands with just the string, i.e. `['/route']`
   *   - **null|undefined**: effectively disables the `routerLink`
   * @see {@link Router#createUrlTree Router#createUrlTree}
   * @see RouterLink.routerLink
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public routerLink = input.required<any[] | string>();

  /**
   * Options to configure how to determine if the router link is active.
   *
   * These options are passed to the `Router.isActive()` function.
   *
   * @see Router.isActive
   * @see RouterLinkActive.routerLinkActiveOptions
   */
  public routerLinkActiveOptions = input<
    { exact: boolean } | IsActiveMatchOptions
  >({
    exact: false,
  });
}
