import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ActionCreator, select, Store } from '@ngrx/store';
import {
  AssignmentStateDisplayValues,
  TransitionToStateEnum,
} from '@scheduler-frontend/assignment-contracts';
import { DeclineReason } from '@scheduler-frontend/data-access-decline-reasons';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, filter, from, map, of, switchMap } from 'rxjs';
import { TransitionEnum, transitionMessages } from '../../enums/transition.enum';
import { activeAssignmentActions } from '../actions/active-assignment.actions';
import { assignmentsActions } from '../actions/assignments.actions';
import { AssignmentApi } from '../api/assignment/assignment.api';
import { AssignmentsSelectors } from '../selectors/assignments.selectors';

@Injectable()
export class AssignmentsEffects {
  private readonly actions$ = inject(Actions);

  private readonly errorHandler = inject(SentryErrorHandler);

  private readonly toastService = inject(ToastService);

  private assignmentApi = inject(AssignmentApi);

  private readonly store = inject(Store);

  private readonly modalController = inject(ModalController);

  public readonly loadActiveAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.setActiveAssignment),
      filter((action) => !!action.selectedId),
      switchMap((action) =>
        this.assignmentApi.getAssignment(action.selectedId as string).pipe(
          map((response) =>
            activeAssignmentActions.loadActiveAssignmentSuccess({
              assignment: response,
            }),
          ),
          catchError((error) =>
            of(
              activeAssignmentActions.loadActiveAssignmentFailure({
                error: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly loadActiveAssignmentFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(activeAssignmentActions.loadActiveAssignmentFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de opdracht.',
        }),
      ),
    { dispatch: false },
  );

  public readonly applyTransition = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentsActions.applyTransition, activeAssignmentActions.applyTransition),
      concatLatestFrom((action) => [
        this.store.pipe(select(AssignmentsSelectors.selectAssignmentOrActive(action)), isDefined()),
      ]),
      exhaustMap((params) => {
        return this.createConfirmTransitionModal(params[0].transition).pipe(
          filter(({ role }) => role === 'confirm'),
          map((response) => params),
        );
      }),
      exhaustMap(([action, activeAssignment]) => {
        return this.assignmentApi
          .applyTransition({
            assignment: jsonLdSelectId(activeAssignment['@id']),
            transition: action.transition,
          })
          .pipe(
            map((response) =>
              assignmentsActions.applyTransitionSuccess({
                newState: response.newState,
              }),
            ),
            catchError((error) => of(assignmentsActions.applyTransitionFailure({ error: error }))),
          );
      }),
    ),
  );

  public readonly applyTransitionFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(assignmentsActions.applyTransitionFailure, {
          message: 'Opdracht-transitie is mislukt!',
        }),
      ),
    { dispatch: false },
  );

  public readonly applyTransitionSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(assignmentsActions.applyTransitionSuccess, {
          message: 'Opdracht-transitie is gelukt!',
        }),
      ),
    { dispatch: false },
  );

  private createConfirmTransitionModal<ActionType extends ReturnType<ActionCreator>>(
    transition: TransitionEnum,
  ) {
    const transitionToState = TransitionToStateEnum[
      transition
    ] as unknown as keyof typeof AssignmentStateDisplayValues;
    let newState = AssignmentStateDisplayValues[transitionToState];
    return from(
      this.modalController.create({
        component: TtSimpleModalComponent,
        cssClass: ['stack-modal'],
        componentProps: {
          title: 'Bevestig status wijziging',
          message: `${transitionMessages[transition]} De nieuwe status wordt dan "${newState}".`,
        },
      }),
    ).pipe(
      switchMap((response) => response.present().then(() => response)),
      switchMap((response) => response.onWillDismiss<DeclineReason | undefined>()),
    );
  }
}
