import { DOCUMENT } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faAlarmClock,
  faCalendarClock,
  faCalendarDays,
  faCalendarUsers,
  faChartUser,
  faFileInvoice,
  faFileInvoiceDollar,
  faFlask,
  faMagnifyingGlass,
  faSnooze,
  faUserGroup,
} from '@fortawesome/pro-duotone-svg-icons';
import { IonicModule } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UsersViewPermissionStoreService } from '@scheduler-frontend/data-access-users';
import { environment } from '@scheduler-frontend/environments';
import { LoggedInUserComponent } from '@scheduler-frontend/menu';
import { ReleaseService } from '@scheduler-frontend/release';
import {
  TtMenuComponent,
  TtMenuItemComponent,
  TtSubMenuItemComponent,
} from '@techniek-team/components/menu-layout';
import { TtPermissionModule } from '@techniek-team/permissions';
import { SentryEnvironment } from '@techniek-team/sentry-web';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { from } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    FaIconComponent,
    LoggedInUserComponent,
    TtMenuComponent,
    TtMenuItemComponent,
    TtPermissionModule,
    TtSubMenuItemComponent,
    TtMenuComponent,
    FaIconComponent,
    IonicModule,
    TtPermissionModule,
    TtMenuItemComponent,
    TtSubMenuItemComponent,
    LoggedInUserComponent,
  ],
})
export class AppComponent implements OnInit {
  private readonly storage = inject(Storage);

  private readonly document = inject<Document>(DOCUMENT);

  private readonly route = inject(ActivatedRoute);

  private readonly releaseService = inject(ReleaseService);

  protected readonly usersViewPermissionStoreService = inject(UsersViewPermissionStoreService);

  public isBeta: boolean = false;

  protected readonly faChartUser = faChartUser;

  protected readonly faMagnifyingGlass = faMagnifyingGlass;

  protected readonly faCalendarUsers = faCalendarUsers;

  protected readonly faCalendarDays = faCalendarDays;

  protected readonly faUserGroup = faUserGroup;

  protected readonly faSnooze = faSnooze;

  protected readonly faFlask = faFlask;

  protected readonly faAlarmClock = faAlarmClock;

  protected readonly faCalendarClock = faCalendarClock;

  protected readonly faFileInvoice = faFileInvoice;

  protected readonly faFileInvoiceDollar = faFileInvoiceDollar;

  protected isExpanded = signal(false);

  constructor() {
    //eslint-disable-next-line @typescript-eslint/naming-convention
    this.storage.create().catch();
  }

  private readonly redirectToBeta = injectNavigationEnd()
    .pipe(
      withLatestFrom(from<Promise<boolean>>(this.storage.get('enableBeta') as Promise<boolean>)),
    )
    .subscribe(([_rootEvent, enableBeta]) => {
      const route = this.route.firstChild?.firstChild ?? (this.route.firstChild as ActivatedRoute);
      const hasPleaseNoMoreBeta = route.snapshot.queryParamMap.has('pleaseNoMoreBeta');
      if (hasPleaseNoMoreBeta) {
        //noinspection JSIgnoredPromiseFromCall
        this.storage.remove('enableBeta');
      }
      if (!hasPleaseNoMoreBeta && enableBeta !== null) {
        if (environment.environment === SentryEnvironment.PRODUCTION) {
          this.document.location.href = 'https://beta.roostertool.mijnskoleo.nl/';
        } else {
          //eslint-disable-next-line no-console
          console.log('In production you would be redirected to beta version');
        }
      }
    });

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    const isDev: boolean = !!this.document.location.origin.match(/localhost$/);
    this.isBeta = !!(this.document.location.origin.match(/^http(s)?:\/\/beta/) || isDev);
    this.releaseService.openWhatsNewModalOnNewRelease().catch();
  }

  public launchBeta(): void {
    this.isBeta = !this.isBeta;

    if (this.isBeta) {
      //noinspection JSIgnoredPromiseFromCall
      this.storage.set('enableBeta', 'true');
      if (environment.environment === SentryEnvironment.PRODUCTION) {
        this.document.location.href = 'https://beta.roostertool.mijnskoleo.nl/';
      } else {
        //eslint-disable-next-line no-console
        console.log('In production you would be redirected to beta version');
      }
    } else {
      //noinspection JSIgnoredPromiseFromCall
      this.storage.remove('enableBeta');
      if (environment.environment === SentryEnvironment.PRODUCTION) {
        this.document.location.href = 'https://roostertool.mijnskoleo.nl/?pleaseNoMoreBeta=true';
      } else {
        //eslint-disable-next-line no-console
        console.log('In production you would be redirected to production version');
      }
    }
  }
}
