import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, filter, map, of, switchMap } from 'rxjs';
import { activeAssignmentActions } from '../actions/active-assignment.actions';
import { assignmentCompensationsActions } from '../actions/assignment-compensations.actions';
import { AssignmentCompensationApi } from '../api/assignment-compensation/assignment-compensation.api';
import { LessonApi } from '../api/lesson.api';
import { adapter } from '../reducer/assignment-compensation.reducer';
import { AssignmentCompensationsSelectors } from '../selectors/assignment-compensations.selectors';
import { AssignmentsSelectors } from '../selectors/assignments.selectors';

const { selectAll, selectEntities } = adapter.getSelectors();

@Injectable()
export class AssignmentCompensationsEffects {
  private readonly assignmentCompensationApi = inject(AssignmentCompensationApi);

  private readonly lessonApi = inject(LessonApi);

  private readonly store = inject(Store);

  private readonly actions$ = inject(Actions);

  public readonly setActiveAssignmentCompensation = createEffect(() => {
    return this.actions$.pipe(
      ofType(assignmentCompensationsActions.loadAssignmentCompensationSuccess),
      map(({ assignmentCompensation }) =>
        assignmentCompensationsActions.setActiveAssignmentCompensation({
          assignmentCompensation: jsonLdSelectId(assignmentCompensation['@id']),
        }),
      ),
    );
  });

  public readonly loadAssignmentCompensationWhenHavingAActiveAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.setActiveAssignment),
      filter((action) => !!action.selectedId),
      map((action) =>
        assignmentCompensationsActions.loadAssignmentCompensation({
          assignment: action.selectedId as string,
        }),
      ),
    ),
  );

  public readonly loadAssignmentCompensation = createEffect(() => {
    return this.actions$.pipe(
      ofType(assignmentCompensationsActions.loadAssignmentCompensation),
      switchMap(({ assignment }) => {
        return this.assignmentCompensationApi.getAssignmentCompensation(assignment).pipe(
          map((response) =>
            assignmentCompensationsActions.loadAssignmentCompensationSuccess({
              assignmentCompensation: response,
            }),
          ),
          catchError((error) =>
            of(assignmentCompensationsActions.loadAssignmentCompensationFailure({ error: error })),
          ),
        );
      }),
    );
  });

  public readonly loadAssignmentCompensationFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignmentCompensationsActions.loadAssignmentCompensationFailure),
        filter((action) => {
          return action?.error?.status !== 404;
        }),
        handleEndpointFailure(assignmentCompensationsActions.loadAssignmentCompensationFailure, {
          message:
            'Er is iets misgegaan bij het ophalen van de uitbetalingsgegevens van de opdracht.',
        }),
      ),
    { dispatch: false },
  );

  public readonly addCompensationLine = createEffect(() => {
    return this.actions$.pipe(
      ofType(assignmentCompensationsActions.addCompensationLine),
      concatLatestFrom(() =>
        this.store.pipe(
          select(AssignmentCompensationsSelectors.activeAssignmentCompensationId),
          isDefined(),
        ),
      ),
      exhaustMap(([{ compensationLine }, assignmentCompensationId]) => {
        return this.assignmentCompensationApi
          .addCompensationLine(assignmentCompensationId, compensationLine)
          .pipe(
            map((response) =>
              assignmentCompensationsActions.addCompensationLineSuccess({
                compensationLine: response,
              }),
            ),
            catchError((error) =>
              of(
                assignmentCompensationsActions.addCompensationLineFailure({
                  error: error,
                }),
              ),
            ),
          );
      }),
    );
  });

  public addCompensationLineFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignmentCompensationsActions.addCompensationLineFailure),
        handleEndpointFailure(assignmentCompensationsActions.addCompensationLineFailure, {
          message: 'Er is iets misgegaan bij het toevoegen van de uitbetalingsregel.',
        }),
      ),
    { dispatch: false },
  );

  public readonly addCompensationLineSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignmentCompensationsActions.addCompensationLineSuccess),
        handleEndpointSuccess(assignmentCompensationsActions.addCompensationLineSuccess, {
          message: 'Uitbetalingsregel is toegevoegd.',
        }),
      ),
    { dispatch: false },
  );

  public readonly removeCompensationLine = createEffect(() => {
    return this.actions$.pipe(
      ofType(assignmentCompensationsActions.removeCompensationLine),
      exhaustMap(({ compensationLine }) => {
        return this.assignmentCompensationApi.removeCompensationLine(compensationLine).pipe(
          map(() =>
            assignmentCompensationsActions.removeCompensationLineSuccess({
              compensationLine: compensationLine,
            }),
          ),
          catchError((error) =>
            of(
              assignmentCompensationsActions.removeCompensationLineFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    );
  });

  public readonly removeCompensationLineFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignmentCompensationsActions.removeCompensationLineFailure),
        handleEndpointFailure(assignmentCompensationsActions.removeCompensationLineFailure, {
          message: 'Er is iets misgegaan bij het verwijderen van de uitbetalingsregel.',
        }),
      ),
    { dispatch: false },
  );

  public readonly removeCompensationLineSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignmentCompensationsActions.removeCompensationLineSuccess),
        handleEndpointSuccess(assignmentCompensationsActions.removeCompensationLineSuccess, {
          message: 'Uitbetalingsregel is verwijderd.',
        }),
      ),
    { dispatch: false },
  );

  public readonly updateAssignmentCompensations = createEffect(() => {
    return this.actions$.pipe(
      ofType(assignmentCompensationsActions.updateAssignmentCompensations),
      concatLatestFrom(() => [
        this.store.pipe(select(AssignmentsSelectors.activeAssignmentId), isDefined()),
        this.store.pipe(select(AssignmentsSelectors.activeAssignment), isDefined()),
      ]),
      exhaustMap(([action, assignmentId, assignment]) => {
        const needsCompensation =
          action.mustPerformCompensation ?? assignment.performCompensation ?? false;
        return this.assignmentCompensationApi
          .postMustPerformCompensation(
            assignmentId,
            needsCompensation,
            action.automaticTravelCompensation ?? needsCompensation
              ? action.automaticTravelCompensation ??
                  assignment.automaticTravelCompensation ??
                  false
              : false,
          )
          .pipe(
            map((response) =>
              assignmentCompensationsActions.updateAssignmentCompensationsSuccess({
                mustPerformCompensation: response.mustPerformCompensation,
                automaticTravelCompensation: response.automaticTravelCompensation,
              }),
            ),
            catchError((error) =>
              of(
                assignmentCompensationsActions.updateAssignmentCompensationsFailure({
                  error: error,
                }),
              ),
            ),
          );
      }),
    );
  });

  public readonly deselectActiveAssignmentCompensationOnUpdate = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentCompensationsActions.updateAssignmentCompensations),
      filter((action) => !action.mustPerformCompensation),
      map((action) =>
        assignmentCompensationsActions.setActiveAssignmentCompensation({
          assignmentCompensation: null,
        }),
      ),
    ),
  );

  public readonly loadAssignmentCompensationCompensationIsDisabled = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentCompensationsActions.updateAssignmentCompensations),
      filter((action) => !!action.mustPerformCompensation),
      concatLatestFrom(() =>
        this.store.select(AssignmentsSelectors.activeAssignmentId).pipe(isDefined()),
      ),
      map(([action, activeAssignmentId]) =>
        assignmentCompensationsActions.loadAssignmentCompensation({
          assignment: activeAssignmentId as string,
        }),
      ),
    ),
  );

  public updateAssignmentCompensationsFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(assignmentCompensationsActions.updateAssignmentCompensationsFailure, {
          message: 'Er is iets misgegaan bij het aanpassen van de uitbetalingseisen.',
        }),
      ),
    { dispatch: false },
  );

  public readonly updateAssignmentCompensationsSuccess = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(assignmentCompensationsActions.updateAssignmentCompensationsSuccess, {
          message: 'Uitbetalingseisen zijn aangepast.',
        }),
      ),
    { dispatch: false },
  );
}
