import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  availabilityStatisticsAdapter,
  AvailabilityStatisticsState,
  TRAVEL_DISTANCE_STATISTICS_FEATURE_KEY,
} from './availability-statistics.reducer';

const { selectAll, selectEntities } = availabilityStatisticsAdapter.getSelectors();

export class AvailabilityStatisticsSelectors {
  public static readonly availabilityStatisticsState =
    createFeatureSelector<AvailabilityStatisticsState>(TRAVEL_DISTANCE_STATISTICS_FEATURE_KEY);

  public static readonly availabilityStatisticsLoading = createSelector(
    AvailabilityStatisticsSelectors.availabilityStatisticsState,
    (state: AvailabilityStatisticsState) => state.loading,
  );

  public static readonly availabilityStatisticsLoaded = createSelector(
    AvailabilityStatisticsSelectors.availabilityStatisticsState,
    (state: AvailabilityStatisticsState) => state.loaded,
  );

  public static readonly availabilityStatisticsError = createSelector(
    AvailabilityStatisticsSelectors.availabilityStatisticsState,
    (state: AvailabilityStatisticsState) => state.error,
  );

  public static readonly availabilityStatistics = createSelector(
    AvailabilityStatisticsSelectors.availabilityStatisticsState,
    (state: AvailabilityStatisticsState) => selectAll(state),
  );

  public static readonly availabilityStatisticEntities = createSelector(
    AvailabilityStatisticsSelectors.availabilityStatisticsState,
    (state: AvailabilityStatisticsState) => selectEntities(state),
  );
}
