<ion-item
  lines="none"
  data-test="tt-menu-item"
  button
  [id]="'tt-menu-item-' + uuid"
  [@.disabled]="true"
  [routerLink]="routerLink()"
  [routerLinkActiveOptions]="routerLinkActiveOptions()"
  [class.expanded]="expanded()"
  routerLinkActive="active"
  #routerLinkActive="routerLinkActive"
  >
  @if (!ref.innerHTML.trim()) {
    <ng-template #iconTemplate>
      @if (isFontAwesomeIcon()) {
        <fa-icon
          data-test="tt-menu-item-icon"
          [fixedWidth]="true"
          [slot]="expanded() ? 'start' : ''"
          [icon]="fontAwesomeIcon()"
          >
        </fa-icon>
      } @else {
        @if (icon()) {
          <ion-icon
            data-test="tt-menu-item-icon"
            [slot]="expanded() ? 'start' : ''"
            [icon]="icon()"
            >
          </ion-icon>
        }
      }
    </ng-template>

    <div class="navigation-rail-item">
      <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      @if (title() && showLabelWhenCollapsed() && !expanded()) {
        <ion-label>{{ title() }}</ion-label>
      }
    </div>
    @if (expanded()) {
      <ion-label>
        @if (title()) {
          {{ title() }}
        }
      </ion-label>
    }
  }
  <div class="custom-content" #ref>
    <ng-content></ng-content>
  </div>
</ion-item>
<ion-popover
  #popover
  [dismissOnSelect]="true"
  [keepContentsMounted]="true"
  [isOpen]="popoverOpen"
  [showBackdrop]="false"
  class="menu-item-popover"
  side="right"
  >
  <ng-template> </ng-template>
</ion-popover>
@if (submenu()) {
  <tt-mouseover
    [position]="[
      { overlayX: 'start', originX: 'end', overlayY: 'top', originY: 'top' }
    ]"
    [trigger]="'tt-menu-item-' + uuid"
    >
    <ion-list class="ion-no-padding menu-item-popover">
      <ion-list-header>{{ title() }}</ion-list-header>

      @if (submenu()) {
        <ng-container *ngTemplateOutlet="submenu() ?? null"></ng-container>
      }
    </ion-list>
  </tt-mouseover>
}
