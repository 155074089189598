import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { AssignmentCompensationContract } from '../../contracts/assignment-compensation.contract';
import { assignmentCompensationsActions } from '../actions/assignment-compensations.actions';

export const ASSIGNMENT_COMPENSATIONS_FEATURE_KEY = 'AssignmentCompensations';

export interface AssignmentCompensationsState extends EntityState<AssignmentCompensationContract> {
  loaded: boolean;
  loading: boolean;
  error?: unknown | null;
  selectedId: string | null;
  savingCompensationLine: boolean;
  savingAssignmentCompensations: boolean;
}

export const adapter: EntityAdapter<AssignmentCompensationContract> =
  createEntityAdapter<AssignmentCompensationContract>({
    selectId: jsonLdSelectId,
  });

export const initialState: AssignmentCompensationsState = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  selectedId: null,
  savingCompensationLine: false,
  savingAssignmentCompensations: false,
});

const reducer = createReducer(
  initialState,
  on(
    assignmentCompensationsActions.setActiveAssignmentCompensation,
    (state, { assignmentCompensation }) => ({
      ...state,
      selectedId: assignmentCompensation,
    }),
  ),
  on(assignmentCompensationsActions.loadAssignmentCompensation, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(
    assignmentCompensationsActions.loadAssignmentCompensationSuccess,
    (state, { assignmentCompensation }) =>
      adapter.addOne(assignmentCompensation, {
        ...state,
        loading: false,
        loaded: true,
      }),
  ),
  on(assignmentCompensationsActions.loadAssignmentCompensationFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  on(assignmentCompensationsActions.addCompensationLine, (state) => ({
    ...state,
    savingCompensationLine: true,
  })),
  on(assignmentCompensationsActions.addCompensationLineSuccess, (state, { compensationLine }) => {
    if (!state.selectedId) {
      return state;
    }
    // Ensure that the AssignmentCompensationContract entity being updated is in the state
    const current = state.entities[state.selectedId];
    if (!current) {
      return state;
    }

    // Add the new AssignmentCompensationLineContract
    current.assignmentCompensationLines.push(compensationLine);

    // Update the entity in the state
    return adapter.updateOne(
      {
        id: state.selectedId,
        changes: current,
      },
      {
        ...state,
        savingCompensationLine: false,
      },
    );
  }),
  on(assignmentCompensationsActions.addCompensationLineFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingCompensationLine: false,
  })),

  on(assignmentCompensationsActions.removeCompensationLine, (state) => ({
    ...state,
    savingCompensationLine: true,
  })),
  on(
    assignmentCompensationsActions.removeCompensationLineSuccess,
    (state, { compensationLine }) => {
      if (!state.selectedId) {
        return state;
      }
      // Ensure that the AssignmentCompensationContract entity being updated is in the state
      const current = state.entities[state.selectedId as string];

      if (!current) {
        return state;
      }

      // Remove the AssignmentCompensationLineContract
      const newArray = current.assignmentCompensationLines.filter(
        (line) => jsonLdSelectId(line['@id']) !== compensationLine,
      );

      // Update the entity in the state
      return adapter.updateOne(
        {
          id: state.selectedId as string,
          changes: {
            assignmentCompensationLines: newArray,
          },
        },
        {
          ...state,
          savingCompensationLine: false,
        },
      );
    },
  ),
  on(assignmentCompensationsActions.removeCompensationLineFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingCompensationLine: false,
  })),
  on(assignmentCompensationsActions.updateAssignmentCompensations, (state) => ({
    ...state,
    error: null,
    savingAssignmentCompensations: true,
  })),
  on(assignmentCompensationsActions.updateAssignmentCompensationsSuccess, (state) => ({
    ...state,
    error: null,
    savingAssignmentCompensations: false,
  })),
  on(assignmentCompensationsActions.updateAssignmentCompensationsFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingAssignmentCompensations: false,
  })),
);

export function assignmentCompensationsReducer(
  state: AssignmentCompensationsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
