import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ApproachAssigneeFilter,
  ApproachContract,
  ApproachDetailedContract,
} from '@scheduler-frontend/approach-contracts';

export const approachesActions = createActionGroup({
  source: '[Approaches]',
  events: {
    markApproachAsHandled: emptyProps(),
    markApproachAsHandledSuccess: props<{ approach: ApproachContract }>(),
    markApproachAsHandledFailure: props<{ error: unknown }>(),

    markApproachAsExpired: emptyProps(),
    markApproachAsExpiredSuccess: props<{ approach: ApproachContract }>(),
    markApproachAsExpiredFailure: props<{ error: unknown }>(),

    loadApproach: props<{ approach: string }>(),
    loadApproachSuccess: props<{ approach: ApproachDetailedContract }>(),
    loadApproachFailure: props<{ error: unknown }>(),

    setActiveAssigneeFilter: props<{ filter: ApproachAssigneeFilter }>(),
    setActiveCandidateSearch: props<{ value: string }>(),

    approachCreated: props<{ approach: ApproachContract }>(),
    approachUpdated: props<{ approach: ApproachContract }>(),

    /**
     * these action enable the approach mechanism when this is not started the approaches store wil not set an slot
     * into the schedulingStore
     */
    startSchedulingByApproaches: emptyProps(),
    stopSchedulingByApproaches: emptyProps(),
  },
});
