import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { CandidateContract } from '@scheduler-frontend/candidate-contracts';
import { RankingContract } from '@scheduler-frontend/data-access-rankings';
import { NonNullableDictionary } from '@techniek-team/tt-ngrx';

export const candidatesByRankingActions = createActionGroup({
  source: '[CandidatesByRanking]',
  events: {
    initCandidatesByRanking: emptyProps(),
    stopCandidatesByRanking: emptyProps(),
    loadNextChunk: emptyProps(),
    refreshCandidatesByRanking: emptyProps(),
    loadCandidatesByRankingSuccess: props<{
      candidates: CandidateContract[];
      candidateIds: string[];
      chunk: number;
      totalItems: number;
    }>(),
    loadNextChunkSuccess: props<{
      candidates: CandidateContract[];
      candidateIds: string[];
      chunk: number;
      totalItems: number;
    }>(),
    loadCandidatesByRankingFailure: props<{ error: unknown }>(),
    removeCandidateForCandidateByRankingList: props<{ candidate: string }>(),
    initLoadSlotByCandidate: emptyProps(),
    stopLoadSlotByCandidate: emptyProps(),
    loadSlotsByCandidateSuccess: props<{
      items: {
        slot: SlotDetailedContract;
        ranking: RankingContract;
      }[];
    }>(),
    loadSlotByCandidateFailure: props<{ error: unknown }>(),

    /**
     * This action trigger the load of alternative shifts use on for example
     * the {@see ApproachAssignCandidatePage}
     */
    startShownAlternativeShifts: emptyProps(),
    stopShownAlternativeShifts: emptyProps(),
    loadAlternativeShiftsSuccess: props<{ items: NonNullableDictionary<SlotDetailedContract[]> }>(),
    loadAlternativeShiftsFailure: props<{ error: unknown }>(),
  },
});
