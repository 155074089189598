import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SLOTS_FEATURE_KEY, slotsAdapter, SlotsState } from './slots.reducer';

// Lookup the 'DataAccessSlots' feature state managed by NgRx
const { selectAll, selectEntities } = slotsAdapter.getSelectors();

export class SlotsSelectors {
  public static readonly slotsState = createFeatureSelector<SlotsState>(SLOTS_FEATURE_KEY);

  public static readonly loading = createSelector(
    SlotsSelectors.slotsState,
    (state: SlotsState) => state.loadingSlot,
  );

  public static readonly error = createSelector(
    SlotsSelectors.slotsState,
    (state: SlotsState) => state.error,
  );

  public static readonly slotEntities = createSelector(
    SlotsSelectors.slotsState,
    (state: SlotsState) => selectEntities(state),
  );

  public static readonly slots = createSelector(SlotsSelectors.slotsState, (state: SlotsState) =>
    selectAll(state),
  );

  public static readonly activeSlotsId = createSelector(
    SlotsSelectors.slotsState,
    (state: SlotsState) => state.selectedId,
  );

  public static readonly currentSearchMeta = createSelector(
    SlotsSelectors.slotsState,
    (state: SlotsState) => state.currentSearch,
  );

  public static readonly selectCurrentSearchLoadedChunks = createSelector(
    SlotsSelectors.currentSearchMeta,
    (search) => search.loadedChunks,
  );

  public static readonly selectCurrentSearchTotalItems = createSelector(
    SlotsSelectors.currentSearchMeta,
    (search) => search.totalItems,
  );

  public static readonly selectCurrentSearchLoading = createSelector(
    SlotsSelectors.currentSearchMeta,
    (search) => search.loading,
  );

  public static readonly selectCurrentSearchLoaded = createSelector(
    SlotsSelectors.currentSearchMeta,
    (search) => search.loaded,
  );

  public static readonly activeSlot = createSelector(
    SlotsSelectors.slotEntities,
    SlotsSelectors.activeSlotsId,
    (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
  );
}
