<ion-item class="item-interactive item-select ion-activatable ion-focusable"
  data-test="tt-ion-select-search-control-input"
  [disabled]="disabled"
  (click)="$event.preventDefault();"
  [class.tt-item-has-focus]="(isOpen$ | async) || (selectedDisableString$ | async)"
  [attr.id]="'select-popup-'+uniqueId"
  [class.item-has-focus]="(isOpen$ | async)"
  [lines]="fill !== 'outline' ? lines : 'none'"
  [class.tt-fill]="fill === 'outline'">
  <ng-content select="ion-label"></ng-content>
  <div aria-hidden="true" class="select-text">
    {{ selectedDisableString$ | async }}
    @if ((selection.selected.length > 1)) {
      <span class="others">
        (+{{ selection.selected.length -1 }} anderen)
      </span>
    }
  </div>
  <ion-icon slot="end"
    size="small"
    [color]="colorSubject$ | async"
    icon="caret-down">
  </ion-icon>
  <ng-content select="ion-note"></ng-content>
  <ion-popover #popover
    [showBackdrop]="showBackdrop"
    [trigger]="(!disabled) ? 'select-popup-'+uniqueId : undefined"
    class="ion-select-search-control {{ size }}"
    [alignment]="alignment"
    [arrow]="arrow"
    [size]="size"
    [triggerAction]="(disabled) ? undefined : 'click'">
    <ng-template>
      <ion-header>
        <ion-searchbar class="ion-no-padding"
          mode="md"
          autofocus
          [placeholder]="placeholder"
          (keyup.arrowDown)="shiftFocusToList($event)"
          (keyup.enter)="selectFirstItem($event)"
        [formControl]="searchInput"></ion-searchbar>
      </ion-header>
      <ion-content class="ion-no-padding" [scrollY]="false">
        <ng-content></ng-content>
        <ng-template #defaultOption let-item let-search="search">
          <span  [innerHTML]="((displayNameFunction) ? displayNameFunction(item) : item.toString()) | highlightSearchText: search"></span>
        </ng-template>
        <div>
          <cdk-virtual-scroll-viewport class="ion-content-scroll-host"
            [style.width.px]="width"
            itemSize="56"
            minBufferPx="900"
            maxBufferPx="1350">
            <ion-list lines="none" class="sc-ion-select-popover-md">
              <ion-radio-group class="sc-ion-select-popover-md" (ionChange)="change($event)">
                @if ((filteredItems$ | async); as items) {
                  @if (items.length) {
                    <ng-container *cdkVirtualFor="let item of items; let i = index">
                      @if (multiple) {
                        <ion-item class="sc-ion-select-popover-md"
                          [class.item-radio-checked]="selection.isSelected(item)"
                          >
                          <ion-checkbox [slot]="checkboxPlacement"
                            style="pointer-events: none"
                            [checked]="selection.isSelected(item)"
                            labelPlacement="end"
                            [value]="item">
                            <ng-container *ngTemplateOutlet="(this.templateSubject$ | async) ?? defaultOption; context: { $implicit: item }"></ng-container>
                          </ion-checkbox>
                        </ion-item>
                      } @else {
                        <ion-item  class="sc-ion-select-popover-md"
                          [button]="true"
                          [class.item-radio-checked]="selection.isSelected(item)"
                          [class.ion-focused]="selection.isSelected(item)"
                          (click)="change($event, item)">
                          <ion-label>
                            <ng-container *ngTemplateOutlet="(this.templateSubject$ | async) ?? defaultOption; context: { $implicit: item, search: searchTerm$ | async }"></ng-container>
                          </ion-label>
                        </ion-item>
                      }
                    </ng-container>
                  } @else {
                    <ion-item class="no-results-found">
                      <ion-label>{{ notFoundText }}</ion-label>
                    </ion-item>
                  }
                } @else {
                  <ion-item class="no-results-found">
                    <ion-label>{{ notFoundText }}</ion-label>
                  </ion-item>
                }
              </ion-radio-group>
            </ion-list>
          </cdk-virtual-scroll-viewport>
        </div>
      </ion-content>
      @if (multiple) {
        <ion-footer>
          <ion-button (click)="cancel()"
            size="small"
            fill="clear"
          >Annuleren</ion-button>
          <ion-button (click)="popover.dismiss()"
            size="small"
            fill="clear"
            color="secondary"
          >Ok</ion-button>
        </ion-footer>
      }
    </ng-template>
  </ion-popover>
</ion-item>



