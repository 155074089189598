import { concatLatestFrom } from '@ngrx/operators';
import { Action, select, Store } from '@ngrx/store';
import { ApproachAssigneeFilter, ApproachContract } from '@scheduler-frontend/approach-contracts';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map, MonoTypeOperatorFunction, pipe } from 'rxjs';
import { ApproachesSelectors } from '../../selectors/approaches.selectors';

export function filterOnActiveAssigneeFilterOption<
  A extends { approach: ApproachContract } & Action,
>(store: Store): MonoTypeOperatorFunction<A> {
  return pipe(
    concatLatestFrom(() => [
      store.pipe(select(ApproachesSelectors.selectedAssigneeFilter)),
      store.pipe(select(UsersSelectors.activeUser)),
    ]),
    filter(([action, approachAssigneeFilter, user]): boolean => {
      if (approachAssigneeFilter === ApproachAssigneeFilter.ALL) {
        return true;
      }
      return !!(user && jsonLdSelectId(action.approach.candidate['@id']) === user?.id);
    }),
    map(([action]) => action),
  );
}

export function filterOnActiveCandidateSearchOption<
  A extends { approach: ApproachContract } & Action,
>(store: Store): MonoTypeOperatorFunction<A> {
  return pipe(
    concatLatestFrom(() => [store.pipe(select(ApproachesSelectors.selectedCandidateSearch))]),
    filter(([action, search]): boolean => {
      if (!search || search === '') {
        return true;
      }

      // We can only compare the full name of the candidate. The phone number is
      // not available in the candidate information in the frontend, so people
      // searching on phone number are not seeing matched candidates move in the
      // approach flow.
      return action.approach.candidate.fullName.toLowerCase().includes(search.toLowerCase());
    }),
    map(([action]) => action),
  );
}
