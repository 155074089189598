import { Dictionary } from '@ngrx/entity/src/models';
import { NonNullableDictionary } from '@techniek-team/tt-ngrx';

/**
 * A memoized function that creates a numerical count dictionary within a provided range.
 *
 * This function generates a dictionary-style object, with keys based on a numerical range
 * controlled by amount and startIndex parameters, and initial values defined by startValue.
 *
 * @param {number} amount - The amount of numerical range dictionary keys.
 * @param {number} [startIndex=0] - The start value for the numerical range of dictionary keys.
 * @param {number} [startValue=0] - The initial value to assign for each key in the dictionary.
 * @returns {Dictionary<number>} - The resulting dictionary with numerical keys and initial values.
 *
 * @example
 * const amount = 5;
 * const startIndex = 1;
 * const startValue = 2;
 *
 * const countDictionary = createCountDictionary(amount, startIndex, startValue);
 *
 * // The resulting countDictionary will look something like this:
 * {
 *   "1": 2,
 *   "2": 2,
 *   "3": 2,
 *   "4": 2,
 *   "5": 2
 * }
 */
export function createCountDictionary(
  amount: number,
  startIndex: number = 0,
  startValue: number = 0,
): NonNullableDictionary<number> {
  let countMap: NonNullableDictionary<number> = {};
  for (let i: number = startIndex; i <= amount + startIndex - 1; i++) {
    countMap[i.toString()] = startValue;
  }

  return countMap;
}
