import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  adapter,
  ASSIGNMENT_TRANSITIONS_FEATURE_KEY,
  AssignmentTransitionsState,
} from '../reducer/assignment-transitions.reducer';

const { selectEntities, selectAll } = adapter.getSelectors();

export class AssignmentTransitionsSelectors {
  public static readonly state = createFeatureSelector<AssignmentTransitionsState>(
    ASSIGNMENT_TRANSITIONS_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    AssignmentTransitionsSelectors.state,
    (state) => state.loading,
  );

  public static readonly loaded = createSelector(
    AssignmentTransitionsSelectors.state,
    (state) => state.loaded,
  );

  public static readonly error = createSelector(
    AssignmentTransitionsSelectors.state,
    (state) => state.error,
  );

  public static readonly assignmentTransitionEntities = createSelector(
    AssignmentTransitionsSelectors.state,
    (state) => selectEntities(state),
  );

  public static readonly assignmentTransitions = createSelector(
    AssignmentTransitionsSelectors.state,
    (state) => selectAll(state),
  );
}
