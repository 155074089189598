import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { BusinessEntityContract } from '../../contracts/business-entity.contract';

@Injectable({
  providedIn: 'root',
})
export class BusinessEntityApi {
  private readonly httpClient = inject(HttpClient);

  /**
   * Gets a sorted (by name ascending) list of all business services.
   */
  public getBusinessEntities(): Observable<Collection<BusinessEntityContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/business_entities`;
    return this.httpClient.get<Collection<BusinessEntityContract>>(url);
  }
}
