/**
 * eslint-disable @typescript-eslint/no-explicit-any
 *

 */

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Filters, SearchContract, SearchDetailedContract } from '../../contract/search.contract';

export const searchActions = createActionGroup({
  source: '[Scheduling/Search]',
  events: {
    /**
     * create a new search which can be either a user initiated search of a system search.
     */
    createSearchHash: props<{
      filters: Filters;
      isUserInitiated: boolean;
      setAsCurrentSearch?: boolean;
    }>(),
    createSearchHashSuccess: props<{
      setAsCurrentSearch?: boolean;
      isUserInitiated: boolean;
      search: SearchContract;
    }>(),
    createSearchHashFailure: props<{ error: unknown }>(),

    appendToSearchHash: props<{
      filters: Filters;
      // optional option to also remove keys from the search hash
      removeKeys?: string[];
      isUserInitiated: boolean;
      setAsCurrentSearch?: boolean;
    }>(),
    appendToSearchHashSuccess: props<{
      setAsCurrentSearch?: boolean;
      isUserInitiated: boolean;
      search: SearchContract;
    }>(),

    removeFromSearchHash: props<{
      keys: string[];
      isUserInitiated: boolean;
      setAsCurrentSearch?: boolean;
    }>(),
    removeFromSearchHashSuccess: props<{
      setAsCurrentSearch?: boolean;
      isUserInitiated: boolean;
      search: SearchContract;
    }>(),

    /**
     * this action is a partial representation of the {@see UserSearchActions.setCurrentUserSearch}.
     * contain a query which only matches the visible view. It will change when the user changes the
     * current visible view, even if he/she is still within his/her current (user initiated) search.
     */
    setCurrentSystemSearch: props<{ searchId: string }>(),
    initCurrentSystemSearch: props<{ searchId: string }>(),
    clearCurrentSystemSearch: emptyProps(),

    addSlotListToCurrentSearchSuccess: props<{ search: SearchDetailedContract }>(),
    addSlotListToCurrentSearchFailure: props<{ error: unknown }>(),

    /**
     * reloads the current (system) search (only containing the curent view)
     */
    reloadCurrentSearch: emptyProps(),
    /**
     * loads the next chunk for the current (system) search
     */
    nextChunkCurrentSearch: emptyProps(),

    /**
     * The action is started when being at the location schedule page. It enables the following:
     *
     * - effect: An effect that sets the current location as search filter.
     */
    startLocationScheduleSearch: emptyProps(),
    initLocationScheduleSearch: emptyProps(),
    stopLocationScheduleSearch: emptyProps(),

    /**
     * These actions enable/disable the search mechanism. Only when it is enabled the search store can set slots into
     * the schedulingStore. It enables the following:
     *
     * - effect: it loads candidates based on the selected slots using the search api.
     * - effect: it adds candidateAllotments to the store based on the response of the candidateBySlotsEndpoint api.
     * - effect: it adds travel Distance Statistics to the store based on the response of the candidateBySlotsEndpoint
     * api.
     * - effect: it adds Ranking Statistics to the store based on the response of the candidateBySlotsEndpoint api.
     * - effect: it adds Candidate Availability to the store based on the response of the candidateBySlotsEndpoint api.
     * - effect: it adds candidate to the store based on the response of the candidateBySlotsEndpoint api.
     */
    startSchedulingBySearch: emptyProps(),
    initSchedulingBySearch: emptyProps(),
    stopSchedulingBySearch: emptyProps(),
  },
});
