import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { hashParams } from '@scheduler-frontend/common';
import { getISODay } from 'date-fns';
import { groupBy, memoize } from 'lodash-es';

/**
 * A memoized function that groups slot contracts by ISO day number.
 *
 * It receives an array of slot contracts, and returns a grouped object.
 * The grouping is done based on the start date of the `timePeriod` of each slot.
 *
 * @param {(SlotContract | SlotDetailedContract)[]} slots - An array of SlotContract or SlotDetailedContract elements.
 * @returns {Dictionary<(SlotContract | SlotDetailedContract)[]>} - Returns a dictionary object where the key is the
 *  ISO day number and the value is an array of slot contracts found on that day.
 *
 * @example
 * const slots = [
 *   {timePeriod: {start: new Date('2022-01-31T12:30:00'), end: new Date('2022-01-31T13:30:00')}, ...otherData}, //
 *   Monday
 *   {timePeriod: {start: new Date('2022-02-01T10:00:00'), end: new Date('2022-02-01T11:00:00')}, ...otherData}, //
 *   Tuesday
 *   {timePeriod: {start: new Date('2022-02-01T15:30:00'), end: new Date('2022-02-01T16:30:00')}, ...otherData}, //
 *   Tuesday
 * ];
 *
 * const groupedSlots = groupSlotsByISODayNumber(slots);
 *
 * // The resulting groupedSlots will look something like this:
 * {
 *   "1": [{timePeriod: {...}, ...otherData}], // Monday = 1
 *   "2": [{timePeriod: {...}, ...otherData}, {timePeriod: {...}, ...otherData}], // Tuesday = 2
 * }
 */
export const groupSlotsByISODayNumber = memoize(
  (slots: (SlotContract | SlotDetailedContract)[]) => {
    return groupBy(slots, (slot) => getISODay(slot.timePeriod.start));
  },
  hashParams,
);
