import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CALENDAR_EVENT_FEATURE_KEY,
  calendarEventEntityAdapter,
  CalendarEventState,
} from '../reducers/calendar-events.reducer';

export class CalendarEventsSelectors {
  public static readonly calendarState = createFeatureSelector<CalendarEventState>(
    CALENDAR_EVENT_FEATURE_KEY,
  );

  public static readonly selectLoading = createSelector(
    CalendarEventsSelectors.calendarState,
    (state) =>
      state.calendarEvents.assignments.loading && state.calendarEvents.availabilities.loading,
  );

  public static readonly selectLoaded = createSelector(
    CalendarEventsSelectors.calendarState,
    (state) =>
      state.calendarEvents.assignments.loaded && state.calendarEvents.availabilities.loaded,
  );

  public static readonly selectError = createSelector(
    CalendarEventsSelectors.calendarState,
    (state) => state.error,
  );

  public static readonly selectActiveDate = createSelector(
    CalendarEventsSelectors.calendarState,
    (state) => state.activeDate,
  );

  //eslint-disable-next-line max-len
  public static readonly selectCurrentView = createSelector(
    CalendarEventsSelectors.calendarState,
    (state) => state.currentView,
  );

  //eslint-disable-next-line max-len
  public static readonly selectAll = createSelector(
    CalendarEventsSelectors.calendarState,
    (state) => calendarEventEntityAdapter.getSelectors().selectAll(state),
  );
}
