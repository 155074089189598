import { createFeatureSelector, createSelector } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { toDictionary } from '@techniek-team/tt-ngrx';
import { DeclineReason } from '../models/decline-reason.model';
import {
  DECLINE_REASONS_FEATURE_KEY,
  declineReasonsAdapter,
  DeclineReasonsState,
} from './decline-reasons.reducer';

const { selectAll, selectEntities } = declineReasonsAdapter.getSelectors();

export class DeclineReasonsSelectors {
  public static readonly declineReasonsState = createFeatureSelector<DeclineReasonsState>(
    DECLINE_REASONS_FEATURE_KEY,
  );

  public static readonly loading = createSelector(
    DeclineReasonsSelectors.declineReasonsState,
    (state: DeclineReasonsState) => state.loading,
  );

  public static readonly loaded = createSelector(
    DeclineReasonsSelectors.declineReasonsState,
    (state: DeclineReasonsState) => state.loaded,
  );

  public static readonly cacheTimestamp = createSelector(
    DeclineReasonsSelectors.declineReasonsState,
    (state: DeclineReasonsState) => state.cacheTimeStamp,
  );

  public static readonly error = createSelector(
    DeclineReasonsSelectors.declineReasonsState,
    (state: DeclineReasonsState) => state.error,
  );

  public static readonly declineReasons = createSelector(
    DeclineReasonsSelectors.declineReasonsState,
    (state: DeclineReasonsState) => selectAll(state),
  );

  public static readonly declineReasonEntities = createSelector(
    DeclineReasonsSelectors.declineReasonsState,
    (state: DeclineReasonsState) => selectEntities(state),
  );

  public static readonly denormalizedDeclineReasonEntities = createSelector(
    DeclineReasonsSelectors.declineReasonsState,
    (state) =>
      toDictionary(denormalize(DeclineReason, Object.values(state.entities)), (item) =>
        item.getId(),
      ),
  );
}
