import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import {
  CandidateContract,
  CandidateDetailedContract,
  CandidateHasSkillsContract,
} from '@scheduler-frontend/candidate-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { candidatesSearchActions } from '../actions/candidates-search.actions';
import { candidatesActions } from '../actions/candidates.actions';

export const CANDIDATES_FEATURE_KEY = 'candidates';

export interface CandidatesState
  extends EntityState<CandidateContract | CandidateDetailedContract> {
  selectedId: string | null;
  error?: unknown;
  loadingSelected: boolean;
  loadedSelected: boolean;
  activeCandidateSkills: CandidateHasSkillsContract[] | null;
  loadedDetailedCandidates: string[];
  savingCandidateRejection: boolean;
}

//eslint-disable-next-line max-len
export const candidateAdapter: EntityAdapter<CandidateContract | CandidateDetailedContract> =
  createEntityAdapter<CandidateContract | CandidateDetailedContract>({
    selectId: jsonLdSelectId,
  });

export const initialCandidatesState: CandidatesState = candidateAdapter.getInitialState({
  error: null,
  selectedId: null,
  loadingSelected: false,
  loadedSelected: false,
  activeCandidateSkills: null,
  loadedDetailedCandidates: [],
  savingCandidateRejection: false,
  loadingCandidatesList: false,
});

const reducer: ActionReducer<CandidatesState> = createReducer(
  initialCandidatesState,
  on(candidatesActions.setActiveCandidate, (state, { selectedId }) => {
    return {
      ...state,
      selectedId: selectedId,
      loadingSelected:
        typeof selectedId === 'string' && !state.loadedDetailedCandidates.includes(selectedId),
    };
  }),
  on(candidatesActions.loadActiveCandidateSuccess, (state, { candidate }) => {
    return candidateAdapter.setOne(candidate, {
      ...state,
      loadedDetailedCandidates: [
        ...state.loadedDetailedCandidates,
        jsonLdSelectId(candidate['@id']),
      ],
      loadingSelected: false,
      loadedSelected: true,
      loadingAssignments: true,
    });
  }),
  on(candidatesActions.loadActiveCandidateFailure, (state, { error }) => ({
    ...state,
    error: error,
    loadingSelected: false,
    loadingAssignments: false,
  })),
  on(candidatesActions.clearActiveCandidate, (state) => ({
    ...state,
    selectedId: null,
    loadedSelected: false,
  })),
  on(candidatesActions.loadCandidatesSuccess, (state, { candidates }) => {
    return candidateAdapter.upsertMany(candidates, {
      ...state,
      loadingSelected: false,
      loadedSelected: false,
      loadingAssignments: true,
    });
  }),
  on(
    candidatesActions.addCandidates,
    candidatesSearchActions.loadCandidateSearchNextChunkSuccess,
    candidatesSearchActions.loadCandidateSearchSuccess,
    (state, { candidates }) => {
      return candidateAdapter.upsertMany(candidates, { ...state });
    },
  ),
  on(candidatesActions.markCandidateRejection, (state) => ({
    ...state,
    savingCandidateRejection: true,
  })),
  on(candidatesActions.markCandidateRejectionSuccess, (state) => ({
    ...state,
    savingCandidateRejection: false,
  })),
  on(candidatesActions.markCandidateRejectionFailure, (state, { error }) => ({
    ...state,
    savingCandidateRejection: false,
    error: error,
  })),
);

export function candidatesReducer(
  state: CandidatesState | undefined,
  action: Action,
): CandidatesState {
  return reducer(state, action);
}
