import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Resource } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { FetchStorageInterface } from '@techniek-team/fetch';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { businessEntitiesActions } from './+state/business-entities.actions';
import { BusinessEntitiesSelectors } from './+state/business-entities.selectors';
import { BusinessEntityContract } from './contracts/business-entity.contract';
import { BusinessEntity } from './models/business-entity.model';

@Injectable()
export class BusinessEntitiesStoreService
  implements FetchStorageInterface<BusinessEntityContract, BusinessEntity>
{
  private readonly store = inject(Store);

  public loading$: Observable<boolean> = this.store.pipe(select(BusinessEntitiesSelectors.loading));

  public loaded$: Observable<boolean> = this.store.pipe(select(BusinessEntitiesSelectors.loaded));

  public businessEntities$: Observable<BusinessEntity[]> = this.store.pipe(
    select(BusinessEntitiesSelectors.businessEntities),
    isDefined(),
    map((data) => {
      return data.map((item: Resource<BusinessEntityContract>) => {
        return denormalize(BusinessEntity, item);
      });
    }),
    shareReplay(),
  );

  public businessEntitiesMap$: Observable<Map<string, BusinessEntity>> = this.store.pipe(
    select(BusinessEntitiesSelectors.businessEntityEntities),
    map((data) => {
      return new Map(
        Object.entries(data).map(([key, _item]) => {
          return [key, denormalize(BusinessEntity, data)];
        }),
      );
    }),
    shareReplay(),
  );

  public businessEntitiesFilterGroup = this.store.selectSignal(
    BusinessEntitiesSelectors.businessEntitiesFilterGroup,
  );

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(businessEntitiesActions.initBusinessEntities());
  }

  public reload(): void {
    this.store.dispatch(businessEntitiesActions.reloadBusinessEntities());
  }

  public supportsFetch(identifier: string): boolean {
    return identifier === 'BusinessEntity';
  }

  public async waitForInitialization(): Promise<void> {
    await firstEmitFrom(
      this.store.pipe(
        select(BusinessEntitiesSelectors.loaded),
        filter((loaded) => loaded === true),
      ),
    );
  }

  public getFetchFromStorage(value: string): Observable<BusinessEntity> {
    return this.store.pipe(
      select(BusinessEntitiesSelectors.denormalizedBusinessEntityEntities),
      map((dict) => dict[jsonLdSelectId(value)]),
      isDefined(),
    );
  }
}
