<ion-list class="candidate">
  <ng-container
    *ngrxLet="assignmentsStoreService.activeAssignmentCandidate$ as candidate; suspenseTpl: loading"
  >
    <ion-item lines="none">
      <ion-label data-test="assignment-general-fullName">{{ candidate?.fullName }}</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label data-test="assignment-general-phoneNumber">{{ candidate?.phoneNumber }}</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label data-test="assignment-general-emailAddress"
        >{{ candidate?.emailAddress }}
      </ion-label>
    </ion-item>
  </ng-container>
  <ng-template #loading>
    <ion-item lines="none">
      <ion-label>
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>
        <ion-skeleton-text [animated]="true" style="width: 80px"></ion-skeleton-text>
      </ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </ion-label>
    </ion-item>
  </ng-template>
  <ng-container
    *ngrxLet="
      assignmentsStoreService.activeAssignment$ as assignment;
      suspenseTpl: loadingLocationName
    "
  >
    @if (assignment.location(); as location) {
      <ion-item lines="none">
        <ion-label>
          <strong>Locatie</strong>
          <p>{{ location }}</p>
        </ion-label>
      </ion-item>
    }
  </ng-container>
  <ng-template #loadingLocationName>
    <ion-item lines="none">
      <ion-label>
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </ion-label>
    </ion-item>
  </ng-template>
</ion-list>
@if (activeAssignmentsPermissionsStoreService.canReadSelfAssignable()) {
  <ng-container *ngrxLet="assignmentsStoreService.activeAssignment$ | async; let assignment">
    <app-self-assign-form
      data-test="assignment-general-self-assign-form"
      [assignment]="assignment | asType: AssignmentDetailedWithAssignmentHasSlot"
    ></app-self-assign-form>
    <app-urgent-form [assignment]="assignment"></app-urgent-form>
  </ng-container>
}
<app-assignment-has-slot-table></app-assignment-has-slot-table>
