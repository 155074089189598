import { Signal } from '@angular/core';
import { ProductTypeDetailed } from '@scheduler-frontend/data-access-product-types';
import { SignalFetch } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { BusinessService } from './business-service.model';
import { DeliveryType } from './delivery-type.model';

/**
 * Detailed version of the BusinessEntity resource from Scheduler-api.
 */
export class BusinessServiceDetailed extends BusinessService {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'BusinessServiceDetailed';

  /**
   * Array of Delivery types which are ways to suply the customer with this service.
   * {@see AssignmentDeliveryTypeEnum}
   */
  @Type(() => DeliveryType)
  @Expose()
  public deliveryTypes: DeliveryType[] = [];

  /**
   * The product type this businessService belongs to.
   *
   * Business service can be seen as the combination of {@see BusinessEntity}
   * and {@see ProductType}.
   */
  @SignalFetch(() => ProductTypeDetailed)
  @Expose()
  public override productType!: Signal<ProductTypeDetailed | undefined>;

  public get validDeliveryTypes(): DeliveryType[] {
    return (this.deliveryTypes ?? []).filter(
      (deliveryType) => !deliveryType.isPast() && !deliveryType.isFuture(),
    );
  }
}
