<div class="table">
  <div class="header status"></div>
  <div class="header date">Datum</div>
  <div class="header time">Tijd</div>
  <div class="header break">Pauze</div>
  <div class="header time-input">(Werkelijk)</div>
  <div class="header role">Role</div>
  <div class="header saldo">Saldo</div>
  <div class="header actions"></div>

  <ng-container *ngrxLet="assignmentsStoreService.activeAssignmentHasSlots$ as assignmentHasSlots">
    @for (
      assignmentHasSlot of assignmentHasSlots ?? [];
      track assignmentHasSlot;
      let index = $index
    ) {
      <app-assignment-has-slot-item
        [style.grid-row]="index + 3"
        [assignmentHasSlot]="assignmentHasSlot"
      ></app-assignment-has-slot-item>
    }
  </ng-container>
</div>
