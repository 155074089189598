<ion-header mode="md">
  <ion-toolbar
    mode="md"
    data-test="header-toolbar"
    [color]="translucent ? undefined : color ?? 'white'"
    [class.flat]="flat"
    [class.translucent]="translucent"
    class="ion-padding-horizontal"
  >
    <div slot="start">
      <ng-content select="[slot=start]"></ng-content>
    </div>
    <div>
      <ng-content></ng-content>
      <ng-content select="app-location"></ng-content>
    </div>
    <div slot="end">
      <ng-content select="[slot=end]"></ng-content>
    </div>
  </ion-toolbar>
  <ng-content select="ion-toolbar"></ng-content>
  <ng-content select="[slot='footer']"></ng-content>
  <ng-content select="[footer]"></ng-content>
</ion-header>
