import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { CandidateDetailedContract } from '@scheduler-frontend/candidate-contracts';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, map, of, switchMap } from 'rxjs';
import { candidateApproachesActions } from '../actions/candidate-approaches.actions';
import { candidatesActions } from '../actions/candidates.actions';
import { ApproachApi } from '../api/approaches/approach.api';
import { CandidateApproachesSelectors } from '../selectors/candidate-approaches.selectors';
import { CandidatesSelectors } from '../selectors/candidates.selectors';

@Injectable()
export class CandidateApproachesEffects {
  private readonly approachApi = inject(ApproachApi);

  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public loadApproaches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidatesActions.loadActiveCandidateSuccess,
        candidatesActions.refreshActiveCandidate,
      ),
      concatLatestFrom(() =>
        this.store.select(CandidatesSelectors.activeCandidateId).pipe(isDefined()),
      ),
      switchMap(([action, previousSelectedId]) => {
        const candidateId: string =
          'candidate' in action
            ? jsonLdSelectId((action as { candidate: CandidateDetailedContract }).candidate)
            : previousSelectedId;

        return this.approachApi.getApproaches(1, candidateId).pipe(
          map((approachesCollection) =>
            candidateApproachesActions.loadApproachesSuccess({
              approaches: approachesCollection['hydra:member'],
              chunk: 1,
              totalItems: approachesCollection['hydra:totalItems'],
            }),
          ),
          catchError((error) =>
            of(candidateApproachesActions.loadApproachesFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  //eslint-disable-next-line max-lines-per-function
  public createLoadNextChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateApproachesActions.loadNextChunk),
      concatLatestFrom(() => [
        this.store.pipe(
          select(CandidateApproachesSelectors.loadedChunks),
          map((chunks) => Math.max(...chunks)),
        ),
        this.store.select(CandidatesSelectors.activeCandidateId).pipe(isDefined()),
      ]),
      exhaustMap(([_action, lastChunk, selectedCandidateId]) => {
        return this.approachApi.getApproaches(lastChunk + 1, selectedCandidateId).pipe(
          map((approachesCollection) =>
            candidateApproachesActions.loadNextChunkSuccess({
              approaches: approachesCollection['hydra:member'],
              chunk: lastChunk + 1,
              totalItems: approachesCollection['hydra:totalItems'],
            }),
          ),
          catchError((error) =>
            of(candidateApproachesActions.loadApproachesFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  public loadApproachesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidateApproachesActions.loadApproachesFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de benaderingen van de begeleider.',
        }),
      ),
    { dispatch: false },
  );
}
