export enum M3Duration {
  SHORT_1 = '50ms',
  SHORT_2 = '100ms',
  SHORT_3 = '150ms',
  SHORT_4 = '200ms',
  SHORT = '200ms',
  MEDIUM_1 = '250ms',
  MEDIUM_2 = '300ms',
  MEDIUM_3 = '350ms',
  MEDIUM_4 = '400ms',
  MEDIUM = '400ms',
  LONG_1 = '450ms',
  LONG_2 = '500ms',
  LONG_3 = '550ms',
  LONG_4 = '600ms',
  LONG = '500ms',
  EXTRA_LONG_1 = '700ms',
  EXTRA_LONG_2 = '800ms',
  EXTRA_LONG_3 = '900ms',
  EXTRA_LONG_4 = '1000ms',
  EXTRA_LONG = '1000ms',
  DEBUG = '10000ms',
}

export enum M3Easing {
  LINEAR = 'cubic-bezier(0, 0, 1, 1)',
  STANDARD = 'cubic-bezier(0.2, 0, 0, 1)',
  STANDARD_ACCELERATE = 'cubic-bezier(0.3, 0, 1, 1)',
  STANDARD_DECELERATE = 'cubic-bezier(0, 0, 0, 1)',
  EMPHASIZED = 'cubic-bezier(0.2, 0, 0, 1)',
  EMPHASIZED_ACCELERATE = 'cubic-bezier(0.3, 0, 0.8, 0.15)',
  EMPHASIZED_DECELERATE = 'cubic-bezier(0.05, 0.7, 0.1, 1)',
}
