@if (assignmentsStoreService.activeAssignmentCompensation()) {
  <app-general-information></app-general-information>
}
<app-edit-payout></app-edit-payout>

@if (assignmentsStoreService.savingAssignmentCompensations()) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}
@if (assignmentsStoreService.activeAssignmentCompensation(); as compensation) {
  <div class="compensations">
    <table
      mat-table
      class="ion-color-primary"
      [dataSource]="compensation.assignmentCompensationLines"
    >
      <ng-container matColumnDef="articleCode">
        <th mat-header-cell *matHeaderCellDef>Artikelcode</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.isExtra ? 'Extra: ' : '' }}</span>
          {{
            ArticleCodeEnumDisplayValues[(element | asType: AssignmentCompensationLine).articleCode]
          }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Omschrijving</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="fit-content">Datum</th>
        <td mat-cell class="fit-content" *matCellDef="let element">
          {{ element.date | date: 'dd-MM-yyyy' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th mat-header-cell class="fit-content" *matHeaderCellDef>Aantal</th>
        <td mat-cell class="fit-content" *matCellDef="let element">
          {{ element.quantity | number: '1.0-2' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Bedrag</th>
        <td mat-cell *matCellDef="let element">
          {{ element.amount | currency: 'EUR' : 'symbol' : '1.2-4' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Subtotaal</th>
        <td mat-cell *matCellDef="let element">
          {{ element.subtotal | currency: 'EUR' : 'symbol' : '1.2-4' }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ compensation.total | currency: 'EUR' : 'symbol' : '1.2-4' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          @if (element.isExtra || element.articleCode === PayoutArticleCodeEnum.SLOT_PREMIUM) {
            <ion-button
              [disabled]="
                !activeAssignmentsPermissionsStoreService.canDeleteAssignmentCompensationLine()
              "
              (click)="deleteLine(element.id)"
            >
              @if ((assignmentsStoreService.savingCompensationLine$ | async) === false) {
                <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
              }
              @if (assignmentsStoreService.savingCompensationLine$ | async) {
                <ion-spinner></ion-spinner>
              }
            </ion-button>
          }
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; let i = index; columns: displayedColumns"
        [class.extra]="row.isExtra"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns"
        [class.ion-hide]="(assignmentsStoreService.activeAssignmentCompensation$ | async) === null"
      ></tr>
    </table>
    @if (
      (assignmentsStoreService.activeAssignment$ | async)?.restrictAssignmentCompensationAccess
    ) {
      <div class="access-denied">
        <h2>
          Toegang geweigerd
          <fa-icon [icon]="faEyeSlash"> visibility_off </fa-icon>
        </h2>
        <p>
          De uitbetalingsdetails van deze opdracht mogen niet door jou worden ingezien.<br />
          Deze opdracht heeft namelijk een product waarbij deze restrictie is ingesteld.
        </p>
      </div>
    }
    @if (assignmentsStoreService.activeAssignment$; as assignment) {
      <ion-button
        (click)="addLine()"
        [disabled]="
          assignmentsStoreService.activeCompensationAllowedArticleCodes().length === 0 ||
          !activeAssignmentsPermissionsStoreService.canCreateAssignmentCompensationLine()
        "
        fill="clear"
        class="ion-float-right"
        size="small"
      >
        Toevoegen
      </ion-button>
    }
  </div>
} @else {
  <div class="compensations" style="width: 80%">
    <p>
      De vergoeding voor deze opdracht is nog niet bekend.<br />
      Het is nog niet duidelijk of deze begeleider wel of geen vergoeding ontvangt voor deze
      opdracht,<br />
      en zoja wat de vergoeding dan moet zijn.
    </p>
    @if (assignmentsStoreService.activeAssignment$ | async; as assignment) {
      <p>
        Neem contact op met
        <a target="_blank" href="https://helpdesk.mijnskoleo.nl/">applicatiebeheer</a>
        en informeer of in Perza vergoedingsregels zijn aangemaakt <br />
        voor {{ assignment.candidate?.fullName }} voor de rol
        {{ assignment.assignmentHasSlots[0].slot.role()?.toString() }} in dienst
        {{ assignment.assignmentHasSlots[0].slot.role()?.businessService()?.name }}.
      </p>
    }
  </div>
}
