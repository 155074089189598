<ion-card [button]="button">
  <ion-card-content>
    @if (iconRef) {
      @if ((loading$ | async) === false) {
        <div class="icon">
          <ng-container [ngTemplateOutlet]="iconRef.templateRef"></ng-container>
        </div>
      } @else {
        <div class="icon">
          <ion-skeleton-text animated></ion-skeleton-text>
        </div>
      }
    }
    <ion-item lines="none">
      <ion-label>
        @if ((loading$ | async) === false) {
          @if (titlePlacement === 'top' && title) {
            <p>{{ title }}</p>
          }
          <h3 [class.center]="!statisticName || !statisticNamePlural">
            {{ statisticPrefix }}
            {{ (value$ | async) | number:digitsInfo }}
            @if (statisticName && statisticNamePlural) {
              {{ (value$ | async) === 1 ? statisticName : statisticNamePlural }}
            }
          </h3>
          @if (titlePlacement === 'bottom' && title) {
            <p>{{ title }}</p>
          }
        } @else {
          @if (titlePlacement === 'top' && title) {
            <p>
              <ion-skeleton-text animated style="width: 20ch; height: 12px;"></ion-skeleton-text>
            </p>
          }
          <h3 class="loading" [class.center]="!statisticName || !statisticNamePlural">
            <ion-skeleton-text animated style="width: 4ch; height: 24px;"></ion-skeleton-text>
          </h3>
          @if (titlePlacement === 'bottom' && title) {
            <p>
              <ion-skeleton-text animated style="width: 20ch; height: 12px;"></ion-skeleton-text>
            </p>
          }
        }
      </ion-label>

      @if (previousValue) {
        @if ((loading$ | async) === false) {
          <!-- The number zero also results into false. Which is intended. -->
          @if ((differenceWithPrevious$ | async); as difference) {
            <div
              slot="end"
              [class]="(difference >= 0) ? 'positive' : 'negative'">
              <ion-icon [name]="(difference >= 0) ? 'trending-up-outline' : 'trending-down-outline'"></ion-icon>
              <span class="caption">
                @if ((difference !== 0)) {
                  {{ (difference < 0) ? '-' : '+' }}
                }
                {{ ((difference < 0) ? (difference.toString() | slice: 1) : difference) | number:digitsInfo }}
                @if (notation === TtStatisticCardNotation.PERCENTAGE) {
                  %
                }
              </span>
            </div>
          }
        } @else {
          <div slot="end">
            <ion-skeleton-text animated style="width: 36px; height: 36px;"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 36px; height: 12px;"></ion-skeleton-text>
          </div>
        }
      }
    </ion-item>
  </ion-card-content>
</ion-card>
