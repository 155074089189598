import { LevelDisplayValues } from '@scheduler-frontend/enums';

export enum AssignmentStateEnum {
  UNASSIGNED = 'unassigned',
  WAITING_FOR_DOCUMENTS = 'waiting_for_documents',
  DRAFT = 'draft',
  WAITING_FOR_CONFIRMATION = 'waiting_for_confirmation',
  WAITING_FOR_CUSTOMER_DOCUMENTS = 'waiting_for_customer_documents',
  PROVISIONALLY_CONFIRMED = 'provisionally_confirmed',
  CONFIRMED = 'confirmed',
  COMPLETED = 'completed',
  APPROVED = 'approved',
  FINAL = 'final',
  CANCELLED = 'cancelled',
  APPROACHED = 'approached',
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum AssignmentStateDisplayValues {
  unassigned = 'Openstaand',
  waiting_for_documents = 'Wachtend op documenten',
  waiting_for_customer_documents = 'Wachtend op offerte',
  draft = 'Concept',
  waiting_for_confirmation = 'Onbevestigd',
  provisionally_confirmed = 'Voorlopig',
  confirm_provisionally = 'Voorlopig',
  confirmed = 'Bevestigd',
  completed = 'Wachtend op goedkeuring',
  approved = 'Goedgekeurd',
  final = 'Afgerond',
  cancelled = 'Geannuleerd',
  approached = 'Benaderd',
}

export enum AssignmentStateShortendDisplayValues {
  unassigned = 'Openstaand',
  waiting_for_documents = 'Wachtend op doc.',
  draft = 'Concept',
  waiting_for_confirmation = 'Onbevestigd',
  waiting_for_customer_documents = 'Wachtend op off.',
  provisionally_confirmed = 'Voorlopig bevestigd',
  confirmed = 'Bevestigd',
  completed = 'Wachtend op goedk.',
  approved = 'Goedgekeurd',
  final = 'Afgerond',
  cancelled = 'Geannuleerd',
  approached = 'Benaderd',
}

export enum AssignmentStateExplanation {
  unassigned = 'uitleg over unassigned',
  waiting_for_documents = 'uitleg over waiting for documents',
  waiting_for_customer_documents = 'uitleg over waiting for customer documents',
  provisionally_confirmed = 'uitleg over voorlopig bevestigd',
  draft = 'uitleg over draft',
  waiting_for_confirmation = 'uitleg over waiting for confirmation',
  confirmed = 'uitleg over waiting for confirmed',
  completed = 'uitleg over waiting for completed',
  approved = 'uitleg over waiting for approved',
  final = 'uitleg over waiting for final',
  cancelled = 'uitleg over geannuleerd',
  approached = 'een of meerdere Begeleiders zijn benaderd voor deze shift',
}

export enum AssignmentStateOrder {
  unassigned = 0,
  waiting_for_documents = 1,
  approached = 2,
  waiting_for_customer_documents = 3,
  draft = 4,
  waiting_for_confirmation = 5,
  provisionally_confirmed = 6,
  confirmed = 7,
  cancelled = 8,
  completed = 9,
  approved = 10,
  final = 11,
}

/* eslint-disable @typescript-eslint/prefer-literal-enum-member */
export enum TransitionToStateEnum {
  //eslint-disable-next-line @typescript-eslint/prefer-literal-enum-member
  assign = AssignmentStateEnum.DRAFT,
  revert_approached = AssignmentStateEnum.UNASSIGNED,
  approach = AssignmentStateEnum.APPROACHED,
  request_confirmation = AssignmentStateEnum.WAITING_FOR_CONFIRMATION,
  reject = AssignmentStateEnum.UNASSIGNED,
  confirm_provisionally = AssignmentStateEnum.PROVISIONALLY_CONFIRMED,
  confirm = AssignmentStateEnum.PROVISIONALLY_CONFIRMED,
  confirm_definitive = AssignmentStateEnum.CONFIRMED,
  complete = AssignmentStateEnum.COMPLETED,
  approve = AssignmentStateEnum.APPROVED,
  finalize = AssignmentStateEnum.FINAL,
  unassign = AssignmentStateEnum.UNASSIGNED,
  cancel = AssignmentStateEnum.CANCELLED,
  withdraw = AssignmentStateEnum.UNASSIGNED,
  revert_approval = AssignmentStateEnum.COMPLETED,
  revert_finalize = AssignmentStateEnum.APPROVED,
  undo_confirm_definitive = AssignmentStateEnum.PROVISIONALLY_CONFIRMED,
}
/* eslint-enable @typescript-eslint/naming-convention, @typescript-eslint/prefer-literal-enum-member */

export function assignmentStateSearchQueryMapper() {
  return {
    'assignment.state': {
      entities: AssignmentStateDisplayValues,
      getDisplayText: (state: string) => state,
    },
  };
}

/**
 * This function check if the given state matches the given target but also takes the order of the states into account.
 *
 * For example when the target CONFIRMED this statement will be true if the state CONFIRMED is given but also when the
 * state is COMPLETED, APPROVED or FINAL
 */
export function isState(
  state: AssignmentStateEnum | undefined,
  target: AssignmentStateEnum,
): boolean {
  if (!state) {
    return false;
  }
  return AssignmentStateOrder[state] >= AssignmentStateOrder[target];
}

/**
 * This function check if the assignment state is in a prelude state of the given target state.
 *
 * For example when the target CONFIRMED this statement will be true if the state CONFIRMED is given but also when the
 * state is UNASSIGNED, WAITING_FOR_DOCUMENTS, DRAFT, WAITING_FOR_CONFIRMATION
 */
export function isBeforeState(state: AssignmentStateEnum, target: AssignmentStateEnum): boolean {
  if (!state) {
    return false;
  }
  return AssignmentStateOrder[state] <= AssignmentStateOrder[target];
}
