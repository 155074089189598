import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssignmentDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { CandidatesSelectors } from '@scheduler-frontend/data-access-candidates';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import {
  ACTIVE_ASSIGNMENT_FEATURE_KEY,
  ActiveAssignmentState,
} from '../reducer/active-assignment.reducer';
import { AssignmentsSelectors } from './assignments.selectors';

export class ActiveAssignmentSelectors {
  public static readonly state = createFeatureSelector<ActiveAssignmentState>(
    ACTIVE_ASSIGNMENT_FEATURE_KEY,
  );

  public static readonly savingIsUrgent = createSelector(
    ActiveAssignmentSelectors.state,
    (state) => state.savingIsUrgent,
  );

  public static readonly savingIsBillable = createSelector(
    ActiveAssignmentSelectors.state,
    (state) => state.savingIsBillable,
  );

  public static readonly savingDescription = createSelector(
    ActiveAssignmentSelectors.state,
    (state) => state.savingDescription,
  );

  public static readonly savingSelfAssignmentSettings = createSelector(
    ActiveAssignmentSelectors.state,
    (state) => state.savingSelfAssignmentSettings,
  );

  public static readonly error = createSelector(
    ActiveAssignmentSelectors.state,
    (state) => state.error,
  );

  public static readonly targetAssignmentHasSlotIsBillable = createSelector(
    ActiveAssignmentSelectors.state,
    AssignmentsSelectors.activeAssignment,
    (state, activeAssignment) => {
      if (!state.targetAssignmentHasSlotIsBillable || !activeAssignment) {
        return undefined;
      }
      return (activeAssignment as AssignmentDetailedContract).assignmentHasSlots.find(
        (item) => jsonLdSelectId(item['@id']) === state.targetAssignmentHasSlotIsBillable,
      );
    },
  );

  public static readonly targetAssignmentHasSlotActualWorkingTimes = createSelector(
    ActiveAssignmentSelectors.state,
    AssignmentsSelectors.activeAssignment,
    (state, activeAssignment) => {
      if (!state.targetAssignmentHasSlotActualWorkingTimes || !activeAssignment) {
        return undefined;
      }
      return (activeAssignment as AssignmentDetailedContract).assignmentHasSlots.find(
        (item) => jsonLdSelectId(item['@id']) === state.targetAssignmentHasSlotActualWorkingTimes,
      );
    },
  );

  public static readonly savingActualWorkingTimes = createSelector(
    ActiveAssignmentSelectors.state,
    (state) => state.savingActualWorkingTimes,
  );

  public static readonly activeAssignmentCandidate = createSelector(
    AssignmentsSelectors.activeAssignment,
    CandidatesSelectors.candidateEntities,
    (assignment, candidates) => {
      if (!assignment?.candidate) {
        return undefined;
      }
      return candidates[jsonLdSelectId(assignment?.candidate['@id'])];
    },
  );
}
