@if (assignmentsStoreService.activeAssignmentHistoryLoading$ | async) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}
<table
  [dataSource]="assignmentsStoreService.activeAssignmentHistory$"
  [trackBy]="trackBy"
  class="ion-color-primary"
  matSort
  mat-table
>
  <ng-container matColumnDef="icon">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element" mat-cell @tableCell>
      <fa-icon
        class="ion-color-{{ element.iconColor }}"
        [icon]="element.icon"
        [fixedWidth]="true"
      ></fa-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="initiatedAt">
    <th *matHeaderCellDef mat-sort-header mat-header-cell>Datum</th>
    <td @tableCell *matCellDef="let element" mat-cell>
      {{ element.initiatedAt | date: 'MMM d, y' }}
      <span>{{ element.initiatedAt | date: 'HH:mm' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th *matHeaderCellDef mat-header-cell></th>
    <td @tableCell mat-cell *matCellDef="let element">
      @switch (element.type) {
        @case (RecordType.TRANSITION) {
          <app-transition-log-record [logRecord]="element"></app-transition-log-record>
        }
        @default {
          {{ element.toString() }}
        }
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="initiatedBy">
    <th *matHeaderCellDef mat-header-cell>Gebruiker</th>
    <td @tableCell *matCellDef="let element" mat-cell>
      @if (element.initiatedBy) {
        {{ element.initiatedBy.fullName }}
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
