import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RoutesView } from '@scheduler-frontend/scheduling-common';
import { PlainRangeInterface, TsRange } from '@techniek-team/class-transformer';
import { formatISO, isDate } from 'date-fns';
import { schedulingViewActions } from './+state/actions/scheduling-view.actions';
import { schedulingActions } from './+state/actions/scheduling.actions';
import { SchedulingDayViewSelectors } from './+state/selectors/scheduling-day-view.selectors';
import { SchedulingViewSelectors } from './+state/selectors/scheduling-view.selectors';
import { SchedulingWeekViewSelectors } from './+state/selectors/scheduling-week-view.selectors';

@Injectable()
export class SchedulingViewStoreService {
  private readonly store = inject(Store);

  public timeRangeInView = this.store.selectSignal(SchedulingViewSelectors.timeRangeInView);

  public view = this.store.selectSignal(SchedulingViewSelectors.currentView);

  public timeRangeInViewReadable = this.store.selectSignal(
    SchedulingViewSelectors.timeRangeInViewReadable,
  );

  public weekViewGrid = this.store.selectSignal(
    SchedulingWeekViewSelectors.slotListAsGridSchedules,
  );

  public dayViewGrid = this.store.selectSignal(SchedulingDayViewSelectors.slotListAsTimelineGrids);

  public inlineAssignButtonsAreVisible = this.store.selectSignal(
    SchedulingViewSelectors.inlineAssignButtonsIsVisible,
  );

  public slotSelectionCheckboxIsVisible = this.store.selectSignal(
    SchedulingViewSelectors.slotSelectionCheckBoxIsVisible,
  );

  public onlyShowSelectedSlotsInView = this.store.selectSignal(
    SchedulingViewSelectors.onlyShowSelectedSlotsInView,
  );

  public changeView(view: RoutesView, date?: string | Date) {
    if (isDate(date)) {
      date = formatISO(date);
    }
    this.store.dispatch(schedulingViewActions.changeCurrentView({ view: view, date: date }));
  }

  public next() {
    this.store.dispatch(schedulingViewActions.nextRangeInView());
  }

  public previous() {
    this.store.dispatch(schedulingViewActions.previousRangeInView());
  }

  public setSchedulingView(
    viewOptions: {
      view?: RoutesView;
      dateInView?: string; // iso format date
      selectedLocations?: string[];
      activeCandidate?: string;
      selectedProductTypes?: string[];
      selectedSlots?: string[];
      selectedCandidates?: string[];
    } = {},
  ) {
    this.store.dispatch(schedulingViewActions.setSchedulingView(viewOptions));
  }

  public setTimeRangeInView(range: PlainRangeInterface<string> | TsRange) {
    if (range instanceof TsRange) {
      range = range.toObject();
    }
    this.store.dispatch(schedulingActions.setTimeRangeInView({ range: range }));
  }

  public showInlineAssignButtons(): void {
    this.store.dispatch(schedulingViewActions.showInlineAssignButtons());
  }

  public hideInlineAssignButtons(): void {
    this.store.dispatch(schedulingViewActions.hideInlineAssignButtons());
  }

  public showSlotSelectionCheckbox(): void {
    this.store.dispatch(schedulingViewActions.showSlotSelectionCheckbox());
  }

  public hideSlotSelectionCheckbox(): void {
    this.store.dispatch(schedulingViewActions.hideSlotSelectionCheckbox());
  }

  public showOnlySelectedSlotsInView(): void {
    this.store.dispatch(schedulingViewActions.showOnlySelectedSlotsInView());
  }

  public stopShowingOnlySelectedSlotsInView(): void {
    this.store.dispatch(schedulingViewActions.stopShowingOnlySelectedSlotsInView());
  }
}
