import { Observable } from 'rxjs';

export interface MutationResponse {
  updated: boolean;
  type: 'remove' | 'append';
  count: number;
}

/**
 * @function fromMutation
 * Create an Observable that uses the DOM's MutationObserver to
 * emit the updated state of the given element's child nodes or descendants --
 * depending on the options. By default, the Observer will check for the
 * addition and removal of elements for the given element and all its
 * descendants, excluding MatRipple elements. For more info about the options:
 * [Mozilla](https://developer.mozilla.org/en-US/docs/Web/API/MutationObserverInit)
 * <br/>
 *
 * @param {HTMLElement} element - the {@link HTMLElement} to observer mutations for.
 *
 * @param {MutationObserverInit} options - (optional) the
 * {@link MutationObserverInit} object. If left empty, `subtree` and `childList`
 * are enabled.
 *
 * <br/>
 * Available options
 * -----------------
 * The following options are available in the {@link MutationObserverInit}
 * object (note: `attributes` and `characterData` are not yet supported)
 * 1. `subtree`: monitor all the descendant elements for mutations. All other
 * options are applied to all descendant elements if `subtree` is enabled.
 * 1. `childList`: monitor direct child elements for mutations.
 * 1. `attributes`: use to watch for specific attribute changes on the element.
 * 1. `attributeFilter`: a list of attributes to watch for specifically. If not
 * set, all attribute changes are monitored.
 * 1. `attributeOldValue`: when set to `true`, records the previous value when
 * monitoring for attribute changes.
 * 1. `characterData`: monitor for character data changes.
 * 1. `characterDataOldValue`: when set to `true`, records the previous value as
 * well when monitoring for character data changes.
 *
 * @todo enable attribute and characterData observation
 *
 * <br/>
 * ## Important for testing
 * Uses `mutationobserver-shim` package to accurately mock the MutationObserver.
 * Make sure to import it in the jest setup file:
 * <br/>
 * ```
 * import 'reflect-metadata';
 * import 'jest-preset-angular';
 * import 'mutationobserver-shim';
 * ... // rest of file
 * ```
 */
export function fromMutation(element: HTMLElement, options?: MutationObserverInit): Observable<MutationResponse> {
  return new Observable(subscriber => {
    const mutationObserver: MutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
      const mutated: MutationResponse = checkMutations(mutations);
      if (mutated.updated) {
        subscriber.next(mutated);
      }
    });

    const init: MutationObserverInit = options ?? { subtree: true, childList: true };
    mutationObserver.observe(element, init);

    return (): void => mutationObserver.disconnect();
  });
}

/**
 * Check for additions and removals in the list of mutations, excluding any
 * mat-ripple-element elements.
 *
 * TODO: attribute and characterData observer options.
 */
function checkMutations(mutations: MutationRecord[]): MutationResponse {
  let update: boolean = false;
  let type: 'remove' | 'append' = 'append';
  let count: number = 0;

  for (const record of mutations) {
    if (record.removedNodes.length) {
      update = true;
      type = 'remove';
      count++;
      continue;
    }

    if (!record.addedNodes.length) {
      continue;
    }

    for (const key in record.addedNodes) {
      if (typeof record.addedNodes[key] !== 'object') {
        continue;
      }

      const node: HTMLElement = record.addedNodes[key] as HTMLElement;
      if (!node.classList?.contains('mat-ripple-element')) {
        update = true;
        count++;
      }
    }
  }

  return { updated: update, type: type, count: count };
}
