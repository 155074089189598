import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { candidatesSearchActions } from './+state/actions/candidates-search.actions';
import { CandidatesSearchSelectors } from './+state/selectors/candidates-search.selectors';

@Injectable()
export class CandidatesSearchStoreService {
  private readonly store = inject(Store);

  public readonly lastChunkLoaded = this.store.selectSignal(
    CandidatesSearchSelectors.lastChunkLoaded,
  );

  public readonly loading = this.store.selectSignal(CandidatesSearchSelectors.loading);

  public readonly candidates = this.store.selectSignal(CandidatesSearchSelectors.candidates);

  public refreshCandidate(): void {
    this.store.dispatch(candidatesSearchActions.refresh());
  }

  public init(): void {
    this.store.dispatch(candidatesSearchActions.loadCandidateSearch());
  }

  public nextChunk(): void {
    this.store.dispatch(candidatesSearchActions.loadCandidateSearchNextChunk());
  }

  public setSearchQuery(query?: string): void {
    this.store.dispatch(candidatesSearchActions.setCandidateSearchQuery({ query: query }));
  }

  public clearSearchQuery(): void {
    this.store.dispatch(candidatesSearchActions.clearSearchQuery());
  }
}
