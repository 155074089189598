import { DOCUMENT } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Storage } from '@ionic/storage';
import { environment } from '@scheduler-frontend/environments';
import { SentryEnvironment } from '@techniek-team/sentry-web';
import { injectNavigationEnd } from 'ngxtension/navigation-end';
import { from } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

/**
 * This component is nothing more than a bootstrap component. Facilitating the routing within the
 * single page application.
 */
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports: [RouterOutlet],
})
export class MainComponent {
  private readonly storage = inject(Storage);

  private readonly document = inject<Document>(DOCUMENT);

  private readonly route = inject(ActivatedRoute);

  private readonly redirectToBeta = injectNavigationEnd()
    .pipe(
      withLatestFrom(from<Promise<boolean>>(this.storage.get('enableBeta') as Promise<boolean>)),
    )
    .subscribe(([rootEvent, enableBeta]) => {
      const route = this.route.firstChild?.firstChild ?? (this.route.firstChild as ActivatedRoute);
      const hasPleaseNoMoreBeta = route.snapshot.queryParamMap.has('pleaseNoMoreBeta');
      if (hasPleaseNoMoreBeta) {
        //noinspection JSIgnoredPromiseFromCall
        this.storage.remove('enableBeta');
      }
      if (!hasPleaseNoMoreBeta && enableBeta !== null) {
        if (environment.environment === SentryEnvironment.PRODUCTION) {
          this.document.location.href = 'https://beta.roostertool.mijnskoleo.nl/';
        } else {
          //eslint-disable-next-line no-console
          console.log('In production you would be redirected to beta version');
        }
      }
    });

  constructor() {
    this.storage.create().catch();
  }
}
