import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CandidateContract,
  CandidateDetailedContract,
} from '@scheduler-frontend/candidate-contracts';
import { CandidateMinimalContract } from '@scheduler-frontend/data-access-users';

export const candidatesActions = createActionGroup({
  source: 'Candidate/Candidate',
  events: {
    /**
     * The active candidate is the candidate used one place where data is retrieve for a specific candidate
     * Examples are: CandidateDetail pane and AssignmentModal
     */
    setActiveCandidate: props<{ selectedId: string | null }>(),
    /**
     * By default the CandidateStore contains basic Candidate contracts. After
     * this command it is overwritten by the Detailed contract.
     */
    loadActiveCandidateSuccess: props<{
      candidate: CandidateDetailedContract;
    }>(),
    loadActiveCandidateFailure: props<{ error: Error }>(),
    refreshActiveCandidate: emptyProps(),
    clearActiveCandidate: emptyProps(),

    /**
     * set a list of candidates to the store comming from other featureStores.
     */
    loadCandidatesSuccess: props<{
      candidates: CandidateContract[];
    }>(),
    loadCandidatesFailure: props<{ error: Error }>(),

    addCandidates: props<{
      candidates: CandidateContract[];
    }>(),

    markCandidateRejection: props<{
      slots: string[];
      declineReason: string;
      candidate?: string;
      assignment?: string;
    }>(),
    markCandidateRejectionSuccess: props<{
      candidate: CandidateMinimalContract;
      assignment?: string;
      slots: string[];
    }>(),
    markCandidateRejectionFailure: props<{ candidate: CandidateMinimalContract; error: unknown }>(),

    openLongTermAvailabilityModal: emptyProps(),
  },
});
