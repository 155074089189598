import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { CompensationLine } from './compensation-lines.model';

/**
 * @deprecated
 */
export class Compensation extends JsonLd {
  @Expose() public state!: string;

  @Expose() public total!: string;

  @Expose() public projectCode!: string;

  @Expose() public division!: string;

  @Type(() => CompensationLine)
  @Expose()
  public assignmentCompensationLines!: CompensationLine[];
}
