import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RegionContract } from '../contracts/region.contract';

export const regionsActions = createActionGroup({
  source: '[Region]',
  events: {
    initRegions: emptyProps(),
    reloadRegions: emptyProps(),
    loadFromCacheRegionsSuccess: props<{
      regions: RegionContract[];
      totalItems: number;
      cacheTimestamp: string; // iso 8601 date
    }>(),
    loadRegionsSuccess: props<{
      regions: RegionContract[];
      totalItems: number;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadRegionsFailure: props<{ error: any }>(),
  },
});
