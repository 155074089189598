import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OAuthService } from '@techniek-team/oauth';

@Component({
  selector: 'app-login-page',
  templateUrl: 'login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [IonicModule, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPage {
  private readonly oauthService = inject(OAuthService);

  public logoUrl: string = 'assets/icon/logo.svg';

  public authenticating = signal(false);

  constructor() {
    this.validToken().catch();
  }

  private async validToken(): Promise<void> {
    this.authenticating.set(true);

    const loggedIn: boolean = await this.oauthService.hasValidAccessToken();

    if (loggedIn) {
      await this.oauthService.redirectAfterLogin();
    }

    this.authenticating.set(false);
  }

  public async login(): Promise<void> {
    this.authenticating.set(true);
    await this.oauthService.initiateAuthCodePKCE();
    this.authenticating.set(false);
  }
}
