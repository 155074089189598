import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { BusinessEntityContract } from '../contracts/business-entity.contract';
import { businessEntitiesActions } from './business-entities.actions';

export const BUSINESS_ENTITIES_FEATURE_KEY = 'businessEntities';

export interface BusinessEntitiesState extends EntityState<BusinessEntityContract> {
  totalItems?: number;

  loaded: boolean;

  loading: boolean;

  loadingDetailed: boolean;

  error?: unknown | null;

  cacheTimeStamp?: string;
}

export const businessEntitiesAdapter: EntityAdapter<BusinessEntityContract> =
  createEntityAdapter<BusinessEntityContract>({
    selectId: jsonLdSelectId,
  });

export const initialBusinessEntitiesState: BusinessEntitiesState =
  businessEntitiesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
    loadingDetailed: false,
  });

const reducer = createReducer(
  initialBusinessEntitiesState,
  on(businessEntitiesActions.initBusinessEntities, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(businessEntitiesActions.reloadBusinessEntities, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    businessEntitiesActions.loadBusinessEntitiesSuccess,
    (state, { businessEntities, totalItems }) =>
      businessEntitiesAdapter.setAll(businessEntities, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: formatISO(new Date()),
      }),
  ),
  on(
    businessEntitiesActions.loadFromCacheBusinessEntitiesSuccess,
    (state, { businessEntities, totalItems, cacheTimestamp }) =>
      businessEntitiesAdapter.setAll(businessEntities, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: cacheTimestamp,
      }),
  ),
  on(businessEntitiesActions.loadBusinessEntitiesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function businessEntitiesReducer(state: BusinessEntitiesState | undefined, action: Action) {
  return reducer(state, action);
}
