import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCabinetFiling, faMoneyCheckDollar } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckToSlot } from '@fortawesome/pro-regular-svg-icons';
import { IonicModule, ModalController } from '@ionic/angular';
import {
  AssignmentStateDisplayValues,
  AssignmentStateEnum,
} from '@scheduler-frontend/assignment-contracts';
import {
  AssignmentsStoreService,
  TransitionEnum,
  TransitionToMessage,
} from '@scheduler-frontend/data-access-assignment';
import { CapitalizeModule } from '@techniek-team/common';
import { TtModalModule, TtSimpleModalComponent } from '@techniek-team/components/modal';
import { format } from 'date-fns';
import { take } from 'rxjs/operators';
import { EditAssignmentDescriptionComponent } from './edit-description/edit-assignment-description.component';
import { EditStatusComponent } from './edit-status/edit-status.component';
import { GeneralComponent } from './general/general.component';
import { HistoryComponent } from './history/history.component';
import { PayoutComponent } from './payout/payout.component';

export enum AssignmentModalSegments {
  OVERVIEW = 'OVERVIEW',
  PAYOUT = 'PAYOUT',
  HISTORY = 'HISTORY',
}

@Component({
  selector: 'app-edit-assignment-modal',
  templateUrl: './assignment-modal.component.html',
  styleUrls: ['./assignment-modal.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    TtModalModule,
    FontAwesomeModule,
    AsyncPipe,
    ReactiveFormsModule,
    CapitalizeModule,
    EditAssignmentDescriptionComponent,
    GeneralComponent,
    PayoutComponent,
    HistoryComponent,
    DatePipe,
    EditStatusComponent,
  ],
})
export class AssignmentModalComponent implements OnInit {
  private readonly modalController = inject(ModalController);

  @Input()
  public set segment(segment: AssignmentModalSegments) {
    if (!segment) {
      return;
    }

    this.currentSegment = segment;
  }

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly faCheckToSlot = faCheckToSlot;

  protected readonly faMoneyCheckDollar = faMoneyCheckDollar;

  protected readonly faCabinetFiling = faCabinetFiling;

  //eslint-disable-next-line max-len
  protected assignmentStatusControl: FormControl<TransitionEnum | null> = new FormControl({
    value: null,
    disabled: true,
  });

  protected readonly TransitionToMessage: typeof TransitionToMessage = TransitionToMessage;

  protected readonly AssignmentModalSegments: typeof AssignmentModalSegments =
    AssignmentModalSegments;

  protected readonly AssignmentStateDisplayValues: typeof AssignmentStateDisplayValues =
    AssignmentStateDisplayValues;

  protected readonly AssignmentStateEnum: typeof AssignmentStateEnum = AssignmentStateEnum;

  protected currentSegment: string = AssignmentModalSegments.OVERVIEW;

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.assignmentsStoreService.activeAssignmentTransitions$.subscribe((transition) => {
      if (transition.length === 0) {
        this.assignmentStatusControl.disable({ emitEvent: false });
        return;
      }
      this.assignmentStatusControl.enable({ emitEvent: false });
    });

    this.assignmentsStoreService.activeAssignment$.subscribe((assignment) => {
      this.assignmentStatusControl.patchValue(assignment.state as unknown as TransitionEnum, {
        emitEvent: false,
      });
    });

    this.assignmentStatusControl.valueChanges.subscribe((value) => {
      if (value) {
        this.assignmentsStoreService.applyTransition(value);
      }
    });
    this.checkCancelledWarning();
  }

  public segmentChanged(tab: AssignmentModalSegments): void {
    this.segment = tab;
  }

  public close(): Promise<boolean> {
    this.assignmentsStoreService.clearActiveAssignment();
    return this.modalController.dismiss(false, 'close');
  }

  private checkCancelledWarning(): void {
    this.assignmentsStoreService.activeAssignment$.pipe(take(1)).subscribe(async (assignment) => {
      if (
        assignment.state === AssignmentStateEnum.CANCELLED &&
        assignment.assignmentHasSlots.length
      ) {
        const date: string = format(assignment.assignmentHasSlots[0].startDate, 'dd-MM-yyyy');
        const confirmModal: HTMLIonModalElement = await this.modalController.create({
          component: TtSimpleModalComponent,
          cssClass: ['stack-modal'],
          componentProps: {
            title: 'Let op',
            message: `Deze opdracht bevat een geannuleerde shift die niet uitgeroosterd kan worden.
            Probeer ${assignment.candidate?.fullName} nog in te plannen op een andere shift op ${date}!`,
            buttons: [
              {
                text: 'OK',
              },
            ],
          },
        });
        confirmModal.present();
      }
    });
  }
}
