import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CreateSlotsRequirementsStoreService } from '../../create-slots-requirements-store.service';
import { SlotsToCreateStoreService } from '../../slots-to-create-store.service';
import { SlotToCreateComponent } from './slot-to-create/slot-to-create.component';

@Component({
  selector: 'app-fine-tune',
  standalone: true,
  imports: [SlotToCreateComponent],
  templateUrl: './fine-tune.component.html',
  styleUrl: './fine-tune.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FineTuneComponent {
  protected readonly slotsToCreateStoreService = inject(SlotsToCreateStoreService);

  protected readonly requirementsStoreService = inject(CreateSlotsRequirementsStoreService);
}
