import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TsRange } from '@techniek-team/class-transformer';
import { areIntervalsOverlapping } from 'date-fns';
import {
  CANDIDATE_AVAILABILITIES_FEATURE_KEY,
  candidateAvailabilitiesAdapter,
  CandidateAvailabilitiesState,
} from '../reducers/candidate-availabilities.reducer';

export class CandidateAvailabilitiesSelectors {
  public static readonly candidateAvailabilitiesState =
    createFeatureSelector<CandidateAvailabilitiesState>(CANDIDATE_AVAILABILITIES_FEATURE_KEY);

  public static readonly selectLoading = createSelector(
    CandidateAvailabilitiesSelectors.candidateAvailabilitiesState,
    (state) => state.loading,
  );

  public static readonly selectLoaded = createSelector(
    CandidateAvailabilitiesSelectors.candidateAvailabilitiesState,
    (state) => state.loaded,
  );

  //eslint-disable-next-line max-len
  public static readonly selectLoadedMonths = createSelector(
    CandidateAvailabilitiesSelectors.candidateAvailabilitiesState,
    (state) => state.loadedMonths,
  );

  public static readonly selectError = createSelector(
    CandidateAvailabilitiesSelectors.candidateAvailabilitiesState,
    (state) => state.error,
  );

  //eslint-disable-next-line max-len
  public static readonly selectAll = createSelector(
    CandidateAvailabilitiesSelectors.candidateAvailabilitiesState,
    (state) => {
      return candidateAvailabilitiesAdapter.getSelectors().selectAll(state);
    },
  );

  public static selectWithinRange(range: TsRange) {
    return createSelector(
      CandidateAvailabilitiesSelectors.candidateAvailabilitiesState,
      (state) => {
        return Object.values(state.entities).filter((value) => {
          if (!value) {
            return false;
          }
          return areIntervalsOverlapping(
            {
              start: value.range.start,
              end: value.range.end,
            },
            range,
          );
        });
      },
    );
  }
}
