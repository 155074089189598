import { CandidateMinimal } from '@scheduler-frontend/data-access-users';
import { Exclude, Expose } from 'class-transformer';
import { Gender } from '../enums/gender.enum';

export class Candidate extends CandidateMinimal {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Candidate';

  @Expose() public gender!: Gender;

  @Expose() public phoneNumber!: string;

  @Expose() public emailAddress!: string;

  @Expose() public grade!: number;

  @Expose() public isMain!: number;

  @Exclude()
  public isCandidateSearchResultItem: boolean = false;
}
