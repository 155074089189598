import { EventAction } from '@techniek-team/calendar';
import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { CalendarEventInterface } from '../contracts/calendar-event.interface';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CalendarEvent<MetaType = any> extends JsonLd implements CalendarEventInterface {
  @Type(() => TsRange)
  @Expose()
  public 'range'!: TsRange;

  @Expose() public 'title'!: string;

  /**
   * Either a hex color code or a css variable;
   */
  @Expose() public 'color'!: { color: string; contrast: string };

  @Expose() public 'actions'?: EventAction[] = [];

  @Expose() public 'allDay'?: boolean;

  @Expose() public 'cssClass'?: string;

  @Expose() public 'resizable'?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };

  @Expose() public 'draggable'?: boolean;

  @Expose() public 'meta'?: MetaType;

  //eslint-disable-next-line @typescript-eslint/naming-convention
  public get '@id'(): string {
    return this.getIri() as string;
  }
}
