import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { RoleDetailedContract, RolesSelectors } from '@scheduler-frontend/data-access-roles';
import { SubjectsSelectors } from '@scheduler-frontend/data-access-subjects';
import { sortString } from '@techniek-team/common';
import { CREATE_SLOTS_FEATURE_KEY, CreateSlotsState } from '../reducers/create-slots.reducer';
import { DeliveryTypeSelectors } from './delivery-type.selector';
import { SelectedSelectors } from './selected.selectors';

export class CreateSlotsSelectors {
  public static readonly slotsState =
    createFeatureSelector<CreateSlotsState>(CREATE_SLOTS_FEATURE_KEY);

  public static readonly isFormValid = createSelector(
    CreateSlotsSelectors.slotsState,
    (state) => state.isFormValid,
  );

  public static readonly loading = createSelector(
    CreateSlotsSelectors.slotsState,
    (state) => state.loading,
  );

  public static readonly loadingBusinessService = createSelector(
    SelectedSelectors.slotsState,
    (state) => state.loadingBusinessService,
  );

  public static readonly submitting = createSelector(
    CreateSlotsSelectors.slotsState,
    (state) => state.submitting,
  );

  public static readonly validSubjects = createSelector(
    SelectedSelectors.productType,
    SubjectsSelectors.subjects,
    (productType, subjects) => {
      if (!productType) {
        return [];
      }
      return subjects.filter((subject) =>
        subject.productTypes.find((pt) => pt['@id'] === productType['@id']),
      );
    },
  );

  public static readonly validRoles = createSelector(
    SelectedSelectors.businessService,
    RolesSelectors.roles,
    (businessService, roles) => {
      if (!businessService) {
        return [];
      }
      const filtered = roles.filter((role) => {
        const roleBusinessService =
          typeof role.businessService === 'string'
            ? role.businessService
            : role.businessService['@id'];
        return roleBusinessService === businessService['@id'];
      });
      filtered.sort((a, b) => {
        const sortingPriority: number = (a.sortingPriority ?? 0) - (b.sortingPriority ?? 0);

        return sortingPriority !== 0 ? sortingPriority : sortString(a.name, b.name);
      });

      return filtered as RoleDetailedContract[];
    },
  );

  public static readonly operationalLocations = createSelector(
    LocationsSelectors.allLocations,
    LocationsSelectors.currentPupilsPrivateLocations,
    DeliveryTypeSelectors.isAtHome,
    (locations, pupilsLocations, isAtHome) => {
      if (isAtHome) {
        return pupilsLocations;
      }
      return locations;
    },
  );
}
