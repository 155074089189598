import { AsyncPipe, DatePipe, KeyValuePipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { LetDirective } from '@ngrx/component';
import { concatLatestFrom } from '@ngrx/effects';
import {
  AssignmentDetailedWithAssignmentHasSlot,
  AssignmentHasSlotDetailedWithSlot,
} from '@scheduler-frontend/assignment-contracts';
import {
  ArticleCodeEnumDisplayValues,
  AssignmentsStoreService,
  PayoutArticleCodeEnum,
} from '@scheduler-frontend/data-access-assignment';
import { TtModalModule } from '@techniek-team/components/modal';
import { TtNumberInputModule } from '@techniek-team/components/number-input';
import { isDefined } from '@techniek-team/rxjs';
import { formatISO } from 'date-fns';
import { firstValueFrom, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-compensation-line-modal',
  templateUrl: './add-compensation-line-modal.component.html',
  styleUrls: ['./add-compensation-line-modal.component.scss'],
  standalone: true,
  imports: [
    TtModalModule,
    IonicModule,
    ReactiveFormsModule,
    KeyValuePipe,
    TtNumberInputModule,
    AsyncPipe,
    LetDirective,
    DatePipe,
  ],
})
export class AddCompensationLineModalComponent implements OnInit {
  private readonly modalController = inject(ModalController);

  private readonly amountMaxValidator: ValidatorFn = Validators.max(100);

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly compensationLineForm = new FormGroup({
    article: new FormControl<PayoutArticleCodeEnum | null>(null, [Validators.required]),
    description: new FormControl<string | null>(null, [Validators.required]),
    assignmentHasSlot: new FormControl<AssignmentHasSlotDetailedWithSlot | null>(null, [
      Validators.required,
    ]),
    quantity: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern('^\\s*(?=.*[1-9])\\d+(\\.\\d{1,2})?\\s*$'),
    ]),
    amount: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern('^\\s*(?=.*[1-9])\\d+(\\.\\d{2})?\\s*$'),
    ]),
  });

  /**
   * An observable containing all the assignment has slots that can be
   * selected when adding a new compensation line. These depend on the
   * selected article code.
   */
  protected selectableAssignmentHasSlots$: Observable<AssignmentHasSlotDetailedWithSlot[]> =
    this.createSelectableAssignmentHasSlotsObserver();

  protected readonly ArticleCodeEnumDisplayValues = ArticleCodeEnumDisplayValues;

  private currentAssignment!: AssignmentDetailedWithAssignmentHasSlot;

  public ngOnInit(): void {
    this.compensationLineForm
      .get('article')
      ?.valueChanges.pipe(takeUntilDestroyed(), startWith(null))
      .subscribe((articleCode: PayoutArticleCodeEnum | null) => {
        this.handleAssignmentHasSlotControl(articleCode);
        this.handleSlotPremiumArticle(articleCode);
      });
  }

  public dismiss(): Promise<boolean> {
    return this.modalController.dismiss(null, 'cancel');
  }

  public async addLine(): Promise<boolean> {
    if (!this.compensationLineForm.valid) {
      return Promise.resolve(false);
    }
    const activeAssignment = await firstValueFrom(this.assignmentsStoreService.activeAssignment$);
    const formData = this.compensationLineForm.getRawValue();
    const date: Date | null = (formData.assignmentHasSlot as AssignmentHasSlotDetailedWithSlot).slot
      .timePeriod.start;
    this.assignmentsStoreService.addCompensationLine({
      articleCode: formData.article as PayoutArticleCodeEnum,
      description: formData.description as string,
      amount: (formData.amount as string).toString(),
      quantity: (formData.quantity as string).toString(),
      assignmentHasSlot: (formData.assignmentHasSlot as AssignmentHasSlotDetailedWithSlot).getId(),
      subtotal: parseFloat(formData.quantity as string) * parseFloat(formData.amount as string),
      date: formatISO(date ?? activeAssignment.assignmentHasSlots[0].slot.timePeriod.start),
    });

    return this.modalController.dismiss(null, 'confirm');
  }

  private createSelectableAssignmentHasSlotsObserver(): Observable<
    AssignmentHasSlotDetailedWithSlot[]
  > {
    return (this.compensationLineForm.get('article') as AbstractControl).valueChanges.pipe(
      concatLatestFrom(() =>
        this.assignmentsStoreService.activeAssignmentCompensation$.pipe(isDefined()),
      ),
      map(([selectedArticle, assignmentCompensation]) => {
        // Find the assignment has slot ids that are linked to assignment
        // compensations which are valid options based on the selected article
        // code.
        const validAssignmentHasSlotIds: string[] =
          assignmentCompensation.assignmentCompensationLines
            .filter((compensationLine) => {
              if (selectedArticle === PayoutArticleCodeEnum.REIMBURSEMENT) {
                return compensationLine.compensation?.allowExtraExpenses;
              }

              if (selectedArticle === PayoutArticleCodeEnum.REMUNERATION) {
                return compensationLine.compensation?.allowExtraHours;
              }

              return selectedArticle === PayoutArticleCodeEnum.SLOT_PREMIUM;
            })
            .map((compensationLine) => compensationLine.assignmentHasSlot);

        return this.currentAssignment.assignmentHasSlots.filter((assignmentHasSlot) => {
          return validAssignmentHasSlotIds.includes(assignmentHasSlot.getIri() as string);
        });
      }),
    );
  }

  /**
   * Set and remove validators for the slot premium
   */
  private handleSlotPremiumArticle(articleCode: PayoutArticleCodeEnum | null): void {
    const quantityControl: FormControl<string> = this.compensationLineForm.get(
      'quantity',
    ) as FormControl<string>;
    const amountControl: FormControl<string> = this.compensationLineForm.get(
      'amount',
    ) as FormControl<string>;

    if (articleCode === PayoutArticleCodeEnum.SLOT_PREMIUM) {
      quantityControl.setValue('1');
      quantityControl.disable();

      amountControl.addValidators(this.amountMaxValidator);
    } else {
      quantityControl.enable();

      amountControl.removeValidators(this.amountMaxValidator);
    }

    amountControl.updateValueAndValidity();
  }

  /**
   * Enable/disable the assignment has slot FormControl.
   */
  private handleAssignmentHasSlotControl(articleCode: PayoutArticleCodeEnum | null): void {
    const control: FormControl<AssignmentHasSlotDetailedWithSlot | null> =
      this.compensationLineForm.get(
        'assignmentHasSlot',
      ) as FormControl<AssignmentHasSlotDetailedWithSlot | null>;
    control.setValue(null);

    if (articleCode) {
      control.enable();
    } else {
      control.disable();
    }
  }
}
