import {
  areIntervalsOverlapping,
  compareAsc,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
} from 'date-fns';

export function formatDatesToCombinedString(dates: (Date | string)[], dateFormat: string): string {
  if (dates.length === 1) {
    return format(dates[0], dateFormat);
  }

  const sortedDates: (Date | string)[] = dates.sort((a, b) => compareAsc(a, b));
  const datesGroupedByMonth: (Date | string)[][] = getDatesGroupedByMonth(sortedDates);

  return datesGroupedByMonth
    .map((days: (Date | string)[]): string => {
      if (days.length === 1) {
        return format(days[0], dateFormat);
      }

      return days
        .map((day: Date | string, index: number, arr: (Date | string)[]): string => {
          if (arr.length - 1 === index) {
            return format(day, dateFormat);
          }

          return format(day, 'd');
        })
        .join('+');
    })
    .join(' + ');
}

function getDatesGroupedByMonth(sortedDates: (Date | string)[]): (Date | string)[][] {
  const datesGroupedByMonth: (Date | string)[][] = [];

  let currentRange: (Date | string)[] = [sortedDates[0]];
  for (let i: number = 1; i < sortedDates.length; i++) {
    const previousDate: Date | string = sortedDates[i - 1];
    const currentDate: Date | string = sortedDates[i];

    const sameMonth: boolean = isSameMonth(previousDate, currentDate);
    const intervalsOverlapping: boolean = areIntervalsOverlapping(
      { start: startOfMonth(previousDate), end: endOfMonth(previousDate) },
      { start: startOfMonth(currentDate), end: endOfMonth(currentDate) },
    );

    if (sameMonth || intervalsOverlapping) {
      currentRange.push(currentDate);
    } else {
      datesGroupedByMonth.push(currentRange);
      currentRange = [currentDate];
    }
  }
  datesGroupedByMonth.push(currentRange);

  return datesGroupedByMonth;
}
