import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { locationsActions } from '@scheduler-frontend/data-access-locations';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { WithoutFollowUpSlotContract } from '../contracts/without-follow-up-slot.contract';
import { awaitingSlotsActions } from './awaiting-slots.actions';

export const AWAITING_FOLLOW_UP_SLOTS_FEATURE_KEY = 'awaitingFollowUpSlots';

export interface AwaitingSlotsState extends EntityState<WithoutFollowUpSlotContract> {
  totalItems?: number;

  loaded: boolean;

  loading: boolean;

  error?: unknown | null;

  cacheTimeStamp?: string;
}

export const awaitingSlotsAdapter: EntityAdapter<WithoutFollowUpSlotContract> =
  createEntityAdapter<WithoutFollowUpSlotContract>({
    selectId: jsonLdSelectId,
  });

export const initialAwaitingSlotsState: AwaitingSlotsState = awaitingSlotsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
});

const reducer = createReducer(
  initialAwaitingSlotsState,
  on(awaitingSlotsActions.loadWithoutFollowUpSlot, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(locationsActions.selectLocation, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(awaitingSlotsActions.loadWithoutFollowUpSlotSuccess, (state, { items, totalItems }) =>
    awaitingSlotsAdapter.setAll(items, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: formatISO(new Date()),
    }),
  ),
  on(
    awaitingSlotsActions.loadFormCacheWithoutFollowUpSlotSuccess,
    (state, { items, totalItems, cacheTimestamp }) =>
      awaitingSlotsAdapter.setAll(items, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: cacheTimestamp,
      }),
  ),
  on(awaitingSlotsActions.loadWithoutFollowUpSlotFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
);

export function awaitingFollowupSlotsReducer(
  state: AwaitingSlotsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
