import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { PersonalRemarkContract } from '@scheduler-frontend/candidate-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { candidateRemarksActions } from '../actions/candidate-remarks.actions';
import { candidatesActions } from '../actions/candidates.actions';

export const CANDIDATE_REMARKS_FEATURE_KEY: string = 'candidateRemarks';

export interface CandidateRemarksState extends EntityState<PersonalRemarkContract> {
  error?: unknown | null;

  loading: boolean;

  loaded: boolean;

  saving: boolean;

  loadingRemarks: boolean;

  savingRemarks: boolean;
}

//eslint-disable-next-line max-len
export const candidateRemarksAdapter: EntityAdapter<PersonalRemarkContract> =
  createEntityAdapter<PersonalRemarkContract>({
    selectId: jsonLdSelectId,
    sortComparer: false,
  });

export const initialCandidateRemarksState: CandidateRemarksState =
  candidateRemarksAdapter.getInitialState({
    error: null,
    loading: false,
    saving: false,
    loaded: false,
    loadingRemarks: false,
    savingRemarks: false,
  });

const reducer: ActionReducer<CandidateRemarksState> = createReducer(
  initialCandidateRemarksState,
  on(candidateRemarksActions.loadActiveCandidateRemarksSuccess, (state, { remarks }) =>
    candidateRemarksAdapter.setAll(remarks, {
      ...state,
      loading: false,
      loaded: true,
    }),
  ),
  on(candidateRemarksActions.refreshActiveCandidateRemarks, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(candidateRemarksActions.loadActiveCandidateRemarksFailure, (state, { error }) => ({
    ...state,
    error: error,
    loadingRemarks: false,
  })),
  on(candidatesActions.clearActiveCandidate, (state) =>
    candidateRemarksAdapter.removeAll({
      ...state,
      error: null,
    }),
  ),
  on(candidateRemarksActions.addCandidateRemark, (state) => ({
    ...state,
    savingRemarks: true,
    error: null,
  })),
  on(candidateRemarksActions.addCandidateRemarkSuccess, (state, { remark }) =>
    candidateRemarksAdapter.addOne(remark, {
      ...state,
    }),
  ),
  on(candidateRemarksActions.addCandidateRemarkFailure, (state, { error }) => ({
    ...state,
    error: error,
    savingRemarks: false,
  })),
);

export function candidateRemarksReducer(
  state: CandidateRemarksState | undefined,
  action: Action,
): CandidateRemarksState {
  return reducer(state, action);
}
