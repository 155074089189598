import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssignmentDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { CandidatesSelectors } from '@scheduler-frontend/data-access-candidates';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import {
  ASSIGNMENTS_FEATURE_KEY,
  assignmentsAdapter,
  AssignmentsState,
} from '../reducer/assignments.reducer';

const { selectEntities } = assignmentsAdapter.getSelectors();

export class AssignmentsSelectors {
  public static readonly state = createFeatureSelector<AssignmentsState>(ASSIGNMENTS_FEATURE_KEY);

  public static readonly loading = createSelector(
    AssignmentsSelectors.state,
    (state: AssignmentsState) => state.loading,
  );

  public static readonly loaded = createSelector(
    AssignmentsSelectors.state,
    (state: AssignmentsState) => state.loaded,
  );

  public static readonly processingUnassign = createSelector(
    AssignmentsSelectors.state,
    (state: AssignmentsState) => state.processingUnassign,
  );

  public static readonly error = createSelector(
    AssignmentsSelectors.state,
    (state: AssignmentsState) => state.error,
  );

  public static readonly assignmentEntities = createSelector(
    AssignmentsSelectors.state,
    (state: AssignmentsState) => selectEntities(state),
  );

  public static readonly activeAssignmentId = createSelector(
    AssignmentsSelectors.state,
    (state: AssignmentsState) => state.selectedId,
  );

  public static readonly activeAssignment = createSelector(
    AssignmentsSelectors.assignmentEntities,
    AssignmentsSelectors.activeAssignmentId,
    (entities, selectedId) =>
      selectedId ? (entities[selectedId] as AssignmentDetailedContract) : undefined,
  );

  public static readonly activeAssignmentMustPerformCompensation = createSelector(
    AssignmentsSelectors.activeAssignment,
    (activeAssignment) => activeAssignment?.performCompensation,
  );

  public static readonly activeAssignmentAutomaticTravelCompensation = createSelector(
    AssignmentsSelectors.activeAssignment,
    (activeAssignment) => activeAssignment?.automaticTravelCompensation,
  );

  public static readonly selectAssignmentOrActive = (
    assignmentId?: string | { assignment: string } | object,
  ) => {
    if (typeof assignmentId === 'object' && 'assignment' in assignmentId) {
      assignmentId = assignmentId.assignment;
    }
    if (typeof assignmentId === 'string') {
      return AssignmentsSelectors.selectAssignment(assignmentId);
    }
    return AssignmentsSelectors.activeAssignment;
  };

  public static readonly selectAssignment = (assignmentId: string) =>
    createSelector(AssignmentsSelectors.assignmentEntities, (entities) => entities[assignmentId]);

  public static readonly activeAssignmentHasSlots = createSelector(
    AssignmentsSelectors.activeAssignment,
    (assignment) =>
      assignment ? (assignment as AssignmentDetailedContract)?.assignmentHasSlots : undefined,
  );

  public static readonly activeAssignmentCandidate = createSelector(
    AssignmentsSelectors.activeAssignment,
    CandidatesSelectors.candidateEntities,
    (assignment, candidates) => {
      if (!assignment?.candidate) {
        return undefined;
      }
      return candidates[jsonLdSelectId(assignment?.candidate['@id'])];
    },
  );

  public static readonly selectAssignmentCandidateOrActive = (
    assignmentId?: string | { assignment: string } | object,
  ) => {
    if (typeof assignmentId === 'object' && 'assignment' in assignmentId) {
      assignmentId = assignmentId.assignment;
    }
    if (typeof assignmentId === 'string') {
      return createSelector(
        AssignmentsSelectors.selectAssignmentOrActive(assignmentId),
        (assignment) => {
          return assignment?.candidate;
        },
      );
    }
    return AssignmentsSelectors.activeAssignmentCandidate;
  };
}
