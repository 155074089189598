import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AssignmentStateEnum, isState } from '@scheduler-frontend/assignment-contracts';
import { AssignmentsStoreService } from '@scheduler-frontend/data-access-assignment';
import { isDefined } from '@techniek-team/rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-edit-assignment-description',
  templateUrl: './edit-assignment-description.component.html',
  styleUrls: ['./edit-assignment-description.component.scss'],
  standalone: true,
  imports: [AsyncPipe, IonicModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAssignmentDescriptionComponent {
  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly descriptionControl: FormControl<string | null> = new FormControl<
    string | null
  >(null);

  /**
   * Set description field's value to the assignments description
   *
   * If the assignment has been finalized we can't update the description
   * anymore
   */
  private setDescriptionFieldValueSubscription = this.assignmentsStoreService.activeAssignment$
    .pipe(takeUntilDestroyed(), isDefined())
    .subscribe((response) => {
      if (response && isState(response.state, AssignmentStateEnum.APPROVED)) {
        this.descriptionControl.disable({ emitEvent: false });
      }
      this.descriptionControl.setValue(response.description ?? null, {
        emitEvent: false,
      });
    });

  /**
   * Auto save the description after 1 second
   */
  private saveOnDescriptionChangeSubscription = this.descriptionControl.valueChanges
    .pipe(
      distinctUntilChanged(),
      takeUntilDestroyed(),
      filter(() => this.descriptionControl.valid),
      debounceTime(1000),
    )
    .subscribe((description) => {
      this.assignmentsStoreService.setActiveAssignmentDescription(description);
    });
}
