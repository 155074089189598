import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '@scheduler-frontend/environments';
import { ScheduleContract, ScheduleDetailedContract } from '@scheduler-frontend/schedule-contracts';
import { Collection } from '@techniek-team/api-platform';
import {
  MercureClient,
  MercureCreatedEvent,
  MercureDeletedEvent,
  MercureUpdatedEvent,
} from '@techniek-team/mercure-client';
import { filter, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScheduleApi {
  protected readonly httpClient = inject(HttpClient);

  private readonly mercureClient = inject(MercureClient);

  private readonly scheduleMercureListener = this.mercureClient
    .request<ScheduleContract | ScheduleDetailedContract | string>(
      environment.mercure.hub,
      ['schedule-created', 'schedule-updated', 'schedule-deleted'],
      {
        tokenEndpoint: true,
        debug: false,
        observe: 'response',
      },
    )
    .pipe(takeUntilDestroyed(), shareReplay(1));

  public execute(location: string, searchInput?: string): Observable<Collection<ScheduleContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/schedules`;

    let params: HttpParams = new HttpParams();

    if (searchInput) {
      params = params.append('search', searchInput);
    }
    if (location) {
      params = params.append('location', location);
    }

    return this.httpClient.get<Collection<ScheduleContract>>(url, { params: params });
  }

  public scheduleCreatedEvents(): Observable<ScheduleContract> {
    return this.scheduleMercureListener.pipe(
      filter((event) => event instanceof MercureCreatedEvent),
      map((event) => event.data as ScheduleContract),
    );
  }

  public scheduleUpdatedEvents(): Observable<ScheduleDetailedContract> {
    return this.scheduleMercureListener.pipe(
      filter((event) => event instanceof MercureUpdatedEvent),
      map((event) => event.data as ScheduleDetailedContract),
    );
  }

  public scheduleDeletedEvents(): Observable<string> {
    return this.scheduleMercureListener.pipe(
      filter((event) => event instanceof MercureDeletedEvent),
      map((event) => event.data as string),
    );
  }
}
