import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideBusinessEntitiesStore } from '@scheduler-frontend/data-access-business-entities';
import { provideBusinessServicesStore } from '@scheduler-frontend/data-access-business-services';
import { provideLocationsStore } from '@scheduler-frontend/data-access-locations';
import { provideProductTypesStore } from '@scheduler-frontend/data-access-product-types';
import { provideRegionsStore } from '@scheduler-frontend/data-access-regions';
import { provideRoleStore } from '@scheduler-frontend/data-access-roles';
import { provideSchedulesStore } from '@scheduler-frontend/data-access-schedules';
import { provideSchedulingStore } from '@scheduler-frontend/data-access-scheduling';
import { provideSlotsStore } from '@scheduler-frontend/data-access-slots';
import { provideSubjectsStore } from '@scheduler-frontend/data-access-subjects';
import { LocationScheduleEffect } from './+state/effects/location-schedule.effect';
import { SchedulingSearchEffects } from './+state/effects/scheduling-search.effects';
import { SearchFavoritesEffects } from './+state/effects/search-favorites.effects';
import { SearchInitEffects } from './+state/effects/search-init-effects.service';
import { SearchRecentEffects } from './+state/effects/search-recent.effects';
import { SearchEffects } from './+state/effects/search.effects';
import { UserSearchEffects } from './+state/effects/user-search.effects';
import { SEARCH_FEATURE_KEY, searchReducer } from './+state/reducer/search.reducer';
import { USER_SEARCH_FEATURE_KEY, userSearchReducer } from './+state/reducer/user-search.reducer';
import { SearchStoreService } from './search-store.service';

export function provideSearchStore(): Provider | EnvironmentProviders[] {
  return [
    provideSchedulesStore(),
    provideBusinessEntitiesStore(),
    provideBusinessServicesStore(),
    provideSubjectsStore(),
    provideRoleStore(),
    provideRegionsStore(),
    provideProductTypesStore(),
    provideLocationsStore(),
    provideSlotsStore(),
    provideSchedulingStore(),
    provideState({ name: SEARCH_FEATURE_KEY, reducer: searchReducer }),
    provideState({ name: USER_SEARCH_FEATURE_KEY, reducer: userSearchReducer }),
    provideEffects(
      SchedulingSearchEffects,
      LocationScheduleEffect,
      UserSearchEffects,
      SearchEffects,
      SearchFavoritesEffects,
      SearchRecentEffects,
      SearchInitEffects,
    ),
    SearchStoreService,
  ];
}
