@if (labelPlacement() === 'start' && label()) {
  <ion-label class="label-start">
    {{ label() }}
  </ion-label>
}

<div class="button-container"
  [class.disabled]="disabled"
  slot="start">
  <ion-button data-test="tt-number-input-sub-button"
    size="small"
    fill="clear"
    (click)="sub($event)"
    (press)="longPressSub()"
    (pressup)="stopPressInterval()"
    [disabled]="numberControl.disabled"
    [color]="color()">
    <ion-icon icon="remove-outline"
    slot="icon-only"></ion-icon>
  </ion-button>
</div>

@if (contentWidth(); as contentWidth) {
  <ion-input
    data-test="tt-number-input-input"
    inputmode="decimal"
    [class.disabled]="disabled"
    [style.min-width]="contentWidth.min"
    [style.max-width]="!expand ? contentWidth.max : undefined"
    [label]="labelPlacement() === 'floating' || labelPlacement() === 'stacked' ? label() : undefined"
    [labelPlacement]="labelPlacement()"
    [attr.aria-label]="label() ?? ariaLabel()"
    [clearOnEdit]="clearOnEdit()"
    [color]="color()"
    [fill]="fill()"
    [maskito]="maskItoConfig"
    [maskitoElement]="maskPredicate"
    [step]="stepIntervalInternal()"
    [placeholder]="placeholder()"
    mode="md"
    [formControl]="maskControl"
    (ionFocus)="hasFocus = true"
    (ionBlur)="hasFocus = false"
    (blur)="checkAndRoundInput()"
    [min]="min()"
    [max]="max()"
  ></ion-input>
}

<div class="button-container"
  [class.disabled]="disabled"
  slot="end">
  <ion-button data-test="tt-number-input-add-button"
    size="small"
    fill="clear"
    (click)="add($event)"
    (press)="longPressAdd()"
    (pressup)="stopPressInterval()"
    [disabled]="numberControl.disabled"
    [color]="color()">
    <ion-icon icon="add-outline"
    slot="icon-only"></ion-icon>
  </ion-button>
</div>

@if (labelPlacement() === 'end' && label()) {
  <ion-label class="label-end">
    {{ label() }}
  </ion-label>
}

@if (numberControl.dirty) {
  <ion-note class="error-text">
    {{ errorText() }}
  </ion-note>
}

@if (numberControl.pristine) {
  <ion-note class="helper-text">
    {{ helperText() }}
  </ion-note>
}
