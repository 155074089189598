import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { LevelEnum } from '@scheduler-frontend/enums';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import {
  GetCandidatesByAverageRankingResponse,
  GetSlotsForCandidateResponse,
} from './candidates-by-ranking.response';

@Injectable({
  providedIn: 'root',
})
export class CandidatesByRankingApi {
  private readonly httpClient: HttpClient = inject(HttpClient);

  /**
   * Fetches the candidates ordered by ranking.
   */
  public getCandidatesByRanking(
    page: number,
    search?: string,
  ): Observable<Collection<GetCandidatesByAverageRankingResponse>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/candidates-by-average-ranking`;

    let params: HttpParams = new HttpParams().set('page', page.toString()).set('itemsPerPage', 50);

    if (search && search !== '') {
      params = params.set('query', search);
    }

    return this.httpClient.get<Collection<GetCandidatesByAverageRankingResponse>>(url, {
      params: params,
    });
  }

  public getSlotsForCandidate(
    candidateId: string,
    filters: {
      businessServices: string[];
      roles: string[];
      subjects: string[];
      educationTypes: LevelEnum[];
    },
  ): Observable<Collection<GetSlotsForCandidateResponse>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/candidates/${candidateId}/best-matched-slots`;

    let params: HttpParams = new HttpParams();
    params = this.appendParamsArray(params, filters.businessServices, 'businessServices');
    params = this.appendParamsArray(params, filters.roles, 'roles');
    params = this.appendParamsArray(params, filters.subjects, 'subjects');
    params = this.appendParamsArray(params, filters.educationTypes, 'educationTypes');

    return this.httpClient.get<Collection<GetSlotsForCandidateResponse>>(url, {
      params: params,
    });
  }

  public getAlternativeSlot(
    slotId: string,
    candidateId: string,
  ): Observable<Collection<SlotDetailedContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/slots/${slotId}/comparable/for-candidate/${candidateId}`;

    return this.httpClient.get<Collection<SlotDetailedContract>>(url);
  }

  private appendParamsArray(
    params: HttpParams,
    ids: undefined | string[],
    key: string,
  ): HttpParams {
    if (ids && ids.length) {
      for (const id of ids) {
        params = params.append(key + '[]', id);
      }
    }

    return params;
  }
}
