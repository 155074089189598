import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, signal } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BusinessEntitiesStoreService } from '@scheduler-frontend/data-access-business-entities';
import { BusinessServicesStoreService } from '@scheduler-frontend/data-access-business-services';
import { LocationsStoreService } from '@scheduler-frontend/data-access-locations';
import { RegionsStoreService } from '@scheduler-frontend/data-access-regions';
import { RolesStoreService } from '@scheduler-frontend/data-access-roles';
import { SubjectsStoreService } from '@scheduler-frontend/data-access-subjects';
import { UsersStoreService } from '@scheduler-frontend/data-access-users';
import { environment } from '@scheduler-frontend/environments';
import { ReleaseService } from '@scheduler-frontend/release';
import { TtAvatarModule } from '@techniek-team/components/avatar';
import { UserService } from '@techniek-team/oauth';
import { SentryEnvironment } from '@techniek-team/sentry-web';

@Component({
  selector: 'app-logged-in-user',
  templateUrl: './logged-in-user.component.html',
  styleUrls: ['./logged-in-user.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TtAvatarModule],
  host: {
    '[class.full-width]': 'displayDetails()',
  },
})
export class LoggedInUserComponent {
  protected readonly userService = inject(UserService);

  protected readonly storage = inject(Storage);

  protected readonly router = inject(Router);

  protected readonly userStoreService = inject(UsersStoreService);

  protected readonly releaseService = inject(ReleaseService);

  public readonly displayDetails = input<boolean>(false);

  protected readonly locationsStoreService = inject(LocationsStoreService);

  protected readonly regionsStoreService = inject(RegionsStoreService);

  protected readonly subjectsStoreService = inject(SubjectsStoreService);

  protected readonly rolesStoreService = inject(RolesStoreService);

  protected readonly businessEntitiesStoreService = inject(BusinessEntitiesStoreService);

  protected readonly businessServicesStoreService = inject(BusinessServicesStoreService);

  protected release = computed(() => {
    if (
      environment.environment === SentryEnvironment.LOCAL &&
      environment.release === '<<RELEASE>>'
    ) {
      return (
        Math.round(Math.random() * 10) + '.' + Math.round(Math.random() * 10) + '.0 (local-dev)'
      );
    }

    if (environment.environment !== SentryEnvironment.PRODUCTION) {
      return environment.release + ` (${environment.environment})`;
    }

    return environment.release;
  });

  protected popoverTrigger = signal('logged-in-user-popover-' + window.crypto.randomUUID());

  protected async clearSchedules() {
    const keys = await this.storage.keys();

    await Promise.all([
      keys.filter((key) => key.match(/schedules/i)).map((key) => this.storage.remove(key)),
    ]);
  }

  public reloadAll(): void {
    this.locationsStoreService.reloadLocations();
    this.businessEntitiesStoreService.reload();
    this.businessServicesStoreService.reload();
    this.subjectsStoreService.reload();
    this.rolesStoreService.reload();
    this.regionsStoreService.reload();
  }
}
