@if (!closeHidden) {
  <ion-button
    color="dark"
    class="close-button"
    data-test="tt-modal-close-button"
    fill="clear"
    size="small"
    (click)="cancel.emit()"
    [disabled]="closeDisabled">
    @if (isFontAwesome(iconUrl); as fontAwesomeIcon) {
      <fa-icon
        [fixedWidth]="true"
      [icon]="fontAwesomeIcon"></fa-icon>
    } @else {
      <ion-icon [icon]="iconUrl"></ion-icon>
    }
  </ion-button>
}
<div class="content"
  [class.no-bottom]="!(ref.innerHTML.trim())">
  <div #startRef
    [class.hide]="!(startRef.innerHTML.trim())"
    part="start">
    <ng-content select="[slot='start']"></ng-content>
  </div>
  <div #centerRef
    [class.hide]="!(centerRef.innerHTML.trim())"
    part="center">
    <ng-content></ng-content>
    <ng-content select="[slot='center']"></ng-content>
  </div>
  <div #endRef
    [class.hide]="!(endRef.innerHTML.trim())"
    part="end">
    <ng-content select="[slot='end']"></ng-content>
  </div>
</div>

<div #ref
  [class.no-border]="!(startRef.innerHTML.trim()) && !(endRef.innerHTML.trim())"
  [class.no-bottom]="!(ref.innerHTML.trim()) && forceFooter === false"
  class="footer">
  <ng-content select="[slot='action']"></ng-content>
</div>

