import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IonColor } from '@techniek-team/lyceo-style';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [IonicModule],
})
export class HeaderComponent {
  @Input() public flat: boolean = false;

  @Input() public translucent: boolean = false;

  @Input() public color: IonColor | undefined = undefined;
}
