import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AssignmentDetailedContract } from '@scheduler-frontend/assignment-contracts';
import {
  AvailabilityStatus,
  AvailabilityStatusDisplayValues,
  CalendarEventInterface,
} from '@scheduler-frontend/calendar-contracts';
import { Resource } from '@techniek-team/api-platform';
import { TsRange } from '@techniek-team/class-transformer';
import { addMonths, parseISO, subMonths } from 'date-fns';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { calendarEventsActions } from '../actions/calendar-events.actions';
import { candidateAssignmentsFutureActions } from '../actions/candidate-assignments-future.actions';
import { candidateAssignmentsHistoryActions } from '../actions/candidate-assignments-history.actions';
import { candidateAvailabilitiesActions } from '../actions/candidate-availabilities.actions';
import { GetAvailabilitiesResponse } from '../api/availability/availability.response';
import { CandidateAvailabilitiesSelectors } from '../selectors/candidate-availabilities.selectors';

@Injectable()
export class CalendarEventsEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public createInitAvailabilityEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidateAssignmentsHistoryActions.loadSuccess,
        candidateAssignmentsFutureActions.loadSuccess,
      ),
      map(() =>
        candidateAvailabilitiesActions.initAvailabilities({
          range: new TsRange(subMonths(new Date(), 2), addMonths(new Date(), 2)).toObject(),
        }),
      ),
      catchError((error) => of(calendarEventsActions.loadCalendarEventsFailure({ error: error }))),
    ),
  );

  public createRefreshAvailabilityEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarEventsActions.refreshCalendarEvents),
      map((action) =>
        candidateAvailabilitiesActions.refreshAvailabilities({
          range: action.range,
        }),
      ),
      catchError((error) => of(calendarEventsActions.loadCalendarEventsFailure({ error: error }))),
    ),
  );

  public refreshAssignmentsHistoryOnRefreshCalendar = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarEventsActions.refreshCalendarEvents),
      map(() => candidateAssignmentsHistoryActions.refresh()),
      catchError((error) => of(calendarEventsActions.loadCalendarEventsFailure({ error: error }))),
    ),
  );

  public refreshAssignmentsFutureOnRefreshCalendar = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarEventsActions.refreshCalendarEvents),
      map(() => candidateAssignmentsFutureActions.refresh()),
      catchError((error) => of(calendarEventsActions.loadCalendarEventsFailure({ error: error }))),
    ),
  );

  public assignmentsHistoryToCalendarEvents = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAssignmentsHistoryActions.loadSuccess),
      map((action) => this.convertAssignmentListIntoCalendarEvents(action.assignments)),
      map((events) =>
        calendarEventsActions.loadAssignmentsHistoryCalendarEventsSuccess({
          assignments: events,
        }),
      ),
      catchError((error) => of(calendarEventsActions.loadCalendarEventsFailure({ error: error }))),
    ),
  );

  public assignmentsFutureToCalendarEvents = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAssignmentsFutureActions.loadSuccess),
      map((action) => this.convertAssignmentListIntoCalendarEvents(action.assignments)),
      map((events) =>
        calendarEventsActions.loadAssignmentsFutureCalendarEventsSuccess({
          assignments: events,
        }),
      ),
      catchError((error) => of(calendarEventsActions.loadCalendarEventsFailure({ error: error }))),
    ),
  );

  public createAvailabilityToCalendarEventsEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidateAvailabilitiesActions.appendAvailabilitiesSuccess,
        candidateAvailabilitiesActions.loadAvailabilitiesSuccess,
        candidateAvailabilitiesActions.refreshAvailabilitiesSuccess,
      ),
      concatLatestFrom(() => this.store.select(CandidateAvailabilitiesSelectors.selectAll)),
      map(([_, availabilities]) =>
        calendarEventsActions.loadAvailabilityCalendarEventsSuccess({
          availabilities: this.convertAvailabilitiesIntoCalendarEvents(availabilities),
        }),
      ),
      catchError((error) => of(calendarEventsActions.loadCalendarEventsFailure({ error: error }))),
    ),
  );

  //eslint-disable-next-line max-lines-per-function
  private convertAvailabilitiesIntoCalendarEvents(
    availabilities: Resource<GetAvailabilitiesResponse>[],
  ): CalendarEventInterface[] {
    //eslint-disable-next-line max-lines-per-function
    return availabilities.map((availability) => {
      let color: CalendarEventInterface['color'] = {
        color: '--lyceo-element-text-color-mint',
        contrast: '--lyceo-text-color',
      };

      switch (availability.status as string) {
        case AvailabilityStatus.UNAVAILABLE:
          color = {
            color: '--lyceo-red-50',
            contrast: '--lyceo-red-50-contrast',
          };
          break;
        case AvailabilityStatus.LONG_TERM_UNAVAILABLE:
        case AvailabilityStatus.VACATION:
          color = {
            color: '--lyceo-yellow-60',
            contrast: '--lyceo-yellow-60-contrast',
          };
          break;
      }

      return {
        'range': {
          ...availability.range,
          start: parseISO(availability.range.start),
          end: parseISO(availability.range.end),
        },
        'title':
          availability.remarks ??
          AvailabilityStatusDisplayValues[
            availability.status as keyof typeof AvailabilityStatusDisplayValues
          ],
        'icon': availability.template ? 'repeat-outline' : undefined,
        'action': undefined,
        'color': color,
        '@id': availability['@id'],
        'meta': availability,
      } as CalendarEventInterface;
    });
  }

  private convertAssignmentListIntoCalendarEvents(
    assignments: AssignmentDetailedContract[],
  ): CalendarEventInterface[] {
    const events: CalendarEventInterface[] = [];
    for (let assignment of assignments) {
      events.push(...this.convertAssignmentIntoCalendarEvents(assignment));
    }
    return events;
  }

  private convertAssignmentIntoCalendarEvents(
    assignment: AssignmentDetailedContract,
  ): CalendarEventInterface[] {
    const events: CalendarEventInterface[] = [];
    for (let slot of assignment.assignmentHasSlots) {
      const event: CalendarEventInterface = {
        '@id': slot['@id'],
        'range': {
          start: parseISO(slot.slot.timePeriod.start),
          end: parseISO(slot.slot.timePeriod.end),
          startInclusive: true,
          endInclusive: false,
        },
        'title': assignment.name as string,
        'color': {
          color: '--lyceo-green-99',
          contrast: '--lyceo-green-99-contrast',
        },
        'meta': assignment,
      };
      events.push(event);
    }
    return events;
  }
}
