type JSONCompatible<T> = unknown extends T
  ? never
  : {
      [P in keyof T]: T[P] extends JSONValue
        ? T[P]
        : T[P] extends NotAssignableToJson
          ? never
          : JSONCompatible<T[P]>;
    };

type NotAssignableToJson = bigint | symbol | Function;

type JSONPrimitive = string | number | boolean | null | undefined;

type JSONValue =
  | JSONPrimitive
  | JSONValue[]
  | JSONValue[][]
  | object
  | { [key: string | number | symbol]: JSONValue };

export function hashParams<T>(...args: (JSONValue | JSONCompatible<T>)[]) {
  return JSON.stringify(args);
}
