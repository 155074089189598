import { computed, inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity/src/models';
import { select, Store } from '@ngrx/store';
import { ApproachMediumEnum } from '@scheduler-frontend/approach-contracts';
import {
  Assignment,
  AssignmentContract,
  Slot,
  SlotContract,
  SlotDetailedWithAssignmentHasSlot,
} from '@scheduler-frontend/assignment-contracts';
import { Candidate, CandidateContract } from '@scheduler-frontend/candidate-contracts';
import { ScheduleMinimalContract } from '@scheduler-frontend/schedule-contracts';
import { denormalize } from '@techniek-team/class-transformer';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { denormalizeDictionary, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { isEqual } from 'lodash-es';
import { derivedAsync } from 'ngxtension/derived-async';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { schedulingApproachCandidatesActions } from './+state/actions/scheduling-approach-candidates.actions';
import { schedulingAssignToSlotsActions } from './+state/actions/scheduling-assign-to-slots.actions';
import { schedulingRejectSlotsActions } from './+state/actions/scheduling-reject-slots.actions';
import { schedulingActions } from './+state/actions/scheduling.actions';
import { SchedulingApproachSelectors } from './+state/selectors/scheduling-approach.selectors';
import { SchedulingSelectors } from './+state/selectors/scheduling.selectors';

@Injectable()
export class SchedulingStoreService {
  private readonly store = inject(Store);

  private readonly actions = inject(Actions);

  public slots = this.store.selectSignal(SchedulingSelectors.slotsInView);

  public loadingSlots = this.store.selectSignal(SchedulingSelectors.loadingSlots);

  public loadedSlots = this.store.selectSignal(SchedulingSelectors.loadedSlots);

  public requestedMinutes = this.store.selectSignal(SchedulingSelectors.requestedMinutes);

  public savingAssignment$ = this.store.pipe(
    select(SchedulingSelectors.savingAssignment),
    shareReplay(1),
  );

  public slotsGroupedByDateAndAssignment$ = this.store.pipe(
    select(SchedulingSelectors.slotListGroupedByDateAndAssignment),
    isDefined(),
    map((items) => {
      return new Map(
        Object.entries(items as Dictionary<Dictionary<SlotContract[]>>).map(([dateKey, value]) => {
          return [
            dateKey,
            new Map(
              Object.entries(value as Dictionary<SlotContract[]>).map(([assignmentKey, slots]) => {
                return [
                  assignmentKey,
                  denormalize(SlotDetailedWithAssignmentHasSlot, slots as SlotContract[]),
                ];
              }),
            ),
          ];
        }),
      );
    }),
    shareReplay(1),
  );

  public slotsGroupedByDateAndAssignment = derivedAsync(
    () => this.slotsGroupedByDateAndAssignment$,
  );

  public candidates$ = this.store.pipe(
    select(SchedulingSelectors.candidateList),
    isDefined(),
    map((items) => denormalize(Candidate, items as CandidateContract[])),
    shareReplay(1),
  );

  public hasSelectedSlots$ = this.store.pipe(
    select(SchedulingSelectors.hasSelectedSlots),
    shareReplay(1),
  );

  public hasSelectedSlotMarkedForRejection$ = this.store.pipe(
    select(SchedulingSelectors.hasSelectedSlotsForRejection),
    shareReplay(1),
  );

  public selectedSlotIds$ = this.store.pipe(
    select(SchedulingSelectors.selectedSlotIds),
    shareReplay(1),
  );

  public selectedSlotIds = this.store.selectSignal(SchedulingSelectors.selectedSlotIds);

  public selectedSlotMarkedForRejectionIds$ = this.store.pipe(
    select(SchedulingSelectors.selectedSlotForRejectionIds),
    shareReplay(1),
  );

  public selectedCandidateIds = this.store.selectSignal(SchedulingSelectors.selectedCandidateIds);

  public readonly selectedSlots = this.store.selectSignal(SchedulingSelectors.selectedSlots, {
    equal: isEqual,
  });

  public alternativeSlots$ = this.store.pipe(
    select(SchedulingSelectors.alternativeSlotList),
    isDefined(),
    map((items) => denormalizeDictionary(SlotDetailedWithAssignmentHasSlot, items)),
    shareReplay(1),
  );

  public selectedCandidates$ = this.store.pipe(
    select(SchedulingSelectors.selectedCandidates),
    map((items) => denormalize(Candidate, items)),
    shareReplay(1),
  );

  public savingApproach = this.store.selectSignal(SchedulingApproachSelectors.savingApproach);

  public savingAssignment = this.store.selectSignal(SchedulingSelectors.savingAssignment);

  public isSlotSelected$(slot: Slot | SlotContract | string): Observable<boolean> {
    const slotId =
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot);

    return this.selectedSlotIds$.pipe(
      map((ids) => ids.includes(slotId)),
      shareReplay(1),
    );
  }

  public areSlotsSelected$(slots: (Slot | SlotContract | string)[]): Observable<boolean> {
    const slotIds = slots.map((slot) =>
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot),
    );

    return this.selectedSlotIds$.pipe(
      map((ids) => {
        for (let slotId of slotIds) {
          if (!ids.includes(slotId)) {
            return false;
          }
        }
        return true;
      }),
      shareReplay(1),
    );
  }

  public getAlternativeSlot$(slots: (Slot | SlotContract | string)[]): Observable<boolean> {
    const slotIds = slots.map((slot) =>
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot),
    );

    return this.selectedSlotIds$.pipe(
      map((ids) => {
        for (let slotId of slotIds) {
          if (!ids.includes(slotId)) {
            return false;
          }
        }
        return true;
      }),
      shareReplay(1),
    );
  }

  public isSlotMarkedForRejectionSelected$(
    slot: Slot | SlotContract | string,
  ): Observable<boolean> {
    const slotId =
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot);

    return this.selectedSlotMarkedForRejectionIds$.pipe(
      map((ids) => ids.includes(slotId)),
      shareReplay(1),
    );
  }

  public areSlotsMarkedForRejectionSelected$(
    slots: (SlotContract | Slot | string)[],
  ): Observable<boolean> {
    const slotIds = slots.map((slot) =>
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot),
    );
    return this.selectedSlotMarkedForRejectionIds$.pipe(
      map((ids) => {
        for (let slotId of slotIds) {
          if (!ids.includes(slotId)) {
            return false;
          }
        }
        return true;
      }),
      shareReplay(1),
    );
  }

  //>== select slot
  public selectSlotsInAssignment(assignment: Assignment | AssignmentContract | string): void {
    assignment =
      typeof assignment === 'string'
        ? assignment
        : assignment instanceof Assignment
          ? assignment.getId()
          : jsonLdSelectId(assignment);

    this.store.dispatch(schedulingActions.selectSlotsInAssignment({ assignment: assignment }));
  }

  public deselectSlotsInAssignment(assignment: Assignment | AssignmentContract | string): void {
    assignment =
      typeof assignment === 'string'
        ? assignment
        : assignment instanceof Assignment
          ? assignment.getId()
          : jsonLdSelectId(assignment);
    this.store.dispatch(schedulingActions.deselectSlotsInAssignment({ assignment: assignment }));
  }

  public selectSlots(...slots: (Slot | SlotContract | string)[]): void {
    const slotIds = slots.map((slot) =>
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot),
    );
    this.store.dispatch(schedulingActions.selectSlots({ items: slotIds }));
  }

  public deselectSlots(...slots: (Slot | SlotContract | string)[]): void {
    const slotIds = slots.map((slot) =>
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot),
    );
    this.store.dispatch(schedulingActions.deselectSlots({ items: slotIds }));
  }

  public selectAllSlots(): void {
    this.store.dispatch(schedulingActions.selectAllSlots());
  }

  public clearSelectedSlots(): void {
    this.store.dispatch(schedulingActions.clearSelectedSlots());
  }
  //<== select slot

  //>== reject slot
  public selectSlotsInAssignmentForRejection(
    assignment: Assignment | AssignmentContract | string,
  ): void {
    assignment =
      typeof assignment === 'string'
        ? assignment
        : assignment instanceof Assignment
          ? assignment.getId()
          : jsonLdSelectId(assignment);
    this.store.dispatch(
      schedulingActions.selectSlotsInAssignmentForRejection({ assignment: assignment }),
    );
  }

  public deselectSlotsInAssignmentForRejection(
    assignment: Assignment | AssignmentContract | string,
  ): void {
    assignment =
      typeof assignment === 'string'
        ? assignment
        : assignment instanceof Assignment
          ? assignment.getId()
          : jsonLdSelectId(assignment);
    this.store.dispatch(
      schedulingActions.deselectSlotsInAssignmentForRejection({ assignment: assignment }),
    );
  }

  public selectSlotsForRejection(...slots: (Slot | SlotContract | string)[]): void {
    const slotIds = slots.map((slot) =>
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot),
    );
    this.store.dispatch(schedulingActions.selectSlotsForRejection({ items: slotIds }));
  }

  public deselectSlotsForRejection(...slots: (Slot | SlotContract | string)[]): void {
    const slotIds = slots.map((slot) =>
      typeof slot === 'string' ? slot : slot instanceof Slot ? slot.getId() : jsonLdSelectId(slot),
    );
    this.store.dispatch(schedulingActions.deselectSlotsForRejection({ items: slotIds }));
  }

  public clearSelectedSlotsForRejection(): void {
    this.store.dispatch(schedulingActions.clearSelectedSlotsForRejection());
  }
  //<== reject slot

  public selectCandidate(candidate: Candidate | string): void {
    if (candidate instanceof Candidate) {
      candidate = candidate.getId();
    }

    this.store.dispatch(schedulingActions.selectCandidates({ items: [candidate] }));
  }

  public deselectCandidate(candidate: Candidate | string): void {
    if (candidate instanceof Candidate) {
      candidate = candidate.getId();
    }

    this.store.dispatch(schedulingActions.deselectCandidates({ items: [candidate] }));
  }

  public isCandidateSelected(candidate: string) {
    return computed(() => {
      const selectedCandidates = this.selectedCandidateIds() ?? [];
      return selectedCandidates.includes(candidate);
    });
  }

  public clearSelectedCandidate(): void {
    this.store.dispatch(schedulingActions.clearSelectedCandidates());
  }

  public clearAllSelections(): void {
    this.store.dispatch(schedulingActions.clearAllSelections());
  }

  public clearCandidateList(): void {
    this.store.dispatch(schedulingActions.setCandidateList({ items: [] }));
  }

  public clearSlotList(): void {
    this.store.dispatch(schedulingActions.setSlotList({ items: [] }));
  }

  public reset(): void {
    this.store.dispatch(schedulingActions.setSlotList({ items: [] }));
    this.store.dispatch(schedulingActions.setCandidateList({ items: [] }));
    this.store.dispatch(schedulingActions.clearAllSelections());
  }

  public async assignCandidate(
    directlyConfirm: boolean = false,
    resolveConflicts: boolean = false,
  ): Promise<boolean> {
    if (!(await firstValueFrom(this.hasSelectedSlots$))) {
      return Promise.resolve(true);
    }

    this.store.dispatch(
      schedulingAssignToSlotsActions.assignCandidate({
        directlyConfirm: directlyConfirm,
        resolveConflicts: resolveConflicts,
      }),
    );
    const action = await Promise.race([
      firstEmitFrom(
        this.actions.pipe(ofType(schedulingAssignToSlotsActions.assignCandidateSuccess)),
      ),
      firstEmitFrom(
        this.actions.pipe(ofType(schedulingAssignToSlotsActions.assignCandidateFailure)),
      ),
    ]);

    if (action && typeof action === 'object' && 'error' in action) {
      return Promise.reject(action.error);
    }

    return Promise.resolve(true);
  }

  public async rejectSlotsForCandidate(): Promise<void> {
    if (!(await firstValueFrom(this.hasSelectedSlotMarkedForRejection$))) {
      return Promise.resolve();
    }

    this.store.dispatch(schedulingRejectSlotsActions.rejectSlotsForCandidate());

    const action = await Promise.race([
      firstEmitFrom(
        this.actions.pipe(ofType(schedulingRejectSlotsActions.rejectSlotsForCandidateSuccess)),
      ),
      firstEmitFrom(
        this.actions.pipe(ofType(schedulingRejectSlotsActions.rejectSlotsForCandidateFailure)),
      ),
    ]);

    if (action && typeof action === 'object' && 'error' in action) {
      return Promise.reject(action.error);
    }

    return Promise.resolve();
  }

  public openScheduleDetails(schedule: ScheduleMinimalContract | string): void {
    this.store.dispatch(
      schedulingActions.openScheduleDetails({ schedule: jsonLdSelectId(schedule) }),
    );
  }

  public setActiveApproach(approach: string): void {
    this.store.dispatch(
      schedulingApproachCandidatesActions.setActiveApproach({ approach: approach }),
    );
  }

  public initiatedApproachingCandidate(
    candidate?: string,
    filters?: ReturnType<typeof schedulingApproachCandidatesActions.approach>['filters'],
  ) {
    this.store.dispatch(
      schedulingApproachCandidatesActions.approach({
        candidate: candidate,
        communicateImmediately: false,
        filters: filters,
      }),
    );
  }

  public async approachCandidate(
    approachThrough?: ApproachMediumEnum[],
    hasAnsweredThePhone?: boolean,
    filters?: ReturnType<typeof schedulingApproachCandidatesActions.approach>['filters'],
  ): Promise<boolean> {
    this.store.dispatch(
      schedulingApproachCandidatesActions.approach({
        communicateImmediately: true,
        filters: filters,
        approachThrough: approachThrough,
        hasAnsweredThePhone: hasAnsweredThePhone,
      }),
    );

    const action = await Promise.race([
      firstEmitFrom(
        this.actions.pipe(ofType(schedulingApproachCandidatesActions.createApproachSuccess)),
      ),
      firstEmitFrom(
        this.actions.pipe(ofType(schedulingApproachCandidatesActions.createApproachFailure)),
      ),
    ]);

    if (action && typeof action === 'object' && 'error' in action) {
      return Promise.reject(action.error);
    }

    return Promise.resolve(true);
  }

  public remindCandidate(
    approachThrough: ApproachMediumEnum[],
    hasAnsweredThePhone: boolean,
  ): void {
    this.store.dispatch(
      schedulingApproachCandidatesActions.remindCandidate({
        approachThrough: approachThrough,
        hasAnsweredThePhone: hasAnsweredThePhone,
      }),
    );
  }

  public communicateCandidate(
    approachThrough: ApproachMediumEnum[],
    hasAnsweredThePhone: boolean,
  ): void {
    this.store.dispatch(
      schedulingApproachCandidatesActions.communicate({
        approachThrough: approachThrough,
        hasAnsweredThePhone: hasAnsweredThePhone,
      }),
    );
  }
}
