import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { SlotsEffects } from './+state/slots.effects';
import { SLOTS_FEATURE_KEY, slotsReducer } from './+state/slots.reducer';
import { SlotsPermissionsStoreService } from './slots-permissions-store.service';
import { SlotsStoreService } from './slots-store.service';

export function provideSlotsStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: SLOTS_FEATURE_KEY, reducer: slotsReducer }),
    provideEffects(SlotsEffects),
    SlotsStoreService,
    SlotsPermissionsStoreService,
  ];
}
