import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PersonalRemarkContract } from '@scheduler-frontend/candidate-contracts';
import { CandidateMinimalContract } from '@scheduler-frontend/data-access-users';

export const candidateRemarksActions = createActionGroup({
  source: 'Candidate/Remarks',
  events: {
    addCandidateRemark: props<{ remark: string }>(),
    addCandidateRemarkSuccess: props<{
      candidate: CandidateMinimalContract;
      remark: PersonalRemarkContract;
    }>(),
    addCandidateRemarkFailure: props<{ error: Error }>(),
    loadActiveCandidateRemarksSuccess: props<{
      remarks: PersonalRemarkContract[];
    }>(),
    refreshActiveCandidateRemarks: emptyProps(),
    loadActiveCandidateRemarksFailure: props<{ error: Error }>(),
  },
});
