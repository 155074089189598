import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { isDefined } from '@techniek-team/rxjs';
import { map, Observable, shareReplay } from 'rxjs';
import { rankingsActions } from './+state/rankings.actions';
import { createRankingsId } from './+state/rankings.reducer';
import { RankingsSelectors } from './+state/rankings.selectors';
import { RankingWithHashContract } from './contracts/ranking.contract';
import { Ranking } from './models/ranking.model';

@Injectable()
export class RankingsStoreService {
  private readonly store = inject(Store);
  public loading$: Observable<boolean> = this.store.pipe(select(RankingsSelectors.rankingsLoading));

  public loaded$: Observable<boolean> = this.store.pipe(select(RankingsSelectors.rankingsLoaded));

  public rankings$ = this.store.pipe(select(RankingsSelectors.rankings));

  public getRanking$(candidateId: string, slotIds: string[]) {
    return this.store.pipe(
      select(RankingsSelectors.rankingEntities),
      map((dict) => {
        return dict[createRankingsId(candidateId, slotIds)];
      }),
      isDefined(),
      map((ranking: RankingWithHashContract) => {
        return denormalize(Ranking, ranking);
      }),
      shareReplay(),
    );
  }

  public clearAllRankings(): void {
    this.store.dispatch(rankingsActions.clearAllRankings());
  }
}
