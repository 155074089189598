import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CandidatesSelectors } from '@scheduler-frontend/data-access-candidates';
import { CandidateMinimalContract } from '@scheduler-frontend/data-access-users';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { handleEndpointFailure, handleEndpointSuccess } from '@techniek-team/tt-ngrx';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { absenceActions } from './absence.actions';
import { MarkAsAbsentApi } from './api/mark-as-absent.api';
import { MarkAsPresentApi } from './api/mark-as-present.api';

@Injectable()
export class AbsenceEffects {
  private readonly actions$ = inject(Actions);

  private readonly markAsAbsentApi = inject(MarkAsAbsentApi);

  private readonly store = inject(Store);

  private readonly markAsPresentApi = inject(MarkAsPresentApi);

  public markAsAbsent = createEffect(() =>
    this.actions$.pipe(
      ofType(absenceActions.markAsAbsent),
      concatLatestFrom((action) =>
        this.store.pipe(
          select(CandidatesSelectors.candidateEntities),
          map((candidates) => candidates[action.candidate]),
        ),
      ),
      switchMap(([action, candidate]) => {
        const promises = action.assignmentHasSlots.map((hasSlot) =>
          firstEmitFrom(this.markAsAbsentApi.execute(hasSlot, action.absencePeriod)),
        );

        return from(Promise.all(promises)).pipe(
          map(() =>
            absenceActions.markAsAbsentSuccess({
              ...action,
              candidate: candidate as CandidateMinimalContract,
            }),
          ),
          catchError((error) =>
            of(
              absenceActions.markAsAbsentFailure({
                error: error,
                candidate: candidate as CandidateMinimalContract,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public markAsAbsentFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(absenceActions.markAsAbsentFailure, {
          message: (action) =>
            `Let op! Het ziekmelden van ${action.candidate.fullName} is mislukt!`,
        }),
      ),
    { dispatch: false },
  );

  public markAsAbsentSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(absenceActions.markAsAbsentSuccess, {
          message: (action) => `${action.candidate.fullName} is ziekgemeld.`,
        }),
      ),
    { dispatch: false },
  );

  public markAsPresent = createEffect(() =>
    this.actions$.pipe(
      ofType(absenceActions.markAsPresent),
      concatLatestFrom((action) =>
        this.store.pipe(
          select(CandidatesSelectors.candidateEntities),
          map((assignments) => assignments[action.candidate as string]),
        ),
      ),
      switchMap(([action, candidate]) =>
        this.markAsPresentApi.execute(action.assignmentHasSlot).pipe(
          map(() =>
            absenceActions.markAsPresentSuccess({
              ...action,
              candidate: candidate as CandidateMinimalContract,
            }),
          ),
          catchError((error) =>
            of(
              absenceActions.markAsPresentFailure({
                error: error,
                candidate: candidate as CandidateMinimalContract,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public markAsPresentFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(absenceActions.markAsPresentFailure, {
          message: (action) =>
            `Let op! Het ziekmelden van ${action.candidate.fullName} is mislukt!`,
        }),
      ),
    { dispatch: false },
  );

  public markAsPresentSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(absenceActions.markAsPresentSuccess, {
          message: (action) =>
            `De ziekmelding voor ${action.candidate?.fullName} op deze shift is teruggedraaid.`,
        }),
      ),
    { dispatch: false },
  );
}
