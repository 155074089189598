import { LessonDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { hashParams } from '@scheduler-frontend/common';
import { compareAsc, formatISO, getDate, getMonth, getYear, set } from 'date-fns';
import { memoize } from 'lodash-es';
import { SlotTemplate } from '../+state/reducers/create-slots.reducer';
import { SlotToCreateContract } from '../../contract/slot-to-create.contract';

export const createSlotsToCreate = memoize((template: Required<SlotTemplate>) => {
  const slotsToCreate: SlotToCreateContract[] = [];
  for (let when of template.when) {
    const date = typeof when === 'string' ? when : when.date;
    if (date) {
      slotsToCreate.push(
        ...createSlotToCreate(typeof when === 'string' ? when : when.date, when, template),
      );
    }
  }
  slotsToCreate.sort((a, b) => compareAsc(a.timeRange.start, b.timeRange.start));
  return slotsToCreate;
}, hashParams);

function createSlotToCreate(
  date: string, // iso 8601 date
  when: string | LessonDetailedContract, // iso 8601 date
  template: Required<SlotTemplate>,
): SlotToCreateContract[] {
  return template.who.map((who) => {
    return {
      id: window.crypto.randomUUID(),
      schedule: template.schedule,
      lesson: typeof when === 'string' ? undefined : when,
      businessService: template.businessService,
      role: who.role,
      timeRange: {
        start: formatISO(
          set(who.timeRange.start, {
            year: getYear(date),
            month: getMonth(date),
            date: getDate(date),
          }),
        ),
        end: formatISO(
          set(who.timeRange.end, {
            year: getYear(date),
            month: getMonth(date),
            date: getDate(date),
          }),
        ),
        inclusiveStart: true,
        inclusiveEnd: false,
      },
      location: who.operationalLocation ?? template.productLocation,
      subject: who.subject ?? undefined,
      level: who.level ?? undefined,
      amountOfPupils: who.amountOfPupils ?? undefined,
      deliveryType: template.deliveryType ?? undefined,
    };
  });
}
