import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { formatISO, isAfter, subDays } from 'date-fns';
import { catchError, exhaustMap, from, of, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { BusinessServiceApi } from './api/business-service.api';
import { businessServicesActions } from './business-services.actions';
import { BUSINESS_SERVICES_FEATURE_KEY } from './business-services.reducer';

@Injectable()
export class BusinessServicesEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly storage = inject(Storage);

  private readonly store = inject(Store);

  private readonly businessServiceApi = inject(BusinessServiceApi);

  public createBusinessServicesFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(businessServicesActions.loadBusinessServicesFailure, {
          message: 'Er is iets misgegaan bij het laden van alle diensten.',
        }),
      ),
    { dispatch: false },
  );

  public createSaveBusinessServicesToStorageEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(businessServicesActions.loadBusinessServicesSuccess),
        tap((action) => {
          if (action.businessServices.length === 0) {
            return;
          }
          return from(
            Promise.all([
              this.storage.set(`${BUSINESS_SERVICES_FEATURE_KEY}-store-cache`, {
                items: action.businessServices,
                timestamp: new Date(),
              }),
            ]),
          );
        }),
      ),
    { dispatch: false },
  );

  public createInitBusinessServicesEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(
        businessServicesActions.initBusinessServices,
        businessServicesActions.reloadBusinessServices,
      ),
      switchMap(() =>
        this.store.pipe(
          select(UsersSelectors.activeUser),
          isDefined(),
          exhaustMap(() => {
            return from(this.storage.get(`${BUSINESS_SERVICES_FEATURE_KEY}-store-cache`)).pipe(
              exhaustMap((cache) => {
                if (cache && isAfter(cache.timestamp, subDays(new Date(), 1))) {
                  return of(
                    businessServicesActions.loadFromCacheBusinessServicesSuccess({
                      businessServices: cache.items,
                      totalItems: cache.items.length,
                      cacheTimestamp: formatISO(cache.timestamp),
                    }),
                  );
                }
                return this.businessServiceApi.getBusinessServices().pipe(
                  map((response) => {
                    return businessServicesActions.loadBusinessServicesSuccess({
                      businessServices: response['hydra:member'],
                      totalItems: response['hydra:totalItems'],
                    });
                  }),
                );
              }),
              catchError((error) =>
                of(businessServicesActions.loadBusinessServicesFailure({ error: error })),
              ),
            );
          }),
        ),
      ),
    ),
  );

  public createLoadDetailedBusinessServiceEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(businessServicesActions.loadDetailedBusinessService),
      exhaustMap((action) => {
        return this.businessServiceApi.getBusinessService(action.id).pipe(
          map((response) => {
            return businessServicesActions.loadDetailedBusinessServiceSuccess({
              detailedBusinessService: response,
            });
          }),
        );
      }),
    ),
  );

  public createLoadDetailedBusinessServiceFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(businessServicesActions.loadDetailedBusinessServiceFailure, {
          message: 'Er is iets misgegaan bij het laden van extra informatie over de dienst.',
        }),
      ),
    { dispatch: false },
  );

  public ngrxOnInitEffects() {
    return businessServicesActions.initBusinessServices();
  }
}
