import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Slot, SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { Role } from '@scheduler-frontend/data-access-roles';
import { environment } from '@scheduler-frontend/environments';
import {
  MercureClient,
  MercureCreatedEvent,
  MercureDeletedEvent,
  MercureUpdatedEvent,
} from '@techniek-team/mercure-client';
import { filter, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateSlotRequest, UpdateSlotRequest } from './slot.request';
import { GetSlotResponse } from './slot.response';

/**
 * Api to retrieve the {@see Slot} resource from the scheduler-api
 */
@Injectable({
  providedIn: 'root',
})
export class SlotApi {
  protected readonly httpClient = inject(HttpClient);

  private readonly mercureClient = inject(MercureClient);

  private readonly slotMercureListener = this.mercureClient
    .request<SlotContract | SlotDetailedContract | string>(
      environment.mercure.hub,
      ['slot-created', 'slot-updated', 'slot-deleted'],
      {
        tokenEndpoint: true,
        debug: true,
        observe: 'response',
      },
    )
    .pipe(takeUntilDestroyed(), shareReplay(1));

  /**
   * Retrieves the specified Slot resource from the api.
   */
  public getSlot(slot: string | Slot): Observable<GetSlotResponse> {
    if (slot instanceof Slot) {
      slot = slot.getIri() as string;
    }
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/slots/${slot}`;

    return this.httpClient.get<GetSlotResponse>(url);
  }

  /**
   * Create an array of slots in the backend.
   */
  public createSlots(createSlotsRequest: CreateSlotRequest[]): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/slots`;

    return this.httpClient
      .post<void>(url, {
        slots: createSlotsRequest.map((item) => ({
          scheduleId: item.scheduleId,
          startTime: item.startTime,
          endTime: item.endTime,
          roleId: item.roleId,
          locationId: item.locationId,
          subjectId: item.subjectId,
          level: item.level,
          lessonId: item.lessonId,
          deliveryTypeId: item.deliveryTypeId,
          numberOfPupils: item.numberOfPupils,
          source: 'SCHEDULER',
        })),
      })
      .pipe(map(() => undefined));
  }

  /**
   * Update a single slot.
   */
  public updateSlot(request: UpdateSlotRequest): Observable<void> {
    request.slot = request.slot instanceof Slot ? request.slot.getId() : request.slot;
    request.role = request.role instanceof Role ? request.role.getId() : request.role;
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/slots/${request.slot}`;

    return this.httpClient
      .put<void>(url, {
        startTime: request.startTime,
        endTime: request.endTime,
        roleId: request.role,
        performSkillCheck: request.performSkillCheck,
      })
      .pipe(map(() => undefined));
  }

  /**
   * Removes a single slot.
   */
  public deleteSlot(slot: string | Slot): Observable<void> {
    if (slot instanceof Slot) {
      slot = slot.getId();
    }
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/slots/${slot}`;

    return this.httpClient.delete<void>(url);
  }

  public slotCreatedEvents(): Observable<SlotContract> {
    return this.slotMercureListener.pipe(
      filter((event) => event instanceof MercureCreatedEvent),
      map((event) => event.data as SlotContract),
    );
  }

  public slotUpdatedEvents(): Observable<SlotDetailedContract> {
    return this.slotMercureListener.pipe(
      filter((event) => event instanceof MercureUpdatedEvent),
      map((event) => event.data as SlotDetailedContract),
    );
  }

  public slotDeletedEvents(): Observable<string> {
    return this.slotMercureListener.pipe(
      filter((event) => event instanceof MercureDeletedEvent),
      map((event) => event.data as string),
    );
  }
}
