import { AsyncPipe, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { IonicModule, ModalController } from '@ionic/angular';
import {
  ActiveAssignmentsPermissionsStoreService,
  ArticleCodeEnumDisplayValues,
  AssignmentCompensationLine,
  AssignmentsStoreService,
  PayoutArticleCodeEnum,
} from '@scheduler-frontend/data-access-assignment';
import { AsTypeModule } from '@techniek-team/common';
import { AddCompensationLineModalComponent } from './add-compensation-line-modal/add-compensation-line-modal.component';
import { EditPayoutComponent } from './edit-payout/edit-payout.component';
import { PayoutInformationComponent } from './payout-information/payout-information.component';

@Component({
  selector: 'app-payout-details',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss'],
  standalone: true,
  imports: [
    PayoutInformationComponent,
    IonicModule,
    ReactiveFormsModule,
    MatTableModule,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    CurrencyPipe,
    FontAwesomeModule,
    AsTypeModule,
    EditPayoutComponent,
  ],
})
export class PayoutComponent {
  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);
  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  protected readonly modalController = inject(ModalController);

  protected readonly PayoutArticleCodeEnum = PayoutArticleCodeEnum;

  protected readonly ArticleCodeEnumDisplayValues = ArticleCodeEnumDisplayValues;

  protected readonly displayedColumns: string[] = [
    'articleCode',
    'description',
    'date',
    'quantity',
    'amount',
    'total',
    'actions',
  ];

  protected readonly AssignmentCompensationLine = AssignmentCompensationLine;

  protected readonly faEyeSlash = faEyeSlash;

  public async addLine(): Promise<void> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: AddCompensationLineModalComponent,
      showBackdrop: true,
      cssClass: ['stack-modal'],
    });
    return confirmModal.present();
  }

  public deleteLine(compensationLine: string): void {
    this.assignmentsStoreService.removeCompensationLine(compensationLine);
  }
}
