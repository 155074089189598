import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalendarEventInterface } from '../calendar-event.interface';
import { TimeSegment } from '../tt-day/tt-day.component';

export enum CalendarViewEnum {
  MONTH = 'MONTH',
  DAY = 'DAY',
}

@Injectable()
export class CalendarService {

  public readonly activeDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  public readonly showToolbar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly next: EventEmitter<void> = new EventEmitter<void>();

  public readonly previous: EventEmitter<void> = new EventEmitter<void>();

  //eslint-disable-next-line max-len
  public readonly currentView: BehaviorSubject<CalendarViewEnum | undefined> = new BehaviorSubject<CalendarViewEnum | undefined>(undefined);

  public readonly previousChange: EventEmitter<[CalendarViewEnum, Date]> = new EventEmitter<[CalendarViewEnum, Date]>();

  public readonly nextChange: EventEmitter<[CalendarViewEnum, Date]> = new EventEmitter<[CalendarViewEnum, Date]>();

  /**
   * This gets Trigger when the users click on an event.
   */
  public readonly eventClicked: EventEmitter<CalendarEventInterface> = new EventEmitter<CalendarEventInterface>();

  /**
   * This gets Trigger when the users click on an empty time segment where no event are happening
   */
  public readonly dayEmptySpaceClick: EventEmitter<TimeSegment> = new EventEmitter<TimeSegment>();

  /**
   * This gets Trigger when the users (long) presses on an empty time segment where no event are happening
   */
  public readonly dayEmptySpacePress: EventEmitter<TimeSegment> = new EventEmitter<TimeSegment>();

  public readonly currentDateTitle: EventEmitter<string> = new EventEmitter<string>();
}
