import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { RegionContract } from '../contracts/region.contract';
import { regionsActions } from './regions.actions';

export const REGIONS_FEATURE_KEY = 'regions';

export interface RegionsState extends EntityState<RegionContract> {
  loaded: boolean; // has the Regions list been loaded
  loading: boolean; // is the Regions busy with loading
  error?: string | null; // last known error (if any)
  totalItems: number | null;
}

export const regionsAdapter: EntityAdapter<RegionContract> = createEntityAdapter<RegionContract>({
  selectId: jsonLdSelectId,
});

export const initialRegionsState: RegionsState = regionsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
  totalItems: null,
});

const reducer = createReducer(
  initialRegionsState,
  on(regionsActions.initRegions, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(regionsActions.reloadRegions, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(regionsActions.loadRegionsSuccess, (state, { regions, totalItems }) =>
    regionsAdapter.setAll(regions, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: formatISO(new Date()),
    }),
  ),
  on(regionsActions.loadFromCacheRegionsSuccess, (state, { regions, totalItems, cacheTimestamp }) =>
    regionsAdapter.setAll(regions, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: cacheTimestamp,
    }),
  ),
  on(regionsActions.loadRegionsFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function regionsReducer(state: RegionsState | undefined, action: Action): RegionsState {
  return reducer(state, action);
}
