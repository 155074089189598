import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  CandidateDetailedContract,
  PersonalRemarkContract,
} from '@scheduler-frontend/candidate-contracts';
import { CandidateMinimal } from '@scheduler-frontend/data-access-users';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { CandidateRejectionRequest } from './candidate.request';

@Injectable({
  providedIn: 'root',
})
export class CandidateApi {
  private readonly httpClient = inject(HttpClient);

  /**
   * Get a candidate with a large set of detailed information, using the known
   * candidate id.
   */
  public getCandidate(candidate: string | CandidateMinimal): Observable<CandidateDetailedContract> {
    const id: string = typeof candidate === 'string' ? candidate : candidate.getId();
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/candidates/${id}`;

    return this.httpClient.get<CandidateDetailedContract>(url);
  }

  public getCandidatePersonalRemarks(
    candidate: string | CandidateMinimal,
  ): Observable<Collection<PersonalRemarkContract>> {
    const id: string = typeof candidate === 'string' ? candidate : candidate.getId();
    const url: string = `${environment.perza.url}${environment.perza.iri}/people/${id}/remarks`;

    return this.httpClient.get<Collection<PersonalRemarkContract>>(url);
  }

  /**
   * Post a remark about a person to Perza.
   *
   * The Candidates used in the scheduler also exists as person resource in Perza
   * But the Iri would differ because it being a different api. Because of that
   * we use they id her and prefix it so create a valid iri for Perza
   */
  public postCandidatePersonalRemarks(
    candidate: string | CandidateMinimal,
    remark: string,
  ): Observable<void> {
    const id: string = typeof candidate === 'string' ? candidate : candidate.getId();
    const url: string = `${environment.perza.url}${environment.perza.iri}/personal_remarks`;

    return this.httpClient.post<void>(url, {
      remark: remark,
      person: '/api/people/' + id,
    });
  }

  public postCandidateRejection(request: CandidateRejectionRequest): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/candidates/${request.candidate}/rejection`;

    return this.httpClient.post<void>(url, {
      declineReason: request.declineReason,
      slots: request.slots,
    });
  }
}
