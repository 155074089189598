import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { WithoutFirstSlotContract } from '../contracts/without-first-slot.contract';
import { WithoutFollowUpSlotContract } from '../contracts/without-follow-up-slot.contract';

export const awaitingSlotsActions = createActionGroup({
  source: '[AwaitingSlots]',
  events: {
    init: emptyProps(),

    loadWithoutFirstSlot: emptyProps(),
    loadWithoutFirstSlotReload: emptyProps(),
    loadWithoutFirstSlotSuccess: props<{ items: WithoutFirstSlotContract[]; totalItems: number }>(),
    loadWithoutFirstSlotFailure: props<{ error: unknown }>(),
    loadFromCacheWithoutFirstSlotSuccess: props<{
      items: WithoutFirstSlotContract[];
      totalItems: number;
      cacheTimestamp: string;
    }>(),

    loadWithoutFollowUpSlot: emptyProps(),
    loadWithoutFollowUpSlotReload: emptyProps(),
    loadWithoutFollowUpSlotSuccess: props<{
      items: WithoutFollowUpSlotContract[];
      totalItems: number;
    }>(),
    loadWithoutFollowUpSlotFailure: props<{ error: unknown }>(),
    loadFormCacheWithoutFollowUpSlotSuccess: props<{
      items: WithoutFollowUpSlotContract[];
      totalItems: number;
      cacheTimestamp: string;
    }>(),
  },
});
