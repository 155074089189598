import { Exclude, Expose, Type } from 'class-transformer';
import { HydraView } from './hydra-view.model';
import { JsonLd } from './json-ld.model';

// Without this dynamic parameter AOT compiling will fail.
// @see https://angular.io/guide/angular-compiler-options#strictmetadataemit
// @dynamic
export class Hydra<T extends JsonLd> extends JsonLd {

  @Exclude() private type: Function;

  @Type(options => {
    return (options?.newObject as Hydra<T>).type;
  })
  @Expose({ name: 'hydra:member' }) public collection!: T[];

  @Expose({ name: 'hydra:totalItems' }) public totalItems!: number;

  @Type(() => HydraView)
  @Expose({ name: 'hydra:view' }) public view!: HydraView;

  constructor(type: Function) {
    super();
    // @dynamic
    this.type = type;
  }

  @Exclude() public get first(): string|undefined {
    return this.view.first;
  }

  @Exclude() public get last(): string|undefined {
    return this.view.last;
  }

  @Exclude() public get previous(): string|undefined {
    return this.view.previous;
  }

  @Exclude() public get next(): string|undefined {
    return this.view.next;
  }

  public toArray(): T[] {
    return this.collection;
  }

  public toMap(): Map<string, T> {
    return new Map<string, T>(this.collection.map(item => [item.getIri(), item] as [string, T]));
  }
}
