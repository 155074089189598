import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LetDirective } from '@ngrx/component';
import { ActionDetailContract, ActionsStoreService } from '@scheduler-frontend/data-access-action';
import { RolesStoreService } from '@scheduler-frontend/data-access-roles';
import { SearchStoreService } from '@scheduler-frontend/data-access-search';
import { jsonLdSelectId, JsonLdSelectIdPipe } from '@techniek-team/tt-ngrx';

@Component({
  selector: 'app-dashboard-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss'],
  standalone: true,
  imports: [IonicModule, LetDirective, AsyncPipe, DatePipe, JsonLdSelectIdPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionListComponent {
  protected readonly actionsStoreService = inject(ActionsStoreService);

  protected readonly rolesStoreService = inject(RolesStoreService);

  protected readonly searchStoreService = inject(SearchStoreService);

  protected readonly router = inject(Router);

  protected async navigateToActionsSlot(action: ActionDetailContract): Promise<boolean> {
    await this.searchStoreService.createSearch(
      { 'slot.id': jsonLdSelectId(action.slot) },
      false,
      true,
    );
    const lastCreatedSearch = this.searchStoreService.lastCreatedSystemSearch();
    return this.router.navigate(['zoeken', lastCreatedSearch?.hash]);
  }
}
