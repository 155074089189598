import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TransitionEnum } from '@scheduler-frontend/data-access-assignment';
import { CandidateMinimalContract } from '@scheduler-frontend/data-access-users';
import { ApplyTransitionResponse } from '../api/transition.response';

export const schedulingAssignToSlotsActions = createActionGroup({
  source: '[Scheduling/AssignToSlots]',
  events: {
    /**
     * On this action the scheduler-api actually assigns the candidate to the
     * assignment (slots).
     */
    assignCandidate: props<{ directlyConfirm: boolean; resolveConflicts: boolean }>(),

    requestConfirmationOnAssigningPartialAvailableCandidate: props<{
      directlyConfirm: boolean;
      resolveConflicts: boolean;
    }>(),

    assignCandidateSubmit: props<{ directlyConfirm: boolean; resolveConflicts: boolean }>(),
    assignCandidateFailure: props<{ error: unknown }>(),

    /**
     * This action is triggered when {@see schedulingAssignToSlotsActions.assignCandidate} directlyConfirm property
     * is true where it will transition the created assignments to the state Confirm
     */
    confirmCandidateAssignmentOnAssign: props<{
      assignmentIds: string[];
      candidate: CandidateMinimalContract;
    }>(),
    askForDirectStateChangeAfterAssignment: props<{ assignmentIds: string[] }>(),

    transitionAfterAssignCandidate: props<{
      transitionTo: TransitionEnum;
      assignmentIds: string[];
    }>(),
    showTransitionMessageModal: props<{ messages: ApplyTransitionResponse['messages'] }>(),
    assignCandidateSuccess: props<{ message: string }>(),
    assignCandidateCancel: emptyProps(),
  },
});
