import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { AvailabilityContract } from '@scheduler-frontend/calendar-contracts';
import { TsRange } from '@techniek-team/class-transformer';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { eachMonthOfInterval } from 'date-fns';
import { candidateAvailabilitiesActions } from '../actions/candidate-availabilities.actions';

export const CANDIDATE_AVAILABILITIES_FEATURE_KEY: string = 'candidateAvailabilities';

export interface CandidateAvailabilitiesState extends EntityState<AvailabilityContract> {
  loaded: boolean;
  loading: boolean;
  error?: unknown;
  loadedMonths: Date[];
}

//eslint-disable-next-line max-len
export const candidateAvailabilitiesAdapter: EntityAdapter<AvailabilityContract> =
  createEntityAdapter<AvailabilityContract>({
    selectId: jsonLdSelectId,
  });

export const initialCandidateAvailabilitiesState: CandidateAvailabilitiesState =
  candidateAvailabilitiesAdapter.getInitialState({
    error: null,
    loaded: false,
    loading: false,
    loadedMonths: [],
  });

const reducer: ActionReducer<CandidateAvailabilitiesState> = createReducer(
  initialCandidateAvailabilitiesState,
  on(candidateAvailabilitiesActions.initAvailabilities, (state, { range }) => {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: null,
      loadedMonths: state.loadedMonths.concat(
        eachMonthOfInterval(TsRange.fromObject(range).interval),
      ),
    };
  }),
  on(candidateAvailabilitiesActions.appendAvailabilities, (state, { range }) => {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: null,
      loadedMonths: state.loadedMonths.concat(
        eachMonthOfInterval(TsRange.fromObject(range).interval),
      ),
    };
  }),
  on(candidateAvailabilitiesActions.refreshAvailabilities, (state) => {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: null,
    };
  }),
  on(candidateAvailabilitiesActions.loadAvailabilitiesSuccess, (state, { availabilities }) => {
    return candidateAvailabilitiesAdapter.setAll(availabilities, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(candidateAvailabilitiesActions.appendAvailabilitiesSuccess, (state, { availabilities }) => {
    return candidateAvailabilitiesAdapter.setMany(availabilities, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(candidateAvailabilitiesActions.refreshAvailabilitiesSuccess, (state, { availabilities }) => {
    return candidateAvailabilitiesAdapter.setMany(availabilities, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(candidateAvailabilitiesActions.loadAvailabilitiesFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
);

export function candidateAvailabilitiesReducer(
  state: CandidateAvailabilitiesState | undefined,
  action: Action,
): CandidateAvailabilitiesState {
  return reducer(state, action);
}
