import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { Observable } from 'rxjs';
import { candidatesByRankingActions } from './+state/candidates-by-ranking.actions';
import { CandidatesByRankingSelectors } from './+state/candidates-by-ranking.selectors';

@Injectable()
export class CandidatesByRankingStoreService {
  private readonly store = inject(Store);
  public loading$: Observable<boolean> = this.store.pipe(
    select(CandidatesByRankingSelectors.candidatesByRankingLoading),
  );

  public loaded$: Observable<boolean> = this.store.pipe(
    select(CandidatesByRankingSelectors.candidatesByRankingLoaded),
  );

  public lastChunkLoaded$: Observable<boolean> = this.store.pipe(
    select(CandidatesByRankingSelectors.lastChunkLoaded),
  );

  public candidateIds$: Observable<string[]> = this.store.pipe(
    select(CandidatesByRankingSelectors.candidateIds),
  );

  public async next(): Promise<void> {
    if (!(await firstEmitFrom(this.lastChunkLoaded$))) {
      this.store.dispatch(candidatesByRankingActions.loadNextChunk());
    }
  }

  public initCandidateByRanking() {
    this.store.dispatch(candidatesByRankingActions.initCandidatesByRanking());
  }

  public stopCandidateByRanking() {
    this.store.dispatch(candidatesByRankingActions.stopCandidatesByRanking());
  }

  public initLoadSlotByCandidate() {
    this.store.dispatch(candidatesByRankingActions.initLoadSlotByCandidate());
  }

  public stopLoadSlotByCandidate() {
    this.store.dispatch(candidatesByRankingActions.stopLoadSlotByCandidate());
  }

  public showAlternativeShifts(): void {
    this.store.dispatch(candidatesByRankingActions.startShownAlternativeShifts());
  }

  public hideAlternativeShifts(): void {
    this.store.dispatch(candidatesByRankingActions.stopShownAlternativeShifts());
  }
}
