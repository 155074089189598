import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Assignment } from '@scheduler-frontend/assignment-contracts';
import { shareReplay } from 'rxjs';
import { AssignmentsPermissionsSelectors } from './+state/selectors/assignments-permissions.selectors';

@Injectable()
export class AssignmentsPermissionsStoreService {
  private readonly store = inject(Store);

  public canMarkAsAbsent$(assignment: Assignment | string) {
    assignment = assignment instanceof Assignment ? assignment.getId() : assignment;
    return this.store.pipe(
      select(AssignmentsPermissionsSelectors.canMarkAsAbsent(assignment)),
      shareReplay(),
    );
  }

  public canMarkAsPresent$(assignment: Assignment | string) {
    assignment = assignment instanceof Assignment ? assignment.getId() : assignment;
    return this.store.pipe(
      select(AssignmentsPermissionsSelectors.canMarkAsPresent(assignment)),
      shareReplay(),
    );
  }
}
