import { Expose, Type } from 'class-transformer';
import { Search } from './search.model';

export class UserSearch {
  /**
   * When true the search is shown in the Favorite list.
   */
  @Expose() public isFavorite!: boolean;

  /**
   * Date when the query was last executed
   */
  @Type(() => Date)
  @Expose()
  public lastQueriedAt!: Date;

  /**
   * Search object
   */
  @Type(() => Search)
  @Expose()
  public search!: Search;
}
