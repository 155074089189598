<div class="header">Rol</div>
<div class="header">Start-/eindtijd</div>
@if (requirementsStoreService.mustSetNumberOfPupils()) {
  <div class="header">Leerlingaantal</div>
}
@if (requirementsStoreService.mustHaveSubject()) {
  <div class="header">Onderwerp</div>
}

@for (
  slotsToCreate of slotsToCreateStoreService.slotsToCreateGroupedByDate();
  track slotsToCreate.date
) {
  <div class="group-header" [style.grid-row]="slotsToCreate.headerGridRow">
    {{ slotsToCreate.date }}
  </div>

  @for (slotToCreate of slotsToCreate.items; track slotToCreate.id) {
    <app-slot-to-create
      data-test="fine-tune-slot-to-create"
      [gridRow]="slotToCreate.gridRow"
      [slotToCreate]="slotToCreate"
    ></app-slot-to-create>
  }
}
