import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, map, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { createSlotsActions } from '../actions/create-slots.actions';
import { slotTemplateActions } from '../actions/slot-template.actions';
import { ProductsByLocationApi } from '../api/products-by-location.api';

@Injectable()
export class LoadProductsByLocationEffects {
  private readonly actions$ = inject(Actions);

  private readonly productByLocationApi = inject(ProductsByLocationApi);

  public readonly loadProductsByLocation = createEffect(() =>
    this.actions$.pipe(
      ofType(slotTemplateActions.changeProductLocation),
      switchMap((action) => {
        return this.productByLocationApi.execute(jsonLdSelectId(action.change['@id'])).pipe(
          map((response) => {
            if (response['hydra:member'].length === 0) {
              return createSlotsActions.loadProductsEmptyResults();
            }

            return createSlotsActions.loadProductsSuccess({
              items: response['hydra:member'],
            });
          }),
          catchError((error) => of(createSlotsActions.loadProductsFailure({ error: error }))),
        );
      }),
    ),
  );

  public readonly loadProductsByLocationFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(createSlotsActions.loadProductsFailure, {
          message: 'Er is iets misgegaan bij het laden van alle producten.',
        }),
      ),
    { dispatch: false },
  );

  public readonly loadProductsEmptyResults = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(createSlotsActions.loadProductsEmptyResults, {
          message: 'Let op! Er zijn voor deze locatie geen producten (en diensten) gevonden!',
        }),
      ),
    { dispatch: false },
  );
}
