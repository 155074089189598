import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { FetchStorageInterface } from '@techniek-team/fetch';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map, Observable, shareReplay } from 'rxjs';
import { declineReasonsActions } from './+state/decline-reasons.actions';
import { DeclineReasonsSelectors } from './+state/decline-reasons.selectors';
import { DeclineReasonContract } from './contracts/decline-reason.contract';
import { DeclineReason } from './models/decline-reason.model';

@Injectable()
export class DeclineReasonsStoreService
  implements FetchStorageInterface<DeclineReasonContract, DeclineReason>
{
  private readonly store = inject(Store);
  public loading$: Observable<boolean> = this.store.pipe(select(DeclineReasonsSelectors.loading));

  public loaded$: Observable<boolean> = this.store.pipe(select(DeclineReasonsSelectors.loaded));

  public declineReasons$ = this.store.pipe(
    select(DeclineReasonsSelectors.declineReasons),
    isDefined(),
    map((data) => denormalize(DeclineReason, data)),
    shareReplay(),
  );

  public declineReasonsMap$: Observable<Map<string, DeclineReason>> = this.store.pipe(
    select(DeclineReasonsSelectors.declineReasonEntities),
    map((data) => {
      return new Map(
        Object.entries(data).map(([key, item]) => [key, denormalize(DeclineReason, data)]),
      );
    }),
    shareReplay(),
  );

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init(): void {
    this.store.dispatch(declineReasonsActions.initDeclineReasons());
  }

  public reload(): void {
    this.store.dispatch(declineReasonsActions.reloadDeclineReasons());
  }

  public supportsFetch(identifier: string): boolean {
    return identifier === 'DeclineReason';
  }

  public async waitForInitialization(): Promise<void> {
    await firstEmitFrom(
      this.store.pipe(
        select(DeclineReasonsSelectors.loaded),
        filter((loaded) => loaded === true),
      ),
    );
  }

  public getFetchFromStorage(value: string): Observable<DeclineReason> {
    return this.store.pipe(
      select(DeclineReasonsSelectors.denormalizedDeclineReasonEntities),
      map((dict) => dict[jsonLdSelectId(value)]),
      isDefined(),
    );
  }
}
