import {
  Slot,
  SlotDetailed,
  SlotDetailedContract,
  SlotDetailedWithAssignmentHasSlot,
} from '@scheduler-frontend/assignment-contracts';
import { isAfter, isSameMonth } from 'date-fns';
import { getLastBookingPeriod } from './last-booking-period.function';

/**
 * The method check if the given Slot or Date lies within a closed booking period.
 */
export function isBookingPeriodClosed(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: Slot | SlotDetailed | SlotDetailedWithAssignmentHasSlot | SlotDetailedContract | Date,
): boolean {
  if (
    (target instanceof SlotDetailedWithAssignmentHasSlot ||
      (typeof target === 'object' && 'assignmentHasSlot' in target)) &&
    target?.assignmentHasSlot?.assignment
  ) {
    return target.assignmentHasSlot.assignment.bookingPeriodClosed;
  }
  const lastBookingPeriod: Date = getLastBookingPeriod();

  if (target instanceof Date) {
    // The booking period for a certain month closes on 00:00:00 of the six day
    // of the next month. The booking period is about a month. Meaning that the
    // first 5 day of a month are part of the booking period of that month and
    // not the previous.
    if (isSameMonth(lastBookingPeriod, target)) {
      return false;
    }

    return isAfter(lastBookingPeriod, target);
  }

  if (isSameMonth(lastBookingPeriod, target.timePeriod.end)) {
    return false;
  }

  return isAfter(getLastBookingPeriod(), target.timePeriod.end);
}
