import { MercureBaseEvent } from './mercure-base.event';

export class MercureEvent<T> extends MercureBaseEvent {
  /**
   * Returns the data of the message.
   *
   * [MDN Reference](https://developer.mozilla.org/docs/Web/API/MessageEvent/data)
   */
  public readonly data: T | null;

  /**
   * Returns the last event ID string, for server-sent events.
   *
   * [MDN Reference](https://developer.mozilla.org/docs/Web/API/MessageEvent/lastEventId)
   */
  public readonly lastEventId: string;

  constructor(init: { data?: T; lastEventId: string; eventType: string }) {
    super({ eventType: init.eventType });
    this.data = init.data !== undefined ? init.data : null;
    this.lastEventId = init.lastEventId;
  }
}
