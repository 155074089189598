import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { DeclineReasonContract } from '../contracts/decline-reason.contract';
import { declineReasonsActions } from './decline-reasons.actions';

export const DECLINE_REASONS_FEATURE_KEY = 'declineReasons';

export interface DeclineReasonsState extends EntityState<DeclineReasonContract> {
  totalItems?: number;

  loaded: boolean;

  loading: boolean;

  error?: unknown | null;

  cacheTimeStamp?: string;
}

export const declineReasonsAdapter: EntityAdapter<DeclineReasonContract> =
  createEntityAdapter<DeclineReasonContract>({
    selectId: jsonLdSelectId,
  });

export const initialDeclineReasonsState: DeclineReasonsState =
  declineReasonsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
  });

const reducer = createReducer(
  initialDeclineReasonsState,
  on(declineReasonsActions.initDeclineReasons, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(declineReasonsActions.reloadDeclineReasons, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(declineReasonsActions.loadDeclineReasonsSuccess, (state, { declineReasons, totalItems }) =>
    declineReasonsAdapter.setAll(declineReasons, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: formatISO(new Date()),
    }),
  ),
  on(
    declineReasonsActions.loadFromCacheDeclineReasonsSuccess,
    (state, { declineReasons, totalItems, cacheTimestamp }) =>
      declineReasonsAdapter.setAll(declineReasons, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: cacheTimestamp,
      }),
  ),
  on(declineReasonsActions.loadDeclineReasonsFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function declineReasonsReducer(state: DeclineReasonsState | undefined, action: Action) {
  return reducer(state, action);
}
