import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarkAsPresentApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(assignmentHasSlot: string): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/mark-as-present`;

    return this.httpClient.post<void>(url, {
      assignmentHasSlotId: assignmentHasSlot,
    });
  }
}
