import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, map, of, switchMap } from 'rxjs';
import { activeAssignmentHistoryActions } from '../actions/active-assignment-history.actions';
import { activeAssignmentActions } from '../actions/active-assignment.actions';
import { AssignmentApi } from '../api/assignment/assignment.api';
import { ActiveAssignmentHistorySelectors } from '../selectors/active-assignment-history.selectors';
import { AssignmentsSelectors } from '../selectors/assignments.selectors';

@Injectable()
export class ActiveAssignmentHistoryEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly errorHandler = inject(SentryErrorHandler);

  private readonly toastService = inject(ToastService);

  private readonly assignmentApi = inject(AssignmentApi);

  public readonly loadHistoryOnSetActiveAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.setActiveAssignment),
      map(() => activeAssignmentHistoryActions.loadHistory()),
    ),
  );

  public readonly loadHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentHistoryActions.loadHistory),
      concatLatestFrom(() =>
        this.store.pipe(select(AssignmentsSelectors.activeAssignmentId), isDefined()),
      ),
      switchMap(([action, active]) =>
        this.assignmentApi.getExecutedTransitions(active, 1).pipe(
          map((response) =>
            activeAssignmentHistoryActions.loadHistorySuccess({
              records: response['hydra:member'],
              totalItems: response['hydra:totalItems'],
              chunk: 1,
            }),
          ),
          catchError((error) =>
            of(
              activeAssignmentHistoryActions.loadHistoryFailure({
                error: error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  //eslint-disable-next-line max-lines-per-function
  public readonly loadNextChunk = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentHistoryActions.loadNextChunk),
      concatLatestFrom(() => [
        this.store.pipe(
          select(ActiveAssignmentHistorySelectors.loadedChunks),
          map((chunks) => Math.max(...chunks)),
        ),
        this.store.pipe(select(AssignmentsSelectors.activeAssignmentId), isDefined()),
      ]),
      exhaustMap(([_action, lastChunk, active]) => {
        return this.assignmentApi.getExecutedTransitions(active, lastChunk + 1).pipe(
          map((response) =>
            activeAssignmentHistoryActions.loadNextChunkSuccess({
              records: response['hydra:member'],
              chunk: lastChunk + 1,
              totalItems: response['hydra:totalItems'],
            }),
          ),
          catchError((error) =>
            of(
              activeAssignmentHistoryActions.loadHistoryFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public readonly loadHistoryFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(activeAssignmentHistoryActions.loadHistoryFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de opdrachtgeschiedenis.',
        }),
      ),
    { dispatch: false },
  );
}
