/* eslint-disable @typescript-eslint/naming-convention */
import { Dictionary } from '@ngrx/entity';
import { hashParams } from '@scheduler-frontend/common';
import { ScheduleContract } from '@scheduler-frontend/schedule-contracts';
import { memoize } from 'lodash-es';

const productTypeOrder: Dictionary<number> = {
  'Huiswerkbegeleiding': 1,
  'Bijles': 2,
  'Basisschoolbegeleiding': 3,
  'Lyceo ET': 4,
  'Lenteschool': 5,
  'Zomerschool': 6,
  'TWOK': 7,
  'Codelab': 8,
  'Coachen': 9,
  'Training Leren Leren': 10,
  'Bijles HO': 11,
  'Bijspijkeren': 12,
  'Codeklas': 13,
  'Eindscan Groep 8': 14,
  'Faalangstreductie': 15,
  'Het Lyceo Examenjaar': 16,
  'HWB in de Vakantie': 17,
  'Individuele Bijles VCB': 18,
  'Interne training': 19,
  'Onderwijshelden ET': 20,
  'Pedagogische coaching': 21,
  'Rekentoets bijles': 22,
  'Scriptiebegeleiding': 23,
  'Studiecoaching': 24,
  'Studiekeuzedag': 25,
  'Weekendtraining': 26,
};

export const groupSchedulesByProductType = memoize(
  (schedules: ScheduleContract[]): Dictionary<ScheduleContract[]> => {
    schedules.sort(sortByProductAndSchedule);
    return schedules.reduce(
      (dict, schedule) => {
        const productTypeId = schedule.product?.productType?.['@id'] ?? 'N/A';
        dict[productTypeId] = dict[productTypeId]?.concat(schedule) ?? [schedule];
        return dict;
      },
      {} as { [key: string]: ScheduleContract[] },
    );
  },
  hashParams,
);

//eslint-disable-next-line complexity
export function sortByProductAndSchedule(a: ScheduleContract, b: ScheduleContract): number {
  if (!a.product || !b.product) {
    return a.product ? -1 : -1;
  }

  if (a.product.productType && b.product.productType) {
    const aProductTypeOrder = getProductTypeOrderEnum(a.product.productType.name);
    const bProductTypeOrder = getProductTypeOrderEnum(b.product.productType.name);

    if (aProductTypeOrder && bProductTypeOrder) {
      const productOrderDifference = aProductTypeOrder - bProductTypeOrder;
      if (productOrderDifference !== 0) {
        return productOrderDifference;
      }
    }

    const productTypeComparison = a.product.productType.name.localeCompare(
      b.product.productType.name,
    );
    if (productTypeComparison !== 0) {
      return productTypeComparison;
    }
  }

  const $rxDatePattern: RegExp = /(\d{1,2})([/-])(\d{1,2})([/-])(\d{1,2})/;
  if (a.name.match($rxDatePattern) || b.name.match($rxDatePattern)) {
    return b.name.localeCompare(a.name);
  }
  return a.name.localeCompare(b.name);
}

function getProductTypeOrderEnum(value: string): number | undefined {
  return productTypeOrder[value] ?? undefined;
}
