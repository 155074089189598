import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { FetchStorageInterface } from '@techniek-team/fetch';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { locationsActions } from './+state/locations.actions';
import { LocationsSelectors } from './+state/locations.selectors';
import { LocationContract } from './contracts/location.contract';
import { LocationTypeEnum } from './enums/location-type.enum';
import { LocationModel } from './models/location.model';

@Injectable()
export class LocationsStoreService
  implements FetchStorageInterface<LocationContract, LocationModel>
{
  private readonly store = inject(Store);

  private readonly actions$ = inject(Actions);
  public loading$: Observable<boolean> = this.store.pipe(
    select(LocationsSelectors.locationsLoading),
  );

  public loaded$: Observable<boolean> = this.store.pipe(select(LocationsSelectors.locationsLoaded));

  public count$: Observable<number> = this.store.pipe(select(LocationsSelectors.count));

  public locationEntities = this.store.selectSignal(LocationsSelectors.locationsEntities);

  public allLocations$: Observable<LocationModel[]> = this.store.pipe(
    select(LocationsSelectors.allLocations),
    isDefined(),
    map((data) => denormalize(LocationModel, data)),
    shareReplay(),
  );

  public allLocations = this.store.selectSignal(LocationsSelectors.allLocations);

  public pupilsPrivateLocations$: Observable<LocationModel[]> = this.store.pipe(
    select(LocationsSelectors.currentPupilsPrivateLocations),
    map((data) => denormalize(LocationModel, data)),
    shareReplay(),
  );

  public locations$: Observable<LocationModel[]> = this.store.pipe(
    select(LocationsSelectors.activeLocations),
    map((data) => denormalize(LocationModel, data)),
    shareReplay(),
  );

  public currentLocation = this.store.selectSignal(LocationsSelectors.currentLocation);

  public currentLocation$: Observable<LocationModel> = this.store.pipe(
    select(LocationsSelectors.currentLocation),
    isDefined(),
    map((data) => denormalize(LocationModel, data)),
  );

  public locationsPreFetchMap$: Observable<Map<string, LocationModel>> = this.store.pipe(
    select(LocationsSelectors.locationsEntities),
    map((data) => {
      return new Map(
        Object.entries(data).map(([key]) => [
          (data['@id'] ?? key) as string,
          denormalize(LocationModel, data),
        ]),
      );
    }),
    shareReplay(),
  );

  public locationsFilterGroup = this.store.selectSignal(LocationsSelectors.locationsFilterGroup);
  public locationsFilterGroupKey = LocationsSelectors.locationsFilterGroupKey;

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public async init(
    locationTypes: LocationTypeEnum[] = [
      LocationTypeEnum.HUB,
      LocationTypeEnum.SCHOOL,
      LocationTypeEnum.EXTERN,
    ],
    includeArchived: boolean = false,
  ): Promise<void> {
    const hasNotLoadedTypes: boolean = await firstValueFrom(
      this.store.pipe(select(LocationsSelectors.locationTypesLoaded)),
    ).then((loadedTypes: LocationTypeEnum[]) => this.hasNotLoadedTypes(locationTypes, loadedTypes));
    const hasNotLoadedArchivedTypes: boolean =
      includeArchived &&
      (await firstValueFrom(
        this.store.pipe(select(LocationsSelectors.archivedLocationTypesLoaded)),
      ).then((loadedTypes) => this.hasNotLoadedTypes(locationTypes, loadedTypes)));

    if (hasNotLoadedTypes || hasNotLoadedArchivedTypes) {
      this.store.dispatch(
        locationsActions.initLocations({
          locationTypes: locationTypes,
          includeArchived: includeArchived,
        }),
      );
    }
  }

  public loadPupilPrivateLocations(pupil: string, includeArchived: boolean = false) {
    this.store.dispatch(
      locationsActions.loadPupilPrivateLocations({
        pupil: pupil,
        includeArchived: includeArchived,
      }),
    );
  }

  public reloadLocations(includeArchived: boolean = false): void {
    this.store.dispatch(
      locationsActions.reloadLocations({
        includeArchived: includeArchived,
      }),
    );
  }

  public selectLocation(location: LocationModel | string | undefined): void {
    if (location instanceof LocationModel) {
      location = location.getId();
    }
    this.store.dispatch(locationsActions.selectLocation({ locationId: location }));
  }

  public supportsFetch(identifier: string): boolean {
    return identifier === 'LocationModel';
  }

  public async waitForInitialization(): Promise<void> {
    await firstEmitFrom(
      this.store.pipe(
        select(LocationsSelectors.locationsLoaded),
        filter((loaded) => loaded === true),
      ),
    );
  }

  public getFetchFromStorage(value: string): Observable<LocationModel> {
    return this.store.pipe(
      select(LocationsSelectors.denormalizedLocationEntities),
      map((dict) => dict[jsonLdSelectId(value)]),
      isDefined(),
    );
  }

  private hasNotLoadedTypes(
    target: LocationTypeEnum[],
    alreadyLoaded: LocationTypeEnum[],
  ): boolean {
    return target.filter((type) => !alreadyLoaded.includes(type)).length > 0;
  }
}
