import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import { filter, map } from 'rxjs';
import { candidatesByRankingActions } from '../candidates-by-ranking.actions';

@Injectable()
export class UpdateCandidateStoreEffect {
  private readonly actions$ = inject(Actions);

  public addItemsToCandidateStore = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidatesByRankingActions.loadCandidatesByRankingSuccess,
        candidatesByRankingActions.loadNextChunkSuccess,
      ),
      filter((action) => !!action.candidates),
      map((action) =>
        candidatesActions.loadCandidatesSuccess({
          candidates: action.candidates,
        }),
      ),
    ),
  );
}
