import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { RegionsEffects } from './+state/regions.effects';
import { REGIONS_FEATURE_KEY, regionsReducer } from './+state/regions.reducer';
import { RegionsStoreService } from './regions-store.service';

export function provideRegionsStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: REGIONS_FEATURE_KEY, reducer: regionsReducer }),
    provideEffects(RegionsEffects),
    RegionsStoreService,
  ];
}
