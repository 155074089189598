import {
  ProductTypeContract,
  ProductTypeDetailed,
  ProductTypeDetailedContract,
} from '@scheduler-frontend/data-access-product-types';

export function mustHaveSubject(
  productType: ProductTypeDetailedContract | ProductTypeContract | ProductTypeDetailed | undefined,
): boolean {
  return !!(productType && 'requiresSubject' in productType && productType.requiresSubject);
}
