import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AssignmentContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { assignmentsActions } from '@scheduler-frontend/data-access-assignment';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { handleEndpointFailure, handleEndpointSuccess } from '@techniek-team/tt-ngrx';
import { catchError, combineLatest, filter, from, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { SlotApi } from './api/slot.api';
import { slotsActions } from './slots.actions';

@Injectable()
export class SlotsEffects {
  private readonly actions$ = inject(Actions);

  private readonly slotApi = inject(SlotApi);

  private readonly store = inject(Store);

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  private readonly modalController = inject(ModalController);

  public listenForSlotCreated = createEffect(() =>
    this.slotApi.slotCreatedEvents().pipe(
      takeUntilDestroyed(),
      map((event) => slotsActions.slotCreated({ slot: event })),
    ),
  );

  public listenForSlotUpdated = createEffect(() =>
    this.slotApi.slotUpdatedEvents().pipe(
      takeUntilDestroyed(),
      map((event) => slotsActions.slotUpdated({ slot: event })),
    ),
  );

  public listenForSlotDeleted = createEffect(() =>
    this.slotApi.slotDeletedEvents().pipe(
      takeUntilDestroyed(),
      map((event) => slotsActions.slotDeleted({ slot: event })),
    ),
  );

  public loadSlots = createEffect(() =>
    this.actions$.pipe(
      ofType(slotsActions.loadSlot, slotsActions.loadSlots),
      switchMap((action) => {
        if (Array.isArray(action.slotId)) {
          return combineLatest(action.slotId.map((slot) => this.slotApi.getSlot(slot))).pipe(
            map((response) => {
              return slotsActions.loadSlotSuccess({
                slots: response,
                setAsSlotList: 'setAsSlotList' in action ? action.setAsSlotList : false,
              });
            }),
            catchError((error) => of(slotsActions.loadSlotFailure({ error: error }))),
          );
        }
        return this.slotApi.getSlot(action.slotId).pipe(
          map((response) =>
            slotsActions.loadSlotSuccess({ slots: [response], setAsSlotList: false }),
          ),
          catchError((error) => of(slotsActions.loadSlotFailure({ error: error }))),
        );
      }),
    ),
  );

  public setAssignmentOnSlotSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(slotsActions.loadSlotsSuccess),
      map((action) => {
        return assignmentsActions.addAssignments({
          assignments: action.slots
            .map((slot) => slot.assignmentHasSlot?.assignment)
            .filter((assignment) => !!assignment) as AssignmentContract[],
        });
      }),
    ),
  );

  public loadSlotFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(slotsActions.loadSlotFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de shift.',
        }),
      ),
    { dispatch: false },
  );

  public createSlot = createEffect(() =>
    this.actions$.pipe(
      ofType(slotsActions.createSlots),
      switchMap((action) =>
        this.slotApi.createSlots(action.request).pipe(
          map((result) =>
            // todo the Create slot endpoint should return they created model.
            slotsActions.createSlotsSuccess({
              slots: [] as SlotDetailedContract[],
            }),
          ),
          catchError((error) => of(slotsActions.createSlotsFailure({ error: error }))),
        ),
      ),
    ),
  );

  public createSlotFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(slotsActions.createSlotsFailure, {
          message: 'Er is iets misgegaan bij het aanmaken van de shifts.',
        }),
      ),
    { dispatch: false },
  );

  public updateSlot = createEffect(() =>
    this.actions$.pipe(
      ofType(slotsActions.updateSlot),
      switchMap((action) =>
        this.slotApi.updateSlot(action.request).pipe(
          map((result) =>
            // todo the Create slot endpoint should return they created model.
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            slotsActions.updateSlotSuccess({ slot: {} as any }),
          ),
          catchError((error) => of(slotsActions.updateSlotFailure({ error: error }))),
        ),
      ),
    ),
  );

  public updateSlotFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(slotsActions.updateSlotFailure, {
          message: 'Er is iets misgegaan bij het bewerken van de shift.',
        }),
      ),
    { dispatch: false },
  );

  public showConfirmationToDeleteSlot = createEffect(() =>
    this.actions$.pipe(
      ofType(slotsActions.showConfirmationToDeleteSlot),
      switchMap((action) => {
        return from(
          this.modalController.create({
            component: TtSimpleModalComponent,
            componentProps: {
              title: 'Shift verwijderen?',
              message: 'Deze shift zal worden verwijderd.',
            },
          }),
        ).pipe(
          switchMap((confirmModal) => confirmModal.present().then(() => confirmModal)),
          switchMap((confirmModal) => confirmModal.onWillDismiss()),
          filter(({ role }) => role === 'confirm'),
          map(() => slotsActions.deleteSlot({ slotId: action.slotId })),
        );
      }),
    ),
  );

  public deleteSlot = createEffect(() =>
    this.actions$.pipe(
      ofType(slotsActions.deleteSlot),
      switchMap((action) =>
        this.slotApi.deleteSlot(action.slotId).pipe(
          map(() => slotsActions.deleteSlotSuccess({ slotId: action.slotId })),
          catchError((error) => of(slotsActions.deleteSlotFailure({ error: error }))),
        ),
      ),
    ),
  );

  public deleteSlotSuccess = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(slotsActions.deleteSlotSuccess, {
          message: 'De shift is verwijderd.',
        }),
      ),
    { dispatch: false },
  );

  public deleteSlotFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(slotsActions.deleteSlotFailure, {
          message: 'Er is iets misgegaan bij het verwijderen van de shift.',
        }),
      ),
    { dispatch: false },
  );
}
