import { Signal } from '@angular/core';
import { SubjectModel } from '@scheduler-frontend/data-access-subjects';
import { LevelEnum } from '@scheduler-frontend/enums';
import { SignalFetch } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { format } from 'date-fns';
import { Lesson } from './lesson.model';

/**
 * The Detailed version of the Lesson Resource.
 *
 * A Lesson isn't the same a lesson should attend to at school. A lesson
 * is a grouping of shift connected to an amount of pupils that are attending
 * the shift on a specific date and most of the time in a specific room. The lesson
 * also holds possibly information about which subject and on what level the lesson is given.
 * (There are services and therefor lesson which aren't about a specific subject
 * for example huiswerkbegeleiding)
 */
export class LessonDetailed extends Lesson {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'LessonDetailed';

  /**
   * The Name of the lesson
   *
   * Lesson has an optional name property where VC most of the time room (or classroom) name.
   * The helps then to identify certain lesson when they manage multiple lesson on the same day
   */
  @Expose() public name?: string;

  /**
   * This is the day on which the lesson is schedule.
   *
   * That information on when a pupil and/of trainer should attend the class can like start and end time can be found
   * in {@see Slot} Model. This date only states that this Lesson is about this date.
   */
  @Type(() => Date)
  @Expose()
  public date!: Date;

  /**
   * The number of pupils attending to this lesson.
   */
  @Expose() public numberOfPupils?: number;

  /**
   * Return true if the lesson should be billed to the customer.
   *
   * In certain cases the customer isn't happy about the given lesson. The VC can
   * when this happens choose to set this property to false. This way the customer isn't
   * billed for the slot on the lesson, but the Candidate are getting paid for the work
   * they have done.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public isBillable: boolean = false;

  /**
   * The possible topic(subject) taught in this lesson.
   */
  @SignalFetch(() => SubjectModel)
  @Expose()
  public subject!: Signal<SubjectModel | undefined>;

  /**
   * The possible level on which the Lesson is given.
   */
  @Expose() public level?: LevelEnum;

  /**
   * Use in the when-control-components to filters result in dropdown.
   */
  public get displayValue(): string {
    if (this.name) {
      return `${this.name} - ${format(this.date, 'dd-MM-yyyy')} - (${
        this.numberOfPupils
      } leerlingen)`;
    }
    return `${format(this.date, 'dd-MM-yyyy')} - (${this.numberOfPupils} leerlingen)`;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.displayValue;
  }
}
