import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LogRecordContract } from '@scheduler-frontend/log-contracts';

export const activeAssignmentHistoryActions = createActionGroup({
  source: '[Assignments/Active/History]',
  events: {
    loadHistory: emptyProps(),
    loadHistorySuccess: props<{
      records: LogRecordContract[];
      chunk: number;
      totalItems: number;
    }>(),
    loadNextChunk: emptyProps(),
    loadNextChunkSuccess: props<{
      records: LogRecordContract[];
      chunk: number;
      totalItems: number;
    }>(),
    loadHistoryFailure: props<{ error: unknown }>(),
  },
});
