import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { TsRangeInterface } from '@techniek-team/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarkAsAbsentApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(assignmentHasSlot: string, period: TsRangeInterface<string>): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/mark-as-absent`;

    return this.httpClient.post<void>(url, {
      assignmentHasSlotId: assignmentHasSlot,
      absencePeriod: {
        start: period.start,
        end: period.end,
        inclusiveStart: period.inclusiveStart,
        inclusiveEnd: period.inclusiveEnd,
      },
    });
  }
}
