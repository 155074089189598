import { createFeatureSelector, createSelector } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { Filter, FilterGroup } from '@techniek-team/search';
import { toDictionary } from '@techniek-team/tt-ngrx';
import { RegionContract } from '../contracts/region.contract';
import { Region } from '../models/region.model';
import { REGIONS_FEATURE_KEY, regionsAdapter, RegionsState } from './regions.reducer';

// Lookup the 'Regions' feature state managed by NgRx
const { selectAll, selectEntities } = regionsAdapter.getSelectors();

export class RegionsSelectors {
  public static readonly selectRegionsState =
    createFeatureSelector<RegionsState>(REGIONS_FEATURE_KEY);

  public static readonly regionsLoading = createSelector(
    RegionsSelectors.selectRegionsState,
    (state: RegionsState) => state.loading,
  );

  public static readonly regionsLoaded = createSelector(
    RegionsSelectors.selectRegionsState,
    (state: RegionsState) => state.loaded,
  );

  public static readonly regionsInitialized = createSelector(
    RegionsSelectors.selectRegionsState,
    (state) => state.loaded || state.loading,
  );

  public static readonly regionsError = createSelector(
    RegionsSelectors.selectRegionsState,
    (state: RegionsState) => state.error,
  );

  public static readonly allRegions = createSelector(
    RegionsSelectors.selectRegionsState,
    (state: RegionsState) => selectAll(state),
  );

  public static readonly allProvinces = createSelector(RegionsSelectors.allRegions, (regions) =>
    regions.filter((region) => region.isProvince),
  );

  public static readonly allClusters = createSelector(RegionsSelectors.allRegions, (regions) =>
    regions.filter((region) => {
      // todo Thijmen is going to expose the type property with which can do a better filtering on cluster
      return region.isProvince === false;
    }),
  );

  public static readonly regionsEntities = createSelector(
    RegionsSelectors.selectRegionsState,
    (state: RegionsState) => selectEntities(state),
  );

  public static readonly denormalizedRegionEntities = createSelector(
    RegionsSelectors.selectRegionsState,
    (state) =>
      toDictionary(denormalize(Region, Object.values(state.entities)), (item) => item.getId()),
  );

  public static readonly count = createSelector(
    RegionsSelectors.allRegions,
    (regions) => regions?.length ?? 0,
  );

  public static readonly provincesFilterGroupKey = 'address.province';

  public static readonly provincesFilterGroup = createSelector(
    RegionsSelectors.allProvinces,
    (region) => {
      return new FilterGroup(
        RegionsSelectors.provincesFilterGroupKey,
        (region ?? []).map(
          (item) =>
            new Filter(item['@id'] as string, {
              label: item.name,
              additionalData: item,
            }),
        ),
        { multiple: true, label: 'Provincies', itemLabel: 'Provincie' },
      );
    },
  );

  public static readonly searchQueryMapper = createSelector(
    RegionsSelectors.regionsEntities,
    (dict) => {
      return {
        'address.province': {
          entities: dict,
          getDisplayText: (item: RegionContract) => item.name,
        },
      };
    },
  );
}
