import { Person } from '@scheduler-frontend/data-access-users';
import { JsonLd } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { ApproachEventType } from '../enums/approach-event.type.enum';

export class ApproachEvent extends JsonLd {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'ApproachEvent';

  @Type(() => Date)
  @Expose()
  public createdAt!: Date;

  @Expose() public types!: ApproachEventType[];

  @Type(() => Person)
  @Expose()
  public person!: Person;
}
