export enum ApproachState {
  TODO = 'TODO',
  WAITING = 'WAITING',
  HANDLED = 'HANDLED',
  EXPIRED = 'EXPIRED',
  ABORTED = 'ABORTED',
}

export enum ApproachStateDisplayValues {
  TODO = 'to-do',
  WAITING = 'in afwachting',
  HANDLED = 'afgerond',
  EXPIRED = 'verlopen',
  ABORTED = 'afgebroken',
}

export enum ApproachStateColorValues {
  TODO = 'success',
  WAITING = 'danger',
  HANDLED = 'primary',
  EXPIRED = 'medium',
  ABORTED = 'medium',
}
