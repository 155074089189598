import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserSearchContract } from '../../contract/user-search.contract';

export const userSearchActions = createActionGroup({
  source: '[Scheduling/userSearch]',
  events: {
    addUserSearchHash: props<{ search: Partial<UserSearchContract> }>(),
    addUserSearchHashFailure: props<{ error: unknown }>(),

    /**
     * Loads the recent searches.
     */
    loadRecentSearches: emptyProps(),
    loadRecentSearchesSuccess: props<{ searches: UserSearchContract[] }>(),
    loadRecentSearchesFailure: props<{ error: unknown }>(),
    /**
     * Loads favorite searches.
     */
    loadFavoriteSearches: emptyProps(),
    loadFavoriteSearchesSuccess: props<{ searches: UserSearchContract[] }>(),
    loadFavoriteSearchesFailure: props<{ error: unknown }>(),
    /**
     * toggle a recent search to favorite search
     */
    toggleFavorite: props<{ favorite: string }>(),
    toggleFavoriteSuccess: props<{ favorite: UserSearchContract }>(),
    toggleFavoriteFailure: props<{ error: unknown }>(),
    /**
     * this action is trigger on a user initiated search request. This request wil later be modified
     * to only contain the range in view. rather than the insane amount of slots.
     */
    setCurrentUserSearch: props<{ searchId: string }>(),

    clearCurrentUserSearch: emptyProps(),
  },
});
