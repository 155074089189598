import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection, Resource } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import {
  BusinessServiceContract,
  BusinessServiceDetailedContract,
} from '../../contracts/business-service.contract';

@Injectable({
  providedIn: 'root',
})
export class BusinessServiceApi {
  private readonly httpClient = inject(HttpClient);

  /**
   * Gets a sorted (by name ascending) list of all business services.
   */
  public getBusinessServices(): Observable<Collection<BusinessServiceContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/business_services?includeArchived=true`;
    return this.httpClient.get<Collection<BusinessServiceContract>>(url);
  }

  /**
   * Gets a specific business service (detailed version).
   */
  public getBusinessService(
    businessService: string,
  ): Observable<Resource<BusinessServiceDetailedContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/business_services/${businessService}`;

    return this.httpClient.get<Resource<BusinessServiceDetailedContract>>(url);
  }
}
