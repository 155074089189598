import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { RolesEffects } from './+state/roles.effects';
import { ROLES_FEATURE_KEY, rolesReducer } from './+state/roles.reducer';
import { RolesStoreService } from './roles-store.service';

export function provideRoleStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: ROLES_FEATURE_KEY, reducer: rolesReducer }),
    provideEffects(RolesEffects),
    RolesStoreService,
  ];
}
