import { Collection } from '@techniek-team/api-platform';
import { plainToInstance, plainToClassFromExist } from 'class-transformer';
import { ClassConstructor, ClassTransformOptions } from 'class-transformer/types/interfaces';
import { Hydra } from '../models/hydra.model';
import { JsonLd } from '../models/json-ld.model';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function denormalize<T extends JsonLd, V extends Collection<any>>(
  cls: ClassConstructor<T>,
  plain: V,
  options?: ClassTransformOptions
): Hydra<T>;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function denormalize<T extends Record<string, any>, V extends any[]>(
  cls: ClassConstructor<T>,
  plain: V,
  options?: ClassTransformOptions
): T[];

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function denormalize<T extends Record<string, any>, V>(
  cls: ClassConstructor<T>,
  plain: V,
  options?: ClassTransformOptions
): T;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function denormalize<T extends Record<string, any>, V>(
  cls: ClassConstructor<T>,
  plain: V | V[],
  options?: ClassTransformOptions,
): T | T[] | Hydra<T & JsonLd> {
  // Check if plain is Hydra.
  let model: T | T[] | Hydra<T & JsonLd>;
  if (Object.prototype.hasOwnProperty.call(plain, 'hydra:member')) {
    model = plainToClassFromExist(
      new Hydra<T & JsonLd>(cls),
      plain,
      {
        excludeExtraneousValues: true,
        ...options,
      },
    );
  } else {
    model = plainToInstance(cls, plain, {
      excludeExtraneousValues: true,
      ...options,
    });
  }

  return model;
}
