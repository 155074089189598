import { Expose } from 'class-transformer';

/**
 * @deprecated
 */
export class SlotAvailability {
  /**
   * this time available in minutes
   */
  @Expose() public available!: number;

  /**
   * this time unavailable in minutes
   */
  @Expose() public unavailable!: number;

  /**
   * the overlapping minutes with other assignments of the candidate
   */
  @Expose() public conflict!: number;

  /**
   * the total time of the slot in minutes
   */
  @Expose() public total!: number;
}
