import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';

/**
 * The Minimal version of the Candidate resource from Scheduler-api
 */
export class Person extends JsonLd {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Person';

  /**
   * The full name of the candidate
   */
  @Expose() public fullName!: string;

  /**
   * Avatar of the person
   */
  @Expose() public pictureUrl!: string;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.fullName;
  }
}
