import { createRangeFromDuration, parseISODuration } from '@scheduler-frontend/common';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { addDays, startOfISOWeek, sub } from 'date-fns';
import { RoutesView } from './scheduling-views-router.selectors';

const tableViewRangeInView = 'infinite';

const weekViewRangeInView = 'P1W';

const dayViewRangeInView = 'P1D';

export function tableViewDateRangeInView(date: Date | string) {
  return createRangeFromDuration(date, tableViewRangeInView).toObject();
}

export function weekViewDateRangeInView(date: Date | string) {
  return createRangeFromDuration(
    startOfISOWeek(date),
    parseISODuration(weekViewRangeInView),
  ).toObject() as PlainRangeInterface<string>;
}

export function dayViewDateRangeInView(date: Date | string) {
  return createRangeFromDuration(
    date,
    parseISODuration(dayViewRangeInView),
  ).toObject() as PlainRangeInterface<string>;
}

export function rangeInView(
  date: Date | string,
  view: RoutesView,
): PlainRangeInterface<string> | 'infinite' {
  switch (view) {
    case RoutesView.WEEK:
      return weekViewDateRangeInView(date);
    case RoutesView.DAY:
      return dayViewDateRangeInView(date);
    case RoutesView.TABLE:
      return 'infinite';
    default:
      throw new Error('invalid view given');
  }
}

export function nextRangeInView(
  date: Date | string | 'infinite',
  view: RoutesView,
): PlainRangeInterface<string> | 'infinite' {
  switch (view) {
    case RoutesView.WEEK:
      return weekViewDateRangeInView(addDays(date, 1));
    case RoutesView.DAY:
      return dayViewDateRangeInView(addDays(date, 1));
    case RoutesView.TABLE:
      return 'infinite';
    default:
      throw new Error('invalid view given');
  }
}

export function previousRangeInView(
  date: Date | string | 'infinite',
  view: RoutesView,
): PlainRangeInterface<string> | 'infinite' {
  switch (view) {
    case RoutesView.WEEK:
      return weekViewDateRangeInView(sub(date, parseISODuration(weekViewRangeInView)));
    case RoutesView.DAY:
      return dayViewDateRangeInView(sub(date, parseISODuration(dayViewRangeInView)));
    case RoutesView.TABLE:
      return 'infinite';
    default:
      throw new Error('invalid view given');
  }
}
