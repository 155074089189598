import { Dictionary } from '@ngrx/entity/src/models';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { hashParams } from '@scheduler-frontend/common';
import { ScheduleMinimalContract } from '@scheduler-frontend/schedule-contracts';
import { NonNullableDictionary } from '@techniek-team/tt-ngrx';
import { memoize, range } from 'lodash-es';
import { ScheduleGroup } from '../+state/selectors/scheduling-week-view.selectors';

export interface ScheduleGrid {
  slots: GridSlot[];
  schedule: ScheduleMinimalContract;
  headerGridColumn: string;
  headerGridRow: string;
  gridRowEnd: number;
}

export interface GridSlot {
  slot: SlotDetailedContract;
  gridColumn: string;
  gridRow: string;
}

const parseScheduleDays = memoize(
  (
    daysInSchedule: number[],
  ): {
    earliestDayInSchedule: number;
    latestDayInSchedule: number;
    daysSpanInSchedule: number[];
  } => {
    const earliestDayInSchedule = Math.min(...daysInSchedule);
    const latestDayInSchedule = Math.max(...daysInSchedule);
    const daysSpanInSchedule: number[] =
      earliestDayInSchedule === latestDayInSchedule
        ? [earliestDayInSchedule]
        : range(earliestDayInSchedule, latestDayInSchedule + 1);
    return {
      earliestDayInSchedule: earliestDayInSchedule,
      latestDayInSchedule: latestDayInSchedule,
      daysSpanInSchedule: daysSpanInSchedule,
    };
  },
  hashParams,
);

const getMaxRow = memoize(
  (daysSpanInSchedule: number[], countPerISODateNumber: NonNullableDictionary<number>): number => {
    return Math.max(...daysSpanInSchedule.map((item) => countPerISODateNumber[item]));
  },
  hashParams,
);

/**
 * A memoized function to create schedules in a grid layout.
 *
 * It receives an array of nested `scheduleGroups` and `countPerISODateNumber` object as arguments,
 * and returns an array of `gridSchedules` objects.
 *
 * The function iterates through the `scheduleGroups` generating grid schedules and
 * manages the number of schedules for each specific day utilizing `countPerISODateNumber` param.
 *
 * @param scheduleGroups - The schedule groups to create grid schedules from.
 * @param countPerISODateNumber - The count per ISO date number.
 * @returns {ScheduleGrid[]} - The created grid schedules.
 *
 * @example
 * const scheduleGroups = [
 *   [
 *     { slots: { "1": slotContract1, "2": slotContract2 }, totalRow: 2, schedule: scheduleContract1 },
 *     { slots: { "3": slotContract3, "4": slotContract4 }, totalRow: 2, schedule: scheduleContract1 }
 *   ],
 *   [
 *     { slots: { "5": slotContract5, "6": slotContract6 }, totalRow: 3, schedule: scheduleContract2 },
 *     { slots: { "7": slotContract7, "8": slotContract8 }, totalRow: 4, schedule: scheduleContract2 }
 *   ]
 * ];
 * const countPerISODateNumber = { "1": 2, "2": 2, "3": 2, "4": 2, "5": 2, "6": 2, "7": 2 };
 *
 * const gridSchedules = createGridSchedules(scheduleGroups, countPerISODateNumber);
 *
 * // gridSchedules[0] will look something like this:
 * {
 *   slots: [{ slot: slotModel1, gridColumn: "day1", gridRow: "4" }],
 *   headerGridRow: "3",
 *   headerGridColumn: "day1 / day2",
 *   schedule: scheduleModel1,
 *   gridRowEnd: 5
 * },
 * {
 *   slots: [{ slot: slotModel2, gridColumn: "day2", gridRow: "4" }],
 *   headerGridRow: "3",
 *   headerGridColumn: "day1 / day2",
 *   schedule: scheduleModel1,
 *   gridRowEnd: 5
 * }
 */
export const createGridSchedules = memoize(
  (
    scheduleGroups: ScheduleGroup[][],
    countPerISODateNumber: NonNullableDictionary<number>,
  ): ScheduleGrid[] => {
    const gridSchedules: ScheduleGrid[] = [];
    for (const row of scheduleGroups) {
      gridSchedules.push(
        ...Object.values(row).map((scheduleGroup: ScheduleGroup) => {
          const daysInSchedule: number[] = Object.keys(scheduleGroup.slots).map((day) =>
            parseInt(day, 10),
          );
          const { earliestDayInSchedule, latestDayInSchedule, daysSpanInSchedule } =
            parseScheduleDays(daysInSchedule);

          const gridHeaderRow: number = getMaxRow(daysSpanInSchedule, countPerISODateNumber);

          let gridHeaderColumn: string = `day${earliestDayInSchedule}`;
          if (daysInSchedule.length > 1) {
            gridHeaderColumn = `${gridHeaderColumn} / day${latestDayInSchedule}`;
          }

          for (let day of daysSpanInSchedule) {
            countPerISODateNumber[day] = gridHeaderRow + 1;
          }
          const slotGrid = createGridSlots(scheduleGroup.slots, countPerISODateNumber);
          const maxRows = getMaxRow(daysSpanInSchedule, countPerISODateNumber);
          for (let day of daysSpanInSchedule) {
            countPerISODateNumber[day] = maxRows;
          }

          return {
            slots: slotGrid,
            headerGridRow: gridHeaderRow.toString(),
            headerGridColumn: gridHeaderColumn,
            schedule: scheduleGroup.schedule,
            gridRowEnd: gridHeaderRow + scheduleGroup.totalRow + 1, // the +1 is to add a little padding beneath
          };
        }),
      );
    }

    return gridSchedules;
  },
  hashParams,
);

/**
 * A memoized function to transform slots data for fitting into a CSS grid structure.
 *
 * It receives a `slots` map and `countPerISODateNumber` object as arguments,
 * and returns an array of `GridSlot` objects.
 *
 * The function iterates through `slots` to create a `GridSlot` for each entry
 * and manage the position (row number) for a particular slot with `countPerISODateNumber`.
 *
 * @param slots - An object where keys are days and values are array of either `SlotContract` or `SlotDetailedContract`
 *   elements.
 * @param countPerISODateNumber - An object pairing date in ISO number format as keys to count number as values.
 * @returns {GridSlot[]} - Returns an array of GridSlot objects.
 *
 * @example
 * const slots = {
 *   "1": [slotContract1, slotDetailedContract1],
 *   "2": [slotContract2, slotDetailedContract2]
 * };
 * const countPerISODateNumber = { "1": 0, "2": 0 };
 *
 * const gridSlots = createGridSlots(slots, countPerISODateNumber);
 *
 * // gridSlots[0] will look something like this:
 * {
 *   slot: SlotModel1,
 *   gridColumn: "day1",
 *   gridRow: "0"
 * }
 */
export const createGridSlots = (
  slots: Dictionary<(SlotContract | SlotDetailedContract)[]>,
  countPerISODateNumber: Dictionary<number>,
): GridSlot[] => {
  return Object.entries(slots).flatMap(([day, items]) => {
    if (!items || items.length <= 0) {
      return [];
    }

    return items.map((slot, _index) => {
      const gridSlot: GridSlot = {
        slot: slot as SlotDetailedContract,
        gridColumn: `day${day}`,
        gridRow: (countPerISODateNumber[day] as number).toString(),
      };
      countPerISODateNumber[day] = (countPerISODateNumber[day] as number) + 1;
      return gridSlot;
    });
  });
};
