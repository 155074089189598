export enum AvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  VACATION = 'VACATION',
  LONG_TERM_UNAVAILABLE = 'LONG_TERM_UNAVAILABLE',
  UNKNOWN = 'UNKNOWN',
}

export enum AvailabilityStatusDisplayValues {
  AVAILABLE = 'Beschikbaar',
  UNAVAILABLE = 'Onbeschikbaar',
  VACATION = 'Vakantie',
  LONG_TERM_UNAVAILABLE = 'Langdurig Onbeschikbaar',
}
