import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import {
  ProductTypeContract,
  ProductTypeDetailedContract,
} from '../contracts/product-type.contract';
import { productTypesActions } from './product-types.actions';

export const PRODUCT_TYPES_FEATURE_KEY = 'productTypes';

export interface ProductTypesState
  extends EntityState<ProductTypeContract | ProductTypeDetailedContract> {
  loaded: boolean;
  loading: boolean;
  error?: string | null;
  totalItems: number | null;
  loadingDetailed: boolean;
  cacheTimeStamp?: string;
}

export const productTypesAdapter: EntityAdapter<ProductTypeContract | ProductTypeDetailedContract> =
  createEntityAdapter<ProductTypeContract | ProductTypeDetailedContract>({
    selectId: jsonLdSelectId,
  });

export const initialProductTypesState: ProductTypesState = productTypesAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
  totalItems: null,
  loadingDetailed: false,
});

const reducer = createReducer(
  initialProductTypesState,
  on(productTypesActions.initProductTypes, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(productTypesActions.reloadProductTypes, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(productTypesActions.loadProductTypesSuccess, (state, { productTypes, totalItems }) =>
    productTypesAdapter.setAll(productTypes, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: formatISO(new Date()),
    }),
  ),
  on(
    productTypesActions.loadFromCacheProductTypesSuccess,
    (state, { productTypes, totalItems, cacheTimestamp }) =>
      productTypesAdapter.setAll(productTypes, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: cacheTimestamp,
      }),
  ),
  on(productTypesActions.loadProductTypesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  /** Detailed business services **/
  on(productTypesActions.loadDetailedProductType, (state) => ({
    ...state,
    loadingDetailed: true,
    error: null,
  })),
  on(productTypesActions.loadDetailedProductTypeSuccess, (state, { detailedProductType }) =>
    productTypesAdapter.upsertOne(detailedProductType, {
      ...state,
      loadingDetailed: false,
    }),
  ),
  on(productTypesActions.loadDetailedProductTypeFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function productTypesReducer(
  state: ProductTypesState | undefined,
  action: Action,
): ProductTypesState {
  return reducer(state, action);
}
