/* eslint-disable @typescript-eslint/no-explicit-any */
import { OperatorFunction, pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Checks if value is defined (!== undefined, !== null).
 */
export function isDefinedValidator<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

//eslint-disable-next-line max-len
export type DefinedObject<T, K extends keyof T> = Omit<T, K> &
  Pick<{ [P in keyof T]-?: NonNullable<T[P]> }, K> & {};
export type DefinedArrayItems<T, K extends keyof T> = Omit<T, K> &
  Pick<{ [P in keyof T]-?: NonNullable<T[P]> }, K> &
  any[];
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DefinedStream<T, K extends keyof T> = T extends any[]
  ? DefinedArrayItems<T, K>
  : T extends object
    ? DefinedObject<T, K>
    : NonNullable<T>;

/**
 * Filter items emitted by the source Observable by only emitting those that
 * are defined. Meaning that all value that aren't null or undefined are emitted.
 *
 * It's also possible to uses this method in conjuction with {@see CombineLatest }
 * by giving an array of the index numbers to check
 *
 * @example
 * ```typescript
 *  const a = of('leead').pipe(
 *    isDefined(),
 *  );
 *
 *  const a = combineLatest([
 *   of(undefined),
 *   of(42),
 *   of('leead'),
 *  ]).pipe(
 *    isDefined([1, 2]),
 *  );
 *
 *  const a = of({ boe: 1, test: 3 }).pipe(
 *    isDefined(['test']),
 *  );
 *
 *  // this will stil emit because isDefined will only check the indexes 1 and 2
 * ```
 */
export function isDefined<T, K extends keyof T = any>(
  paramsToCheck: K[],
): OperatorFunction<T | undefined | null, DefinedStream<T, K>>;

export function isDefined<T, K extends keyof T = any>(
  paramsToCheck?: undefined,
): OperatorFunction<T | undefined | null, T extends any[] ? T : NonNullable<T>>;

export function isDefined<T, K extends keyof T = any>(
  paramsToCheck?: K[],
): OperatorFunction<T | undefined | null, DefinedStream<T, K>> {
  return pipe(
    filter((param: T) => {
      if (param && (Array.isArray(param) || typeof param === 'object') && paramsToCheck) {
        for (let paramToCheck of paramsToCheck) {
          if (!isDefinedValidator(param[paramToCheck])) {
            return false;
          }
        }
        return true;
      }
      return isDefinedValidator(param);
    }),
  ) as OperatorFunction<T | undefined | null, DefinedStream<T, K>>;
}
