import { transition } from '@angular/animations';
import { inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AssignmentTransitionMessagesModalComponent } from '@scheduler-frontend/assignment-transition-messages-modal';
import { AllTransitionEnum, TransitionEnum } from '@scheduler-frontend/data-access-assignment';
import {
  AvailabilityStatisticsSelectors,
  createAvailabilityStatisticsId,
} from '@scheduler-frontend/data-access-availability-statistics';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import { CandidateMinimalContract } from '@scheduler-frontend/data-access-users';
import { TtSimpleModalButton, TtSimpleModalComponent } from '@techniek-team/components/modal';
import { IonColorType } from '@techniek-team/lyceo-style';
import { isDefined } from '@techniek-team/rxjs';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import { some } from 'lodash-es';
import { catchError, forkJoin, from, map, of, switchMap } from 'rxjs';
import { isSingleInfoOrSuccess } from '../../functions/is-single-info-or-success-tansition-message.function';
import { schedulingAssignToSlotsActions } from '../actions/scheduling-assign-to-slots.actions';
import { AssignApi } from '../api/assign.api';
import { TransitionApi } from '../api/transition.api';
import { SchedulingSelectors } from '../selectors/scheduling.selectors';

export class SchedulingAssignToSlotsEffect {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly assignApi = inject(AssignApi);

  private readonly transitionApi = inject(TransitionApi);

  private readonly modalController = inject(ModalController);

  private readonly askForDirectStateChangeAfterAssignmentButtons: TtSimpleModalButton[] = [
    {
      text: 'CONCEPT',
      color: IonColorType.PRIMARY,
      handler: (): AllTransitionEnum => AllTransitionEnum.DRAFT,
    },
    {
      text: 'VRAAG BEVESTIGING',
      color: IonColorType.PRIMARY,
      handler: (): AllTransitionEnum => AllTransitionEnum.REQUEST_CONFIRMATION,
    },
    {
      text: 'DIRECT BEVESTIGEN',
      color: IonColorType.PRIMARY,
      handler: (): AllTransitionEnum => AllTransitionEnum.CONFIRM,
    },
  ];

  public readonly assignCandidatesToSlotsStart = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingAssignToSlotsActions.assignCandidate),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.selectedCandidateIds),
        this.store.select(SchedulingSelectors.selectedSlotIds),
        this.store.select(AvailabilityStatisticsSelectors.availabilityStatisticEntities),
      ]),
      map(([action, selectedCandidates, selectedSlots, availabilityStatistics]) => {
        if (
          some(selectedCandidates, (candidate) => {
            let availabilityStatistic =
              availabilityStatistics[createAvailabilityStatisticsId(candidate, selectedSlots)];
            return (availabilityStatistic?.unavailableMinutes ?? 0) > 0;
          })
        ) {
          return schedulingAssignToSlotsActions.requestConfirmationOnAssigningPartialAvailableCandidate(
            {
              directlyConfirm: action.directlyConfirm,
              resolveConflicts: action.resolveConflicts,
            },
          );
        }
        return schedulingAssignToSlotsActions.assignCandidateSubmit({
          directlyConfirm: action.directlyConfirm,
          resolveConflicts: action.resolveConflicts,
        });
      }),
    ),
  );

  public readonly requestConfirmationOnPartialAvailable = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulingAssignToSlotsActions.requestConfirmationOnAssigningPartialAvailableCandidate,
      ),
      switchMap(({ directlyConfirm, resolveConflicts }) => {
        return from(
          this.modalController.create({
            component: TtSimpleModalComponent,
            componentProps: {
              title: 'Begeleider is niet beschikbaar',
              //todo multiple candidates,
              message:
                'Weet je zeker dat je deze begeleider wilt inplannen? Hij/zij heeft namelijk aangegeven dat hij/zij deels of helemaal niet beschikbaar is voor deze shift.',
              buttons: [
                {
                  text: 'TOCH INPLANNEN',
                  role: 'confirm',
                },
                {
                  text: 'Sluiten',
                  fill: 'outline',
                  role: 'cancel',
                },
              ],
            },
          }),
        ).pipe(
          switchMap((modal) => modal.present().then(() => modal)),
          switchMap((modal) => modal.onWillDismiss()),
          map(({ role }) => {
            if (role === 'confirm') {
              return schedulingAssignToSlotsActions.assignCandidateSubmit({
                directlyConfirm: directlyConfirm,
                resolveConflicts: resolveConflicts,
              });
            }
            return schedulingAssignToSlotsActions.assignCandidateCancel();
          }),
        );
      }),
    ),
  );

  public readonly assignCandidatesToSlotsSubmit = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingAssignToSlotsActions.assignCandidateSubmit),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.selectedCandidates),
        this.store.select(SchedulingSelectors.selectedSlotIds),
      ]),
      switchMap(([action, candidates, slotIds]) =>
        this.assignApi
          .execute({
            candidate: jsonLdSelectId(candidates[0]?.['@id'] as string),
            slots: slotIds,
            resolveConflicts: action.resolveConflicts,
          })
          .pipe(
            map((response) => {
              if (action.directlyConfirm) {
                return schedulingAssignToSlotsActions.confirmCandidateAssignmentOnAssign({
                  assignmentIds: response,
                  candidate: candidates[0] as CandidateMinimalContract,
                });
              }
              if (candidates.length === 1) {
                return schedulingAssignToSlotsActions.askForDirectStateChangeAfterAssignment({
                  assignmentIds: response,
                });
              }
              return schedulingAssignToSlotsActions.assignCandidateSuccess({
                message: 'De begeleiders zijn successvol benaderd',
              });
            }),
            catchError((error) =>
              of(schedulingAssignToSlotsActions.assignCandidateFailure({ error: error })),
            ),
          ),
      ),
    ),
  );

  public readonly askForDirectStateChangeAfterAssignment = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingAssignToSlotsActions.askForDirectStateChangeAfterAssignment),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.selectedSlots),
        this.store.select(SchedulingSelectors.selectedCandidates).pipe(
          map((candidates) => candidates[0]),
          isDefined(),
        ),
      ]),
      switchMap(([action, selectedSlots, selectedCandidate]) => {
        let slotPluralized: string = selectedSlots.length === 1 ? 'shift' : 'shifts';
        let assignmentPluralized: string =
          action.assignmentIds.length === 1 ? 'opdracht' : 'opdrachten';

        return from(
          this.modalController.create({
            component: TtSimpleModalComponent,
            componentProps: {
              title: 'Bevestiging vragen',
              message: `${selectedCandidate.fullName} is als CONCEPT toegekend aan de ${slotPluralized} in ${action.assignmentIds.length}
                    ${assignmentPluralized}. Wil je de begeleider om bevestiging vragen?`,
              buttons: this.askForDirectStateChangeAfterAssignmentButtons,
            },
          }),
        ).pipe(
          switchMap((modal) => modal.present().then(() => modal)),
          switchMap((modal) => modal.onWillDismiss<AllTransitionEnum | TransitionEnum>()),
          map(({ data }) => {
            if (!data || data === AllTransitionEnum.DRAFT) {
              return schedulingAssignToSlotsActions.assignCandidateSuccess({
                message: `${selectedCandidate.fullName} is als CONCEPT toegekend aan de ${slotPluralized}. Verander deze status door de ${slotPluralized} te bewerken.`,
              });
            }
            return schedulingAssignToSlotsActions.transitionAfterAssignCandidate({
              transitionTo: data as TransitionEnum,
              assignmentIds: action.assignmentIds,
            });
          }),
        );
      }),
    ),
  );

  public readonly transitionAfterAssignCandidate = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingAssignToSlotsActions.transitionAfterAssignCandidate),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.selectedSlots),
        this.store.select(SchedulingSelectors.selectedCandidates).pipe(
          map((candidates) => candidates[0]),
          isDefined(),
        ),
      ]),
      switchMap(([action, selectedSlots, candidate]) => {
        const applyTransitionRequests = action.assignmentIds.map((assignment) => {
          return this.transitionApi.execute(assignment, action.transitionTo);
        });

        return forkJoin(applyTransitionRequests).pipe(
          map((responses) => {
            const messages = responses.map((response) => response.messages).flat(1);
            if (messages.length === 0) {
              return schedulingAssignToSlotsActions.assignCandidateSuccess({
                message: `${candidate.fullName} is succesvol ingepland.`,
              });
            }

            if (isSingleInfoOrSuccess(messages)) {
              return schedulingAssignToSlotsActions.assignCandidateSuccess({
                message: messages[0].text,
              });
            }
            //todo see transition service on which message to show
            return schedulingAssignToSlotsActions.showTransitionMessageModal({
              messages: messages,
            });
          }),
          catchError((error) =>
            of(schedulingAssignToSlotsActions.assignCandidateFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  public readonly showTransitionMessageModal = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingAssignToSlotsActions.showTransitionMessageModal),
      concatLatestFrom(() => [
        this.store.select(SchedulingSelectors.selectedCandidates).pipe(
          map((candidates) => candidates[0]),
          isDefined(),
        ),
      ]),
      switchMap(([action, selectedCandidate]) => {
        return from(
          this.modalController.create({
            component: AssignmentTransitionMessagesModalComponent,
            componentProps: { transition: transition },
          }),
        ).pipe(
          switchMap((modal) => modal.present().then(() => modal)),
          switchMap((modal) => modal.onWillDismiss<AllTransitionEnum | TransitionEnum>()),
          map(() =>
            schedulingAssignToSlotsActions.assignCandidateSuccess({
              message: `${selectedCandidate.fullName} is succesvol ingepland.`,
            }),
          ),
        );
      }),
    ),
  );

  public afterAssignDirectlyTransitionTheAssignmentToConfirm = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingAssignToSlotsActions.confirmCandidateAssignmentOnAssign),
      switchMap((action) => {
        const applyTransitionRequests = action.assignmentIds.map((assignment) => {
          return this.transitionApi.execute(assignment, TransitionEnum.CONFIRM);
        });

        return forkJoin(applyTransitionRequests).pipe(
          map(() => {
            return schedulingAssignToSlotsActions.assignCandidateSuccess({
              message: `${action.candidate.fullName} is succesvol ingepland.`,
            });
          }),
          catchError((error) =>
            of(schedulingAssignToSlotsActions.assignCandidateFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  public createAssignCandidatesToSlotsFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(schedulingAssignToSlotsActions.assignCandidateFailure, {
          message: 'Er is iets misgegaan bij het inplannen van de shift(s) voor de begeleider.',
        }),
      ),
    { dispatch: false },
  );

  public createAssignCandidatesToSlotsSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(schedulingAssignToSlotsActions.assignCandidateSuccess, {
          message: (action) => action.message,
        }),
      ),
    { dispatch: false },
  );

  public clearActiveCandidateOnSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(schedulingAssignToSlotsActions.assignCandidateSuccess),
        map(() => candidatesActions.clearActiveCandidate()),
      ),
    { dispatch: false },
  );
}
