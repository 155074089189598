import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { actionsActions } from './+state/actions.actions';
import { ActionsSelectors } from './+state/actions.selectors';
import { ActionContract } from './contracts/action.contract';

@Injectable()
export class ActionsStoreService {
  private readonly store = inject(Store);

  public readonly lastChunkLoaded = this.store.selectSignal(ActionsSelectors.lastChunkLoaded);

  public readonly totalItems = this.store.selectSignal(ActionsSelectors.totalItems);

  public readonly loading = this.store.selectSignal(ActionsSelectors.loading);

  public readonly loaded = this.store.selectSignal(ActionsSelectors.loaded);

  public readonly savingDeletions = this.store.selectSignal(ActionsSelectors.savingDeletions);

  public readonly actions = this.store.selectSignal(ActionsSelectors.actions);

  public refresh(): void {
    this.store.dispatch(actionsActions.refresh());
  }

  public nextChunk(): void {
    this.store.dispatch(actionsActions.loadActionsNextChunk());
  }

  public deleteAction(action: string | ActionContract): void {
    this.store.dispatch(actionsActions.deleteAction({ id: jsonLdSelectId(action) }));
  }
}
