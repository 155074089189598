import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { LessonContract } from '@scheduler-frontend/assignment-contracts';
import { absenceActions } from '@scheduler-frontend/data-access-absence';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { isDefined } from '@techniek-team/rxjs';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import { format } from 'date-fns';
import { catchError, exhaustMap, filter, from, map, of, switchMap } from 'rxjs';
import { activeAssignmentActions } from '../actions/active-assignment.actions';
import { assignmentsActions } from '../actions/assignments.actions';
import { AssignmentApi } from '../api/assignment/assignment.api';
import { LessonApi } from '../api/lesson.api';
import { ActiveAssignmentSelectors } from '../selectors/active-assignment.selectors';
import { AssignmentsSelectors } from '../selectors/assignments.selectors';

@Injectable()
export class ActiveAssignmentEffects {
  private readonly actions$ = inject(Actions);

  private readonly modalController = inject(ModalController);

  private readonly assignmentApi = inject(AssignmentApi);

  private readonly lessonApi = inject(LessonApi);

  private readonly store = inject(Store);

  public readonly setActiveAssignmentOnOpeningAssignmentModal = createEffect(() =>
    this.actions$.pipe(
      ofType(assignmentsActions.openAssignmentModal),
      map((action) =>
        activeAssignmentActions.setActiveAssignment({
          selectedId: action.assignment,
        }),
      ),
    ),
  );

  public readonly setIsUrgent = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.setIsUrgent),
      concatLatestFrom(() =>
        this.store.pipe(select(AssignmentsSelectors.activeAssignment), isDefined()),
      ),
      exhaustMap(([action, assignment]) => {
        return this.assignmentApi
          .markAsUrgent({
            assignmentId: jsonLdSelectId(assignment['@id']),
            isUrgent: action.isUrgent,
          })
          .pipe(
            map(() => activeAssignmentActions.setIsUrgentSuccess(action)),
            catchError((error) => of(activeAssignmentActions.setIsUrgentFailure({ error: error }))),
          );
      }),
    ),
  );

  public readonly setIsUrgentFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(activeAssignmentActions.setIsUrgentFailure, {
          message: 'Er is iets misgegaan bij het bijwerken van de urgentiestatus.',
        }),
      ),
    { dispatch: false },
  );

  public readonly setIsUrgentSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(activeAssignmentActions.setIsUrgentSuccess, {
          message: 'Urgentiestatus is opgeslagen.',
        }),
      ),
    { dispatch: false },
  );

  public readonly setDescription = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.setDescription),
      concatLatestFrom(() =>
        this.store.pipe(select(AssignmentsSelectors.activeAssignment), isDefined()),
      ),
      switchMap(([action, assignment]) =>
        this.assignmentApi
          .editDescription({
            assignment: jsonLdSelectId(assignment['@id']),
            description: action.description,
          })
          .pipe(
            map(() => activeAssignmentActions.setDescriptionSuccess(action)),
            catchError((error) =>
              of(activeAssignmentActions.setDescriptionFailure({ error: error })),
            ),
          ),
      ),
    ),
  );

  public readonly setDescriptionFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(activeAssignmentActions.setDescriptionFailure, {
          message: 'Opslaan van omschrijving is mislukt!',
        }),
      ),
    { dispatch: false },
  );

  public setDescriptionSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(activeAssignmentActions.setDescriptionSuccess, {
          message: 'Omschrijving is bijgewerkt!',
        }),
      ),
    { dispatch: false },
  );

  public readonly setSelfAssignmentSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.setSelfAssignment),
      concatLatestFrom(() =>
        this.store.pipe(select(AssignmentsSelectors.activeAssignmentId), isDefined()),
      ),
      switchMap(([action, assignment]) =>
        this.assignmentApi
          .updateSelfAssignSettings({
            assignment: assignment,
            selfAssign: {
              selfAssignable: action.selfAssignable,
              allowSelfAssignWhenNew: action.allowSelfAssignWhenNew,
              minimalGradeSelfAssign: action.minimalGradeSelfAssign ?? undefined,
              maxTravelDistanceSelfAssign: action.maxTravelDistanceSelfAssign ?? undefined,
            },
          })
          .pipe(
            map(() => activeAssignmentActions.setSelfAssignmentSuccess(action)),
            catchError((error) => {
              return of(
                activeAssignmentActions.setSelfAssignmentFailure({
                  error: error,
                }),
              );
            }),
          ),
      ),
    ),
  );

  public readonly setSelfAssignmentSettingsFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(activeAssignmentActions.setSelfAssignmentFailure, {
          message: 'Er is iets misgegaan bij het bijwerken van de zelf inplan data.',
        }),
      ),
    { dispatch: false },
  );

  public readonly setSelfAssignmentSettingsSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(activeAssignmentActions.setSelfAssignmentSuccess, {
          message: 'Zelf inplan data opgeslagen.',
        }),
      ),
    { dispatch: false },
  );

  //eslint-disable-next-line max-lines-per-function
  public readonly updateActualWorkingTimes = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.updateActualWorkingTimes),
      concatLatestFrom(() => [
        this.store.pipe(select(AssignmentsSelectors.activeAssignmentId), isDefined()),
        this.store.pipe(
          select(ActiveAssignmentSelectors.targetAssignmentHasSlotActualWorkingTimes),
          isDefined(),
        ),
      ]),
      switchMap(([action, assignment, assignmentHasSlot]) => {
        return this.assignmentApi
          .updateActualWorkingTimes({
            assignmentId: assignment,
            assignmentHasSlotId: action.assignmentHasSlotId,
            time: action.time,
            breakTime: action.breakTime,
          })
          .pipe(
            map(() =>
              activeAssignmentActions.updateActualWorkingTimesSuccess({
                time: action.time,
                breakTime: action.breakTime,
                assignmentHasSlot: assignmentHasSlot,
              }),
            ),
            catchError((error) =>
              of(
                activeAssignmentActions.updateActualWorkingTimesFailure({
                  error: error,
                  assignmentHasSlot: assignmentHasSlot,
                }),
              ),
            ),
          );
      }),
    ),
  );

  public readonly updateActualWorkingTimesFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(activeAssignmentActions.updateActualWorkingTimesSuccess, {
          message: (action) => {
            let date: string = format(action.assignmentHasSlot.slot.timePeriod.start, 'dd-MM-yyyy');
            return `Er is iets misgegaan bij het bijwerken van de shift op datum ${date}.`;
          },
        }),
      ),
    { dispatch: false },
  );

  public readonly updateActualWorkingTimesSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(activeAssignmentActions.updateActualWorkingTimesSuccess, {
          message: (action) => {
            let date: string = format(action.assignmentHasSlot.slot.timePeriod.start, 'dd-MM-yyyy');
            return `Werkelijke werktijd opgeslagen voor ${date}`;
          },
        }),
      ),
    { dispatch: false },
  );

  public readonly setIsBillable = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.setIsBillable),
      concatLatestFrom(() => {
        return this.store.pipe(
          select(ActiveAssignmentSelectors.targetAssignmentHasSlotIsBillable),
          isDefined(),
        );
      }),
      filter(([_, assignmentHasSlot]) => !!assignmentHasSlot.slot.lesson),
      switchMap(([action, assignmentHasSlot]) =>
        this.lessonApi
          .setIsBillable(
            jsonLdSelectId((assignmentHasSlot.slot.lesson as LessonContract)['@id']),
            action.isBillable,
          )
          .pipe(
            map(() => activeAssignmentActions.setIsBillableSuccess(action)),
            catchError((error) =>
              of(activeAssignmentActions.setIsBillableFailure({ error: error })),
            ),
          ),
      ),
    ),
  );

  public readonly setIsBillableFailureMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(activeAssignmentActions.setIsBillableFailure, {
          message: 'Let op! Bijwerken van saldo afschrijven niet gelukt!',
        }),
      ),
    { dispatch: false },
  );

  public readonly setIsBillableSuccessMessage = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(activeAssignmentActions.setIsBillableSuccess, {
          message: (action) =>
            `'Saldo afschrijven voor deze les is ${action.isBillable ? 'aangezet' : 'uitgezet'}'`,
        }),
      ),
    { dispatch: false },
  );

  public readonly askToMarkAsPresent = createEffect(() =>
    this.actions$.pipe(
      ofType(activeAssignmentActions.askToMarkAsPresent),
      concatLatestFrom(() =>
        this.store.pipe(select(AssignmentsSelectors.activeAssignmentCandidate), isDefined()),
      ),
      switchMap(([action, candidate]) => {
        return from(
          this.modalController.create({
            component: TtSimpleModalComponent,
            componentProps: {
              title: 'Ziekmelding ongedaan maken',
              message:
                'Weet je zeker dat je de ziekmelding ' +
                `voor ${candidate?.fullName} op deze shift wilt terugdraaien?`,
            },
          }),
        ).pipe(
          switchMap((confirmModal) => confirmModal.present().then(() => confirmModal)),
          switchMap((confirmModal) => confirmModal.onWillDismiss()),
          filter(({ role }) => role === 'confirm'),
          map(() => {
            return absenceActions.markAsPresent({
              candidate: jsonLdSelectId(candidate['@id']),
              assignmentHasSlot: action.assignmentHasSlot,
            });
          }),
        );
      }),
    ),
  );

  public readonly setAssignmentsCandidateAsActiveCandidate = createEffect(() =>
    this.store.pipe(select(AssignmentsSelectors.activeAssignment)).pipe(
      map((active) => {
        if (active && active.candidate) {
          return candidatesActions.setActiveCandidate({
            selectedId: jsonLdSelectId(active.candidate['@id']),
          });
        }

        return candidatesActions.setActiveCandidate({ selectedId: null });
      }),
    ),
  );
}
