import { Expose } from 'class-transformer';

export class Compensation {
  @Expose() public hourlyFee!: string;

  @Expose() public travelCompensation!: string;

  @Expose() public articleCode?: string;

  @Expose() public allowExtraHours!: boolean;

  @Expose() public allowExtraExpenses!: boolean;
}
