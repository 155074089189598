import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  BusinessServiceContract,
  BusinessServiceDetailedContract,
} from '../contracts/business-service.contract';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { businessServicesActions } from './business-services.actions';

export const BUSINESS_SERVICES_FEATURE_KEY = 'businessServices';

export interface BusinessServicesState
  extends EntityState<BusinessServiceContract | BusinessServiceDetailedContract> {
  totalItems?: number;
  loaded: boolean;
  loading: boolean;
  loadingDetailed: boolean;
  error?: unknown | null;
  cacheTimeStamp?: string;
}

export const businessServicesAdapter: EntityAdapter<
  BusinessServiceContract | BusinessServiceDetailedContract
> = createEntityAdapter<BusinessServiceContract | BusinessServiceDetailedContract>({
  selectId: jsonLdSelectId,
});

export const initialBusinessServicesState: BusinessServicesState =
  businessServicesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
    loadingDetailed: false,
  });

const reducer = createReducer(
  initialBusinessServicesState,
  on(businessServicesActions.initBusinessServices, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(businessServicesActions.reloadBusinessServices, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    businessServicesActions.loadBusinessServicesSuccess,
    (state, { businessServices, totalItems }) =>
      businessServicesAdapter.setAll(businessServices, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: formatISO(new Date()),
      }),
  ),
  on(
    businessServicesActions.loadFromCacheBusinessServicesSuccess,
    (state, { businessServices, totalItems, cacheTimestamp }) =>
      businessServicesAdapter.setAll(businessServices, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: cacheTimestamp,
      }),
  ),
  on(businessServicesActions.loadBusinessServicesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  /** Detailed business services **/
  on(businessServicesActions.loadDetailedBusinessService, (state) => ({
    ...state,
    loadingDetailed: true,
    error: null,
  })),
  on(
    businessServicesActions.loadDetailedBusinessServiceSuccess,
    (state, { detailedBusinessService }) =>
      businessServicesAdapter.upsertOne(detailedBusinessService, {
        ...state,
        loadingDetailed: false,
      }),
  ),
  on(businessServicesActions.loadDetailedBusinessServiceFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function businessServicesReducer(state: BusinessServicesState | undefined, action: Action) {
  return reducer(state, action);
}
