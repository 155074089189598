import { AsyncPipe } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { LetDirective } from '@ngrx/component';
import { AssignmentContract, ContractTypeEnum } from '@scheduler-frontend/assignment-contracts';
import {
  DeclineReasonTypeEnum,
  DeclineReason,
  DeclineReasonsStoreService,
} from '@scheduler-frontend/data-access-decline-reasons';
import { TtModalModule } from '@techniek-team/components/modal';
import { shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';

function isEmploymentContractOrUnknown(assignment: AssignmentContract): boolean {
  return (
    !assignment?.contractType || assignment.contractType === ContractTypeEnum.EMPLOYMENT_CONTRACT
  );
}

@Component({
  selector: 'app-absent-modal',
  templateUrl: './absent-modal.component.html',
  styleUrls: ['./absent-modal.component.scss'],
  standalone: true,
  imports: [TtModalModule, IonicModule, ReactiveFormsModule, AsyncPipe, LetDirective],
})
export class AbsentModalComponent {
  @Input() public absentForFullAssignment: boolean = false;

  @Input()
  public set assignment(assignment: AssignmentContract) {
    if (!assignment) {
      return;
    }
    this.candidateName = assignment?.candidate?.fullName;
    this._assignment = assignment;
  }

  protected readonly declineReasonControl: FormControl<DeclineReason | null> =
    new FormControl<DeclineReason | null>(null, Validators.required);

  //eslint-disable-next-line @typescript-eslint/naming-convention
  protected _assignment!: AssignmentContract;

  protected candidateName?: string;

  protected subject: string = 'Afmelden';

  protected readonly ContractTypeEnum: typeof ContractTypeEnum = ContractTypeEnum;

  protected readonly declineReasonStore = inject(DeclineReasonsStoreService);

  protected readonly declineReasons$ = this.declineReasonStore.declineReasons$.pipe(
    map((reasons: DeclineReason[]) => {
      return reasons.filter((reason) => reason.declineReasonType === DeclineReasonTypeEnum.CANCEL);
    }),
    map((reasons: DeclineReason[]) => {
      let reasonSick: DeclineReason = new DeclineReason();
      // we only register that am employee is sick when it's an employee contract
      // if ib47 we use the `Privé omstandigheden` reason.
      if (isEmploymentContractOrUnknown(this._assignment)) {
        // this is a fake id which is being picked up by the { @see UnassignService }
        reasonSick._id = 'sick';
        reasonSick.description = 'Ziekmelding';
      } else {
        reasonSick._id = (
          reasons.find((item) => item.description === 'Privé omstandigheden') as DeclineReason
        ).getIri() as string;
        reasonSick.description = 'Ziek (= uitroosteren wegens privé omstandigheden)';
      }

      reasons.push(reasonSick);
      return reasons;
    }),
    shareReplay(),
  );

  private readonly modalController = inject(ModalController);

  protected cancel(): Promise<boolean> {
    return this.modalController.dismiss(undefined, 'cancel');
  }

  protected confirm(): Promise<boolean> {
    if (!this.declineReasonControl.valid) {
      return Promise.resolve(false);
    }

    // we only register that an employee is sick when the assignment.contractType is EMPLOYEE_CONTRACT or NULL.
    // we do not register that an employee is sick for IB47. There we use the 'Prive omstandigheden' declineReason.
    if (this.declineReasonControl.value?._id === 'sick') {
      return this.modalController.dismiss(undefined, 'sick');
    }
    return this.modalController.dismiss(this.declineReasonControl.value, 'confirm');
  }
}
