import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { approachesActions, ApproachesSelectors } from '@scheduler-frontend/data-access-approaches';
import { Resource } from '@techniek-team/api-platform';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, filter, map, of, switchMap } from 'rxjs';
import { CandidatesByRankingApi } from '../api/candidates-by-ranking.api';
import { GetCandidatesByAverageRankingResponse } from '../api/candidates-by-ranking.response';
import { candidatesByRankingActions } from '../candidates-by-ranking.actions';
import { CandidatesByRankingSelectors } from '../candidates-by-ranking.selectors';

@Injectable()
export class LoadCandidatesByRankingEffect {
  private readonly actions$ = inject(Actions);

  private readonly candidatesByRankingApi = inject(CandidatesByRankingApi);

  private readonly store = inject(Store);

  /**
   * Loads or refreshes on trigger the list of candidates shown on the {@see ApproachList} page.
   */
  public loadCandidatesBasedOnRanking = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidatesByRankingActions.initCandidatesByRanking,
        candidatesByRankingActions.refreshCandidatesByRanking,
        approachesActions.setActiveCandidateSearch,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(ApproachesSelectors.selectedCandidateSearch)),
      ]),
      switchMap(([_action, search]) => {
        return this.candidatesByRankingApi.getCandidatesByRanking(1, search).pipe(
          map((candidateCollection) => {
            const candidates: Resource<GetCandidatesByAverageRankingResponse>[] =
              candidateCollection['hydra:member'];
            return candidatesByRankingActions.loadCandidatesByRankingSuccess({
              candidates: candidates,
              candidateIds: candidates.map((item) => item['@id']),
              chunk: 1,
              totalItems: candidateCollection['hydra:totalItems'],
            });
          }),
          catchError((error) =>
            of(
              candidatesByRankingActions.loadCandidatesByRankingFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * Loads next chunk the list of candidates shown on the {@see ApproachList} page.
   */
  public loadNextChunkOfCandidatesByRanking = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.loadNextChunk),
      concatLatestFrom(() => [
        this.store.pipe(
          select(CandidatesByRankingSelectors.loadedChunks),
          filter((chunks: number[]) => chunks.length > 0),
          map((chunks: number[]) => Math.max(...chunks)),
        ),
        this.store.pipe(select(ApproachesSelectors.selectedCandidateSearch)),
      ]),
      exhaustMap(([_actions, lastChunk, search]) => {
        return this.candidatesByRankingApi.getCandidatesByRanking(lastChunk + 1, search).pipe(
          map((candidateCollection) => {
            const candidates: Resource<GetCandidatesByAverageRankingResponse>[] =
              candidateCollection['hydra:member'];
            return candidatesByRankingActions.loadNextChunkSuccess({
              candidates: candidates,
              candidateIds: candidates.map((item) => jsonLdSelectId(item)),
              chunk: lastChunk + 1,
              totalItems: candidateCollection['hydra:totalItems'],
            });
          }),
          catchError((error) =>
            of(
              candidatesByRankingActions.loadCandidatesByRankingFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * After the trigger {@link candidatesByRankingActions.initCandidatesByRanking} and until the
   * {@link candidatesByRankingActions.stopCandidatesByRanking}
   * will this effect trigger the {@link candidatesByRankingActions.refreshCandidatesByRanking} on a
   * regular interval or 3 minutes.
   */
  //public refreshCandidatesByRankingOnSetInterval = createEffect(() => {
  //  return this.actions$.pipe(
  //    ofType(candidatesByRankingActions.initCandidatesByRanking),
  //    exhaustMap(() =>
  //      // Refresh the candidates automatically every 3 minutes.
  //      interval(60000 * 3)
  //        .pipe(switchMap(() => of(candidatesByRankingActions.refreshCandidatesByRanking())))
  //        .pipe(
  //          takeUntil(
  //            this.actions$.pipe(ofType(candidatesByRankingActions.stopCandidatesByRanking)),
  //          ),
  //        ),
  //    ),
  //  );
  //});

  public handleFailureOfLoadCandidateByRaking = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidatesByRankingActions.loadCandidatesByRankingFailure, {
          message: 'Er is iets misgegaan bij het laden van de begeleiders-ranglijst.',
        }),
      ),
    { dispatch: false },
  );
}
