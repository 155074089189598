import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  AssignmentPayoutContract,
  PayoutWaitingForApproval,
} from '@scheduler-frontend/data-access-assignment';
import { LocationModel } from '@scheduler-frontend/data-access-locations';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompensationCell } from '../../payout/admin-approval-overview/models/compensation-cell.model';
import { CompensationTable } from '../../payout/admin-approval-overview/models/compensation-table.model';
import { GetBulkPayoutResponse } from './payout.response';

@Injectable({
  providedIn: 'root',
})
export class PayoutApi {
  protected readonly httpClient = inject(HttpClient);

  public getBulkPayouts(): Observable<GetBulkPayoutResponse[]> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/payouts/aggregated/unfinalized-assignments`;

    return this.httpClient.get<GetBulkPayoutResponse[]>(url);
  }

  public postBulkPayouts(product: CompensationTable, week: number, year: number): Observable<void> {
    //eslint-disable-next-line max-len
    return this.httpClient.post<void>(
      `${environment.scheduler.url}${environment.scheduler.iri}/v1/payouts/aggregated/unfinalized-assignments`,
      {
        productTypeId: product.id,
        week: week,
        year: year,
        numberOfAssignments: product.totalAssignments,
        totalAmount: (product.footer.getCell('total') as CompensationCell).amount,
      },
    );
  }

  public getLocationPayoutWaitingForApprovals(
    location: string | LocationModel,
  ): Observable<Hydra<PayoutWaitingForApproval>> {
    if (location instanceof LocationModel) {
      location = location.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/assignments/completed`;
    const params: HttpParams = new HttpParams().set('location', location);

    return this.httpClient
      .get<Collection<AssignmentPayoutContract>>(url, { params: params })
      .pipe(map((response) => denormalize(PayoutWaitingForApproval, response)));
  }
}
