import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LetDirective } from '@ngrx/component';
import {
  ActiveAssignmentsPermissionsStoreService,
  AssignmentsStoreService,
} from '@scheduler-frontend/data-access-assignment';
import { AssignmentDetailedWithAssignmentHasSlot } from '@scheduler-frontend/assignment-contracts';
import { AsTypeModule } from '@techniek-team/common';
import { AssignmentHasSlotTableComponent } from './assignment-has-slot-table/assignment-has-slot-table.component';
import { SelfAssignFormComponent } from './self-assign-form/self-assign-form.component';
import { UrgentFormComponent } from './urgent-form/urgent-form.component';

@Component({
  selector: 'app-assignment-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LetDirective,
    IonicModule,
    AsyncPipe,
    UrgentFormComponent,
    SelfAssignFormComponent,
    AssignmentHasSlotTableComponent,
    AsTypeModule,
  ],
})
export class GeneralComponent {
  public readonly displayedColumns: string[] = [
    'location',
    'date',
    'time',
    'break',
    'actualTime',
    'role',
  ];

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  protected readonly AssignmentDetailedWithAssignmentHasSlot =
    AssignmentDetailedWithAssignmentHasSlot;
}
