import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CandidateContract,
  CandidateDetailedContract,
  CandidateHasSkillsContract,
} from '@scheduler-frontend/candidate-contracts';
import {
  BusinessServiceContract,
  BusinessServicesSelectors,
} from '@scheduler-frontend/data-access-business-services';
import { LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { RoleContract, RolesSelectors } from '@scheduler-frontend/data-access-roles';
import { SubjectContract, SubjectsSelectors } from '@scheduler-frontend/data-access-subjects';
import { ChooseableLevelEnum, LevelEnum } from '@scheduler-frontend/enums';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { isAfter, isBefore } from 'date-fns';
import { sortBy, uniqBy } from 'lodash-es';
import {
  candidateAdapter,
  CANDIDATES_FEATURE_KEY,
  CandidatesState,
} from '../reducers/candidates.reducer';
import { CandidateAssignmentsFutureSelectors } from './candidate-assignments-future.selectors';
import { CandidateAssignmentsHistorySelectors } from './candidate-assignments-history.selectors';
import { CandidateRemarksSelectors } from './candidate-remarks.selectors';

const { selectEntities, selectAll } = candidateAdapter.getSelectors();

export class CandidatesSelectors {
  public static readonly state = createFeatureSelector<CandidatesState>(CANDIDATES_FEATURE_KEY);

  public static readonly loading = createSelector(CandidatesSelectors.state, (state) => {
    return state.loadingSelected;
  });

  public static readonly candidateEntities = createSelector(CandidatesSelectors.state, (state) =>
    selectEntities(state),
  );

  public static readonly candidates = createSelector(CandidatesSelectors.state, (state) =>
    selectAll(state),
  );

  public static readonly loadCandidateDetails = createSelector(
    CandidatesSelectors.state,
    (state) => state.loadedDetailedCandidates,
  );

  public static readonly selectAllLoading = createSelector(
    CandidatesSelectors.state,
    CandidateRemarksSelectors.loading,
    CandidateAssignmentsHistorySelectors.loading,
    CandidateAssignmentsFutureSelectors.loading,
    (state, loadingRemarks, loadingAssignmentsHistory, loadingAssignmentsFuture) =>
      state.loadedSelected &&
      loadingRemarks &&
      loadingAssignmentsHistory &&
      loadingAssignmentsFuture,
  );

  public static readonly loaded = createSelector(
    CandidatesSelectors.state,
    (state) => state.loadedSelected,
  );

  public static readonly error = createSelector(CandidatesSelectors.state, (state) => state.error);

  public static readonly activeCandidateId = createSelector(
    CandidatesSelectors.state,
    (state) => state.selectedId,
  );

  public static readonly hasActiveCandidate = createSelector(
    CandidatesSelectors.state,
    (state) => !!state.selectedId,
  );

  //eslint-disable-next-line max-len
  public static readonly activeCandidate = createSelector(
    CandidatesSelectors.candidateEntities,
    CandidatesSelectors.activeCandidateId,
    (entities, selectedId) => {
      return entities[selectedId as string] as
        | CandidateContract
        | CandidateDetailedContract
        | undefined;
    },
  );

  public static readonly activeCandidateDetails = createSelector(
    CandidatesSelectors.activeCandidate,
    (activeCandidate) => {
      if (activeCandidate && 'lastName' in activeCandidate) {
        return activeCandidate;
      }
      return undefined;
    },
  );

  public static readonly activeCandidateMainLocation = createSelector(
    CandidatesSelectors.activeCandidateDetails,
    LocationsSelectors.locationsEntities,
    (activeCandidate, locations) => {
      const locationIri = activeCandidate?.candidateHasLocations.find(
        (location) => location.isMain,
      )?.location;
      if (locationIri) {
        return locations[jsonLdSelectId(locationIri)];
      }
      return undefined;
    },
  );

  public static readonly selectCandidate = (candidateId: string) =>
    createSelector(CandidatesSelectors.candidateEntities, (entities) => entities[candidateId]);

  public static readonly selectCandidateOrActiveCandidate = (candidateId?: string) => {
    if (candidateId) {
      return CandidatesSelectors.selectCandidate(candidateId);
    }
    return CandidatesSelectors.activeCandidate;
  };

  public static readonly activeCandidateSkills = createSelector(
    CandidatesSelectors.state,
    (state) => state.activeCandidateSkills,
  );

  public static readonly activeCandidateSkillsCurrentAndFuture = createSelector(
    CandidatesSelectors.activeCandidateSkills,
    (activeCandidateSkills) => {
      if (!activeCandidateSkills || activeCandidateSkills.length === 0) {
        return [];
      }

      return activeCandidateSkills.filter((candidateSkill: CandidateHasSkillsContract) => {
        const today: Date = new Date();
        const isCurrent: boolean =
          isBefore(candidateSkill.validityRange.start, today) &&
          isAfter(candidateSkill.validityRange.end, today);
        const isFuture: boolean = isAfter(candidateSkill.validityRange.start, today);

        return isCurrent || isFuture;
      });
    },
  );

  public static readonly activeCandidateCurrentAndFutureSkillsBusinessServices = createSelector(
    CandidatesSelectors.activeCandidateSkillsCurrentAndFuture,
    RolesSelectors.roleEntities,
    BusinessServicesSelectors.businessServiceEntities,
    (candidateSkills, roles, businessServices) => {
      if (candidateSkills.length === 0) {
        return [];
      }

      const foundBusinessServices: BusinessServiceContract[] = candidateSkills
        .map((candidateSkill) => {
          return businessServices[roles[candidateSkill.skill.role]?.businessService as string];
        })
        .filter((businessService) => !!businessService) as BusinessServiceContract[];

      return sortBy(uniqBy(foundBusinessServices, '@id'), 'name');
    },
  );

  public static readonly activeCandidateBusinessServicesToApproachFor = createSelector(
    CandidatesSelectors.activeCandidateCurrentAndFutureSkillsBusinessServices,
    (businessServices) => {
      return businessServices.filter((businessService) =>
        businessService.name.includes('Examentraining'),
      );
    },
  );

  public static readonly activeCandidateCurrentAndFutureSkillRoles = createSelector(
    CandidatesSelectors.activeCandidateSkillsCurrentAndFuture,
    RolesSelectors.roleEntities,
    (candidateSkills, roles) => {
      if (candidateSkills.length === 0) {
        return [];
      }

      const foundRoles: RoleContract[] = candidateSkills
        .map((candidateSkill) => roles[candidateSkill.skill.role])
        .filter((role) => !!role) as RoleContract[];

      return sortBy(uniqBy(foundRoles, '@id'), 'name');
    },
  );

  public static readonly activeCandidateRolesToApproachFor = createSelector(
    CandidatesSelectors.activeCandidateCurrentAndFutureSkillRoles,
    BusinessServicesSelectors.businessServiceEntities,
    (roles, businessServices) => {
      return roles.filter((role) =>
        businessServices[role.businessService]?.name.includes('Examentraining'),
      );
    },
  );

  public static readonly activeCandidateCurrentAndFutureSkillSubjects = createSelector(
    CandidatesSelectors.activeCandidateSkillsCurrentAndFuture,
    SubjectsSelectors.subjectEntities,
    (candidateSkills, subjects) => {
      if (candidateSkills.length === 0) {
        return [];
      }

      const subjectsFound = candidateSkills
        .map((candidateSkill) => {
          if (candidateSkill.skill.subject) {
            return subjects[candidateSkill.skill.subject];
          }
          return undefined;
        })
        .filter((subject) => !!subject) as SubjectContract[];

      return sortBy(uniqBy(subjectsFound, '@id'), 'name');
    },
  );

  public static readonly activeCandidateSubjectsToApproachFor = createSelector(
    CandidatesSelectors.activeCandidateSkillsCurrentAndFuture,
    RolesSelectors.roleEntities,
    BusinessServicesSelectors.businessServiceEntities,
    SubjectsSelectors.subjectEntities,
    (candidateSkills, roles, businessServices, subjects) => {
      if (candidateSkills.length === 0) {
        return [];
      }

      const subjectsFound: SubjectContract[] = candidateSkills
        .filter((candidateSkill) => {
          const role = roles[candidateSkill.skill.role];
          if (!role) {
            return false;
          }
          if (typeof role.businessService === 'string') {
            return businessServices[role.businessService]?.name.includes('Examentraining');
          }
          return role.businessService?.name.includes('Examentraining');
        })
        .map((candidateSkill) => {
          if (candidateSkill.skill.subject) {
            return subjects[candidateSkill.skill.subject as string];
          }
          return undefined;
        })
        .filter((subject) => !!subject) as SubjectContract[];

      return sortBy(uniqBy(subjectsFound, '@id'), 'name');
    },
  );

  public static readonly activeCandidateCurrentAndFutureSkillLevels = createSelector(
    CandidatesSelectors.activeCandidateSkillsCurrentAndFuture,
    (candidateSkills) => {
      if (candidateSkills.length === 0) {
        return [];
      }

      const levels: LevelEnum[] = candidateSkills
        .map((candidateSkill) => {
          return candidateSkill.skill.level;
        })
        .filter((level: LevelEnum) => level !== LevelEnum.ANY);

      return [...new Set(levels)].sort((levelA: LevelEnum, levelB: LevelEnum) => {
        return levelA.localeCompare(levelB);
      });
    },
  );

  public static readonly activeCandidateLevelsToApproachFor = createSelector(
    CandidatesSelectors.activeCandidateSkillsCurrentAndFuture,
    RolesSelectors.roleEntities,
    BusinessServicesSelectors.businessServiceEntities,
    (candidateSkills, roles, businessServices) => {
      if (candidateSkills.length === 0) {
        return [];
      }

      const levels: ChooseableLevelEnum[] = candidateSkills
        .filter((candidateSkill) => {
          const role = roles[candidateSkill.skill.role];
          if (!role) {
            return false;
          }

          if (typeof role.businessService === 'string') {
            return businessServices[role.businessService]?.name.includes('Examentraining');
          }
          return role.businessService?.name.includes('Examentraining');
        })
        .map((candidateSkill) => {
          return candidateSkill.skill.level as ChooseableLevelEnum;
        })
        .filter((level: LevelEnum) => level !== LevelEnum.ANY);

      return [...new Set(levels)].sort((levelA: LevelEnum, levelB: LevelEnum) => {
        return levelA.localeCompare(levelB);
      });
    },
  );
}
