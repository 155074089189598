import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ApproachContract } from '@scheduler-frontend/approach-contracts';

export const approachesWaitingActions = createActionGroup({
  source: '[Approaches/Waiting]',
  events: {
    loadApproaches: emptyProps(),
    loadNextChunk: emptyProps(),
    loadApproachesSuccess: props<{
      approaches: ApproachContract[];
      chunk: number;
      totalItems: number;
    }>(),
    addApproach: props<{
      approach: ApproachContract;
    }>(),
    removeApproach: props<{
      approach: ApproachContract;
    }>(),
    loadNextChunkSuccess: props<{
      approaches: ApproachContract[];
      chunk: number;
      totalItems: number;
    }>(),
    loadApproachesFailure: props<{ error: unknown }>(),
  },
});
