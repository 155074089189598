import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssignApi {
  protected readonly httpClient = inject(HttpClient);

  public execute(request: {
    /**
     * The id of the candidate
     */
    candidate: string;

    /**
     * A list of slot Ids
     */
    slots: string[];

    /**
     * Allow endpoint to resolve conflicts (if possible)
     */
    resolveConflicts?: boolean;
  }): Observable<string[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments`;
    return this.httpClient
      .post<{ id: string }[]>(url, {
        candidate: request.candidate,
        slots: request.slots,
        resolveConflicts: request.resolveConflicts,
      })
      .pipe(map((response) => response.map((item) => item.id)));
  }
}
