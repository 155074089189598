import { transition, trigger, useAnimation } from '@angular/animations';
import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { LetDirective } from '@ngrx/component';
import {
  noTableDataEnterAnimation,
  noTableDataLeaveAnimation,
  tableEnterAnimation,
  tableLeaveAnimation,
  tableLoadingEnterAnimation,
  tableLoadingLeaveAnimation,
} from '@scheduler-frontend/common/animation';
import {
  AssignmentsStoreService,
  AssignmentTransition,
  TransitionLogRecord,
} from '@scheduler-frontend/data-access-assignment';
import { RecordType } from '@scheduler-frontend/log-contracts';
import { TransitionLogRecordComponent } from './transittion-log-record/transition-log-record.component';

@Component({
  selector: 'app-assignment-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  standalone: true,
  imports: [
    TransitionLogRecordComponent,
    MatTableModule,
    MatSortModule,
    FontAwesomeModule,
    DatePipe,
    IonicModule,
    LetDirective,
    AsyncPipe,
  ],
  animations: [
    trigger('tableCell', [
      transition(':enter', useAnimation(tableEnterAnimation)),
      transition(':leave', useAnimation(tableLeaveAnimation)),
    ]),
    trigger('tableLoading', [
      transition(':enter', useAnimation(tableLoadingEnterAnimation)),
      transition(':leave', useAnimation(tableLoadingLeaveAnimation)),
    ]),
    trigger('noTableData', [
      transition(':enter', useAnimation(noTableDataEnterAnimation)),
      transition(':leave', useAnimation(noTableDataLeaveAnimation)),
    ]),
  ],
})
export class HistoryComponent {
  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly AssignmentTransition = AssignmentTransition;

  protected readonly RecordType = RecordType;

  protected readonly displayedColumns = ['icon', 'initiatedAt', 'description', 'initiatedBy'];

  @ViewChild(MatSort) public sort!: MatSort;

  protected trackBy(index: number, item: TransitionLogRecord): string {
    return item.getIri() as string;
  }
}
