import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  adapter,
  CANDIDATES_BY_RANKING_FEATURE_KEY,
  CandidatesByRankingState,
} from './candidates-by-ranking.reducer';

const { selectIds } = adapter.getSelectors();

export class CandidatesByRankingSelectors {
  public static readonly candidatesByRankingState = createFeatureSelector<CandidatesByRankingState>(
    CANDIDATES_BY_RANKING_FEATURE_KEY,
  );

  public static readonly candidateIds = createSelector(
    CandidatesByRankingSelectors.candidatesByRankingState,
    (state: CandidatesByRankingState) => selectIds(state) as string[],
  );

  public static readonly candidatesLength = createSelector(
    CandidatesByRankingSelectors.candidatesByRankingState,
    (state: CandidatesByRankingState) => state.ids.length,
  );

  public static readonly candidatesByRankingLoading = createSelector(
    CandidatesByRankingSelectors.candidatesByRankingState,
    (state: CandidatesByRankingState) => state.loading,
  );

  public static readonly candidatesByRankingLoaded = createSelector(
    CandidatesByRankingSelectors.candidatesByRankingState,
    (state: CandidatesByRankingState) => state.loaded,
  );

  public static readonly loadedChunks = createSelector(
    CandidatesByRankingSelectors.candidatesByRankingState,
    (state: CandidatesByRankingState) => state.loadedChunks,
  );

  public static readonly lastChunkLoaded = createSelector(
    CandidatesByRankingSelectors.candidatesByRankingState,
    (state: CandidatesByRankingState) => state.lastChunkLoaded,
  );

  public static readonly candidatesByRankingError = createSelector(
    CandidatesByRankingSelectors.candidatesByRankingState,
    (state: CandidatesByRankingState) => state.error,
  );
}
