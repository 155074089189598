import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchSlotApi {
  protected readonly http = inject(HttpClient);

  /**
   * Searches for slot with the given search hash.
   */
  public search(
    searchHash: string,
    options: {
      withRelatedShifts: boolean;
      page: number;
    } = { withRelatedShifts: false, page: 1 },
  ): Observable<Collection<SlotDetailedContract>> {
    //todo pagination is not yet available on the backend.

    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/search/${searchHash}/results`;
    const params: HttpParams = new HttpParams().set(
      'withRelatedShifts',
      options.withRelatedShifts ? '1' : '0',
    );

    return this.http.get<Collection<SlotDetailedContract>>(url, { params: params });
  }
}
