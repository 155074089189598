import { createFeatureSelector, createSelector } from '@ngrx/store';
import { denormalize } from '@techniek-team/class-transformer';
import { Filter, FilterGroup } from '@techniek-team/search';
import { toDictionary } from '@techniek-team/tt-ngrx';
import { SubjectContract } from '../contracts/subject.contract';
import { SubjectModel } from '../models/subject.model';
import { SUBJECTS_FEATURE_KEY, subjectsAdapter, SubjectsState } from './subjects.reducer';

const { selectAll, selectEntities } = subjectsAdapter.getSelectors();

export class SubjectsSelectors {
  public static readonly subjectsState = createFeatureSelector<SubjectsState>(SUBJECTS_FEATURE_KEY);

  public static readonly loading = createSelector(
    SubjectsSelectors.subjectsState,
    (state: SubjectsState) => state.loading,
  );

  public static readonly loaded = createSelector(
    SubjectsSelectors.subjectsState,
    (state: SubjectsState) => state.loaded,
  );

  public static readonly cacheTimestamp = createSelector(
    SubjectsSelectors.subjectsState,
    (state: SubjectsState) => state.cacheTimeStamp,
  );

  public static readonly error = createSelector(
    SubjectsSelectors.subjectsState,
    (state: SubjectsState) => state.error,
  );

  public static readonly subjects = createSelector(
    SubjectsSelectors.subjectsState,
    (state: SubjectsState) => selectAll(state),
  );

  public static readonly subjectEntities = createSelector(
    SubjectsSelectors.subjectsState,
    (state: SubjectsState) => selectEntities(state),
  );

  public static readonly denormalizedSubjectEntities = createSelector(
    SubjectsSelectors.subjectsState,
    (state) =>
      toDictionary(denormalize(SubjectModel, Object.values(state.entities)), (item) =>
        item.getId(),
      ),
  );

  public static readonly subjectsFilterGroupKey = 'lesson.subject';

  public static readonly subjectsFilterGroup = createSelector(
    SubjectsSelectors.subjects,
    (subject) => {
      return new FilterGroup(
        SubjectsSelectors.subjectsFilterGroupKey,
        (subject ?? []).map(
          (item) =>
            new Filter(item['@id'] as string, {
              label: item.name,
              additionalData: item,
            }),
        ),
        { multiple: true, label: 'Vakken', itemLabel: 'Vak' },
      );
    },
  );

  public static readonly searchQueryMapper = createSelector(
    SubjectsSelectors.subjectEntities,
    (dict) => {
      return {
        'lesson.subject': {
          entities: dict,
          getDisplayText: (item: SubjectContract) => item.name,
        },
      };
    },
  );
}
