import { MercureBaseEvent } from './mercure-base.event';

export class MercureErrorEvent extends MercureBaseEvent {
  public readonly message: string;

  public readonly error: unknown;

  constructor(init: { error: unknown; eventType: string }) {
    super({ eventType: init.eventType });
    this.error = init.error;
    this.message =
      init.error && typeof init.error === 'object' && 'message' in init.error
        ? (init.error.message as string)
        : 'Event Source Error';
  }
}
