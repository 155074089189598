import { Signal } from '@angular/core';
import { BusinessService } from '@scheduler-frontend/data-access-business-services';
import { LocationModel } from '@scheduler-frontend/data-access-locations';
import { ScheduleMinimal } from '@scheduler-frontend/schedule-contracts';
import { denormalize, TsRange } from '@techniek-team/class-transformer';
import { SignalFetch } from '@techniek-team/fetch';
import { Exclude, Expose, Type } from 'class-transformer';
import { AssignmentDocument } from './assignment-document.model';
import { AssignmentOnlineLesson } from './assignment-online-lesson.model';
import { Assignment } from './assignment.model';

/**
 * The Detailed version of the Assignment resource from Scheduler-api
 */
export class AssignmentDetailed extends Assignment {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'AssignmentDetailed';

  /**
   * The grade that is given to the candidate for this assignment.
   */
  @Expose() public grade?: number;

  /**
   * The number of pupils that graded the candidate
   */
  @Expose() public gradeCount!: number;

  @Type(() => TsRange)
  @Expose()
  public period!: TsRange;

  /**
   * This is the schedule of the assignment.
   */
  @Type(() => ScheduleMinimal)
  @Expose()
  public schedule?: ScheduleMinimal;

  /**
   * The location where the assignment takes place.
   *
   * This property can be empty in which case the assignment takes place online.
   */
  @SignalFetch(() => LocationModel)
  @Expose()
  public location!: Signal<LocationModel | undefined>;

  /**
   * The service done in this assignment. For example `Examen Training` or `Bijles`
   */
  @SignalFetch(() => BusinessService)
  @Expose()
  public businessService!: Signal<BusinessService | undefined>;

  /**
   * List of documents that are related to the assignment.
   *
   * The list a made available to the candidate in the {@see AssignmentDetailPage}
   * Where he/she can go to the link or download the file.
   */
  @Type(() => AssignmentDocument)
  @Exclude()
  public documents!: AssignmentDocument[];

  /**
   * Setter to convert assignmentHasDocuments to {@see AssignmentDocument} that are
   * stored in the {@see self.documents} property
   *
   * The actual endpoint returns a `assignmentHasDocuments` property which isn't
   * usefully within the app because it doesn't contain usefully additional information
   * so we convert the AssignmentHasDocuments property to a {@see AssignmentDocument}
   * model.
   * @param documents
   */
  @Expose()
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public set assignmentHasDocuments(documents: any[]) {
    this.documents = denormalize(
      AssignmentDocument,
      (documents ?? []).map((item) => ({
        ...item.document,
        assignmentHasDocumentId: item['@id'],
      })),
    );
  }

  /**
   * When an assignment takes place online the assignment can contain a number of
   * url to the online WizIq rooms.
   */
  @Type(() => AssignmentOnlineLesson)
  @Expose({ name: 'onlineLessonLinks' })
  public onlineLessons!: AssignmentOnlineLesson[];

  /**
   * Time precision specifies who precise the actual workings time should be set
   *
   * The is only used within the Tutor-app
   * @example
   * '0,15,30,45'
   */
  @Expose() public timePrecision!: number;

  /**
   * The minimum grade needed for a candidate to self assign {@see selfAssignable}
   * to the assignment.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public minimalGradeSelfAssign?: number | string;

  /**
   * The maximum travel distance needed for a candidate to self assign {@see selfAssignable}
   * to the assignment.
   *
   * value is distance in km
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public maxTravelDistanceSelfAssign?: number;

  /**
   * if true the candidate can assign there self to the assignment using the tutor-app.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public selfAssignable: boolean = false;

  /**
   * Candidate which are new and haven't done this kind a assignment before can
   * self assign {@see selfAssignable} to this assignment.
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public allowSelfAssignWhenNew: boolean = false;

  /**
   * Updating the self assign fields is only allowed when self assigning
   * is turned on by default in the admin.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public allowUpdatingSelfAssignFields: boolean = false;

  /**
   * Updating the self assign fields is only allowed when self assigning
   * is turned on by default in the admin.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public isUrgent: boolean = false;

  /**
   * When true, an AssignmentCompensation will be created.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public performCompensation: boolean = false;

  /**
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public addPremiumAllowed: boolean = false;

  /**
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public needsCompensation: boolean = false;

  /**
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public automaticTravelCompensation: boolean = false;

  /**
   * When true, performCompensation can be toggled.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public canEnableCompensation: boolean = false;

  /**
   * If true, manual travel compensation is allowed for this assignment.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public allowManualTravelCompensation: boolean = false;

  /**
   * For soms assignment there a restricted access in viewing the assignment compensation
   * lines.
   *
   *                         | UNASSIGNED ASSIGNMENT | ASSIGNED ASSIGNMENT
   * Admin (ROLE)            | TRUE                  | TRUE
   * Scheduling Team (ROLE)  | FALSE                 | FALSE
   * Location Manager (ROLE) | FALSE                 | FALSE
   * Product Coordinator     | TRUE                  | TRUE
   * Candidate               | FALSE                 | TRUE (FALSE if assigned candidate != candidate)
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public restrictAssignmentCompensationAccess: boolean = true;

  /**
   * Returns true if the assignment has online lessons.
   */
  @Exclude()
  public get hasOnlineLessons(): boolean {
    return this.onlineLessons.length > 0;
  }

  /**
   * Returns true if the assignment contains documents.
   */
  @Exclude()
  public get hasDocuments(): boolean {
    return this.documents.length > 0;
  }

  /**
   * Returns true if the assignment contains a small amount of documents.
   * In this case we want to show them in a different way.
   */
  @Exclude()
  public get hasLargeAmountOfDocuments(): boolean {
    return this.documents.length > 3;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
