import { CandidateMinimal, Person } from '@scheduler-frontend/data-access-users';
import { JsonLd } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { ApproachState } from '../enums/approach-state.enum';
import { ApproachEvent } from './approach-event.model';

export class Approach extends JsonLd {
  @Type(() => CandidateMinimal)
  @Expose()
  public candidate!: CandidateMinimal;

  @Type(() => Person)
  @Expose()
  public person!: Person;

  @Type(() => ApproachEvent)
  @Expose()
  public latestCommunicationEvent?: ApproachEvent;

  @Type(() => ApproachEvent)
  @Expose()
  public approachHasEvents!: ApproachEvent[];

  @Expose() public state!: ApproachState;

  @Expose() public numberOfSlots!: number;
}
