import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SubjectContract } from '../contracts/subject.contract';

export const subjectsActions = createActionGroup({
  source: '[Subjects]',
  events: {
    initSubjects: emptyProps(),
    reloadSubjects: emptyProps(),
    loadSubjectsSuccess: props<{
      subjects: SubjectContract[];
      totalItems: number;
    }>(),
    loadFromCacheSubjectsSuccess: props<{
      subjects: SubjectContract[];
      totalItems: number;
      cacheTimestamp: string; // iso 8601 date
    }>(),
    loadSubjectsFailure: props<{ error: unknown }>(),
  },
});
