import { Expose } from 'class-transformer';
import { TransitionEnum } from '../enums/transition.enum';

export class TransitionOption {
  @Expose() public name!: TransitionEnum;

  @Expose() public froms!: TransitionEnum[];

  @Expose() public tos!: TransitionEnum[];
}
