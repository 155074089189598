import { createFeatureSelector, createSelector } from '@ngrx/store';
import { assignmentStateSearchQueryMapper } from '@scheduler-frontend/assignment-contracts';
import { BusinessEntitiesSelectors } from '@scheduler-frontend/data-access-business-entities';
import {
  BusinessServicesSelectors,
  deliveryTypeSearchQueryMapper,
} from '@scheduler-frontend/data-access-business-services';
import { LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { ProductTypesSelectors } from '@scheduler-frontend/data-access-product-types';
import { RegionsSelectors } from '@scheduler-frontend/data-access-regions';
import { RolesSelectors } from '@scheduler-frontend/data-access-roles';
import { SchedulesSelectors } from '@scheduler-frontend/data-access-schedules';
import { SubjectsSelectors } from '@scheduler-frontend/data-access-subjects';
import { levelSearchQueryMapper } from '@scheduler-frontend/enums';
import { searchExpressionToObject } from '@scheduler-frontend/search-expression';
import { isAfter, subMonths } from 'date-fns';
import { getReadableSearchChips } from '../../functions/readable-search-chips.function';
import {
  USER_SEARCH_FEATURE_KEY,
  userSearchAdapter,
  UserSearchState,
} from '../reducer/user-search.reducer';

const { selectAll, selectEntities } = userSearchAdapter.getSelectors();

export class UserSearchSelectors {
  public static readonly state = createFeatureSelector<UserSearchState>(USER_SEARCH_FEATURE_KEY);

  public static readonly loading = createSelector(
    UserSearchSelectors.state,
    (state) => state.loading,
  );

  public static readonly savingAsFavorite = createSelector(
    UserSearchSelectors.state,
    (state) => state.savingAsFavorite,
  );

  public static readonly loaded = createSelector(
    UserSearchSelectors.state,
    (state) => state.loaded,
  );

  public static readonly currentSearchId = createSelector(UserSearchSelectors.state, (state) => {
    return state.currentUserSearch;
  });

  public static readonly lastCreated = createSelector(
    UserSearchSelectors.state,
    (state) => state.lastCreatedUserSearch,
  );

  public static readonly error = createSelector(UserSearchSelectors.state, (state) => state.error);

  public static readonly userSearches = createSelector(UserSearchSelectors.state, (state) =>
    selectAll(state),
  );

  public static readonly userSearchesEntities = createSelector(UserSearchSelectors.state, (state) =>
    selectEntities(state),
  );

  public static readonly currentSearch = createSelector(
    UserSearchSelectors.currentSearchId,
    UserSearchSelectors.userSearchesEntities,
    (current, searches) => {
      if (!current) {
        return;
      }
      return searches[current];
    },
  );

  public static readonly lastCreatedSearch = createSelector(
    UserSearchSelectors.userSearchesEntities,
    UserSearchSelectors.lastCreated,
    (entities, selectedId) => {
      return selectedId ? entities[selectedId] : undefined;
    },
  );

  public static readonly favorites = createSelector(
    UserSearchSelectors.userSearches,
    (searches) => {
      return searches.filter((search) => search.isFavorite);
    },
  );

  public static readonly recent = createSelector(UserSearchSelectors.userSearches, (searches) => {
    return searches
      .filter((search) => isAfter(new Date(search.lastQueriedAt), subMonths(new Date(), 1)))
      .sort((searchA, searchB) => {
        return isAfter(searchA.lastQueriedAt, searchB.lastQueriedAt) ? -1 : 1;
      });
  });

  public static readonly currentUserSearchParsedToSearchBarInputData = createSelector(
    UserSearchSelectors.currentSearch,
    (userSearch) => {
      if (!userSearch || !userSearch.search.metaData) {
        return undefined;
      }
      return searchExpressionToObject(userSearch.search.metaData.query);
    },
  );

  public static readonly currentUserSearchChips = createSelector(
    UserSearchSelectors.currentSearch,
    BusinessEntitiesSelectors.searchQueryMapper,
    BusinessServicesSelectors.searchQueryMapper,
    LocationsSelectors.searchQueryMapper,
    ProductTypesSelectors.searchQueryMapper,
    RegionsSelectors.searchQueryMapper,
    RolesSelectors.searchQueryMapper,
    SubjectsSelectors.searchQueryMapper,
    SchedulesSelectors.searchQueryMapper,
    deliveryTypeSearchQueryMapper,
    levelSearchQueryMapper,
    assignmentStateSearchQueryMapper,
    (userSearch, ...queryMappersDictionaries) => {
      if (!userSearch || !userSearch.search.metaData) {
        return undefined;
      }
      return getReadableSearchChips(userSearch.search, queryMappersDictionaries);
    },
  );
}
