import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { ApproachContract, ApproachDetailedContract } from '@scheduler-frontend/approach-contracts';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import { RankingContract, rankingsActions } from '@scheduler-frontend/data-access-rankings';
import {
  schedulingActions,
  schedulingApproachCandidatesActions,
} from '@scheduler-frontend/data-access-scheduling';
import { slotsActions } from '@scheduler-frontend/data-access-slots';
import { isDefined } from '@techniek-team/rxjs';
import { ToastService } from '@techniek-team/services';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { approachesActions } from '../actions/approaches.actions';
import { ApproachApi } from '../api/approach.api';
import { ApproachesSelectors } from '../selectors/approaches.selectors';
import { navigateToCorrectStepInFlow } from './functions/navigate-to-correct-step-in-flow.function';

@Injectable()
export class ApproachesEffects implements OnRunEffects {
  private readonly actions$: Actions = inject(Actions);

  private readonly approachApi: ApproachApi = inject(ApproachApi);

  private readonly store: Store = inject(Store);

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  private readonly router: Router = inject(Router);

  private readonly toastService: ToastService = inject(ToastService);

  public listenForApproachCreated = createEffect(() =>
    this.approachApi.approachCreatedEvents().pipe(
      takeUntilDestroyed(this.destroyRef),
      map((event) => approachesActions.approachCreated({ approach: event })),
    ),
  );

  public listenForApproachUpdated = createEffect(() =>
    this.approachApi.approachUpdatedEvents().pipe(
      takeUntilDestroyed(this.destroyRef),
      map((event) => approachesActions.approachUpdated({ approach: event })),
    ),
  );

  public createSetSlotsOnLoadApproachSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.loadApproachSuccess),
      isDefined(),
      filter((action) => (action.approach.approachHasSlots?.length ?? 0) > 0),
      map((action) => {
        return slotsActions.loadSlotsSuccess({
          slots: action.approach.approachHasSlots?.map(
            (hasSlot) => hasSlot.slot,
          ) as SlotDetailedContract[],
          chunk: 1,
          totalItems: action.approach.approachHasSlots?.length as number,
        });
      }),
    ),
  );

  public createSetRankingOnLoadApproachSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(approachesActions.loadApproachSuccess),
      isDefined(),
      filter((action) => (action.approach.approachHasSlots.length ?? 0) > 0),
      map((action) => {
        // TODO Error when multiple active candidates?
        return rankingsActions.addRankings({
          items: action.approach.approachHasSlots.map((data) => {
            return {
              candidate: jsonLdSelectId(action.approach.candidate),
              slots: [jsonLdSelectId(data.slot)],
              ranking: data.ranking,
            };
          }) as { candidate: string; slots: string[]; ranking: RankingContract }[],
        });
      }),
    );
  });

  public loadActiveApproachEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingApproachCandidatesActions.setActiveApproach),
      filter((action) => !!action.approach),
      switchMap((action) => {
        return this.approachApi.getApproach(action.approach as string).pipe(
          map((response) => approachesActions.loadApproachSuccess({ approach: response })),
          catchError((error) => of(approachesActions.loadApproachFailure({ error: error }))),
        );
      }),
    ),
  );

  public createLoadDetailedApproachEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.loadApproach),
      switchMap((action) => {
        return this.approachApi.getApproach(action.approach).pipe(
          map((response) => approachesActions.loadApproachSuccess({ approach: response })),
          catchError((error) => of(approachesActions.loadApproachFailure({ error: error }))),
        );
      }),
    ),
  );

  public markApproachAsHandledEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.markApproachAsHandled),
      concatLatestFrom(() => [
        this.store.pipe(select(ApproachesSelectors.activeApproach), isDefined()),
      ]),
      switchMap(([_action, activeApproach]) => {
        return this.approachApi.markApproachAsHandled(jsonLdSelectId(activeApproach)).pipe(
          map((response) => approachesActions.markApproachAsHandledSuccess({ approach: response })),
          catchError((error) =>
            of(approachesActions.markApproachAsHandledFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  public markApproachAsHandledSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(approachesActions.markApproachAsHandledSuccess, {
          message: (action) =>
            `De benadering van ${action.approach.candidate.fullName} is afgehandeld.`,
        }),
        map(() => schedulingActions.clearAllSelections()),
        tap(() => this.router.navigate(['/', 'benaderlijst'])),
      ),
    { dispatch: false },
  );

  public markApproachAsHandledFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(approachesActions.markApproachAsHandledFailure, {
          message: 'Er is iets misgegaan bij het afhandelen van de benadering.',
        }),
      ),
    { dispatch: false },
  );

  public markApproachAsExpiredEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.markApproachAsExpired),
      concatLatestFrom(() => [
        this.store.pipe(select(ApproachesSelectors.activeApproach), isDefined()),
      ]),
      switchMap(([_action, activeApproach]) => {
        return this.approachApi.markApproachAsExpired(jsonLdSelectId(activeApproach)).pipe(
          map((response) => approachesActions.markApproachAsExpiredSuccess({ approach: response })),
          catchError((error) =>
            of(approachesActions.markApproachAsExpiredFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  public markApproachAsExpiredSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(approachesActions.markApproachAsExpiredSuccess, {
          message: (action) =>
            `De benadering van ${action.approach.candidate.fullName} is afgebroken.`,
        }),
        map(() => schedulingActions.clearAllSelections()),
        tap(() => this.router.navigate(['/', 'benaderlijst'])),
      ),
    { dispatch: false },
  );

  public markApproachAsExpiredFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(approachesActions.markApproachAsExpiredFailure, {
          message: 'Er is iets misgegaan bij het afbreken van de benadering.',
        }),
      ),
    { dispatch: false },
  );

  public createLoadApproachFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(approachesActions.loadApproachFailure, {
          message: 'Er is iets misgegaan bij het opstarten van het benaderingsproces.',
        }),
      ),
    { dispatch: false },
  );

  public setSlotsBasedOnApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      exhaustMap(() =>
        this.store
          .pipe(
            select(ApproachesSelectors.activeApproach),
            distinctUntilChanged((a, b) => a?.['@id'] === b?.['@id']),
          )
          .pipe(
            isDefined(),
            filter((approach) => approach.hasOwnProperty('approachHasSlots')),
            isDefined(),
            map((approach) => {
              return slotsActions.loadSlotsSuccess({
                slots: (approach as ApproachDetailedContract).approachHasSlots?.map(
                  (hasSlot) => hasSlot.slot,
                ) as SlotDetailedContract[],
                chunk: 1,
                totalItems: (approach as ApproachDetailedContract).approachHasSlots
                  ?.length as number,
              });
            }),
            takeUntil(this.actions$.pipe(ofType(approachesActions.stopSchedulingByApproaches))),
          ),
      ),
    ),
  );

  public setSlotListBaseOnApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      exhaustMap(() =>
        this.store.pipe(select(ApproachesSelectors.activeApproachSlotIds)).pipe(
          isDefined(),
          map((slots) => schedulingActions.setSlotList({ items: slots })),
          takeUntil(this.actions$.pipe(ofType(approachesActions.stopSchedulingByApproaches))),
        ),
      ),
    ),
  );

  public setActiveCandidateBasedOnApproach = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulingApproachCandidatesActions.approach,
        schedulingApproachCandidatesActions.setActiveApproach,
      ),
      concatLatestFrom(() =>
        this.store.pipe(select(ApproachesSelectors.activeApproach), isDefined()),
      ),
      map(([action, approach]) =>
        candidatesActions.setActiveCandidate({
          selectedId: jsonLdSelectId(approach.candidate['@id']),
        }),
      ),
    ),
  );

  public redirectToCorrectStepInApproachFlow = createEffect(
    () =>
      this.actions$.pipe(
        ofType(schedulingApproachCandidatesActions.setActiveApproach),
        exhaustMap(() =>
          this.store.pipe(select(ApproachesSelectors.activeApproach)).pipe(
            isDefined(),
            tap((approach: ApproachContract) =>
              navigateToCorrectStepInFlow(approach, this.router, this.toastService),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions$.pipe(ofType(approachesActions.stopSchedulingByApproaches))),
        ),
      ),
    );
  }
}
