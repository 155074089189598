<tt-modal (cancel)="modalController.dismiss()">
  <tt-modal-title color="tertiary">Langdurige onbeschikbaarheid toevoegen</tt-modal-title>

  @if (usersStoreService.isAdmin()) {
    <form [formGroup]="availabilityForm">
      <tt-date-picker-input
        fill="solid"
        type="range"
        label="periode"
        format="dd-MM-yyyy"
        formControlName="dateRange"
        interface="popover"
        labelPlacement="floating"
        placeholder="van - tot"
      >
      </tt-date-picker-input>
      <ion-input fill="solid" label="Opmerking" labelPlacement="floating" formControlName="remarks">
      </ion-input>
    </form>
  }

  <ion-button
    slot="action"
    fill="clear"
    (click)="saveLongTermUnavailable()"
    [disabled]="availabilityForm.invalid || !usersStoreService.isAdmin()"
  >
    Opslaan
  </ion-button>
</tt-modal>
