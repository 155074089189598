import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { catchError, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSearchContract } from '../../contract/user-search.contract';
import { userSearchActions } from '../action/user-search.actions';
import { SearchApi } from '../api/search.api';
import { UserSearchSelectors } from '../selector/user-search.selectors';

@Injectable()
export class SearchFavoritesEffects {
  private readonly actions$ = inject(Actions);

  private readonly searchApi = inject(SearchApi);

  private readonly store = inject(Store);

  public readonly loadFavoriteSearches = createEffect(() =>
    this.actions$.pipe(
      ofType(userSearchActions.loadFavoriteSearches),
      switchMap(() =>
        this.searchApi.getFavoriteSearches().pipe(
          map((searches) => userSearchActions.loadFavoriteSearchesSuccess({ searches: searches })),
          catchError((error) =>
            of(userSearchActions.loadFavoriteSearchesFailure({ error: error })),
          ),
        ),
      ),
    ),
  );

  public readonly loadFavoriteSearchesFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(userSearchActions.loadFavoriteSearchesFailure, {
          message: 'Er is iets misgegaan bij het ophalen van favoriete zoekopdrachten.',
        }),
      ),
    { dispatch: false },
  );

  public readonly setFavorite = createEffect(() =>
    this.actions$.pipe(
      ofType(userSearchActions.toggleFavorite),
      concatLatestFrom(() => this.store.pipe(select(UserSearchSelectors.userSearchesEntities))),
      switchMap(([action, userSearches]) => {
        const search: UserSearchContract | undefined = userSearches[action.favorite];
        if (!search) {
          throw Error('user search not found');
        }
        return this.searchApi.setFavoriteSearch(search).pipe(
          map((favorite) => userSearchActions.toggleFavoriteSuccess({ favorite: favorite })),
          catchError((error) => of(userSearchActions.toggleFavoriteFailure({ error: error }))),
        );
      }),
    ),
  );

  public readonly setFavoriteFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(userSearchActions.toggleFavoriteFailure, {
          message: 'Er is iets misgegaan bij het opslaan van de zoekopdracht als favoriet.',
        }),
      ),
    { dispatch: false },
  );
}
