import {
  AssignmentStateDisplayValues,
  AssignmentStateEnum,
} from '@scheduler-frontend/assignment-contracts';
import { TransitionEnum } from '../enums/transition.enum';
import { AssignmentTransition } from './assignment-transition.model';

export class TransitionLogRecord extends AssignmentTransition {
  public get from(): AssignmentStateEnum {
    return this.previousState as AssignmentStateEnum;
  }

  public get to(): AssignmentStateEnum {
    return this.enteredState;
  }

  public get name(): TransitionEnum {
    return this.transitionName;
  }

  public override toString(): string {
    return (
      `Status is veranderd van ${AssignmentStateDisplayValues[this.from]} ` +
      `maar ${AssignmentStateDisplayValues[this.to]}`
    );
  }
}
