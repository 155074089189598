import { Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LetDirective } from '@ngrx/component';
import { AssignmentsStoreService } from '@scheduler-frontend/data-access-assignment';

@Component({
  selector: 'app-general-information',
  templateUrl: './payout-information.component.html',
  styleUrls: ['./payout-information.component.scss'],
  standalone: true,
  imports: [LetDirective, IonicModule],
})
export class PayoutInformationComponent {
  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);
}
