import { Expose } from 'class-transformer';

/**
 * Standard version of the Product type resource from Scheduler-api
 */
export class Ranking {
  /**
   * The calculated result giving weight to the travel burden the candidate will feel
   */
  @Expose() public travelBurden!: number;

  /**
   * The calculated result giving weight to the quality of the candidates previous assignments
   *
   * gather from survey under pupils.
   */
  @Expose() public quality!: number;

  /**
   * The calculated result giving weight to the availability of the candidate
   */
  @Expose() public availability!: number;

  /**
   * The calculated result giving weight to on who long the candidate has been waiting
   * since being hired.
   */
  @Expose() public waitingTimeSinceHired!: number;

  /**
   * The calculated result giving weight to on who long the candidate has been waiting
   * since his previous assignment.
   */
  @Expose() public waitingTimeSinceLastAssignment!: number;

  /**
   * The calculated result giving weight to how efficient this candidate with his
   * skillset is on a slot.
   *
   * for example, we have but a few candidate who can do Wiskunde and many that
   * can give Nederlands. Then it would be better to assign the candidate to the
   * shift where he gives Wiskunde instead of Nederlands.
   */
  @Expose() public skillEfficiency!: number;

  /**
   *The Accumulated ranking grade base on the properties above.
   */
  @Expose() public total!: number;
}
