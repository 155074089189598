import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { LocationContract } from '../../../contracts/location.contract';
import { LocationRequest } from './location.request';

@Injectable({
  providedIn: 'root',
})
export class LocationApi {
  private readonly httpClient = inject(HttpClient);

  /**
   * Fetch a list of locations. Resource objects with names only.
   */
  public getLocations(request: LocationRequest): Observable<Collection<LocationContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/locations`;

    let params: HttpParams = new HttpParams({
      fromObject: {
        //eslint-disable-next-line @typescript-eslint/naming-convention
        'include_archived': request.includeArchived ?? false,
        //eslint-disable-next-line @typescript-eslint/naming-convention
        'location_types[]': request.locationTypes,
      },
    });

    if (request.pupil) {
      params = params.set('pupil', request.pupil);
    }

    return this.httpClient.get<Collection<LocationContract>>(url, {
      params: params,
    });
  }
}
