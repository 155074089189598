import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { slotsActions } from '@scheduler-frontend/data-access-slots';
import { filter, map } from 'rxjs';
import { candidatesByRankingActions } from '../candidates-by-ranking.actions';

@Injectable()
export class UpdateSlotStoreEffect {
  private readonly actions$ = inject(Actions);

  public addItemsToSlotStore = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.loadSlotsByCandidateSuccess),
      filter((action) => !!action.items),
      map((action) =>
        slotsActions.loadSlotsSuccess({
          slots: action.items.map((item) => item.slot),
          totalItems: action.items.length,
          chunk: 1,
        }),
      ),
    ),
  );

  public addAlternativeItemsToSlotStore = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.loadAlternativeShiftsSuccess),
      map((action) => {
        const slots = Object.values(action.items).flat();
        return slotsActions.loadSlotsSuccess({
          slots: slots,
          totalItems: slots.length,
          chunk: 1,
        });
      }),
    ),
  );
}
