import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideAbsenceStore } from '@scheduler-frontend/data-access-absence';
import { provideBusinessServicesStore } from '@scheduler-frontend/data-access-business-services';
import { provideCandidatesStore } from '@scheduler-frontend/data-access-candidates';
import { provideDeclineReasonsStore } from '@scheduler-frontend/data-access-decline-reasons';
import { ActiveAssignmentHistoryEffects } from './+state/effects/active-assignment-history.effects';
import { ActiveAssignmentEffects } from './+state/effects/active-assignment.effects';
import { AssignmentCompensationsEffects } from './+state/effects/assignment-compensations.effects';
import { AssignmentTransitionsEffects } from './+state/effects/assignment-transitions.effects';
import { AssignmentsEffects } from './+state/effects/assignments.effects';
import { UnassignAssignmentsEffects } from './+state/effects/unassign-assignments.effects';
import {
  ACTIVE_ASSIGNMENT_HISTORY_FEATURE_KEY,
  activeAssignmentHistoryReducer,
} from './+state/reducer/active-assignment-history.reducer';
import {
  ACTIVE_ASSIGNMENT_FEATURE_KEY,
  activeAssignmentReducer,
} from './+state/reducer/active-assignment.reducer';
import {
  ASSIGNMENT_COMPENSATIONS_FEATURE_KEY,
  assignmentCompensationsReducer,
} from './+state/reducer/assignment-compensation.reducer';
import {
  ASSIGNMENT_TRANSITIONS_FEATURE_KEY,
  assignmentTransitionsReducer,
} from './+state/reducer/assignment-transitions.reducer';
import { ASSIGNMENTS_FEATURE_KEY, assignmentsReducer } from './+state/reducer/assignments.reducer';
import { ActiveAssignmentsPermissionsStoreService } from './active-assignments-permissions-store.service';
import { AssignmentsPermissionsStoreService } from './assignments-permissions-store.service';
import { AssignmentsStoreService } from './assignments-store.service';

export function provideAssignmentsStore(): Provider | EnvironmentProviders[] {
  return [
    provideAbsenceStore(),
    provideCandidatesStore(),
    provideDeclineReasonsStore(),
    provideBusinessServicesStore(),
    provideState({ name: ASSIGNMENTS_FEATURE_KEY, reducer: assignmentsReducer }),
    provideState({ name: ACTIVE_ASSIGNMENT_FEATURE_KEY, reducer: activeAssignmentReducer }),
    provideState({
      name: ACTIVE_ASSIGNMENT_HISTORY_FEATURE_KEY,
      reducer: activeAssignmentHistoryReducer,
    }),
    provideState({
      name: ASSIGNMENT_COMPENSATIONS_FEATURE_KEY,
      reducer: assignmentCompensationsReducer,
    }),
    provideState({
      name: ASSIGNMENT_TRANSITIONS_FEATURE_KEY,
      reducer: assignmentTransitionsReducer,
    }),
    provideEffects(
      AssignmentsEffects,
      AssignmentCompensationsEffects,
      ActiveAssignmentEffects,
      ActiveAssignmentHistoryEffects,
      UnassignAssignmentsEffects,
      AssignmentTransitionsEffects,
    ),
    AssignmentsStoreService,
    ActiveAssignmentsPermissionsStoreService,
    AssignmentsPermissionsStoreService,
  ];
}
