import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { format } from 'date-fns';
import { EditSlotStoreService } from '../../edit-slot-store.service';

@Component({
  selector: 'app-slot-details',
  templateUrl: './slot-details.component.html',
  styleUrls: ['./slot-details.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe],
})
export class SlotDetailsComponent {
  protected readonly editSlotStoreService = inject(EditSlotStoreService);

  protected readonly lessonName = computed(() => {
    const lesson = this.editSlotStoreService.slot()?.lesson;
    if (!(lesson && 'date' in lesson)) {
      return;
    }
    if (lesson && 'name' in lesson && lesson.name) {
      return `${lesson.name} - ${format(lesson.date, 'dd-MM-yyyy')} - (${
        lesson.numberOfPupils
      } leerlingen)`;
    }
    return `${format(lesson.date, 'dd-MM-yyyy')} - (${lesson.numberOfPupils} leerlingen)`;
  });
}
