import { TsRange } from '@techniek-team/class-transformer';
import { addYears, Duration, endOfDay, startOfDay, subDays } from 'date-fns';
import { add as dateFnsAdd } from 'date-fns/add';

export function createRangeFromDuration(
  datePoint: Date | string,
  duration: Duration | 'infinite',
): TsRange {
  if (duration === 'infinite') {
    // todo add infinite support to tsRange
    return new TsRange(startOfDay(datePoint), addYears(datePoint, 200), true, true);
  }
  const endDate: Date = subDays(dateFnsAdd(datePoint, duration), 1);
  return new TsRange(startOfDay(datePoint), endOfDay(endDate), true, true);
}
