import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { approachesActions } from '@scheduler-frontend/data-access-approaches';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { candidatesByRankingActions } from './candidates-by-ranking.actions';

export const CANDIDATES_BY_RANKING_FEATURE_KEY = 'candidatesByRanking';

// we only hold a list of candidate iri which can be retrieved from the candidates store
export interface CandidatesByRankingState extends EntityState<string> {
  updatedAt: Date | null;
  loaded: boolean;
  loading: boolean;
  loadedChunks: number[];
  lastChunkLoaded: boolean;
  totalItems?: number | null;
  error?: unknown | null;
}
export const adapter = createEntityAdapter<string>({
  selectId: jsonLdSelectId,
  sortComparer: false,
});
export const initialCandidatesByRankingState: CandidatesByRankingState = adapter.getInitialState({
  updatedAt: null,
  loaded: false,
  loading: false,
  loadedChunks: [],
  lastChunkLoaded: false,
});

const reducer = createReducer(
  initialCandidatesByRankingState,
  on(candidatesByRankingActions.initCandidatesByRanking, (state) => ({
    ...state,
    loadedChunks: [],
    totalItems: undefined,
    loaded: false,
    loading: true,
    lastChunkLoaded: false,
    error: null,
  })),
  on(candidatesByRankingActions.loadNextChunk, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(candidatesByRankingActions.refreshCandidatesByRanking, (state) => ({
    ...state,
    ids: [],
    loadedChunks: [],
    totalItems: undefined,
    loaded: false,
    loading: true,
    lastChunkLoaded: false,
    error: null,
  })),
  on(
    candidatesByRankingActions.loadCandidatesByRankingSuccess,
    (state, { candidateIds, chunk, totalItems }) =>
      adapter.setAll(candidateIds, {
        ...state,
        loaded: true,
        loading: false,
        loadedChunks: [chunk],
        lastChunkLoaded: candidateIds.length === totalItems,
        totalItems: totalItems,
        error: null,
      }),
  ),
  on(
    candidatesByRankingActions.loadNextChunkSuccess,
    (state, { candidateIds, chunk, totalItems }) =>
      adapter.addMany(candidateIds, {
        ...state,
        loaded: true,
        loading: false,
        loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()] as number[],
        lastChunkLoaded: state.ids.length + candidateIds.length === totalItems,
        totalItems: totalItems,
        error: null,
      }),
  ),
  on(candidatesByRankingActions.removeCandidateForCandidateByRankingList, (state, { candidate }) =>
    adapter.removeOne(candidate, {
      ...state,
      totalItems: state.totalItems ? Math.min(state.totalItems - 1, 0) : state.totalItems,
    }),
  ),
  on(candidatesByRankingActions.loadCandidatesByRankingFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(candidatesByRankingActions.loadSlotByCandidateFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(approachesActions.setActiveCandidateSearch, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
);

export function candidatesByRankingReducer(
  state: CandidatesByRankingState | undefined,
  action: Action,
): CandidatesByRankingState {
  return reducer(state, action);
}
