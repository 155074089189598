import { EnvironmentProviders, Provider } from '@angular/core';
import { provideState } from '@ngrx/store';
import { RANKINGS_FEATURE_KEY, rankingsReducer } from './+state/rankings.reducer';
import { RankingsStoreService } from './rankings-store.service';

export function provideRankingsStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: RANKINGS_FEATURE_KEY, reducer: rankingsReducer }),
    RankingsStoreService,
  ];
}
