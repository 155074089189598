import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import {
  CandidateContract,
  CandidateDetailedContract,
} from '@scheduler-frontend/candidate-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { candidatesSearchActions } from '../actions/candidates-search.actions';

export const CANDIDATES_SEARCH_FEATURE_KEY = 'candidatesSearch';

export interface CandidatesSearchState
  extends EntityState<CandidateContract | CandidateDetailedContract> {
  loading: boolean;

  loaded: boolean;

  searchQuery?: string;

  loadedChunks: number[];

  lastChunkLoaded: boolean;

  error: unknown;

  totalItems?: number;
}

//eslint-disable-next-line max-len
export const candidatesSearchAdapter: EntityAdapter<CandidateContract | CandidateDetailedContract> =
  createEntityAdapter<CandidateContract | CandidateDetailedContract>({
    selectId: jsonLdSelectId,
  });

export const initialCandidatesSearchState: CandidatesSearchState =
  candidatesSearchAdapter.getInitialState({
    error: null,
    loading: false,
    loaded: false,
    loadedChunks: [],
    lastChunkLoaded: false,
  });

const reducer: ActionReducer<CandidatesSearchState> = createReducer(
  initialCandidatesSearchState,
  on(
    candidatesSearchActions.loadCandidateSearch,
    candidatesSearchActions.clearSearchQuery,
    candidatesSearchActions.setCandidateSearchQuery,
    (state, action) => {
      return candidatesSearchAdapter.removeAll({
        ...state,
        searchQuery: action && 'query' in action ? action.query : undefined,
        loading: true,
        chunk: [],
        error: null,
        lastChunkLoaded: false,
      });
    },
  ),
  on(
    candidatesSearchActions.loadCandidateSearchSuccess,
    (state, { candidates, chunk, totalItems }) => {
      return candidatesSearchAdapter.setAll(candidates, {
        ...state,
        loading: false,
        loaded: true,
        loadedChunks: [chunk],
        lastChunkLoaded: candidates.length === totalItems,
        error: null,
      });
    },
  ),
  on(candidatesSearchActions.refresh, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(candidatesSearchActions.loadCandidateSearchFailure, (state, error) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(candidatesSearchActions.loadCandidateSearchNextChunk, (state, error) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    candidatesSearchActions.loadCandidateSearchNextChunkSuccess,
    (state, { candidates, chunk, totalItems }) =>
      candidatesSearchAdapter.addMany(candidates, {
        ...state,
        loading: false,
        loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()],
        lastChunkLoaded: state.ids.length + candidates.length === totalItems,
        error: null,
      }),
  ),
);

export function candidatesSearchReducer(
  state: CandidatesSearchState | undefined,
  action: Action,
): CandidatesSearchState {
  return reducer(state, action);
}
