import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AssignmentDetailedContract } from '@scheduler-frontend/assignment-contracts';

export const candidateAssignmentsHistoryActions = createActionGroup({
  source: 'Candidate/Assignments/History',
  events: {
    loadSuccess: props<{
      assignments: AssignmentDetailedContract[];
      chunk: number;
      totalItems: number;
    }>(),
    loadFailure: props<{ error: Error }>(),
    refresh: emptyProps(),
    loadNextChunk: emptyProps(),
    loadNextChunkSuccess: props<{
      assignments: AssignmentDetailedContract[];
      chunk: number;
      totalItems: number;
    }>(),
  },
});
