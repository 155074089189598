<h4>Algemene informatie</h4>

<ng-container
  *ngrxLet="
    assignmentsStoreService.activeAssignmentCompensation$ as compensation;
    suspenseTpl: loading
  "
>
  <div>
    <div class="label">Project ID</div>
    <div data-test="payout-information-projectCode">{{ compensation.projectCode }}</div>
  </div>
  <div>
    <div class="label">Divisie</div>
    <div data-test="payout-information-division">{{ compensation.division }}</div>
  </div>
</ng-container>
<ng-template #loading>
  <div>
    <div class="label">Project ID</div>
    <div>
      <ion-skeleton-text animated="true" style="width: 80px"></ion-skeleton-text>
    </div>
  </div>
  <div>
    <div class="label">Divisie</div>
    <div>
      <ion-skeleton-text animated="true" style="width: 40px"></ion-skeleton-text>
    </div>
  </div>
</ng-template>
