import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { businessEntitiesAdapter } from '@scheduler-frontend/data-access-business-entities';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { LocationContract } from '../contracts/location.contract';
import { LocationTypeEnum } from '../enums/location-type.enum';
import { locationsActions } from './locations.actions';

export const LOCATIONS_FEATURE_KEY = 'locations';

export interface LocationsState extends EntityState<LocationContract> {
  selectedId?: string; // which Locations record has been selected
  loaded: boolean; // has the Locations list been loaded
  loading: boolean; // is the Locations busy with loading
  error?: string | null; // last known error (if any)
  totalItems: number | null;
  locationTypesLoaded: LocationTypeEnum[];
  archivedLocationTypesLoaded: LocationTypeEnum[];
  pupilPrivateLocationsLoaded: boolean;
  currentPupilPrivateLocations: LocationContract[];
  cacheTimeStamp?: string;
}

// prettier-ignore
export const locationsAdapter: EntityAdapter<LocationContract> = createEntityAdapter<LocationContract>({
  selectId: jsonLdSelectId,
});

export const initialState: LocationsState = locationsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
  initLoading: false,
  totalItems: null,
  locationTypesLoaded: [],
  archivedLocationTypesLoaded: [],
  pupilPrivateLocationsLoaded: false,
  currentPupilPrivateLocations: [],
});

const reducer = createReducer(
  initialState,
  on(locationsActions.initLocations, (state, { locationTypes, includeArchived }) => {
    let archivedLocationTypesLoaded = state.archivedLocationTypesLoaded;
    if (includeArchived) {
      archivedLocationTypesLoaded = [
        ...new Set([...archivedLocationTypesLoaded, ...locationTypes]),
      ] as LocationTypeEnum[];
    }
    return {
      ...state,
      loaded: false,
      loading: true,
      error: null,
      locationTypesLoaded: [
        ...new Set([...state.locationTypesLoaded, ...locationTypes]),
      ] as LocationTypeEnum[],
      archivedLocationTypesLoaded: archivedLocationTypesLoaded,
    };
  }),
  on(locationsActions.loadLocationsSuccess, (state, { locations, totalItems }) =>
    locationsAdapter.setAll(locations, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: formatISO(new Date()),
    }),
  ),
  on(
    locationsActions.loadFromCacheLocationsSuccess,
    (state, { locations, totalItems, cacheTimestamp, locationTypes }) =>
      businessEntitiesAdapter.setAll(locations, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        locationTypesLoaded: [
          ...new Set([...state.locationTypesLoaded, ...locationTypes]),
        ] as LocationTypeEnum[],
        cacheTimeStamp: cacheTimestamp,
      }),
  ),
  on(locationsActions.loadLocationsFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
    locationTypesLoaded: [],
    archivedLocationTypesLoaded: [],
  })),
  on(locationsActions.selectLocation, (state, { locationId }) => ({
    ...state,
    selectedId: locationId,
  })),
  on(locationsActions.loadPupilPrivateLocations, (state) => {
    return {
      ...state,
      currentPupilPrivateLocations: [],
      loading: true,
      pupilPrivateLocationsLoaded: false,
    };
  }),
  on(locationsActions.loadPupilPrivateLocationsSuccess, (state, { locations }) => {
    return {
      ...state,
      currentPupilPrivateLocations: locations,
      loading: false,
      pupilPrivateLocationsLoaded: true,
    };
  }),
);

export function locationsReducer(
  state: LocationsState | undefined,
  action: Action,
): LocationsState {
  return reducer(state, action);
}
