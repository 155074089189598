import { Action, createReducer, on } from '@ngrx/store';
import {
  nextRangeInView,
  previousRangeInView,
  rangeInView,
  RoutesView,
  weekViewDateRangeInView,
} from '@scheduler-frontend/scheduling-common';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { formatISO, parseISO } from 'date-fns';
import { schedulingViewActions } from '../actions/scheduling-view.actions';
import { schedulingActions } from '../actions/scheduling.actions';

export const SCHEDULING_VIEW_FEATURE_KEY = 'schedulingView';

export interface SchedulingViewState {
  timeRangeInView: PlainRangeInterface<string> | 'infinite';
  currentView: RoutesView;
  showInlineAssignButtons: boolean;
  showSlotSelectionCheckbox: boolean;
  showOnlySelectedSlotsInView: boolean;
}

export const initialSchedulingViewState: SchedulingViewState = {
  timeRangeInView: weekViewDateRangeInView(new Date()),
  currentView: RoutesView.WEEK,
  showInlineAssignButtons: false,
  showSlotSelectionCheckbox: true,
  showOnlySelectedSlotsInView: false,
};

const reducer = createReducer(
  initialSchedulingViewState,
  on(
    schedulingActions.reset,
    (state) =>
      ({
        ...state,
        timeRangeInView: weekViewDateRangeInView(new Date()),
        showInlineAssignButtons: false,
        showSlotSelectionCheckbox: true,
        showOnlySelectedSlotsInView: false,
      }) as SchedulingViewState,
  ),
  on(schedulingViewActions.setSchedulingView, (state, { view, dateInView }) => {
    const newView = view ?? state.currentView;
    if (newView === RoutesView.TABLE) {
      return { ...state, timeRangeInView: 'infinite' as 'infinite', currentView: newView };
    }

    if (!dateInView) {
      if (state.timeRangeInView === 'infinite') {
        dateInView = formatISO(new Date());
      } else {
        dateInView = state.timeRangeInView.start;
      }
    }
    if (dateInView === 'infinite') {
      dateInView = formatISO(new Date());
    }

    return {
      ...state,
      timeRangeInView: rangeInView(dateInView, view ?? state.currentView),
      currentView: view ?? state.currentView,
    };
  }),
  on(schedulingActions.setTimeRangeInView, (state, { range }) => {
    return {
      ...state,
      timeRangeInView: range,
    };
  }),
  on(schedulingViewActions.nextRangeInView, (state) => {
    return {
      ...state,
      timeRangeInView: nextRangeInView(
        state.timeRangeInView === 'infinite' ? 'infinite' : parseISO(state.timeRangeInView.end),
        state.currentView,
      ),
    };
  }),
  on(schedulingViewActions.previousRangeInView, (state) => {
    return {
      ...state,
      timeRangeInView: previousRangeInView(
        state.timeRangeInView === 'infinite' ? 'infinite' : parseISO(state.timeRangeInView.start),
        state.currentView,
      ),
    };
  }),
  on(schedulingViewActions.changeCurrentViewSuccess, (state, { view, date }) => {
    let targetDate: string | Date = new Date();
    if (state.timeRangeInView !== 'infinite') {
      targetDate = date ?? state.timeRangeInView.start;
    }
    return {
      ...state,
      currentView: view,
      timeRangeInView: rangeInView(targetDate, view),
    };
  }),
  on(schedulingViewActions.showInlineAssignButtons, (state) => ({
    ...state,
    showInlineAssignButtons: true,
  })),
  on(schedulingViewActions.hideInlineAssignButtons, (state) => ({
    ...state,
    showInlineAssignButtons: false,
  })),
  on(schedulingViewActions.showSlotSelectionCheckbox, (state) => ({
    ...state,
    showSlotSelectionCheckbox: true,
  })),
  on(schedulingViewActions.hideSlotSelectionCheckbox, (state) => ({
    ...state,
    showSlotSelectionCheckbox: false,
  })),
  on(schedulingViewActions.showOnlySelectedSlotsInView, (state) => ({
    ...state,
    showOnlySelectedSlotsInView: true,
  })),
  on(schedulingViewActions.stopShowingOnlySelectedSlotsInView, (state) => ({
    ...state,
    showOnlySelectedSlotsInView: false,
  })),
);

export function schedulingViewReducer(state: SchedulingViewState | undefined, action: Action) {
  return reducer(state, action);
}
