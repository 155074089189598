import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ABSENCE_FEATURE_KEY, AbsencesState } from './absence.reducer';

export class AbsenceSelectors {
  public static readonly absencesState = createFeatureSelector<AbsencesState>(ABSENCE_FEATURE_KEY);

  public static readonly savingMarkAsAbsent = createSelector(
    AbsenceSelectors.absencesState,
    (state: AbsencesState) => state.savingMarkAsAbsent,
  );

  public static readonly savingMarkAsPresent = createSelector(
    AbsenceSelectors.absencesState,
    (state: AbsencesState) => state.savingMarkAsPresent,
  );

  public static readonly error = createSelector(
    AbsenceSelectors.absencesState,
    (state: AbsencesState) => state.error,
  );
}
