import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { approachesActions } from '@scheduler-frontend/data-access-approaches';
import { candidatesActions } from '@scheduler-frontend/data-access-candidates';
import { schedulingActions, SchedulingSelectors } from '@scheduler-frontend/data-access-scheduling';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { filter, map } from 'rxjs';
import { candidatesByRankingActions } from '../candidates-by-ranking.actions';
import { CandidatesByRankingSelectors } from '../candidates-by-ranking.selectors';

@Injectable()
export class ModifyCandidatesByRankingListEffect {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  /**
   * This ensures that the candidate Detail pane has an active candidate.
   */
  public markFirstSelectedCandidateAsActiveCandidateEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingActions.selectCandidates),
      concatLatestFrom(() => this.store.pipe(select(SchedulingSelectors.selectedCandidateIds))),
      filter(([_action, selectedCandidates]) => !!selectedCandidates?.length),
      map(([_action, selectedCandidates]) => {
        return candidatesActions.setActiveCandidate({ selectedId: selectedCandidates[0] });
      }),
    ),
  );

  public loadNextChunkOnSmallAmountOfCandidatesInList = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesByRankingActions.removeCandidateForCandidateByRankingList),
      concatLatestFrom(() =>
        this.store.pipe(select(CandidatesByRankingSelectors.candidatesLength)),
      ),
      filter(([_action, length]) => length < 50),
      map((_event) => candidatesByRankingActions.loadNextChunk()),
    ),
  );

  public removeCandidateOnApproachCreated = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.approachCreated),
      map((action) =>
        candidatesByRankingActions.removeCandidateForCandidateByRankingList({
          candidate: jsonLdSelectId(action.approach.candidate['@id']),
        }),
      ),
    ),
  );
}
