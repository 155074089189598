import { AsyncPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { SlotDetailedWithAssignmentHasSlot } from '@scheduler-frontend/assignment-contracts';
import { AwaitingSlotsStoreService } from '@scheduler-frontend/data-access-awaiting-slots';
import { LocationModel, LocationsStoreService } from '@scheduler-frontend/data-access-locations';
import { UsersStoreService } from '@scheduler-frontend/data-access-users';
import {
  HeaderComponent,
  HeaderMenuComponent,
  LocationComponent,
} from '@scheduler-frontend/header';
import { TtStatisticCardModule } from '@techniek-team/components/statistic-card';
import { PermissionService, TtPermissionModule } from '@techniek-team/permissions';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { combineLatest, from, mergeMap, Observable, Subject } from 'rxjs';
import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';
import { LocationDashboardApi } from '../api/location-dashboard/location-dashboard.api';
import { LocationDashboardFinalizeResponse } from '../api/location-dashboard/location-dashboard.response';
import { PayoutApi } from '../api/payout/payout.api';
import { ActualWorkingTimesApi } from '../api/slot/actual-working-times/actual-working-times.api';
import { ActionListComponent } from './action-list/action-list.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  standalone: true,
  imports: [
    HeaderComponent,
    LocationComponent,
    HeaderMenuComponent,
    ActionListComponent,
    RouterLink,
    TtStatisticCardModule,
    TtPermissionModule,
    AsyncPipe,
    IonicModule,
  ],
})
export class HomePage implements OnInit, OnDestroy {
  public readonly locationsStoreService = inject(LocationsStoreService);

  private readonly actualWorkingTimesApi = inject(ActualWorkingTimesApi);

  private readonly locationDashboardApi = inject(LocationDashboardApi);

  private readonly toastService = inject(ToastService);

  private readonly permissionService = inject(PermissionService);

  protected readonly usersStoreService = inject(UsersStoreService);

  private readonly storage = inject(Storage);

  private readonly sentryErrorHandler = inject(SentryErrorHandler);

  private readonly payoutApi = inject(PayoutApi);

  protected readonly awaitingSlotsStoreService = inject(AwaitingSlotsStoreService);

  /**
   * @var  finalizeList$ Observer with a List of assignments that need
   * to be finalized.
   */
  public finalizeList$!: Observable<LocationDashboardFinalizeResponse[]>;

  /**
   * Emits true if user is a scheduling team member.
   */
  protected isSchedulingTeam$!: Observable<boolean>;

  protected slotsWithHoursToApproveCount$!: Observable<Map<string, number>>;

  protected candidateAwaitingPayoutApproval$!: Observable<Map<string, number>>;

  /**
   * Subject with which we can destroy all others hot observers
   */
  private onDestroy$: Subject<void> = new Subject();

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.isSchedulingTeam$ = from(this.permissionService.is('schedulingTeam'));
    this.finalizeList$ = this.createFinalizeListObserver();
    this.slotsWithHoursToApproveCount$ = this.createSlotsWithHoursToApproveCountObservable();
    this.candidateAwaitingPayoutApproval$ = this.createCandidateAwaitingPayoutApprovalObservable();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  /**
   * The method creates an Observer which return a list of slot that needs
   * to be finalized
   */
  private createFinalizeListObserver(): Observable<LocationDashboardFinalizeResponse[]> {
    return this.locationsStoreService.currentLocation$.pipe(
      takeUntil(this.onDestroy$),
      mergeMap((location) => {
        return this.locationDashboardApi.getLocationFinalizeList(location);
      }),
      shareReplay(1),
    );
  }

  private createSlotsWithHoursToApproveCountObservable(): Observable<
    Map<'current' | 'previous', number>
  > {
    return this.locationsStoreService.currentLocation$.pipe(
      takeUntil(this.onDestroy$),
      mergeMap((location: LocationModel) => {
        const storageKey: string = location.getId() + '_shifts-with-hours-to-approve';

        return combineLatest([
          this.actualWorkingTimesApi.getSlotsWithoutActualTimesLocation(location),
          from(this.storage.get(storageKey)).pipe(map((value) => +value)),
        ]).pipe(
          map(([slots, previousCount]: [SlotDetailedWithAssignmentHasSlot[], number]) => {
            return new Map<'current' | 'previous', number>([
              ['current', slots.length],
              ['previous', previousCount],
            ]);
          }),
          catchError((error) => {
            return from(
              Promise.all([
                this.sentryErrorHandler.captureError(error),
                this.toastService.error(
                  this.sentryErrorHandler.extractMessage(
                    error,
                    'Het ophalen van de door te geven shifts is mislukt.',
                  ),
                ),
              ]).then(() => undefined),
            );
          }),
        );
      }),
      isDefined(),
      shareReplay(1),
    );
  }

  //eslint-disable-next-line max-lines-per-function
  private createCandidateAwaitingPayoutApprovalObservable(): Observable<
    Map<'current' | 'previous', number>
  > {
    return this.locationsStoreService.currentLocation$.pipe(
      isDefined(),
      takeUntil(this.onDestroy$),
      mergeMap((location: LocationModel) => {
        const storageKey: string = location.getId() + '_candidate-awaiting-payout-approval';

        return combineLatest([
          this.payoutApi.getLocationPayoutWaitingForApprovals(location),
          from(this.storage.get(storageKey)).pipe(map((value) => +value)),
        ]).pipe(
          map(([payouts, previousCount]) => {
            return new Map<'current' | 'previous', number>([
              ['current', payouts.totalItems],
              ['previous', previousCount],
            ]);
          }),
          catchError((error) => {
            let message: string;

            if (error.status === 403) {
              message = 'Je hebt niet de juiste rechten om uren goed te keuren voor deze locatie.';
            } else {
              message = 'Er is iets misgegaan bij het ophalen van de uitbetalingen.';
            }

            return from(
              Promise.all([
                this.sentryErrorHandler.captureError(error),
                this.toastService.error(this.sentryErrorHandler.extractMessage(error, message)),
              ]).then(() => undefined),
            );
          }),
          isDefined(),
          shareReplay(1),
        );
      }),
    );
  }
}
