import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TtAvatarComponent } from './tt-avatar.component';

/**
 * @deprecated removed in next major version
 */
@NgModule({
    imports: [
        TtAvatarComponent,
    ],
    exports: [TtAvatarComponent],
})
export class TtAvatarModule {
}
