import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';

/**
 * The standard version of the Action Resource.
 */
export class SlotAction extends JsonLd {
  /**
   * Description of this action
   */
  @Expose() public description!: string;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.description;
  }
}
