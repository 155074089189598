import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaConfig, FaIconLibrary, FaIconComponent } from '@fortawesome/angular-fontawesome';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { IonicModule } from '@ionic/angular';

/**
 * A generic implementation to be used within a modal. You can determine how
 * many slots you want to use and which data you want inside them. There are
 * a total of 3 slot (left, center, right) and also a footer that will
 * be displayed if an action and/or cancel button should be displayed.
 *
 * @example
 * ```html
 * <tt-modal>
 *   <div>My awesome content</div>
 *   <div slot="right" class="description">This is a description within the right side slot</div>
 *   <ion-list slot="right>
 *     <ion-item>Am I right?</item>
 *   </ion-list>
 *   <ion-button slot="footer">Cancel</ion-button>
 * </tt-modal>
 * ```
 *
 * It's possible to change the close icon to an ion icon, fontawesome icon or svg,
 * to disable the close button or to hide it completely
 * When the close button is clicked the component emits a `cancel` event.
 *
 * @example
 * ```html
 * <tt-modal closeIcon="start"
 *           closeDisabled="true">
 *   <div>My awesome content</div>
 * </tt-modal>
 *
 * <tt-modal [closeIcon]="['fa', 'faHome']"
 *           [closeDisabled]="loading$ | async">
 *   <div>My awesome content</div>
 * </tt-modal>
 *
 * <tt-modal closeHidden="true">
 *   <div>My awesome content</div>
 * </tt-modal>
 * ```
 *
 * It's possible to force the footer to be there even if there is no content
 * @example
 * ```html
 * <tt
 * </tt-modal>-modal forceFooter="true">
 *   <div>My awesome content</div>
 * ```
 */
@Component({
    selector: 'tt-modal',
    templateUrl: './tt-modal.component.html',
    styleUrls: ['./tt-modal.component.scss'],
    standalone: true,
    imports: [IonicModule, FaIconComponent],
})
export class TtModalComponent {

  @Output() public readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Hide the close button when true.
   */
  @Input() public closeHidden: boolean = false;

  /**
   * Disabled the close button when true.
   */
  @Input() public closeDisabled: boolean = false;

  @Input() public forceFooter: boolean = false;

  /**
   * Set the close icon that should be displayed in the upper right corner.
   */
  @Input()
  public set closeIcon(icon: IconProp | string | undefined) {
    if (!icon) {
      return;
    }
    this.isFontAwesomeIcon = this.lookupIsFontAwesomeIcon(icon as IconProp);
    this.iconUrl = icon;
  }

  /**
   * The close icon that should be displayed in the upper right corner.
   */
  protected iconUrl: string | IconProp = 'close-outline';

  private isFontAwesomeIcon: boolean = false;

  constructor(
    private iconLibrary: FaIconLibrary,
    private fontAwesomeConfig: FaConfig,
  ) {
  }

  public isFontAwesome(icon: IconProp | string | undefined): IconProp | undefined {
    return (this.isFontAwesomeIcon) ? icon as IconProp : undefined;
  }

  private lookupIsFontAwesomeIcon(iconSpec: IconDefinition | IconProp): boolean {
    let lookup: IconProp;
    if (Array.isArray(iconSpec) && (iconSpec as string[]).length === 2) {
      lookup = { prefix: iconSpec[0], iconName: iconSpec[1] };
    } else if (typeof iconSpec === 'string') {
      lookup = { prefix: this.fontAwesomeConfig.defaultPrefix, iconName: iconSpec };
    } else {
      return false;
    }

    return !!(this.iconLibrary.getIconDefinition(lookup.prefix, lookup.iconName));
  }
}
