import { Location } from '@angular/common';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { locationsActions, LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { schedulingViewActions } from '@scheduler-frontend/data-access-scheduling';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import {
  SchedulingViewQueryParams,
  setSchedulingViewQueryParam,
} from '@scheduler-frontend/scheduling-common';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { formatISO, isAfter, subMinutes } from 'date-fns';
import { catchError, exhaustMap, filter, from, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScheduleApi } from './api/schedule.api';
import { schedulesActions } from './schedules.actions';
import { SCHEDULES_FEATURE_KEY } from './schedules.reducer';
import { SchedulesSelectors } from './schedules.selectors';

@Injectable()
export class SchedulesEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly destroyRef = inject(DestroyRef);

  private readonly storage = inject(Storage);

  private readonly store = inject(Store);

  private readonly scheduleApi = inject(ScheduleApi);

  private readonly router = inject(Router);

  private readonly location = inject(Location);

  public readonly setSelectedFilterLocationThroughSetScheduleView = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulingViewActions.setSchedulingView),
      filter(
        (
          action,
        ): action is typeof schedulingViewActions.setSchedulingView & {
          selectedProductTypes: string[];
        } => !!action.selectedProductTypes,
      ),
      map((action) =>
        schedulesActions.selectedProductTypeAtLocation({
          productTypeId: action.selectedProductTypes,
        }),
      ),
    ),
  );

  public updateUrlOnFilterLocationChange = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          schedulesActions.deselectedProductTypeAtLocation,
          schedulesActions.selectedProductTypeAtLocation,
          schedulesActions.setSearchQuery,
        ),
        concatLatestFrom(() => [
          this.store.pipe(select(SchedulesSelectors.selectedProductTypesAtLocationDict)),
        ]),
        tap(([_, selectedProductTypesAtLocation]) => {
          const productIds = Object.keys(selectedProductTypesAtLocation);
          setSchedulingViewQueryParam(
            this.location,
            this.router,
            SchedulingViewQueryParams.SELECTED_PRODUCT_TYPES,
            productIds,
          );
        }),
      ),
    { dispatch: false },
  );

  public listenForScheduleCreated = createEffect(() =>
    this.scheduleApi.scheduleCreatedEvents().pipe(
      takeUntilDestroyed(this.destroyRef),
      map((event) => schedulesActions.scheduleCreated({ schedule: event })),
    ),
  );

  public listenForScheduleUpdated = createEffect(() =>
    this.scheduleApi.scheduleUpdatedEvents().pipe(
      takeUntilDestroyed(this.destroyRef),
      map((event) => schedulesActions.scheduleUpdated({ schedule: event })),
    ),
  );

  public listenForScheduleDeleted = createEffect(() =>
    this.scheduleApi.scheduleDeletedEvents().pipe(
      takeUntilDestroyed(this.destroyRef),
      map((event) => schedulesActions.scheduleDeleted({ schedule: event })),
    ),
  );

  public loadLocationSchedulesFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(schedulesActions.loadLocationSchedulesFailure, {
          message: 'Er is iets misgegaan bij het laden de roosters van deze locatie.',
        }),
      ),
    { dispatch: false },
  );

  public createSaveSchedulesToStorageEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(schedulesActions.loadLocationSchedulesSuccess),
        filter((action) => !action.query || action.query.trim() === ''),
        tap((action) => {
          return from(
            Promise.all([
              this.storage.set(`${SCHEDULES_FEATURE_KEY}-${action.location}-store-cache`, {
                items: action.schedules,
                timestamp: new Date(),
              }),
            ]),
          );
        }),
      ),
    { dispatch: false },
  );

  //eslint-disable-next-line max-lines-per-function
  public createInitSchedulesEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(
        schedulesActions.loadLocationSchedules,
        schedulesActions.reloadLocationSchedules,
        locationsActions.selectLocation,
        schedulesActions.setSearchQuery,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(UsersSelectors.activeUser), isDefined()),
        this.store.pipe(select(LocationsSelectors.currentLocationId), isDefined()),
        this.store.pipe(select(SchedulesSelectors.searchQuery)),
      ]),
      exhaustMap(([action, user, location, query]) => {
        if (query && query.trim() !== '') {
          return of([action, user, location, null, query]);
        }
        return from(
          this.storage
            .get(`${SCHEDULES_FEATURE_KEY}-${location}-store-cache`)
            .then((cache) => [action, user, location, cache]),
        );
      }),
      exhaustMap(([_action, _user, location, cache, query]) => {
        if (cache && isAfter(cache.timestamp, subMinutes(new Date(), 15))) {
          return of(
            schedulesActions.loadFromCacheLocationSchedulesSuccess({
              schedules: cache.items,
              totalItems: cache.items.length,
              location: location,
              cacheTimestamp: formatISO(cache.timestamp),
            }),
          );
        }
        return this.scheduleApi.execute(location as string, query).pipe(
          map((response) => {
            return schedulesActions.loadLocationSchedulesSuccess({
              schedules: response['hydra:member'],
              location: location as string,
              totalItems: response['hydra:totalItems'],
              query: query,
            });
          }),
        );
      }),
      catchError((error) => of(schedulesActions.loadLocationSchedulesFailure({ error: error }))),
    ),
  );

  public ngrxOnInitEffects() {
    return schedulesActions.loadLocationSchedules();
  }
}
