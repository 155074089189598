/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dictionary } from '@ngrx/entity';
import { NonNullableDictionary } from './non-nullable-dictionary.function';

export function tupleToNonNullableDictionary<T = any>(
  tuple: [string, T][],
): NonNullableDictionary<T> {
  return tuple.reduce<NonNullableDictionary<T>>((dict, item, index) => {
    if (item[1] || (Array.isArray(item[1]) && (item[1] as any[]).length > 0)) {
      dict[item[0] as string] = item[1];
    }
    return dict;
  }, {});
}

export function tupleToDictionary<T = any>(tuple: [string, T][]): Dictionary<T> {
  return tuple.reduce<Dictionary<T>>((dict, item, index) => {
    dict[item[0] as string] = item[1];
    return dict;
  }, {});
}
