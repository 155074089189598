import { computed, Signal } from '@angular/core';
import { Role } from '@scheduler-frontend/data-access-roles';
import { SubjectModel } from '@scheduler-frontend/data-access-subjects';
import { LevelEnum } from '@scheduler-frontend/enums';
import { TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { isEqual, roundToNearestMinutes } from 'date-fns';
import { SlotDetailed } from '../slot/slot-detailed.model';
import { AssignmentHasSlotDetailed } from './assignment-has-slot-detailed.model';

export class AssignmentHasSlotDetailedWithSlot extends AssignmentHasSlotDetailed {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'AssignmentHasSlotDetailedWithSlot';

  /**
   * The slot or shift a candidate needs to preform.
   *
   * Note: this property is only available when this resource is retrieved
   * through the {@see AssignmentApi}. In other cases look at
   * the parent of this resource
   */
  @Type(() => SlotDetailed)
  @Expose()
  public slot!: SlotDetailed;

  /**
   * Returns either the actualTimePeriod or the slot timePeriod when the actual
   * isn't available.
   */
  public get realTimePeriod(): TsRange {
    if (this.actualTimePeriod) {
      return this.actualTimePeriod;
    }
    return this.slot.timePeriod;
  }

  public get isTimePeriodEqual(): boolean {
    if (!this.actualTimePeriodUpdatedAt || !this.actualTimePeriod) {
      // no actual time period set.
      return false;
    }
    const isStartEqual: boolean = isEqual(
      roundToNearestMinutes(this.slot.timePeriod.start),
      roundToNearestMinutes(this.actualTimePeriod.start),
    );
    const isEndEqual: boolean = isEqual(
      roundToNearestMinutes(this.slot.timePeriod.end),
      roundToNearestMinutes(this.actualTimePeriod.end),
    );
    return isStartEqual && isEndEqual;
  }

  /**
   * Returns the start date of this assignment.
   */
  public get startDate(): Date {
    return this.slot.timePeriod.start;
  }

  /**
   * Returns the end date of this assignment.
   */
  public get endDate(): Date {
    return this.slot.timePeriod.start;
  }

  /**
   * Returns the subject of this assignment.
   */
  public get subject(): Signal<SubjectModel | undefined> {
    return computed(() => this.slot?.lesson.subject());
  }

  /**
   * Returns the level of this assignment.
   */
  public get level(): LevelEnum | undefined {
    return this.slot?.lesson?.level;
  }

  /**
   * Returns the role of this assignment.
   */
  public get role(): Signal<Role | undefined> {
    return this.slot?.role;
  }
}
