import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { searchActions } from '../action/search.actions';

@Injectable()
export class SearchInitEffects {
  private readonly actions$ = inject(Actions);

  public readonly initSchedulingBySearch = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.startSchedulingBySearch),
      map(() => searchActions.initSchedulingBySearch()),
    ),
  );

  public readonly initLocationScheduleSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.startLocationScheduleSearch),
      map(() => searchActions.initLocationScheduleSearch()),
    ),
  );
}
