import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { candidatesSearchActions } from '../actions/candidates-search.actions';
import { CandidateSearchApi } from '../api/candidate/candidate-search.api';
import { CandidatesSearchSelectors } from '../selectors/candidates-search.selectors';

@Injectable()
export class CandidatesSearchEffects {
  private readonly candidateSearchApi = inject(CandidateSearchApi);

  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public loadCandidates = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidatesSearchActions.loadCandidateSearch,
        candidatesSearchActions.setCandidateSearchQuery,
        candidatesSearchActions.clearSearchQuery,
      ),
      concatLatestFrom(() => this.store.pipe(select(CandidatesSearchSelectors.searchQuery))),
      switchMap(([action, query]) => {
        return this.candidateSearchApi.execute(query ?? '', 1).pipe(
          map((response) => {
            return candidatesSearchActions.loadCandidateSearchSuccess({
              candidates: response['hydra:member'],
              chunk: 1,
              totalItems: response['hydra:totalItems'],
            });
          }),
          catchError((error) =>
            of(candidatesSearchActions.loadCandidateSearchFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  public loadCandidatesNextChunk = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesSearchActions.loadCandidateSearchNextChunk),
      concatLatestFrom(() => [
        this.store.select(CandidatesSearchSelectors.lastLoadedChunk),
        this.store.pipe(select(CandidatesSearchSelectors.searchQuery)),
      ]),
      switchMap(([action, lastChunk, query]) => {
        return this.candidateSearchApi.execute(query ?? '', lastChunk + 1).pipe(
          map((response) => {
            return candidatesSearchActions.loadCandidateSearchNextChunkSuccess({
              candidates: response['hydra:member'],
              chunk: lastChunk + 1,
              totalItems: response['hydra:totalItems'],
            });
          }),
          catchError((error) =>
            of(candidatesSearchActions.loadCandidateSearchFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  public loadCandidatesFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidatesSearchActions.loadCandidateSearchFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de begeleider.',
        }),
      ),
    { dispatch: false },
  );
}
