import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { hashParams } from '@scheduler-frontend/common';
import { groupBy, memoize } from 'lodash-es';

/**
 * A memoized function that groups slot contracts by schedule ID.
 *
 * This function receives an array of slot contracts, and returns an object
 * grouped by the `@id` property of the `schedule` in each slot.
 *
 * @param {(SlotContract | SlotDetailedContract)[]} slots - An array of SlotContract or SlotDetailedContract elements.
 * @returns {Dictionary<(SlotContract | SlotDetailedContract)[]>} - Returns a dictionary object where the key is
 *  the schedule ID and the value is an array of slot contracts for that schedule ID.
 *
 * @example
 * const slots = [
 *   {schedule: {'@id': 'schedule1'}, ...otherData},
 *   {schedule: {'@id': 'schedule1'}, ...otherData},
 *   {schedule: {'@id': 'schedule2'}, ...otherData},
 * ];
 *
 * const groupedSlots = groupSlotsBySchedule(slots);
 *
 * // The resulting groupedSlots will look something like this:
 * {
 *   "schedule1": [{schedule: {'@id': 'schedule1'}, ...otherData}, {schedule: {'@id': 'schedule1'}, ...otherData}],
 *   "schedule2": [{schedule: {'@id': 'schedule2'}, ...otherData}],
 * }
 */
export const groupSlotsBySchedule = memoize((slots: (SlotContract | SlotDetailedContract)[]) => {
  return groupBy(slots, (slot) => slot.schedule['@id']);
}, hashParams);
