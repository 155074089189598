<div class="shift-info-container">
  <div class="shift-info-row">
    <div class="label">Producttype</div>
    <div class="value" data-test="slot-details-product-type">
      {{ editSlotStoreService.selectedSlotProductType()?.name }}
    </div>
  </div>
  <div class="shift-info-row">
    <div class="label">Rooster</div>
    <div class="value" data-test="slot-details-scheduler">
      {{ editSlotStoreService.slot()?.schedule?.name }}
    </div>
  </div>
  <div class="shift-info-row">
    <div class="label">Les</div>
    <div class="value" data-test="slot-details-lesson">{{ lessonName() }}</div>
  </div>
  <div class="shift-info-row">
    <div class="label">Locatie</div>
    <div class="value" data-test="slot-details-location">
      @if (editSlotStoreService.selectedSlotLocation()) {
        {{ editSlotStoreService.selectedSlotLocation()?.name }}
      } @else {
        Online
      }
    </div>
  </div>
  <div class="shift-info-row">
    <div class="label">Datum</div>
    <div class="value">
      {{ editSlotStoreService.slot()?.timePeriod?.start | date: 'EEE d MMMM yyyy' }}
    </div>
  </div>
</div>
