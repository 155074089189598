import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SearchContract, SearchDetailedContract } from '../../contract/search.contract';
import { UserSearchContract } from '../../contract/user-search.contract';
import { searchActions } from '../action/search.actions';

export const SEARCH_FEATURE_KEY = 'search';

export interface SearchState extends EntityState<SearchContract | SearchDetailedContract> {
  lastCreatedSearch?: string;
  currentSystemSearch?: string; // which Search record which is currently in view (this is not the search set by the input)
  loaded: boolean; // has the DataAccessSearch list been loaded
  loading: boolean; // is the DataAccessSearch busy with loading
  loadingCurrentSystemSearch: boolean;
  error?: unknown; // last known error (if any)
  schedulingBySearch: boolean;
}

export function searchSelectId(item: SearchContract | UserSearchContract | string): string {
  if (typeof item === 'string') {
    return item;
  }
  if ('hash' in item) {
    return item.hash;
  }
  return item.search.hash;
}

export const searchAdapter = createEntityAdapter<SearchContract | SearchDetailedContract>({
  selectId: searchSelectId,
});

export const initialSearchState: SearchState =
  // set initial required properties
  searchAdapter.getInitialState({
    loaded: false,
    loading: false,
    savingAsFavorite: false,
    loadingCurrentSystemSearch: false,
    schedulingBySearch: false,
  });

const reducer = createReducer(
  initialSearchState,
  on(searchActions.startSchedulingBySearch, (state) => ({
    ...state,
    schedulingBySearch: true,
  })),
  on(searchActions.stopSchedulingBySearch, (state) => ({
    ...state,
    schedulingBySearch: false,
  })),
  on(
    searchActions.createSearchHash,
    searchActions.appendToSearchHash,
    searchActions.removeFromSearchHash,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    searchActions.createSearchHashSuccess,
    searchActions.appendToSearchHashSuccess,
    searchActions.removeFromSearchHashSuccess,
    (state, { search }) =>
      searchAdapter.setOne(search, {
        ...state,
        loading: false,
        loaded: true,
        lastCreatedSearch: search.hash,
      }),
  ),
  on(searchActions.createSearchHashFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(searchActions.setCurrentSystemSearch, (state, { searchId }) => ({
    ...state,
    currentSystemSearch: searchId,
    loadingCurrentSystemSearch: true,
  })),
  on(searchActions.addSlotListToCurrentSearchSuccess, (state, { search }) =>
    searchAdapter.setOne(search, {
      ...state,
      loadingCurrentSystemSearch: false,
      loaded: true,
    }),
  ),
  on(searchActions.addSlotListToCurrentSearchFailure, (state, { error }) => ({
    ...state,
    error: error,
    currentSystemSearch: undefined,
  })),
  on(searchActions.clearCurrentSystemSearch, (state) => ({
    ...state,
    currentSystemSearch: undefined,
  })),
);

export function searchReducer(state: SearchState | undefined, action: Action) {
  return reducer(state, action);
}
