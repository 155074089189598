import { JsonLd } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { AssignmentCompensationLine } from './assignment-compensation-line.model';

export class AssignmentCompensation extends JsonLd {
  @Expose() public state!: string;

  @Expose() public projectCode!: string;

  @Expose() public division!: string;

  @Expose() public total!: string;

  @Type(() => AssignmentCompensationLine)
  @Expose()
  public assignmentCompensationLines: AssignmentCompensationLine[] = [];
}
