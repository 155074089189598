import { TsRange, TsRangeInterface } from '@techniek-team/class-transformer';
import { getHours, getMinutes, parseISO, roundToNearestMinutes, subMinutes } from 'date-fns';
import { memoize } from 'lodash-es';

/**
 * Transforms a timestamp range into a formatted grid column string.
 *
 * @example
 * const tsRange = { start: '2022-03-30T08:00:00.000Z', end: '2022-03-30T20:00:00.000Z'};
 * const formatted = formatTsRangeToGridColumn(tsRange);
 * console.log(formatted); // Output: 'h0800 / h2000'
 */
export const formatTsRangeToGridColumn = memoize(
  (
    tsRange: TsRangeInterface,
    {
      minHour = 8,
      maxHour = 20,
      minMinute = 0,
      maxMinute = 45,
    }: {
      minHour?: number;
      maxHour?: number;
      minMinute?: number;
      maxMinute?: number;
    } = {},
  ): string => {
    const isoStart: Date = roundToNearestMinutes(parseISO(tsRange.start), { nearestTo: 15 });
    const isoEnd: Date = subMinutes(
      roundToNearestMinutes(parseISO(tsRange.end), { nearestTo: 15 }),
      15,
    );

    const hoursStart: string = Math.max(minHour, Math.min(maxHour, getHours(isoStart)))
      .toString()
      .padStart(2, '0');
    const minutesStart: string = Math.max(minMinute, Math.min(maxMinute, getMinutes(isoStart)))
      .toString()
      .padStart(2, '0');

    const hoursEnd: string = Math.max(minHour, Math.min(maxHour, getHours(isoEnd)))
      .toString()
      .padStart(2, '0');
    const minutesEnd: string = Math.max(minMinute, Math.min(maxMinute, getMinutes(isoEnd)))
      .toString()
      .padStart(2, '0');

    return `h${hoursStart}${minutesStart} / h${hoursEnd}${minutesEnd}`;
  },
  (tsRange: TsRangeInterface) => TsRange.fromObject(tsRange).toString(),
);
