import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ActionReducer } from '@ngrx/store/src/models';
import { AssignmentDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { candidateAssignmentsHistoryActions } from '../actions/candidate-assignments-history.actions';
import { candidatesActions } from '../actions/candidates.actions';

export const CANDIDATE_ASSIGNMENTS_HISTORY_FEATURE_KEY: string = 'candidateAssignmentsHistory';

export interface CandidateAssignmentsHistoryState extends EntityState<AssignmentDetailedContract> {
  error?: Error | null;
  loading: boolean;
  loaded: boolean;
  loadedChunks: number[];
  lastChunkLoaded: boolean;
}

export const candidateAssignmentsHistoryAdapter: EntityAdapter<AssignmentDetailedContract> =
  createEntityAdapter<AssignmentDetailedContract>({
    selectId: jsonLdSelectId,
  });

export const initialCandidateAssignmentsHistoryState: CandidateAssignmentsHistoryState =
  candidateAssignmentsHistoryAdapter.getInitialState({
    error: null,
    loading: false,
    loaded: false,
    loadedChunks: [],
    lastChunkLoaded: false,
  });

const reducer: ActionReducer<CandidateAssignmentsHistoryState> = createReducer(
  initialCandidateAssignmentsHistoryState,
  on(candidatesActions.loadActiveCandidateSuccess, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(candidateAssignmentsHistoryActions.loadSuccess, (state, { assignments, chunk, totalItems }) =>
    candidateAssignmentsHistoryAdapter.setAll(assignments, {
      ...state,
      loading: false,
      loaded: true,
      loadedChunks: [chunk],
      lastChunkLoaded: assignments.length === totalItems,
      error: null,
    }),
  ),
  on(candidateAssignmentsHistoryActions.refresh, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(candidateAssignmentsHistoryActions.loadFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(candidateAssignmentsHistoryActions.loadNextChunk, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    candidateAssignmentsHistoryActions.loadNextChunkSuccess,
    (state, { assignments, chunk, totalItems }) =>
      candidateAssignmentsHistoryAdapter.addMany(assignments, {
        ...state,
        loading: false,
        loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()],
        lastChunkLoaded: state.ids.length + assignments.length === totalItems,
        error: null,
      }),
  ),
  on(candidatesActions.clearActiveCandidate, (state) =>
    candidateAssignmentsHistoryAdapter.removeAll({
      ...state,
      loaded: false,
      error: null,
    }),
  ),
);

export function candidateAssignmentsHistoryReducer(
  state: CandidateAssignmentsHistoryState | undefined,
  action: Action,
): CandidateAssignmentsHistoryState {
  return reducer(state, action);
}
