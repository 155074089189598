import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { getRouterSelectors } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import {
  SchedulingViewQueryParams,
  setSchedulingViewQueryParam,
} from '@scheduler-frontend/scheduling-common';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { of, switchMap, tap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { candidatesActions } from '../actions/candidates.actions';
import { CandidateApi } from '../api/candidate/candidate.api';
import { CandidatesSelectors } from '../selectors/candidates.selectors';

export const { selectQueryParams } = getRouterSelectors();

@Injectable()
export class CandidatesEffects {
  private readonly candidateApi = inject(CandidateApi);

  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly router = inject(Router);

  private readonly location = inject(Location);

  public loadActiveCandidate = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesActions.setActiveCandidate),
      concatLatestFrom(() => this.store.pipe(select(CandidatesSelectors.activeCandidateDetails))),
      filter(([action, alreadyLoadedCandidate]) => !!action.selectedId && !alreadyLoadedCandidate),
      switchMap(([action]) => {
        return this.candidateApi.getCandidate(action.selectedId as string).pipe(
          map((response) => {
            return candidatesActions.loadActiveCandidateSuccess({
              candidate: response,
            });
          }),
          catchError((error) => of(candidatesActions.loadActiveCandidateFailure({ error: error }))),
        );
      }),
    ),
  );

  public updateUrlOnCandidateSelection = createEffect(
    () =>
      this.actions$.pipe(
        ofType(candidatesActions.setActiveCandidate),
        concatLatestFrom(() => [this.store.select(selectQueryParams)]),
        filter(([action, currentQueryParams]) => {
          return (
            action.selectedId !== currentQueryParams?.[SchedulingViewQueryParams.ACTIVE_CANDIDATE]
          );
        }),
        tap(([action]) => {
          setSchedulingViewQueryParam(
            this.location,
            this.router,
            SchedulingViewQueryParams.ACTIVE_CANDIDATE,
            action.selectedId,
          );
        }),
      ),
    { dispatch: false },
  );

  public loadActiveCandidateFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidatesActions.loadCandidatesFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de gegevens van de begeleider.',
        }),
      ),
    { dispatch: false },
  );

  public markCandidateSlotRejection = createEffect(() =>
    this.actions$.pipe(
      ofType(candidatesActions.markCandidateRejection),
      concatLatestFrom((action) =>
        this.store.pipe(
          select(CandidatesSelectors.selectCandidateOrActiveCandidate(action.candidate)),
          isDefined(),
        ),
      ),
      switchMap(([action, candidate]) => {
        return this.candidateApi
          .postCandidateRejection({
            candidate: jsonLdSelectId(candidate['@id']),
            declineReason: action.declineReason,
            slots: action.slots,
          })
          .pipe(
            map(() => {
              return candidatesActions.markCandidateRejectionSuccess({
                candidate: candidate,
                slots: action.slots,
                assignment: action.assignment,
              });
            }),
            catchError((error) =>
              of(
                candidatesActions.markCandidateRejectionFailure({
                  candidate: candidate,
                  error: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  public markCandidateRejectionFailure = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidatesActions.markCandidateRejectionFailure, {
          message: (action) =>
            `Let op! Het uitroosteren van ${action.candidate.fullName} is mislukt!`,
        }),
      ),
    { dispatch: false },
  );
}
