import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';
import { WithoutFirstSlotContract } from '../../contracts/without-first-slot.contract';

@Injectable({
  providedIn: 'root',
})
export class WithoutFirstSlotApi {
  private readonly httpClient = inject(HttpClient);

  public execute(locationId: string): Observable<Collection<WithoutFirstSlotContract>> {
    const url: string =
      `${environment.scheduler.url}${environment.scheduler.iri}` +
      `/v3/locations/${locationId}/tutoring-without-first-slot`;

    return this.httpClient.get<Collection<WithoutFirstSlotContract>>(url);
  }
}
