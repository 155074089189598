import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ApproachContract } from '@scheduler-frontend/approach-contracts';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { approachesWaitingActions } from '../actions/approaches-waiting.actions';
import { approachesActions } from '../actions/approaches.actions';

export const APPROACHES_WAITING_FEATURE_KEY = 'approachesWaiting';

export interface ApproachesWaitingState extends EntityState<ApproachContract> {
  loaded: boolean;
  loading: boolean;
  loadedChunks: number[];
  lastChunkLoaded: boolean;
  totalItems?: number | null;
  error?: unknown | null;
}

export const adapter: EntityAdapter<ApproachContract> = createEntityAdapter<ApproachContract>({
  selectId: jsonLdSelectId,
});

export const initialApproachesWaitingState: ApproachesWaitingState = adapter.getInitialState({
  loaded: false,
  loading: false,
  loadedChunks: [],
  lastChunkLoaded: false,
  error: null,
});

const reducer = createReducer(
  initialApproachesWaitingState,
  on(approachesWaitingActions.loadApproaches, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(approachesWaitingActions.loadNextChunk, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(approachesWaitingActions.loadApproachesSuccess, (state, { approaches, chunk, totalItems }) =>
    adapter.setAll(approaches, {
      ...state,
      loading: false,
      loaded: true,
      loadedChunks: [chunk],
      lastChunkLoaded: approaches.length === totalItems,
      error: null,
    }),
  ),
  on(approachesWaitingActions.loadNextChunkSuccess, (state, { approaches, chunk, totalItems }) =>
    adapter.addMany(approaches, {
      ...state,
      loading: false,
      loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()],
      lastChunkLoaded: state.ids.length + approaches.length === totalItems,
      error: null,
    }),
  ),
  on(approachesWaitingActions.addApproach, (state, { approach }) =>
    adapter.setOne(approach, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),
  ),
  on(approachesWaitingActions.removeApproach, (state, { approach }) =>
    adapter.removeOne(jsonLdSelectId(approach['@id']), {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    }),
  ),
  on(approachesWaitingActions.loadApproachesFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(approachesActions.setActiveAssigneeFilter, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(approachesActions.setActiveCandidateSearch, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
);

export function approachesWaitingReducer(
  state: ApproachesWaitingState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
