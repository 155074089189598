import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRightFromBracket, faVirus } from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { IonicModule, ModalController } from '@ionic/angular';
import { PushPipe } from '@ngrx/component';
import {
  AssignmentHasSlotDetailedWithSlot,
  AssignmentHasSlotPurposeEnum,
  AssignmentStateEnum,
  LessonDetailed,
} from '@scheduler-frontend/assignment-contracts';
import {
  ActiveAssignmentsPermissionsStoreService,
  AssignmentsStoreService,
} from '@scheduler-frontend/data-access-assignment';
import { TsRange } from '@techniek-team/class-transformer';
import { AsTypeModule } from '@techniek-team/common';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { TtNumberInputModule } from '@techniek-team/components/number-input';
import { TtTimeInputModule } from '@techniek-team/components/time-input';
import { TtFetchModule } from '@techniek-team/fetch';
import { differenceInMinutes, isAfter } from 'date-fns';
import { Observable } from 'rxjs';
import { AssignmentHasSlotRowFormValue } from '../assignment-has-slot-table.component';

@Component({
  selector: 'app-assignment-has-slot-item',
  standalone: true,
  imports: [
    CommonModule,
    AsTypeModule,
    FontAwesomeModule,
    IonicModule,
    MatTableModule,
    MatTooltipModule,
    ReactiveFormsModule,
    TtFetchModule,
    TtNumberInputModule,
    TtTimeInputModule,
    PushPipe,
  ],
  templateUrl: './assignment-has-slot-item.component.html',
  styleUrls: ['./assignment-has-slot-item.component.scss'],
})
export class AssignmentHasSlotItemComponent {
  protected readonly faTrashCan = faTrashCan;

  protected readonly faArrowRightFromBracket = faArrowRightFromBracket;

  protected readonly faVirus = faVirus;

  protected readonly assignmentsStoreService = inject(AssignmentsStoreService);

  protected readonly activeAssignmentsPermissionsStoreService = inject(
    ActiveAssignmentsPermissionsStoreService,
  );

  protected readonly AssignmentHasSlotPurposeEnum = AssignmentHasSlotPurposeEnum;

  protected readonly AssignmentStateEnum = AssignmentStateEnum;

  protected readonly form = new FormGroup({
    breakTime: new FormControl<number>(
      { value: 0, disabled: true },
      {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0), this.validateBreakTime],
      },
    ),
    actualTimePeriod: new FormGroup(
      {
        start: new FormControl<Date>(new Date(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
        end: new FormControl<Date>(new Date(), {
          nonNullable: true,
          validators: [Validators.required],
        }),
      },
      { validators: this.timeDateRangeValidator },
    ),
  });

  private readonly modalController = inject(ModalController);

  @Input()
  public set assignmentHasSlot(hasSlot: AssignmentHasSlotDetailedWithSlot) {
    if (!hasSlot) {
      return;
    }
    this.currentAssignmentHasSlot = hasSlot;
    this.form.reset(
      {
        breakTime: hasSlot.breakTime,
        actualTimePeriod: {
          start: hasSlot.realTimePeriod.start,
          end: hasSlot.realTimePeriod.end,
        },
      },
      { emitEvent: false },
    );

    if (!hasSlot.slot.role()?.allowBreakTime) {
      this.form.controls['breakTime'].disable();
    }

    this.canRemoveSlot$ =
      this.activeAssignmentsPermissionsStoreService.canRemoveSlotFromAssignment$(hasSlot);
    this.savingActualWorkingTimes$ = this.assignmentsStoreService.savingActualWorkingTimes(hasSlot);
  }

  protected currentAssignmentHasSlot!: AssignmentHasSlotDetailedWithSlot;

  protected canRemoveSlot$!: Observable<boolean>;

  protected savingActualWorkingTimes$!: Observable<boolean>;

  protected changeIsBillable(assignmentHasSlot: AssignmentHasSlotDetailedWithSlot): void {
    const lesson: LessonDetailed = assignmentHasSlot.slot.lesson;
    this.assignmentsStoreService.setIsBillable(assignmentHasSlot.getId(), !lesson.isBillable);
  }

  protected unassignSlot(): void {
    this.assignmentsStoreService.unassignSlot(this.currentAssignmentHasSlot);
  }

  protected async removeSlot(): Promise<void> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: TtSimpleModalComponent,
      componentProps: {
        title: 'Shift openstellen',
        message: 'Deze shift zal worden ontkoppeld van deze opdracht.',
      },
    });
    await confirmModal.present();
    const { role } = await confirmModal.onWillDismiss();

    if (role === 'confirm') {
      this.assignmentsStoreService.removeSlotFormAssignment(
        this.currentAssignmentHasSlot.slot.getId(),
      );
    }
  }

  protected markAsPresent(): void {
    this.assignmentsStoreService.markAsPresent(this.currentAssignmentHasSlot);
  }

  protected confirmTime(): void {
    if (this.form.invalid || this.form.disabled) {
      return;
    }

    const formValue = this.form.getRawValue();

    this.assignmentsStoreService.updateActualWorkingTimes(
      this.currentAssignmentHasSlot.getId(),
      new TsRange(formValue.actualTimePeriod.start, formValue.actualTimePeriod.end),
      this.currentAssignmentHasSlot.slot.role()?.allowBreakTime ? +formValue.breakTime : undefined,
    );
  }

  /**
   * Form validator which checks if the time is correct.
   */
  private timeDateRangeValidator(group: AbstractControl): ValidationErrors | null {
    if (!group || !(group instanceof FormGroup)) {
      return null;
    }

    const value: NonNullable<AssignmentHasSlotRowFormValue['actualTimePeriod']> =
      group.getRawValue();
    if (!value.start || !value.end) {
      return { tsRangeTimeNotSet: { value: group } };
    }
    return isAfter(group.value.end, group.value.start)
      ? null
      : { tsRangeNotInRange: { value: group } };
  }

  /**
   * Make sure the length of the break is not longer than the length of the shift
   */
  private validateBreakTime(control: AbstractControl): ValidationErrors | null {
    if (!control || !(control instanceof FormControl) || !control.parent) {
      return null;
    }
    const shiftLength: number = differenceInMinutes(
      control.parent.value?.actualTimePeriod?.end,
      control.parent.value?.actualTimePeriod?.start,
    );
    const breakLength: number = +control.getRawValue();

    if (shiftLength > breakLength) {
      return null;
    }

    return { breakIsLongerThenWorkingTimes: { value: breakLength } };
  }
}
