<div slot="start">
  @if (assignmentsStoreService.activeAssignment$ | async; as assignment) {
    @if (assignment.state === AssignmentStateEnum.PROVISIONALLY_CONFIRMED) {
      <ion-item class="status-explanation" lines="none">
        <ion-label class="ion-text-wrap"
          >Deze opdracht zal op
          {{ assignment.definitiveConfirmationDate | date: 'dd-MM-yyyy' }}
          definitief bevestigd worden.</ion-label
        >
      </ion-item>
    }
  }
</div>
<ion-select
  interface="popover"
  label="Status"
  placeholder="Status"
  slot="start"
  (ionChange)="transition($event)"
  [formControl]="assignmentStatusControl"
>
  @if (assignmentsStoreService.activeAssignment$ | async; as assignment) {
    <ion-select-option [value]="assignment.state" disabled="true">
      {{ AssignmentStateDisplayValues[assignment.state] | capitalize }}
    </ion-select-option>
  }
  @if (assignmentsStoreService.activeAssignmentTransitions$ | async; as availableTransitions) {
    @for (transition of availableTransitions; track transition) {
      <ion-select-option [value]="transition.name">
        {{ TransitionToMessage[transition.name] | capitalize }}
      </ion-select-option>
    }
  }
</ion-select>
