import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { CandidateDetailedContract } from '@scheduler-frontend/candidate-contracts';
import { isDefined } from '@techniek-team/rxjs';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { exhaustMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { candidateAssignmentsHistoryActions } from '../actions/candidate-assignments-history.actions';
import { candidatesActions } from '../actions/candidates.actions';
import { AssignmentApi } from '../api/assignments/assignment.api';
import { CandidateAssignmentsHistorySelectors } from '../selectors/candidate-assignments-history.selectors';
import { CandidatesSelectors } from '../selectors/candidates.selectors';

@Injectable()
export class CandidateAssignmentsHistoryEffects {
  private readonly assignmentApi = inject(AssignmentApi);

  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public loadActiveCandidateAssignmentsHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidatesActions.loadActiveCandidateSuccess,
        candidatesActions.refreshActiveCandidate,
        candidateAssignmentsHistoryActions.refresh,
      ),
      concatLatestFrom(() =>
        this.store.select(CandidatesSelectors.activeCandidateId).pipe(isDefined()),
      ),
      exhaustMap(([action, previousActiveCandidateId]) => {
        const candidateId: string =
          'candidate' in action
            ? jsonLdSelectId((action as { candidate: CandidateDetailedContract }).candidate)
            : previousActiveCandidateId;

        return this.assignmentApi
          .getCandidateAssignments(candidateId, 1, { startBefore: new Date() }, { period: 'desc' })
          .pipe(
            map((assignmentsCollection) =>
              candidateAssignmentsHistoryActions.loadSuccess({
                assignments: assignmentsCollection['hydra:member'],
                chunk: 1,
                totalItems: assignmentsCollection['hydra:totalItems'],
              }),
            ),
            catchError((error) =>
              of(candidateAssignmentsHistoryActions.loadFailure({ error: error })),
            ),
          );
      }),
    ),
  );

  public loadActiveCandidateAssignmentsHistoryNextChunk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateAssignmentsHistoryActions.loadNextChunk),
      concatLatestFrom(() => [
        this.store
          .select(CandidateAssignmentsHistorySelectors.loadedChunks)
          .pipe(map((chunks) => Math.max(...chunks))),
        this.store.select(CandidatesSelectors.activeCandidateId).pipe(isDefined()),
      ]),
      exhaustMap(([_action, lastChunk, candidateId]) => {
        return this.assignmentApi
          .getCandidateAssignments(
            candidateId,
            lastChunk + 1,
            { startBefore: new Date() },
            { period: 'desc' },
          )
          .pipe(
            map((assignmentsCollection) =>
              candidateAssignmentsHistoryActions.loadNextChunkSuccess({
                assignments: assignmentsCollection['hydra:member'],
                chunk: lastChunk + 1,
                totalItems: assignmentsCollection['hydra:totalItems'],
              }),
            ),
            catchError((error) =>
              of(candidateAssignmentsHistoryActions.loadFailure({ error: error })),
            ),
          );
      }),
    ),
  );

  public failureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidateAssignmentsHistoryActions.loadFailure, {
          message:
            'Er is iets misgegaan bij het ophalen van de opdrachtgeschiedenis van de begeleider.',
        }),
      ),
    { dispatch: false },
  );
}
