import { createActionGroup, props } from '@ngrx/store';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { CreateSlotRequest, UpdateSlotRequest } from './api/slot.request';

export const slotsActions = createActionGroup({
  source: '[Slot]',
  events: {
    /**
     * The active slot, use for example by the edit slot dialog.
     */
    setActiveSlot: props<{ slotId: string }>(),

    /**
     * loading a single slot, for example edit slot dialog needs this
     */
    loadSlot: props<{ slotId: string }>(),
    loadSlots: props<{ slotId: string[]; setAsSlotList: boolean }>(),
    loadSlotSuccess: props<{ slots: SlotDetailedContract[]; setAsSlotList: boolean }>(),
    loadSlotFailure: props<{ error: unknown }>(),

    /**
     * loading slots which belong to a specific search.
     */
    loadSlotsSuccess: props<{
      slots: SlotDetailedContract[];
      totalItems: number;
      chunk: number;
    }>(),
    loadSlotsFailure: props<{ error: unknown }>(),

    /**
     * creating a slot
     */
    createSlots: props<{ request: CreateSlotRequest[] }>(),
    createSlotsSuccess: props<{ slots: SlotDetailedContract[] }>(),
    createSlotsFailure: props<{ error: unknown }>(),

    /**
     * editing a slot
     */
    updateSlot: props<{ request: UpdateSlotRequest }>(),
    updateSlotSuccess: props<{ slot: SlotDetailedContract }>(),
    updateSlotFailure: props<{ error: unknown }>(),

    /**
     * deleting a slot
     */
    showConfirmationToDeleteSlot: props<{ slotId: string }>(),
    deleteSlot: props<{ slotId: string }>(),
    deleteSlotSuccess: props<{ slotId: string }>(),
    deleteSlotFailure: props<{ error: unknown }>(),

    /**
     * Mercure updates trigger these actions
     */
    slotCreated: props<{ slot: SlotContract }>(),
    slotUpdated: props<{ slot: SlotDetailedContract }>(),
    slotDeleted: props<{ slot: string }>(),
  },
});
