import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DeclineReasonContract } from '../contracts/decline-reason.contract';

export const declineReasonsActions = createActionGroup({
  source: '[DeclineReasons]',
  events: {
    initDeclineReasons: emptyProps(),
    reloadDeclineReasons: emptyProps(),
    loadDeclineReasonsSuccess: props<{
      declineReasons: DeclineReasonContract[];
      totalItems: number;
    }>(),
    loadFromCacheDeclineReasonsSuccess: props<{
      declineReasons: DeclineReasonContract[];
      totalItems: number;
      cacheTimestamp: string; // iso 8601 date
    }>(),
    loadDeclineReasonsFailure: props<{ error: unknown }>(),
  },
});
