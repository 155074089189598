import { Observable, firstValueFrom } from 'rxjs';

/**
 * Converts an observable to a promise by subscribing to the observable,
 * and returning a promise that will resolve as soon as the first value
 * arrives from the observable. The subscription will then be closed.
 *
 * If the observable stream completes before any values were emitted, the
 * returned promise will return undefined.
 *
 * If the observable stream emits an error, the returned promise will reject
 * with that error.
 *
 * The different between this and the <a href="https://rxjs.dev/api/index/function/firstValueFrom">firstValueFrom</a>
 * method from Rxjs is that is waits for the first emit instead of the first value.
 *
 * ## Example
 *
 * Wait for the first value or emit from a stream and emit it from a promise in
 * an async function
 *
 * ```ts
 * async function execute() {
 *   const source$ = of(undefined, 1, 2, 3, undefined);
 *   const firstNumber = await lastEmitFrom(source$);
 *   console.log(`The first number is ${ firstNumber }`);
 * }
 *
 * execute();
 *
 * // Expected output:
 * // 'The first emit is undefined'
 * // where the firstValueFrom function would wait for the second to last emit with the value 3
 * ```
 *
 * @see {@link firstValueFrom}
 *
 * @param source the observable to convert to a promise
 */
export function firstEmitFrom<T>(source: Observable<T>): Promise<T | undefined> {
  return firstValueFrom(source, { defaultValue: undefined });
}

