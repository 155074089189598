import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AvailabilityTypeEnum } from '@scheduler-frontend/calendar-contracts';
import { CandidateMinimal, UsersStoreService } from '@scheduler-frontend/data-access-users';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { PlainRangeInterface, TsRange } from '@techniek-team/class-transformer';
import { parseISO } from 'date-fns';
import { Observable, switchMap } from 'rxjs';
import { PostCandidateAvailabilityRequest } from './availability.request';
import { GetAvailabilitiesResponse } from './availability.response';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityApi {
  private readonly httpClient = inject(HttpClient);

  private readonly userStoreService = inject(UsersStoreService);

  public getAvailabilities(
    candidate: CandidateMinimal | string,
    range?: TsRange | PlainRangeInterface<string>,
  ): Observable<Collection<GetAvailabilitiesResponse>> {
    if (candidate instanceof CandidateMinimal) {
      candidate = candidate.getId();
    }
    let params: HttpParams = new HttpParams();
    if (range) {
      for (const [key, value] of Object.entries(
        range instanceof TsRange ? range.toObject() : range,
      )) {
        params = params.set(`range[${key}]`, value);
      }
    }

    //todo dont use userStore directly
    return this.userStoreService.user$.pipe(
      switchMap(() => {
        const url = `${environment.scheduler.url}${environment.scheduler.iri}/v3/candidates/${candidate}/availabilities`;

        return this.httpClient.get<Collection<GetAvailabilitiesResponse>>(url, {
          params: params,
        });
      }),
    );
  }

  public postCandidateAvailability(
    timePeriod: PlainRangeInterface,
    candidateId: string,
    availabilityValue: AvailabilityTypeEnum = AvailabilityTypeEnum.YES,
    remarks: string | null = null,
  ): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/candidates/${candidateId}/availability`;

    const requestBody: PostCandidateAvailabilityRequest = {
      available: availabilityValue,
      timePeriod: new TsRange(
        parseISO(timePeriod.start),
        parseISO(timePeriod.end),
        timePeriod.inclusiveStart,
        timePeriod.inclusiveEnd,
      ).toString(),
      remarks: remarks,
      createdBy: 'PLANNER',
    };

    return this.httpClient.post<void>(url, requestBody);
  }

  public deleteAvailability(availabilityId: string): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/availabilities/${availabilityId}`;

    return this.httpClient.delete<void>(url);
  }
}
