import { ScheduleGroup } from '../+state/selectors/scheduling-week-view.selectors';

/**
 * A memoized function to sort ScheduleGroup objects first by schedule name and then by schedule ID.
 *
 * @param {ScheduleGroup} scheduleGroupA - A ScheduleGroup object.
 * @param {ScheduleGroup} scheduleGroupB - Another ScheduleGroup object to compare with the first one.
 * @returns {number} - A number indicating the sort order.
 *
 * @example
 * const scheduleGroupA = { schedule: { '@id': 'id1', name: 'Schedule A' } };
 * const scheduleGroupB = { schedule: { '@id': 'id2', name: 'Schedule B' } };
 *
 * const order = sortScheduleGroup(scheduleGroupA, scheduleGroupB);
 */
export function sortScheduleGroup(
  scheduleGroupA: ScheduleGroup,
  scheduleGroupB: ScheduleGroup,
): number {
  const compareScheduleName: number = scheduleGroupB.schedule.name.localeCompare(
    scheduleGroupA.schedule.name,
  );
  if (compareScheduleName !== 0) {
    return compareScheduleName;
  }

  return (scheduleGroupB.schedule['@id'] ?? '').localeCompare(scheduleGroupA.schedule['@id'] ?? '');
}
