import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActionDetailContract } from '../contracts/action.contract';

export const actionsActions = createActionGroup({
  source: '[Action]',
  events: {
    loadActions: emptyProps(),
    refresh: emptyProps(),
    loadActionsSuccess: props<{
      actions: ActionDetailContract[];
      chunk: number;
      totalItems: number;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadActionsFailure: props<{ error: any }>(),
    loadActionsNextChunk: emptyProps(),
    loadActionsNextChunkSuccess: props<{
      actions: ActionDetailContract[];
      chunk: number;
      totalItems: number;
    }>(),

    deleteAction: props<{ id: string }>(),
    deleteActionSuccess: props<{ id: string }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    deleteActionFailure: props<{ error: any }>(),
  },
});
