import { SlicePipe } from '@angular/common';
import { Component, effect, ElementRef, inject, input, Renderer2 } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';

export enum TtAvatarShape {
  ROUND = 'ROUND',
  SQUARE = 'SQUARE',
}

@Component({
  selector: 'tt-avatar',
  templateUrl: './tt-avatar.component.html',
  styleUrls: ['./tt-avatar.component.scss'],
  host: {
    '[class.shape-round]': 'this.shape() === \'ROUND\'',
    '[class.shape-square]': 'this.shape() === \'SQUARE\'',
  },
  standalone: true,
  imports: [
    IonicModule,
    SlicePipe,
  ],
})
export class TtAvatarComponent {

  private readonly element = inject(ElementRef);

  private readonly renderer = inject(Renderer2);

  public name = input.required<string>();

  public picture = input<string>();

  public shape = input<TtAvatarShape>(TtAvatarShape.ROUND);

  public color = input<IonColor>(IonColorType.PRIMARY);

  protected readonly setColor = effect(() => {
    const previousColorClasses: string[] = this.element.nativeElement.className.split(' ')
      .filter((item: string) => ((item) ? item.match(/ion-color-*./) : false));
    for (let className of previousColorClasses) {
      this.renderer.removeClass(this.element.nativeElement, className);
    }
    this.renderer.addClass(this.element.nativeElement, 'ion-color-' + (this.color() ?? 'primary'));
  });
}
