import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Approach, ApproachContract, ApproachState } from '@scheduler-frontend/approach-contracts';
import { BusinessService } from '@scheduler-frontend/data-access-business-services';
import { Role } from '@scheduler-frontend/data-access-roles';
import { SubjectModel } from '@scheduler-frontend/data-access-subjects';
import { LevelEnum } from '@scheduler-frontend/enums';
import { environment } from '@scheduler-frontend/environments';
import { Collection, Resource } from '@techniek-team/api-platform';
import {
  MercureClient,
  MercureCreatedEvent,
  MercureUpdatedEvent,
} from '@techniek-team/mercure-client';
import { filter, map, Observable, shareReplay } from 'rxjs';
import {
  ExpireApproachResponse,
  GetApproachResponse,
  GetDetailedApproachResponse,
  HandleApproachResponse,
} from './approach.response';

export interface PostApproachFilters {
  businessServices?: BusinessService[] | string[];
  roles?: Role[] | string[];
  levels?: LevelEnum[];
  subjects?: SubjectModel[] | string[];
}

@Injectable({
  providedIn: 'root',
})
export class ApproachApi {
  private readonly httpClient = inject(HttpClient);

  private readonly mercureClient = inject(MercureClient);

  private readonly approachMercureListener = this.mercureClient
    .request<ApproachContract>(environment.mercure.hub, ['approach-created', 'approach-updated'], {
      tokenEndpoint: true,
      debug: true,
      observe: 'response',
    })
    .pipe(takeUntilDestroyed(), shareReplay(1));

  public getApproach(approach: string): Observable<Resource<GetDetailedApproachResponse>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/approaches/${approach}`;

    return this.httpClient.get<Resource<GetDetailedApproachResponse>>(url);
  }

  public getApproaches(
    page: number,
    state?: ApproachState,
    person?: string,
    search?: string,
  ): Observable<Collection<GetApproachResponse>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/approaches`;

    let params: HttpParams = new HttpParams()
      .set('page', page.toString())
      .set('itemsPerPage', '20');

    if (state) {
      params = params.set('state', state);
    }

    if (person) {
      params = params.set('person', person);
    }

    if (search && search !== '') {
      params = params.set('query', search);
    }

    return this.httpClient.get<Collection<GetApproachResponse>>(url, { params: params });
  }

  public markApproachAsHandled(
    approach: Approach | string,
  ): Observable<Resource<HandleApproachResponse>> {
    if (approach instanceof Approach) {
      approach = approach.getId();
    }

    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/approaches/${approach}/handle`;
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/merge-patch+json',
    );

    return this.httpClient.patch<Resource<HandleApproachResponse>>(url, {}, { headers: headers });
  }

  public markApproachAsExpired(
    approach: Approach | string,
  ): Observable<Resource<ExpireApproachResponse>> {
    if (approach instanceof Approach) {
      approach = approach.getId();
    }

    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/approaches/${approach}/expire`;
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/merge-patch+json',
    );

    return this.httpClient.patch<Resource<ExpireApproachResponse>>(url, {}, { headers: headers });
  }

  public approachCreatedEvents(): Observable<ApproachContract> {
    return this.approachMercureListener.pipe(
      filter((event) => event instanceof MercureCreatedEvent),
      map((event) => event.data as ApproachContract),
    );
  }

  public approachUpdatedEvents(): Observable<ApproachContract> {
    return this.approachMercureListener.pipe(
      filter((event) => event instanceof MercureUpdatedEvent),
      map((event) => event.data as ApproachContract),
    );
  }
}
