import { AssignmentStateEnum } from '@scheduler-frontend/assignment-contracts';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';

function createFilterGroupExpression(
  key: string,
  value: string | number | string[],
): string | undefined {
  if (typeof value === 'number') {
    return `${key} = ${value}`;
  }
  if (typeof value === 'string' || value.length === 1) {
    return `${key} = '${jsonLdSelectId(typeof value === 'string' ? value : value[0])}'`;
  }

  return `${key} = ['${value.map((item) => jsonLdSelectId(item)).join("','")}']`;
}

function createRangeFilterExpression(
  key: string,
  value: PlainRangeInterface<string | number>,
): string | undefined {
  return `${key} >= '${value.start}' and ${key} <= '${value.end}'`;
}

function createDateRangeFilterExpression(
  key: string,
  value: PlainRangeInterface<string | number>,
): string | undefined {
  return `${key} >= '${formatISO(value.start, { representation: 'date' })}' and ${key} <= '${formatISO(value.end, { representation: 'date' })}'`;
}

function createAssignmentStateExpression(
  key: string,
  value: string | string[],
): string | undefined {
  if (typeof value === 'string') {
    if (value === AssignmentStateEnum.UNASSIGNED) {
      return 'slot.candidate = NULL';
    }
    return `${key}="${value}"`;
  }

  let expression = '';
  const indexOf = value.findIndex((item) => item === AssignmentStateEnum.UNASSIGNED);
  if (indexOf !== -1) {
    expression += 'slot.candidate = NULL';
    value.splice(indexOf, 1);
    if (value.length > 0) {
      expression += ' or ';
    }
  }

  if (value.length > 0) {
    expression = `${expression}${key} = ['${value.join("','")}']`;
  }

  return expression;
}

function createCustomFilterGroupExpression(
  key: string,
  value: string | string[],
): string | undefined {
  if (typeof value === 'string') {
    return value;
  }

  return value.join(' and ');
}

export function objectToSearchExpression(filters: {
  [param: string]:
    | PlainRangeInterface<string | number>
    | string
    | number
    | readonly (string | number)[];
}): string {
  return Object.entries(filters)
    .map(([key, value]) => {
      switch (key) {
        case 'lesson.date':
          return createDateRangeFilterExpression(
            key,
            value as PlainRangeInterface<string | number>,
          );
        case 'lesson.numberOfPupils':
          return createRangeFilterExpression(key, value as PlainRangeInterface<string | number>);
        case 'assignment.state':
          return createAssignmentStateExpression(key, value as string | string[]);
        case 'custom_labels':
          return createCustomFilterGroupExpression(key, value as string | string[]);
        default:
          return createFilterGroupExpression(key, value as string | number | string[]);
      }
    })
    .join(' and ');
}
