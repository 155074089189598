import { createSelector } from '@ngrx/store';
import { SlotContract, SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { groupBy } from 'lodash-es';
import { formatTsRangeToGridColumn } from '../../functions/format-ts-range-to-grid-column.function';
import { SchedulingSelectors } from './scheduling.selectors';

export interface TimelineGridSlot {
  slot: SlotDetailedContract;
  candidateName: string | undefined;
  gridColumn: string;
  gridRow: number;
}

export class SchedulingDayViewSelectors {
  public static readonly slotListAsTimelineGrids = createSelector(
    SchedulingSelectors.slotsInView,
    (slots) => {
      if (!slots) {
        return undefined;
      }

      if (slots.length === 0) {
        return [];
      }

      const getCandidateName = (slot: SlotContract | SlotDetailedContract) =>
        (slot as SlotDetailedContract)?.assignmentHasSlot?.assignment?.candidate?.fullName ??
        'unassigned';
      const candidateWithSlotsDict: [string, (SlotContract | SlotDetailedContract)[]][] =
        Object.entries(groupBy<SlotContract | SlotDetailedContract>(slots, getCandidateName));

      this.sortCandidates(candidateWithSlotsDict);

      let count = 1; // start on grid row 2 accounting for the header
      const sortedCandidateWithSlotsDict = candidateWithSlotsDict.map(([key, slotsOfCandidate]) => {
        count++;
        return slotsOfCandidate.map(
          (slot) =>
            ({
              candidateName: key,
              slot: slot,
              gridColumn: formatTsRangeToGridColumn(slot.timePeriod),
              gridRow: count,
            }) as TimelineGridSlot,
        );
      });

      sortedCandidateWithSlotsDict[sortedCandidateWithSlotsDict.length - 1] =
        sortedCandidateWithSlotsDict[sortedCandidateWithSlotsDict.length - 1].map((slot) => ({
          ...slot,
          gridRow: ++count,
        }));
      return sortedCandidateWithSlotsDict;
    },
  );

  private static sortCandidates(
    candidateWithSlotsDict: [string, (SlotContract | SlotDetailedContract)[]][],
  ) {
    return candidateWithSlotsDict.sort(([keyA], [keyB]) => {
      if (keyA === 'unassigned') {
        return 1;
      }

      if (keyB === 'unassigned') {
        return -1;
      }

      return keyA.localeCompare(keyB);
    });
  }
}
