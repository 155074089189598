import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { ApproachAssigneeFilter, ApproachState } from '@scheduler-frontend/approach-contracts';
import { usersActions, UsersSelectors } from '@scheduler-frontend/data-access-users';
import { handleEndpointFailure } from '@techniek-team/tt-ngrx';
import { catchError, exhaustMap, filter, map, Observable, of, switchMap, takeUntil } from 'rxjs';
import { approachesToDoActions } from '../actions/approaches-to-do.actions';
import { approachesActions } from '../actions/approaches.actions';
import { ApproachApi } from '../api/approach.api';
import { ApproachesToDoSelectors } from '../selectors/approaches-to-do.selectors';
import { ApproachesSelectors } from '../selectors/approaches.selectors';
import {
  filterOnActiveAssigneeFilterOption,
  filterOnActiveCandidateSearchOption,
} from './functions/approach-filters.function';

@Injectable()
export class ApproachesToDoEffects implements OnRunEffects {
  private readonly actions$ = inject(Actions);

  private readonly approachApi = inject(ApproachApi);

  private readonly store = inject(Store);

  public createLoadApproaches = createEffect(() =>
    this.actions$.pipe(
      ofType(
        approachesToDoActions.loadApproaches,
        approachesActions.setActiveAssigneeFilter,
        approachesActions.setActiveCandidateSearch,
        usersActions.loadUserSuccess,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(UsersSelectors.activeUser)),
        this.store.pipe(select(ApproachesSelectors.selectedAssigneeFilter)),
        this.store.pipe(select(ApproachesSelectors.selectedCandidateSearch)),
      ]),
      switchMap(([_action, user, approachAssigneeFilter, search]) => {
        return this.approachApi
          .getApproaches(
            1,
            ApproachState.TODO,
            approachAssigneeFilter === ApproachAssigneeFilter.PERSONAL ? user?.id : undefined,
            search,
          )
          .pipe(
            map((approachesCollection) =>
              approachesToDoActions.loadApproachesSuccess({
                approaches: approachesCollection['hydra:member'],
                chunk: 1,
                totalItems: approachesCollection['hydra:totalItems'],
              }),
            ),
            catchError((error) =>
              of(approachesToDoActions.loadApproachesFailure({ error: error })),
            ),
          );
      }),
    ),
  );

  public addApproachToTodoOnCreate = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.approachCreated),
      filterOnActiveAssigneeFilterOption(this.store),
      filterOnActiveCandidateSearchOption(this.store),
      map((action) => {
        return approachesToDoActions.addApproach({ approach: action.approach });
      }),
    ),
  );

  public removeApproachOnStatusChanged = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesActions.approachUpdated),
      filter((action) => action.approach.state !== ApproachState.TODO),
      map((action) => {
        return approachesToDoActions.removeApproach({ approach: action.approach });
      }),
    ),
  );

  public createLoadNextChunkEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(approachesToDoActions.loadNextChunk),
      concatLatestFrom(() => [
        this.store.pipe(
          select(ApproachesToDoSelectors.loadedChunks),
          map((chunks) => Math.max(...chunks)),
        ),
        this.store.pipe(select(UsersSelectors.activeUser)),
        this.store.pipe(select(ApproachesSelectors.selectedAssigneeFilter)),
        this.store.pipe(select(ApproachesSelectors.selectedCandidateSearch)),
      ]),
      exhaustMap(([_action, lastChunk, user, approachAssigneeFilter, search]) => {
        return this.approachApi
          .getApproaches(
            lastChunk + 1,
            ApproachState.TODO,
            approachAssigneeFilter === ApproachAssigneeFilter.PERSONAL ? user?.id : undefined,
            search,
          )
          .pipe(
            map((approachesCollection) =>
              approachesToDoActions.loadNextChunkSuccess({
                approaches: approachesCollection['hydra:member'],
                chunk: lastChunk + 1,
                totalItems: approachesCollection['hydra:totalItems'],
              }),
            ),
            catchError((error) =>
              of(approachesToDoActions.loadApproachesFailure({ error: error })),
            ),
          );
      }),
    ),
  );

  public createLoadApproachesFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(approachesToDoActions.loadApproachesFailure, {
          message:
            'Er is iets misgegaan bij het ophalen van de benaderingen voor in de "To-Do"-lijst.',
        }),
      ),
    { dispatch: false },
  );

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(approachesActions.startSchedulingByApproaches),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions$.pipe(ofType(approachesActions.stopSchedulingByApproaches))),
        ),
      ),
    );
  }
}
