import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideCandidatesStore } from '@scheduler-frontend/data-access-candidates';
import { AbsenceEffects } from './+state/absence.effects';
import { ABSENCE_FEATURE_KEY, absenceReducer } from './+state/absence.reducer';
import { AbsenceStoreService } from './absence-store.service';

export function provideAbsenceStore(): Provider | EnvironmentProviders[] {
  return [
    provideCandidatesStore(),
    provideState({ name: ABSENCE_FEATURE_KEY, reducer: absenceReducer }),
    provideEffects(AbsenceEffects),
    AbsenceStoreService,
  ];
}
