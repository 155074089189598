import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { UsersSelectors } from '@scheduler-frontend/data-access-users';
import { isDefined } from '@techniek-team/rxjs';
import {
  handleEndpointFailure,
  handleEndpointSuccess,
  jsonLdSelectId,
} from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { candidateRemarksActions } from '../actions/candidate-remarks.actions';
import { candidatesActions } from '../actions/candidates.actions';
import { CandidateApi } from '../api/candidate/candidate.api';
import { CandidatesSelectors } from '../selectors/candidates.selectors';

@Injectable()
export class CandidateRemarksEffects {
  private readonly candidateApi = inject(CandidateApi);

  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  public readonly createLoadSelectedCandidateRemarksEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(
        candidatesActions.loadActiveCandidateSuccess,
        candidatesActions.refreshActiveCandidate,
        candidateRemarksActions.refreshActiveCandidateRemarks,
        candidateRemarksActions.addCandidateRemarkSuccess,
      ),
      concatLatestFrom(() =>
        this.store.select(CandidatesSelectors.activeCandidateId).pipe(isDefined()),
      ),
      switchMap(([action, previousSelectedId]) => {
        return this.candidateApi
          .getCandidatePersonalRemarks(
            'candidate' in action ? jsonLdSelectId(action.candidate) : previousSelectedId,
          )
          .pipe(
            map((response) => response['hydra:member']),
            map((response) => {
              return candidateRemarksActions.loadActiveCandidateRemarksSuccess({
                remarks: response,
              });
            }),
            catchError((error) =>
              of(
                candidateRemarksActions.loadActiveCandidateRemarksFailure({
                  error: error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  public readonly createAddCandidateRemarkEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(candidateRemarksActions.addCandidateRemark),
      concatLatestFrom(() =>
        this.store.select(CandidatesSelectors.activeCandidateId).pipe(isDefined()),
      ),
      switchMap(([action, selectedId]) => {
        return this.candidateApi.postCandidatePersonalRemarks(selectedId, action.remark).pipe(
          concatLatestFrom(() => [
            this.store.select(CandidatesSelectors.activeCandidate).pipe(isDefined()),
            this.store.select(UsersSelectors.activeUser).pipe(isDefined()),
          ]),
          map(([_response, candidate, activeUser]) => {
            return candidateRemarksActions.addCandidateRemarkSuccess({
              candidate: candidate,
              remark: {
                'remark': action.remark,
                'createdAt': formatISO(new Date()),
                '@id': 'newInstance',
                'createdBy': {
                  '@id': activeUser.id,
                  'fullName': activeUser.fullName,
                },
              },
            });
          }),
          catchError((error) =>
            of(candidateRemarksActions.addCandidateRemarkFailure({ error: error })),
          ),
        );
      }),
    ),
  );

  public createAddRemarkFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidateRemarksActions.addCandidateRemarkFailure, {
          message: 'Er is iets misgegaan bij het opslaan van je opmerking.',
        }),
      ),
    { dispatch: false },
  );

  public createAddRemarkSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointSuccess(candidateRemarksActions.addCandidateRemarkSuccess, {
          message: (action) =>
            `De opmerking over ${action.candidate.fullName} is succesvol opgeslagen.`,
        }),
      ),
    { dispatch: false },
  );

  public createLoadFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(candidateRemarksActions.loadActiveCandidateRemarksFailure, {
          message: 'Er is iets misgegaan bij het ophalen van de gegevens van de begeleider.',
        }),
      ),
    { dispatch: false },
  );
}
