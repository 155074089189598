import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { Exclude, Expose } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { BaseFilterGroupInterface } from './base-filter-group.interface';

export interface BaseFilterGroupOptions {
  label?: string;
  itemLabel?: string;
  position?: number;
  multiple?: boolean;
  disabled?: boolean;
  visibility?: FilterGroupDisplayState;
  required?: boolean;
}

export enum FilterGroupInteractionState {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
export enum FilterGroupDisplayState {
  VISIBLE = 'VISIBLE',
  COLLAPSED = 'COLLAPSED',
  HIDDEN = 'HIDDEN',
}

interface StateChange {
  interactionState: FilterGroupInteractionState;
  displayState: FilterGroupDisplayState;
}

// Without this dynamic parameter AOT compiling will fail.
// @see https://angular.io/guide/angular-compiler-options#strictmetadataemit
// @dynamic
export abstract class BaseFilterGroup implements BaseFilterGroupInterface {
  @Exclude() public readonly type!: string;

  /**
   * A unique name to identify the group and also the key which can be used in for example a queryParameter
   *
   * @inheritDoc
   */
  @Expose() public key!: string;

  /**
   * This property will be used as the display text for the button which will
   * open the menu containing the filters of this group.
   * If not set the value property will be used.
   *
   * @inheritDoc
   */
  @Expose() public label?: string;

  /**
   * The position in which order the filter group buttons should be shown.
   * If not set it will order them by displayValue (or otherwise name) and if partially set.
   *
   * @inheritDoc
   */
  @Expose() public position?: number;

  /**
   * This state states the current form of interaction that is possible with
   * this filterGroup. The are 2 possible status values:
   *
   * * **ENABLED**:  This filterGroup is active, meaning that the user can
   *                   select this filter.
   * * **DISABLED**: This filterGroup is disabled meaning that the User can't
   *                   select this specific filter.
   *
   * When the filterGroup is disabled it's still possible to change the selection
   * of filters. The component using the model is responsible for the disabling
   * of the filters. The {@see changed } observable doesn't emit the selection
   * changes when in disabled state.
   *
   * Setting the {@see FilterGroup.enable} and {@see FilterGroup.disable}
   * function also changes the {@see interactionState} for each filter.
   */
  @Exclude()
  protected interactionState: BehaviorSubject<FilterGroupInteractionState> =
    new BehaviorSubject<FilterGroupInteractionState>(
      FilterGroupInteractionState.ENABLED,
    );

  /**
   * This state states the current form of interaction that is possible with
   * this filterGroup. The are 3 possible status values:
   *
   * * **VISIBLE**:   This filterGroup is visible, meaning that the user can see
   *                    the filterGroup.
   * * **COLLAPSED**: This filterGroup is collapsed, meaning that the user can
   *                    see a collapsed version of this filterGroup.
   * * **HIDDEN**:    This filterGroup is hidden, meaning that the user can't
   *                    see the filterGroup.
   *
   * The implementation of the display states should be done within the
   * component using this model. This property is merely for storage and doesn't
   * have to be used.
   *
   * Setting the {@see FilterGroup.show} and {@see FilterGroup.hide} function
   * also changes the {@see displayState} for each filter.
   */
  @Exclude() protected visibility = FilterGroupDisplayState.VISIBLE;

  @Expose() public multiple = false;

  @Expose() public required = false;

  /**
   * Returns the display text of the filter groups.
   *
   * This is the displayValue or if not set the key of this group.
   */
  @Exclude()
  public get displayText(): string {
    return this.label ?? this.key;
  }

  /**
   * Returns true if this filterGroup is disabled. see {@see interactionState}
   * for more information.
   */
  public get disabled(): boolean {
    return (
      this.interactionState.getValue() === FilterGroupInteractionState.DISABLED
    );
  }

  /**
   * Returns true if this filterGroup is enabled. see {@see interactionState}
   * for more information.
   */
  public get enabled(): boolean {
    return (
      this.interactionState.getValue() === FilterGroupInteractionState.ENABLED
    );
  }

  /**
   * Returns true if this filterGroup is visible. see {@see displayState}
   * for more information.
   */
  public get visible(): boolean {
    return this.visibility === FilterGroupDisplayState.VISIBLE;
  }

  /**
   * Returns true if this filterGroup is collapsed. see {@see displayState}
   * for more information.
   */
  public get collapsed(): boolean {
    return this.visibility === FilterGroupDisplayState.COLLAPSED;
  }

  /**
   * Returns true if this filterGroup is hidden. see {@see displayState}
   * for more information.
   */
  public get hidden(): boolean {
    return this.visibility === FilterGroupDisplayState.HIDDEN;
  }

  /**
   * Enables this filterGroup. See {@see interactionState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public enable(options: { onlySelf: boolean } = { onlySelf: false }): void {
    this.interactionState.next(FilterGroupInteractionState.ENABLED);
  }

  /**
   * Disables this filterGroup. See {@see interactionState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public disable(options: { onlySelf: boolean } = { onlySelf: false }): void {
    this.interactionState.next(FilterGroupInteractionState.DISABLED);
  }

  /**
   * Makes this filter visible. See {@see displayState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public show(options: { onlySelf: boolean } = { onlySelf: false }): void {
    this.visibility = FilterGroupDisplayState.VISIBLE;
  }

  /**
   * Makes this filter invisible. See {@see displayState} for more information.
   *
   * @param options Configuration options that determine how the filter
   * propagates changes in the {@see interactionState}.
   *
   * * `onlySelf`:  When true, each change only affects this filterGroup, and not
   * the filters withing this group. Default is false.
   */
  @Exclude()
  public hide(options: { onlySelf: boolean } = { onlySelf: false }): void {
    this.visibility = FilterGroupDisplayState.HIDDEN;
  }

  public abstract toObject():
    | {
        [param: string]:
          | PlainRangeInterface<string | number>
          | string
          | number
          | readonly (string | number)[];
      }
    | undefined;
}
