import { Observable, Subscriber, TeardownLogic } from 'rxjs';

//eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ClassConstructor<T> = {
  //eslint-disable-next-line @typescript-eslint/prefer-function-type,@typescript-eslint/no-explicit-any
  new (...args: any[]): T;
};

/**
 * This Observable type is returned by the {@see Fetch} decorator. It has the same
 * properties as a normal Observable with the extra addition of the {@see getIri} method
 * which returns the iri of the resource without the need to retrieve the Resource.
 *
 * It also exposes the {@see isType} method which can be used to check if this Observable
 * return the same Resource as the Type given a parameter.
 */
export class FetchObservable<T> extends Observable<T> {

  constructor(
    public readonly iri: string,
    public readonly instanceType: ClassConstructor<T>,
    subscribe?: (this: Observable<T>, subscriber: Subscriber<T>) => TeardownLogic,
  ) {
    // The actual typing in rxjs isn't exposed so typescript will bitch about the
    // subscribe property type not being the same that where they are actually equal.
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    super(subscribe);
  }

  /**
   * The method can be used to return a default Observable into an FetchObservable.
   * @param iri
   * @param instanceType
   * @param observable
   */
  public static createFromExisting<R>(
    iri: string,
    instanceType: ClassConstructor<R>,
    observable: Observable<R>,
  ): FetchObservable<R> {
    const newObservable: FetchObservable<R> = new FetchObservable<R>(iri, instanceType);
    // These are internal properties but it's the only way to properly extend the Default Observable Class.
    newObservable.source = observable.source;
    newObservable.operator = observable.operator;
    return newObservable;
  }

  /**
   * This method returns the iri of the resource without the need to retrieve
   * the Resource.
   */
  public getIri(): string {
    return this.iri;
  }

  /**
   * This method can be used to check if this FetchObservable returns the same
   * Resource as the Type given a parameter.
   */
  public isType(type: ClassConstructor<T>): boolean {
    //eslint-disable-next-line new-cap
    const instanceA: T = new this.instanceType();
    return (instanceA instanceof type);
  }
}
