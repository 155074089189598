import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { ModalController, IonicModule } from '@ionic/angular';
import { TsRange } from '@techniek-team/class-transformer';
import { isToday } from 'date-fns';
import { TtDateRangePipe } from '@techniek-team/common';

@Component({
    selector: 'tt-date-picker-modal',
    templateUrl: './tt-date-picker-modal.component.html',
    styleUrls: ['./tt-date-picker-modal.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        MatCalendar,
        TtDateRangePipe,
    ],
})
export class TtDatePickerModalComponent {
  private modalController = inject(ModalController);

  @Input() public set date(date: TsRange | DateRange<Date> | Date | null) {
    if (date instanceof TsRange) {
      this.currentDate = date.dateRange;
      return;
    }

    this.currentDate = date;
  };

  @Input() public type: 'single' | 'range' = 'single';

  @Input() public showActions: boolean = true;

  /**
   * The minimum selectable date.
   */
  @Input() public minDate!: Date;

  /**
   * The maximum selectable date.
   */
  @Input() public maxDate!: Date;

  @Output() public readonly selectedChange = new EventEmitter<
    DateRange<Date> | Date | null | undefined
  >();

  protected currentDate: DateRange<Date> | Date | null = null;

  protected dateChanged(date: Date | null): Promise<boolean | void> {
    if (this.type === 'single') {
      this.currentDate = date;
      this.selectedChange.emit(this.currentDate);
      return this.select();
    }

    const range: DateRange<Date> = this.currentDate as DateRange<Date>;
    if (range && range.start && date && date > range.start && !range.end) {
      this.currentDate = new DateRange(range.start, date);
      this.selectedChange.emit(this.currentDate);
      return this.select();
    }

    this.currentDate = new DateRange(date, null);
    return Promise.resolve();
  }

  protected async select(): Promise<boolean | void> {
    const overlay = await this.modalController.getTop();
    if (overlay && this.currentDate) {
      if (this.currentDate instanceof DateRange && this.currentDate.start && this.currentDate.end) {
        return this.modalController.dismiss(
          new TsRange(this.currentDate.start, this.currentDate.end, true, true),
          'select'
        );
      }
      return this.modalController.dismiss(this.currentDate, 'select');
    }
  }

  public cancel(): Promise<boolean> {
    return this.modalController.dismiss(undefined, 'cancel');
  }

  protected showToday(date: Date): string[] {
    return (isToday(date)) ? ['today'] : [];
  }

  protected get firstDate(): Date | null {
    if (this.currentDate instanceof DateRange) {
      return this.currentDate.start;
    }
    return this.currentDate;
  }
}
