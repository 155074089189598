import { Expose, Type } from 'class-transformer';
import {
  ArticleCodeEnumDisplayValues,
  PayoutArticleCodeEnum,
} from '../enums/payout-article-code.enum';
import { Compensation } from './compensation.model';

export class AssignmentCompensationLine {
  @Expose() public description!: string;

  @Expose() public quantity!: string;

  @Expose() public amount!: string;

  @Expose() public subtotal!: string;

  @Expose() public articleCode!: PayoutArticleCodeEnum & keyof ArticleCodeEnumDisplayValues;

  @Expose() public isExtra!: boolean;

  @Type(() => Date)
  @Expose()
  public date!: Date; // iso 8601 dateTime

  @Expose() public assignmentCompensation!: string; // iri

  @Expose() public assignmentHasSlot!: string; // iri

  @Type(() => Compensation)
  @Expose()
  public compensation!: Compensation;
}
