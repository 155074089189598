import { CandidateMinimal } from '@scheduler-frontend/data-access-users';
import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Exclude, Expose, Type } from 'class-transformer';
import { AssignmentStateEnum } from '../../enums/assignment-state.enum';
import { ContractTypeEnum } from '../../enums/contract-type.enum';

/**
 * Standard version of the Assignment resource from Scheduler-api.
 */
export class Assignment extends JsonLd {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Assignment';

  /**
   * The name of the assignment. This is used when the assignment doesn't have
   * a subject. So for example a `examentraning` is show as
   *
   * @example
   * ExamenTraining
   * Wiskunde
   *
   * Where as a `Onderwijshelden` assignment could be
   *
   * @example
   * Onderwijshelden
   * Lesopvang basisonderwijs
   */
  @Expose() public name!: string;

  @Expose() public containsCombined!: boolean;

  /**
   * This is an Internal description only used in the scheduler frontend
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public description?: string;

  /**
   * Kind of contract. So if it ib47 or not.
   */
  @Expose() public contractType!: ContractTypeEnum;

  @Type(() => Date)
  @Expose()
  public definitiveConfirmationDate!: Date;

  /**
   * The state of the assignment.
   * - UNASSIGNED There is no candidate currently assigned to this Assignment.
   * - DRAFT There is a candidate assigned to the Assignment but the request for
   *   him/her to do it isn't send yet.
   * - WAITING_FOR_CONFIRMATION A candidate is assigned to the Assignment but
   *   he/she didn't response to it yet. Waiting for either a aproval or decline
   * - CONFIRMED The assigned candidate confirmed that he is going to do the
   *   assignment
   * - FINAL The assignment is finished.
   */
  @Expose() public state!: AssignmentStateEnum;

  /**
   * If true the booking period of this assignment has been closed.
   *
   * When it does most of the manipulation of the Resource can only be executed
   * by someone with admin rights.
   *
   * This property is mainly used in the Scheduler and not in the tutor app.
   */
  @Expose() public bookingPeriodClosed!: boolean;

  /**
   * The candidate that is assigned to this assignment.
   */
  @Type(() => CandidateMinimal)
  @Expose()
  public candidate?: CandidateMinimal;

  /**
   * Synoniem for {@see bookingPeriodClosed}
   */
  @Exclude() public get isBookingPeriodClosed(): boolean {
    return this.bookingPeriodClosed;
  }

  /**
   * Return true if a Candidate is assigned to this assignment.
   */
  @Exclude() public isAssigned(): boolean {
    return !this.isUnassigned() && !!this.candidate;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isUnassigned(): boolean {
    return this.state === AssignmentStateEnum.UNASSIGNED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isDraft(): boolean {
    return this.state === AssignmentStateEnum.DRAFT;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isWaitingForConfirmation(): boolean {
    return this.state === AssignmentStateEnum.WAITING_FOR_CONFIRMATION;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isProvisionallyConfirmed(): boolean {
    return this.state === AssignmentStateEnum.PROVISIONALLY_CONFIRMED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isConfirmed(): boolean {
    return this.state === AssignmentStateEnum.CONFIRMED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isCompleted(): boolean {
    return this.state === AssignmentStateEnum.COMPLETED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isApproved(): boolean {
    return this.state === AssignmentStateEnum.APPROVED;
  }

  /**
   * Helper method to check for the state of the assignment.
   */
  @Exclude() public isFinal(): boolean {
    return this.state === AssignmentStateEnum.FINAL;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.name}`;
  }
}
