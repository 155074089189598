import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApproachContract } from '@scheduler-frontend/approach-contracts';
import { environment } from '@scheduler-frontend/environments';
import { Collection } from '@techniek-team/api-platform';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApproachApi {
  private readonly httpClient: HttpClient = inject(HttpClient);

  public getApproaches(page: number, candidate: string): Observable<Collection<ApproachContract>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/approaches`;

    let params: HttpParams = new HttpParams()
      .set('page', page.toString())
      .set('order[updatedAt]', 'desc')
      .set('itemsPerPage', '10');

    if (candidate) {
      params = params.set('candidate', candidate);
    }

    return this.httpClient.get<Collection<ApproachContract>>(url, { params: params });
  }
}
