import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AssignmentAssignmentHasSlotContract,
  AssignmentDetailedContract,
} from '@scheduler-frontend/assignment-contracts';
import { LogRecordContract } from '@scheduler-frontend/log-contracts';
import { PlainRangeInterface } from '@techniek-team/class-transformer';
import { TransitionEnum } from '../../enums/transition.enum';

export const activeAssignmentActions = createActionGroup({
  source: '[Assignments/Active]',
  events: {
    setActiveAssignment: props<{ selectedId: string | null }>(),
    loadActiveAssignmentSuccess: props<{
      assignment: AssignmentDetailedContract;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadActiveAssignmentFailure: props<{ error: any }>(),
    refresh: emptyProps(),
    clear: emptyProps(),

    loadHistorySuccess: props<{ records: LogRecordContract }>,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadHistoryFailure: props<{ error: any }>(),

    setIsUrgent: props<{ isUrgent: boolean }>(),
    setIsUrgentSuccess: props<{ isUrgent: boolean }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIsUrgentFailure: props<{ error: any }>(),

    setDescription: props<{ description: string | null }>(),
    setDescriptionSuccess: props<{ description: string | null }>(),
    setDescriptionFailure: props<{ error: unknown }>(),

    setSelfAssignment: props<{
      selfAssignable: boolean;
      allowSelfAssignWhenNew: boolean;
      minimalGradeSelfAssign: number | null;
      maxTravelDistanceSelfAssign: number | null;
    }>(),
    setSelfAssignmentSuccess: props<{
      selfAssignable: boolean;
      allowSelfAssignWhenNew: boolean;
      minimalGradeSelfAssign: number | null;
      maxTravelDistanceSelfAssign: number | null;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSelfAssignmentFailure: props<{ error: any }>(),

    updateActualWorkingTimes: props<{
      assignmentHasSlotId: string;
      time: PlainRangeInterface;
      breakTime?: number;
    }>(),
    updateActualWorkingTimesSuccess: props<{
      assignmentHasSlot: AssignmentAssignmentHasSlotContract;
      time: PlainRangeInterface;
      breakTime?: number;
    }>(),
    updateActualWorkingTimesFailure: props<{
      error: unknown;
      assignmentHasSlot: AssignmentAssignmentHasSlotContract;
    }>(),

    setIsBillable: props<{
      assignmentHasSlotId: string;
      isBillable: boolean;
    }>(),
    setIsBillableSuccess: props<{
      assignmentHasSlotId: string;
      isBillable: boolean;
    }>(),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIsBillableFailure: props<{ error: any }>(),

    unassignSlot: props<{
      assignmentHasSlot: string;
      absencePeriod?: PlainRangeInterface<string>;
    }>(),

    unassign: props<{
      absencePeriod?: PlainRangeInterface<string>;
    }>(),

    askToMarkAsPresent: props<{ assignmentHasSlot: string }>(),

    applyTransition: props<{ transition: TransitionEnum }>(),
  },
});
