import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { SubjectsEffects } from './+state/subjects.effects';
import { SUBJECTS_FEATURE_KEY, subjectsReducer } from './+state/subjects.reducer';
import { SubjectsStoreService } from './subjects-store.service';

export function provideSubjectsStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: SUBJECTS_FEATURE_KEY, reducer: subjectsReducer }),
    provideEffects(SubjectsEffects),
    SubjectsStoreService,
  ];
}
