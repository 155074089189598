import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { formatISO } from 'date-fns';
import { SubjectContract } from '../contracts/subject.contract';
import { subjectsActions } from './subjects.actions';

export const SUBJECTS_FEATURE_KEY = 'subjects';

export interface SubjectsState extends EntityState<SubjectContract> {
  totalItems?: number;

  loaded: boolean;

  loading: boolean;

  error?: unknown | null;

  cacheTimeStamp?: string;
}

export const subjectsAdapter: EntityAdapter<SubjectContract> = createEntityAdapter<SubjectContract>(
  {
    selectId: jsonLdSelectId,
  },
);

export const initialSubjectsState: SubjectsState = subjectsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: false,
});

const reducer = createReducer(
  initialSubjectsState,
  on(subjectsActions.initSubjects, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(subjectsActions.reloadSubjects, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(subjectsActions.loadSubjectsSuccess, (state, { subjects, totalItems }) =>
    subjectsAdapter.setAll(subjects, {
      ...state,
      loaded: true,
      loading: false,
      totalItems: totalItems,
      cacheTimeStamp: formatISO(new Date()),
    }),
  ),
  on(
    subjectsActions.loadFromCacheSubjectsSuccess,
    (state, { subjects, totalItems, cacheTimestamp }) =>
      subjectsAdapter.setAll(subjects, {
        ...state,
        loaded: true,
        loading: false,
        totalItems: totalItems,
        cacheTimeStamp: cacheTimestamp,
      }),
  ),
  on(subjectsActions.loadSubjectsFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function subjectsReducer(state: SubjectsState | undefined, action: Action) {
  return reducer(state, action);
}
