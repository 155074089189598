import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { locationsActions, LocationsSelectors } from '@scheduler-frontend/data-access-locations';
import { schedulesActions } from '@scheduler-frontend/data-access-schedules';
import { schedulingViewActions } from '@scheduler-frontend/data-access-scheduling';
import { slotsActions, SlotsSelectors } from '@scheduler-frontend/data-access-slots';
import { isDefined } from '@techniek-team/rxjs';
import { NgrxAction } from '@techniek-team/tt-ngrx';
import { exhaustMap, Observable, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { searchActions } from '../action/search.actions';
import { SearchSlotApi } from '../api/search-slot.api';

@Injectable()
export class LocationScheduleEffect implements OnRunEffects {
  private readonly actions$ = inject(Actions);

  private readonly searchSlotApi = inject(SearchSlotApi);

  private readonly store = inject(Store);

  public ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(searchActions.startLocationScheduleSearch),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(this.actions$.pipe(ofType(searchActions.stopLocationScheduleSearch))),
        ),
      ),
    );
  }

  public loadSlotForMissingSelectedSlots = createEffect(() => {
    return this.actions$.pipe(
      ofType(schedulingViewActions.setSchedulingView),
      concatLatestFrom(() => this.store.select(SlotsSelectors.slotEntities)),
      map(([action, slots]) => {
        const missingSlots: string[] = [];
        for (let slot of action.selectedSlots ?? []) {
          if (!slots[slot]) {
            missingSlots.push(slot);
          }
        }
        return slotsActions.loadSlots({ slotId: missingSlots, setAsSlotList: true });
      }),
    );
  });

  public readonly setCurrentLocationAsSearchFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.initLocationScheduleSearch, locationsActions.selectLocation),
      concatLatestFrom(() => [
        this.store.pipe(select(LocationsSelectors.currentLocationId), isDefined()),
      ]),
      map(([action, locationId]) => {
        let actionBody: NgrxAction<typeof searchActions.appendToSearchHash> = {
          isUserInitiated: false,
          setAsCurrentSearch: true,
          filters: {},
        };
        if (locationId) {
          actionBody.filters = { [LocationsSelectors.locationsFilterGroupKey]: locationId };
        }

        return searchActions.appendToSearchHash(actionBody);
      }),
    ),
  );

  public readonly setScheduleAsSearchFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(schedulesActions.setSelectedSchedule), // Set the action to be listened
      map((action) => {
        const actionBody: NgrxAction<typeof searchActions.appendToSearchHash> = {
          isUserInitiated: false,
          setAsCurrentSearch: true,
          filters: {},
        };
        if (action.schedule) {
          actionBody.filters = { 'slot.schedule': action.schedule };
        } else {
          actionBody.removeKeys = ['slot.schedule'];
        }
        return searchActions.appendToSearchHash(actionBody);
      }),
    ),
  );
}
