import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { ActionDetailContract } from '../contracts/action.contract';
import { actionsActions } from './actions.actions';

export const ACTIONS_FEATURE_KEY = 'actions';

export interface ActionState extends EntityState<ActionDetailContract> {
  loading: boolean;
  loaded: boolean;
  error: unknown;
  totalItems?: number;
  loadedChunks: number[];
  lastChunkLoaded: boolean;
  saveDeletions: boolean;
}

export const actionAdapter: EntityAdapter<ActionDetailContract> =
  createEntityAdapter<ActionDetailContract>({
    selectId: jsonLdSelectId,
  });

export const initialState: ActionState = actionAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  loadedChunks: [],
  lastChunkLoaded: false,
  saveDeletions: false,
});

const reducer = createReducer(
  initialState,
  on(actionsActions.loadActions, actionsActions.refresh, (state) => ({
    ...state,
    chunk: [],
    loaded: false,
    loading: true,
    error: null,
  })),
  on(actionsActions.loadActionsSuccess, (state, { actions, totalItems, chunk }) =>
    actionAdapter.setAll(actions, {
      ...state,
      loading: false,
      loaded: true,
      loadedChunks: [chunk],
      totalItems: totalItems,
      lastChunkLoaded: actions.length === totalItems,
    }),
  ),
  on(actionsActions.loadActionsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
  on(actionsActions.loadActionsNextChunk, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(actionsActions.loadActionsNextChunkSuccess, (state, { actions, totalItems, chunk }) =>
    actionAdapter.addMany(actions, {
      ...state,
      loading: false,
      loadedChunks: [...new Set([...state.loadedChunks, chunk]).values()],
      lastChunkLoaded: state.ids.length + actions.length === totalItems,
      error: null,
    }),
  ),
  on(actionsActions.deleteAction, (state) => ({
    ...state,
    saveDeletions: true,
    error: null,
  })),
  on(actionsActions.deleteActionSuccess, (state, { id }) =>
    actionAdapter.removeOne(id, {
      ...state,
      saveDeletions: false,
    }),
  ),
  on(actionsActions.deleteActionFailure, (state, { error }) => ({
    ...state,
    saveDeletions: false,
    error: error,
  })),
);

export function actionReducer(state: ActionState | undefined, action: Action): ActionState {
  return reducer(state, action);
}
